import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Blocker } from 'components';
import { ButtonProps } from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import { ActiveCampaignsCard } from 'components/gms/ActiveCampaignsCard/ActiveCampaignsCard';
import { DashboardCard } from 'components/gms/DashboardCard';
import { DashboardJumbo } from 'components/gms/DashboardJumbo';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import { RevenueByChannelCard } from 'components/gms/RevenueByChannelCard';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useOrganization } from 'queries/UseOrganizationData';
import { getSelectedDateRangeFromCurrentDate } from 'utils/dateHelpers';
import './Dashboard.scss';

export const Dashboard = (): JSX.Element => {
  const navigate = useNavigate();
  const { selectedOrganization } = useAppContext();
  const [pushAlert] = useAlerts();
  // Handling toggle and state here since multiple components will share it
  // Defaulting to 'YTD' (-1)
  const [rangeToggleSelection, setRangeToggleSelection] = useState(-1);
  const [selectedDateRange, setSelectedDateRange] = useState(
    getSelectedDateRangeFromCurrentDate(-1)
  );

  // Get org's logo url and start date since they aren't returned from the user's org data
  const {
    isLoading,
    data: orgData,
    isError,
    refetch: refetchOrgData
  } = useOrganization(selectedOrganization.id);

  useEffect(() => {
    if (isError) {
      pushAlert({
        title: 'Uh oh. Looks like there was an error loading your dashboard.',
        severity: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  // Refetch the org's data if the user selects a different org
  useEffect(() => {
    refetchOrgData();
  }, [refetchOrgData, selectedOrganization]);

  useEffect(() => {
    // TODO - LEGACY SUNSET - Remove this localStorage logic once legacy GMS is gone
    // Check for flag in localStorage to see if we need to redirect to /designated-funds/new
    const redirectToNew = window.localStorage.getItem(
      'redirectToNewDesignation'
    );
    if (redirectToNew && redirectToNew === 'true') {
      window.localStorage.removeItem('redirectToNewDesignation');
      navigate('/settings/designated-funds/new');
    }

    // TODO - LEGACY SUNSET - Remove this localStorage logic once legacy GMS is gone
    // Check for flag in localStorage to see if we need to redirect to /designated-funds/:id/edit
    const editId = window.localStorage.getItem('redirectToEditDesignation');
    if (editId) {
      window.localStorage.removeItem('redirectToEditDesignation');
      navigate(`/settings/designated-funds/${editId}/edit`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRangeSelect = (
    _event: React.MouseEvent<HTMLElement>,
    rangeSelection: number
  ) => {
    if (rangeSelection !== null) {
      setRangeToggleSelection(rangeSelection);
      setSelectedDateRange(getSelectedDateRangeFromCurrentDate(rangeSelection));
    }
  };

  const primaryButtonProps: ButtonProps = {
    children: 'Start New Campaign',
    startIcon: <Icon icon={ICONS.PLUS} />,
    onClick: () => navigate('/campaigns/new')
  };

  return (
    <Box className="dashboard-container">
      <Blocker block={isLoading}>
        <FeatureHeader
          titleProps={selectedOrganization.name}
          primaryButtonProps={primaryButtonProps}
          orgLogoUrl={orgData?.logoUrl}
          hoverThemeVariant
        />
        <div className="content-container fluid-container">
          <DashboardJumbo
            organizationId={selectedOrganization.id}
            selectedDateRange={selectedDateRange}
            handleRangeSelect={handleRangeSelect}
            selectedRangeValue={rangeToggleSelection}
            orgSupportsChapters={orgData?.features?.chapters}
            orgStartDate={orgData?.startDate}
          />

          <Grid container direction="row" columnSpacing={1.5} marginTop={1.5}>
            <DashboardCard isActiveCampaignsCard>
              <ActiveCampaignsCard
                selectedOrganizationId={selectedOrganization.id}
              />
            </DashboardCard>
            <DashboardCard>
              <RevenueByChannelCard
                organizationId={selectedOrganization.id}
                selectedDateRange={selectedDateRange}
                selectedRangeValue={rangeToggleSelection}
                orgStartDate={orgData?.startDate}
              />
            </DashboardCard>
          </Grid>
        </div>
      </Blocker>
    </Box>
  );
};
