import { Skeleton, Stack } from '@mui/material';
import clsx from 'clsx';
import Text from 'components/Text';
import { useAlerts } from 'hooks';
import { useOrgRevenueByChannel } from 'queries/UseOrganizationData';
import { DateRange } from 'utils';
import { numberFormatter, usdCurrencyFormatter } from 'utils/formatters';
import { CollapsedBarGraph } from '.';
import { DateRangeText } from '../DateRangeText';
import './RevenueByChannelCard.scss';

export interface RevenueByChannelCardProps {
  organizationId: string;
  selectedDateRange: DateRange;
  selectedRangeValue: number;
  // Optional start date string to override what's in `selectedDateRange` (used for 'All' range value)
  orgStartDate?: string | null;
}

export const RevenueByChannelCard = ({
  organizationId,
  selectedDateRange,
  selectedRangeValue,
  orgStartDate
}: RevenueByChannelCardProps) => {
  const { startDate, endDate } = selectedDateRange;
  const [pushAlert] = useAlerts();

  // TODO: Update revenue by channel endpoint to work with 'All' selected range option.
  // Currently expects a previous start and end date, which doesn't work for selecting an orgs entire data history
  const {
    data: channelRevenueData,
    isLoading: isLoadingChannelRevenue,
    isError
  } = useOrgRevenueByChannel(
    organizationId,
    selectedRangeValue === 0 ? orgStartDate : startDate.toISOString(),
    endDate.toISOString(),
    {
      onError: () => {
        pushAlert({
          title:
            'Uh oh. Looks like there was an error loading your channel revenue.',
          severity: 'error'
        });
      }
    }
  );

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text variant="h3">Revenue By Channel</Text>

        {!isError && (
          <DateRangeText
            selectedRangeValue={selectedRangeValue}
            startDate={
              selectedRangeValue === 0 ? new Date(orgStartDate) : startDate
            }
            endDate={endDate}
          />
        )}
      </Stack>

      <div
        className={clsx('revenue-table-container', {
          'is-error': isError
        })}
      >
        <CollapsedBarGraph channelRevenueData={channelRevenueData} />
        <div className="channel-row">
          <div className="row-label">
            <div className="channel-color" />
            <Text variant="h5" className="channel-type">
              Giving Forms
            </Text>
          </div>
          {isLoadingChannelRevenue ? (
            <Skeleton animation="wave" height={15} width="100%" />
          ) : (
            <div className="row-values">
              <Text variant="h5" className="total-amount">
                {usdCurrencyFormatter.format(
                  channelRevenueData?.givingForms?.totalAmount || 0
                )}
              </Text>
              <Text variant="h5" className="revenue-percentage">
                {numberFormatter.format(
                  channelRevenueData?.givingForms?.revenuePercentage || 0
                )}
                %
              </Text>
            </div>
          )}
        </div>
        <div className="channel-row">
          <div className="row-label">
            <div className="channel-color events" />
            <Text variant="h5" className="channel-type">
              Events
            </Text>
          </div>
          {isLoadingChannelRevenue ? (
            <Skeleton animation="wave" height={15} width="100%" />
          ) : (
            <div className="row-values">
              <Text variant="h5" className="total-amount">
                {usdCurrencyFormatter.format(
                  channelRevenueData?.events?.totalAmount || 0
                )}
              </Text>
              <Text variant="h5" className="revenue-percentage">
                {numberFormatter.format(
                  channelRevenueData?.events?.revenuePercentage || 0
                )}
                %
              </Text>
            </div>
          )}
        </div>
        <div className="channel-row">
          <div className="row-label">
            <div className="channel-color peer-to-peer" />
            <Text variant="h5" className="channel-type">
              Peer-To-Peer
            </Text>
          </div>
          {isLoadingChannelRevenue ? (
            <Skeleton animation="wave" height={15} width="100%" />
          ) : (
            <div className="row-values">
              <Text variant="h5" className="total-amount">
                {usdCurrencyFormatter.format(
                  channelRevenueData?.p2p?.totalAmount || 0
                )}
              </Text>
              <Text variant="h5" className="revenue-percentage">
                {numberFormatter.format(
                  channelRevenueData?.p2p?.revenuePercentage || 0
                )}
                %
              </Text>
            </div>
          )}
        </div>
        <div className="channel-row">
          <div className="row-label">
            <div className="channel-color text-to-give" />
            <Text variant="h5" className="channel-type">
              Text 2 Give
            </Text>
          </div>
          {isLoadingChannelRevenue ? (
            <Skeleton animation="wave" height={15} width="100%" />
          ) : (
            <div className="row-values">
              <Text variant="h5" className="total-amount">
                {usdCurrencyFormatter.format(
                  channelRevenueData?.sms?.totalAmount || 0
                )}
              </Text>
              <Text variant="h5" className="revenue-percentage">
                {numberFormatter.format(
                  channelRevenueData?.sms?.revenuePercentage || 0
                )}
                %
              </Text>
            </div>
          )}
        </div>
        <div className="channel-row">
          <div className="row-label">
            <div className="channel-color other" />
            <Text variant="h5" className="channel-type">
              Other
            </Text>
          </div>
          {isLoadingChannelRevenue ? (
            <Skeleton animation="wave" height={15} width="100%" />
          ) : (
            <div className="row-values">
              <Text variant="h5" className="total-amount">
                {usdCurrencyFormatter.format(
                  channelRevenueData?.other?.totalAmount || 0
                )}
              </Text>
              <Text variant="h5" className="revenue-percentage">
                {numberFormatter.format(
                  channelRevenueData?.other?.revenuePercentage || 0
                )}
                %
              </Text>
            </div>
          )}
        </div>
      </div>
    </Stack>
  );
};
