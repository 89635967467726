import { Controller, FieldValues } from 'react-hook-form';
import { AutocompleteElementProps } from 'react-hook-form-mui';
import ComboCheckBox from 'components/ComboCheckBox';
import {
  ComboCheckBoxProps,
  Option
} from 'components/ComboCheckBox/ComboCheckBox';

const ComboCheckboxElement = <TFieldValues extends FieldValues>({
  name,
  control,
  options,
  loading,
  label,
  onChange
}: Pick<
  AutocompleteElementProps<TFieldValues, Option, true, false>,
  'name' | 'control' | 'options' | 'loading'
> & {
  label: ComboCheckBoxProps['label'];
  onChange?: ComboCheckBoxProps['onChange'];
}) => (
  <Controller
    name={name}
    control={control}
    render={({
      field: { onChange: onControlChange, onBlur, ref, value, ...field },
      fieldState: { error }
    }) => (
      <ComboCheckBox
        {...field}
        defaultValue={value}
        loading={loading}
        options={options}
        error={!!error}
        helperText={error?.message}
        label={label}
        onChange={(newValue) => {
          if (onChange) {
            onChange(newValue);
          }
          onControlChange(newValue);
        }}
        onBlur={onBlur}
      />
    )}
  />
);

export default ComboCheckboxElement;
