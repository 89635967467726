import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import TextField, { LimitedTextField } from 'components/TextField';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useCreateGivingFormTemplateMutation } from 'queries/UseGivingForms';
import './SaveAsTemplateDialog.scss';

type SaveAsTemplateDialogProps = {
  isOpen: boolean;
  onClose?: () => void;

  /**
   * The giving form to base the template off of
   */
  givingFormId: string;
};

/**
 * A GMS-specific dialog for creating a new giving form template.
 * The user must input a name for the new giving form template before submitting.
 */
export const SaveAsTemplateDialog = ({
  isOpen: open,
  onClose,
  givingFormId
}: SaveAsTemplateDialogProps): JSX.Element => {
  const [templateName, setTemplateName] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');
  const [addAlert] = useAlerts();
  const [valid, setValid] = useState(true);
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!valid) {
      setValid(true);
    }
    setTemplateName(e.currentTarget.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!valid) {
      setValid(true);
    }
    setTemplateDescription(e.currentTarget.value);
  };

  const { selectedOrganization } = useAppContext();

  const organizationId = selectedOrganization?.id;

  const {
    mutateAsync: createGivingFormTemplate,
    isLoading: isCreatingGivingFormTemplate
  } = useCreateGivingFormTemplateMutation({
    organizationId,
    name: templateName,
    templateId: givingFormId,
    templateDescription
  });

  const createTemplate = async (close: () => void) => {
    await createGivingFormTemplate(undefined, {
      onSuccess: (data) => {
        addAlert({
          title: `The giving form template "${data.name}" was successfully created.`,
          severity: 'success'
        });
        close();
      },
      onError: (error) => {
        // this error is only thrown when trying to create the template with a duplicate name
        if (error.response?.status === 400) {
          setValid(false);
        } else {
          addAlert({
            title:
              'Uh oh. Looks like there was an error creating the giving form template.',
            severity: 'error'
          });
        }
      }
    });
  };

  return (
    <Dialog
      className="save-as-template-dialog"
      label="Save as Template Dialog"
      open={open}
      onClose={() => {
        setTemplateName('');
        setTemplateDescription('');
        setValid(true);
        onClose?.();
      }}
    >
      <DialogTitle onClick={(close) => close()} />
      <DialogContent>
        <DialogContentTitle>
          What are we naming this Template?
        </DialogContentTitle>
        <DialogContentDescription>
          To save this form as a template, we need to give it a unique name.
        </DialogContentDescription>
        <TextField
          value={templateName}
          name="name"
          placeholder="Enter Template Name"
          hiddenLabel
          onChange={handleNameChange}
          error={!valid}
          required
          helperText={
            !valid
              ? `"${templateName}" is already being used by another Giving Form or Giving Form Template in this campaign. Please pick a unique name.`
              : undefined
          }
          inputProps={{
            'aria-label': 'template name'
          }}
        />
        <LimitedTextField
          maxChar={200}
          multiline
          minRows={3}
          value={templateDescription}
          name="description"
          placeholder="Enter Template Description (Optional)"
          hiddenLabel
          onChange={handleDescriptionChange}
          aria-label="template description"
        />
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          variant="primary"
          onClick={createTemplate}
          disabled={templateName === '' || isCreatingGivingFormTemplate}
        >
          {isCreatingGivingFormTemplate ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Save Template'
          )}
        </DialogActionButton>
        <DialogActionButton variant="secondary" onClick={(close) => close()}>
          Cancel
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
