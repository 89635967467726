import { ChangeEventHandler } from 'react';
import { Box, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form';
import * as yup from 'yup';
import Text from 'components/Text';
import { LimitedTextField, useLimitedLength } from 'components/TextField';
import { useConfigContext } from 'hooks/useConfigContext';
import { IGivingFormConfig } from 'types';
import './EditFooter.scss';

const textMaxChars = 500;

const footerSchema = yup.object({
  text: yup
    .string()
    .max(textMaxChars, `Title cannot exceed ${textMaxChars} characters`)
    .typeError('Must enter valid title')
});

const EditFooter = (): JSX.Element => {
  const { control } = useFormContext();
  const { configData, updateConfig } = useConfigContext<IGivingFormConfig>();
  const [text, setText] = useLimitedLength(
    textMaxChars,
    configData.config.footer?.text
  );

  const handleTitleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setText(event.target.value);
  };

  const handleTitleBlur: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = async (event) => {
    footerSchema
      .validateAt('text', { text: event.target.value })
      .then((validValue) => {
        // AssertShape promise return is type of field being checked, but must be cast to unknown first
        const newText = validValue as unknown as string;
        const newConfig = { ...configData.config };
        if (newConfig.footer) {
          newConfig.footer.text = newText;
        }
        updateConfig(newConfig);
      })
      .catch(() => undefined); // No need to handle, just preventing uncaught error
  };

  return (
    <Stack id="ElementLibrary-EditFooter">
      <Box id="ElementLibrary-EditFooter--message">
        <Text variant="h3">Footer Message</Text>
        <Controller
          name="text"
          control={control}
          defaultValue={text ?? ''}
          render={({
            field: { ref, onChange, onBlur, ...field },
            fieldState: { error }
          }) => (
            <LimitedTextField
              {...field}
              hiddenLabel
              fullWidth
              multiline
              minRows={10}
              maxChar={textMaxChars}
              error={!!error}
              onChange={(event) => {
                onChange(event.target.value.substring(0, textMaxChars));
                handleTitleChange(event);
              }}
              onBlur={(event) => {
                onBlur();
                handleTitleBlur(event);
              }}
            />
          )}
        />
      </Box>
    </Stack>
  );
};

export default (): JSX.Element => {
  const methods = useForm({
    resolver: yupResolver(footerSchema),
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <EditFooter />
    </FormProvider>
  );
};
