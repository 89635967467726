import { TextFieldProps } from '@mui/material';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import TextField from '.';

export type NumberTextFieldProps = Omit<
  NumericFormatProps<TextFieldProps>,
  'onChange'
> & {
  onChange?: (value: string) => void;
};

const NumberTextField = ({
  onChange,
  ...props
}: NumberTextFieldProps): JSX.Element => (
  <NumericFormat
    onValueChange={(values) => onChange?.(values.value)}
    customInput={TextField}
    {...props}
  />
);
export default NumberTextField;
