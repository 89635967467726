import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import './DiscardAbTestDraftDialog.scss';

type DiscardAbTestDraftDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onDiscardDraftConfirmClick: () => void;
};

export const DiscardAbTestDraftDialog = ({
  isOpen,
  onClose,
  onDiscardDraftConfirmClick
}: DiscardAbTestDraftDialogProps) => (
  <Dialog
    className="discard-ab-test-draft"
    label="Discard A/B Test Draft Modal"
    open={isOpen}
    onClose={() => onClose()}
  >
    <DialogTitle
      className="discard-ab-test-draft-title"
      onClick={(close) => close()}
    >
      Discard Draft
    </DialogTitle>
    <DialogContent>
      <DialogContentTitle>Are you sure?</DialogContentTitle>
      <DialogContentDescription className="discard-ab-test-draft-content">
        If you discard this draft, you will not be able to access it again. Do
        you wish to continue?
      </DialogContentDescription>
    </DialogContent>
    <DialogActions>
      <DialogActionButton
        variant="primary"
        onClick={() => onDiscardDraftConfirmClick()}
      >
        Yes, Discard Draft
      </DialogActionButton>
      <DialogActionButton onClick={(close) => close()} variant="secondary">
        No, Back to A/B Tests
      </DialogActionButton>
    </DialogActions>
  </Dialog>
);
