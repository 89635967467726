import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Text from 'components/Text';

export interface MenuItemProps extends MuiMenuItemProps {
  label: string;
  path?: string;
  onClick?: () => void;
}

const MenuItem = ({
  label,
  path,
  onClick,
  ...args
}: MenuItemProps): JSX.Element => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path) {
      navigate(path);
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <MuiMenuItem onClick={() => handleClick()} {...args}>
      <Text variant="caption">{label}</Text>
    </MuiMenuItem>
  );
};

export default MenuItem;
