import { Dispatch, SetStateAction, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useQueryClient } from 'react-query';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import { LimitedTextField } from 'components/TextField';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useCreateDesignationGroupMutation } from 'queries/UseDesignations';
import { DesignationGroup } from 'services';
import './AdvancedDesignationsModals.scss';

export interface NewDesignationsGroupModalProps {
  allGroupsForOrg: DesignationGroup[];
  isOpen: boolean;
  onClose: () => void;
  selectedGroups: DesignationGroup[];
  setSelectedGroups: Dispatch<SetStateAction<DesignationGroup[]>>;
  setFieldToTouched: () => void;
}

export const NewDesignationsGroupModal = ({
  allGroupsForOrg,
  isOpen,
  onClose,
  selectedGroups,
  setSelectedGroups,
  setFieldToTouched
}: NewDesignationsGroupModalProps) => {
  const [groupName, setGroupName] = useState<string>('');
  const [isUniqueGroupName, setIsUniqueGroupName] =
    useState<boolean>(undefined);
  const [pushAlert] = useAlerts();
  const queryClient = useQueryClient();
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();

  const { mutateAsync: createDesignationGroupMutation, isLoading } =
    useCreateDesignationGroupMutation(organizationId, {
      onSuccess: (newDesignationGroup: DesignationGroup) => {
        setSelectedGroups([...selectedGroups, newDesignationGroup]);
        queryClient.invalidateQueries('getDesignationGroups');
        setFieldToTouched();
        onClose();
      },
      onError: () => {
        pushAlert({
          severity: 'error',
          title: 'There was an error creating your designation group'
        });
      }
    });

  const allDesignationGroupNames = allGroupsForOrg.map((group) =>
    group.name.toLowerCase()
  );

  const checkGroupNameIsUnique = () => {
    const index = allDesignationGroupNames.indexOf(groupName.toLowerCase());
    if (index === -1) {
      setIsUniqueGroupName(true);
      return true;
    }

    setIsUniqueGroupName(false);
    return false;
  };

  const handleChangeGroupName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGroupName(event.target.value);
    setIsUniqueGroupName(undefined);
  };

  const setHelperText = () => {
    let helperText = '';
    if (isUniqueGroupName === false) {
      helperText = `"${groupName}" already exists. Please use a unique name, or select the existing group name within your designated fund.`;
    }
    return helperText;
  };

  const handleOnSubmit = () => {
    const validName = checkGroupNameIsUnique();
    if (!validName) return;
    createDesignationGroupMutation(groupName);
  };

  const handleOnClose = () => {
    onClose();
    setIsUniqueGroupName(undefined);
    setGroupName('');
  };

  return (
    <Dialog
      label="new designations group modal"
      onClose={handleOnClose}
      open={isOpen}
      className="new-designations-group-modal"
    >
      <DialogTitle
        className="new-designations-group-modal-title"
        onClick={handleOnClose}
      >
        Add New Group
      </DialogTitle>
      <DialogContent className="new-designations-group-modal-content">
        <DialogContentTitle className="new-designations-group-modal-content-title">
          Create a new group with a unique name
        </DialogContentTitle>
        <div className="new-designations-group-modal-input-container">
          <LimitedTextField
            helperText={setHelperText()}
            error={isUniqueGroupName === false}
            fullWidth
            maxChar={60}
            onChange={handleChangeGroupName}
            placeholder="Enter Group Name"
            value={groupName}
          />
        </div>
      </DialogContent>
      <DialogActions className="new-designations-group-modal-actions-container">
        <DialogActionButton
          className="new-designations-group-modal-confirm"
          onClick={() => handleOnSubmit()}
          disabled={!groupName || isUniqueGroupName === false}
          variant="primary"
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Create Group'
          )}
        </DialogActionButton>
        <DialogActionButton onClick={handleOnClose} variant="secondary">
          Cancel
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
