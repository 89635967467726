import { ReactNode } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BrowserRouter } from 'react-router-dom';
import { AlertProvider } from 'hooks';
import { AppProvider } from './Providers/AppProvider';
import { ReactQuery } from './Providers/QueryClientProvider';
import { theTheme } from './theme';

type ProvidersProps = {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  envConfig: Record<string, any>;
};

const Providers = ({ children, envConfig }: ProvidersProps): JSX.Element => (
  <BrowserRouter>
    <ReactQuery>
      <AppProvider envConfig={envConfig}>
        <ThemeProvider theme={theTheme}>
          <CssBaseline />
          <AlertProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {children}
            </LocalizationProvider>
          </AlertProvider>
        </ThemeProvider>
      </AppProvider>
    </ReactQuery>
  </BrowserRouter>
);

export default Providers;
