import { FunctionComponent } from 'react';
import {
  Box,
  ButtonUnstyled,
  ButtonUnstyledProps,
  Icon as MuiIcon,
  Stack
} from '@mui/material';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { useConfigContext } from 'hooks/useConfigContext';
import { ElementLibraryEmailViews } from 'types';
import { EmailConfig } from 'types/emailTypes';
import './RootMenu.scss';

const RootViewMenuItem = ({
  children,
  icon,
  ...props
}: ButtonUnstyledProps & { icon: FunctionComponent }) => (
  <Box className="ElementLibrary-EmailRootMenu--item">
    <ButtonUnstyled {...props}>
      <Stack
        className="ElementLibrary-EmailRootMenu--item-container"
        direction="row"
      >
        <MuiIcon component={icon} />
        <Text variant="h4">{children}</Text>
        <Icon icon={ICONS.CHEVRON_RIGHT} />
      </Stack>
    </ButtonUnstyled>
  </Box>
);
const RootViewContent = ({
  setView
}: {
  setView: (view: ElementLibraryEmailViews) => void;
}): JSX.Element => {
  const { setHighlightedBlock } = useConfigContext<EmailConfig>();

  return (
    <Stack id="ElementLibrary-EmailRootMenu">
      <Box>
        <Text variant="h3">Commonly Used</Text>
        <RootViewMenuItem
          onClick={() => {
            setHighlightedBlock('header');
            setView(ElementLibraryEmailViews.Header);
          }}
          icon={ICONS.LAYOUT_NAVBAR}
        >
          Header
        </RootViewMenuItem>
        <RootViewMenuItem
          onClick={() => {
            setView(ElementLibraryEmailViews.CustomContent);
          }}
          icon={ICONS.MESSAGE2}
        >
          Custom Content
        </RootViewMenuItem>
        <RootViewMenuItem
          onClick={() => {
            setView(ElementLibraryEmailViews.EmailDetails);
          }}
          icon={ICONS.MAIL_OPENED}
        >
          Email Subject
        </RootViewMenuItem>
      </Box>
    </Stack>
  );
};

export default RootViewContent;
