import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { UseMutateAsyncFunction, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Blocker } from 'components';
import Breadcrumbs from 'components/Breadcrumbs';
import Text from 'components/Text';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import {
  useCreateDesignationMutation,
  useDesignations,
  useToggleDesignationStatusMutation,
  useUpdateDesignationMutation
} from 'queries/UseDesignations';
import { Designation } from 'services';
import { AdvancedDesignations } from './AdvancedDesignations';
import { DesignatedFunds } from './DesignatedFunds';
import './Designations.scss';

export interface DesignationsProps {
  allDesignationsInOrg: Designation[];
  allDesignationsIsLoading: boolean;
  checkFundIdIsUnique: (code: string) => boolean;
  confirmOverwriteModalIsOpen: boolean;
  createDesignationMutation: UseMutateAsyncFunction<
    Designation,
    unknown,
    Designation,
    unknown
  >;
  designationIdParam: string | undefined;
  idTooltip: string;
  organizationId: string;
  renderButtonContent: () => ReactNode;
  setConfirmOverwriteModalIsOpen: Dispatch<SetStateAction<boolean>>;
  setCurrentFundId: Dispatch<SetStateAction<string>>;
  updateDesignationMutation: UseMutateAsyncFunction<
    Designation,
    unknown,
    Designation,
    unknown
  >;
  toggleDesignationStatus: UseMutateAsyncFunction<
    void,
    unknown,
    boolean,
    unknown
  >;
}

export const Designations = ({
  orgInfoIsLoading
}: {
  orgInfoIsLoading: boolean;
}) => {
  // when editing, keep track of the current code/fund ID so that
  // we can exclude it from the duplicates check
  const [currentFundId, setCurrentFundId] = useState<string>(null);
  const [confirmOverwriteModalIsOpen, setConfirmOverwriteModalIsOpen] =
    useState(false);
  const { designationId: designationIdParam } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [pushAlert] = useAlerts();
  const idTooltip =
    'Used for internal reporting so that you can track the different designations. A value may only be used by a single designation (even if disabled). Special characters (@#) are not allowed.';

  const {
    selectedOrganization: { id: organizationId },
    organizationInfo
  } = useAppContext();

  const { data: allDesignationsInOrg, isLoading: allDesignationsIsLoading } =
    useDesignations({
      organizationId,
      isActiveFilter: false,
      refetchOnWindowFocus: true
    });

  const allDesignationFundIds = allDesignationsInOrg?.map((d) =>
    d.code?.toLowerCase()
  );

  const { mutateAsync: createDesignationMutation, isLoading: createIsLoading } =
    useCreateDesignationMutation(organizationId, {
      onSuccess: (designation: Designation) => {
        queryClient.invalidateQueries('getDesignations');
        navigate('/settings/designated-funds');
      },
      onError: () => {
        pushAlert({
          severity: 'error',
          title: 'There was an error creating your designation'
        });
      }
    });

  const { mutateAsync: updateDesignationMutation, isLoading: updateIsLoading } =
    useUpdateDesignationMutation(organizationId, designationIdParam, {
      onSuccess: (designation: Designation) => {
        queryClient.invalidateQueries('getDesignations');
        navigate('/settings/designated-funds');
      },
      onError: () => {
        pushAlert({
          severity: 'error',
          title: 'There was an error updating your designation'
        });
      }
    });

  const { mutateAsync: toggleDesignationStatus } =
    useToggleDesignationStatusMutation(organizationId, designationIdParam, {
      onSuccess: () => {
        queryClient.invalidateQueries('getDesignations');
        navigate('/settings/designated-funds');
      },
      onError: (err: Error) => {
        pushAlert({
          severity: 'error',
          title: err.message
        });
      }
    });

  const checkFundIdIsUnique = (code: string) => {
    let index;
    if (currentFundId) {
      // check the code/fund ID from form data against all except the current fund ID
      index = allDesignationFundIds
        .filter((id) => id !== currentFundId)
        .indexOf(code);
    } else {
      // if no current fund ID, we are creating a new designation and need to check against all fund IDs
      index = allDesignationFundIds.indexOf(code.toLowerCase());
    }
    if (index === -1) {
      return true;
    }
    return false;
  };

  const renderButtonContent = () => {
    if (createIsLoading || updateIsLoading) {
      return <CircularProgress color="inherit" size={24} />;
    }
    if (designationIdParam) {
      return 'Update Designation';
    }
    return 'Create Designation';
  };

  const breadcrumbs = [
    {
      label: 'Designations',
      path: '/settings/designated-funds'
    },
    {
      label: designationIdParam ? 'Edit Designation' : 'New Designation',
      path: '' // don't need a path on this breadcrumb because it's not clickable
    }
  ];

  const renderForm = (): ReactNode => {
    if (organizationInfo?.features?.advancedDesignations) {
      return (
        <AdvancedDesignations
          allDesignationsInOrg={allDesignationsInOrg}
          allDesignationsIsLoading={allDesignationsIsLoading}
          checkFundIdIsUnique={checkFundIdIsUnique}
          confirmOverwriteModalIsOpen={confirmOverwriteModalIsOpen}
          createDesignationMutation={createDesignationMutation}
          designationIdParam={designationIdParam}
          idTooltip={idTooltip}
          organizationId={organizationId}
          renderButtonContent={renderButtonContent}
          setConfirmOverwriteModalIsOpen={setConfirmOverwriteModalIsOpen}
          setCurrentFundId={setCurrentFundId}
          updateDesignationMutation={updateDesignationMutation}
          toggleDesignationStatus={toggleDesignationStatus}
        />
      );
    }
    return (
      <DesignatedFunds
        allDesignationsInOrg={allDesignationsInOrg}
        allDesignationsIsLoading={allDesignationsIsLoading}
        checkFundIdIsUnique={checkFundIdIsUnique}
        confirmOverwriteModalIsOpen={confirmOverwriteModalIsOpen}
        createDesignationMutation={createDesignationMutation}
        designationIdParam={designationIdParam}
        idTooltip={idTooltip}
        organizationId={organizationId}
        renderButtonContent={renderButtonContent}
        setConfirmOverwriteModalIsOpen={setConfirmOverwriteModalIsOpen}
        setCurrentFundId={setCurrentFundId}
        updateDesignationMutation={updateDesignationMutation}
        toggleDesignationStatus={toggleDesignationStatus}
      />
    );
  };

  return (
    <div>
      <div className="designations-header">
        <div className="designations-breadcrumbs">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <Text variant="h2" color="primary">
          {designationIdParam ? '' : 'New '} Designated Fund
        </Text>
        <Text variant="h1">What are the details of your designation?</Text>
      </div>
      {orgInfoIsLoading ? (
        <Blocker block={orgInfoIsLoading}>
          <div />
        </Blocker>
      ) : (
        renderForm()
      )}
    </div>
  );
};
