import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import MultiselectCheckbox, {
  MultiselectProps
} from 'components/Select/MultiselectCheckbox';
import { TributeDataCollectionField } from 'hooks/useTributeDataCollection';

const MultiselectCheckboxElement = <T extends FieldValues>({
  control,
  name,
  ...otherProps
}: MultiselectProps & {
  control?: Control<T>;
  name: Path<T>;
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <MultiselectCheckbox
        {...otherProps}
        defaultValue={field.value}
        onChange={(event) =>
          field.onChange(event.target.value as TributeDataCollectionField[])
        }
      />
    )}
  />
);

export default MultiselectCheckboxElement;
