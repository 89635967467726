import { ZoomEmitValues } from 'components/gms/HostedPageEditor';
import { FormDataType } from 'hooks/useConfigContext';
import { EditorEventTypes, EventHub } from 'services';
import {
  EditorTypes,
  ElementLibraryEmailViewMap,
  ElementLibraryEmailViews,
  ElementLibraryGivingFormViewMap,
  ElementLibraryGivingFormViews,
  ElementLibraryHostedPageViewMap,
  ElementLibraryHostedPageViews,
  EmailConfig,
  IGivingFormConfig
} from 'types';
import { getHostedPageBlockDataById } from './hostedPageBlockUtils';

export const getLibraryViewFromBlockId = (
  blockId: string,
  editorType: EditorTypes,
  configData: FormDataType<IGivingFormConfig> | FormDataType<EmailConfig>
) => {
  // Handling for Giving Form and Thank You Editor views:
  if (editorType === EditorTypes.GivingForm) {
    if (blockId === 'header') {
      return ElementLibraryGivingFormViews.HeaderView;
    }
    if (blockId === 'footer') {
      return ElementLibraryGivingFormViews.FooterView;
    }
    const givingFormConfig = configData as FormDataType<IGivingFormConfig>;

    const givingFormBlockType = [
      ...(givingFormConfig?.config?.blocks || []),
      ...(givingFormConfig?.config?.thankYouConfig?.blocks || [])
    ]?.find((block) => block.id === blockId)?.blockType;

    const newGivingFormLibraryView = givingFormBlockType
      ? ElementLibraryGivingFormViewMap[givingFormBlockType]
      : ElementLibraryGivingFormViews.RootView;
    return newGivingFormLibraryView;
  }

  // Handling for Hosted Page Editor views:
  if (editorType === EditorTypes.HostedPage) {
    if (blockId === 'header') {
      return ElementLibraryHostedPageViews.HeaderView;
    }

    const hpBlockType = getHostedPageBlockDataById(
      blockId,
      configData.config as IGivingFormConfig
    )?.block.blockType;

    const newHostedPageLibraryView = hpBlockType
      ? ElementLibraryHostedPageViewMap[hpBlockType]
      : ElementLibraryHostedPageViews.RootView;
    return newHostedPageLibraryView;
  }

  // Handling for Email Editor views:
  // function currently defaults to returning Email Views if editorType is not Giving Form
  // will need to add additional else if check if additional editors will utilize this function
  if (blockId === 'header') {
    return ElementLibraryEmailViews.Header;
  }
  const emailConfig = configData as FormDataType<EmailConfig>;

  const emailBlockType = [...(emailConfig?.config?.blocks || [])]?.find(
    (block) => block.id === blockId
  )?.blockType;

  const newEmailLibraryView = emailBlockType
    ? ElementLibraryEmailViewMap[emailBlockType]
    : ElementLibraryEmailViews.RootView;
  return newEmailLibraryView;
};

export const emitSelect = (
  blockId: string,
  eventHub: EventHub,
  config?: IGivingFormConfig
) => {
  eventHub?.emit(EditorEventTypes.EditBlock, { id: blockId, config });
};

export const emitDeselect = (eventHub: EventHub) => {
  eventHub?.emit(EditorEventTypes.DeselectBlock, {});
};

export const emitSetPreviewGivingForm = (eventHub: EventHub) => {
  eventHub?.emit(EditorEventTypes.SetPreviewGivingForm, {});
};

export const emitSetPreviewThankYou = (eventHub: EventHub) => {
  eventHub?.emit(EditorEventTypes.SetPreviewThankYou, {});
};

export const emitZoomLevel = (
  zoomInstruction: ZoomEmitValues,
  eventHub: EventHub
) => {
  eventHub?.emit(EditorEventTypes.PreviewZoom, { zoomInstruction });
};

export const emitOrgInfo = (
  eventHub: EventHub,
  orgInfo: { orgName: string; orgLogoUrl: string }
) => {
  eventHub?.emit(EditorEventTypes.OrgInfoUpdate, { orgInfo });
};
