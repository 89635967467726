import { Skeleton } from '@mui/material';

export const AbTestNoteSkeleton = () => (
  <div>
    <div className="skeleton-note-container">
      <div className="skeleton-note-publish-info">
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </div>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </div>
  </div>
);
