import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import './ConfirmDefaultEmailDialog.scss';

type ConfirmDefaultEmailDialogProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const ConfirmDefaultEmailDialog = ({
  isOpen,
  onConfirm,
  onClose
}: ConfirmDefaultEmailDialogProps) => (
  <Dialog
    open={isOpen}
    label="Confirm Default Receipt"
    onClose={onClose}
    className="confirm-default-email-modal"
  >
    <DialogTitle
      onClick={(close) => close()}
      className="confirm-default-email-modal-title"
    >
      Update Default Receipt
    </DialogTitle>
    <DialogContent>
      <DialogContentTitle>
        Are you sure you want to use this receipt as the default receipt?
      </DialogContentTitle>
      <DialogContentDescription>
        This receipt will be used for all new giving forms created from scratch.
        It does not affect giving forms copied from templates or existing giving
        forms.
      </DialogContentDescription>
    </DialogContent>
    <DialogActions>
      <DialogActionButton
        onClick={(close) => {
          onConfirm();
          close();
        }}
        variant="primary"
      >
        Yes, Use Receipt As Default
      </DialogActionButton>
      <DialogActionButton onClick={(close) => close()} variant="secondary">
        No, Use Existing Default
      </DialogActionButton>
    </DialogActions>
  </Dialog>
);
