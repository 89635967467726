import Text from 'components/Text';
import './EmptyStateTextDisplay.scss';

export interface EmptyStateTextDisplayProps {
  heading: string;
  subheading?: string;
}

export const EmptyStateTextDisplay = ({
  heading,
  subheading
}: EmptyStateTextDisplayProps): JSX.Element => (
  <div className="empty-state-text-display">
    <div className="empty-state-bar" />
    <Text variant="h2" className="empty-state-text heading">
      {heading}
    </Text>
    {subheading && (
      <Text variant="body" className="empty-state-text">
        {subheading}
      </Text>
    )}
  </div>
);
