import { Chip } from '@mui/material';
import clsx from 'clsx';
import './AbTestStatusChip.scss';

type AbTestStatusChipProps = {
  endedAt: string;
  hasDraft: boolean;
};

enum AbTestStatus {
  InProgress = 'Test In Progress',
  Ended = 'Test Ended',
  Draft = 'Draft'
  // Add other statuses here when implemented.
  // i.e ThresholdMet - LC
}

const getAbTestStatus = (endedDate: string, hasDraft: boolean): string => {
  if (endedDate) {
    return AbTestStatus.Ended;
  }
  if (hasDraft) {
    return AbTestStatus.Draft;
  }

  return AbTestStatus.InProgress;
};

export const AbTestStatusChip = ({
  endedAt,
  hasDraft
}: AbTestStatusChipProps) => {
  const status = getAbTestStatus(endedAt, hasDraft);

  return (
    <Chip
      className={clsx({
        'abtest-status-chip-in-progress': status === AbTestStatus.InProgress,
        'abtest-status-chip-ended': status === AbTestStatus.Ended,
        'abtest-status-chip-draft': status === AbTestStatus.Draft
      })}
      label={status}
    />
  );
};
