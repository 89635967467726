import { useState } from 'react';
import { Skeleton } from '@mui/material';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useGetAllAbTests } from 'queries/UseAbTests';
import './DashboardBadge.scss';

export const DashboardBadge = () => {
  const [activeAbTests, setActiveAbTests] = useState<number>(0);
  const {
    selectedOrganization: { id: selectedOrganizationId }
  } = useAppContext();

  const [pushAlert] = useAlerts();
  const { isLoading, isRefetching } = useGetAllAbTests(
    { organizationId: selectedOrganizationId, includeMetrics: true },
    {
      onError: () => {
        pushAlert({
          title: 'Uh oh. Looks like there was an error loading your A/B tests.',
          severity: 'error'
        });
      },
      onSuccess: (data) => {
        setActiveAbTests(data.filter((test) => !test.endedAt).length);
      },
      refetchOnMount: 'always'
    }
  );

  return isLoading || isRefetching ? (
    <Skeleton className="dashboard-badge-loading" />
  ) : (
    <div className="dashboard-badge">
      <div className="dashboard-badge-text-wrapper">
        <Icon icon={ICONS.COPY_DASHED} />
        <Text className="dashboard-badge-text" variant="h6">
          {`${pluralize('test', activeAbTests, true)} running`}
        </Text>
      </div>
      <Link
        className="dashboard-badge-link"
        to={activeAbTests ? '/strategy/ab-test' : '/strategy'}
      >
        <Text variant="h6">{activeAbTests ? 'View' : 'Learn More'}</Text>
      </Link>
    </div>
  );
};
