import { Box, Stack } from '@mui/material';
import ColorPicker from 'components/ColorPicker';
import FontSelect from 'components/EditorMenus/FontSelect';
import { ICONS } from 'components/EditorMenus/FontSelect/FontIcons';
import 'components/EditorMenus/GivingFormLookAndFeelMenu/GivingFormLookAndFeelMenu';
import Text from 'components/Text';
import { EmailFontOptions } from 'types';

const fontOptions = [
  {
    value: EmailFontOptions.Helvetica,
    icon: ICONS.HELVETICA
  },
  {
    value: EmailFontOptions.LibreBaskerville,
    icon: ICONS.LIBREBASKERVILLE
  },
  {
    value: EmailFontOptions.TimesNewRoman,
    icon: ICONS.TIMESNEWROMAN
  }
];

export interface EmailLookAndFeelMenuTypes {
  startingBackgroundColor: string;
  updateBackgroundColor(newColor: string): void;
  startingTextColor: string;
  updateTextColor(newColor: string): void;
  startingFont: EmailFontOptions;
  updateFont(newFont: EmailFontOptions): void;
}

export const EmailLookAndFeelMenu = ({
  startingBackgroundColor,
  updateBackgroundColor,
  startingTextColor,
  updateTextColor,
  startingFont,
  updateFont
}: EmailLookAndFeelMenuTypes): JSX.Element => (
  <Box className="ElementLibraryMenu-container">
    <Box className="ElementLibraryMenu-Header">
      <Text variant="h2" className="ElementLibraryMenu-Header__title">
        Look &amp; Feel
      </Text>
    </Box>
    <Box className="ElementLibraryMenu-content--container">
      <Stack className="LookAndFeelMenu">
        <Text variant="h3">Background Color</Text>
        <ColorPicker
          startingColor={startingBackgroundColor}
          onChange={updateBackgroundColor}
        />
        <Text variant="h3">Text Color</Text>
        <ColorPicker
          startingColor={startingTextColor}
          onChange={updateTextColor}
        />
        <FontSelect<EmailFontOptions>
          startingFont={startingFont}
          updateFont={updateFont}
          fontOptions={fontOptions}
        />
      </Stack>
    </Box>
  </Box>
);
