import { Skeleton, Stack } from '@mui/material';
import Text from 'components/Text';
import { useAlerts } from 'hooks';
import { OrganizationRole, useRenderIfRole } from 'hooks/useRenderIfRole';
import { useCampaigns } from 'queries/UseCampaigns';
import { useOrganization } from 'queries/UseOrganizationData';
import { CampaignType } from 'services';
import { DashboardBadge } from '../DashboardBadge';
import './ActiveCampaignsCard.scss';
import { ActiveCampaignsCardItem } from './ActiveCampaignsCardItem/ActiveCampaignsCardItem';

type ActiveCampaignsCardProps = {
  selectedOrganizationId: string;
};

export const ActiveCampaignsCard = ({
  selectedOrganizationId
}: ActiveCampaignsCardProps): JSX.Element => {
  const { renderIfRole } = useRenderIfRole();
  const { data: orgData } = useOrganization(selectedOrganizationId);
  const [pushAlert] = useAlerts();
  const { isLoading, data } = useCampaigns(selectedOrganizationId, {
    onError: () => {
      pushAlert({
        title:
          'Uh oh. Looks like there was an error loading your active campaigns.',
        severity: 'error'
      });
    }
  });

  const activeCampaigns =
    !isLoading &&
    data.length > 0 &&
    data
      .filter(
        (campaign: CampaignType) =>
          campaign.isActive === true && !campaign.isArchived
      )
      .sort((campaignA: CampaignType, campaignB: CampaignType) =>
        campaignA.title.localeCompare(campaignB.title, 'en', { numeric: true })
      );

  return (
    <>
      <div className="active-campaigns-header-wrapper">
        <Text variant="h3" className="active-campaigns-card-title">
          Active Campaigns ({isLoading ? '-' : activeCampaigns.length || 0})
        </Text>
        {orgData?.features?.abTesting &&
          renderIfRole(<DashboardBadge />, OrganizationRole.Editor)}
      </div>
      <div className="active-campaigns-card">
        {isLoading && (
          <Stack className="active-campaigns-card-skeleton-container">
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Stack>
        )}
        {activeCampaigns.length > 0 &&
          !isLoading &&
          activeCampaigns?.map((campaign: CampaignType) => (
            <ActiveCampaignsCardItem key={campaign.id} campaign={campaign} />
          ))}
        {!activeCampaigns.length && !isLoading && (
          <Stack className="active-campaigns-card-empty-container">
            <span className="active-campaigns-card-empty-divider" />
            <Text variant="h3" className="active-campaigns-card-empty-text">
              You don’t currently have any active campaigns to display.
            </Text>
          </Stack>
        )}
      </div>
    </>
  );
};
