import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Subject, Subscription } from 'rxjs';
import { useAlerts } from 'hooks';
import EventHub, { EditorEventTypes } from 'services/EventHub';
import EditsRepository from 'services/editsRepositoryService';

/**
 * Type for context data.
 */
export type FormDataType<T> = {
  /**
   * The config object holding the configuration object.
   */
  config: T;
  /**
   * The Date object representation of the last time the user's edits were saved for this form.
   */
  editsSavedTime?: Date;
  /**
   * The Date object representation of the last updated time of the config from MongoDB
   */
  seedConfigPublishedAt: Date;
  /**
   * The metadata name of the config object
   */
  name: string;
  /**
   * ID of the receipt selected for this giving form
   */
  receiptId?: string;
  /**
   * Boolean from MongoDB indicating whether the giving form has been published
   * Optional because emails do not have an isPublished flag
   */
  isPublished?: boolean;
  /**
   * Boolean from MongoDB indicating whether the giving form has been selected as the giving form used for SMS transaction processing.
   */
  isSmsForm?: boolean;
};

/**
 * Representation of the API for interfacing with the context.
 */
type ConfigContextType<T> = {
  configData: FormDataType<T>;
  setEventHub(eventHub: EventHub): void;
  updateConfig(newConfig: T): Promise<void>;
  handleSetForm(form: FormDataType<T>): void;
  updateName(name: string): void;
  updateIsSmsForm(isSmsForm: boolean): void;
  discardEdits(date?: Date): void;
  copyConfigToNewDraft(
    toId: string,
    newSeedConfigPublishedAtTime: Date,
    newName: string
  ): void;
  highlightedBlock: string;
  setHighlightedBlock: Dispatch<SetStateAction<string>>;
  receiptId: string;
  updateReceiptId(receiptId: string): void;
  isPublished?: boolean;
  toggleRecurringGiftPrompt(isEnabled: boolean): void;
};
export const ConfigContext = createContext<ConfigContextType<unknown> | null>(
  null
);

/**
 * Internal context component for managing the RxJS subject
 */
interface IConfigSubjectManager<T> {
  addSubscription: (callback: (v: FormDataType<T>) => void) => Subscription;
  updateValue: (value: FormDataType<T>) => void;
}
const ConfigSubjectManager = <T,>(): IConfigSubjectManager<T> => {
  const editsObjectSubject = new Subject<FormDataType<T>>();

  const addSubscription = (nextCallback: (v: FormDataType<T>) => void) =>
    editsObjectSubject.subscribe({
      next: nextCallback
    });

  const updateValue = (value: FormDataType<T>) => {
    editsObjectSubject.next(value);
  };

  return { addSubscription, updateValue };
};

/**
 * A hook for interfacing with the config. Bring this hook into your component to be able
 * to easily access the managed config or related metadata. Directly interfaces with
 * the `Config Context`
 */
export const useConfigContext = <T,>(
  initialForm?: FormDataType<T>
): ConfigContextType<T> => {
  // Prevent initial config from being set multiple times:
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('Something went wrong. No context found for this config.');
  }
  // Only ever use the initial form data once.  Can still manually override when needed with `handleSetForm`
  if (initialForm && !isInitialized) {
    setIsInitialized(true);

    context.handleSetForm(initialForm);
  }

  return context as ConfigContextType<T>;
};

/**
 * Typing for `ConfigContextProvider` props.
 * @param id The unique ID of the config, used to save to the edits repository.
 * @param type The type for form, e.g. "givingForm".  Used to save to the edits repository.
 */
type ConfigProviderProps = {
  children: React.ReactNode;
  id: string;
  type: string;
};

/**
 * A wrapper for `ConfigContext.Provider`. This component should be rendered high enough in the component
 * tree so all relevant children components are able to access the API it exposes. `ConfigContextProvider` holds
 * the state of the config and provides utility functions to easily update the value.
 */
export const ConfigContextProvider = <T,>({
  children,
  id,
  type
}: ConfigProviderProps): JSX.Element => {
  // State items exposed through context.  Setters are internal:
  // Set initial value to `{} as T` for initialization.  Should be overwritten once initial value is loaded
  const [config, setConfig] = useState<T>({} as T);
  const [editsSavedTime, setEditsSavedTime] = useState<Date>();
  const [seedConfigPublishedAtTime, setSeedConfigPublishedAtTime] =
    useState<Date>();
  const [name, setName] = useState<string>('');
  const [isSmsForm, setIsSmsForm] = useState<boolean>();
  const [receiptId, setReceiptId] = useState<string>('');
  const [isPublished, setIsPublished] = useState<boolean>();
  const [highlightedBlock, setHighlightedBlock] = useState<string>('');
  // Used internally:
  const [{ addSubscription, updateValue }] = useState<IConfigSubjectManager<T>>(
    ConfigSubjectManager()
  );
  const [eventHub, setEventHub] = useState<EventHub>();
  const [addAlert] = useAlerts();

  const handleUpdatedConfig = (updatedConfigObject: FormDataType<T>) => {
    setEditsSavedTime(updatedConfigObject.editsSavedTime);
    setSeedConfigPublishedAtTime(updatedConfigObject.seedConfigPublishedAt);
    setName(updatedConfigObject.name);
    setIsSmsForm(updatedConfigObject.isSmsForm);
    setConfig(updatedConfigObject.config);
    setReceiptId(updatedConfigObject.receiptId);
    setIsPublished(updatedConfigObject.isPublished);
    eventHub?.emit(
      EditorEventTypes.ConfigurationUpdate,
      updatedConfigObject.config
    );
  };

  /**
   * This function is used to set a form object, e.g. on initial load or after
   * a soft-refresh.  This will set all the appropriate `FormDataType` fields,
   * emit the data through the EventHub, but will not trigger any edits repository
   * actions.  If there is an active edits object, it will take precedence over any `initialForm`
   * values passed.
   */
  const handleSetForm = async (initialForm: FormDataType<T>) => {
    const editsObject: FormDataType<T> | null =
      await EditsRepository.getEditsById(type, id);

    if (editsObject !== null) {
      handleUpdatedConfig(editsObject);
    } else {
      handleUpdatedConfig(initialForm);
    }
  };

  // On initial load, see if there is an edits object in the localStorage
  useEffect(() => {
    // Add subscription after get initial edits, otherwise will try to do unnecessary edits repository update
    addSubscription(async (updatedConfigObject: FormDataType<T>) => {
      try {
        const editsTime = await EditsRepository.updateEdits(
          type,
          id,
          updatedConfigObject.config,
          updatedConfigObject.seedConfigPublishedAt,
          updatedConfigObject.name,
          updatedConfigObject.receiptId,
          updatedConfigObject.isPublished
        );
        handleUpdatedConfig({
          ...updatedConfigObject,
          editsSavedTime: editsTime
        });
      } catch {
        addAlert({ title: 'Error saving edits.', severity: 'error' });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventHub]);

  const value: ConfigContextType<T> = useMemo(() => {
    const updateConfig = async (edits: T) => {
      updateValue({
        config: edits,
        seedConfigPublishedAt: seedConfigPublishedAtTime,
        name,
        receiptId,
        isPublished,
        isSmsForm
      });
    };

    // this event is used to render the Recurring Gift Prompt in the Giving Form while in Edit mode
    const toggleRecurringGiftPrompt = (isEnabled: boolean) => {
      eventHub.emit(EditorEventTypes.ToggleRecurringGiftPrompt, isEnabled);
    };

    const updateName = async (newName: string) => {
      const updatedConfigObject: FormDataType<T> = {
        config,
        seedConfigPublishedAt: seedConfigPublishedAtTime,
        name: newName,
        receiptId,
        isPublished,
        isSmsForm
      };
      updateValue(updatedConfigObject);
      handleSetForm(updatedConfigObject);
    };

    const updateIsSmsForm = async (useAsSmsForm: boolean) => {
      const updatedConfigObject: FormDataType<T> = {
        config,
        seedConfigPublishedAt: seedConfigPublishedAtTime,
        name,
        receiptId,
        isPublished,
        isSmsForm: useAsSmsForm
      };
      updateValue(updatedConfigObject);
      handleSetForm(updatedConfigObject);
    };

    const updateReceiptId = async (selectedReceiptId: string) => {
      updateValue({
        config,
        seedConfigPublishedAt: seedConfigPublishedAtTime,
        name,
        receiptId: selectedReceiptId,
        isPublished,
        isSmsForm
      });
    };

    const discardEdits = async () => {
      await EditsRepository.discardEdits(type, id);
      setEditsSavedTime(null);
    };

    /**
     * Creates a copy of the current config to a new draft with the provided `toId` givingFormId
     * @param toId the id of the giving form to specify the draft for
     * @param newSeedConfigPublishedAtTime
     * @param newName
     */
    const copyConfigToNewDraft = async (
      toId: string,
      newSeedConfigPublishedAtTime: Date,
      newName: string
    ) => {
      const currEdits: FormDataType<T> | null =
        await EditsRepository.getEditsById(type, id);

      if (!currEdits) {
        return;
      }

      await EditsRepository.updateEdits(
        type,
        toId,
        currEdits.config,
        newSeedConfigPublishedAtTime,
        newName,
        receiptId,
        isPublished
      );
    };

    return {
      configData: {
        config,
        editsSavedTime,
        seedConfigPublishedAt: seedConfigPublishedAtTime,
        name,
        isSmsForm
      },
      updateConfig,
      updateName,
      updateIsSmsForm,
      discardEdits,
      copyConfigToNewDraft,
      setEventHub,
      handleSetForm,
      highlightedBlock,
      setHighlightedBlock,
      receiptId,
      updateReceiptId,
      isPublished,
      toggleRecurringGiftPrompt
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editsSavedTime,
    config,
    eventHub,
    highlightedBlock,
    setHighlightedBlock,
    receiptId,
    isPublished,
    isSmsForm
  ]);

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};
