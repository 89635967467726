import { useState } from 'react';
import { FormControlLabel, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import Button from 'components/Button';
import Radio, { RadioGroup } from 'components/Radio';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { BlockTypes, ICustomFieldBlock } from 'types';
import { CustomFieldInputTypesProps } from '../EditCustomFields';

const CustomFieldSingleChoiceInputSchema = yup.object({
  heading: yup.string().required('Prompt name is required'),
  fieldInfo: yup.object({
    options: yup.string().required('At least one option is required')
  })
});

const CustomFieldSingleChoiceInput = ({
  customField,
  onUpdate,
  name,
  legacyFieldMapping,
  nameError
}: CustomFieldInputTypesProps): JSX.Element => {
  const { control, formState } = useFormContext();
  const [heading, setHeading] = useState<string>(customField?.heading || '');
  const [options, setOptions] = useState<string>(
    customField?.fieldInfo?.options?.join('\n') || ''
  );
  const [required, setRequired] = useState<boolean>(
    customField?.fieldInfo?.required || false
  );

  const isFormUpdated =
    heading !== customField?.heading ||
    name !== customField?.name ||
    legacyFieldMapping !== customField?.legacyFieldMapping ||
    customField?.fieldInfo?.options?.join('\n') !== options ||
    required !== customField?.fieldInfo?.required ||
    customField?.fieldInfo?.type !== 'singleChoice';

  const updateSingleChoiceInput = () => {
    const updatedBlock: ICustomFieldBlock = {
      name,
      reportingKey: name,
      heading,
      id: customField?.id || uuid(),
      blockType: BlockTypes.CustomFieldBlock,
      fieldInfo: {
        type: 'singleChoice',
        options: options
          .split('\n')
          .filter((option) => option)
          .filter((value, idx, values) => values.indexOf(value) === idx), // filters duplicates,
        required
      },
      legacyFieldMapping
    };
    onUpdate(updatedBlock);
  };

  return (
    <Stack spacing={1}>
      <Text variant="h5">Prompt Text</Text>
      <Controller
        name="heading"
        control={control}
        defaultValue={heading || ''}
        render={({
          field: { ref, onChange, ...field },
          fieldState: { error }
        }) => (
          <TextField
            {...field}
            hiddenLabel
            multiline
            minRows={1}
            placeholder="Prompt Text Here"
            onChange={(e) => {
              onChange(e.target.value);
              setHeading(e.target.value as string);
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
      <Text variant="h5">Make this a required field? </Text>
      <Controller
        name="fieldInfo.required"
        control={control}
        defaultValue={required ? 'yes' : 'no'}
        render={({ field: { ref, onChange, ...field } }) => (
          <RadioGroup
            {...field}
            row
            onChange={(e) => {
              onChange(e.target.value);
              setRequired((e.target.value as string) === 'yes');
            }}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={<Text variant="caption">Yes</Text>}
            />
            <FormControlLabel
              value="no"
              control={<Radio />}
              label={<Text variant="caption">No</Text>}
            />
          </RadioGroup>
        )}
      />
      <Text variant="h5">
        Type your options to select from. Separate each on a new line.
      </Text>
      <Controller
        name="fieldInfo.options"
        control={control}
        defaultValue={options || ''}
        render={({
          field: { ref, onChange, ...field },
          fieldState: { error }
        }) => (
          <TextField
            {...field}
            hiddenLabel
            multiline
            minRows={6}
            placeholder="Single Choice Answers Here"
            onChange={(e) => {
              onChange(e.target.value);
              setOptions(e.target.value as string);
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
      <Button
        variant="primary"
        fullWidth
        disabled={nameError || !formState.isValid || !isFormUpdated}
        onClick={updateSingleChoiceInput}
      >
        Update
      </Button>
    </Stack>
  );
};

export default (props: CustomFieldInputTypesProps): JSX.Element => {
  const methods = useForm({
    resolver: yupResolver(CustomFieldSingleChoiceInputSchema),
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <CustomFieldSingleChoiceInput {...props} />
    </FormProvider>
  );
};
