import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as ChevronsLeftIcon } from 'assets/icons/chevrons-left.svg';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import './ElementLibrary.scss';

interface IViewComponentProps<T> {
  setView: Dispatch<SetStateAction<T>>;
}

export interface IDataView<T> {
  title: string;
  subtitle?: string;
  component: FunctionComponent<IViewComponentProps<T>>;
}

interface ElementLibraryHeaderProps {
  title: string;
  subtitle?: string;
  returnAction?(): void;
}

const ElementLibraryHeader = ({
  title,
  subtitle,
  returnAction
}: ElementLibraryHeaderProps): JSX.Element => (
  <Box className="ElementLibraryMenu-Header">
    {!!returnAction && (
      <IconButton
        icon={ChevronsLeftIcon}
        onClick={returnAction}
        label="Back"
        className="ElementLibraryMenu-Header__back-button"
      />
    )}
    <Text variant="h2" className="ElementLibraryMenu-Header__title">
      {title}
    </Text>
    {!!subtitle && (
      <Text variant="body" className="ElementLibraryMenu-Header__subtitle">
        {subtitle}
      </Text>
    )}
  </Box>
);

type ElementLibraryProps<T extends string | number | symbol> = {
  libraryView: T;
  setLibraryView: Dispatch<SetStateAction<T>>;
  returnAction: () => void;
  elementLibraryViewData: { [key in T]: IDataView<T> };
};

export const ElementLibrary = <T extends string | number | symbol>({
  libraryView,
  setLibraryView,
  returnAction,
  elementLibraryViewData
}: ElementLibraryProps<T>): JSX.Element => {
  const {
    title,
    subtitle,
    component: LibraryContentComponent
  } = elementLibraryViewData[libraryView];
  return (
    <Box className="ElementLibraryMenu-container">
      <ElementLibraryHeader
        title={title}
        subtitle={subtitle}
        returnAction={returnAction}
      />
      <Box className="ElementLibraryMenu-content--container">
        <LibraryContentComponent setView={setLibraryView} />
      </Box>
    </Box>
  );
};
