import { AxiosError } from 'axios';
import axios from 'services/axiosClient';
import {
  CreateDesignation,
  CreateDesignationGroup,
  DeleteDesignationGroup,
  Designation,
  DesignationGroup,
  IGetDesignations,
  ToggleDesignationStatus,
  UpdateDesignation
} from './types';

export const getDesignationsService = async ({
  organizationId,
  campaignId,
  isActiveFilter
}: IGetDesignations): Promise<Designation[]> => {
  try {
    const searchParams = new URLSearchParams();

    searchParams.set('organizationId', organizationId);
    if (campaignId) {
      searchParams.set('campaignId', campaignId);
    }
    // isActiveFilter defaults to true in backend
    if (isActiveFilter !== undefined) {
      searchParams.set('isActiveFilter', `${isActiveFilter}`);
    }

    const { data } = await axios.get(
      `/designations?${searchParams.toString()}`
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error retrieving designations'
    );
  }
};

export const createDesignationService = async ({
  organizationId,
  designationFormData
}: CreateDesignation): Promise<Designation> => {
  try {
    const { data } = await axios.post(
      `/designations?organizationId=${organizationId}`,
      designationFormData
    );
    return data.result;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error creating designation'
    );
  }
};

export const updateDesignationService = async ({
  organizationId,
  designationFormData,
  designationId
}: UpdateDesignation): Promise<Designation> => {
  try {
    const { data } = await axios.put(
      `/designations?organizationId=${organizationId}&designationId=${designationId}`,
      designationFormData
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error updating designation'
    );
  }
};

export const getDesignationGroupsService = async (
  organizationId: string
): Promise<DesignationGroup[]> => {
  try {
    const { data } = await axios.get(
      `/designations/groups?organizationId=${organizationId}`
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error retrieving designation groups'
    );
  }
};

export const createDesignationGroupService = async ({
  organizationId,
  groupName
}: CreateDesignationGroup): Promise<DesignationGroup> => {
  try {
    const { data } = await axios.post(
      `/designations/groups?organizationId=${organizationId}`,
      { groupName }
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error creating designation group'
    );
  }
};

export const deleteDesignationGroupByIdService = async ({
  organizationId,
  designationGroupId
}: DeleteDesignationGroup) => {
  try {
    await axios.delete(
      `/designations/groups/${designationGroupId}?organizationId=${organizationId}`
    );
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error deleting designation group'
    );
  }
};

export const toggleDesignationStatusService = async ({
  organizationId,
  designationId,
  isActive
}: ToggleDesignationStatus) => {
  try {
    await axios.put(
      `/designations/${designationId}/toggleStatus?organizationId=${organizationId}`,
      { isActive }
    );
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error toggling designation status'
    );
  }
};
