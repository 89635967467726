import { Box, Chip, Stack } from '@mui/material';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { numberFormatter, usdCurrencyFormatter } from 'utils/formatters';
import '../ChannelPreviewCard.scss';
import './PeerToPeerPreviewCard.scss';

type PeerToPeerPreviewCardProps = {
  id: string;
  peerToPeerName: string;
  createdDate: string;
  amountRaised: number;
  donationCount: number;
  donorCount: number;
  visitCount: number;
  isArchived: boolean;
  onArchiveClick: (toArchive: boolean) => void;
  marginOnEven?: boolean;
};

/**
 * A card used to display an overview of campaign's peer to peer
 */
export const PeerToPeerPreviewCard = ({
  id,
  peerToPeerName,
  createdDate,
  amountRaised,
  donationCount,
  donorCount,
  visitCount,
  isArchived,
  onArchiveClick,
  marginOnEven = true
}: PeerToPeerPreviewCardProps) => {
  const navigate = useNavigate();

  return (
    <Box
      className={clsx({
        'channel-preview-box': true,
        'p2p-preview-box': true,
        'margin-on-even': marginOnEven
      })}
    >
      {isArchived && <div className="archived-grayed-out" />}
      {isArchived && (
        <Chip
          className="channel-card-chip archived"
          size="medium"
          label="Archived"
        />
      )}
      <Stack
        direction="column"
        justifyContent="center"
        className={clsx('overview-hover', {
          'overview-archived': isArchived
        })}
      >
        <Button
          variant="secondary"
          startIcon={<Icon icon={ICONS.CHART_INFOGRAPHIC} />}
          onClick={() => navigate(`p2p/${id}`)}
        >
          Overview
        </Button>
        <Button
          variant="secondary"
          startIcon={<Icon icon={ICONS.PENCIL} />}
          onClick={() => navigate(`p2p/${id}/edit`)}
        >
          Edit
        </Button>
        {/* <Button variant="secondary" startIcon={<Icon icon={ICONS.VIDEO} />}>
          Demo
        </Button> */}
        <Button
          variant="secondary"
          onClick={() => onArchiveClick(!isArchived)}
          startIcon={<Icon icon={ICONS.ARCHIVE} />}
        >
          {isArchived ? 'Unarchive' : 'Archive'}
        </Button>
      </Stack>

      <Stack direction="column" justifyContent="center" spacing={2}>
        <Box
          display="flex"
          paddingLeft={1.5}
          justifyContent="space-between"
          alignItems="center"
          className="channel-preview-header"
        >
          <Text className="header-label" variant="h2">
            Peer-to-Peer
          </Text>
          <Icon icon={ICONS.USERS} className="header-icon" />
        </Box>
        <Box
          padding={1.5}
          paddingBottom={0.5}
          className="channel-preview-content"
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <IconButton
              label="giving form label icon"
              className="channel-icon"
              icon={ICONS.USERS}
              variant="primary"
              size="small"
            />
            <Text variant="h3"> {peerToPeerName}</Text>
          </Stack>

          <Stack direction="column" marginTop={1}>
            <Stack
              className="form-detail"
              direction="row"
              justifyContent="space-between"
            >
              <Box>
                <Text className="label" variant="body">
                  Created
                </Text>
              </Box>
              <Box>
                <Text variant="body">{createdDate}</Text>
              </Box>
            </Stack>
            <Stack
              className="form-detail"
              direction="row"
              justifyContent="space-between"
            >
              <Box>
                <Text className="label" variant="body">
                  Amount Raised
                </Text>
              </Box>
              <Box>
                <Text variant="body">
                  {usdCurrencyFormatter.format(amountRaised)}
                </Text>
              </Box>
            </Stack>
            <Stack
              className="form-detail"
              direction="row"
              justifyContent="space-between"
            >
              <Box>
                <Text className="label" variant="body">
                  Donations
                </Text>
              </Box>
              <Box>
                <Text variant="body">
                  {numberFormatter.format(donationCount)}
                </Text>
              </Box>
            </Stack>
            <Stack
              className="form-detail"
              direction="row"
              justifyContent="space-between"
            >
              <Box>
                <Text className="label" variant="body">
                  Donors
                </Text>
              </Box>
              <Box>
                <Text variant="body">{numberFormatter.format(donorCount)}</Text>
              </Box>
            </Stack>
            <Stack
              className="form-detail"
              direction="row"
              justifyContent="space-between"
            >
              <Box>
                <Text className="label" variant="body">
                  Visits
                </Text>
              </Box>
              <Box>
                <Text variant="body">{numberFormatter.format(visitCount)}</Text>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
