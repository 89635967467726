import * as yup from 'yup';

export interface IDesignatedFunds {
  title: string;
  code: string; // user-provided ID
  isActive: boolean;
}

export const DesignatedFundsSchema = yup.object({
  title: yup
    .string()
    .required('Designation title is required')
    .max(60, 'Cannot exceed 60 characters'),
  code: yup
    .string()
    .required('Designation fund ID is required')
    .max(60, 'Cannot exceed 60 characters')
});
