import { useState } from 'react';
import Text from 'components/Text';
import WYSIWYG, { Tag, TagSection } from 'components/WYSIWYG';
import { validateWYSIWYGHTML } from 'components/WYSIWYG/WYSIWYGUtils';
import { useConfigContext } from 'hooks/useConfigContext';
import { BlockTypes, IGivingFormConfig } from 'types';
import { IThankYouMessageBody } from 'types/givingFormTypes/ThankYouMessageBody';
import { getThankYouBodyIndex } from 'utils/givingFormBlockUtils';
import { createTagList } from 'utils/placeholderUtils';
import './ThankYouBodyMessage.scss';

const ThankYouBodyMessage = () => {
  const [error, setError] = useState('');
  const {
    updateConfig,
    configData: { config }
  } = useConfigContext<IGivingFormConfig>();

  const onEditorChange = (newContent: string) => {
    const newConfig = { ...config };
    const index = getThankYouBodyIndex(newConfig);

    if (index >= 0) {
      (newConfig.thankYouConfig.blocks[index] as IThankYouMessageBody).html =
        newContent;
    } else {
      newConfig.thankYouConfig.blocks.push({
        id: '01-thankYouBody',
        blockType: BlockTypes.ThankYouMessageBodyBlock,
        html: newContent
      });
    }

    if (validateWYSIWYGHTML({ config: newConfig })) {
      setError('');
      updateConfig(newConfig);
    } else {
      setError(
        'Your message has an invalid tag. Please remove this before making further changes.'
      );
    }
  };

  return (
    <div className="ty-body-message">
      <Text variant="h3" className="ty-body-message-title">
        Message
      </Text>
      <WYSIWYG
        defaultStyles={{
          fontFamily: config.theme.font.formBody
        }}
        onBlur={onEditorChange}
        value={
          (
            config.thankYouConfig.blocks[
              getThankYouBodyIndex(config)
            ] as IThankYouMessageBody
          ).html
        }
        tagList={createTagList(config, true) as TagSection[]}
        tags={createTagList(config) as Tag[]}
      />
      {!!error && (
        <Text variant="caption" className="ty-body-message-error">
          {error}
        </Text>
      )}
    </div>
  );
};

export default ThankYouBodyMessage;
