import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import {
  AbTestNotesDeleteBody,
  AbTestNotesPatchBody,
  AbTestNotesPostBody,
  AbTestPostBody,
  AbTestUniqueNamePostBody,
  EndAbTestPostBody,
  IGetAbTest
} from 'services';
import {
  createAbTestService,
  deleteAbTestNoteService,
  endAbTestService,
  getAbTestByOrganization,
  getAbTestService,
  patchAbTestNoteService,
  publishAbTestNotesService,
  validateAbTestNameService
} from 'services/abTestService';
import { AbTest } from 'types';

export const useGetAllAbTests = (
  {
    organizationId,
    includeMetrics
  }: { organizationId: string; includeMetrics?: boolean },
  queryOptions?: UseQueryOptions<AbTest[]>
) =>
  useQuery<AbTest[]>(
    ['getAbTestByOrganization', { organizationId, includeMetrics }],
    () => getAbTestByOrganization(organizationId, includeMetrics),
    queryOptions
  );

export const useAbTest = (
  { organizationId, abTestId, includeMetrics }: IGetAbTest,
  queryOptions?: UseQueryOptions<AbTest>
) =>
  useQuery<AbTest>(
    ['getAbTest', { organizationId, abTestId, includeMetrics }],
    () => getAbTestService({ organizationId, abTestId, includeMetrics }),
    queryOptions
  );

export const useValidateAbTestName = ({
  organizationId,
  name,
  campaignId
}: AbTestUniqueNamePostBody) =>
  useMutation(
    ['validateAbTestNameByName', { organizationId, name, campaignId }],
    () =>
      validateAbTestNameService({
        organizationId,
        name,
        campaignId
      })
  );

export const useCreateAbTestMutation = (
  organizationId: string,
  abTest: AbTestPostBody
) => {
  const queryClient = useQueryClient();
  return useMutation(() => createAbTestService(organizationId, abTest), {
    onSuccess: (result: AbTest) => {
      queryClient.setQueryData(
        ['getAllAbTests', organizationId],
        (prevState: AbTest[] | undefined): AbTest[] => {
          if (prevState) {
            return [...prevState, result];
          }
          return [result];
        }
      );
    }
  });
};

export const useEndAbTestMutation = (
  { organizationId, abTestId, givingFormConfigVariant }: EndAbTestPostBody,
  queryOptions?: UseQueryOptions<AbTest>
) =>
  useMutation(
    [
      'endAbTest',
      {
        organizationId,
        abTestId,
        givingFormConfigVariant
      }
    ],
    () => endAbTestService(organizationId, abTestId, givingFormConfigVariant),
    queryOptions
  );

export const usePublishAbTestNotes = (
  { userId, abTestId, content, organizationId }: AbTestNotesPostBody,
  queryOptions?: UseQueryOptions<AbTest>
) =>
  useMutation(
    ['publishAbTestNotes', { userId, content, abTestId, organizationId }],
    () =>
      publishAbTestNotesService({ userId, content, abTestId, organizationId }),
    queryOptions
  );

export const usePatchAbTestNotes = (
  patchDTO: AbTestNotesPatchBody,
  queryOptions?: UseQueryOptions<AbTest>
) =>
  useMutation(
    ['patchAbTestNotes', patchDTO],
    () => patchAbTestNoteService(patchDTO),
    queryOptions
  );

export const useDeleteAbTestNote = (
  deleteDto: AbTestNotesDeleteBody,
  queryOptions?: UseQueryOptions<AbTest>
) =>
  useMutation(
    ['deleteAbTestNote', deleteDto],
    () => deleteAbTestNoteService(deleteDto),
    queryOptions
  );
