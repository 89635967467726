import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { DEFAULT_ORGANIZATION } from 'constants/organizationConstants';
import { EmptyStateTextDisplay } from 'components';
import { CreateGivingFormWithHostedPageDialog } from 'components/gms/CreateGivingFormWithHostedPageDialog';
import NewGivingFormDialog from 'components/gms/NewGivingFormDialog';
import TemplatePreviewCard from 'components/gms/TemplatePreviewCard';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { OrganizationRole, getRoleInt } from 'hooks/useRenderIfRole';
import { useGivingFormTemplates } from 'queries/UseGivingForms';
import editsRepositoryApi from 'services/editsRepositoryService';
import { GivingForm } from 'services/types';
import './Templates.scss';

type TemplatesProps = {
  activeTab: string;
};

export const Templates = ({ activeTab }: TemplatesProps) => {
  const editType = 'givingForm';

  const { selectedOrganization } = useAppContext();
  const [pushAlert] = useAlerts();

  const { id: organizationId } = selectedOrganization;
  const { data: templates } = useGivingFormTemplates({
    organizationId,
    includeGlobals: true
  });
  const [allGivingFormEdits, setAllGivingFormEdits] = useState<
    Array<{ id: string; name: string; editsSavedTime: string }>
  >([]);
  const [showNewGivingFormDialog, setShowNewGivingFormDialog] = useState(false);
  const [
    showCreateGivingFormWithHpDialog,
    setShowCreateGivingFormWithHpDialog
  ] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');

  const getAllGivingFormEditsIds = async () => {
    try {
      const givingFormEdits = await editsRepositoryApi.getAllEdits(editType);
      setAllGivingFormEdits(givingFormEdits);
    } catch (e) {
      pushAlert({
        title: 'There was an error loading your local edits.',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    getAllGivingFormEditsIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unpublishedChangesById = (formId: string) => {
    const gfEdit = allGivingFormEdits.find(({ id }) => id === formId);
    return !!gfEdit;
  };

  const onTemplateClick = (template: GivingForm) => {
    if (
      getRoleInt(selectedOrganization.role) >=
      getRoleInt(OrganizationRole.Editor)
    ) {
      setSelectedTemplateId(template.embedInstanceId);
      // If the template has a Hosted Page config then show unique HP modal
      if (template.config.hostedPageConfig) {
        setShowCreateGivingFormWithHpDialog(true);
      } else {
        setShowNewGivingFormDialog(true);
      }
    }
  };

  const renderTemplates = (templatesArray: GivingForm[]) => {
    const templatesToDisplay = templatesArray.filter((template: GivingForm) =>
      activeTab === 'globalTemplates'
        ? template.organizationId === DEFAULT_ORGANIZATION
        : template.organizationId !== DEFAULT_ORGANIZATION
    );
    if (templatesToDisplay.length) {
      return (
        <Box className="templates-container">
          <Grid container marginTop={1.5} justifyContent="flex-start">
            {templatesToDisplay.map((template: GivingForm) => (
              <TemplatePreviewCard
                templateOrgId={template.organizationId}
                templateName={template.name}
                templateId={template.embedInstanceId}
                templateDescription={template.templateDescription}
                onTemplateClick={() => onTemplateClick(template)}
                key={template.embedInstanceId}
                isGlobalTemplate={activeTab === 'globalTemplates'}
                hasUnpublishedChanges={unpublishedChangesById(
                  template.embedInstanceId
                )}
              />
            ))}
          </Grid>
        </Box>
      );
    }
    return (
      <EmptyStateTextDisplay
        heading="You don’t currently have any saved templates."
        subheading="Start by creating a template from an existing giving form."
      />
    );
  };

  return (
    <>
      <Box padding={7} paddingTop={11} display="flex">
        {templates && renderTemplates(templates)}
      </Box>
      <NewGivingFormDialog
        open={showNewGivingFormDialog}
        onClose={() => setShowNewGivingFormDialog(false)}
        templateId={selectedTemplateId}
      />
      <CreateGivingFormWithHostedPageDialog
        open={showCreateGivingFormWithHpDialog}
        onClose={() => setShowCreateGivingFormWithHpDialog(false)}
        templateId={selectedTemplateId}
        isDuplicate={false}
      />
    </>
  );
};
