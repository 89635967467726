import { alpha } from '@mui/material';
import {
  ChartDataset,
  TickOptions,
  TimeScaleOptions,
  defaults
} from 'chart.js';
import { getMonth, getYear, subDays } from 'date-fns';
import { format, formatInTimeZone } from 'date-fns-tz';
import { IOrganizationDashboard, PlotBy } from 'services/types';
import { DateRangeValues } from 'utils';
import { LineGraphData } from './LineGraph.types';

enum AdditionalDateRangeValues {
  Fallback = 'Fallback'
}

type AllDateRangeValues =
  | Exclude<DateRangeValues, DateRangeValues.All | DateRangeValues.YearToDate>
  | AdditionalDateRangeValues;

defaults.font.family = 'Roboto';

const dateFormatter = (value: number) => (fmt: string) => format(value, fmt);

export const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact'
});

export const calculatePlotBy = (elapsedDaysInData: number): PlotBy => {
  // when choosing year to date, calculate diff in days
  if (elapsedDaysInData <= 7) {
    return PlotBy.Day;
  }

  if (elapsedDaysInData <= 30) {
    return PlotBy.Day;
  }

  if (elapsedDaysInData <= 91) {
    return PlotBy.Week;
  }

  if (elapsedDaysInData <= 365) {
    return PlotBy.Month;
  }

  return PlotBy.Month;
};

export const map: {
  [key in AllDateRangeValues]: {
    time: Partial<TimeScaleOptions['time']>;
    callback?: TickOptions['callback'];
  };
} = {
  [DateRangeValues.OneWeek]: {
    time: {
      unit: 'day',
      stepSize: 1,
      displayFormats: {
        day: 'd',
        hour: 'd'
      }
    },
    callback: (value, index, values) => {
      if (values[index] !== undefined) {
        if (values[index].major === true || index === 0) {
          const dateFmt = dateFormatter(values[index].value);
          return `${dateFmt('MMM')} ${dateFmt('d')}`;
        }
        return value;
      }

      return value;
    }
  },
  [DateRangeValues.OneMonth]: {
    time: {
      unit: 'day',
      stepSize: 1,
      displayFormats: {
        day: 'd'
      }
    },
    callback: (value, index, values) => {
      if (values[index] !== undefined) {
        if (values[index].major === true || index === 0) {
          const dateFmt = dateFormatter(values[index].value);
          return [dateFmt('d'), dateFmt('MMM')];
        }
        return value;
      }

      return value;
    }
  },
  [DateRangeValues.ThreeMonths]: {
    time: {
      unit: 'day',
      stepSize: 7,
      displayFormats: {
        day: 'd',
        week: 'd'
      }
    },
    callback: (value, index, values) => {
      if (values[index] !== undefined) {
        const isNewMonth =
          getMonth(values[index]?.value) !==
            getMonth(values[index - 1]?.value) ?? false;
        if (values[index].major === true || index === 0 || isNewMonth) {
          const dateFmt = dateFormatter(values[index].value);
          return `${dateFmt('MMM')} ${dateFmt('d')}`;
        }
        return value;
      }

      return value;
    }
  },
  [DateRangeValues.OneYear]: {
    time: {
      unit: 'month',
      stepSize: 1,
      displayFormats: {
        month: 'MMM'
      }
    },
    callback: (value, index, values) => {
      if (values[index] !== undefined) {
        if (values[index].major === true || index === 0) {
          const dateFmt = dateFormatter(values[index].value);
          return [dateFmt('MMM'), dateFmt('yyyy')];
        }
        return value;
      }

      return value;
    }
  },
  [AdditionalDateRangeValues.Fallback]: {
    time: {
      unit: 'year',
      stepSize: 1,
      displayFormats: {
        month: 'MMM',
        year: 'yyyy'
      }
    },
    callback: (value, index, values) => {
      if (values[index] !== undefined) {
        if (values[index].major === true || index === 0) {
          const dateFmt = dateFormatter(values[index].value);
          return [dateFmt('MMM'), dateFmt('yyyy')];
        }
        return value;
      }

      return value;
    }
  }
};

export const timeScaleMapper = (elapsedDaysInData: number) => {
  if (elapsedDaysInData <= 7) {
    return map[DateRangeValues.OneWeek];
  }

  if (elapsedDaysInData <= 30) {
    return map[DateRangeValues.OneMonth];
  }

  if (elapsedDaysInData <= 91) {
    return map[DateRangeValues.ThreeMonths];
  }

  if (elapsedDaysInData <= 365) {
    return map[DateRangeValues.OneYear];
  }

  return map[AdditionalDateRangeValues.Fallback];
};

export const makeBaseDataset = (color: string) =>
  ({
    label: 'Total Giving Received',
    data: null,
    borderColor: color,
    borderWidth: 4,
    fill: true,
    tension: 0.4,
    pointStyle: 'circle',
    pointRadius: 0,
    pointHoverRadius: 8,
    pointHoverBorderWidth: 2,
    pointHoverBorderColor: 'white',
    pointHoverBackgroundColor: color,
    backgroundColor: ({ chart: chartRef }) => {
      const { ctx } = chartRef;
      const { canvas } = ctx;
      const { height: graphHeight } = canvas;

      const gradientLine = ctx.createLinearGradient(0, 0, 0, graphHeight * 0.5);
      gradientLine.addColorStop(0, alpha(color, 0.1));
      gradientLine.addColorStop(0.5, alpha(color, 0.05));
      gradientLine.addColorStop(0.85, alpha(color, 0));

      return gradientLine;
    }
  } as ChartDataset<'line', LineGraphData>);

/**
 * Returns an array of years that the given dashboard data spans in sorted order
 * e.g. [2021, 2022, 2023]
 */
export const getSpannedYears = (data: IOrganizationDashboard['chartData']) => ({
  current: Array.from(
    new Set(data.current?.map((point) => getYear(new Date(point.x))) ?? [])
  ).sort((a, b) => a - b),
  previous: Array.from(
    new Set(data.previous?.map((point) => getYear(new Date(point.x))) ?? [])
  ).sort((a, b) => a - b)
});

/**
 * Returns a legend title for use in the chart legend
 */
export const makeLegendTitle = (spannedYears: number[]): string => {
  if (spannedYears.length === 0) {
    return '';
  }

  if (spannedYears.length > 1) {
    return `${spannedYears[0]} - ${spannedYears[spannedYears.length - 1]}`;
  }

  return `${spannedYears[0]}`;
};

export const generateTooltipDate = (plotBy: PlotBy, date: Date) => {
  // Date range request is in the user's timezone, but is returned as GMT
  // We need to format these with the right timezone.
  // TODO - DEV-5481 Fix dashboard queries, they currently provide a start date in
  // the users timezone, but the dates on the response are in GMT.
  if (plotBy === PlotBy.Day) {
    return formatInTimeZone(date, 'GMT', 'MMM do');
  }

  if (plotBy === PlotBy.Week) {
    return `${formatInTimeZone(
      subDays(date, 6),
      'GMT',
      'MMM do'
    )} - ${formatInTimeZone(date, 'GMT', 'MMM do')}`;
  }

  if (plotBy === PlotBy.Month) {
    return formatInTimeZone(date, 'GMT', 'MMMM');
  }

  return formatInTimeZone(date, 'GMT', 'MMM dd, yyyy');
};
