import { CSSProperties, useRef, useState } from 'react';
import { Chart } from 'chart.js/types/index.esm';
import { Bar } from 'react-chartjs-2';
import { Blocker } from 'components/Blocker';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import './AbTestOverviewBarGraph.scss';
import { AbTestOverviewBarGraphTooltip } from './AbTestOverviewBarGraphTooltip/AbTestOverviewBarGraphTooltip';

const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact'
});

interface AbTestOverviewBarGraphProps {
  variantAData: number;
  variantBData: number;
  useCurrencyFormatter?: boolean;
  tooltipTitle: string;
}

interface TooltipData {
  styles: CSSProperties;
  amount: string | number;
}

interface ChartJSProperty {
  height: number;
}

export const AbTestOverviewBarGraph = ({
  variantAData = 0,
  variantBData = 0,
  useCurrencyFormatter = false,
  tooltipTitle
}: AbTestOverviewBarGraphProps) => {
  /**
   * Height of the two bars on the bar chart. Used for various calculations
   */
  const [bar1Height, setBar1Height] = useState<number>(null);
  const [bar2Height, setBar2Height] = useState<number>(null);
  /**
   * Width of the delta zone.
   */
  const [deltaWidth, setDeltaWidth] = useState<number>(null);
  /**
   * Data of the currently displayed tooltip (if any)
   */
  const [tooltipData, setTooltipData] = useState<TooltipData>(null);
  /**
   * Refs to access DOM properties off some of the elements in the graph
   */
  const chartRef = useRef<Chart<'bar', number[], string>>();
  const deltaRef = useRef<HTMLDivElement>();
  const deltaTextRef = useRef<HTMLDivElement>();
  const barGraphContainerRef = useRef<HTMLDivElement>();

  const isPositive = bar1Height - bar2Height < 0;
  const isNegative = bar1Height - bar2Height > 0;

  // eslint-disable-next-line no-nested-ternary
  const symbol = isPositive ? '+' : isNegative ? '-' : '';
  const variantDifference = Math.abs(variantAData - variantBData);
  const deltaText = useCurrencyFormatter
    ? currencyFormatter.format(variantDifference)
    : variantDifference;

  return (
    <div className="bar-graph-container" ref={barGraphContainerRef}>
      <Blocker block={bar1Height === null || bar2Height === null}>
        <div
          className="bar-graph-delta"
          ref={deltaRef}
          style={{ width: deltaWidth }}
        />
        <Bar
          ref={chartRef}
          data={{
            labels: [''],
            datasets: [
              {
                borderRadius: 8,
                maxBarThickness: 40,
                label: 'Form A',
                backgroundColor: '#AA23BF',
                data: [variantAData]
              },
              {
                borderRadius: 8,
                maxBarThickness: 40,
                label: 'Form B',
                backgroundColor: '#2356F6',
                data: [variantBData]
              }
            ]
          }}
          plugins={[
            {
              id: '',
              afterRender: (ctx) => {
                // after the bars have been rendered, we save the widths of each bar in state
                const bars = ctx.getSortedVisibleDatasetMetas();
                const { height: newBar1Height } = bars[0]
                  .data[0] as unknown as ChartJSProperty;
                const { height: newBar2Height } = bars[1]
                  .data[0] as unknown as ChartJSProperty;

                /**
                 * To calculate the appropriate width of the delta zone
                 * (because based on the number of characters for the labels on the Y axis, the distance from the right end of the chart to the y axis changes),
                 * we take the width of the entire chart, and subtract the width of the labels on the y axis.
                 */
                const newDeltaWidth =
                  (barGraphContainerRef.current?.offsetWidth ?? 0) -
                  bars[0].yScale.width;

                /**
                 * We get the height of the delta zone by taking the difference of the heights of the two bars.
                 * We also get the distance from the bottom that the delta zone should be by taking the smaller
                 * of the two heights and offseting it vertically by that much
                 */
                const deltaHeight = Math.abs(newBar1Height - newBar2Height);
                deltaRef.current.style.height = `${deltaHeight + 0.5}px`;
                deltaRef.current.style.bottom = `${
                  Math.min(newBar1Height, newBar2Height) + 25
                }px`;

                /**
                 * Once the delta zone has been rendered, we unhide the delta text and bring the delta text up above the delta zone by
                 * offsetting it by the height of the largest bar plus a little extra space (50px)
                 */
                deltaTextRef.current.style.transform = `translateY(-${
                  Math.max(newBar1Height, newBar2Height) + 50
                }px)`;
                deltaTextRef.current.style.visibility = 'visible';

                setBar1Height(newBar1Height);
                setBar2Height(newBar2Height);
                setDeltaWidth(newDeltaWidth);
              }
            }
          ]}
          options={{
            plugins: {
              legend: {
                display: false,
                position: 'bottom',
                labels: {
                  boxWidth: 12,
                  boxHeight: 12,
                  usePointStyle: true,
                  pointStyle: 'circle',
                  font: {
                    weight: 'bold'
                  }
                }
              },
              tooltip: {
                enabled: false,
                external: (context) => {
                  const { tooltip } = context;
                  if (tooltip.opacity !== 0) {
                    const correctVariant =
                      tooltip.dataPoints[0].datasetIndex === 0
                        ? variantAData
                        : variantBData;
                    const nonCompactFormatter = Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD'
                    });

                    const amount = useCurrencyFormatter
                      ? nonCompactFormatter.format(correctVariant)
                      : correctVariant.toLocaleString('en-US');

                    setTooltipData({
                      styles: {
                        bottom:
                          50 +
                          (
                            tooltip.dataPoints[0]
                              .element as unknown as ChartJSProperty
                          ).height,
                        left: tooltip.dataPoints[0].element.x - 80
                      },
                      amount
                    });
                  } else {
                    setTooltipData(null);
                  }
                }
              }
            },
            scales: {
              y: {
                min: 0,
                /**
                 * Max here is the larger data * 1.175 to give it a bit of extra space at the top of the graph.
                 * Ensures the range will be at least 0 - 1
                 */
                max: Math.ceil(
                  Math.max(variantAData, variantBData, 0.5) * 1.175
                ),
                ticks: {
                  /**
                   * If the range of the y axis is less than 10, we ensure that each step will be a whole number.
                   * We turn this off for axes with y values greater then ten, to defer to chartJS's internal
                   * tick decision, and to disable warnings that come with having too many steps
                   */
                  stepSize:
                    Math.max(variantAData, variantBData) > 10 ? undefined : 1,
                  callback: (val) =>
                    useCurrencyFormatter
                      ? currencyFormatter.format(Number(val))
                      : Math.floor(+val).toLocaleString('en-US')
                }
              }
            }
          }}
        />
        {tooltipData && (
          <AbTestOverviewBarGraphTooltip
            title={tooltipTitle}
            amount={`${tooltipData.amount}`}
            styles={tooltipData.styles}
          />
        )}

        <div className="bar-graph-legend">
          <div className="bar-graph-legend-a">
            <div className="bar-graph-legend-a-color" />
            <Text variant="h6">Form A</Text>
          </div>
          <div className="bar-graph-delta-text" ref={deltaTextRef}>
            <Text variant="h6">
              {symbol} {deltaText}
            </Text>
            <Icon icon={ICONS.TRIANGLE} />
          </div>
          <div className="bar-graph-legend-a">
            <div className="bar-graph-legend-b-color" />
            <Text variant="h6">Form B</Text>
          </div>
        </div>
      </Blocker>
    </div>
  );
};
