import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import './WYSIWYG.scss';

type WYSIWYGMenuProps = {
  popperProps: PopperProps;
  menuItems: {
    heading: string;
    tags: {
      label: string;
      onClick: () => void;
    }[];
  }[];
  handleClose: () => void;
};

const WYSIWYGMenu = ({
  popperProps,
  menuItems,
  handleClose
}: WYSIWYGMenuProps) => (
  <Popper {...popperProps}>
    <Paper>
      <ClickAwayListener onClickAway={handleClose}>
        <MenuList>
          {menuItems.map(({ heading, tags }) => {
            const className = heading ? 'indented-menu-item' : '';
            return (
              <div key={heading}>
                {!!heading && <MenuItem disabled>{heading}</MenuItem>}
                <div className={className}>
                  {tags.map(({ label, onClick }) => (
                    <MenuItem key={label} onClick={onClick}>
                      {label}
                    </MenuItem>
                  ))}
                </div>
              </div>
            );
          })}
        </MenuList>
      </ClickAwayListener>
    </Paper>
  </Popper>
);

export default WYSIWYGMenu;
