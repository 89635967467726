import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAppContext } from 'hooks/useAppContext';
import {
  CreateEmailByTypeRequest,
  Email,
  GetAllEmailTemplatesRequest,
  MarkEmailAsDefault,
  UniqueEmailNameRequestDto
} from 'services';
import {
  GetAllEmailsRequest,
  GetEmailByIdRequest,
  UpdateEmail
} from 'services/types/emailTypes';
import {
  createEmailByTypeService,
  getAllEmailsService,
  getEmailByIdService,
  getEmailTemplatesService,
  markEmailAsDefaultService,
  updateEmailService,
  validateEmailNameService
} from '../services/emailService';

export const useEmails = ({ organizationId, type }: GetAllEmailsRequest) =>
  useQuery(
    [
      'getAllEmails',
      {
        organizationId,
        type
      }
    ],
    () => getAllEmailsService({ organizationId, type })
  );

export const useEmailById = ({
  emailId,
  type
}: Pick<GetEmailByIdRequest, 'emailId' | 'type'>) => {
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();
  return useQuery(
    ['emailById', { emailId, type }],
    () => getEmailByIdService({ organizationId, emailId, type }),
    { cacheTime: 0 }
  );
};

export const useEmailTemplates = ({
  organizationId,
  type,
  includeGlobals = false
}: GetAllEmailTemplatesRequest) =>
  useQuery(
    [
      'getEmailTemplates',
      {
        organizationId,
        type,
        includeGlobals
      }
    ],
    () => getEmailTemplatesService({ organizationId, type, includeGlobals })
  );

export const useValidateEmailName = ({
  organizationId,
  type,
  name,
  emailId
}: UniqueEmailNameRequestDto) =>
  useMutation(() =>
    validateEmailNameService({ organizationId, type, name, emailId })
  );

export const useCreateEmailByTypeMutation = ({
  organizationId,
  type,
  templateId,
  name
}: CreateEmailByTypeRequest) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => createEmailByTypeService({ organizationId, type, templateId, name }),
    {
      onSuccess: (result: Email) => {
        queryClient.setQueryData(
          ['getAllEmails', { organizationId, type }],
          (prevState: Email[] | undefined): Email[] => {
            if (prevState) {
              return [...prevState, result];
            }
            return [result];
          }
        );
      }
    }
  );
};

export const useUpdateEmail = ({
  emailId,
  name,
  config,
  organizationId
}: UpdateEmail) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => updateEmailService({ emailId, name, config, organizationId }),
    {
      onSuccess: (result: Email) => {
        queryClient.setQueryData(
          ['emailById', { emailId: result.emailId, type: result.type }],
          result
        );
      }
    }
  );
};

export const useMarkEmailAsDefault = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ organizationId, emailId }: MarkEmailAsDefault) =>
      markEmailAsDefaultService({ organizationId, emailId }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['getAllEmails']);
      }
    }
  );
};
