import { ReactNode } from 'react';
import Button from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import './InlineNotification.scss';

export interface InlineNotificationProps {
  header: string;
  subheader: ReactNode;
  onDismiss?: () => void;
  ctaText: string;
  onCtaClick: () => void;
}

export const InlineNotification = ({
  header,
  subheader,
  onDismiss,
  ctaText,
  onCtaClick
}: InlineNotificationProps) => (
  <aside className="inline-notification">
    <div className="inline-notification-content">
      <Icon
        icon={ICONS.BELL_RINGING}
        className="inline-notification-bell-icon"
      />
      <div className="inline-notification-text-context">
        <Text className="inline-notification-header" variant="h5">
          {header}
        </Text>
        <Text
          className="inline-notification-subheader"
          variant="h6Regular"
          as="p"
        >
          {subheader}
        </Text>
      </div>
    </div>

    <div className="inline-notification-cta-container">
      <Button
        className="inline-notification-cta"
        size="small"
        onClick={onCtaClick}
      >
        {ctaText}
      </Button>
    </div>
    <div className="inline-notification-dismiss-button-container">
      <IconButton
        className="inline-notification-dismiss-button"
        size="small"
        label="Dismiss"
        tooltipLabel="Dismiss"
        onClick={onDismiss}
        icon={ICONS.X}
      />
    </div>
  </aside>
);
