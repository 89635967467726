import { Menu as MuiMenu, MenuProps as MuiMenuProps } from '@mui/material';
import { MenuItem, MenuItemProps } from '.';

export interface MenuProps extends MuiMenuProps {
  menuItems: MenuItemProps[];
}

const Menu = ({ menuItems, ...props }: MenuProps): JSX.Element => (
  <MuiMenu {...props}>
    {menuItems.map((item) => (
      <MenuItem key={item.label} {...item} />
    ))}
  </MuiMenu>
);

export default Menu;
