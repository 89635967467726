import { DialogContentText, DialogContentTextProps } from '@mui/material';
import { useDialogContext } from './Dialog';

const DialogContentDescription = (
  props: DialogContentTextProps
): JSX.Element => {
  const { computedLabel } = useDialogContext();
  return (
    <DialogContentText id={`${computedLabel}-dialog-description`} {...props} />
  );
};

export default DialogContentDescription;
