import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { useUser } from 'queries/UseUsers';
import { IOrganizationInfo } from 'services/types/organizationTypes';
import { IOrganization, IUser } from 'services/types/userTypes';
import { IAppContext } from './types/AppProviderTypes';

type ProviderProps = {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  envConfig: Record<string, any>;
};

export const AppContext = createContext<IAppContext>({
  selectedGivingFormId: '',
  setSelectedGivingFormId: () => ({}),
  selectedTemplateId: '',
  setSelectedTemplateId: () => ({}),
  selectedCampaignName: '',
  setSelectedCampaignName: () => ({}),
  showNewCampaignDialog: false,
  setShowNewCampaignDialog: () => ({}),
  selectedOrganization: {},
  setSelectedOrganization: () => ({}),
  organizationInfo: {},
  setOrganizationInfo: () => ({}),
  userOrganizations: [],
  setUserOrganizations: () => [],
  abTestName: '',
  setNewAbTestName: () => ({}),
  envConfig: {}
});

const pendoConfig = (user: IUser, org: IOrganization) => ({
  visitor: {
    id: user.id,
    email: user.email,
    full_name: `${user.email}`
  },
  account: {
    id: org.id,
    name: org.name
  }
});

const storeSelectedOrg = (user: IUser, organization: IOrganization) => {
  // Store currently selected organization keyed off logged in user's email.
  if (user?.id) {
    window.localStorage.setItem(
      `${user.id}_selectedOrganization`,
      organization.id
    );
  }
};

const getSelectedOrganizationFromStorage = (user: IUser) => {
  const orgId = window.localStorage.getItem(`${user?.id}_selectedOrganization`);
  if (orgId && user?.organizations) {
    // verify that the user still has access to this org
    return user.organizations.find((org: IOrganization) => org.id === orgId);
  }
  // if there is no saved org in local storage then default to the user's first org
  if (!orgId && user?.organizations) {
    storeSelectedOrg(user, user.organizations[0]);
    return user.organizations[0];
  }
  return null;
};

export const AppProvider = ({ children, envConfig }: ProviderProps) => {
  const { data: user } = useUser();

  const [selectedGivingFormId, setSelectedGivingFormId] = useState<string>('');
  const [selectedCampaignName, setSelectedCampaignName] = useState<string>('');
  const [showNewCampaignDialog, setShowNewCampaignDialog] =
    useState<boolean>(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');
  const [selectedOrganization, _setSelectedOrganization] =
    useState<IOrganization>(null);
  const [organizationInfo, setOrganizationInfo] = useState<IOrganizationInfo>(
    {}
  );
  const [userOrganizations, setUserOrganizations] = useState<IOrganization[]>(
    []
  );
  const [abTestName, setNewAbTestName] = useState<string>('');

  // useUser is asyncronous, so we need to wait for it to return before
  // attempting to set selected and available organizations in state
  useEffect(() => {
    if (user && !userOrganizations.length) {
      setUserOrganizations(user.organizations);
      _setSelectedOrganization(getSelectedOrganizationFromStorage(user));
    }
  }, [user, userOrganizations]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { pendo } = window as any;

    if (user && selectedOrganization) {
      if (!pendo.accountId) {
        pendo.initialize(pendoConfig(user, selectedOrganization));
      } else {
        pendo.updateOptions(pendoConfig(user, selectedOrganization));
      }
    }
  }, [user, selectedOrganization]);

  const context = useMemo(
    () => ({
      selectedGivingFormId,
      setSelectedGivingFormId,
      selectedCampaignName,
      showNewCampaignDialog,
      setShowNewCampaignDialog,
      setSelectedCampaignName,
      selectedTemplateId,
      setSelectedTemplateId,
      selectedOrganization,
      setSelectedOrganization: (organization: IOrganization) => {
        // When we update selected org, make sure to update it in storage too
        storeSelectedOrg(user, organization);
        _setSelectedOrganization(organization);
      },
      organizationInfo,
      setOrganizationInfo,
      userOrganizations,
      setUserOrganizations,
      abTestName,
      setNewAbTestName,
      envConfig
    }),
    [
      selectedGivingFormId,
      setSelectedGivingFormId,
      selectedCampaignName,
      setSelectedCampaignName,
      showNewCampaignDialog,
      setShowNewCampaignDialog,
      selectedTemplateId,
      setSelectedTemplateId,
      selectedOrganization,
      _setSelectedOrganization,
      organizationInfo,
      setOrganizationInfo,
      userOrganizations,
      setUserOrganizations,
      abTestName,
      setNewAbTestName,
      envConfig,
      user
    ]
  );
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
