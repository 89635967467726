import { useState } from 'react';
import {
  Box,
  FormControlLabel,
  Skeleton,
  Stack,
  Switch,
  useTheme
} from '@mui/material';
import clsx from 'clsx';
import { addYears, differenceInDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import { DateRangeSelector } from 'components/DateRangeSelector';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { useAlerts } from 'hooks';
import { useOrganizationDashboard } from 'queries/UseOrganizationData';
import { DateRange, getDateComparisonUnit } from 'utils';
import { numberFormatter, usdCurrencyFormatter } from 'utils/formatters';
import { DashboardTotal } from '../DashboardTotal';
import { DateRangeText } from '../DateRangeText';
import { DonorLineGraph } from '../DonorLineGraph';
import { LineGraph, calculatePlotBy } from '../LineGraph';
import './DashboardJumbo.scss';

export interface DashboardJumboProps {
  organizationId: string;
  selectedDateRange: DateRange;
  handleRangeSelect: (
    _event: React.MouseEvent<HTMLElement>,
    rangeSelection: number
  ) => void;
  selectedRangeValue: number;
  orgSupportsChapters: boolean;
  orgStartDate?: string;
}

export const DashboardJumbo = ({
  organizationId,
  selectedDateRange,
  handleRangeSelect,
  selectedRangeValue,
  orgSupportsChapters,
  orgStartDate
}: DashboardJumboProps): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [pushAlert] = useAlerts();
  const [includeChapters, setIncludeChapters] = useState(false);

  const isAllTimeRangeSelected = selectedRangeValue === 0;
  const { startDate, endDate } = selectedDateRange;
  const elapsedDaysInData = differenceInDays(
    endDate,
    isAllTimeRangeSelected ? new Date(orgStartDate) : startDate
  );
  const plotBy = calculatePlotBy(elapsedDaysInData);

  const {
    data: dashboardData,
    isLoading: isLoadingDashboard,
    isError
  } = useOrganizationDashboard(
    organizationId,
    isAllTimeRangeSelected ? orgStartDate : startDate.toISOString(),
    endDate.toISOString(),
    plotBy,
    includeChapters,
    isAllTimeRangeSelected,
    {
      onError: () => {
        pushAlert({
          title: 'Uh oh. Looks like there was an error loading your dashboard.',
          severity: 'error'
        });
      }
    }
  );

  // have to add a year to the previous year's dataset so it shows up
  // on the same part of the x-axis
  const buildLineGraphData = () => {
    const lineGraphData = {
      ...dashboardData?.chartData
    };

    // Previous data points not needed when viewing all org data
    if (dashboardData?.chartData?.previous) {
      lineGraphData.previous = dashboardData?.chartData?.previous?.map(
        (point) => ({
          ...point,
          x: addYears(new Date(point.x), 1).toISOString()
        })
      );
    }

    return lineGraphData;
  };

  const isEmptyData =
    !isLoadingDashboard &&
    (!dashboardData ||
      !dashboardData?.chartData ||
      !dashboardData?.chartData?.current?.length) &&
    !dashboardData?.totalDonors;

  const handleIncludeChaptersToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIncludeChapters(event.target.checked);
  };

  return (
    <Stack
      flexDirection="column"
      className="dashboard-jumbo-container"
      padding={2.5}
      paddingTop={orgSupportsChapters ? 1 : 2.5}
    >
      {orgSupportsChapters && (
        <FormControlLabel
          className="include-chapters-switch"
          control={
            <Switch
              checked={includeChapters}
              onChange={handleIncludeChaptersToggle}
              inputProps={{ 'aria-label': 'include chapters toggle switch' }}
            />
          }
          label="Include Chapters"
        />
      )}
      <Stack
        className="top-stats"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Stack flexDirection="row">
          <DashboardTotal
            heading="Total Revenue"
            icon={ICONS.DOLLAR}
            total={usdCurrencyFormatter.format(
              dashboardData?.totalRevenue || 0
            )}
            comparisonDifference={dashboardData?.totalRevenueDeltaPercentage}
            comparisonUnit={getDateComparisonUnit(selectedRangeValue)}
            empty={isError || isEmptyData}
            isLoading={isLoadingDashboard}
          />
          <DashboardTotal
            heading="Total Donations"
            icon={ICONS.GIFT}
            total={numberFormatter.format(dashboardData?.totalDonations || 0)}
            comparisonDifference={dashboardData?.totalDonationsDeltaPercentage}
            comparisonUnit={getDateComparisonUnit(selectedRangeValue)}
            empty={isError || isEmptyData}
            isLoading={isLoadingDashboard}
          />
        </Stack>

        <Box className="range-selector">
          {isLoadingDashboard
            ? null
            : !isEmptyData && (
                <>
                  <DateRangeSelector
                    onChange={handleRangeSelect}
                    selectedRangeValue={selectedRangeValue}
                  />
                  <DateRangeText
                    startDate={
                      isAllTimeRangeSelected
                        ? new Date(orgStartDate)
                        : startDate
                    }
                    endDate={endDate}
                    selectedRangeValue={selectedRangeValue}
                  />
                </>
              )}
        </Box>
      </Stack>
      <div className="jumbo-graph">
        <Box className="jumbo-graph-donors-stats">
          <Stack direction="row" spacing={1} alignItems="center">
            <Box className="users-icon-wrapper">
              <Icon icon={ICONS.USERS} />
            </Box>
            <Text variant="h3">Donors</Text>
          </Stack>
          <Box
            className={clsx('donor-stats-card', {
              'donor-stats-card-empty': isEmptyData,
              'donor-stats-card-total': !isEmptyData
            })}
          >
            <div>
              <Text variant="h6">Total Donors</Text>
              {isLoadingDashboard ? (
                <Skeleton animation="wave" height={35} width={200} />
              ) : (
                <Text variant="h2">
                  {numberFormatter.format(dashboardData?.totalDonors || 0)}
                </Text>
              )}
            </div>
            {!isEmptyData && (
              <DonorLineGraph
                chartData={dashboardData?.totalDonorsChartData}
                color={theme.palette.blue.main}
              />
            )}
          </Box>
          <Box
            className={clsx(
              'donor-stats-card',
              isEmptyData && 'donor-stats-card-empty',
              !isEmptyData && 'donor-stats-card-recurring'
            )}
          >
            <div>
              <Text variant="h6">Recurring Donors</Text>
              {isLoadingDashboard ? (
                <Skeleton animation="wave" height={35} width={200} />
              ) : (
                <Text variant="h2">
                  {numberFormatter.format(dashboardData?.recurringDonors || 0)}
                </Text>
              )}
            </div>
            {!isEmptyData && (
              <DonorLineGraph
                chartData={dashboardData?.recurringDonorsChartData}
                color={theme.palette.purple.main}
              />
            )}
          </Box>
          <Box
            className={clsx(
              'donor-stats-card',
              isEmptyData && 'donor-stats-card-empty',
              !isEmptyData && 'donor-stats-card-first-time'
            )}
          >
            <div>
              <Text variant="h6">First-Time Donors</Text>
              {isLoadingDashboard ? (
                <Skeleton animation="wave" height={35} width={200} />
              ) : (
                <Text variant="h2">
                  {numberFormatter.format(dashboardData?.firstTimeDonors || 0)}
                </Text>
              )}
            </div>
            {!isEmptyData && (
              <DonorLineGraph
                chartData={dashboardData?.firstTimeDonorsChartData}
                color="#1AA37b"
              />
            )}
          </Box>
        </Box>
        <Box className="jumbo-graph-total-giving-graph">
          <div
            className={clsx(
              'jumbo-graph-loader',
              isLoadingDashboard && 'jumbo-graph-loader-is-loading'
            )}
          />
          {isEmptyData ? (
            <div className="jumbo-graph-empty-state">
              <Text
                className="jumbo-graph-empty-state-heading"
                variant="hero"
                as="h2"
              >
                Create meaningful donor experiences.
              </Text>
              <Text
                className="jumbo-graph-empty-state-subheading"
                variant="h5"
                as="h3"
              >
                Creating a Campaign is your first step toward making a
                difference. Campaigns can be comprised of any number of giving
                channels, from online giving forms to text-message giving
                keywords. Once you set up your Campaign, you can view its key
                performance metrics (conversion rate, total giving, giving by
                channel, etc.) on the Campaign Dashboard.
              </Text>
              <Button
                startIcon={<Icon icon={ICONS.PLUS} />}
                onClick={() => navigate('/campaigns/new')}
              >
                Start New Campaign
              </Button>
            </div>
          ) : (
            <div className="chart-container">
              <LineGraph
                data={buildLineGraphData()}
                elapsedDaysInData={elapsedDaysInData}
                plotBy={plotBy}
              />
            </div>
          )}
        </Box>
      </div>
    </Stack>
  );
};
