import { Box, Grid } from '@mui/material';
import { ICONS } from 'components/Icon';
import IconAvatar from 'components/IconAvatar';
import ProgressBar from 'components/ProgressBar';
import Text from 'components/Text';
import { CampaignType } from 'services';
import { getProgressBarAmount } from 'utils/chartHelpers';
import { formatDate } from 'utils/dateHelpers';
import { numberFormatter, usdCurrencyFormatter } from 'utils/formatters';
import './CampaignDetail.scss';

type CampaignDetailProps = {
  campaignData: Pick<
    CampaignType,
    | 'startDate'
    | 'endDate'
    | 'goal'
    | 'amountRaised'
    | 'totalDonations'
    | 'pageVisits'
    | 'conversionRate'
  >;
};

const CampaignDetail = ({ campaignData }: CampaignDetailProps): JSX.Element => (
  <Box className="CampaignDetail">
    <Grid container>
      <Box className="CampaignDetail-Summary">
        <Box className="CampaignDetail-Avatar">
          <IconAvatar icon={ICONS.USERS} />
        </Box>
        <Box className="CampaignDetail-DateRange">
          <Text variant="h2">Amount Raised</Text>
          <Text variant="caption">
            {`${formatDate(campaignData.startDate)} to ${
              formatDate(campaignData.endDate) || 'Present'
            }`}
          </Text>
        </Box>
        <Box className="CampaignDetail-DetailValues">
          <Text variant="h3">{`${numberFormatter.format(
            campaignData.conversionRate || 0
          )}%`}</Text>
          <Text variant="h3">
            {numberFormatter.format(campaignData.totalDonations || 0)}
          </Text>
          <Text variant="h3">
            {numberFormatter.format(campaignData.pageVisits || 0)}
          </Text>
        </Box>
        <Box className="CampaignDetail-DetailKeys">
          <Text variant="h4">Conversion Rate</Text>
          <Text variant="h4">Total Donations</Text>
          <Text variant="h4">Total Page Visits</Text>
        </Box>
      </Box>
      <Box className="CampaignDetail-AmountRaised">
        <Text variant="h1">
          {usdCurrencyFormatter.format(campaignData.amountRaised || 0)}
        </Text>
        <Text variant="h8">
          {` of ${usdCurrencyFormatter.format(campaignData.goal)} raised`}
        </Text>
      </Box>
      <Box className="CampaignDetail-ProgressBar">
        <ProgressBar
          variant="determinate"
          value={getProgressBarAmount(
            campaignData.amountRaised,
            campaignData.goal
          )}
        />
      </Box>
    </Grid>
  </Box>
);

export default CampaignDetail;
