import { Box, Stack } from '@mui/material';
import clsx from 'clsx';
import Button from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { numberFormatter, usdCurrencyFormatter } from 'utils/formatters';
import '../ChannelPreviewCard.scss';
import './TextToGivePreviewCard.scss';

type TextToGivePreviewCardProps = {
  textToGiveName: string;
  createdDate: string;
  amountRaised: number;
  donorCount: number;
  donationCount: number;
  marginOnEven?: boolean;
  onEditClick?: () => void;
};

/**
 * A card used to display an overview of campaign's Text to Give
 */
export const TextToGivePreviewCard = ({
  createdDate,
  amountRaised,
  donorCount,
  donationCount,
  textToGiveName,
  marginOnEven = true,
  onEditClick
}: TextToGivePreviewCardProps) => (
  <Box
    className={clsx({
      'channel-preview-box': true,
      't2g-preview-box': true,
      'margin-on-even': marginOnEven
    })}
  >
    <Stack
      direction="column"
      justifyContent="center"
      className="overview-hover"
    >
      <Button
        variant="secondary"
        startIcon={<Icon icon={ICONS.PENCIL} />}
        onClick={() => onEditClick()}
      >
        Edit
      </Button>
    </Stack>

    <Stack direction="column" justifyContent="center" spacing={2}>
      <Box
        display="flex"
        paddingLeft={1.5}
        justifyContent="space-between"
        alignItems="center"
        className="channel-preview-header"
      >
        <Text className="header-label" variant="h2">
          Text to Give
        </Text>
        <Icon icon={ICONS.MESSAGES} className="header-icon" />
      </Box>
      <Box
        padding={1.5}
        paddingBottom={0.5}
        className="channel-preview-content"
      >
        <Stack direction="row" spacing={0.5} alignItems="center">
          <IconButton
            label="giving form label icon"
            className="channel-icon"
            icon={ICONS.MESSAGES}
            variant="primary"
            size="small"
          />
          <Text variant="h3"> {textToGiveName}</Text>
        </Stack>

        <Stack direction="column" marginTop={1}>
          <Stack
            className="form-detail"
            direction="row"
            justifyContent="space-between"
          >
            <Box>
              <Text className="label" variant="body">
                Created
              </Text>
            </Box>
            <Box>
              <Text variant="body">{createdDate}</Text>
            </Box>
          </Stack>

          <Stack
            className="form-detail"
            direction="row"
            justifyContent="space-between"
          >
            <Box>
              <Text className="label" variant="body">
                Amount Raised
              </Text>
            </Box>
            <Box>
              <Text variant="body">
                {usdCurrencyFormatter.format(amountRaised)}
              </Text>
            </Box>
          </Stack>

          <Stack
            className="form-detail"
            direction="row"
            justifyContent="space-between"
          >
            <Box>
              <Text className="label" variant="body">
                Donors
              </Text>
            </Box>
            <Box>
              <Text variant="body">{numberFormatter.format(donorCount)}</Text>
            </Box>
          </Stack>

          <Stack
            className="form-detail"
            direction="row"
            justifyContent="space-between"
          >
            <Box>
              <Text className="label" variant="body">
                Donations
              </Text>
            </Box>
            <Box>
              <Text variant="body">
                {numberFormatter.format(donationCount)}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  </Box>
);
