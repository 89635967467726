/* eslint-disable no-useless-escape */

/* eslint-disable no-param-reassign */

/* eslint-disable  no-return-assign */
import { LegacyRoute } from 'routes/types';
import {
  buildPathQuery,
  getBaseSite,
  getPathFragment,
  getPathParams
} from 'utils';
import {
  EXTERNAL_APPS,
  LEGACY_APPS,
  LEGACY_ROUTES
} from '../routes/LegacyRoutes';
import { Url } from '../utils/Url';

export class LegacyRouteMapper {
  private legacySites: string[];

  private externalSites: string[];

  private routes: LegacyRoute[];

  constructor(private localRootUrl: string) {
    this.routes = LegacyRouteMapper.parseRoutes(LEGACY_ROUTES);
    (window as any).legacyRoutes = this.routes;

    this.legacySites = LEGACY_APPS.map((x) => getBaseSite(x.rootUrl));
    this.externalSites = EXTERNAL_APPS.map((x) => getBaseSite(x.rootUrl));
  }

  isLegacySite(href: string): boolean {
    const baseSite = getBaseSite(href);
    return !!this.legacySites.find((x) => x === baseSite);
  }

  isExternalSite(href: string): boolean {
    const baseSite = getBaseSite(href);
    return !!this.externalSites.find((x) => x === baseSite);
  }

  getLocalUrl(legacyUrl: Url, defaultParams?: any): Url {
    const routeInfo = this.routes.find(
      (x) =>
        x.app.rootUrl === legacyUrl.origin &&
        !!legacyUrl.path.match(x.legacyPathPattern)
    );
    if (!routeInfo) {
      return undefined;
    }

    let routeParams = LegacyRouteMapper.extractRouteParameters(
      legacyUrl.path,
      routeInfo.legacyPathPattern
    );
    let pathExtras;
    if (legacyUrl.path.startsWith('#/')) {
      pathExtras = '';
    } else if (routeInfo.queryMap) {
      const queryParams = getPathParams(legacyUrl.href);
      Object.keys(routeInfo.queryMap).forEach((key: string) => {
        const queryParam = routeInfo.queryMap[key];
        const value = queryParams[queryParam];
        if (value !== undefined) {
          routeParams[key] = value;
          delete queryParams[queryParam];
        }
      });
      pathExtras =
        buildPathQuery(queryParams) + getPathFragment(legacyUrl.href);
    } else {
      pathExtras = legacyUrl.extras || '';
    }

    if (defaultParams) {
      routeParams = { ...defaultParams, ...routeParams };
    }

    return new Url(
      `${this.localRootUrl}/${LegacyRouteMapper.applyRouteParameters(
        routeInfo.redirectToLocalPath || routeInfo.localPath,
        routeParams
      )}${pathExtras}`
    );
  }

  getLegacyUrl(localUrl: Url, defaultParams?: any): Url {
    const routeInfo = this.routes.find(
      (x) => x.localPathPattern && !!localUrl.path.match(x.localPathPattern)
    );
    if (!routeInfo) {
      return undefined;
    }

    let routeParams = LegacyRouteMapper.extractRouteParameters(
      localUrl.path,
      routeInfo.localPathPattern
    );

    let pathExtras;
    if (routeInfo.legacyPath.startsWith('#/')) {
      pathExtras = '';
    } else if (routeInfo.queryMap) {
      const queryParams = getPathParams(localUrl.href);

      Object.keys(routeInfo.queryMap).forEach((key: string) => {
        const value = routeParams[key];
        if (value !== undefined) {
          const queryParam = routeInfo.queryMap[key];
          queryParams[queryParam] = value;
          delete routeParams[key];
        }
      });

      pathExtras = buildPathQuery(queryParams) + getPathFragment(localUrl.href);
    } else {
      pathExtras = localUrl.extras || '';
    }

    if (defaultParams) {
      routeParams = { ...defaultParams, ...routeParams };
    }

    return new Url(
      `${routeInfo.app.rootUrl}/${LegacyRouteMapper.applyRouteParameters(
        routeInfo.legacyPath,
        routeParams
      )}${pathExtras}`
    );
  }

  static parseRoutes(routes: LegacyRoute[]): LegacyRoute[] {
    routes.forEach((x) => {
      x.legacyPathPattern = LegacyRouteMapper.getPathMatchRegex(x.legacyPath);
      if (x.localPath) {
        x.localPathPattern = LegacyRouteMapper.getPathMatchRegex(x.localPath);
      }
    });

    return routes;
  }

  static getPathMatchRegex(pathDef: string): RegExp {
    const parsed = pathDef
      .replace(/^\//, '')
      .replace(/\/$/, '')
      .split('/')
      .map((x, i) =>
        x.startsWith(':')
          ? x.replace(/^\:(\w+)/, `(?:[\\/]${i ? '+' : '*'}(?<$1>[^\\/\\?]+))`)
          : x.replace(/^(\w+)/, `(?:[\\/]${i ? '+' : '*'}$1)`)
      );
    const result = new RegExp(`^${parsed.join('')}[\\/]*$`, 'i');
    return result;
  }

  static extractRouteParameters(
    pathWithoutExtras: string,
    pathRegex: RegExp
  ): any {
    const match = pathRegex.exec(pathWithoutExtras);
    const result = match ? match.groups || {} : {};
    return result;
  }

  static applyRouteParameters(pathDefinition: string, parameters: any): string {
    let target = pathDefinition || '';

    const placeholders = target.match(/(:[^/]+)/g);
    if (placeholders) {
      // eslint-disable-next-line no-return-assign
      placeholders.forEach(
        (x) =>
          (target = target.replace(x, parameters[x.replace(/[:?]/g, '')] || ''))
      );
      target = target.replace(/\/\//g, '/');
    }

    return target;
  }
}
