import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import {
  useCheckHostedPageSlug,
  useCreateGivingFormMutation,
  useValidateGivingFormName
} from 'queries/UseGivingForms';
import './CreateGivingFormWithHostedPageDialog.scss';

interface CreateGivingFormWithHostedPageDialogProps {
  open: boolean;
  onClose: () => void;
  templateId: string;
  isDuplicate: boolean;
}

/**
 * A GMS-specific dialog for creating a new giving form from a template that contains a hosted page config.
 * The user must input a name for the new giving form and a unique HP slug before submitting.
 */
export const CreateGivingFormWithHostedPageDialog = ({
  open,
  onClose,
  templateId,
  isDuplicate
}: CreateGivingFormWithHostedPageDialogProps) => {
  const [formName, setFormName] = useState('');
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [addAlert] = useAlerts();
  const [isNameValid, setIsNameValid] = useState(true);
  const [slug, setSlug] = useState<string>('');
  const [isValidSlug, setIsValidSlug] = useState<boolean>(undefined);
  const [isUniqueSlug, setIsUniqueSlug] = useState<boolean>(undefined);

  const {
    envConfig,
    setSelectedGivingFormId,
    selectedOrganization: { id: selectedOrganizationId }
  } = useAppContext();

  const {
    mutateAsync: createGivingFormMutation,
    isLoading: isCreatingGivingForm
  } = useCreateGivingFormMutation({
    organizationId: selectedOrganizationId,
    name: formName,
    campaignId,
    templateId,
    hostedPageSlug: slug
  });

  const {
    mutateAsync: checkValidGivingFormName,
    isLoading: isCheckingNameValidity
  } = useValidateGivingFormName({
    organizationId: selectedOrganizationId,
    campaignId,
    name: formName
  });

  const {
    mutateAsync: checkHostedPageSlug,
    isLoading: isCheckingSlugValidity
  } = useCheckHostedPageSlug({
    organizationId: selectedOrganizationId,
    slug,
    givingFormId: templateId,
    isFromDuplicateForm: isDuplicate
  });

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsNameValid(true);
    setFormName(event.currentTarget.value);
  };

  const onCloseDialog = (close: () => void) => {
    setFormName('');
    setSlug('');
    setIsNameValid(true);
    setIsValidSlug(undefined);
    setIsUniqueSlug(undefined);
    close();
  };

  const handleCreateGivingForm = async (close: () => void) => {
    try {
      const data = await createGivingFormMutation();
      setSelectedGivingFormId(data.embedInstanceId);
      onCloseDialog(close);
      navigate(
        `/campaigns/${campaignId}/giving-forms/${data.embedInstanceId}/edit`
      );
    } catch (e) {
      addAlert({
        title: 'Uh oh. Looks like there was an error creating the giving form.',
        severity: 'error'
      });
    }
  };

  const checkValidGfName = async () => {
    const isValid = await checkValidGivingFormName();
    setIsNameValid(isValid);

    return isValid;
  };

  const onStartBuilding = async (close: () => void) => {
    const isGfNameValid = await checkValidGfName();
    const isSlugUnique = await checkHostedPageSlug();
    setIsUniqueSlug(isSlugUnique);

    if (isGfNameValid && isSlugUnique && isValidSlug) {
      await handleCreateGivingForm(close);
    }
  };

  const validateSlug = (inputSlug: string): boolean => {
    // TODO: JDH - leading hyphens ok?
    // const regex = /^(?!(-))[a-zA-Z0-9-]{1,50}$/
    const regex = /^[a-zA-Z0-9-]{1,50}$/;

    return !!inputSlug.match(regex);
  };

  const handleChangeSlug = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSlug(event.target.value);
    setIsValidSlug(validateSlug(event.target.value));
    setIsUniqueSlug(undefined);
  };

  const setHelperText = () => {
    let helperText = '';
    if (isUniqueSlug === false) {
      helperText = 'You must choose a unique slug';
    }
    if (isValidSlug === false) {
      helperText =
        'URL must be between 1-50 alphanumeric characters including hyphens';
    }
    return helperText;
  };

  return (
    <Dialog
      className="new-gf-with-hp-dialog"
      label="new giving form with hosted page dialog"
      open={open}
      onClose={() => onCloseDialog(onClose)}
    >
      <DialogTitle onClick={(close) => onCloseDialog(close)}>
        Create Giving Form
      </DialogTitle>
      <DialogContent>
        <DialogContentTitle>Create Giving Form</DialogContentTitle>
        <Text variant="h7" className="description">
          In order to build a Giving Form with a hosted page, we need to give it
          a unique name and URL.
        </Text>

        <div className="form-container">
          <Text variant="h3" className="input-label">
            What are we calling this Giving Form?
          </Text>
          <TextField
            value={formName}
            placeholder="Enter Form Name"
            hiddenLabel
            onChange={handleChangeName}
            error={!isNameValid}
            helperText={
              !isNameValid
                ? `"${formName}" is already being used in this campaign. Please pick a unique name.`
                : undefined
            }
            className="input-field"
          />

          <Text variant="h3" className="input-label">
            What would you like your unique URL to be for the hosted page?
          </Text>
          <div className="hosted-page-slug-input-container">
            <Text className="hosted-page-base-slug" variant="h8">
              {envConfig.hostedPageDomain}
            </Text>
            <TextField
              placeholder="Enter Customization"
              value={slug}
              onChange={handleChangeSlug}
              error={isUniqueSlug === false || isValidSlug === false}
              helperText={setHelperText()}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          variant="primary"
          onClick={(close) => {
            onStartBuilding(close);
          }}
          disabled={
            formName === '' ||
            slug === '' ||
            isCheckingNameValidity ||
            isCreatingGivingForm ||
            isCheckingSlugValidity
          }
        >
          {isCheckingNameValidity ||
          isCreatingGivingForm ||
          isCheckingSlugValidity ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Start Building'
          )}
        </DialogActionButton>
        <DialogActionButton
          variant="secondary"
          onClick={(close) => {
            onCloseDialog(close);
          }}
        >
          Back to {isDuplicate ? 'Editor' : 'Templates'}
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
