import { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  FormProvider,
  UseFormReturn,
  useForm,
  useFormContext
} from 'react-hook-form';
import * as yup from 'yup';
import Radio from 'components/Radio';
import Text from 'components/Text';
import TextField from 'components/TextField';
import WYSIWYG, { Tag, TagSection } from 'components/WYSIWYG';
import { isValidWYSIWYGHTML } from 'components/WYSIWYG/WYSIWYGUtils';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import {
  BlockTypes,
  IGivingFormConfig,
  IThankYouAccountInvitationBlock
} from 'types';
import { getThankYouBlockIndex } from 'utils/givingFormBlockUtils';
import { createTagList } from 'utils/placeholderUtils';
import OptionalSection from '../../OptionalSection';
import './ThankYouAccountInvitation.scss';

enum URLOptions {
  SelfServe = 'SelfServe',
  Custom = 'Custom'
}

type AccountInvitationBlockProperties = {
  html?: string;
  accountInvitationUrl?: string;
};

const accountInvitationSchema = yup.object({
  customURL: yup
    .string()
    .url('Must be a valid URL')
    .required('Please enter a valid URL')
});

export const getAccountInvitationDefaults = (
  config: IGivingFormConfig,
  donorSSP: string,
  hasDonorSsbEnabled: boolean
) => {
  const index = getThankYouBlockIndex(
    config,
    BlockTypes.ThankYouAccountInvitationBlock
  );

  const block = config?.thankYouConfig?.blocks?.[
    index
  ] as IThankYouAccountInvitationBlock;

  if (!block) {
    return {
      isEnabled: false,
      urlOption: hasDonorSsbEnabled ? URLOptions.SelfServe : URLOptions.Custom,
      customUrl: '',
      html: ''
    };
  }
  const isCustom = block.accountInvitationUrl !== donorSSP;
  return {
    isEnabled: true,
    urlOption: isCustom ? URLOptions.Custom : URLOptions.SelfServe,
    customUrl: isCustom ? block.accountInvitationUrl : '',
    html: block.html ?? ''
  };
};

type FormValues = {
  customURL?: string;
};

type ThankYouAccountInvitationViewProps = Partial<UseFormReturn<FormValues>>;

const ThankYouAccountInvitationView = ({
  trigger
}: ThankYouAccountInvitationViewProps) => {
  const { configData, updateConfig } = useConfigContext<IGivingFormConfig>();
  const {
    organizationInfo: { donorSSP, features }
  } = useAppContext();
  const hasDonorSsbEnabled = features.donorSelfServeBranding;
  const defaults = getAccountInvitationDefaults(
    configData.config,
    donorSSP,
    hasDonorSsbEnabled
  );
  const [selectedUrlOption, setSelectedUrlOption] = useState(
    defaults.urlOption
  );
  const [customURL, setCustomURL] = useState(defaults.customUrl);
  const [WYSIWYGError, setWYSIWYGError] = useState('');
  const { control } = useFormContext();

  const getAccountInvitationBlockIndex = () =>
    getThankYouBlockIndex(
      configData.config,
      BlockTypes.ThankYouAccountInvitationBlock
    );

  const addAccountInvitationBlock = () => {
    const newBlock = {
      id: '01-accountInvitation',
      blockType: BlockTypes.ThankYouAccountInvitationBlock,
      html: '',
      accountInvitationUrl: donorSSP
    };

    const index = getAccountInvitationBlockIndex();
    if (index < 0) {
      const newConfig = { ...configData.config };
      newConfig.thankYouConfig.blocks.push(newBlock);
      updateConfig(newConfig);
    }
  };

  const removeAccountInvitationBlock = () => {
    const index = getAccountInvitationBlockIndex();
    if (index >= 0) {
      const newConfig = { ...configData.config };
      const blocks = [...newConfig.thankYouConfig.blocks];
      blocks.splice(index, 1);
      newConfig.thankYouConfig.blocks = blocks;
      updateConfig(newConfig);
    }
  };

  const updateAccountInvitationBlock = ({
    html,
    accountInvitationUrl
  }: AccountInvitationBlockProperties) => {
    const index = getAccountInvitationBlockIndex();
    if (index >= 0) {
      const newConfig = { ...configData.config };
      if (html)
        (
          newConfig.thankYouConfig.blocks[
            index
          ] as IThankYouAccountInvitationBlock
        ).html = html;
      if (accountInvitationUrl)
        (
          newConfig.thankYouConfig.blocks[
            index
          ] as IThankYouAccountInvitationBlock
        ).accountInvitationUrl = accountInvitationUrl;
      updateConfig(newConfig);
    }
  };

  const handleWYSIWYGBlur = (html: string) => {
    if (isValidWYSIWYGHTML(html)) {
      updateAccountInvitationBlock({ html });
      setWYSIWYGError('');
    } else {
      setWYSIWYGError(
        'Your message has an invalid tag. Please remove this before making further changes.'
      );
    }
  };

  return (
    <div className="ty-account-invitation">
      <OptionalSection
        title="Include Account Invitation"
        initiallySelected={defaults.isEnabled}
        onChange={(isOpen) =>
          isOpen ? addAccountInvitationBlock() : removeAccountInvitationBlock()
        }
      >
        <Text className="ty-account-invitation-message-heading" variant="h4">
          Invitation Message
        </Text>
        <WYSIWYG
          onBlur={handleWYSIWYGBlur}
          tagList={createTagList(configData.config, true) as TagSection[]}
          tags={createTagList(configData.config) as Tag[]}
          value={defaults.html}
        />
        {!!WYSIWYGError && (
          <Text variant="caption" className="ty-account-invitation-error">
            {WYSIWYGError}
          </Text>
        )}
        <Text className="ty-account-invitation-radio-heading" variant="h4">
          Redirects To:
        </Text>
        <RadioGroup
          className="ty-account-invitation-url-options"
          name="ty-account-invitation-url-options"
          value={selectedUrlOption}
          onChange={(e) => {
            setSelectedUrlOption(e.target.value as URLOptions);
            if (e.target.value === URLOptions.SelfServe) {
              updateAccountInvitationBlock({
                accountInvitationUrl: donorSSP
              });
            }
          }}
        >
          {hasDonorSsbEnabled && (
            <FormControlLabel
              value={URLOptions.SelfServe}
              control={<Radio />}
              label={<Text variant="body">Donor Self Serve Portal</Text>}
            />
          )}
          <FormControlLabel
            value={URLOptions.Custom}
            control={<Radio />}
            label={<Text variant="body">Custom URL</Text>}
          />
        </RadioGroup>
        {selectedUrlOption === URLOptions.Custom && (
          <Controller
            name="customURL"
            control={control}
            defaultValue={defaults.customUrl}
            render={({
              field: { ref, onChange, onBlur, ...field },
              fieldState: { error }
            }) => (
              <TextField
                {...field}
                id="ty-account-invitation-url-field"
                className="ty-account-invitation-url-field"
                label="Account Invitation URL"
                hiddenLabel
                error={!!error}
                helperText={error?.message ?? null}
                placeholder="Enter Invitation URL"
                onChange={(event) => {
                  onChange(event);
                  setCustomURL(event.target.value);
                }}
                onBlur={() => {
                  onBlur();
                  trigger('customURL').then((isValid: boolean) => {
                    if (isValid) {
                      updateAccountInvitationBlock({
                        accountInvitationUrl: customURL
                      });
                    }
                  });
                }}
              />
            )}
          />
        )}
      </OptionalSection>
    </div>
  );
};

export const ThankYouAccountInvitation = () => {
  const methods = useForm({
    resolver: yupResolver(accountInvitationSchema),
    mode: 'onBlur'
  });

  return (
    <FormProvider {...methods}>
      <ThankYouAccountInvitationView
        formState={methods.formState}
        trigger={methods.trigger}
      />
    </FormProvider>
  );
};
