import { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { useNavigate, useParams } from 'react-router-dom';
import {
  InlineNotification,
  InlineNotificationProps
} from 'components/InlineNotification/InlineNotification';
import Text from 'components/Text';
import { useAppContext } from 'hooks/useAppContext';
import { useGetCampaignNotifications } from 'queries/UseNotifications';
import { Notification, NotificationType } from 'services';
import './CampaignNotifications.scss';
import { makeThresholdNotificationText } from './CampaignNotifications.utils';

/**
 * Houses the logic to show inline notifications such as the number of active
 * A/B tests within the campaign or any A/B tests that have had their
 * thresholds met.
 */
export const CampaignNotifications = () => {
  const navigate = useNavigate();
  const {
    selectedOrganization: { id: selectedOrganizationId }
  } = useAppContext();
  const { campaignId } = useParams();
  const [isExpanded, setIsExpanded] = useState(false);

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const { data: notificationsData, isLoading } = useGetCampaignNotifications({
    campaignId,
    organizationId: selectedOrganizationId
  });

  useEffect(() => {
    if (!isLoading && notificationsData?.length) {
      setNotifications(notificationsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const navigateToAllAbTests = () => navigate('/strategy/ab-test');

  const dismissNotification = (i: number) => {
    setNotifications((prevNotifications) => {
      const filtered = prevNotifications.filter((_n, idx) => idx !== i);

      if (filtered.length <= 1) {
        setIsExpanded(true);
      }

      return filtered;
    });
  };

  const mapNotificationsDataToNotifications = (
    notificationData?: Notification[]
  ) =>
    notificationData?.map((notification, i) => {
      let notificationProps = {} as InlineNotificationProps;

      // determine props based on type of notification
      switch (notification.type) {
        case NotificationType.NumActiveAbTests: {
          notificationProps = {
            header: `You have ${pluralize(
              'test',
              notification.payload.numTests,
              true
            )} running!`,
            subheader: (
              <span>Check in on your A/B Tests and see how they’re doing!</span>
            ),
            ctaText: 'View A/B tests',
            onCtaClick: navigateToAllAbTests
          };
          break;
        }

        case NotificationType.Threshold: {
          notificationProps = {
            header: makeThresholdNotificationText(notification),
            subheader:
              'Your A/B Test has reached its threshold! Let’s take a look at the results to see which form is performing better.',
            ctaText: 'View Test Results',
            onCtaClick: () =>
              navigate(
                `/strategy/ab-test/${notification.payload.abTestId}/overview`
              )
          };
          break;
        }

        default:
          break;
      }

      return (
        <InlineNotification
          key={notification.id}
          onDismiss={() => dismissNotification(i)}
          {...notificationProps}
        />
      );
    });

  return notifications?.length > 0 ? (
    <div className="campaign-notifications">
      {notifications?.length > 1 ? (
        <>
          <Text variant="h3">{notifications?.length} Notifications</Text>
          <div style={{ textAlign: 'right' }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              className="expand-btn"
              component="button"
              onClick={() => setIsExpanded((expanded) => !expanded)}
            >
              <Text variant="h6">{isExpanded ? 'Show Less' : 'Show More'}</Text>
            </Link>
          </div>
        </>
      ) : null}

      <div
        className={clsx('campaign-notifications-cards', {
          'campaign-notifications-cards-collapsed': !isExpanded
        })}
      >
        {mapNotificationsDataToNotifications(
          // if isExpanded, map through all of the notifications,
          // otherwise only the first one
          isExpanded ? notifications : notifications.slice(0, 1)
        )}
        {notifications.length > 1 && !isExpanded && (
          <div className="campaign-notifications-stack-behind" />
        )}
      </div>
    </div>
  ) : null;
};
