import { NotificationUnit, TimeNotificationUnit } from 'types';

export enum NotificationType {
  NumActiveAbTests = 'NumActiveAbTests',
  Threshold = 'Threshold'
}

export type Notification =
  | {
      id: string;
      type: NotificationType.NumActiveAbTests;
      payload: {
        numTests: number;
      };
    }
  | {
      id: string;
      type: NotificationType.Threshold;
      payload: {
        abTestId: string;
        abTestName: string;
        thresholds: Array<{
          unit: NotificationUnit | TimeNotificationUnit;
          amount: number;
        }>;
      };
    };
