import AlignCenter from 'assets/icons/align-center.svg';
import AlignLeft from 'assets/icons/align-left.svg';
import AlignRight from 'assets/icons/align-right.svg';
import Bold from 'assets/icons/bold.svg';
import Color from 'assets/icons/color.svg';
import FontSize from 'assets/icons/font-size.svg';
import Font from 'assets/icons/font.svg';
import Image from 'assets/icons/image.svg';
import Italic from 'assets/icons/italic.svg';
import Ol from 'assets/icons/ol.svg';
import Paragraph from 'assets/icons/paragraph.svg';
import Table from 'assets/icons/table.svg';
import Ul from 'assets/icons/ul.svg';
import Underline from 'assets/icons/underline.svg';

const icons = {
  bold: Bold,
  italic: Italic,
  underline: Underline,
  font: Font,
  fontsize: FontSize,
  paragraph: Paragraph,
  left: AlignLeft,
  center: AlignCenter,
  right: AlignRight,
  ul: Ul,
  ol: Ol,
  table: Table,
  image: Image,
  brush: Color
};

export default icons;
