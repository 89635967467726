import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState
} from 'react';
import { Box, Stack, Tab, TabProps } from '@mui/material';
import clsx from 'clsx';
import Breadcrumbs, { BreadcrumbsProps } from 'components/Breadcrumbs';
import Button, { ButtonProps } from 'components/Button';
import { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Menu, { MenuItemProps } from 'components/Menu';
import Tabs from 'components/Tabs';
import Text, { EditableText } from 'components/Text';
import { EditableTextProps } from 'components/Text/EditableText';
import { useAppContext } from 'hooks/useAppContext';
import {
  OrganizationRole,
  getRoleInt,
  useRenderIfRole
} from 'hooks/useRenderIfRole';
import './FeatureHeader.scss';

type CampaignSubtitle = {
  campaignTitle?: string;
  customTitle?: React.ReactNode;
};

type FeatureHeaderProps = {
  activeTab?: string;
  setActiveTab?: Dispatch<SetStateAction<string>>;
  titleProps: string | EditableTextProps;
  subtitleProps?: CampaignSubtitle;
  breadcrumbsProps?: BreadcrumbsProps;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  featureMenuItems?: MenuItemProps[];
  tabsProps?: TabProps[];
  isLoaded?: boolean;
  orgLogoUrl?: string;
  hoverThemeVariant?: boolean;
  nameIsDuplicate?: boolean;
  children?: JSX.Element;
  className?: string;
};

export const FeatureHeader = ({
  activeTab,
  setActiveTab,
  titleProps,
  subtitleProps,
  breadcrumbsProps,
  primaryButtonProps,
  secondaryButtonProps,
  featureMenuItems,
  tabsProps,
  isLoaded,
  orgLogoUrl,
  hoverThemeVariant,
  nameIsDuplicate,
  children,
  className
}: FeatureHeaderProps): JSX.Element => {
  const [name, setName] = useState<string>();
  const { selectedOrganization } = useAppContext();
  const { renderIfRole } = useRenderIfRole();
  const permissionToEdit =
    getRoleInt(selectedOrganization.role) >=
    getRoleInt(OrganizationRole.Editor);
  const displayedTitle =
    typeof titleProps === 'string' ? titleProps : titleProps.value;
  const [anchorEl, setAnchorEl] = React.useState<
    null | (EventTarget & HTMLButtonElement)
  >(null);
  useEffect(() => {
    if (typeof titleProps !== 'string') {
      setName(titleProps.value);
    }
  }, [titleProps]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Hides the image if there's an error loading it
  const handleImgLoadError = (
    event: SyntheticEvent<HTMLImageElement, Event>
  ) => {
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.hidden = true;
  };

  const renderLogo = (logoUrl: string) => (
    <img
      alt="organization logo"
      className="org-logo"
      src={logoUrl}
      loading="lazy"
      onError={handleImgLoadError}
    />
  );

  const featureTitle =
    typeof titleProps === 'string' || !permissionToEdit ? (
      <Text variant="h1">{displayedTitle}</Text>
    ) : (
      <Box className="editable-text-container">
        <EditableText
          value={name}
          variant={titleProps?.variant}
          textFieldProps={{
            ...titleProps?.textFieldProps,
            className: clsx(
              'editable-text-field',
              titleProps?.textFieldProps?.className
            ),
            onChange: (e) => {
              setName(e.target.value);
            },
            helperText:
              isLoaded &&
              titleProps?.textFieldProps?.error &&
              'This name is already being used.',
            error:
              (name === '' || titleProps?.textFieldProps?.error) && isLoaded
          }}
        />
      </Box>
    );

  const featureSubtitle = subtitleProps?.campaignTitle ? (
    <Text variant="body">
      Campaign: <b>{subtitleProps.campaignTitle}</b>
    </Text>
  ) : (
    <Text variant="body">{subtitleProps?.customTitle}</Text>
  );

  const hasSubtitle = !!subtitleProps;
  const hasTabs = !!tabsProps?.length;

  const containerClassName = clsx('feature-header-container', {
    'feature-header-container--subtitle': hasSubtitle && !hasTabs,
    'feature-header-container--tab': hasTabs && !hasSubtitle,
    'feature-header-container--subtitle-and-tab': hasTabs && hasSubtitle,
    'feature-header-container-hover-theme-variant': hoverThemeVariant,
    [className]: !!className
  });

  return (
    <Box className={containerClassName}>
      <Box
        className={clsx('feature-header fluid-container', {
          'feature-header-hover-theme-variant': hoverThemeVariant,
          'feature-header-hover-theme-variant-with-logo': orgLogoUrl
        })}
      >
        <Stack className="text-container">
          {!!breadcrumbsProps && (
            <Box className="breadcrumb-container">
              <Breadcrumbs {...breadcrumbsProps} />
            </Box>
          )}
          <Stack
            className={clsx('title-container', {
              'title-container-hover-theme-variant': hoverThemeVariant
            })}
            direction="row"
            alignItems={nameIsDuplicate ? 'flex-start' : 'center'}
            justifyContent="flex-start"
            spacing={1}
          >
            {orgLogoUrl && renderLogo(orgLogoUrl)}
            {featureTitle}
            {!!featureMenuItems?.length && (
              <>
                <IconButton
                  label="Feature Header Menu"
                  className="menu-icon"
                  icon={ICONS.DOT_CIRCLE_HORIZONTAL}
                  onClick={(event) => handleClick(event)}
                />
                <Menu
                  menuItems={featureMenuItems.map((menuItem) => ({
                    ...menuItem,
                    onClick: () => {
                      if (menuItem.onClick) {
                        menuItem.onClick();
                      }
                      handleClose();
                    }
                  }))}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                />
              </>
            )}
          </Stack>
          {!!subtitleProps && !nameIsDuplicate && featureSubtitle}
        </Stack>
        {!!children && children}
        <Stack
          className={clsx('button-container', {
            'button-container-hover-theme-variant': hoverThemeVariant
          })}
          direction="row"
          spacing={1}
        >
          {!!secondaryButtonProps && (
            <Button variant="secondary" {...secondaryButtonProps} />
          )}
          {!!primaryButtonProps &&
            renderIfRole(
              <Button
                {...primaryButtonProps}
                disabled={primaryButtonProps.disabled || name === ''}
              />,
              OrganizationRole.Editor
            )}
        </Stack>
      </Box>
      {!!tabsProps?.length && (
        <Tabs value={activeTab} centered>
          {tabsProps.map((tabProps, index) => (
            <Tab
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              {...tabProps}
              onClick={() => setActiveTab(tabProps?.value)}
            />
          ))}
        </Tabs>
      )}
    </Box>
  );
};
