import { Outlet } from 'react-router-dom';
import { AppHeader } from 'components/gms/AppHeader';
import { GlobalAlerts } from 'hooks';
import AppContainer from './AppContainer';
import ContentContainer from './ContentContainer';

export const AppLayout = (): JSX.Element => (
  <AppContainer>
    <AppHeader />
    <GlobalAlerts />
    <ContentContainer>
      <Outlet />
    </ContentContainer>
  </AppContainer>
);
