import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import Providers from './Providers';
import './_main.scss';
import reportWebVitals from './reportWebVitals';
import { AppRoutes } from './routes';
import { resolvedEnvironment } from './services/environment';
import { preloadAuthenticator } from './services/preloadAuthenticator';

// boot the React application
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadReactRender = async (envConfig: Record<string, any>) => {
  ReactDOM.render(
    <React.StrictMode>
      <Providers envConfig={envConfig}>
        <AppRoutes />
      </Providers>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

window.onload = async () => {
  // ensure environment is loaded before app boot continues
  // add sentry logging
  Sentry.init({
    dsn: resolvedEnvironment.sentry.dsn,
    integrations: [new BrowserTracing()],
    environment: resolvedEnvironment.sentry.environment,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });

  // bail out if user is unauthenticated
  const loggedIn = await preloadAuthenticator();

  if (loggedIn) {
    loadReactRender(resolvedEnvironment);
  } else {
    // TODO : give user a way to solve this instead of leaving them on a blank
    //        screen.
    throw new Error('Authentication Failure');
  }

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
};
