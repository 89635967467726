import { useCallback, useState } from 'react';
import { Stack } from '@mui/material';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import Text from 'components/Text';
import { LimitedTextField } from 'components/TextField';
import { useConfigContext } from 'hooks/useConfigContext';
import { BlockTypes, IEmailOptInBlock, IGivingFormConfig } from 'types';
import OptionalSection from '../OptionalSection';
import './EmailOptIn.scss';
import { EmailOptInFieldTypes } from './EmailOptIn.types';

const DEFAULT_PROMPT = 'Stay up to date on upcoming news and events!';
const DEFAULT_MESSAGE = 'I would like to receive email notifications.';

const EmailOptIn = (): JSX.Element => {
  const {
    configData: { config },
    updateConfig
  } = useConfigContext<IGivingFormConfig>();
  const { control } = useFormContext();

  const getEmailOptInBlock = useCallback((): [IEmailOptInBlock, number] => {
    const newEmailOptInBlock = config.blocks.find(
      (block) => block.blockType === 'EmailOptInBlock'
    ) as IEmailOptInBlock;
    const newEmailOptInBlockIndex = config.blocks.indexOf(newEmailOptInBlock);

    return [newEmailOptInBlock, newEmailOptInBlockIndex];
  }, [config.blocks]);

  const [emailOptInBlock, emailOptInBlockIndex] = getEmailOptInBlock();

  const buildInitialValues = () => ({
    ...emailOptInBlock,
    prompt: emailOptInBlock?.prompt ?? DEFAULT_PROMPT,
    message: emailOptInBlock?.message ?? DEFAULT_MESSAGE
  });

  const [initialValues, setInitialValues] = useState<IEmailOptInBlock>(
    buildInitialValues()
  );

  const updateEmailOptInBlockConfig = (
    newEmailOptInBlockConfig: IEmailOptInBlock
  ) => {
    const newBlocks = [...config.blocks];

    if (emailOptInBlock) {
      newBlocks.splice(emailOptInBlockIndex, 1, newEmailOptInBlockConfig);
    } else {
      const newEmailOptInBlock: IEmailOptInBlock = {
        ...newEmailOptInBlockConfig,
        // id: 'email-opt-in',
        id: uuid(),
        blockType: BlockTypes.EmailOptInBlock
      };
      newBlocks.push(newEmailOptInBlock);
    }

    const newConfig: IGivingFormConfig = {
      ...config,
      blocks: newBlocks
    };

    updateConfig(newConfig);
  };

  const deleteEmailOptInBlock = () => {
    const newBlocks = [...config.blocks];

    newBlocks.splice(emailOptInBlockIndex, 1);

    const newConfig: IGivingFormConfig = {
      ...config,
      blocks: newBlocks
    };

    // Reset initialValues to current state to keep correct state if someone accidentally turns email opt-in off and back on
    setInitialValues(buildInitialValues());
    updateConfig(newConfig);
  };

  const handleEnableEmailOptIn = () => {
    const newEmailOptInBlock: IEmailOptInBlock = {
      ...initialValues
    };

    if (emailOptInBlock) {
      deleteEmailOptInBlock();
    } else {
      updateEmailOptInBlockConfig(newEmailOptInBlock);
    }
  };

  const handleChange = (
    blockFieldName: string,
    content: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newEmailOptInBlock = {
      ...emailOptInBlock,
      [blockFieldName]: content
    };

    if (blockFieldName === EmailOptInFieldTypes.MESSAGE && !content) {
      newEmailOptInBlock[blockFieldName] = DEFAULT_MESSAGE;
    }

    updateEmailOptInBlockConfig(newEmailOptInBlock);
  };

  return (
    <Stack className="element-library-email-opt-in" spacing={1}>
      <OptionalSection
        title="Show Email Opt-In"
        initiallySelected={Boolean(emailOptInBlock)}
        onChange={handleEnableEmailOptIn}
      >
        <Stack
          className="element-library-email-opt-in-input-wrapper"
          spacing={1}
        >
          <Text variant="h5" className="email-opt-in-prompt-header">
            Prompt Name
          </Text>
          <Controller
            name="emailOptInPrompt"
            control={control}
            defaultValue={initialValues?.prompt ?? ''}
            render={({
              field: { ref, onChange: onChangeControl, ...field },
              fieldState: { error }
            }) => (
              <LimitedTextField
                {...field}
                error={!!error}
                fullWidth
                maxChar={50}
                minRows={2}
                multiline
                placeholder={DEFAULT_PROMPT}
                onChange={(e) => onChangeControl(e.target.value)}
                onBlur={() =>
                  handleChange(EmailOptInFieldTypes.PROMPT, field.value)
                }
              />
            )}
          />

          <Text variant="h5" className="email-opt-in-message-header">
            Opt-In Message
          </Text>
          <Controller
            name="emailOptInMessage"
            control={control}
            defaultValue={
              initialValues?.message === DEFAULT_MESSAGE
                ? ''
                : initialValues?.message ?? ''
            }
            render={({
              field: { ref, onChange: onChangeControl, ...field },
              fieldState: { error }
            }) => (
              <LimitedTextField
                {...field}
                required
                error={!!error}
                fullWidth
                maxChar={300}
                minRows={3}
                multiline
                placeholder={DEFAULT_MESSAGE}
                onChange={(e) => onChangeControl(e.target.value)}
                onBlur={() =>
                  handleChange(EmailOptInFieldTypes.MESSAGE, field.value)
                }
              />
            )}
          />
        </Stack>
      </OptionalSection>
    </Stack>
  );
};

export default (): JSX.Element => {
  const methods = useForm({
    mode: 'onBlur'
  });

  return (
    <FormProvider {...methods}>
      <EmailOptIn />
    </FormProvider>
  );
};
