import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import './DeleteBlockModal.scss';

export type DeleteModalConfig = {
  open: boolean;
  id?: string;
};

type DeleteBlockModalProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

const DeleteBlockModal = ({
  open,
  onConfirm,
  onClose
}: DeleteBlockModalProps) => (
  <Dialog open={open} label="Delete Modal" onClose={onClose}>
    <DialogTitle
      onClick={(close) => close()}
      className="DeleteBlockModal-Title"
    >
      Changes will not be saved
    </DialogTitle>
    <DialogContent>
      <DialogContentTitle>Are you sure?</DialogContentTitle>
      <DialogContentDescription>
        If you delete this section, any changes you&apos;ve made on this element
        will not be saved and it will be removed from your form.
      </DialogContentDescription>
    </DialogContent>
    <DialogActions className="DeleteBlockModal-ActionContainer">
      <DialogActionButton
        onClick={(close) => {
          onConfirm();
          close();
        }}
        variant="primary"
      >
        Yes, Delete Section
      </DialogActionButton>
      <DialogActionButton onClick={(close) => close()} variant="secondary">
        No, Cancel Changes
      </DialogActionButton>
    </DialogActions>
  </Dialog>
);

export default DeleteBlockModal;
