import { FC, forwardRef } from 'react';
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps
} from '@mui/material';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip';

type IconButtonProps = MuiIconButtonProps & {
  icon: FC;
  label: string;
  tooltipLabel?: string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'basic';
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, label, tooltipLabel = '', ...props }, ref): JSX.Element => {
    const wrapWithTooltip = (iconButton: React.ReactElement) => (
      <Tooltip title={tooltipLabel}>{iconButton}</Tooltip>
    );

    const iconButton = (
      <MuiIconButton ref={ref} aria-label={label} {...props}>
        <Icon icon={icon} />
      </MuiIconButton>
    );

    return tooltipLabel ? wrapWithTooltip(iconButton) : iconButton;
  }
);
export default IconButton;
