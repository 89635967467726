import { Theme, createTheme, lighten } from '@mui/material';

export const createTabsTheme = (currTheme: Theme): Theme => {
  const theme = createTheme(currTheme, {
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: 3
          },
          flexContainer: {
            gap: '2.5rem'
          }
        },
        defaultProps: {
          allowScrollButtonsMobile: true,
          scrollButtons: true
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            ...currTheme.typography.h3,
            color: lighten(currTheme.palette.darkGray.main, 0.4),
            padding: '1.125rem 0',
            minWidth: 0,
            '&.Mui-disabled': {
              color: lighten(currTheme.palette.darkGray.main, 0.7)
            }
          }
        },
        defaultProps: {
          disableRipple: true
        }
      }
    }
  });

  return theme;
};
