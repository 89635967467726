import { useQuery } from 'react-query';
import { Notification } from 'services';
import { getCampaignNotifications } from 'services/notificationsService';

export const useGetCampaignNotifications = ({
  organizationId,
  campaignId
}: {
  organizationId: string;
  campaignId: string;
}) =>
  useQuery<Notification[], Error>(
    ['getCampaignNotifications', { organizationId, campaignId }],
    () => getCampaignNotifications({ organizationId, campaignId })
  );
