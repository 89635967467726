import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { ICONS, LimitedTextField } from 'components';
import Button from 'components/Button';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import WYSIWYG, { Tag, TagSection } from 'components/WYSIWYG';
import DeleteBlockModal from 'components/gms/DeleteBlockModal/DeleteBlockModal';
import { useOrgCustomFields } from 'hooks';
import { useConfigContext } from 'hooks/useConfigContext';
import {
  CustomContentBlockType,
  EmailBlockTypes,
  EmailConfig
} from 'types/emailTypes';
import { createEmailTagList } from 'utils/placeholderUtils';
import '../GivingFormViews/EditCustomContent.scss';

const NAME_MAX_CHARS = 50;

type EditCustomContentItemProps = {
  option: string;
  options: CustomContentBlockType[];
  name: string;
  open: boolean;
  setOpenId: (id: string) => void;
  onUpdate: (html: string, name: string, id?: string) => void;
  onDelete: (id: string) => void;
  index: number;
  id?: string;
};

const EditCustomContentItem = ({
  option,
  options,
  name: optionName,
  open,
  setOpenId,
  onUpdate,
  onDelete,
  index,
  id
}: EditCustomContentItemProps): JSX.Element => {
  const {
    configData: { config },
    setHighlightedBlock
  } = useConfigContext<EmailConfig>();
  const orgCustomFields = useOrgCustomFields();

  const [html, setHtml] = useState<string>(option);
  const [name, setName] = useState<string>(optionName);
  const [uniqueNameError, setUniqueNameError] = useState<boolean>(false);

  const handleNameChange = (newName: string) => {
    if (options.find((block) => id !== block.id && block.name === newName)) {
      setUniqueNameError(true);
    } else {
      setUniqueNameError(false);
      setName(newName);
    }
  };

  const disableButton =
    !html ||
    (html === option && name === optionName) || // Disable if html AND name are unchanged
    uniqueNameError || // Disable if there is a unique name error
    name.trim() === '' || // Disable if the name field is empty
    html === ''; // Disable if HTML is "empty" // TODO: May need to handle html empty tags differently later if WYSIWYG behavior is changed

  return (
    <Accordion className="edit-custom-content" expanded={open}>
      <AccordionSummary
        className={`edit-custom-content-summary ${open ? 'expanded' : ''}`}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={0.25} alignItems="center">
            <Text variant="h3">
              {index === null || open ? null : `${optionName}`}
            </Text>{' '}
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            {!open && (
              <IconButton
                label="edit"
                variant="basic"
                size="small"
                icon={ICONS.PENCIL}
                onClick={() => {
                  setOpenId(id);
                  setHighlightedBlock(id);
                }}
              />
            )}
            <IconButton
              label="delete"
              variant="basic"
              size="small"
              icon={ICONS.TRASH}
              onClick={() => {
                setHighlightedBlock('');
                onDelete(id);
              }}
            />
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        className={`edit-custom-content-details ${open ? 'expanded' : ''}`}
      >
        <Stack spacing={0.5}>
          <Text variant="h3">Name This Custom Content</Text>
          <LimitedTextField
            hiddenLabel
            fullWidth
            value={name}
            maxChar={NAME_MAX_CHARS}
            error={uniqueNameError}
            onChange={(event) => {
              handleNameChange(event.target.value);
            }}
            helperText={uniqueNameError && 'Name must be unique'}
            className="custom-name-input"
          />
          <Box className="edit-custom-content-WYSIWYG-container">
            {open && ( // Only render when `open` is true, so we don't load too many heavy (WYSIWYG) components
              <WYSIWYG
                defaultStyles={{
                  fontFamily: config.theme.font,
                  color: config.theme.text
                }}
                onChange={(newHtml: string) => setHtml(newHtml)}
                value={html}
                tagList={
                  createEmailTagList(orgCustomFields, true) as TagSection[]
                }
                tags={createEmailTagList(orgCustomFields) as Tag[]}
              />
            )}
          </Box>
          <Button
            variant="primary"
            fullWidth
            disabled={disableButton}
            onClick={() => {
              onUpdate(html, name, id);
              setHighlightedBlock('');
            }}
          >
            Update
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const EditCustomContent = (): JSX.Element => {
  const { configData, updateConfig, highlightedBlock } =
    useConfigContext<EmailConfig>();
  const { config } = configData;
  const [openId, setOpenId] = useState<string | null>(null);
  const [idToDelete, setIdToDelete] = useState<string | null>(null);
  const [addNewOpen, setAddNewOpen] = useState<boolean>(false);
  const [customContentBlocks, setCustomContentBlocks] = useState<
    CustomContentBlockType[]
  >([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const contentBlocks = configData.config.blocks.filter(
      (block) => block.blockType === EmailBlockTypes.CustomContent
    ) as CustomContentBlockType[];
    setCustomContentBlocks(contentBlocks);
    setAddNewOpen(contentBlocks.length === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.blocks, config.blocks.length]);

  useEffect(() => {
    setOpenId(highlightedBlock);
    setAddNewOpen(false);
  }, [highlightedBlock]);

  const updateCustomContentConfig = (
    html: string,
    name: string,
    id: string
  ) => {
    const targetBlock = customContentBlocks.find((block) => block.id === id);
    targetBlock.html = html;
    targetBlock.name = name;
    updateConfig(config);
    setOpenId(null);
  };

  const deleteCustomContentConfig = (id: string) => {
    const targetBlockIndex = config.blocks.findIndex(
      (block) => block.id === id
    );
    config.blocks.splice(targetBlockIndex, 1);
    updateConfig(config);
    setOpenId(null);
  };

  const handleAddNewContent = (html: string, name: string) => {
    const id = uuid();
    const newContentBlock: CustomContentBlockType = {
      id,
      html,
      name,
      blockType: EmailBlockTypes.CustomContent
    };
    const newConfig: EmailConfig = {
      ...configData.config,
      blocks: [...config.blocks, newContentBlock]
    };
    updateConfig(newConfig);
    setAddNewOpen(false);
  };

  return (
    <Stack id="element-library-edit-custom-content">
      {(customContentBlocks.length > 0 || addNewOpen) && (
        <Box id="element-library-edit-custom-content-optionsContainer">
          {customContentBlocks.map((option, idx) => (
            <EditCustomContentItem
              option={option.html}
              name={option.name}
              options={customContentBlocks}
              open={openId === option.id}
              setOpenId={(id: string) => {
                setOpenId(id);
                setAddNewOpen(false);
              }}
              onUpdate={updateCustomContentConfig}
              onDelete={() => {
                setIdToDelete(option.id);
                setDeleteModalOpen(true);
              }}
              index={idx}
              id={option.id}
              key={option.id}
            />
          ))}
          {addNewOpen && (
            <EditCustomContentItem
              option=""
              name=""
              options={customContentBlocks}
              open
              setOpenId={null}
              onUpdate={handleAddNewContent}
              onDelete={() => setAddNewOpen(false)}
              index={null}
              id={null}
            />
          )}
        </Box>
      )}
      <Stack
        id="element-library-edit-custom-content-addOption"
        direction="row"
        spacing={0.25}
        alignItems="center"
        className={addNewOpen || openId ? 'disabled' : ''}
      >
        <Icon icon={ICONS.PLUS} fontSize="small" />
        <Text
          variant="h4"
          onClick={() => {
            if (!(addNewOpen || openId)) {
              // Only enable if not already editing something
              setAddNewOpen(true);
            }
          }}
        >
          Add New Content{' '}
        </Text>
      </Stack>

      <DeleteBlockModal
        onConfirm={() => deleteCustomContentConfig(idToDelete)}
        onClose={() => {
          setIdToDelete(null);
          setDeleteModalOpen(false);
        }}
        open={deleteModalOpen}
      />
    </Stack>
  );
};

export default EditCustomContent;
