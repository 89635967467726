import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import { useAppContext } from 'hooks/useAppContext';
import { useGetGivingFormsForReceipt } from 'queries/UseGivingForms';
import { GivingForm } from 'services';
import './ConfirmReceiptEditDialog.scss';

type ConfirmReceiptEditDialogProps = {
  emailId: string;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const ConfirmReceiptEditDialog = ({
  emailId,
  isOpen,
  onConfirm,
  onClose
}: ConfirmReceiptEditDialogProps) => {
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();

  const { data, isLoading, isFetching } = useGetGivingFormsForReceipt({
    organizationId,
    emailId
  });

  return (
    <Dialog
      open={isOpen}
      label="Confirm Receipt Edit"
      onClose={onClose}
      className="confirm-receipt-edit-modal"
    >
      <DialogTitle
        onClick={(close) => close()}
        className="confirm-receipt-edit-modal-title"
      >
        Edit Receipt Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentTitle>
          Are you sure you want to edit this receipt template?
        </DialogContentTitle>
        <DialogContentDescription className="confirm-receipt-edit-form-list">
          Every giving form using this receipt will be updated. The following
          giving forms currently use this receipt:
          <ul>
            {(isLoading || isFetching) && <li>Loading Giving Forms...</li>}
            {data &&
              data.length > 0 &&
              data.map((givingForm: GivingForm) => <li>{givingForm.name}</li>)}
            {data && data.length === 0 && <li>No Giving Forms</li>}
          </ul>
        </DialogContentDescription>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          onClick={(close) => {
            onConfirm();
            close();
          }}
          variant="primary"
        >
          Yes, Edit This Receipt
        </DialogActionButton>
        <DialogActionButton onClick={(close) => close()} variant="secondary">
          No, Don&apos;t Edit
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
