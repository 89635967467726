import React, { createContext, useContext, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { v4 as uuid } from 'uuid';
import Alert, { AlertProps } from 'components/Alert';
import { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';

/**
 * Type for GlobalAlert objects to adhere to. Includes a generated ID, and any valid
 * `Alert` props.
 */
type GlobalAlert = {
  /**
   * UUID to uniquely identify different GlobalAlerts. removeAlert relies on this
   * to consistently remove the correct alert.
   */
  id: string;
  /**
   * The amount of milliseconds to wait before automatically dimissing a given
   * alert. If left undefined alert will persist until manually dismissed.
   */
  autoHideDuration?: number;
  /**
   * A description that gets mapped to an `Alert`'s `children`. Use this to
   * specify any "description" text for a given alert.
   */
  description?: string;
} & AlertProps;

/**
 * `AlertInfo` represents what the `addAlert` method exposed by the Alert Context
 * expects. This is essentially any valid AlertProps, but without the uuid, as that will be
 * automatically generated.
 */
export type AlertInfo = Omit<GlobalAlert, 'id'>;

/**
 * Representation of the API for interfacing with alerts.
 * [`addAlert`, `removeAlert`, `allAlerts`]. This is what
 * is held in the `AlertContext`
 */
type AlertContextType = readonly [
  (alert: AlertInfo) => void,
  (id: string) => void,
  GlobalAlert[]
];

/**
 * The context for tracking and manipulating the global alerts.
 */
const AlertContext = createContext<AlertContextType | null>(null);

/**
 * A hook for interfacing with the alert service. Bring this hook into your component
 * to be able to easily push, remove, or access alerts. Directly interfaces with
 * the `Alert Context`
 */
export const useAlerts = (): AlertContextType => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('Something went wrong. No context found for Alerts.');
  }
  return context;
};

/**
 * Typing for `AlertProvider` props.
 */
type AlertProviderProps = {
  children: React.ReactNode;
};

/**
 * A wrapper for `AlertContext.Provider`. This component should be rendered at the top of the application
 * so all children components are able to access the API it exposes. `AlertProvider` holds the state
 * of all alerts and provides utility functions to easily add and remove any alerts from the screen.
 */
export const AlertProvider = ({
  children
}: AlertProviderProps): JSX.Element => {
  /**
   * The actual state for holding all the alerts
   */
  const [alerts, setAlerts] = useState<GlobalAlert[]>([]);

  /**
   * Memoized value of the `AlertContext` api.
   */
  const value: AlertContextType = useMemo(() => {
    /**
     * A function that will remove any alert with a given UUID.
     */
    const removeAlert = (id: string): void => {
      const index = alerts.findIndex((alert) => alert.id === id);
      if (index >= 0) {
        const alertsCopy = [...alerts];
        alertsCopy.splice(index, 1);
        setAlerts(alertsCopy);
      }
    };
    /**
     * A function that will create and push a provided alert.
     */
    const addAlert = (alert: AlertInfo): void => {
      setAlerts((prev) => [
        ...prev,
        {
          ...alert,
          id: uuid()
        }
      ]);
    };

    /**
     * The memoized value to be passed to the AlertContext provider.
     */
    return [addAlert, removeAlert, alerts] as const;
  }, [alerts]);

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

/**
 * An out of the box consumer for rendering Alerts. Rendering this component in the base
 * of your app will render any alerts regardless of the origin of their push.
 *
 * It's worth noting that this component just utilizes the `useAlerts` hook and renders
 * them on screen. You can leverage the same hook to create the same functionality with whatever custom
 * styling or intercepting functionality that you want.
 */

export const GlobalAlerts = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, removeAlert, alerts] = useAlerts();

  /**
   * Renders a snackbar for each alert in the alerts array. Will autodismiss if a value was provided
   * for the alert, and persist if not.
   *
   * MUI Snackbars require a child `div` to forward a ref to, which is why the Alert's Stack is wrapped in a `div`
   */
  return (
    <Stack
      direction="column"
      spacing={0.5}
      sx={{
        position: 'fixed',
        top: '56px',
        right: '24px',
        width: '416px',
        zIndex: (theme) => theme.zIndex.snackbar
      }}
    >
      {alerts.map(
        ({ id, onClose, description, autoHideDuration, ...alertProps }) => {
          const handleClose = (e: React.SyntheticEvent) => {
            removeAlert(id);
            onClose?.(e);
          };

          return (
            <Snackbar
              key={id}
              open={!!alerts.length}
              autoHideDuration={autoHideDuration}
              onClose={(e, reason) => {
                if (reason !== 'clickaway') {
                  handleClose(e as React.SyntheticEvent);
                }
              }}
              sx={{
                position: 'relative'
              }}
            >
              <div>
                <Alert
                  onClose={handleClose}
                  sx={{
                    width: '416px'
                  }}
                  /**
                   * Override default close icon to not have tooltip
                   */
                  action={
                    <IconButton
                      onClick={handleClose}
                      label="dismiss alert"
                      icon={ICONS.X}
                      size="small"
                    />
                  }
                  {...alertProps}
                >
                  {description}
                </Alert>
              </div>
            </Snackbar>
          );
        }
      )}
    </Stack>
  );
};
