import {
  MutableRefObject,
  forwardRef,
  useEffect,
  useRef,
  useState
} from 'react';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import { Skeleton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import clsx from 'clsx';
import Button from 'components/Button';
import { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import { useAppContext } from 'hooks/useAppContext';
import { FormDataType } from 'hooks/useConfigContext';
import { useEventHubPreview } from 'hooks/useEventHubPreview';
import { useGivingFormByInstanceId } from 'queries/UseGivingForms';
import {
  EditorEventTypes,
  EventHub,
  EventHubEvent,
  GivingFormModes
} from 'services';
import editsRepositoryApi from 'services/editsRepositoryService';
import { AbTest } from 'types';
import { IGivingFormConfig } from 'types/givingFormTypes';
import './PreviewModal.scss';

type PreviewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  configData: FormDataType<IGivingFormConfig>;
  givingFormId: string;
  portalRef: MutableRefObject<HTMLDivElement>;
  // when previewing a published A/B Test, we pass the entire abTest object to the modal
  abTest?: AbTest;
  isAbTestWizard?: boolean;
  // when previewing in the A/B Test wizard, we do not have an abTest
  // object and must fetch configVariantA separately (configData
  // will be configVariantB in thise case)
  configVariantA?: FormDataType<IGivingFormConfig>;
};

const PreviewModal = ({
  isOpen,
  onClose,
  configData,
  givingFormId,
  portalRef,
  abTest,
  isAbTestWizard,
  configVariantA
}: PreviewModalProps) => {
  const [embedReadyToDisplay, setEmbedReadyToDisplay] =
    useState<boolean>(false);
  const [eventHub, setEventHub] = useState<EventHub>();
  const [iframeHeight, setIFrameHeight] = useState<string>('');
  const [previewSize, setPreviewSize] = useState<string>('desktop');
  const [abVariantSelected, setAbVariantSelected] = useState<
    'configVariantA' | 'configVariantB'
  >('configVariantA');
  const previewSizeRef = useRef<string>('desktop');
  const iframeRef = useRef<HTMLIFrameElement>();
  const { envConfig } = useAppContext();
  const iframeSrc = `${envConfig?.appsBaseUrl}/giving-form/edit-${givingFormId}`;
  const iframeUrl = new URL(iframeSrc);
  let lastBodyHeightTS: number; // Track last time a height update occurred for syncing with scroll events

  const iframeReadyCallback = (iframe: EventTarget) => {
    const eventHubRef = new EventHub(iframe, iframeUrl.origin, {
      props: { mode: GivingFormModes.PREVIEW }
    });

    setEventHub(eventHubRef);

    if (configVariantA) {
      eventHubRef.emit(
        EditorEventTypes.ConfigurationUpdate,
        configVariantA.config
      );
    } else if (abTest) {
      eventHubRef.emit(
        EditorEventTypes.ConfigurationUpdate,
        abTest.configVariantA
      );
    } else if (configData.config.blocks) {
      // Before data is loaded, config can be an empty object.  Using presence of `blocks` to determine if real config
      // if we have the config already, can emit right away.  Otherwise if delay getting data, emit will happen elsewhere
      eventHubRef.emit(EditorEventTypes.ConfigurationUpdate, configData.config);
    }

    eventHubRef.subscribe(
      EditorEventTypes.BodyHeightUpdate,
      (ev: EventHubEvent) => {
        setIFrameHeight(ev.payload);
      }
    );

    eventHubRef.subscribe(EditorEventTypes.PageLoaded, () => {
      setEmbedReadyToDisplay(true);
      // iframe will get configData through standard eventHub setup,
      // which in the case of A/B tests, will be variant B.
      // Variant A should be shown by default when the modal
      // loads, so we are emitting an additional configuration update
      // after the page loads to render variant A if we are
      // previewing an A/B test
      if (abTest) {
        eventHubRef.emit(
          EditorEventTypes.ConfigurationUpdate,
          abTest.configVariantA
        );
      } else if (isAbTestWizard) {
        eventHubRef.emit(
          EditorEventTypes.ConfigurationUpdate,
          configVariantA.config
        );
      }
    });

    eventHubRef.subscribe(
      EditorEventTypes.FormPageChange,
      (ev: EventHubEvent) => {
        const scrollPosition = ev.payload;

        const portalScroll = (blockValue: ScrollLogicalPosition) => {
          if (previewSizeRef.current === 'mobile') {
            let top = 0;
            if (blockValue === 'end') {
              top = portalRef.current.getBoundingClientRect().height;
            }
            // setTimeout in order to give enough time for iframe's scrollIntoView jump to complete
            setTimeout(() => {
              portalRef.current.scrollTo({
                behavior: 'smooth',
                top
              });
            }, 150);
          }
        };

        const iframeScroll = (blockValue: ScrollLogicalPosition) => {
          // When in mobile view, do an immediate scroll jump and then allow the portal/phone frame to scroll smoothly.  May not be possible to let both scroll smoothly
          const behavior =
            previewSizeRef.current === 'mobile' ? 'auto' : 'smooth';
          iframeRef.current.scrollIntoView({
            block: blockValue,
            inline: 'start',
            behavior
          });
        };

        // The scroll event is initially received prior to body height updates, need to ensure we take action after that occurs
        const currentTime = Date.now();
        const WAIT_FOR_HEIGHT_EVENT_TIMEOUT = 1000;
        const id = setInterval(() => {
          // Take action once body height event has occurred, or until we reach a timeout
          if (
            lastBodyHeightTS > currentTime ||
            Date.now() - currentTime > WAIT_FOR_HEIGHT_EVENT_TIMEOUT
          ) {
            clearInterval(id);
            // Need iframe position to determine if top/bottom has left the viewport
            const { top: topPosition, bottom: bottomPosition } =
              iframeRef.current.getBoundingClientRect();

            if (scrollPosition === 'top') {
              // Only scroll if the top of the embed is off screen
              if (
                topPosition < 0 ||
                topPosition > document.documentElement.clientHeight
              ) {
                iframeScroll('start');
              }
              portalScroll('start');
            } else if (scrollPosition === 'bottom') {
              // Only scroll if the bottom of the embed is off screen
              if (
                bottomPosition < 0 ||
                bottomPosition > document.documentElement.clientHeight
              ) {
                iframeScroll('end');
              }
              portalScroll('end');
            }
          }
        }, 250);
      }
    );

    return eventHubRef;
  };

  useEventHubPreview({
    iframeRef,
    configData,
    iframeReadyCallback
  });

  const onChange = (
    _event: React.MouseEvent<HTMLElement>,
    variant: 'configVariantA' | 'configVariantB'
  ) => {
    if (variant !== null) {
      setAbVariantSelected(variant);
      if (isAbTestWizard) {
        const currentVariant =
          variant === 'configVariantA'
            ? configVariantA.config
            : configData.config;
        eventHub?.emit('ConfigurationUpdate', currentVariant);
      } else {
        eventHub?.emit('ConfigurationUpdate', abTest[variant]);
      }
    }
  };

  return (
    <div
      className="preview-modal"
      style={{ display: isOpen ? 'flex' : 'none' }}
    >
      <div className="preview-modal-header">
        <div className="preview-modal-buttons-left">
          {(abTest?.abTestId || isAbTestWizard) && (
            <ToggleButtonGroup
              aria-label="Variant Selection"
              color="primary"
              exclusive
              onChange={onChange}
              size="small"
              value={abVariantSelected}
            >
              <ToggleButton
                aria-label="Form A"
                className="left-toggle"
                value="configVariantA"
              >
                Form A
              </ToggleButton>
              <ToggleButton
                aria-label="Form B"
                className="right-toggle"
                value="configVariantB"
              >
                Form B
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </div>
        <div className="preview-modal-buttons-right">
          <IconButton
            icon={ICONS.DEVICE_MOBILE}
            label="Mobile Preview"
            onClick={() => {
              setPreviewSize('mobile');
              previewSizeRef.current = 'mobile';
            }}
            variant={previewSize === 'mobile' ? 'primary' : 'secondary'}
          />
          <IconButton
            icon={ICONS.DEVICE_DESKTOP}
            label="Desktop Preview"
            onClick={() => {
              setPreviewSize('desktop');
              previewSizeRef.current = 'desktop';
            }}
            variant={previewSize === 'desktop' ? 'primary' : 'secondary'}
          />
          <Button
            className="preview-modal-exit-button"
            onClick={() => onClose()}
            variant="secondary"
          >
            Exit Preview Mode
          </Button>
        </div>
      </div>

      <div
        className={clsx(
          { 'mobile-preview': previewSize === 'mobile' },
          'preview-modal-content'
        )}
      >
        {!embedReadyToDisplay && (
          <div
            className={clsx('preview-modal-skeleton-container', {
              'mobile-skeleton': previewSize === 'mobile'
            })}
          >
            <Skeleton animation="wave" className="xsmall" />
            <Skeleton animation="wave" className="xsmall" />
            <Skeleton animation="wave" className="small" />
            <Skeleton animation="wave" className="small" />
            <Skeleton animation="wave" className="large" />
            <Skeleton animation="wave" className="medium" />
            <Skeleton animation="wave" className="medium" />
            <Skeleton animation="wave" className="xlarge" />
            <Skeleton animation="wave" className="large" />
            <Skeleton animation="wave" className="medium" />
          </div>
        )}
        <div
          className={clsx(
            { mobile: previewSize === 'mobile' },
            'iframe-wrapper'
          )}
          style={{ display: embedReadyToDisplay ? 'block' : 'none' }}
        >
          <iframe
            className={clsx({
              'iframe-mobile-preview': previewSize === 'mobile'
            })}
            id="previewPreviewFrame"
            name="previewPreviewFrame"
            title="The Real Preview"
            src={iframeSrc}
            frameBorder="0"
            allow="payment"
            sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation allow-modals allow-downloads allow-popups"
            style={{
              visibility:
                !iframeHeight || !embedReadyToDisplay ? 'hidden' : 'visible',
              height: iframeHeight
            }}
            ref={iframeRef}
          />
        </div>
      </div>
    </div>
  );
};

const PreviewBackdrop = forwardRef<HTMLDivElement>((props, ref) => (
  <div className="preview-modal-backdrop" ref={ref} />
));

export const Preview = ({
  isOpen,
  onClose,
  configData: configDataProp,
  givingFormId,
  abTest,
  isAbTestWizard
}: {
  isOpen: boolean;
  onClose: () => void;
  configData: FormDataType<IGivingFormConfig> | null;
  givingFormId: string;
  abTest?: AbTest;
  isAbTestWizard?: boolean;
}) => {
  // Since we can't conditionally use a react hook, we retrieve the BE giving form even when not needed:
  const { isLoading, data: backendGivingForm } =
    useGivingFormByInstanceId(givingFormId);

  // When in GivingFormEditor, configData will always be the config currently being edited;
  // this means, in the A/B Test Wizard, configData is configVariantB.
  // As we do not have an abTest object while in the wizard (since it hasn't been published),
  // we need to separately provide configVariantA to the PreviewModal, which is the backendGivingForm
  const [configData, setConfig] =
    useState<FormDataType<IGivingFormConfig>>(configDataProp);
  const [configVariantA, setConfigVariantA] =
    useState<FormDataType<IGivingFormConfig>>(null);
  const portalRef = useRef<HTMLDivElement>();

  // When configData is provided, we use that as the data
  useEffect(() => {
    if (configDataProp !== null && configDataProp !== configData) {
      setConfig(configDataProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configDataProp]);

  // When configData is not provided, we retrieve and use our own data
  useEffect(() => {
    if (isAbTestWizard) {
      setConfigVariantA({
        ...backendGivingForm,
        seedConfigPublishedAt: new Date()
      });
    }
    // If we were given a config or we already have it, do nothing
    if (configData !== null) {
      return;
    }

    // config is null, indicating we need to retrieve our own config
    const getConfig = async () => {
      // First, check if we have an edit:
      const editConfig =
        await editsRepositoryApi.getEditsById<IGivingFormConfig>(
          'givingForm',
          givingFormId
        );
      if (editConfig === null) {
        // there is no edit, use the BE result once it loads
        if (!isLoading) {
          setConfig({
            ...backendGivingForm,
            seedConfigPublishedAt: new Date()
          });
        }
      } else {
        // there is an edit to use
        setConfig(editConfig);
      }
    };
    getConfig();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <ModalUnstyled
      open={isOpen}
      components={{
        Backdrop: PreviewBackdrop
      }}
      classes={{ root: 'preview-modal-portal' }}
      ref={portalRef}
    >
      <div>
        <PreviewModal
          isOpen={isOpen}
          onClose={() => onClose()}
          configData={configData}
          givingFormId={givingFormId}
          portalRef={portalRef}
          abTest={abTest}
          isAbTestWizard={isAbTestWizard}
          configVariantA={configVariantA}
        />
      </div>
    </ModalUnstyled>
  );
};
