import { MenuItem } from '@mui/material';
import clsx from 'clsx';
import Icon, { ICONS } from 'components/Icon';
import { IOrganization } from 'services/types/userTypes';

type OrgItemProps = {
  organization: IOrganization;
  isSelectedOrg(org: IOrganization): boolean;
  isFocusedOrg: boolean;
  handleOrgSelect(org: IOrganization): void;
};

export const OrgItem = ({
  organization,
  isSelectedOrg,
  isFocusedOrg,
  handleOrgSelect
}: OrgItemProps) => (
  <MenuItem
    className={clsx({
      selected: isSelectedOrg(organization),
      focused: isFocusedOrg
    })}
    key={organization.id}
    onClick={() => handleOrgSelect(organization)}
  >
    <div className="org-list-item-title" title={organization.name}>
      {organization.name}
    </div>
    {isSelectedOrg(organization) && (
      <div className="org-list-item-selected">
        <Icon
          className="icon-badge"
          icon={ICONS.DODECAGON}
          color="primary"
          viewBox="0 0 142 142"
        />
        <Icon className="icon-check" icon={ICONS.CHECK} />
      </div>
    )}
  </MenuItem>
);
