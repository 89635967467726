import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'components/Select';
import Text from 'components/Text';
import { NumberTextField } from 'components/TextField';
import { NotificationTypes } from 'utils';
import './AbTestNotificationCriteria.scss';

const NOTIFICATION_OPTIONS = [
  {
    label: 'Select',
    value: 'Select'
  },
  {
    label: 'Visit(s)',
    value: 'visit'
  },
  {
    label: 'Donation(s)',
    value: 'donation'
  }
];

const TIME_NOTIFICATION_OPTIONS = [
  {
    label: 'Select',
    value: 'Select'
  },
  {
    label: 'Day(s)',
    value: 'day'
  },
  {
    label: 'Week(s)',
    value: 'week'
  },
  {
    label: 'Month(s)',
    value: 'month'
  }
];

interface AbTestNotificationCriteriaProps {
  variant: NotificationTypes;
  onValueChange?(triggerName: string): void;
  defaultAmount: number;
  defaultUnit: string;
}

export const AbTestNotificationCriteria = ({
  variant,
  onValueChange,
  defaultAmount,
  defaultUnit
}: AbTestNotificationCriteriaProps): JSX.Element => {
  const { control } = useFormContext();

  const metricBasedProps = {
    componentClassName: 'ab-test-notification',
    controllerName: 'notificationUnit',
    textFieldClassName: 'ab-test-notification-textfield',
    selectControllerName: 'notificationUnit',
    triggerName: 'notificationUnit',
    options: NOTIFICATION_OPTIONS
  };

  const timeBasedProps = {
    componentClassName: 'ab-test-time-notification',
    controllerName: 'timeNotificationUnit',
    textFieldClassName: 'ab-test-time-notification-textfield',
    selectControllerName: 'timeNotificationUnit',
    triggerName: 'timeNotificationUnit',
    options: TIME_NOTIFICATION_OPTIONS
  };

  const props =
    variant === NotificationTypes.MetricBased
      ? metricBasedProps
      : timeBasedProps;

  return (
    <div className={props.componentClassName}>
      <Controller
        name={`${props.controllerName}Amount`}
        control={control}
        defaultValue={defaultAmount}
        render={({
          field: { ref, onChange, ...field },
          fieldState: { error }
        }) => (
          <NumberTextField
            {...field}
            inputProps={{
              'aria-label': 'notificationAmount'
            }}
            placeholder="Number"
            className={props.textFieldClassName}
            error={!!error}
            helperText={error?.message}
            onBlur={() => {
              onValueChange?.(props.controllerName);
            }}
            onChange={(value) => {
              onChange(value === '' ? null : value);
              onValueChange?.(props.controllerName);
            }}
          />
        )}
      />
      <Controller
        name={props.selectControllerName}
        control={control}
        defaultValue={defaultUnit}
        render={({
          field: { ref, onChange, value, ...field },
          fieldState: { error }
        }) => (
          <div className="ab-test-notification-select">
            <Select
              {...field}
              inputProps={{
                'aria-label': 'notificationUnit'
              }}
              value={value}
              className={clsx('ab-test-select', {
                'unit-select-default': value === 'Select'
              })}
              defaultValue={defaultUnit}
              error={!!error}
              options={props.options}
              onChange={async (e) => {
                onChange(e);
                onValueChange?.(`${props.controllerName}Amount`);
              }}
            />
            <Text variant="caption" className="ab-test-error">
              {error?.message}
            </Text>
          </div>
        )}
      />
    </div>
  );
};
