/**
 * This component shows examples of using external svgs, and icon packages
 */
import {
  IconActivity,
  IconAlarm,
  IconAlertCircle,
  IconAlertTriangle,
  IconAlien,
  IconArchive,
  IconArrowBackUp,
  IconArrowDownRight,
  IconArrowUpRight,
  IconAward,
  IconBellRinging,
  IconBlockquote,
  IconBold,
  IconBrackets,
  IconBuildingSkyscraper,
  IconCalendarPlus,
  IconCalendarStats,
  IconCertificate,
  IconChartInfographic,
  IconChartLine,
  IconCheck,
  IconChevronDown,
  IconChevronRight,
  IconChevronUp,
  IconCircle,
  IconCircleCheck,
  IconCircleSquare,
  IconClick,
  IconClipboardX,
  IconCode,
  IconCoin,
  IconComet,
  IconCopy,
  IconCreditCard,
  IconCurrencyDollar,
  IconDeviceDesktop,
  IconDeviceMobile,
  IconDots,
  IconDotsCircleHorizontal,
  IconExclamationMark,
  IconEye,
  IconFile,
  IconFileCode,
  IconFileInfo,
  IconFileText,
  IconGift,
  IconHash,
  IconHelp,
  IconHome,
  IconIceCream2,
  IconInfoCircle,
  IconItalic,
  IconLayoutBottombar,
  IconLayoutNavbar,
  IconLink,
  IconList,
  IconListNumbers,
  IconLock,
  IconMail,
  IconMailOpened,
  IconMessage2,
  IconMessages,
  IconMinus,
  IconPalette,
  IconPencil,
  IconPlus,
  IconReceipt,
  IconRepeat,
  IconSchool,
  IconSearch,
  IconSettings,
  IconShare,
  IconSpeakerphone,
  IconSquare,
  IconSquareCheck,
  IconSquareMinus,
  IconStars,
  IconSubtask,
  IconTable,
  IconTrash,
  IconTriangle,
  IconTypography,
  IconUnderline,
  IconUserPlus,
  IconUsers,
  IconVideo,
  IconViewportWide,
  IconX
} from '@tabler/icons';
import { ReactComponent as IconDropdown } from 'assets/icons/Dropdown.svg';
import { ReactComponent as IconRadioButton } from 'assets/icons/RadioButton.svg';
import { ReactComponent as IconCopyDashed } from 'assets/icons/copy-dashed.svg';
import { ReactComponent as IconDodecagonCheck } from 'assets/icons/dodecagon-check.svg';
import { ReactComponent as IconDodecagon } from 'assets/icons/dodecagon.svg';
import { ReactComponent as IconIdonateLogo } from 'assets/icons/iDonateLogo.svg';

// if using TS, use enums
export const ICONS = {
  ACTIVITY: IconActivity,
  ALARM: IconAlarm,
  ALERT: IconAlertCircle,
  ALERT_TRIANGLE: IconAlertTriangle,
  ALIEN: IconAlien,
  ARCHIVE: IconArchive,
  ARROW_DOWN_RIGHT: IconArrowDownRight,
  ARROW_UP_RIGHT: IconArrowUpRight,
  AWARD: IconAward,
  BELL_RINGING: IconBellRinging,
  BLOCKQUOTE: IconBlockquote,
  BOLD: IconBold,
  BRACKETS: IconBrackets,
  CALENDAR: IconCalendarPlus,
  CALENDAR_STATS: IconCalendarStats,
  CERTIFICATE: IconCertificate,
  CHART_INFOGRAPHIC: IconChartInfographic,
  CHART_LINE: IconChartLine,
  CHECK: IconCheck,
  CHEVRON_DOWN: IconChevronDown,
  CHEVRON_RIGHT: IconChevronRight,
  CHEVRON_UP: IconChevronUp,
  CIRCLE: IconCircle,
  CIRCLE_CHECK: IconCircleCheck,
  CIRCLE_SQUARE: IconCircleSquare,
  CLICK: IconClick,
  CLIPBOARD_X: IconClipboardX,
  CODE: IconCode,
  COIN: IconCoin,
  COMET: IconComet,
  COPY: IconCopy,
  COPY_DASHED: IconCopyDashed,
  CREDIT_CARD: IconCreditCard,
  DEVICE_DESKTOP: IconDeviceDesktop,
  DEVICE_MOBILE: IconDeviceMobile,
  DODECAGON: IconDodecagon,
  DODECAGON_CHECK: IconDodecagonCheck,
  DOLLAR: IconCurrencyDollar,
  DOT_CIRCLE_HORIZONTAL: IconDotsCircleHorizontal,
  DOTS_HORIZONTAL: IconDots,
  EXCLAMATION: IconExclamationMark,
  DROPDOWN: IconDropdown,
  EYE: IconEye,
  FILE: IconFile,
  FILE_CODE: IconFileCode,
  FILE_INFO: IconFileInfo,
  FILE_TEXT: IconFileText,
  GIFT: IconGift,
  HASH: IconHash,
  HELP: IconHelp,
  HOUSE: IconHome,
  ICE_CREAM_2: IconIceCream2,
  IDONATE_LOGO: IconIdonateLogo,
  INFO: IconInfoCircle,
  ITALIC: IconItalic,
  LAYOUT_BOTTOMBAR: IconLayoutBottombar,
  LAYOUT_NAVBAR: IconLayoutNavbar,
  LINK: IconLink,
  LIST: IconList,
  LIST_NUMBERS: IconListNumbers,
  LOCK: IconLock,
  MAIL: IconMail,
  MAIL_OPENED: IconMailOpened,
  MESSAGE2: IconMessage2,
  MESSAGES: IconMessages,
  MINUS: IconMinus,
  PALETTE: IconPalette,
  PENCIL: IconPencil,
  PLUS: IconPlus,
  RADIO_BUTTON: IconRadioButton,
  RECEIPT: IconReceipt,
  REPEAT: IconRepeat,
  SCHOOL: IconSchool,
  SEARCH: IconSearch,
  SETTINGS: IconSettings,
  SHARE: IconShare,
  SKYSCRAPER: IconBuildingSkyscraper,
  SPEAKERPHONE: IconSpeakerphone,
  SQUARE: IconSquare,
  SQUARE_CHECK: IconSquareCheck,
  SQUARE_MINUS: IconSquareMinus,
  STARS_THREE: IconStars,
  SUBTASK: IconSubtask,
  TABLE: IconTable,
  TRASH: IconTrash,
  TRIANGLE: IconTriangle,
  TYPOGRAPHY: IconTypography,
  UNDERLINE: IconUnderline,
  UNDO: IconArrowBackUp,
  USERS: IconUsers,
  USER_PLUS: IconUserPlus,
  VIDEO: IconVideo,
  VIEWPORT_WIDE: IconViewportWide,
  X: IconX
};

export type IconType = keyof typeof ICONS;
