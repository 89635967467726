import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import clsx from 'clsx';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { AbTestMetrics } from 'types';
import {
  customNumberFormatter,
  numberFormatter,
  usdCurrencyFormatter
} from 'utils';
import './AbTestResultsTable.scss';

type AbTestResultsTableProps = {
  metrics: AbTestMetrics;
};

export const AbTestResultsTable = ({
  metrics
}: AbTestResultsTableProps): JSX.Element => {
  const { variantA, variantB, delta } = metrics;
  const conversionRateFormatter = customNumberFormatter(1);

  const renderVariantMetric = (metricValue: number) => {
    const formattedMetricValue = Math.round(metricValue * 10) / 10;
    return (
      <div
        className={clsx('ab-variant-metric', {
          'variant-increased': formattedMetricValue > 0,
          'variant-decreased': formattedMetricValue < 0,
          'variant-unchanged': formattedMetricValue === 0
        })}
      >
        <span className="variant-direction">
          {formattedMetricValue > 0 && (
            <Icon
              className="variant-direction-icon"
              icon={ICONS.ARROW_UP_RIGHT}
            />
          )}
          {formattedMetricValue < 0 && (
            <Icon
              className="variant-direction-icon"
              icon={ICONS.ARROW_DOWN_RIGHT}
            />
          )}
        </span>
        <span>{`${Math.abs(formattedMetricValue)}%`}</span>
      </div>
    );
  };

  return (
    <div className="ab-test-results-table">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className="header-row">
              <TableCell>
                <Text variant="h6">Form</Text>
              </TableCell>
              <TableCell>
                <Text variant="h6">Total Donation Amount</Text>
              </TableCell>
              <TableCell>
                <Text variant="h6">Total Donations</Text>
              </TableCell>
              <TableCell>
                <Text variant="h6">Page Visits</Text>
              </TableCell>
              <TableCell>
                <Text variant="h6">Unique Visitors</Text>
              </TableCell>
              <TableCell>
                <Text variant="h6">Conversion Rate</Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className="variant-a-row">
              <TableCell>
                <Text variant="h6" className="form-label">
                  Form A
                </Text>
              </TableCell>
              <TableCell>
                <Text variant="h6">
                  {usdCurrencyFormatter.format(variantA?.amountRaised || 0)}
                </Text>
              </TableCell>
              <TableCell>
                <Text variant="h6">
                  {numberFormatter.format(variantA?.donationCount || 0)}
                </Text>
              </TableCell>
              <TableCell>
                <Text variant="h6">
                  {numberFormatter.format(variantA?.visits || 0)}
                </Text>
              </TableCell>
              <TableCell>
                <Text variant="h6">
                  {numberFormatter.format(variantA?.visitors || 0)}
                </Text>
              </TableCell>
              <TableCell>
                <Text variant="h6">
                  {conversionRateFormatter.format(
                    (variantA?.conversionRate || 0) * 100
                  )}
                  %
                </Text>
              </TableCell>
            </TableRow>
            <TableRow className="variant-b-row">
              <TableCell>
                <Text variant="h6" className="form-label">
                  Form B
                </Text>
              </TableCell>
              <TableCell>
                <Text className="variant-b-row-metric-container" variant="h6">
                  {usdCurrencyFormatter.format(variantB?.amountRaised || 0)}
                  {renderVariantMetric((delta?.amountRaised || 0) * 100)}
                </Text>
              </TableCell>
              <TableCell>
                <Text className="variant-b-row-metric-container" variant="h6">
                  {numberFormatter.format(variantB?.donationCount || 0)}
                  {renderVariantMetric((delta?.donationCount || 0) * 100)}
                </Text>
              </TableCell>
              <TableCell>
                <Text className="variant-b-row-metric-container" variant="h6">
                  {numberFormatter.format(variantB?.visits || 0)}
                  {renderVariantMetric((delta?.visits || 0) * 100)}
                </Text>
              </TableCell>
              <TableCell>
                <Text variant="h6">
                  {numberFormatter.format(variantB?.visitors || 0)}
                  {renderVariantMetric((delta?.visitors || 0) * 100)}
                </Text>
              </TableCell>
              <TableCell>
                <Text className="variant-b-row-metric-container" variant="h6">
                  {conversionRateFormatter.format(
                    (variantB?.conversionRate || 0) * 100
                  )}
                  %{renderVariantMetric((delta?.conversionRate || 0) * 100)}
                </Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
