import { useNavigate } from 'react-router-dom';
import abTestImg from 'assets/images/strategy.png';
import Button from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import './StrategyAbTesting.scss';

export const StrategyAbTesting = () => {
  const navigate = useNavigate();

  return (
    <div className="ab-testing-container">
      <div className="explanation-container">
        <Text variant="h1">A/B Testing</Text>
        <Text variant="h7" as="h2">
          Take guessing out of it. An A/B test is designed to help you make the
          right decisions for your giving forms. Make changes and test out which
          form creates the biggest impact with your donors.
        </Text>
        <ul className="bullets">
          <li className="bullet">
            <Icon
              className="bullet-point"
              color="primary"
              viewBox="0 0 31 31"
              icon={ICONS.DODECAGON_CHECK}
            />
            <Text variant="h7">
              Optimize your giving channels to increase conversion rates
            </Text>
          </li>
          <li className="bullet">
            <Icon
              className="bullet-point"
              color="primary"
              viewBox="0 0 31 31"
              icon={ICONS.DODECAGON_CHECK}
            />
            <Text variant="h7">
              Gain valuable insights to increase donor engagement
            </Text>
          </li>
          <li className="bullet">
            <Icon
              className="bullet-point"
              color="primary"
              viewBox="0 0 31 31"
              icon={ICONS.DODECAGON_CHECK}
            />
            <Text variant="h7">
              Uncover the best strategies for your giving community
            </Text>
          </li>
        </ul>
        <div className="buttons-container">
          <Button onClick={() => navigate('ab-test/giving-forms')}>
            Create A/B Test
          </Button>
          <Button variant="secondary" onClick={() => navigate(`ab-test`)}>
            View A/B Tests
          </Button>
        </div>
      </div>
      <div className="ab-testing-image-container">
        <img
          className="ab-testing-image"
          src={abTestImg}
          alt="ab testing example"
          loading="lazy"
        />
      </div>
    </div>
  );
};
