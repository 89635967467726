import { MenuItemProps, MenuItem as MuiMenuItem } from '@mui/material';
import Checkbox from 'components/Checkbox';
import Text from 'components/Text';

export interface SelectCheckboxMenuItemProps extends MenuItemProps {
  label: string;
}
const SelectCheckboxMenuItem = ({
  label,
  checked = false,
  ...props
}: SelectCheckboxMenuItemProps & { checked?: boolean }): JSX.Element => (
  <MuiMenuItem {...{ ...props, label }} className="MultiselectCheckbox--select">
    <Checkbox checked={checked} />
    <Text variant="body">{label}</Text>
  </MuiMenuItem>
);

export default SelectCheckboxMenuItem;
