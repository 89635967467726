import React, { FC } from 'react';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  SelectChangeEvent,
  styled
} from '@mui/material';
import { ICONS } from 'components/Icon';
import { SelectMenuItem } from '.';

export interface SelectOption {
  label?: string;
  value: string;
  icon?: FC;
}

export interface SelectProps
  extends Omit<MuiSelectProps, 'label' | 'defaultValue' | 'disabled'> {
  label?: string;
  options: SelectOption[];
  defaultValue?: string;
  disabled?: boolean;
}

const styledChevronIcon = (disabled: boolean) =>
  styled(ICONS.CHEVRON_DOWN)(({ theme }) => ({
    '&': { top: 'unset' },
    stroke: disabled
      ? theme.palette.lightGray.main
      : theme.palette.darkGray.main
  }));

const Select = ({
  options,
  label,
  defaultValue,
  disabled = false,
  ...props
}: SelectProps): JSX.Element => {
  const [value, setValue] = React.useState<string>(defaultValue || '');

  const handleChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    props?.onChange?.(event, child);
    setValue(event.target.value);
  };

  const selectId = `${label}-select-id`;

  const selectProps = {
    ...props,
    sx: props.sx,
    defaultValue,
    labelId: `${label}-select-label-id`,
    id: selectId,
    label,
    value,
    disabled,
    onChange: handleChange
  };

  return (
    <FormControl fullWidth>
      {!!label && (
        <InputLabel
          disabled={disabled}
          id={`${label}-select-label-id`}
          htmlFor={selectId}
        >
          {label}
        </InputLabel>
      )}
      <MuiSelect {...selectProps} IconComponent={styledChevronIcon(disabled)}>
        {options.map(SelectMenuItem)}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
