import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { ChannelRevenue, ChannelType, OrgRevenueByChannel } from 'services';
import { reactJoin } from 'utils';
import './CollapsedBarGraph.scss';

type CollapsedBarGraphProps = {
  channelRevenueData?: OrgRevenueByChannel;
};
export const CollapsedBarGraph = ({
  channelRevenueData
}: CollapsedBarGraphProps) => {
  const theme = useTheme();

  const isEmptyOrIsLoading = useMemo(
    () =>
      !channelRevenueData ||
      Object.values(channelRevenueData).reduce(
        (total, channel) => total + channel.revenuePercentage,
        0
      ) === 0 ||
      Object.values(channelRevenueData).reduce(
        (total, channel) => total + channel.totalAmount,
        0
      ) === 0,
    [channelRevenueData]
  );

  const emptyOrLoadingState = useMemo(
    () => (
      <div className="collapsed-bar collapsed-bar-empty">
        {reactJoin(
          [
            <span
              className="collapsed-bar-channel"
              style={{
                flex: 56.7
              }}
            />,
            <span
              className="collapsed-bar-channel"
              style={{
                flex: 20
              }}
            />,
            <span
              className="collapsed-bar-channel"
              style={{
                flex: 13.7
              }}
            />,
            <span
              className="collapsed-bar-channel"
              style={{
                flex: 10
              }}
            />,
            <span
              className="collapsed-bar-channel"
              style={{
                flex: 10.3
              }}
            />
          ],
          <span className="collapsed-bar-channel-separator" />
        )}
      </div>
    ),
    []
  );

  if (isEmptyOrIsLoading) {
    return emptyOrLoadingState;
  }

  const orderedData: Array<
    Omit<ChannelRevenue, 'totalAmount'> & {
      backgroundColor: string;
      label: string;
    }
  > = [];

  Object.keys(channelRevenueData)?.forEach((channel) => {
    const channelType = channel as ChannelType;

    if (channelType === ChannelType.GivingForms) {
      orderedData[0] = {
        ...channelRevenueData[channelType],
        backgroundColor: theme.palette.primary.main,
        label: channelType
      };
    } else if (channelType === ChannelType.Events) {
      orderedData[1] = {
        ...channelRevenueData[channelType],
        backgroundColor: theme.palette.purple.main,
        label: channelType
      };
    } else if (channelType === ChannelType.PeerToPeer) {
      orderedData[2] = {
        ...channelRevenueData[channelType],
        backgroundColor: theme.palette.orange.main,
        label: channelType
      };
    } else if (channelType === ChannelType.TextToGive) {
      orderedData[3] = {
        ...channelRevenueData[channelType],
        backgroundColor: theme.palette.green.main,
        label: channelType
      };
    } else {
      orderedData[4] = {
        revenuePercentage: orderedData[4]
          ? orderedData[4].revenuePercentage +
            channelRevenueData[channelType].revenuePercentage
          : channelRevenueData[channelType].revenuePercentage,
        backgroundColor: theme.palette.pink.main,
        label: channelType
      };
    }
  });

  return (
    <div className="collapsed-bar">
      {reactJoin(
        orderedData
          .filter((channel) => channel.revenuePercentage !== 0)
          .map(({ label, revenuePercentage, backgroundColor }) => (
            <span
              key={label}
              className="collapsed-bar-channel"
              style={{
                flex: revenuePercentage < 1 ? 1 : revenuePercentage,
                backgroundColor
              }}
            />
          )),
        <span className="collapsed-bar-channel-separator" />
      )}
    </div>
  );
};
