import { Theme, createTheme } from '@mui/material';

declare module '@mui/material/Chip' {
  /**
   * Disable large variant
   */
  interface ChipPropsSizeOverrides {
    large: false;
  }

  /**
   * Only allow 'default' | 'darkBlue'
   */
  interface ChipPropsColorOverrides {
    default: false;
    darkBlue: true;
    primary: true;
    secondary: false;
    tertiary: false;
    error: false;
    info: false;
    success: false;
    warning: false;
  }
}

export const createChipTheme = (currTheme: Theme): Theme => {
  const theme = createTheme(currTheme, {
    components: {
      MuiChip: {
        defaultProps: {
          size: 'small',
          variant: 'filled',
          color: 'primary'
        },
        styleOverrides: {
          root: {
            ...currTheme.typography.h6,
            textTransform: 'uppercase',
            color: currTheme.palette.common.white
          }
        }
      }
    }
  });

  return theme;
};
