import { ReactNode } from 'react';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import './RevertChangesModal.scss';

type RevertDraftModalProps = {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
  children: ReactNode;
};

export const RevertChangesModal = ({
  open,
  onClose,
  onDiscard,
  children
}: RevertDraftModalProps) => (
  <Dialog label="Revert Changes Modal" open={open} onClose={() => onClose()}>
    <DialogTitle
      className="RevertChangesModal__title"
      onClick={(close) => close()}
    >
      Changes will not be saved
    </DialogTitle>
    <DialogContent>
      <DialogContentTitle>Are you sure?</DialogContentTitle>
      <DialogContentDescription>{children}</DialogContentDescription>
    </DialogContent>
    <DialogActions className="RevertChangesModal__actions-container">
      <DialogActionButton
        onClick={(close) => {
          onDiscard();
          close();
        }}
        variant="primary"
      >
        Yes, revert changes
      </DialogActionButton>
      <DialogActionButton onClick={(close) => close()} variant="secondary">
        No, cancel
      </DialogActionButton>
    </DialogActions>
  </Dialog>
);
