import { useState } from 'react';
import { ChromePicker, ColorResult, CustomPicker } from 'react-color';
import { AddColorButton, ColorMenuContainer } from './ColorMenu.styles';

/**
 * Props for the ColorMenu component
 */
type ColorMenuProps = {
  /**
   * The starting color to be pre-selected when the menu opens. Can be in hex, hsl, or rgba() format
   */
  defaultColor?: string;
  /**
   * Callback to handle any logic that should happen when a color is confirmed. This happens when
   * the form is actually submitted via keyboard event or button click
   */
  onSelect?: (color: string) => void;
};

const ColorMenu = ({
  defaultColor = '#FFFFFF',
  onSelect
}: ColorMenuProps): JSX.Element => {
  const [color, setColor] = useState<string>(defaultColor);

  const handleChange = ({ rgb: { r, g, b, a } }: ColorResult) =>
    setColor(`rgba(${r}, ${g}, ${b}, ${a})`);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSelect?.(color);
      }}
    >
      <ColorMenuContainer>
        <ChromePicker
          color={color}
          onChange={handleChange}
          styles={{
            default: {
              body: {
                // Make space for the add color button
                paddingBottom: '75px'
              }
            }
          }}
        />
        <AddColorButton type="submit">Select Color</AddColorButton>
      </ColorMenuContainer>
    </form>
  );
};

export default CustomPicker(ColorMenu);
