import { EmailBlockBaseType, EmailFontOptions } from '.';
import { HeaderBlock } from './HeaderBlock';

export enum EmailBlockTypes {
  Header = 'Header',
  CustomContent = 'CustomContent'
}

export enum EmailType {
  Receipt = 'Receipt'
}
interface EmailTheme {
  /**
   * A background theme color
   */
  background: string;

  /**
   * A text color
   */
  text?: string;

  /**
   * A theme font
   */
  font: EmailFontOptions;
}

export interface EmailConfig {
  header: HeaderBlock;
  blocks: EmailBlockBaseType[];
  subjectLine: string;
  theme: EmailTheme;
}
