/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { deepmergeCustom } from 'deepmerge-ts';
import { FormDataType } from 'hooks/useConfigContext';
import EditsRepository from 'services/editsRepositoryService';
import { AbTest, AbTestDraft } from 'types';

type AbTestDraftContextType = {
  draft: any;
  updateDraft: (
    draftDiff: Partial<FormDataType<Partial<AbTest>>>
  ) => Promise<any>;
  discardDraft: () => void;
};

const AbTestDraftContext = createContext<AbTestDraftContextType | null>(null);

type AbTestDraftProviderProps = {
  children: JSX.Element;
  userId: string;
  orgId: string;
  draftId: string;
};

export const AbTestDraftProvider = ({
  children,
  userId,
  orgId,
  draftId
}: AbTestDraftProviderProps) => {
  const [draft, setDraft] = useState<FormDataType<AbTestDraft>>();

  useEffect(() => {
    const getDraft = async () => {
      const retrievedDraft = await EditsRepository.getEditsById<AbTestDraft>(
        'abTest',
        draftId,
        userId
      );
      setDraft(retrievedDraft);
    };

    getDraft();
  }, [draftId, userId]);

  const value = useMemo(() => {
    const emptyDraft: FormDataType<AbTestDraft> = {
      config: {
        name: '',
        organization: {
          id: orgId
        },
        givingForm: {
          id: draftId
        },
        thresholds: {}
      },
      seedConfigPublishedAt: new Date(), // this doesnt translate well for a/b drafts since they never get published to mongo during their draft lifecycle. how to handle this? probably make it optional
      name: ''
    };

    const updateDraft = (
      draftDiff: Partial<FormDataType<Partial<AbTestDraft>>>
    ) => {
      const baseDraft = draft ?? emptyDraft;

      const deepmerge = deepmergeCustom({
        // val is of type unknown[][] where the second element is the source array
        // in this case, we just want to always overwrite the array with the source array
        mergeArrays: (val) => val[1]
      });
      const newDraft = deepmerge(
        baseDraft,
        draftDiff
      ) as FormDataType<AbTestDraft>;

      setDraft(newDraft);
      return EditsRepository.updateEdits(
        'abTest',
        draftId,
        newDraft.config,
        newDraft.seedConfigPublishedAt,
        newDraft.name,
        newDraft?.receiptId,
        newDraft?.isPublished,
        userId
      );
    };

    // variant B draft must be discarded before A/B test draft so that the discard function
    // has access to the associated giving form id
    const discardDraft = () => {
      // TODO kstickel 6/22/22: variant B drafts are being created with the username 'janedoe', this should be updated when the actual userId is utilized in the Edits Repository
      EditsRepository.discardEdits(
        'givingFormVariantB',
        draft.config.givingForm.id,
        'janedoe'
      );
      EditsRepository.discardEdits('abTest', draftId, userId);
    };

    return {
      draft,
      updateDraft,
      discardDraft
    };
  }, [draft, draftId, orgId, userId]);

  return (
    <AbTestDraftContext.Provider value={value}>
      {children}
    </AbTestDraftContext.Provider>
  );
};

export const useAbTestDraft = (): AbTestDraftContextType => {
  const context = useContext(AbTestDraftContext);
  if (!context) {
    throw new Error(
      'Something went wrong. No context found for A/B Test Drafts.'
    );
  }
  return context;
};

export const checkIfDraftExists = (
  userId: string,
  draftId: string
): Promise<FormDataType<AbTestDraft>> =>
  EditsRepository.getEditsById('abTest', draftId, userId);

export const discardDraftById = (
  givingFormId: string,
  draftId: string,
  userId: string
) => {
  // TODO kstickel 6/22/22 - EO 6/30/22: variant B drafts are being created
  // with the username 'janedoe', this should be updated when the actual userId
  // is utilized in the Edits Repository
  EditsRepository.discardEdits('givingFormVariantB', givingFormId, 'janedoe');
  EditsRepository.discardEdits('abTest', draftId, userId);
};
