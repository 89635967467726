import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import './DeleteConfirmationModal.scss';

type DeleteConfirmationModalProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  name?: string;
};

export const DeleteConfirmationModal = ({
  open,
  onConfirm,
  onClose,
  name
}: DeleteConfirmationModalProps) => (
  <Dialog open={open} label="Delete Confirmation Modal" onClose={onClose}>
    <DialogTitle
      onClick={(close) => close()}
      className="delete-confirmation-modal-title"
    >
      Changes will not be saved
    </DialogTitle>
    <DialogContent>
      <DialogContentTitle>Are you sure?</DialogContentTitle>
      <DialogContentDescription>
        Any changes you&apos;ve made on this element will not be saved and it
        will be removed from your form.
      </DialogContentDescription>
    </DialogContent>
    <DialogActions className="delete-confirmation-modal-title-action-container">
      <DialogActionButton
        onClick={(close) => {
          onConfirm();
          close();
        }}
        variant="primary"
      >
        Yes, Delete {name || ''}
      </DialogActionButton>
      <DialogActionButton onClick={(close) => close()} variant="secondary">
        No, Cancel
      </DialogActionButton>
    </DialogActions>
  </Dialog>
);
