import { Dispatch, SetStateAction } from 'react';
import { Stack } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useConfigContext } from 'hooks/useConfigContext';
import {
  BlockTypes,
  EditorMenus,
  ElementLibraryEmailViews,
  ElementLibraryGivingFormViews,
  ElementLibraryHostedPageViews,
  EmailConfig,
  EmailFontOptions,
  FontOptions,
  IGivingFormConfig
} from 'types';
import {
  ElementLibrary,
  EmailLookAndFeelMenu,
  GivingFormLookAndFeelMenu
} from '..';
import { ElementLibraryEmailViewData } from '../ElementLibrary/EmailViews/EmailViewData';
import { ElementLibraryGivingFormViewData } from '../ElementLibrary/GivingFormViews/GivingFormViewData';
import { ElementLibraryHostedPageViewData } from '../ElementLibrary/HostedPageViews/HostedPageViewData';
import { GivingFormCustomCss } from '../GivingFormCustomCss/GivingFormCustomCss';
import { HostedPageCustomCss } from '../HostedPageCustomCss/HostedPageCustomCss';
import './ActiveMenu.scss';

type ActiveMenuProps = {
  activeMenu: EditorMenus;
  elementLibraryReturnAction: () => void;
  libraryView:
    | ElementLibraryEmailViews
    | ElementLibraryGivingFormViews
    | ElementLibraryHostedPageViews;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setLibraryView: Dispatch<SetStateAction<any>>;
};

export const ActiveMenu = ({
  activeMenu,
  elementLibraryReturnAction,
  libraryView,
  setLibraryView
}: ActiveMenuProps) => {
  const { configData, updateConfig } = useConfigContext();
  const { config } = configData;

  // Giving Form Look and Feel update functions
  const updateGFPrimaryColor = (newColor: string) => {
    const newConfig = { ...(config as IGivingFormConfig) };
    newConfig.theme.primary = newColor;
    updateConfig(newConfig);
  };

  const updateGFAccentColor = (newColor: string) => {
    const newConfig = { ...(config as IGivingFormConfig) };
    newConfig.theme.accent = newColor;
    updateConfig(newConfig);
  };

  const updateGFFont = (newFont: FontOptions) => {
    const newConfig = { ...(config as IGivingFormConfig) };
    newConfig.theme.font = {
      header: newFont,
      headerDescription: newFont,
      selectors: newFont,
      formSubheadings: newFont,
      formBody: newFont,
      donateButton: newFont,
      footer: newFont
    };
    updateConfig(newConfig);
  };

  const addPageBreak = () => {
    const newConfig = { ...(config as IGivingFormConfig) };
    newConfig.blocks = [
      ...newConfig.blocks,
      { id: uuid(), blockType: BlockTypes.PageBreakBlock }
    ];
    updateConfig(newConfig);
  };

  // Email Look and Feel update functions
  const updateEmailBackgroundColor = (newColor: string) => {
    const newConfig = { ...(config as EmailConfig) };
    newConfig.theme.background = newColor;
    updateConfig(newConfig);
  };

  const updateEmailTextColor = (newColor: string) => {
    const newConfig = { ...(config as EmailConfig) };
    newConfig.theme.text = newColor;
    updateConfig(newConfig);
  };

  const updateEmailFont = (newFont: EmailFontOptions) => {
    const newConfig = { ...(config as EmailConfig) };
    newConfig.theme.font = newFont;
    updateConfig(newConfig);
  };

  // Giving Form Custom CSS update function
  const updateGivingFormCustomCss = (customCss: string) => {
    const newConfig = { ...(config as IGivingFormConfig) };
    newConfig.customCss = customCss;
    updateConfig(newConfig);
  };

  // Hosted Page Custom CSS update function
  const updateHostedPageCustomCss = (customCss: string) => {
    const newConfig = { ...(config as IGivingFormConfig) };
    if (newConfig.hostedPageConfig) {
      newConfig.hostedPageConfig.customCss = customCss;
      updateConfig(newConfig);
    }
  };

  const renderActiveMenu = () => {
    switch (activeMenu) {
      case EditorMenus.GivingFormLookAndFeel: {
        const givingFormConfig = config as IGivingFormConfig;
        return (
          <GivingFormLookAndFeelMenu
            startingPrimaryColor={givingFormConfig.theme.primary}
            updatePrimaryColor={updateGFPrimaryColor}
            startingAccentColor={givingFormConfig.theme.accent}
            updateAccentColor={updateGFAccentColor}
            startingFont={givingFormConfig.theme.font.header}
            updateFont={updateGFFont}
            addPageBreak={addPageBreak}
          />
        );
      }
      case EditorMenus.EmailLookAndFeel: {
        const emailConfig = config as EmailConfig;
        return (
          <EmailLookAndFeelMenu
            startingBackgroundColor={emailConfig.theme.background ?? '#FFFFFF'}
            updateBackgroundColor={updateEmailBackgroundColor}
            startingTextColor={emailConfig.theme.text ?? '#000000'}
            updateTextColor={updateEmailTextColor}
            startingFont={emailConfig.theme.font}
            updateFont={updateEmailFont}
          /> // TODO: (Mykenzie) Need to setup theme on email config to match what L&F expects
        );
      }
      case EditorMenus.EmailElementLibrary: {
        const emailLibraryView = libraryView as ElementLibraryEmailViews;
        return (
          <ElementLibrary<ElementLibraryEmailViews>
            libraryView={emailLibraryView}
            setLibraryView={setLibraryView}
            returnAction={elementLibraryReturnAction}
            elementLibraryViewData={ElementLibraryEmailViewData}
          />
        );
      }
      case EditorMenus.GivingFormElementLibrary: {
        const givingFormLibraryView =
          libraryView as ElementLibraryGivingFormViews;
        return (
          <ElementLibrary<ElementLibraryGivingFormViews>
            libraryView={givingFormLibraryView}
            setLibraryView={setLibraryView}
            returnAction={elementLibraryReturnAction}
            elementLibraryViewData={ElementLibraryGivingFormViewData}
          />
        );
      }
      case EditorMenus.GivingFormCustomCss: {
        const givingFormConfig = config as IGivingFormConfig;
        return (
          <GivingFormCustomCss
            startingCustomCss={givingFormConfig?.customCss}
            updateCustomCss={updateGivingFormCustomCss}
          />
        );
      }
      case EditorMenus.HostedPageElementLibrary: {
        const hostedPageLibraryView =
          libraryView as ElementLibraryHostedPageViews;
        return (
          <ElementLibrary<ElementLibraryHostedPageViews>
            libraryView={hostedPageLibraryView}
            setLibraryView={setLibraryView}
            returnAction={elementLibraryReturnAction}
            elementLibraryViewData={ElementLibraryHostedPageViewData}
          />
        );
      }
      case EditorMenus.HostedPageCustomCss: {
        const givingFormConfig = config as IGivingFormConfig;
        return (
          <HostedPageCustomCss
            startingCustomCss={
              givingFormConfig?.hostedPageConfig?.customCss ?? ''
            }
            updateCustomCss={updateHostedPageCustomCss}
          />
        );
      }
      default:
        throw new Error(
          `${activeMenu} is not a valid menu type, could not render ActiveMenu`
        );
    }
  };

  return <Stack className="active-menu-container">{renderActiveMenu()}</Stack>;
};
