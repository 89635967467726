import { UseQueryOptions, useQuery } from 'react-query';
import { IUser } from 'services/types/userTypes';
import { getUserInfoById, getUserService } from '../services/userService';

export const useUser = (onSuccess?: (data: IUser) => void) =>
  useQuery(['getUser'], () => getUserService(), {
    onSuccess
  });

export const useGetUserInfo = (
  userId: string,
  organizationId: string,
  queryOptions?: UseQueryOptions<IUser>
) =>
  useQuery<IUser>(
    ['abTestNoteUserInfo', userId],
    () => getUserInfoById(userId, organizationId),
    queryOptions
  );
