import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SuccessDialog } from 'components';
import {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogTitle
} from 'components/Dialog';
import Text from 'components/Text';
import { AbTest, GivingFormConfigVariant } from 'types';
import './EndAbTestCongratulationsModal.scss';

interface EndAbTestCongratulationsModalProps {
  abTest: AbTest;
  selectedVariant: GivingFormConfigVariant;
}

export const EndAbTestCongratulationsModal = ({
  abTest,
  selectedVariant
}: EndAbTestCongratulationsModalProps): JSX.Element => {
  const navigate = useNavigate();

  const handleNavigateToOverview = () => {
    navigate(
      `/campaigns/${abTest.campaign.id}/giving-forms/${abTest.givingForm.id}`
    );
  };

  const handleNavigateToAbTests = () => {
    navigate('/strategy/ab-test');
  };

  const getVariantName = (variant: GivingFormConfigVariant) =>
    `Form ${variant === GivingFormConfigVariant.VariantA ? 'A' : 'B'}`;

  return (
    <SuccessDialog
      className="ab-test-ended-dialog"
      label="AB Test Ended"
      open
      onClose={() => null}
    >
      <DialogTitle>Congratulations</DialogTitle>
      <DialogContent className="ab-test-ended-dialog-content">
        <DialogContentDescription>
          Your A/B test has ended and {getVariantName(selectedVariant)} is
          published!
        </DialogContentDescription>
        <Stack>
          <Text variant="body">
            This Giving Form is now available in your {abTest.campaign.name}{' '}
            campaign home.{' '}
          </Text>
        </Stack>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          variant="primary"
          onClick={handleNavigateToOverview}
        >
          Form Overview
        </DialogActionButton>
        <DialogActionButton
          variant="secondary"
          onClick={handleNavigateToAbTests}
        >
          View All A/B Tests
        </DialogActionButton>
      </DialogActions>
    </SuccessDialog>
  );
};
