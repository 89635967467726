import Text from 'components/Text';
import './LineGraphTooltip.scss';

export type LineGraphTooltipProps = {
  top: number;
  left: number;
  date: string;
  primaryColor: string;
  secondaryColor?: string;
  currentYearStat: string;
  previousYearStat?: string;
};

export const LineGraphTooltip = ({
  top,
  left,
  date,
  primaryColor,
  secondaryColor,
  currentYearStat,
  previousYearStat
}: LineGraphTooltipProps) => (
  <div
    className="line-graph-tooltip"
    style={{ position: 'fixed', ...{ top, left } }}
  >
    <Text variant="h6" as="p">
      Total Giving Received
    </Text>
    <Text variant="caption" as="p" color="darkGray.main">
      {date}
    </Text>
    <Text className="line-graph-tooltip-legend" variant="h4" as="p">
      <span
        className="line-graph-tooltip-marker"
        style={{ backgroundColor: primaryColor }}
      />
      {currentYearStat}
    </Text>
    {previousYearStat && (
      <Text className="line-graph-tooltip-legend" variant="h6" as="p">
        <span
          className="line-graph-tooltip-marker"
          style={{ backgroundColor: secondaryColor }}
        />
        {previousYearStat}
      </Text>
    )}
  </div>
);
