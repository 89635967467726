/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable no-use-before-define */

/* eslint-disable no-return-assign */

/* eslint-disable no-param-reassign */

/* eslint-disable react/jsx-no-useless-fragment */

/* eslint-disable react/self-closing-comp */
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Blocker } from 'components';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { OrganizationRole, getRoleInt } from 'hooks/useRenderIfRole';
import { LegacyBackgroundHeader } from 'routes/types';
import {
  EventHub,
  getAbsolutePath,
  getPathParams,
  isAbsolutePath
} from 'services';
import { SINGLETONS } from 'services/singletons';
import { Url } from 'utils/Url';
import './LegacyViewer.scss';

type LegacyViewerProps = {
  featureHeader?: JSX.Element;
  minimumRole?: OrganizationRole;
  backgroundHeader: LegacyBackgroundHeader;
};

const reconciliationPathname = '/settings/reconciliation';
const legacyBatchIdPathname = '/reconciliation/funding-batches/';

export const LegacyViewer = memo(
  ({ featureHeader, minimumRole, backgroundHeader }: LegacyViewerProps) => {
    const params = useParams();

    const legacyRouteMapper = useContext(SINGLETONS.legacyRouteMapper);
    const logger = useContext(SINGLETONS.logger);

    const [alerts] = useAlerts();
    const { selectedOrganization, envConfig } = useAppContext();
    const [frameUrl, setFrameUrl] = useState<Url>(undefined);
    const batchId = useRef<string>();
    const [frameHeight, setFrameHeight] = useState<string>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [, setIsWaiting] = useState<boolean>(false);

    const navigate = useNavigate();

    let { eventHub }: { eventHub: EventHub } = window as any;

    const { pathname } = useLocation();
    const [isReconciliation, setIsReconciliation] = useState(
      pathname === reconciliationPathname
    );

    useEffect(() => {
      if (pathname === reconciliationPathname) {
        setIsReconciliation(true);
      } else {
        setIsReconciliation(false);
      }
    }, [pathname]);

    useEffect(() => {
      setIsLoading(true);
    }, []);

    useEffect(() => {
      if (
        minimumRole &&
        getRoleInt(selectedOrganization.role) < getRoleInt(minimumRole)
      ) {
        window.location.replace('/dashboard');
      }
    }, [selectedOrganization]);

    useEffect(() => {
      // This is used by legacy P2P and Event applications
      const handleLegacyGMSMessagePost = (message: any) => {
        let data: any = {};

        try {
          data = JSON.parse(message.data);
        } catch (e) {
          return;
        }

        if (
          ['p2p-ui', 'events-ui', 'legacy-ui', 'reconciliation'].includes(
            data.source
          ) &&
          data.status === 'ready'
        ) {
          const messageParams: Record<string, string> = {
            apiAuthorization: `Bearer ${sessionStorage.accessToken}`,
            organizationId: selectedOrganization.id,
            baseUrl: envConfig.gmsApiBaseUrl,
            p2pPublicBaseUrl: envConfig.p2pPublicBaseUrl,
            eventsPublicBaseUrl: envConfig.eventsPublicBaseUrl
          };

          if (batchId.current) {
            messageParams.navigate = `/funding-batches/${batchId.current}`;
          }

          // send config back down
          message.source.postMessage(
            JSON.stringify({
              source: 'gms',
              method: 'setConfiguration',
              params: messageParams
            }),
            '*' // TODO PG:  lookup allowed origin (in env vars?) based on data.source and use it here.
          );

          // Legacy GMS emits `navigate.success` events, the rest of the ui- plugins do not and should be shown when
          // they indicate they are ready.
          if (data.source !== 'legacy-ui') {
            setIsLoading(false);
          }
        }
      };

      window.addEventListener('message', handleLegacyGMSMessagePost);

      return () => {
        window.removeEventListener('message', handleLegacyGMSMessagePost);
      };
    }, []);

    useEffect(() => {
      logger.log(`LegacyViewer | Handling Route = ${window.location.href}`);
      const legacyUrl = legacyRouteMapper.getLegacyUrl(
        new Url(window.location.href),
        {
          orgId: selectedOrganization.id
        }
      );
      if (!legacyUrl) {
        logger.error(
          `LegacyViewer | Unable to Match Route: ${window.location.href}`
        );
        navigate('');
      } else if (!frameUrl || frameUrl.href !== legacyUrl.href) {
        if (eventHub) {
          eventHub.emit('navigate', {
            path: legacyUrl.path,
            params: getPathParams(legacyUrl.extras),
            organization: selectedOrganization
          });
        } else {
          setFrameUrl(legacyUrl);
        }
      }
    }, [window.location.href]);

    useEffect(
      () => () => {
        logger.log('LegacyViewer | Unloading EventHub');
        delete (window as any).eventHub;
      },
      []
    );

    function onIFrameLoaded(iframe: any) {
      if (
        iframe.contentDocument &&
        iframe.contentDocument.URL === 'about:blank'
      ) {
        return;
      }

      logger.log('LegacyViewer | Initializing EventHub');

      eventHub = new EventHub(iframe, frameUrl.origin, { logger });
      eventHub.subscribe('connected', () => onConnected());
      eventHub.subscribe('navigation', (x) => onNavigation(x.payload));
      eventHub.subscribe('window.open', (x) =>
        onNavigation(x.payload.url, x.payload.target, x.payload.features)
      );
      eventHub.subscribe('window.close', () => window.close());
      eventHub.subscribe('window.print', () => window.print());
      eventHub.subscribe('history.back', () => window.history.back());
      eventHub.subscribe('history.forward', () => window.history.forward());
      eventHub.subscribe('history.go', (x) => window.history.go(x.payload));
      eventHub.subscribe('title', (x) => (document.title = x.payload));
      eventHub.subscribe('wait.on', () => setIsWaiting(true));
      eventHub.subscribe('wait.off', () => setIsWaiting(false));
      eventHub.subscribe('body.height', (x) =>
        setFrameHeight(`${x.payload}px`)
      );
      eventHub.subscribe('alert', (x) => alerts(x.payload));

      eventHub.subscribe('navigate.success', (x) => {
        setFrameUrl(new Url(x.payload));
        setIsLoading(false);
      });
    }

    function onConnected(): void {
      logger.log('LegacyViewer | EventHub Connected');

      // Initial load is just to origin
      if (frameUrl.href !== frameUrl.origin) {
        eventHub.emit('navigate', {
          path: frameUrl.path,
          params: getPathParams(frameUrl.extras),
          organization: selectedOrganization
        });
      }

      (window as any).eventHub = eventHub;
      window.dispatchEvent(
        new CustomEvent('eventHub_ready', {
          bubbles: true,
          detail: eventHub
        })
      );
    }

    function onNavigation(
      href: string,
      target?: string,
      features?: string
    ): void {
      if (!isAbsolutePath(href)) {
        href = getAbsolutePath(href, frameUrl.href);
      }

      const url = new Url(href);
      const localUrl = legacyRouteMapper.getLocalUrl(url, params);

      if (!href.includes(legacyBatchIdPathname)) {
        batchId.current = '';
      }

      if (href.includes(legacyBatchIdPathname)) {
        const reconcilationUrl = legacyRouteMapper.getLegacyUrl(
          new Url(`${window.location.origin}${reconciliationPathname}`),
          {
            orgId: selectedOrganization.id
          }
        );
        const [, batchIdParam] = url.href.split(legacyBatchIdPathname);
        batchId.current = batchIdParam;
        setIsReconciliation(true);
        setFrameUrl(reconcilationUrl);
        setFrameHeight(undefined);
      } else if (localUrl) {
        if (target) {
          window.open(localUrl.href, target, features);
        } else if (localUrl.href === window.location.href) {
          setFrameUrl(url);
        } else {
          navigate(localUrl.pathWithExtras);
        }
      } else if (legacyRouteMapper.isLegacySite(url.href)) {
        logger.error(`LegacyViewer | Unrecognized Url: ${url.href}`);
        if (target) {
          window.open(url.href, target, features);
        } else {
          setFrameUrl(url);
        }
      } else if (legacyRouteMapper.isExternalSite(href)) {
        if (target) {
          window.open(href, target, features);
        } else {
          setFrameUrl(url);
        }
      } else {
        logger.log(`LegacyViewer | Url not allowed in iFrame: ${url}`);
        if (target) {
          window.open(localUrl.href, target, features);
        } else {
          window.location.href = url.href;
        }
      }
    }

    if (!frameUrl) {
      return <></>;
    }

    return (
      <Blocker block={isLoading}>
        {featureHeader}
        <div className="legacy-viewer fluid-container">
          {!isLoading && backgroundHeader === 'default' && (
            <div className="default-background-header"></div>
          )}
          <iframe
            id="legacyFrame"
            name="legacyFrame"
            title="Legacy Content"
            src={isReconciliation ? frameUrl.href : frameUrl.origin}
            sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation allow-modals allow-downloads allow-popups"
            allow="camera"
            onLoad={(e) => onIFrameLoaded(e.target)}
            height={frameHeight}
            style={{ visibility: isLoading ? 'hidden' : 'visible' }}
          ></iframe>
        </div>
      </Blocker>
    );
  }
);
