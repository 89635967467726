import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import {
  createCampaignService,
  getAllCampaignChannelMetrics,
  getAllCampaignsService,
  getCampaignByIdService,
  getCampaignEvents,
  getCampaignGivingForms,
  getCampaignPeerToPeers,
  getCampaignPerChannelMetrics,
  getCampaignTextToGive,
  getFieldSetByIdService,
  updateCampaign
} from 'services/campaignService';
import {
  CampaignPostBody,
  CampaignType,
  CampaignUpdateBody
} from 'services/types';

export const useCampaigns = (
  organizationId: string,
  queryOptions?: UseQueryOptions<CampaignType[], Error>
) =>
  useQuery<CampaignType[], Error>(
    ['getAllCampaigns', { organizationId }],
    () => getAllCampaignsService(organizationId),
    { ...queryOptions, refetchOnMount: 'always' }
  );

export const useCampaignById = (
  organizationId: string,
  campaignId: string,
  queryOptions: UseQueryOptions<CampaignType> = {}
) =>
  useQuery<CampaignType>(
    ['campaignById', { organizationId, campaignId }],
    () => getCampaignByIdService(organizationId, campaignId),
    { ...queryOptions, enabled: !!campaignId }
  );

export const useCampaignGivingForms = (
  organizationId: string,
  campaignId: string,
  isLoading?: boolean
) =>
  useQuery(
    ['campaignGivingForms', { organizationId, campaignId }],
    () => getCampaignGivingForms(organizationId, campaignId),
    // isLoading allows this hook to be called after the campaign's start date is retrieved
    { enabled: !isLoading }
  );

export const useCampaignPeerToPeers = (
  organizationId: string,
  campaignId: string,
  onError?: () => void
) =>
  useQuery(
    ['campaignPeerToPeers', { organizationId, campaignId }],
    () => getCampaignPeerToPeers(organizationId, campaignId),
    { onError }
  );

export const useCampaignEvents = (
  organizationId: string,
  campaignId: string,
  onError?: () => void
) =>
  useQuery(
    ['campaignEvents', { organizationId, campaignId }],
    () => getCampaignEvents(organizationId, campaignId),
    { onError }
  );

export const useCampaignTextToGive = (
  organizationId: string,
  campaignId: string,
  onError?: () => void
) =>
  useQuery(
    ['campaignTextToGive', { organizationId, campaignId }],
    () => getCampaignTextToGive(organizationId, campaignId),
    { onError, refetchOnMount: 'always' }
  );

export const useCampaignMetrics = (
  organizationId: string,
  campaignId: string,
  startDate: string,
  endDate?: string,
  isLoading?: boolean
) =>
  useQuery(
    [
      'campaignChannelMetrics',
      { organizationId, campaignId, startDate, endDate }
    ],
    () =>
      getAllCampaignChannelMetrics(
        organizationId,
        campaignId,
        startDate,
        endDate
      ),
    // isLoading allows this hook to be called after the campaign's start date is retrieved
    { enabled: !isLoading }
  );

export const usePerChannelMetrics = (
  organizationId: string,
  campaignId: string
) =>
  useQuery(['campaignPerChannelMetrics', { organizationId, campaignId }], () =>
    getCampaignPerChannelMetrics(organizationId, campaignId)
  );

export const useCreateCampaignMutation = (organizationId: string) => {
  const queryClient = useQueryClient();
  const queryKey = ['getAllCampaigns', { organizationId }];

  return useMutation(
    (campaign: CampaignPostBody) => createCampaignService(campaign),
    {
      onSuccess: (newCampaign: CampaignType) => {
        if (queryClient.getQueryData(queryKey)) {
          queryClient.setQueryData(queryKey, (oldCampaigns: CampaignType[]) => [
            ...oldCampaigns,
            newCampaign
          ]);
        }
      },
      onError: () => {
        queryClient.invalidateQueries(queryKey);
      }
    }
  );
};

export const useGetFieldSetById = (organizationId: string) =>
  useQuery(
    ['fieldSetByOrganizationId', { organizationId }],
    () => getFieldSetByIdService(organizationId),
    { cacheTime: 0 }
  );

export const useUpdateCampaign = (
  organizationId: string,
  campaignId: string
) => {
  const queryClient = useQueryClient();
  const queryKey = ['getAllCampaigns', { organizationId }];

  return useMutation(
    (body: CampaignUpdateBody) =>
      updateCampaign(organizationId, campaignId, body),
    {
      onSuccess: (newCampaign: CampaignType) => {
        if (queryClient.getQueryData(queryKey)) {
          queryClient.setQueryData(queryKey, (oldCampaigns: CampaignType[]) =>
            oldCampaigns.map((campaign) =>
              campaign.id === newCampaign.id ? newCampaign : campaign
            )
          );
        }
        queryClient.invalidateQueries([
          'campaignById',
          { organizationId, campaignId }
        ]);
        queryClient.invalidateQueries(['getOrganizationDashboard']); // invalidate dashboard queries
      },
      onError: () => {
        queryClient.invalidateQueries(queryKey);
      }
    }
  );
};
