import { ReactNode } from 'react';
import FlaskBlockerGif from 'assets/images/science-flask.gif';
import Text from 'components/Text';
import './FlaskBlocker.scss';

export interface FlaskBlockerProps {
  block: boolean;
  children: ReactNode;
}

export const FlaskBlocker = ({
  block,
  children
}: FlaskBlockerProps): JSX.Element => (
  <>
    {block && (
      <div className="flask-blocker-wrapper">
        <div className="flask-blocker-indicator">
          <div className="flask-blocker-circle">
            <img alt="Loading..." src={FlaskBlockerGif} />
            <div className="flask-blocker-text">
              <Text variant="h7">Hang Tight!</Text>
              <Text variant="caption">Building Your Test...</Text>
            </div>
          </div>
        </div>
      </div>
    )}
    {children}
  </>
);
