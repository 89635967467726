import { AxiosError } from 'axios';
import { v4 as uuid } from 'uuid';
import { Notification } from 'services';
import axios from 'services/axiosClient';

export const getCampaignNotifications = async ({
  organizationId,
  campaignId
}: {
  organizationId: string;
  campaignId: string;
}): Promise<Notification[]> => {
  try {
    const { data } = await axios.get<Notification[]>(`/notifications`, {
      params: {
        organizationId,
        campaignId
      }
    });

    return data.map((n) => ({ ...n, id: uuid() }));
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching notifications for your campaign.'
    );
  }
};
