import auth0Client from './auth0Client';

async function storeSessionData(
  user: any,
  accessToken: string,
  idToken: string
) {
  window.sessionStorage.setItem('isAuthenticated', 'true');
  window.sessionStorage.setItem('user', JSON.stringify(user));
  window.sessionStorage.setItem('accessToken', accessToken);
  window.sessionStorage.setItem('idToken', idToken);
}

async function clearSessionData() {
  window.sessionStorage.removeItem('isAuthenticated');
  window.sessionStorage.removeItem('user');
  window.sessionStorage.removeItem('accessToken');
  window.sessionStorage.removeItem('idToken');
}

export const loginService = async () => {
  const uri =
    window.location.hostname === 'localhost'
      ? `${window.location.hostname}:${window.location.port}`
      : window.location.hostname;
  const auth0 = await auth0Client;

  // TODO - LEGACY SUNSET - Remove this localStorage logic once legacy GMS is gone
  // Add a flag to localStorage so that we know to redirect to /designated-funds/new
  // and a flag for /designated-funds/edit
  if (window.location.pathname === '/settings/designated-funds/new') {
    window.localStorage.setItem('redirectToNewDesignation', 'true');
  }

  const regex = window.location.pathname.match(
    /(\/settings\/designated-funds\/)([a-zA-Z0-9-]*)(\/edit)/
  );
  if (regex) {
    // Regex matches:
    //  1) full matched string
    //  2) /settings/designated-funds/
    //  3) designation ID
    //  4) /edit
    window.localStorage.setItem('redirectToEditDesignation', regex[2]);
  }

  await auth0.loginWithRedirect({
    redirect_uri: `${window.location.protocol}//${uri}/dashboard`
  });
};

/**
 * Consult Auth0 library on whether the current user is authenticated
 *
 * If authenticated, also populate SessionData from Auth0 results.
 */
export async function checkAuthentication() {
  const auth0 = await auth0Client;

  if (await auth0.isAuthenticated()) {
    const accessToken = await auth0.getTokenSilently();
    const claims = await auth0.getIdTokenClaims();

    const user = await auth0.getUser();

    // eslint-disable-next-line no-underscore-dangle
    const idToken = claims?.__raw;

    await storeSessionData(user, accessToken, idToken);

    return true;
  }

  // do we need to clearSessionData before returning here?
  return false;
}

export async function authenticateUser() {
  try {
    const auth0 = await auth0Client;
    await auth0.handleRedirectCallback();

    window.history.replaceState({}, document.title, '/dashboard');

    return await checkAuthentication();
  } catch (e) {
    clearSessionData();
    await loginService();
    throw new Error('Old token');
  }
}

export async function logoutService() {
  const auth0 = await auth0Client;
  clearSessionData();
  await auth0.logout({
    returnTo: window.location.origin
  });
}
