import {
  Stepper as MuiStepper,
  Step,
  StepButton,
  StepLabel
} from '@mui/material';
import Text from 'components/Text';
import { StepIcon } from './StepIcon';
import './Stepper.scss';

interface StepperProps {
  activeStep: number;
  handleStep: (index: number) => void;
  hasHostedPage?: boolean;
}

export const Stepper = ({
  activeStep,
  handleStep,
  hasHostedPage
}: StepperProps): JSX.Element => {
  const labels = ['Settings', 'Edit Form B', 'Edit Page B', 'Review'];
  if (!hasHostedPage) {
    labels.splice(2, 1);
  }
  return (
    <MuiStepper alternativeLabel activeStep={activeStep} className="ab-stepper">
      {labels.map((label, index) => (
        <Step key={label}>
          <StepButton onClick={() => handleStep(index)}>
            <StepLabel StepIconComponent={StepIcon}>
              <Text variant="h6" className="ab-step-label">
                {label}
              </Text>
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </MuiStepper>
  );
};
