import { Tag } from 'components/WYSIWYG/WYSIWYG';
import {
  BlockTypes,
  ICustomFieldBlock,
  IDesignationsBlock,
  IGivingFormConfig,
  IPaymentInfoSection
} from 'types';
import { getBlockIndex } from 'utils/givingFormBlockUtils';

interface IPlaceHolder {
  [key: string]: string | number;
}

export const toProperCase = (str: string) =>
  str.replace(
    /\w\S*/g,
    (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

const keyToPlaceholderKey = (key: string): string => {
  const matchedKey = key
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/([A-Z][a-z])/g, ' $1')
    .split('  ')
    .join(' ');
  return toProperCase(matchedKey);
};

export const objectToPlaceholder = (obj: IPlaceHolder) => {
  const objEntries = Object.entries(obj);
  const placeholder: IPlaceHolder = {};
  objEntries.forEach(([key, val]) => {
    const formattedKey = keyToPlaceholderKey(key);
    Object.assign(placeholder, { [`{{${formattedKey}}}`]: val });
  });
  return placeholder;
};

export const placeholderParser = (string: string, obj: IPlaceHolder): string =>
  string.replace(/{{(.*?)}}/g, (match: string): string => {
    const placeHolders = objectToPlaceholder(obj);
    if (!placeHolders[match]) {
      return match;
    }
    return `${placeHolders?.[match] || match}`;
  });

/**
 * Same as giving form tag list, but we don't care which are enabled,
 * we're going to hide them if they don't exist in the data.
 */
export const createEmailTagList = (
  orgCustomFields: string[],
  enableSections = false,
  filterCb: (tag: Tag, index: number, array: Tag[]) => boolean = () => true
) => {
  const createTag = (name: string, value: string): Tag => ({ name, value });
  const donorAndGiftInfo = [
    createTag('Donor Name', '{{Donor Name}}'),
    createTag('Company Name', '{{Company Name}}'),
    createTag('Donor Address', '{{Donor Address}}'),
    createTag('Donor Phone', '{{Donor Phone}}'),
    createTag('Donor Email', '{{Donor Email}}'),
    createTag('Donation Amount', '{{Donation Amount}}'),
    createTag('Transaction Fee', '{{Transaction Fee}}'),
    createTag('Designation', '{{Designation}}'),
    createTag('Payment Method', '{{Payment Method}}'),
    createTag('Payment Expiration', '{{Payment Expiration}}'),
    createTag('Thank You Gift Name', '{{Thank You Gift Name}}'),
    createTag('Thank You Gift Description', '{{Thank You Gift Description}}'),
    createTag('Tribute Name', '{{Tribute Name}}'),
    createTag('Tribute Message', '{{Tribute Message}}'),
    createTag('Tribute Recipient Email', '{{Tribute Recipient Email}}')
  ];

  const scheduleInfo = [
    createTag('Billing Frequency', '{{Billing Frequency}}'),
    createTag('Billing Start Date', '{{Billing Start Date}}'),
    createTag('Schedule Number', '{{Schedule Number}}')
  ];

  const transaction = [
    createTag('Authorization Number', '{{Authorization Number}}'),
    createTag('Transaction Date', '{{Transaction Date}}'),
    createTag('Transaction Amount', '{{Transaction Amount}}'),
    createTag('Deductible Amount', '{{Deductible Amount}}'),
    createTag('Fair Market Value', '{{Fair Market Value}}')
  ];

  const customFields = orgCustomFields.map((f) => createTag(f, `{{${f}}}`));

  const sectionedTags = [
    {
      heading: 'Donor / Gift Information',
      tags: donorAndGiftInfo
    },
    {
      heading: 'Schedule Information',
      tags: scheduleInfo
    },
    {
      heading: "Today's Transaction",
      tags: transaction
    },
    {
      heading: 'Custom Fields',
      tags: customFields
    }
  ];

  const tags = [
    ...donorAndGiftInfo,
    ...scheduleInfo,
    ...transaction,
    ...customFields
  ].filter(filterCb);

  return enableSections ? sectionedTags : tags;
};

export const createTagList = (
  config: IGivingFormConfig,
  enableSections = false,
  additionalTags: Tag[] = [],
  filterCb: (tag: Tag, index: number, array: Tag[]) => boolean = () => true
) => {
  const createTag = (name: string, value: string): Tag => ({ name, value });

  const paymentBlockIndex = getBlockIndex(config, BlockTypes.PaymentSection);
  const designationBlockIndex = getBlockIndex(
    config,
    BlockTypes.DesignationsBlock
  );
  const transactionFeeIndex = getBlockIndex(
    config,
    BlockTypes.CoverTransactionFeeBlock
  );

  const isPhoneEnabled = (
    config.blocks?.[paymentBlockIndex] as IPaymentInfoSection
  ).billingInfoBlock.enablePhone;
  const isCompanyNameEnabled = (
    config.blocks?.[paymentBlockIndex] as IPaymentInfoSection
  ).billingInfoBlock.enableCompanyName;
  const hasDesignations =
    (config.blocks?.[designationBlockIndex] as IDesignationsBlock)?.designations
      .length > 0;
  const hasTransactionFeeOption = !!transactionFeeIndex;

  const customFields = config.blocks.filter(
    (block) => block.blockType === BlockTypes.CustomFieldBlock
  );
  const customFieldTags = (customFields as ICustomFieldBlock[]).map(
    ({ name }) => createTag(name, `{{${name}*}}`)
  );

  const donorAndGiftInfo = [
    createTag('Donor Name', '{{Donor Name}}'),
    isCompanyNameEnabled ? createTag('Company Name', '{{Company Name}}') : null,
    createTag('Donor Address', '{{Donor Address}}'),
    isPhoneEnabled ? createTag('Donor Phone', '{{Donor Phone}}') : null,
    createTag('Donor Email', '{{Donor Email}}'),
    createTag('Donation Amount', '{{Donation Amount}}'),
    hasTransactionFeeOption
      ? createTag('Transaction Fee', '{{Transaction Fee}}')
      : null,
    hasDesignations ? createTag('Designation', '{{Designation}}') : null,
    createTag('Payment Method', '{{Payment Method}}'),
    createTag('Payment Expiration', '{{Payment Expiration}}'),
    createTag('Thank You Gift Name', '{{Thank You Gift Name}}'),
    createTag('Thank You Gift Description', '{{Thank You Gift Description}}'),
    createTag('Tribute Name', '{{Tribute Name}}'),
    createTag('Tribute Message', '{{Tribute Message}}'),
    createTag('Tribute Recipient Email', '{{Tribute Recipient Email}}')
  ].filter((e) => e);

  const scheduleInfo = [
    createTag('Billing Frequency', '{{Billing Frequency}}'),
    createTag('Billing Start Date', '{{Billing Start Date}}'),
    createTag('Schedule Number', '{{Schedule Number}}')
  ];

  const transaction = [
    createTag('Authorization Number', '{{Authorization Number}}'),
    createTag('Transaction Date', '{{Transaction Date}}'),
    createTag('Transaction Amount', '{{Transaction Amount}}'),
    createTag('Deductible Amount', '{{Deductible Amount}}'),
    createTag('Fair Market Value', '{{Fair Market Value}}')
  ];

  const custom = [...customFieldTags, ...additionalTags];

  const sectionedTags = [
    {
      heading: 'Donor / Gift Information',
      tags: donorAndGiftInfo
    },
    {
      heading: 'Schedule Information',
      tags: scheduleInfo
    },
    {
      heading: "Today's Transaction",
      tags: transaction
    },
    {
      heading: 'Custom Fields',
      tags: custom
    }
  ];

  const tags = [
    ...donorAndGiftInfo,
    ...scheduleInfo,
    ...transaction,
    ...custom
  ].filter(filterCb);

  return enableSections ? sectionedTags : tags;
};

export const createUnfilteredTagList = (
  customFieldPlaceholders: string[],
  enableSections = false
) => {
  const createTag = (name: string, value: string): Tag => ({ name, value });

  const customFieldTags = customFieldPlaceholders.map((name) =>
    createTag(name, `{{${name}*}}`)
  );

  const donorAndGiftInfo = [
    createTag('Donor Name', '{{Donor Name}}'),
    createTag('Company Name', '{{Company Name}}'),
    createTag('Donor Address', '{{Donor Address}}'),
    createTag('Donor Phone', '{{Donor Phone}}'),
    createTag('Donor Email', '{{Donor Email}}'),
    createTag('Email Opt-In', '{{Email Opt-In}}'),
    createTag('Donation Amount', '{{Donation Amount}}'),
    createTag('Transaction Fee', '{{Transaction Fee}}'),
    createTag('Designation', '{{Designation}}'),
    createTag('Anonymous Gift', '{{Anonymous Gift}}'),
    createTag('Payment Method', '{{Payment Method}}'),
    createTag('Payment Expiration', '{{Payment Expiration}}'),
    createTag('Thank You Gift Name', '{{Thank You Gift Name}}'),
    createTag('Thank You Gift Description', '{{Thank You Gift Description}}'),
    createTag('Tribute Name', '{{Tribute Name}}'),
    createTag('Tribute Message', '{{Tribute Message}}'),
    createTag('Tribute Recipient Email', '{{Tribute Recipient Email}}')
  ].filter((e) => e);

  const scheduleInfo = [
    createTag('Billing Frequency', '{{Billing Frequency}}'),
    createTag('Billing Start Date', '{{Billing Start Date}}'),
    createTag('Schedule Number', '{{Schedule Number}}')
  ];

  const transaction = [
    createTag('Authorization Number', '{{Authorization Number}}'),
    createTag('Transaction Date', '{{Transaction Date}}'),
    createTag('Transaction Amount', '{{Transaction Amount}}'),
    createTag('Deductible Amount', '{{Deductible Amount}}'),
    createTag('Fair Market Value', '{{Fair Market Value}}')
  ];

  const sectionedTags = [
    {
      heading: 'Donor / Gift Information',
      tags: donorAndGiftInfo
    },
    {
      heading: 'Schedule Information',
      tags: scheduleInfo
    },
    {
      heading: "Today's Transaction",
      tags: transaction
    },
    {
      heading: 'Custom Fields',
      tags: customFieldTags
    }
  ];

  const tags = [
    ...donorAndGiftInfo,
    ...scheduleInfo,
    ...transaction,
    ...customFieldTags
  ];

  return enableSections ? sectionedTags : tags;
};
