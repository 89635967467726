import { useState } from 'react';
import { Skeleton } from '@mui/material';
import Button from 'components/Button';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { LimitedTextField } from 'components/TextField';
import { useAlerts } from 'hooks/useAlerts/useAlerts';
import { useDeleteAbTestNote, usePatchAbTestNotes } from 'queries/UseAbTests';
import { useGetUserInfo } from 'queries/UseUsers';
import { AbTest, AbTestNote as AbTestNoteType } from 'types/abTypes';
import { formatIsoDate } from 'utils';
import { AbTestNoteSkeleton } from '../AbTestNoteSkeleton';
import './AbTestNote.scss';

export interface AbTestNoteProps {
  note: AbTestNoteType;
  userId: string;
  organizationId: string;
  setNotes(notes: AbTestNoteType[]): void;
}

export const AbTestNote = ({
  note,
  userId,
  organizationId,
  setNotes
}: AbTestNoteProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [value, setValue] = useState(note.content);
  const [showModal, setShowModal] = useState(false);
  const [pushAlert] = useAlerts();
  const { data, isLoading: isUserLoading } = useGetUserInfo(
    note.userId,
    organizationId
  );

  const displayName =
    data?.firstName && data?.lastName
      ? `${data.firstName} ${data.lastName}`
      : data?.email;

  const { mutateAsync: patchAbTestNotes, isLoading: isPatchPending } =
    usePatchAbTestNotes(
      {
        content: value,
        organizationId,
        noteId: note.id
      },
      {
        onSuccess: (newTest: AbTest) => setNotes(newTest.notes),
        onError: () => {
          pushAlert({
            severity: 'error',
            title: 'There was an error updating your note',
            autoHideDuration: 5000
          });
          setValue(note.content);
        },
        onSettled: () => setIsEditMode(false)
      }
    );

  const { mutateAsync: deleteAbTestNote, isLoading: isDeletePending } =
    useDeleteAbTestNote(
      {
        organizationId,
        noteId: note.id
      },
      {
        onSuccess: (newTest: AbTest) => setNotes(newTest.notes),
        onError: () =>
          pushAlert({
            severity: 'error',
            title: 'There was an error deleting your note',
            autoHideDuration: 5000
          })
      }
    );

  const onClick = () => patchAbTestNotes();

  const onDelete = (close: () => void) => {
    close();
    deleteAbTestNote();
  };

  if (isPatchPending || isDeletePending) {
    return <AbTestNoteSkeleton />;
  }

  if (isEditMode) {
    return (
      <div id="note-container-edit">
        <LimitedTextField
          maxChar={2000}
          className="note-container-edit-input"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          multiline
        />
        <Button className="note-container-save" onClick={onClick}>
          Save Note
        </Button>
      </div>
    );
  }

  return (
    <div className="note-container" key={note.id}>
      <div className="note-publish-info">
        <Text variant="h6">
          {!isUserLoading ? (
            displayName || 'Do Gooder'
          ) : (
            <Skeleton id="ab-note-name-loading-skeleton" animation="wave" />
          )}
        </Text>
        <Text variant="h6Regular" className="note-publish-info-date">
          {formatIsoDate(note.createdAt, 'MM/dd/yyyy')}
        </Text>
      </div>
      <Text variant="body" className="note-container-user-note">
        {note.content}
      </Text>
      {note.userId === userId && (
        <div className="note-container-hover-overlay">
          <IconButton
            icon={ICONS.PENCIL}
            variant="secondary"
            label="edit"
            onClick={() => setIsEditMode(true)}
          />
          <IconButton
            icon={ICONS.TRASH}
            variant="secondary"
            label="delete"
            onClick={() => setShowModal(true)}
          />
        </div>
      )}

      <Dialog
        className="note-container-delete-modal"
        label="delete ab test dialog"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <DialogTitle onClick={(close) => close()}>Delete Note</DialogTitle>
        <DialogContent className="ab-test-note-dialog-content">
          <DialogContentTitle>Are you sure?</DialogContentTitle>
          <Text variant="body">
            Are you sure you want to delete this A/B test note? This action
            cannot be undone.
          </Text>
        </DialogContent>
        <DialogActions>
          <DialogActionButton
            onClick={(close) => onDelete(close)}
            variant="primary"
          >
            Yes, Delete Note
          </DialogActionButton>
          <DialogActionButton onClick={(close) => close()} variant="secondary">
            No, Go Back
          </DialogActionButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
