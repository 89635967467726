export enum BlockTypes {
  GiftOptionBlock = 'GiftOptionBlock',
  PaymentSection = 'PaymentSection',
  RecurringOptions = 'RecurringOptions',
  RecurringGiftPrompt = 'RecurringGiftPrompt',
  CustomContentBlock = 'CustomContentBlock',
  CustomFieldBlock = 'CustomFieldBlock',
  EmailOptInBlock = 'EmailOptInBlock',
  PaymentOptionsBlock = 'PaymentOptionsBlock',
  GiveAnonymouslyBlock = 'GiveAnonymouslyBlock',
  CoverTransactionFeeBlock = 'CoverTransactionFeeBlock',
  DesignationsBlock = 'DesignationsBlock',
  CorporateMatchingBlock = 'CorporateMatchingBlock',
  ThankYouCorporateMatchingBlock = 'ThankYouCorporateMatchingBlock',
  ThankYouMessageBodyBlock = 'ThankYouMessageBodyBlock',
  ThankYouMessageDonationDetailsBlock = 'ThankYouMessageDonationDetailsBlock',
  ThankYouHeaderBlock = 'ThankYouHeaderBlock',
  ThankYouAccountInvitationBlock = 'ThankYouAccountInvitationBlock',
  ThankYouGiftBlock = 'ThankYouGiftBlock',
  PageBreakBlock = 'PageBreakBlock',
  TributeBlock = 'TributeBlock'
}

export default interface BlockBaseType {
  id: string;
  blockType: BlockTypes;
}
