import { Box, styled } from '@mui/material';
import Button from 'components/Button';

export const ColorMenuContainer = styled(Box)({
  position: 'relative',
  width: 'fit-content',
  display: 'flex',
  justifyContent: 'center'
});

export const AddColorButton = styled(Button)({
  position: 'absolute',
  width: '90%',
  margin: '10px',
  bottom: 0
});
