import { BlockTypes, IGivingFormConfig } from 'types';

export const getGivingFormBlock = (
  block: BlockTypes,
  config?: IGivingFormConfig
) => config?.blocks.find((configBlock) => configBlock.blockType === block);

export const getThankYouBodyIndex = (config: IGivingFormConfig) =>
  config.thankYouConfig.blocks.findIndex(
    (block) => block.blockType === BlockTypes.ThankYouMessageBodyBlock
  );

export const getBlockIndex = (
  config: IGivingFormConfig,
  blockType: BlockTypes
) => config.blocks.findIndex((block) => block.blockType === blockType);

export const getThankYouBlockIndex = (
  config: IGivingFormConfig,
  blockType: BlockTypes
) =>
  config.thankYouConfig.blocks.findIndex(
    (block) => block.blockType === blockType
  );
