import * as yup from 'yup';

export const CreateAbTestSchema = yup.object().shape(
  {
    description: yup.string(),
    assumption: yup.string(),
    /**
     * When they have entered a number, require a valid unit
     */
    notificationUnit: yup.string().when('notificationUnitAmount', {
      is: (val: string) => !!val, // if there was an amount entered
      then: (schema) => schema.notOneOf(['Select'], 'Please enter a unit'), // make sure they don't still have "select" as their unit of choice
      otherwise: (schema) => schema.nullable(true)
    }),
    timeNotificationUnit: yup.string().when('timeNotificationUnitAmount', {
      is: (val: string) => !!val,
      then: (schema) => schema.notOneOf(['Select'], 'Please enter a unit'),
      otherwise: (schema) => schema.nullable(true)
    }),
    /**
     * When they have entered a unit, require a valid amount
     */
    notificationUnitAmount: yup
      .number()
      .moreThan(0, 'Please enter a valid amount')
      .when('notificationUnit', {
        is: (val: string) => val !== 'Select', // If they chose a unit other than select
        then: (schema) =>
          schema
            .required('Please enter an amount') // make sure they entered a valid amount
            .typeError('Please enter an amount'),
        otherwise: (schema) => schema.nullable(true)
      }),
    timeNotificationUnitAmount: yup
      .number()
      .moreThan(0, 'Please enter a valid amount')
      .when('timeNotificationUnit', {
        is: (val: string) => val !== 'Select',
        then: (schema) =>
          schema
            .required('Please enter an amount')
            .typeError('Please enter an amount'),
        otherwise: (schema) => schema.nullable(true)
      })
  },
  [
    ['timeNotificationUnit', 'timeNotificationUnitAmount'],
    ['notificationUnit', 'notificationUnitAmount']
  ]
);
