import { useEffect, useState } from 'react';
import {
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  Switch
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { yupResolver } from '@hookform/resolvers/yup';
import { add } from 'date-fns';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import Checkbox from 'components/Checkbox';
import Select, { SelectOption } from 'components/Select';
import Text from 'components/Text';
import TextField, {
  LimitedTextField,
  NumberTextField
} from 'components/TextField';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { useCampaignById } from 'queries/UseCampaigns';
import {
  GoalMeterConfig,
  GoalMeterType,
  GoalSource,
  HostedPageBlockTypes,
  IGivingFormConfig,
  IHostedPageConfig
} from 'types';
import { getHostedPageBlockDataById } from 'utils/hostedPageBlockUtils';
import './EditGoalMeter.scss';

const sectionTitleMaxChars = 50;

const goalMeterSchema = yup.object({
  goalMeter: yup.object({
    sectionTitle: yup
      .string()
      .max(
        sectionTitleMaxChars,
        `Section Title cannot exceed ${sectionTitleMaxChars} characters`
      ),
    goalMeterSource: yup.string().required('Required'),
    goalMeterType: yup.string().required('Required'),
    goalAmount: yup.number().required('Required').typeError('Required').min(1)
  })
});

const newGoalEndDate = add(new Date(), { days: 30 });

const emptyGoalMeter: GoalMeterConfig = {
  id: 'goalMeter',
  blockType: HostedPageBlockTypes.GoalMeterBlock,
  isEnabled: false,
  goalMeterType: GoalMeterType.PROGRESS,
  goalSource: GoalSource.CAMPAIGN,
  goalAmount: 1000,
  sectionTitle: '',
  goalEndDate: newGoalEndDate.toString(),
  showAmountRaised: false,
  showGoalAmount: false,
  showDaysLeft: false
};

type HostedPageBlocksArrays = Omit<
  IHostedPageConfig,
  | 'layout'
  | 'pageFavicon'
  | 'pageTitle'
  | 'slug'
  | 'header'
  | 'footer'
  | 'customCss'
>;

const EditGoalMeter = () => {
  const { campaignId } = useParams();
  const { selectedOrganization, selectedCampaignName } = useAppContext();
  const organizationId = selectedOrganization?.id;
  const { data: campaignData } = useCampaignById(organizationId, campaignId);

  const { control } = useFormContext();
  const { configData, updateConfig } = useConfigContext<IGivingFormConfig>();
  const { config } = configData;

  const [goalMeterBlock, setGoalMeterBlock] =
    useState<GoalMeterConfig>(emptyGoalMeter);

  const [showGoalDateInput, setShowGoalDateInput] = useState<boolean>(false);

  useEffect(() => {
    const goalMeterData = getHostedPageBlockDataById('goalMeter', config);
    if (goalMeterData) {
      const { block } = goalMeterData;
      setGoalMeterBlock(block as GoalMeterConfig);
      setShowGoalDateInput(
        (block as GoalMeterConfig).showDaysLeft &&
          (block as GoalMeterConfig).goalSource === GoalSource.CHANNEL
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData]);

  const goalMeterTypeOptions: SelectOption[] = [
    {
      label: 'Progress Bar',
      value: GoalMeterType.PROGRESS
    },
    {
      label: 'Speedometer',
      value: GoalMeterType.SPEEDOMETER
    },
    {
      label: 'Circle',
      value: GoalMeterType.CIRCLE
    }
  ];

  const getGoalMeterTypeRenderedValue = () => {
    const selection = goalMeterTypeOptions.find(
      (option) => option.value === goalMeterBlock.goalMeterType
    );
    return (
      <Stack direction="row" spacing={0.5}>
        <Text variant="body">{selection.label}</Text>
      </Stack>
    );
  };

  const getGoalAmountInitialValue = () => {
    if (
      goalMeterBlock.goalSource === GoalSource.CAMPAIGN &&
      !!campaignData?.goal
    ) {
      return campaignData.goal;
    }
    return goalMeterBlock.goalAmount;
  };

  const handleGoalSourceOnChange = (value: GoalSource) => {
    if (value === GoalSource.CAMPAIGN && !campaignData?.endDate) {
      setGoalMeterBlock({
        ...goalMeterBlock,
        goalSource: value,
        showDaysLeft: false
      });
    } else {
      setGoalMeterBlock({ ...goalMeterBlock, goalSource: value });
    }
  };

  const handleUpdate = () => {
    const newConfig = { ...configData.config };
    const goalMeterData = getHostedPageBlockDataById('goalMeter', newConfig);
    let section;
    let goalMeterIndex;
    // if goal meter already exists, update existing one
    // otherwise, add to top of hosted page
    if (goalMeterData) {
      section =
        `${goalMeterData.section}Blocks` as keyof HostedPageBlocksArrays;
      goalMeterIndex = goalMeterData.index;
    } else {
      section = 'topBlocks' as keyof HostedPageBlocksArrays;
      goalMeterIndex = 0;
    }
    newConfig.hostedPageConfig[section][goalMeterIndex] = goalMeterBlock;
    updateConfig(newConfig);
  };

  // note kstickel 12/13/22: for inputs where onChange and onBlur are functionally the same, such as radio buttons and checkboxes,
  // we must wait until state has been updated before we can trigger a config update
  useEffect(
    () => {
      handleUpdate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      goalMeterBlock.isEnabled,
      goalMeterBlock.goalSource,
      goalMeterBlock.goalMeterType,
      goalMeterBlock.showAmountRaised,
      goalMeterBlock.showGoalAmount,
      goalMeterBlock.showDaysLeft,
      goalMeterBlock.goalEndDate
    ]
  );

  return (
    <div className="element-library-edit-goal-meter">
      <FormControlLabel
        className="enable-goal-meter-switch"
        control={
          <Switch
            checked={goalMeterBlock.isEnabled}
            onChange={(event) => {
              setGoalMeterBlock({
                ...goalMeterBlock,
                isEnabled: event.target.checked
              });
            }}
            inputProps={{ 'aria-label': 'enable goal meter toggle switch' }}
          />
        }
        label="Enable Goal Meter"
      />
      <div className={goalMeterBlock.isEnabled ? '' : 'disabled'}>
        <div className="disabled-overlay" />
        <div className="goal-meter-editor-section-container">
          <Text className="goal-meter-editor-section-title" variant="h5">
            Section Title
          </Text>
          <Controller
            name="goalMeter.sectionTitle"
            control={control}
            defaultValue={goalMeterBlock.sectionTitle}
            render={({
              field: { ref, onChange, onBlur, ...field },
              fieldState: { error }
            }) => (
              <LimitedTextField
                {...field}
                hiddenLabel
                fullWidth
                multiline
                minRows={3}
                maxChar={sectionTitleMaxChars}
                error={!!error}
                value={goalMeterBlock.sectionTitle}
                onChange={(event) => {
                  onChange(
                    // note kstickel 12/14/22: MUI erroneously appends one additional character to end of value if user continues to
                    // type beyond maximum length; taking substring of value to remove 51st char if present
                    event.target.value.substring(0, sectionTitleMaxChars)
                  );
                  setGoalMeterBlock({
                    ...goalMeterBlock,
                    sectionTitle: event.target.value.substring(
                      0,
                      sectionTitleMaxChars
                    )
                  });
                }}
                onBlur={() => {
                  onBlur();
                  handleUpdate();
                }}
              />
            )}
          />
        </div>

        <div className="goal-meter-editor-section-container">
          <Text variant="h5">Select Goal Meter</Text>
          <Controller
            name="goalMeter.goalMeterSource"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <RadioGroup
                {...field}
                value={goalMeterBlock.goalSource}
                onChange={(event) => {
                  onChange(event.target.value);
                  handleGoalSourceOnChange(event.target.value as GoalSource);
                }}
              >
                <FormControlLabel
                  value={GoalSource.CAMPAIGN}
                  control={<Radio />}
                  label={
                    <Text variant="caption">
                      Campaign{' '}
                      {selectedCampaignName && (
                        <span className="goal-source-bold">
                          ({selectedCampaignName})
                        </span>
                      )}
                    </Text>
                  }
                />
                <FormControlLabel
                  value={GoalSource.CHANNEL}
                  control={<Radio />}
                  label={
                    <Text variant="caption">
                      Channel{' '}
                      {configData.name && (
                        <span className="goal-source-bold">
                          ({configData.name})
                        </span>
                      )}
                    </Text>
                  }
                />
              </RadioGroup>
            )}
          />
        </div>

        <div className="goal-meter-editor-section-container">
          <Text className="goal-meter-editor-section-title" variant="h5">
            Select Goal Meter Visual
          </Text>
          <Controller
            name="goalMeter.goalMeterType"
            control={control}
            defaultValue={goalMeterBlock.goalMeterType}
            render={({
              field: { ref, onChange, onBlur, ...field },
              fieldState: { error }
            }) => (
              <Select
                {...field}
                aria-label="goal meter visual"
                displayEmpty
                error={!!error}
                onBlur={() => onBlur()}
                onChange={(event) => {
                  onChange(event);
                  setGoalMeterBlock({
                    ...goalMeterBlock,
                    goalMeterType: event.target.value as GoalMeterType
                  });
                }}
                options={goalMeterTypeOptions}
                renderValue={getGoalMeterTypeRenderedValue}
                value={goalMeterBlock.goalMeterType}
              />
            )}
          />
        </div>
        <div className="goal-meter-editor-section-container">
          <Text className="goal-meter-editor-section-title" variant="h5">
            Goal Amount
          </Text>
          <Controller
            name="goalMeter.goalAmount"
            control={control}
            defaultValue={getGoalAmountInitialValue()} //! this loads before campaign data is done fethcing
            render={({
              field: { ref, onChange, onBlur, ...field },
              fieldState: { error }
            }) => (
              <NumberTextField
                {...field}
                aria-label="goal meter goal amount"
                decimalScale={0}
                error={!!error}
                fullWidth
                helperText={error && 'Required'}
                hiddenLabel
                onBlur={() => {
                  onBlur();
                  handleUpdate();
                }}
                onChange={(value) => {
                  onChange(value);
                  setGoalMeterBlock({
                    ...goalMeterBlock,
                    goalAmount: Number(value)
                  });
                }}
                placeholder="$"
                prefix="$"
                thousandSeparator
                value={goalMeterBlock.goalAmount}
              />
            )}
          />
        </div>
        <div className="goal-meter-editor-section-container">
          <Text variant="h4">Display Options</Text>
          <FormGroup className="goal-meter-display-options-container">
            <FormControlLabel
              label="Show Amount Raised"
              control={
                <Checkbox
                  checked={goalMeterBlock.showAmountRaised}
                  onChange={(event, isChecked) => {
                    setGoalMeterBlock({
                      ...goalMeterBlock,
                      showAmountRaised: isChecked
                    });
                  }}
                />
              }
            />
            <FormControlLabel
              label="Show Goal Amount"
              control={
                <Checkbox
                  checked={goalMeterBlock.showGoalAmount}
                  onChange={(event, isChecked) => {
                    setGoalMeterBlock({
                      ...goalMeterBlock,
                      showGoalAmount: isChecked
                    });
                  }}
                />
              }
            />
            <FormControlLabel // disable if campaign and no end date
              label="Show Days Left"
              control={
                <Checkbox
                  checked={goalMeterBlock.showDaysLeft}
                  disabled={
                    goalMeterBlock.goalSource === GoalSource.CAMPAIGN &&
                    !campaignData?.endDate
                  }
                  onChange={(event, isChecked) => {
                    setGoalMeterBlock({
                      ...goalMeterBlock,
                      showDaysLeft: isChecked
                    });
                    if (goalMeterBlock.goalSource === GoalSource.CHANNEL) {
                      setShowGoalDateInput(isChecked);
                    }
                  }}
                />
              }
            />
          </FormGroup>
          {showGoalDateInput && (
            <div className="goal-date-time-container">
              <Text variant="h5">Goal End Date</Text>
              <DateTimePicker
                minDate={new Date()}
                minDateTime={new Date()}
                value={goalMeterBlock.goalEndDate}
                onChange={(value) => {
                  setGoalMeterBlock({
                    ...goalMeterBlock,
                    goalEndDate: value.toString()
                  });
                }}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    error={props.error}
                    helperText={props.error && 'End date cannot be in the past'}
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default (): JSX.Element => {
  const methods = useForm({
    resolver: yupResolver(goalMeterSchema),
    mode: 'onChange'
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormProvider {...methods}>
        <EditGoalMeter />
      </FormProvider>
    </LocalizationProvider>
  );
};
