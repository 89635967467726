import { ReactFragment, useState } from 'react';
import { Box } from '@mui/material';
import { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import { RevertChangesModal } from 'components/RevertChangesModal';
import Text from 'components/Text';
import { FormDataType } from 'hooks/useConfigContext';
import { EditorTypes, EmailConfig, IGivingFormConfig } from 'types';
import { editsSavedTimeFormat } from 'utils';
import './EditsSaved.scss';

type EditsSavedProps = {
  configData: FormDataType<IGivingFormConfig> | FormDataType<EmailConfig>;
  onDiscard: () => void;
  modalCopy: ReactFragment;
  editorType: EditorTypes;
  isPublished?: boolean;
  isTemplate?: boolean;
};

export const EditsSaved = ({
  configData,
  onDiscard,
  modalCopy,
  editorType,
  isPublished,
  isTemplate
}: EditsSavedProps) => {
  const [revertChangesModalOpen, setRevertChangesModalOpen] =
    useState<boolean>(false);

  return (
    <Box className="GivingFormEditor-EditsSavedTime">
      <RevertChangesModal
        open={revertChangesModalOpen}
        onClose={() => setRevertChangesModalOpen(false)}
        onDiscard={onDiscard}
      >
        {modalCopy}
      </RevertChangesModal>
      <IconButton
        icon={ICONS.UNDO}
        label="revert changes"
        className="GivingFormEditor-RevertChanges"
        onClick={() => setRevertChangesModalOpen(true)}
        tooltipLabel="Revert Changes"
        variant="basic"
      />
      {editorType === EditorTypes.GivingForm && (isTemplate || isPublished) ? (
        <Text variant="h5">Unpublished Changes: </Text>
      ) : (
        <Text variant="h5">Draft Saved: </Text>
      )}
      <Text variant="caption">
        {editsSavedTimeFormat(configData.editsSavedTime)}
      </Text>
    </Box>
  );
};
