import { useCallback, useEffect, useMemo } from 'react';
import { Skeleton, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import { useForm } from 'react-hook-form';
import { SwitchElement } from 'react-hook-form-mui';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import * as yup from 'yup';
import { Option } from 'components/ComboCheckBox/ComboCheckBox';
import ComboBoxElement from 'components/ReactHookForm/ComboBoxElement';
import ComboCheckboxElement from 'components/ReactHookForm/ComboCheckBoxElement';
import LimitedTextElement from 'components/ReactHookForm/LimitedTextElement';
import Text from 'components/Text';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { useDesignations } from 'queries/UseDesignations';
import {
  BlockTypes,
  Designation,
  IDesignationsBlock,
  IGivingFormConfig
} from 'types';
import { mapDesignations } from 'utils/designations';
import './EditDesignations.scss';

const designationsSchema = yup.object({
  designations: yup.array().min(1, 'Must select at least one designation')
});

const DEFAULT_PROMPT_NAME = 'Select Your Designation';

const mapDesignationToOption = (designation: Designation): Option => ({
  label: designation.title,
  value: designation.id
});

const mapOptionToDesignation = (option: Option): Designation => ({
  title: option.label,
  id: option.value as string
});

const mapOptionToId = (option: Option): string => option.value as string;

const isDesignationBlock = (block: IGivingFormConfig['blocks'][number]) =>
  block.blockType === 'DesignationsBlock';

type EditDesignationsFormValues = {
  allowMultipleDesignations: boolean;
  designationsPrompt: string;
  designations: Option[];
  defaultDesignation: Designation | undefined;
};

const EditDesignationsForm = ({
  defaultFormValues,
  designations,
  onSubmit
}: {
  defaultFormValues: EditDesignationsFormValues;
  designations: Designation[];
  onSubmit: (values: EditDesignationsFormValues) => void;
}) => {
  const {
    control,
    watch,
    setValue,
    formState: { isValid }
  } = useForm({
    resolver: yupResolver(designationsSchema),
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: defaultFormValues
  });

  const watchAllFields = watch();
  const [allFields] = useDebounce(watchAllFields, 500, {
    equalityFn: (left, right) => isEqual(left, right)
  });

  useEffect(() => {
    if (isValid) {
      onSubmit(allFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFields, isValid]);

  const designationOptions = useMemo(
    () => designations.map(mapDesignationToOption),
    [designations]
  );
  const defaultDesignationOptions = useMemo(
    () => allFields.designations.map(mapOptionToDesignation),
    [allFields.designations]
  );

  const handleDesignationsChange = useCallback(
    (options: Option[]) => {
      const newDesignations: Designation[] = options.map(
        mapOptionToDesignation
      );

      if (
        allFields.defaultDesignation?.id &&
        !newDesignations.find((d) => d.id === allFields.defaultDesignation?.id)
      ) {
        setValue('defaultDesignation', null);
      }
    },
    [allFields.defaultDesignation?.id, setValue]
  );

  return (
    <Stack spacing={1} className="EditDesignations">
      <Text variant="h3">Prompt Name</Text>
      <LimitedTextElement
        name="designationsPrompt"
        control={control}
        maxChar={50}
        minRows={3}
        multiline
        fullWidth
        placeholder={DEFAULT_PROMPT_NAME}
      />

      <Text
        variant="h3"
        className="EditDesignations__select-designations-header"
      >
        Select Designations
      </Text>
      <ComboCheckboxElement
        name="designations"
        control={control}
        options={designationOptions}
        onChange={handleDesignationsChange}
        label={(numSelected) =>
          `(${numSelected}) Designation${numSelected === 1 ? '' : 's'}`
        }
      />
      <Text
        className="EditDesignations__select-designations-header"
        variant="h3"
      >
        Select Default Designation
      </Text>
      <Text variant="h6Regular">
        If the donor does not select a designation, their donation will go here.
      </Text>
      <ComboBoxElement
        name="defaultDesignation"
        control={control}
        options={defaultDesignationOptions}
        autocompleteProps={{
          className: 'EditDesignations__select-default',
          getOptionLabel: (option) => option.title ?? '',
          isOptionEqualToValue: (option, value) => option.id === value.id
        }}
      />

      <SwitchElement
        control={control}
        className="switch-element EditDesignations__allow-multiple-toggle"
        name="allowMultipleDesignations"
        label="Allow Multiple Designations"
      />
    </Stack>
  );
};

export default (): JSX.Element => {
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();
  const { campaignId } = useParams();

  const { data: designations, isLoading } = useDesignations({
    organizationId,
    campaignId
  });

  const mappedDesignationsForCampaign = useMemo(() => {
    if (designations?.length > 0) {
      return designations.map(mapDesignations);
    }
    return [];
  }, [designations]);

  const {
    configData: { config },
    updateConfig
  } = useConfigContext<IGivingFormConfig>();

  const designationBlock = useMemo(
    () => config.blocks.find(isDesignationBlock) as IDesignationsBlock,
    [config.blocks]
  );

  const defaultFormValues = useMemo<EditDesignationsFormValues>(() => {
    const getDesignationObjects = () => {
      if (!designationBlock || designationBlock?.designations?.length === 0) {
        return [];
      }
      let designationsArrayForForm: Option[] = [];
      const { designations: designationsFromConfig } = designationBlock;
      if (typeof designationsFromConfig[0] === 'string') {
        designationsArrayForForm = mappedDesignationsForCampaign
          .filter(({ id }) => designationsFromConfig.some((x) => x === id))
          .map(mapDesignationToOption);
      } else {
        designationsArrayForForm = (
          designationsFromConfig as Designation[]
        ).map(mapDesignationToOption);
      }
      return designationsArrayForForm;
    };

    const getDefaultDesignationObject = () => {
      if (!designationBlock?.defaultDesignation) return undefined;
      if (typeof designationBlock?.defaultDesignation === 'string')
        return mappedDesignationsForCampaign.find(
          ({ id }) => id === designationBlock.defaultDesignation
        );
      return designationBlock.defaultDesignation;
    };

    return {
      designationsPrompt: designationBlock?.promptText ?? '',
      designations: getDesignationObjects(),
      defaultDesignation: getDefaultDesignationObject(),
      allowMultipleDesignations: !designationBlock?.singleDesignation ?? true
    };
  }, [designationBlock, mappedDesignationsForCampaign]);

  const handleSave = (values: EditDesignationsFormValues) => {
    const commonDesignationBlock: Pick<
      IDesignationsBlock,
      'designations' | 'promptText' | 'defaultDesignation' | 'singleDesignation'
    > = {
      promptText: values.designationsPrompt ?? DEFAULT_PROMPT_NAME,
      defaultDesignation: values.defaultDesignation?.id,
      designations: values.designations.map(mapOptionToId),
      singleDesignation: !values.allowMultipleDesignations
    };

    const newConfig = {
      ...config,
      blocks: designationBlock
        ? config.blocks.map((block) =>
            block.blockType === 'DesignationsBlock'
              ? {
                  ...block,
                  ...commonDesignationBlock
                }
              : block
          )
        : [
            ...config.blocks,
            {
              blockType: BlockTypes.DesignationsBlock,
              id: '01-designationsBlock',
              ...commonDesignationBlock
            }
          ]
    };
    updateConfig(newConfig);
  };

  const block = isLoading ? (
    <Stack spacing={1} className="EditDesignations">
      <Text variant="h3">Prompt Name</Text>
      <Skeleton
        animation="wave"
        height={100}
        classes={{
          root: 'EditDesignations__select-designations-loader'
        }}
      />
      <Text
        variant="h3"
        className="EditDesignations__select-designations-header"
      >
        Select Designations
      </Text>
      <Skeleton
        animation="wave"
        height={60}
        classes={{
          root: 'EditDesignations__select-designations-loader'
        }}
      />
      <Text
        className="EditDesignations__select-designations-header"
        variant="h3"
      >
        Select Default Designation
      </Text>
      <Text variant="h6Regular">
        If the donor does not select a designation, their donation will go here.
      </Text>
      <Skeleton
        animation="wave"
        height={60}
        classes={{
          root: 'EditDesignations__select-designations-loader'
        }}
      />
      <Skeleton
        animation="wave"
        height={30}
        classes={{
          root: 'EditDesignations__select-designations-loader'
        }}
      />
    </Stack>
  ) : (
    <EditDesignationsForm
      designations={mappedDesignationsForCampaign ?? []}
      defaultFormValues={defaultFormValues}
      onSubmit={handleSave}
    />
  );

  return block;
};
