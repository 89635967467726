import { useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { SwitchElement, TextFieldElement } from 'react-hook-form-mui';
import { useDebounce } from 'use-debounce';
import * as yup from 'yup';
import LimitedTextElement from 'components/ReactHookForm/LimitedTextElement';
import NumberTextElement from 'components/ReactHookForm/NumberTextElement';
import Text from 'components/Text';
import { useConfigContext } from 'hooks/useConfigContext';
import { BlockTypes, IGivingFormConfig } from 'types';
import { IThankYouGiftBlock } from 'types/givingFormTypes/ThankYouGiftBlock';
import './EditThankYouGift.scss';
import ThankYouGiftImageManager from './ThankYouGiftImageManager';

const EditThankYouGiftSchema = yup.object({
  isEnabled: yup.boolean(),
  sectionTitle: yup.string().when('isEnabled', {
    is: true,
    then: yup.string().required('Section Title is required')
  }),
  giftName: yup.string().when('isEnabled', {
    is: true,
    then: yup.string().required('Name is required')
  }),
  oneTimeThreshold: yup.number(),
  recurringThreshold: yup.number(),
  description: yup.string().when('isEnabled', {
    is: true,
    then: yup.string()
  }),
  image: yup.string().when('isEnabled', {
    is: true,
    then: yup.string()
  }),
  sku: yup.string().when('isEnabled', {
    is: true,
    then: yup.string().required('SKU is required')
  }),
  fairMarketValue: yup.string().when('isEnabled', {
    is: true,
    then: yup.string().required('Fair Market Value is required')
  })
});

interface ThankYouGiftBlockFieldsProps {
  values: IThankYouGiftBlock;
  onSubmit: (values: IThankYouGiftBlock) => void;
}

const ThankYouGiftBlockForm = ({
  values,
  onSubmit
}: ThankYouGiftBlockFieldsProps): JSX.Element => {
  const {
    control,
    watch,
    formState: { errors },
    setValue
  } = useForm<IThankYouGiftBlock>({
    defaultValues: values,
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(EditThankYouGiftSchema),
    mode: 'all'
  });

  const watchAllFields = watch();
  const [allFields] = useDebounce(watchAllFields, 1000, {
    equalityFn: (left, right) => isEqual(left, right)
  });

  useEffect(() => {
    onSubmit(allFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFields]);

  return (
    <form>
      <div className="element-library-edit-thank-you-gift">
        <div className="text-fields">
          <SwitchElement
            control={control}
            name="isEnabled"
            className="switch-element"
            label="Enable Thank You Gift"
          />

          <div className={!watchAllFields.isEnabled ? 'disabled' : ''}>
            <div className="disabled-overlay" />

            <Stack spacing={1} className="edit-gift-field">
              <div>
                <Text variant="h5">Name This Gift</Text>
                <div className="text-field">
                  <TextFieldElement
                    name="giftName"
                    placeholder="Enter Name Here"
                    control={control}
                    hiddenLabel
                    fullWidth
                    error={!!errors.giftName}
                    helperText={errors.giftName?.message}
                  />
                </div>
              </div>

              <div>
                <Text variant="h5">
                  What is the minimum donation amount required to receive this
                  gift?
                </Text>
                <div className="text-field">
                  <Text
                    className="threshold-label"
                    variant="caption"
                    component="label"
                    htmlFor="recurringThreshold"
                  >
                    Recurring donation
                  </Text>
                  <NumberTextElement
                    name="recurringThreshold"
                    control={control}
                    fullWidth
                    placeholder="$"
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    error={!!errors.recurringThreshold}
                    helperText={errors.recurringThreshold?.message}
                  />
                </div>
                <div className="text-field">
                  <Text
                    className="threshold-label"
                    variant="caption"
                    component="label"
                    htmlFor="oneTimeThreshold"
                  >
                    One-time donation
                  </Text>
                  <NumberTextElement
                    name="oneTimeThreshold"
                    control={control}
                    fullWidth
                    placeholder="$"
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    error={!!errors.oneTimeThreshold}
                    helperText={errors.oneTimeThreshold?.message}
                  />
                </div>
              </div>

              <div>
                <Text variant="h5">Gift Description</Text>
                <div className="text-field">
                  <LimitedTextElement
                    name="description"
                    placeholder="Enter Description Here"
                    control={control}
                    hiddenLabel
                    fullWidth
                    multiline
                    minRows={3}
                    error={!!errors.description}
                    maxChar={300}
                  />
                </div>
              </div>

              <div>
                <Text variant="h5">Image</Text>
                <div className="images-field">
                  <Controller
                    control={control}
                    name="image"
                    render={({ field }) => (
                      <ThankYouGiftImageManager
                        image={field.value}
                        setImage={(image) =>
                          setValue('image', image, { shouldValidate: true })
                        }
                      />
                    )}
                  />
                </div>
              </div>

              <div>
                <Text variant="h5">Fair market value</Text>
                <div className="text-field">
                  <NumberTextElement
                    name="fairMarketValue"
                    control={control}
                    fullWidth
                    placeholder="$"
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    hiddenLabel
                    error={!!errors.fairMarketValue}
                    helperText={errors.fairMarketValue?.message}
                  />
                </div>
              </div>

              <div>
                <Text variant="h5">SKU</Text>
                <div className="text-field">
                  <TextFieldElement
                    name="sku"
                    control={control}
                    fullWidth
                    placeholder="Enter SKU here"
                    hiddenLabel
                    error={!!errors.sku}
                    helperText={errors.sku?.message}
                  />
                </div>
              </div>
            </Stack>
          </div>
        </div>
      </div>
    </form>
  );
};

const DEFAULT_THANK_YOU_GIFT_BLOCK: IThankYouGiftBlock = {
  id: 'thank-you-gift',
  blockType: BlockTypes.ThankYouGiftBlock,
  isEnabled: false,
  giftName: '',
  oneTimeThreshold: 0,
  recurringThreshold: 0,
  description: '',
  image: '',
  fairMarketValue: 0,
  sku: ''
};

const EditThankYouGifts = (): JSX.Element => {
  const {
    configData: { config },
    updateConfig
  } = useConfigContext<IGivingFormConfig>();

  const thankYouGiftBlock = useMemo(
    () =>
      (config.blocks.find(
        (x) => x.blockType === BlockTypes.ThankYouGiftBlock
      ) as IThankYouGiftBlock) || DEFAULT_THANK_YOU_GIFT_BLOCK,
    [config.blocks]
  );

  const handleSubmit = (values: IThankYouGiftBlock) => {
    const newBlock: IThankYouGiftBlock = {
      ...thankYouGiftBlock,
      ...values
    };
    const blockWithValidFields = Object.keys(
      DEFAULT_THANK_YOU_GIFT_BLOCK
    ).reduce(
      (block, key) => ({
        ...block,
        [key]: newBlock[key as keyof IThankYouGiftBlock]
      }),
      {}
    ) as IThankYouGiftBlock;

    if (!isEqual(blockWithValidFields, thankYouGiftBlock)) {
      const isThankYouGiftBlockExists = !!config.blocks.find(
        (x) => x.blockType === BlockTypes.ThankYouGiftBlock
      );

      const newBlocks = isThankYouGiftBlockExists
        ? config.blocks.map((block) =>
            block.blockType === BlockTypes.ThankYouGiftBlock
              ? blockWithValidFields
              : block
          )
        : [...config.blocks, blockWithValidFields];

      updateConfig({
        ...config,
        blocks: newBlocks
      });
    }
  };

  return (
    <ThankYouGiftBlockForm values={thankYouGiftBlock} onSubmit={handleSubmit} />
  );
};

export default EditThankYouGifts;
