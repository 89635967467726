import { useMemo, useState } from 'react';
import {
  Box,
  FormControlLabel,
  InputAdornment,
  Switch,
  TableCell
} from '@mui/material';
import { matchSorter } from 'match-sorter';
import { useNavigate } from 'react-router-dom';
import { Blocker, ButtonProps } from 'components';
import Icon, { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import { DateCell, Table, TableHeaderProps } from 'components/Table';
import { BooleanCell } from 'components/Table/BooleanCell';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import { useAppContext } from 'hooks/useAppContext';
import { useOrgFeatures } from 'hooks/useOrgFeatures';
import { useDesignations } from 'queries/UseDesignations';
import { DesignationGroup } from 'services';
import './DesignationList.scss';

export const DesignationList = () => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const [hideArchived, setHideArchived] = useState<boolean>(true);

  const navigate = useNavigate();
  const orgFeatures = useOrgFeatures();
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();

  const { data: designations, isLoading } = useDesignations({
    organizationId,
    campaignId: null,
    isActiveFilter: hideArchived,
    refetchOnWindowFocus: true
  });

  const buttonProps: ButtonProps = {
    children: 'New Designated Fund',
    startIcon: <Icon icon={ICONS.PLUS} />,
    onClick: () => navigate('/settings/designated-funds/new')
  };

  const handleEdit = (designationId: string) => {
    navigate(`/settings/designated-funds/${designationId}/edit`);
  };

  // start with all advanced designations
  const designationHeaders: TableHeaderProps[] = [
    {
      key: 'edit',
      label: '',
      renderer: (value, designation) => (
        <TableCell>
          <IconButton
            variant="basic"
            label="Edit"
            tooltipLabel="Edit Designation"
            icon={ICONS.PENCIL}
            onClick={() => handleEdit(designation.id)}
          />
        </TableCell>
      )
    },
    {
      key: 'startDate',
      label: 'Start Date',
      renderer: (value) => <DateCell value={value} />
    },
    {
      key: 'title',
      label: 'Designation Title'
    },
    {
      key: 'code',
      label: 'Designation Label'
    },
    {
      key: 'description',
      label: 'Designation Description'
    },
    {
      key: 'groups',
      label: 'Groups',
      renderer: (value: DesignationGroup[]) => {
        const groupString = value.reduce(
          (acc, val) => (!acc ? val.name : `${acc}, ${val.name}`),
          ''
        );

        return <TableCell>{groupString}</TableCell>;
      }
    },
    {
      key: 'tags',
      label: 'Tags',
      renderer: (value: string[]) => {
        const tagString = value.reduce(
          (acc, val) => (!acc ? val : `${acc}, ${val}`),
          ''
        );

        return <TableCell>{tagString}</TableCell>;
      }
    },
    {
      key: 'image',
      label: 'Image Present?',
      renderer: (value) => <BooleanCell value={value} />
    },
    {
      key: 'endDate',
      label: 'End Date',
      renderer: (value) => <DateCell value={value} />
    },
    {
      key: 'isActive',
      label: 'Status',
      renderer: (value) => (
        <BooleanCell value={value} trueValue="Active" falseValue="Archived" />
      )
    }
  ];

  const advancedHeaders = [
    'startDate',
    'endDate',
    'image',
    'groups',
    'tags',
    'description'
  ];

  const filteredHeaders = useMemo(() => {
    if (!orgFeatures?.advancedDesignations) {
      return designationHeaders.filter(
        (header) => !advancedHeaders.includes(header.key)
      );
    }

    return designationHeaders;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgFeatures]);

  const searchedResults = useMemo(() => {
    if (designations && searchQuery) {
      return matchSorter(designations, searchQuery, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ['title', 'code', 'description', 'groups.*.name', 'tags']
      });
    }

    return designations;
  }, [designations, searchQuery]);

  return (
    <div className="designation-list">
      <FeatureHeader
        primaryButtonProps={buttonProps}
        titleProps="Designated Funds"
      />
      <Box padding={7} paddingTop={11} display="flex" flexDirection="column">
        <Box className="designation-list-wrapper">
          <TextField
            className="list-header-search-bar"
            placeholder="Search Desginations"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon={ICONS.SEARCH} />
                </InputAdornment>
              )
            }}
            autoComplete="off"
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideArchived}
                onChange={(event, checked) => setHideArchived(checked)}
              />
            }
            label={<Text variant="body">Hide Archived</Text>}
          />
        </Box>
        <Blocker block={isLoading}>
          <Table
            headers={filteredHeaders}
            rows={searchedResults}
            isLoading={isLoading}
          />
        </Blocker>
      </Box>
    </div>
  );
};
