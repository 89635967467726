import { FieldValues } from 'react-hook-form';
import {
  CheckboxElement as BaseCheckboxElement,
  CheckboxElementProps
} from 'react-hook-form-mui';
import Icon, { ICONS } from '../Icon';

const CheckboxElement = <T extends FieldValues>(
  props: CheckboxElementProps<T>
) => (
  <BaseCheckboxElement
    icon={<Icon icon={ICONS.SQUARE} />}
    checkedIcon={<Icon icon={ICONS.SQUARE_CHECK} />}
    indeterminateIcon={<Icon icon={ICONS.SQUARE_MINUS} />}
    {...props}
  />
);

export default CheckboxElement;
