import { Ref } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Icon, { ICONS } from 'components/Icon';
import TextField from '../TextField';
import './ComboBox.scss';

type ComboBoxProps<T> = Omit<
  AutocompleteProps<T, false, false, true>,
  'renderInput'
> & {
  placeholder?: string | JSX.Element;
  innerRef?: Ref<unknown>;
};
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ComboBox = <T extends unknown>({
  options,
  placeholder = 'Select',
  innerRef,
  ...rest
}: ComboBoxProps<T>): JSX.Element => (
  <Autocomplete
    id="GMS-ComboBox"
    options={options}
    ref={innerRef}
    {...rest}
    popupIcon={<Icon icon={ICONS.CHEVRON_DOWN} />}
    renderInput={(params) => (
      <TextField {...params} placeholder={placeholder} />
    )}
  />
);

export default ComboBox;
