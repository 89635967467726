import { differenceInDays, format, parseISO, subDays } from 'date-fns';
import {
  formatInTimeZone,
  format as formatTz,
  utcToZonedTime
} from 'date-fns-tz';
import { DateRange, DateRangeValueComparisonLabel } from './types';

const fmt = (
  date?: string,
  defaultToToday?: boolean,
  formatString?: string
) => {
  if (!date && !defaultToToday) {
    return '';
  }

  let parsedDate = null;
  if (!date) {
    parsedDate = new Date();
  } else {
    parsedDate = parseISO(date);
  }

  return format(parsedDate, formatString || 'MM/dd/yy');
};

export const formatDate = (date: string) => fmt(date, false);

export const formatDateOrToday = (date?: string) => fmt(date, true);

export const formatIsoDate = (
  dateString: string,
  dateFormat: string,
  timeZone?: string
) => {
  if (!dateString) {
    return '';
  }
  const parsedDate = parseISO(dateString);

  if (timeZone) {
    return formatTz(utcToZonedTime(parsedDate, timeZone), dateFormat, {
      timeZone
    });
  }

  return format(parsedDate, dateFormat);
};

export const remainingDays = (startDate: Date, endDate: Date) =>
  differenceInDays(startDate, endDate) * -1;

// Display format for edits saved time isn't a simple-to-produce format, need to build it up:
export const editsSavedTimeFormat = (date: Date) => {
  const intlDateTime = Intl.DateTimeFormat();

  const userTimezone = intlDateTime.resolvedOptions().timeZone;
  const time = formatInTimeZone(date, userTimezone, 'hh:mm');
  const amOrPm = formatInTimeZone(date, userTimezone, 'a').toLowerCase();
  const timezone = formatInTimeZone(date, userTimezone, 'zzz');

  return `${intlDateTime.format(date)} ${time} ${amOrPm} ${timezone}`;
};

/**
 * Returns an object with a start and end date range with the end date being the current date.
 * -1 as {daysToSubtract} will be interpreted as Year To Date range.
 * 0 as {daysToSubtract} will be interpreted as All Time and will set the start date as null                              .
 * @param  {number} daysToSubtract - the days to subtract from the current date
 */
export const getSelectedDateRangeFromCurrentDate = (
  daysToSubtract: number
): DateRange => {
  const currentDay = new Date();
  // If 'ytd' then set the start date to Jan 1st of the current year
  if (daysToSubtract === -1) {
    return {
      startDate: new Date(currentDay.getFullYear(), 0, 1),
      endDate: currentDay
    };
  }
  // If 'All' time then we're manually setting the start date
  if (daysToSubtract === 0) {
    return {
      startDate: currentDay,
      endDate: currentDay
    };
  }

  const subtractedDays = subDays(currentDay, daysToSubtract);
  return {
    startDate: subtractedDays,
    endDate: currentDay
  };
};

export const getDateComparisonUnit = (selectedRangeValue: number) => {
  switch (selectedRangeValue) {
    case 7:
      return DateRangeValueComparisonLabel.OneWeek;
    case 30:
      return DateRangeValueComparisonLabel.OneMonth;
    case 91:
      return DateRangeValueComparisonLabel.ThreeMonths;
    case 365:
      return DateRangeValueComparisonLabel.OneYear;
    case -1:
      return DateRangeValueComparisonLabel.YearToDate;
    default:
      return '';
  }
};
