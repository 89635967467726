import { useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Blocker } from 'components';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import ComboCheckBox from 'components/ComboCheckBox';
import DatePicker from 'components/DatePicker';
import Text from 'components/Text';
import { LimitedTextField, NumberTextField } from 'components/TextField';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { OrganizationRole, useRenderIfRole } from 'hooks/useRenderIfRole';
import { useCreateCampaignMutation } from 'queries/UseCampaigns';
import { useDesignations } from 'queries/UseDesignations';
import { mapDesignations } from 'utils/designations';
import { INewCampaignSchema, NewCampaignSchema } from './NewCampaign.Schema';
import './NewCampaign.scss';

export const NewCampaign = (): JSX.Element => {
  const navigate = useNavigate();
  const [pushAlert] = useAlerts();
  const { renderIfRole } = useRenderIfRole();
  const {
    setSelectedCampaignName,
    setShowNewCampaignDialog,
    selectedOrganization: { id: organizationId }
  } = useAppContext();

  const { mutateAsync: createCampaignMutation, isLoading } =
    useCreateCampaignMutation(organizationId);

  const { data: activeDesignationsInOrg } = useDesignations({
    organizationId
  });

  const mappedDesignations = useMemo(() => {
    if (activeDesignationsInOrg?.length > 0) {
      return activeDesignationsInOrg.map(mapDesignations);
    }
    return [];
  }, [activeDesignationsInOrg]);

  const methods = useForm<INewCampaignSchema>({
    resolver: yupResolver(NewCampaignSchema),
    mode: 'onBlur'
  });

  const handleCreateCampaign = async (data: INewCampaignSchema) => {
    try {
      const response = await createCampaignMutation({
        ...data,
        goalAmount: data?.goalAmount ? Number(data?.goalAmount) : undefined,
        organizationId,
        designationIds: data.designations.map((d) => d.value as string)
      });
      setSelectedCampaignName(response.title);
      setShowNewCampaignDialog(true);
      navigate(`/campaigns/${response.id}/`, {
        state: { showNewCampaignModal: true }
      });
    } catch (e) {
      pushAlert({
        title: 'Error creating campaign',
        description: `${e as string}`,
        severity: 'error',
        autoHideDuration: 10000
      });
    }
  };

  const breadcrumbs = [
    {
      label: 'All Campaigns',
      path: '/campaigns'
    },
    {
      label: 'New Campaign',
      path: '/campaigns/new'
    }
  ];

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((data) => handleCreateCampaign(data))}
      >
        <Box className="new-campaign-header">
          <Stack
            className="new-campaign-header-stack"
            alignItems="center"
            spacing={1.5}
          >
            <div className="new-campaign-breadcrumbs">
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
            <Text variant="h2" color="primary">
              New Campaign
            </Text>
            <Text variant="h1">What are the details of your campaign?</Text>
          </Stack>
        </Box>
        <Blocker block={isLoading}>
          <Stack className="new-campaign-form" alignItems="center">
            <Stack direction="row" spacing={1} justifyContent="center">
              <Stack
                className="new-campaign-field-labels"
                spacing={2.8125}
                alignItems="flex-end"
              >
                <Text variant="h3">My campaign is named</Text>
                <Text variant="h3">and I want to raise</Text>
                <Text variant="h3">between</Text>
                <Text variant="h3">for</Text>
              </Stack>
              <Stack className="new-campaign-fields" spacing={1}>
                <Controller
                  name="title"
                  defaultValue=""
                  control={methods.control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error }
                  }) => (
                    <LimitedTextField
                      {...field}
                      maxChar={100}
                      hiddenLabel
                      placeholder="Enter Campaign Name"
                      error={!!error}
                      helperText={error?.message ?? null}
                    />
                  )}
                />
                <Controller
                  name="goalAmount"
                  defaultValue={null}
                  control={methods.control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error }
                  }) => (
                    <NumberTextField
                      {...field}
                      hiddenLabel
                      valueIsNumericString
                      thousandSeparator
                      prefix="$"
                      placeholder="$ Enter Goal Amount"
                      error={!!error}
                      helperText={error?.message ?? null}
                    />
                  )}
                />
                <Stack direction="row" spacing={0.75} alignItems="flex-start">
                  <Controller
                    name="startDate"
                    control={methods.control}
                    defaultValue={new Date().toString()}
                    render={({
                      field: { ref, onChange, ...field },
                      fieldState: { error }
                    }) => (
                      <DatePicker
                        value={field.value}
                        error={!!error}
                        onChange={(...args) => {
                          onChange(...args);
                          methods.trigger('startDate');
                        }}
                        TextFieldProps={{
                          ...field,
                          helperText: error?.message ?? null
                        }}
                      />
                    )}
                  />
                  <Text variant="h3" className="new-campaign-date-divider">
                    -
                  </Text>
                  <Controller
                    name="endDate"
                    control={methods.control}
                    render={({
                      field: { ref, onChange, ...field },
                      fieldState: { error }
                    }) => (
                      <DatePicker
                        error={!!error}
                        minDate={new Date()}
                        label="No End Date"
                        onChange={(...args) => {
                          onChange(...args);
                          methods.trigger('startDate');
                          methods.trigger('endDate');
                        }}
                        TextFieldProps={{
                          ...field
                        }}
                      />
                    )}
                  />
                </Stack>
                <Controller
                  name="designations"
                  defaultValue={[]}
                  render={({
                    field: { ref, onBlur, ...field },
                    fieldState: { error }
                  }) => (
                    <ComboCheckBox
                      {...field}
                      options={
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        mappedDesignations?.map((d: any) => ({
                          label: d.title,
                          value: d.id
                        })) ?? []
                      }
                      error={!!error}
                      onBlur={async () => {
                        await methods.trigger('designations');
                      }}
                      helperText={error?.message ?? null}
                      label={(numSelected) =>
                        `(${numSelected}) Designation${
                          numSelected === 1 ? '' : 's'
                        }`
                      }
                    />
                  )}
                />
              </Stack>
            </Stack>
            <div className="new-campaign-button-container">
              {renderIfRole(
                <Button
                  fullWidth
                  type="submit"
                  disabled={!methods.formState.isValid}
                >
                  Create Campaign
                </Button>,
                OrganizationRole.Editor
              )}
            </div>
          </Stack>
        </Blocker>
      </form>
    </FormProvider>
  );
};
