import { Navigate, useLocation } from 'react-router-dom';

type Props = {
  children: JSX.Element;
};

export const AuthorizedRoute = ({ children }: Props): JSX.Element => {
  const location = useLocation();
  // TODO: Add authentication logic here
  const authorized = true;

  if (!authorized) {
    return <Navigate to="/log-in" state={{ from: location }} />;
  }

  return children;
};
