import { IPaymentGateway } from 'services';
import BlockBaseType from './BlockBaseType';

export interface IBillingInfoBlock {
  enablePhone: boolean;
  enableCompanyName: boolean;
  enablePrefix: boolean;
  enableMiddleName: boolean;
  enableInternationalDonations: boolean;
}

export enum AllPaymentOptions {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  APPLEPAY = 'APPLEPAY',
  PAYPAL = 'PAYPAL',
  GOOGLEPAY = 'GOOGLEPAY',
  ECHECK = 'ECHECK'
}

export type PaymentOptionsWithIcons =
  | 'APPLEPAY'
  | 'ECHECK'
  | 'GOOGLEPAY'
  | 'PAYPAL';

export interface IPaymentOption {
  paymentType: AllPaymentOptions;
}

export interface IPaymentOptionsBlock {
  paymentOptions: AllPaymentOptions[];
  defaultOption?: string;
}

export interface IPaymentInfoSection extends BlockBaseType {
  billingInfoBlock: IBillingInfoBlock;
  paymentOptionsBlock: IPaymentOptionsBlock;
  ccPaymentProvider: string;
  paymentGateway?: IPaymentGateway;
}
