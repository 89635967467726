import { CampaignGivingForm } from 'services/types/campaignTypes';

export const sortGivingFormsByDate = (
  a: CampaignGivingForm,
  b: CampaignGivingForm
) => {
  const dateA: string = new Date(a.updatedAt).toISOString();
  const dateB: string = new Date(b.updatedAt).toISOString();
  if (dateA < dateB) return 1;
  if (dateA > dateB) return -1;
  return 0;
};

// sort an array of giving forms or an array of campaigns
export const sortAlphabetically = (a: { name: string }, b: { name: string }) =>
  a.name.toLowerCase().localeCompare(b.name.toLowerCase());

export const filterChannelsByActiveStatus = (
  archiveStatus: 'active' | 'archived',
  channel: { isArchived: boolean }
) => {
  if (archiveStatus === 'active') return !channel.isArchived;
  return channel.isArchived;
};
