import { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { checkIfDraftExists, discardDraftById } from 'hooks/useAbTestDraft';
import { FormDataType } from 'hooks/useConfigContext';
import { useUser } from 'queries/UseUsers';
import { AbTest, AbTestDraft } from 'types';
import { formatDate, usdCurrencyFormatter } from 'utils';
import { AbTestStatusChip } from '../AbTestOverviewBody/AbTestStatusChip';
import { DiscardAbTestDraftDialog } from '../DiscardAbTestDraftDialog';
import './AbTestPreviewCard.scss';
import { AbTestPreviewCardMetrics } from './AbTestPreviewCardMetrics';

type AbTestPreviewCardProps = {
  abTest: AbTest;
  marginOnEven?: boolean;
  onDraftDiscarded: () => void;
  onEndAbTest?: (abTestId: string) => void;
  onPreviewClick?: () => void;
};

/**
 * A card used to display an overview of campaign's A/B tests
 */
export const AbTestPreviewCard = ({
  abTest,
  marginOnEven = true,
  onDraftDiscarded,
  onEndAbTest,
  onPreviewClick
}: AbTestPreviewCardProps) => {
  const navigate = useNavigate();

  const {
    data: { id: userId }
  } = useUser();

  const [hasDraft, setHasDraft] = useState(false);
  const [draft, setDraft] = useState<FormDataType<AbTestDraft>>();
  const [showDiscardAbTestDraftDialog, setShowDiscardAbTestDraftDialog] =
    useState(false);
  useEffect(() => {
    checkIfDraftExists(userId, abTest.givingForm.id).then((abTestDraft) => {
      setHasDraft(!!abTestDraft);
      setDraft(abTestDraft);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderConversionRate = useMemo(() => {
    const conversionDelta = (abTest?.metrics?.delta?.conversionRate ?? 0) * 100;

    return (
      <AbTestPreviewCardMetrics
        rowTitle="Conversion Rate"
        valueA={
          abTest?.metrics?.variantA?.conversionRate !== undefined
            ? `${(
                (abTest?.metrics?.variantA?.conversionRate ?? 0) * 100
              ).toFixed(1)}%`
            : 'N/A'
        }
        valueB={
          abTest?.metrics?.variantB?.conversionRate !== undefined
            ? `${(
                (abTest?.metrics?.variantB?.conversionRate ?? 0) * 100
              ).toFixed(1)}%`
            : 'N/A'
        }
        delta={conversionDelta}
      />
    );
  }, [abTest?.metrics]);

  const renderAverageDonationAmount = useMemo(() => {
    const averageDonationDelta =
      (abTest?.metrics?.delta?.donationAverage ?? 0) * 100;

    return (
      <AbTestPreviewCardMetrics
        rowTitle="Average Donation Amount"
        valueA={usdCurrencyFormatter.format(
          Math.round(abTest?.metrics?.variantA?.donationAverage ?? 0)
        )}
        valueB={usdCurrencyFormatter.format(
          Math.round(abTest?.metrics?.variantB?.donationAverage ?? 0)
        )}
        delta={averageDonationDelta}
      />
    );
  }, [abTest?.metrics]);

  const onDiscardDraftConfirmClick = () => {
    discardDraftById(draft.config.givingForm.id, abTest.givingForm.id, userId);
    setHasDraft(false);
    setDraft(null);
    onDraftDiscarded(); // let the parent know a draft was discarded
    setShowDiscardAbTestDraftDialog(false);
  };

  return (
    <>
      <div
        className={clsx({
          'ab-test-preview-box': true,
          'margin-on-even': marginOnEven
        })}
      >
        <div className="overview-hover">
          {hasDraft ? (
            <>
              <Button
                variant="secondary"
                onClick={() =>
                  navigate(`/strategy/ab-test/create/${abTest.givingForm.id}`)
                }
                startIcon={<Icon icon={ICONS.PENCIL} />}
              >
                Edit
              </Button>
              <Button
                variant="secondary"
                onClick={() => setShowDiscardAbTestDraftDialog(true)}
                startIcon={<Icon icon={ICONS.TRASH} />}
              >
                Discard Draft
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={() =>
                  navigate(`/strategy/ab-test/${abTest.abTestId}/overview`)
                }
                startIcon={<Icon icon={ICONS.CHART_INFOGRAPHIC} />}
              >
                Overview
              </Button>
              <Button
                onClick={() => onPreviewClick()}
                startIcon={<Icon icon={ICONS.EYE} />}
                variant="secondary"
              >
                Preview
              </Button>
              {!abTest?.endedAt && (
                <Button
                  variant="secondary"
                  startIcon={<Icon icon={ICONS.CLIPBOARD_X} />}
                  onClick={() => onEndAbTest(abTest.abTestId)}
                >
                  End Test
                </Button>
              )}
            </>
          )}
          {/* <Button variant="secondary" startIcon={<Icon icon={ICONS.EYE} />}> -- MAR - add these back in once we provide functionality for the buttons
        Preview
      </Button>
      */}
        </div>
        <div
          className={clsx('ab-test-preview-header', {
            'header-draft': hasDraft
          })}
        />
        <div className="ab-test-preview-content">
          <div className="main-details">
            <div className="title-container">
              <IconButton
                label="a/b tests label icon"
                className={clsx('channel-icon', {
                  'icon-draft': hasDraft
                })}
                icon={ICONS.COPY_DASHED}
                variant="primary"
                size="large"
              />
              <div>
                <Text variant="h3">{abTest?.name}</Text>
                <Text variant="caption" className="ab-test-date-range">
                  {hasDraft
                    ? `Created ${formatDate(abTest.createdAt)}`
                    : `${formatDate(abTest.createdAt)} to ${
                        formatDate(abTest.endedAt) || 'Present'
                      }`}
                </Text>
              </div>
            </div>
            <AbTestStatusChip endedAt={abTest.endedAt} hasDraft={hasDraft} />
          </div>

          <TableContainer className="form-details-container">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Text className="label" variant="caption" as="p">
                      Giving Form
                    </Text>
                  </TableCell>
                  <TableCell align="right">
                    <Text variant="h4" as="p">
                      {abTest.givingForm?.name}
                    </Text>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text className="label" variant="caption" as="p">
                      Campaign
                    </Text>
                  </TableCell>
                  <TableCell align="right">
                    <Text variant="h4" as="p">
                      {abTest.campaign?.name}
                    </Text>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table>
              <TableBody>
                {renderConversionRate}
                {renderAverageDonationAmount}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {showDiscardAbTestDraftDialog && (
        <DiscardAbTestDraftDialog
          isOpen={showDiscardAbTestDraftDialog}
          onClose={() => setShowDiscardAbTestDraftDialog(false)}
          onDiscardDraftConfirmClick={() => onDiscardDraftConfirmClick()}
        />
      )}
    </>
  );
};
