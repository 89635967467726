import { FormDataType } from 'hooks/useConfigContext';

const setItem = (id: string, value: string) =>
  window.localStorage.setItem(id, value);
const getItem = (id: string) => window.localStorage.getItem(id);
const removeItem = (id: string) => window.localStorage.removeItem(id);

const updateEdits = async <T>(
  type: string,
  id: string,
  edits: T,
  seedConfigPublishedAt: Date,
  name: string,
  receiptId: string,
  isPublished: boolean,
  userId = 'janedoe' // TODO: Placeholder until we have a place to get the user's id
): Promise<Date> => {
  const editsObject = {
    editsSavedTime: new Date(),
    edits,
    seedConfigPublishedAt,
    name,
    receiptId,
    isPublished
  };

  setItem(`edits-${type}-${userId}-${id}`, JSON.stringify(editsObject));
  return editsObject.editsSavedTime;
};

const getEditsById = async <T>(
  type: string,
  id: string,
  userId = 'janedoe' // TODO: Placeholder until we have a place to get the user's id
): Promise<FormDataType<T> | null> => {
  const editsObjectString = getItem(`edits-${type}-${userId}-${id}`);

  if (editsObjectString) {
    let {
      edits,
      editsSavedTime,
      seedConfigPublishedAt,
      name,
      receiptId,
      isPublished
    } = JSON.parse(editsObjectString);
    edits = edits as T;
    editsSavedTime = new Date(editsSavedTime);
    name = name as string;
    seedConfigPublishedAt = new Date(seedConfigPublishedAt);
    receiptId = receiptId as string;
    isPublished = isPublished as boolean;
    return {
      config: edits,
      editsSavedTime,
      seedConfigPublishedAt,
      name,
      receiptId,
      isPublished
    };
  }

  return null;
};

// TODO:  userId is placeholder until we have a place to get the user's id
const getAllEdits = async (type: string, userId = 'janedoe') => {
  const searchString = `edits-${type}-${userId}`;
  // find all items in localStorage whose keys begin with the searchString pattern
  // and extract the keys and values into an array
  const localStorageEdits = Object.entries(window.localStorage).filter(
    ([key]: [string, string]) => key.match(searchString)
  );
  // return an array of just the IDs and names from each localStorage entry
  return localStorageEdits.map(([key, value]) => {
    const { name, editsSavedTime } = JSON.parse(value);
    return {
      id: key.split(`${userId}-`).pop(),
      name,
      editsSavedTime
    };
  });
};

const discardEdits = async (
  type: string,
  id: string,
  userId = 'janedoe' // TODO: Placeholder until we have a place to get the user's id
) => {
  removeItem(`edits-${type}-${userId}-${id}`);
};

const editsRepositoryApi = {
  updateEdits,
  getEditsById,
  getAllEdits,
  discardEdits
};

export default editsRepositoryApi;
