import { MultiselectOption } from 'components/Select/MultiselectCheckbox';
import { ITributeBlock } from 'types';

export type TributeDataCollectionField =
  | 'isEmailEnabled'
  | 'isTitleEnabled'
  | 'isAddressEnabled'
  | 'isTributeMessageEnabled';

export const useTributeDataCollection = () => {
  const collectionFields: TributeDataCollectionField[] = [
    'isEmailEnabled',
    'isTitleEnabled',
    'isAddressEnabled',
    'isTributeMessageEnabled'
  ];

  const composeTributeDataCollection = (tributeBlock: ITributeBlock) => {
    const value: TributeDataCollectionField[] = [];

    if (tributeBlock) {
      collectionFields.forEach((x) => {
        if (tributeBlock[x]) {
          value.push(x);
        }
      });
    }

    return value;
  };

  const populateFromTributeDataCollection = (
    tributeBlock: ITributeBlock,
    tributeDataCollection: TributeDataCollectionField[]
  ): ITributeBlock => {
    const value = { ...tributeBlock };
    collectionFields.forEach((x) => {
      value[x] = tributeDataCollection.indexOf(x) > -1;
    });

    return value;
  };

  const tributeDataCollectionFieldLabels: {
    [key in TributeDataCollectionField]: string;
  } = {
    isEmailEnabled: "Tribute's Email",
    isTitleEnabled: "Tribute's Name Prefix",
    isAddressEnabled: "Tribute's Address",
    isTributeMessageEnabled: 'Tribute Message'
  };

  const tributeDataCollectionOptions: MultiselectOption[] = Object.keys(
    tributeDataCollectionFieldLabels
  ).map((key) => ({
    value: key,
    label:
      tributeDataCollectionFieldLabels[
        key as keyof typeof tributeDataCollectionFieldLabels
      ]
  }));

  return {
    composeTributeDataCollection,
    populateFromTributeDataCollection,
    tributeDataCollectionOptions
  };
};
