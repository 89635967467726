import { MutableRefObject, useEffect, useState } from 'react';
import { EditorEventTypes, EventHub } from 'services';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericConfigWithBlocks = { config: { blocks: any[] } };

type useEventHubPreviewProps<T extends GenericConfigWithBlocks> = {
  iframeRef: MutableRefObject<HTMLIFrameElement>;
  iframeReadyCallback: (iframe: HTMLIFrameElement) => EventHub;
  configData: T;
};

/**
 * This hook is used when we have an iframe preview (e.g. email and giving form) that must be
 * fully loaded and initialized before we can start sending/receving EventHub events.
 *
 * The useEffect updates based on the configData so that we can immediately send data at the appropriate time.
 * Types setup to expect the shape of T to have `configData.config.blocks`
 */
export const useEventHubPreview = <T extends GenericConfigWithBlocks>({
  iframeRef,
  iframeReadyCallback,
  configData
}: useEventHubPreviewProps<T>) => {
  const [iframeReady, setIframeReady] = useState<boolean>(false);

  useEffect(() => {
    if (iframeReady) {
      return;
    }
    const iframeReadyListener = (message: MessageEvent) => {
      if (message.data.name !== EditorEventTypes.PageReady) {
        return;
      }

      const eventHubRef = iframeReadyCallback(iframeRef.current);
      setIframeReady(true);
      window.removeEventListener('message', iframeReadyListener);

      if (configData.config.blocks) {
        // Before data is loaded, config can be an empty object.  Using presence of `blocks` to determine if real config
        // if we have the config already, can emit right away.  Otherwise if delay getting data, emit will happen elsewhere

        // The intial load of the config happens in this listener.
        eventHubRef.emit(
          EditorEventTypes.ConfigurationUpdate,
          configData.config
        );
      }
    };

    window.addEventListener('message', iframeReadyListener);
    // Destructor incase we need cleanup before it happens inside iframeReadyListener handler:
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('message', iframeReadyListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData]);
};
