import { useMemo } from 'react';
import { TableCell } from '@mui/material';
import { isDate } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export interface DateCellProps {
  value: string;
}

export const DateCell = ({ value }: DateCellProps) => {
  const formattedValue = useMemo(() => {
    if (!value) {
      return '';
    }

    const castToDate = new Date(value);
    if (!isDate(castToDate)) {
      return '-';
    }

    return formatInTimeZone(castToDate, 'GMT', 'MM/dd/yyyy');
  }, [value]);

  return <TableCell>{formattedValue}</TableCell>;
};
