import { useRef, useState } from 'react';
import { Box, Popover, Stack } from '@mui/material';
import ColorMenu from 'components/ColorMenu';
import Text from 'components/Text';
import './ColorPicker.scss';

type ColorPickerProps = {
  startingColor: string;
  onChange?: (newColor: string) => void;
};

const ColorPicker = ({ startingColor, onChange }: ColorPickerProps) => {
  const [showPicker, setShowPicker] = useState(false);
  const [currColor, setCurrColor] = useState<string>(startingColor);
  const colorPickerRef = useRef<HTMLDivElement>(null);

  const rgbToHex = (rgb: string) =>
    rgb.length === 7
      ? rgb.substring(1).toUpperCase()
      : rgb
          ?.match?.(/[0-9|.]+/g)
          ?.map((x, i) =>
            i === 3
              ? (255 * parseInt(x, 10)).toString(16)
              : parseInt(x, 10).toString(16)
          )
          ?.map((x) => (Number(x) < 10 ? `0${x}` : x))
          .join('')
          .toUpperCase()
          .substring(0, 6);

  return (
    <Stack
      className="ColorPickerRow"
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <Box
        className="ColorPicker-SelectedColorIndicator"
        ref={colorPickerRef}
        onClick={() => setShowPicker((prev) => !prev)}
        style={{
          backgroundColor: currColor
        }}
      />
      <Popover
        id="color-picker-popover"
        open={showPicker}
        onClose={() => setShowPicker(false)}
        anchorEl={colorPickerRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <ColorMenu
          defaultColor={currColor}
          onSelect={(color) => {
            setCurrColor(color);
            onChange?.(color);
            setShowPicker(false);
          }}
        />
      </Popover>
      <Text className="ColorPicker-SelectedColorLabel" variant="h4">
        {rgbToHex(currColor)}
      </Text>
    </Stack>
  );
};

export default ColorPicker;
