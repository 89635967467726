import { Box, Grid } from '@mui/material';
import Text from 'components/Text';
import './ChannelPreviewGrid.scss';

export type ChannelPreviewGridProps = {
  title: string;
  children: React.ReactNode;
};

export const ChannelPreviewGrid = ({
  title,
  children
}: ChannelPreviewGridProps) => (
  <Box className="channels-container">
    <Text variant="h3">{title}</Text>
    <Grid className="channels-grid" container>
      {children}
    </Grid>
  </Box>
);
