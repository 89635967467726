import { Auth0ClientOptions } from '@auth0/auth0-spa-js';

export type EnvironmentVariables = {
  auth0?: Auth0ClientOptions;
  baseUrl?: string;
  apiPrefix?: string;
  appsBaseUrl?: string;
  gmsBaseUrl?: string;
  eventsBaseUrl?: string;
  p2pBaseUrl?: string;
  gmsApiBaseUrl?: string;
  eventsPublicBaseUrl?: string;
  p2pPublicBaseUrl?: string;
  reconciliationUiBaseUrl?: string;
  s3Url?: string;
  sentry?: {
    dsn?: string;
    environment?: string;
    tracesSampleRate?: number;
  };
  joditProLicense?: string;
};

declare global {
  interface Window {
    envVars: EnvironmentVariables;
    pendo: any;
  }
}

export const resolvedEnvironment: EnvironmentVariables = window.envVars;
