import { useState } from 'react';
import { Skeleton } from '@mui/material';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import Text from 'components/Text';
import { AbTest, GivingFormConfigVariant } from 'types';
import { GivingFormPreviewCard } from '../ChannelPreviewCards';
import './EndAbTestModal.scss';

interface EndAbTestModalProps {
  abTest: AbTest;
  onCancel: () => void;
  onEndTest: (variant: GivingFormConfigVariant) => void;
  returnButtonText: string;
}

export const EndAbTestModal = ({
  abTest,
  onCancel,
  onEndTest,
  returnButtonText
}: EndAbTestModalProps): JSX.Element => {
  const [selectedVariant, setSelectedVariant] =
    useState<GivingFormConfigVariant>();

  const onCloseDialog = () => {
    setSelectedVariant(null);
    onCancel();
  };

  return (
    <Dialog
      className="end-ab-test-dialog"
      label="end ab test dialog"
      open
      onClose={() => {
        onCloseDialog();
      }}
    >
      <DialogTitle
        onClick={() => {
          onCloseDialog();
        }}
      >
        End Test
      </DialogTitle>
      <DialogContent>
        <DialogContentTitle>
          Select the form you would like to use.
        </DialogContentTitle>
        <Text variant="body">
          In order to end your A/B test, please choose which form you would like
          to use going forward.
        </Text>
        <div className="variant-container">
          {abTest?.abTestId ? (
            <>
              <GivingFormPreviewCard
                className="end-ab-test-card"
                givingFormName="Form A"
                givingFormId={abTest?.givingForm.id}
                createdDate={abTest?.createdAt}
                amountRaised={abTest?.metrics?.variantA?.amountRaised}
                conversionRate={abTest?.metrics?.variantA?.conversionRate}
                donationCount={abTest?.metrics?.variantA?.donationCount}
                visits={abTest?.metrics?.variantA?.visits}
                unpublishedChanges={false}
                parentComp="endAbTest"
                testVariantSelected={
                  selectedVariant === GivingFormConfigVariant.VariantA
                }
                onChooseFormClick={() => {
                  setSelectedVariant(GivingFormConfigVariant.VariantA);
                }}
              />
              <GivingFormPreviewCard
                className="end-ab-test-card"
                givingFormName="Form B"
                givingFormId={abTest?.givingForm.id}
                createdDate={abTest?.createdAt}
                amountRaised={abTest?.metrics?.variantB?.amountRaised}
                conversionRate={abTest?.metrics?.variantB?.conversionRate}
                donationCount={abTest?.metrics?.variantB?.donationCount}
                visits={abTest?.metrics?.variantB?.visits}
                unpublishedChanges={false}
                parentComp="endAbTest"
                testVariantSelected={
                  selectedVariant === GivingFormConfigVariant.VariantB
                }
                onChooseFormClick={() => {
                  setSelectedVariant(GivingFormConfigVariant.VariantB);
                }}
                abTestDelta={abTest?.metrics?.delta}
              />
            </>
          ) : (
            <>
              <Skeleton className="form-preview-skeleton" animation="wave" />
              <Skeleton className="form-preview-skeleton" animation="wave" />
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          variant="primary"
          onClick={() => {
            onEndTest(selectedVariant);
          }}
          disabled={!selectedVariant}
        >
          End Test & Launch Form
        </DialogActionButton>
        <DialogActionButton
          variant="secondary"
          onClick={() => {
            onCloseDialog();
          }}
        >
          {returnButtonText}
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
