import { AxiosError } from 'axios';
import axios from 'services/axiosClient';
import { EmailType } from 'types';
import {
  CreateEmailByTypeRequest,
  Email,
  GetAllEmailTemplatesRequest,
  GetAllEmailsRequest,
  GetEmailByIdRequest,
  GetEmailTemplatesRequest,
  UniqueEmailNameRequestDto,
  UpdateEmail
} from './types/emailTypes';

export const getAllEmailsService = async ({
  organizationId,
  type
}: GetAllEmailsRequest): Promise<Email[]> => {
  try {
    const queryParams = `/?organizationId=${organizationId}&type=${type}`;

    const { data } = await axios.get(`/emails${queryParams}`);
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        `There was an error fetching your ${type}s`
    );
  }
};

export const getEmailByIdService = async ({
  organizationId,
  emailId
}: GetEmailByIdRequest): Promise<Email> => {
  try {
    const { data } = await axios.get(
      `/emails/${emailId}?organizationId=${organizationId}`
    );
    data.updatedAt = new Date(data.updatedAt);
    data.createdAt = new Date(data.createdAt);
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your emails.'
    );
  }
};

export const validateEmailNameService = async ({
  organizationId,
  type,
  name,
  emailId
}: UniqueEmailNameRequestDto): Promise<boolean> => {
  try {
    const { data } = await axios.post('/emails/nameCheck', {
      organizationId,
      type,
      name,
      emailId
    });
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your email.'
    );
  }
};

export const getEmailTemplatesService = async ({
  organizationId,
  type,
  includeGlobals = false
}: GetAllEmailTemplatesRequest): Promise<Email[]> => {
  try {
    const { data } = await axios.get(`/emails/templates`, {
      params: { organizationId, type, includeGlobals }
    });
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error retrieving your email templates'
    );
  }
};

export const createEmailByTypeService = async ({
  organizationId,
  type,
  templateId,
  name
}: CreateEmailByTypeRequest): Promise<Email> => {
  try {
    const { data: newEmail } = await axios.post('/emails', {
      organizationId,
      type,
      templateId,
      name
    });
    return newEmail;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || `Error creating your ${type}`
    );
  }
};

export const updateEmailService = async ({
  emailId,
  name,
  config,
  organizationId
}: UpdateEmail) => {
  try {
    const { data } = await axios.patch(`/emails/${emailId}`, {
      name,
      config,
      organizationId,
      type: EmailType.Receipt
    });

    data.updatedAt = new Date(data.updatedAt);
    data.createdAt = new Date(data.createdAt);
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Unable to update email'
    );
  }
};

export const markEmailAsDefaultService = async ({
  organizationId,
  emailId
}: {
  organizationId: string;
  emailId: string;
}) => {
  const { data } = await axios.post(`/emails/markAsDefault`, {
    organizationId,
    emailId
  });

  return data;
};
