import { FormDataType } from 'hooks/useConfigContext';
import { EmailConfig } from 'types/emailTypes';

export const isValidPlaceholder = (
  string: string,
  validTags: string[]
): boolean => validTags.includes(string);

export const addValidityClass = (tag: Element, isValid: boolean): void => {
  const [classToAdd, classToRemove] = isValid
    ? ['jodit-valid-tag', 'jodit-invalid-tag']
    : ['jodit-invalid-tag', 'jodit-valid-tag'];

  if (tag.classList.contains(classToRemove)) {
    tag.classList.remove(classToRemove);
  }

  if (!tag.classList.contains(classToAdd)) {
    tag.classList.add(classToAdd);
  }
};

export const checkForTags = (
  htmlString: string,
  validTags: string[]
): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Find all instances of pattern
  const allTags = doc.body.getElementsByTagName('*');
  const regex = /{{[A-Z a-z*]+}}/gm;
  const tagsWithPattern = Array.from(allTags).filter((node) =>
    node.textContent.match(regex)
  );
  // Check if they are wrapped in a span, if not wrap them in a span
  tagsWithPattern.forEach((tag) => {
    if (tag.textContent.trim().match(/^{{[A-Z a-z]+}}$/g)) {
      // if they are already wrapped in a span...
      const isValid = isValidPlaceholder(tag.textContent.trim(), validTags);
      addValidityClass(tag, isValid); // ...update their validity class
    } else {
      // if they contain instance(s) of a potential tag
      const allChunks: (Element | string | ChildNode)[] = [];
      tag.childNodes.forEach((node) => {
        if (node.nodeName === '#text') {
          // we parse through each the text node and split it into chunks. e.g. my name is {{First Name}} and my last name is {{Last Name}}, cool huh?
          // turns into nodes for
          // [my name is, {{First Name}}, and my last name is, {{Last Name}}, cool huh?];
          const chunks = [];
          let lastIndex = 0;
          let chunk = regex.exec(node.textContent);
          while (chunk) {
            const [match] = chunk;

            chunks.push(node.textContent.substring(lastIndex, chunk.index));

            const isValid = isValidPlaceholder(match, validTags);

            const className = isValid ? 'jodit-valid-tag' : 'jodit-invalid-tag';

            const span = doc.createElement('span');
            span.classList.add(className);
            span.textContent = match;

            chunks.push(span);

            lastIndex = chunk.index + match.length;
            chunk = regex.exec(node.textContent);
          }
          chunks.push(node.textContent.substring(lastIndex));
          allChunks.push(...chunks);
        } else {
          allChunks.push(node);
        }
      });
      // eslint-disable-next-line no-param-reassign
      tag.innerHTML = '';
      tag.append(...allChunks);
    }
  });

  return doc.body.innerHTML;
};

export const isValidWYSIWYGHTML = (html: string) =>
  !html.includes('jodit-invalid-tag');

export const validateWYSIWYGHTML = (configData: any) => {
  const bodyIndex = configData.config.thankYouConfig.blocks.findIndex(
    (block: any) => block.blockType === 'ThankYouMessageBodyBlock'
  );
  if (bodyIndex >= 0) {
    return isValidWYSIWYGHTML(
      configData.config.thankYouConfig.blocks[bodyIndex].html
    );
  }
  return true;
};

export const validateHeader = (configData: any) => {
  if (
    configData.config.thankYouConfig.blocks[0].blockType ===
    'ThankYouHeaderBlock'
  ) {
    return isValidWYSIWYGHTML(configData.config.thankYouConfig.blocks[0].html);
  }
  return true;
};

export const validateEmailHeader = (configData: FormDataType<EmailConfig>) =>
  !configData.config.header.html.includes('jodit-invalid-tag');
