import {
  HostedPageBlockBaseType,
  HostedPageSections,
  IGivingFormConfig,
  IHostedPageConfig
} from 'types';

type IHostedPageBlockData = {
  block: HostedPageBlockBaseType;
  section: HostedPageSections;
  index: number;
};
// Because the HP blocks are split into 3 arrays, we may need additional context info at times
// To support different use cases, this util function will return the block, section and the index within that array
// If no block is found, the result will be null
export const getHostedPageBlockDataById = (
  blockId: string,
  gfConfig: IGivingFormConfig
): IHostedPageBlockData | null => {
  const { topBlocks, sideBlocks, bottomBlocks } = gfConfig.hostedPageConfig;
  const blockArrays = [topBlocks, sideBlocks, bottomBlocks];
  let blockData: IHostedPageBlockData = null;

  blockArrays.forEach((blocks, index) => {
    // If we already found it, no need to check the other arrays
    if (blockData) {
      return;
    }

    let section: HostedPageSections;
    switch (index) {
      case 0:
        section = HostedPageSections.top;
        break;
      case 1:
        section = HostedPageSections.side;
        break;
      case 2:
      default:
        section = HostedPageSections.bottom;
        break;
    }

    blocks.find((block, blockIndex) => {
      if (block.id === blockId) {
        blockData = {
          block,
          section,
          index: blockIndex
        };
        return true;
      }
      return false;
    });
  });

  return blockData;
};

// Given a block id, this will delete it from the config
// For simplicity, this attempts to delete the first matching block it finds in each array.
export const deleteHostedPageBlockFromConfigById = (
  blockId: string,
  gfConfig: IGivingFormConfig
): IGivingFormConfig => {
  const newConfig = gfConfig;
  const { topBlocks, sideBlocks, bottomBlocks } = newConfig.hostedPageConfig;

  const blockArrays = [topBlocks, sideBlocks, bottomBlocks];

  blockArrays.forEach((blockArray) => {
    const index = blockArray.findIndex((block) => block.id === blockId);
    if (index !== -1) {
      blockArray.splice(index, 1);
    }
  });

  return newConfig;
};

// The pattern for adding a new block is:
// 1. Consider from the bottom up (bottom->side->top)
// 2. Append it to the first section that is not empty
// If layout is FullWidth, then the side section array should always be empty so it is handled implicitly
// For any special handling of adding new blocks to a specific area, e.g. header or footer, that should be handled manually without using this util
export const addBlockToHostedPageConfig = (
  block: HostedPageBlockBaseType,
  gfConfig: IGivingFormConfig
): IGivingFormConfig => {
  const newConfig = gfConfig;
  const { topBlocks, sideBlocks, bottomBlocks } = newConfig.hostedPageConfig;

  if (bottomBlocks.length > 0) {
    bottomBlocks.push(block);
  } else if (sideBlocks.length > 0) {
    sideBlocks.push(block);
  } else {
    topBlocks.push(block);
  }

  return newConfig;
};
