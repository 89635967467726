import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Blocker } from 'components';
import { BreadcrumbsProps } from 'components/Breadcrumbs';
import { ButtonProps } from 'components/Button';
import EmailEditor from 'components/EmailEditor';
import { MenuItemProps } from 'components/Menu';
import { EditableTextProps } from 'components/Text';
import { validateEmailHeader } from 'components/WYSIWYG/WYSIWYGUtils';
import { DeleteModalConfig } from 'components/gms/DeleteBlockModal/DeleteBlockModal';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import { PublishWarningDialog } from 'components/gms/PublishWarningDialog';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import {
  ConfigContextProvider,
  useConfigContext
} from 'hooks/useConfigContext';
import { IframeContextProvider } from 'hooks/useEditorIframeContext';
import {
  useEmailById,
  useUpdateEmail,
  useValidateEmailName
} from 'queries/UseEmails';
import { GivingFormModes } from 'services';
import { EmailConfig, EmailType } from 'types/emailTypes';

type LocationState = {
  isFromGivingFormEditor: boolean;
  isFromReceiptManager: boolean;
  givingFormId: string;
  givingFormName: string;
  campaignId: string;
  campaignName: string;
};

const EditEmail = ({ type }: { type: EmailType }): JSX.Element => {
  const { emailId } = useParams();
  const navigate = useNavigate();
  const [addAlert] = useAlerts();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location: Record<string, any> = useLocation();
  const {
    isLoading: isLoadingEmail,
    data: backendEmailReference,
    refetch: getEmail
  } = useEmailById({ emailId, type });

  const [initialEmailLoaded, setInitialEmailLoaded] = useState(false);

  const { configData, updateName, handleSetForm, discardEdits } =
    useConfigContext<EmailConfig>();

  const { selectedOrganization, envConfig } = useAppContext();
  const iframeSrc = `${envConfig?.appsBaseUrl}/email-preview`;
  const { mutateAsync: checkUniqueName } = useValidateEmailName({
    organizationId: selectedOrganization.id,
    type: EmailType.Receipt,
    name: configData.name,
    emailId
  });

  const [showSaveWarningDialog, setShowSaveWarningDialog] = useState(false);
  const [nameIsDuplicate, setNameIsDuplicate] = useState(false);
  const [checkNameGuard, setCheckNameGuard] = useState<boolean>(false);
  const [deleteModalConfig, setDeleteModalConfig] = useState<DeleteModalConfig>(
    {
      open: false
    }
  );

  // This useEffect is only for handling the initial load.  Any follow-up updates require checking values before overwriting data
  useEffect(() => {
    if (!isLoadingEmail && !initialEmailLoaded) {
      setInitialEmailLoaded(true);
      handleSetForm({
        ...backendEmailReference,
        seedConfigPublishedAt: backendEmailReference.updatedAt
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingEmail]);

  useEffect(() => {
    // First guard is to prevent an API call before the name is even available
    if (!initialEmailLoaded) {
      return;
    }
    // Second guard is to prevent an API call when the giving form loads.  Any changes after that will fire the check
    // TODO: not sure this is necessary for emails (haven't throughly thought it out)
    if (!checkNameGuard) {
      setCheckNameGuard(true);
      return;
    }

    const checkIfUnique = async () => {
      const isUniqueName = await checkUniqueName();
      if (!isUniqueName) {
        setNameIsDuplicate(true);
      } else {
        setNameIsDuplicate(false);
      }
    };

    checkIfUnique();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData.name]);

  const { mutateAsync: saveEmail } = useUpdateEmail({
    emailId,
    name: configData.name,
    config: configData.config,
    organizationId: selectedOrganization.id
  });

  const handleSaveEmail = async () => {
    try {
      const isUniqueName = await checkUniqueName();
      if (!isUniqueName) {
        throw new Error('Name must be unique');
      }
      const data = await saveEmail();
      discardEdits();

      addAlert({ title: 'Your changes were successfully saved.' });

      handleSetForm({
        ...data,
        seedConfigPublishedAt: data.updatedAt
      });

      if (location?.state?.isFromGivingFormEditor) {
        navigate(
          `/campaigns/${location?.state?.campaignId}/giving-forms/${location?.state?.givingFormId}/edit`,
          {
            state: {
              emailId,
              initialTab: 'Receipt'
            }
          }
        );
      } else if (location?.state?.isFromGivingFormTemplateEditor) {
        navigate(
          `/giving-form-templates/${location?.state?.givingFormId}/edit`,
          {
            state: {
              emailId,
              initialTab: 'Receipt'
            }
          }
        );
      } else if (location?.state?.isFromReceiptManager) {
        navigate(`/receipts`);
      }
    } catch (error) {
      let message = 'Unable to process.'; // Fallback error message incase something unexpected threw a non-base Error
      if (error instanceof Error) {
        message = error.message;
      }
      addAlert({
        title: 'Error Saving Email',
        description: message,
        severity: 'error'
      });
    }
  };

  const handleSaveButtonClick = async () => {
    try {
      const { data: currentBackendEmail } = await getEmail();

      const isValidateEmailHeader = validateEmailHeader(configData);

      if (!isValidateEmailHeader) {
        addAlert({
          severity: 'error',
          title: 'Invalid Tag',
          description:
            'Could not publish the email because your header message contains an invalid tag.'
        });
      } else if (
        currentBackendEmail?.updatedAt > configData.seedConfigPublishedAt
      ) {
        setShowSaveWarningDialog(true);
      } else if (!configData.config.subjectLine) {
        addAlert({
          severity: 'error',
          title: 'No Subject Line',
          description: 'Please enter a subject line under Email Details.'
        });
      } else {
        await handleSaveEmail();
      }
    } catch (error) {
      let message = 'Unable to process.'; // Fallback error message incase something unexpected threw a non-base Error
      if (error instanceof Error) {
        message = error.message;
      }
      addAlert({
        title: 'Error Publishing Form',
        description: message,
        severity: 'error'
      });
    }
  };

  const titleProps: EditableTextProps = {
    value: configData.name,
    textFieldProps: {
      onBlur: async (e) => {
        const name = e.target.value;
        if (name === '') {
          return;
        }

        updateName(name);
      },
      error: nameIsDuplicate
    }
  };

  let breadcrumbs;
  if (location.state) {
    const {
      campaignId,
      campaignName,
      givingFormId,
      givingFormName,
      isFromReceiptManager
    } = location.state as LocationState;

    if (isFromReceiptManager) {
      breadcrumbs = [
        {
          label: 'Manage Receipts',
          path: '/receipts'
        }
      ];
    } else {
      breadcrumbs = [
        {
          label: 'All Campaigns',
          path: '/campaigns'
        },
        {
          label: campaignName || 'Campaign Home',
          path: `/campaigns/${campaignId}`
        },
        {
          label: givingFormName || 'Giving Form Overview',
          path: `/campaigns/${campaignId}/giving-forms/${givingFormId}`
        },
        {
          label: 'Giving Form Builder',
          path: `/campaigns/${campaignId}/giving-forms/${givingFormId}/edit`,
          state: { initialTab: 'GivingForm' }
        },
        {
          label: 'Receipt Builder',
          path: '/'
        }
      ];
    }
  } else {
    breadcrumbs = [
      {
        label: 'Dashboard',
        path: '/'
      }
    ];
  }

  const breadcrumbsProps: BreadcrumbsProps = {
    breadcrumbs
  };

  const primaryButtonProps: ButtonProps = {
    children: location?.state?.isFromGivingFormEditor
      ? 'Save & Return'
      : 'Save',
    onClick: handleSaveButtonClick,
    disabled: !configData.editsSavedTime
  };

  const featureMenuItems: MenuItemProps[] = [];

  return (
    <>
      <Blocker block={isLoadingEmail}>
        <FeatureHeader
          titleProps={titleProps}
          breadcrumbsProps={breadcrumbsProps}
          primaryButtonProps={primaryButtonProps}
          featureMenuItems={featureMenuItems}
          isLoaded={initialEmailLoaded}
          activeTab="Placeholder"
        />
        <IframeContextProvider
          iframeSrc={iframeSrc}
          setDeleteModalConfig={setDeleteModalConfig}
          mode={GivingFormModes.EDIT}
        >
          <EmailEditor
            type={type}
            iframeSrc={iframeSrc}
            deleteModalConfig={deleteModalConfig}
            setDeleteModalConfig={setDeleteModalConfig}
          />
        </IframeContextProvider>
      </Blocker>
      <PublishWarningDialog
        open={showSaveWarningDialog}
        onConfirm={() => handleSaveEmail()}
        onClose={() => setShowSaveWarningDialog(false)}
        onDiscard={async () => {
          discardEdits();
          handleSetForm({
            ...backendEmailReference,
            seedConfigPublishedAt: backendEmailReference.updatedAt
          });
        }}
      />
    </>
  );
};

export default ({ type }: { type: EmailType }) => {
  const { emailId } = useParams();
  return (
    <ConfigContextProvider<EmailConfig> id={emailId} type="email" key={emailId}>
      <EditEmail type={type} />
    </ConfigContextProvider>
  );
};
