import { ChangeEvent, useEffect, useState } from 'react';
import {
  MAXIMUM_FILE_SIZE,
  MAXIMUM_FILE_SIZE_EXCEEDED_MSG
} from 'constants/givingFormContants';
import { Blocker, ICONS } from 'components';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useImageDelete, useImageUpload } from 'queries/UseImages';

const imagePrefix = 'designations';

export interface AdvDesImageManagerProps {
  image: string;
  setImage: (images: string) => void;
  setFieldToTouched: () => void;
}

export const AdvancedDesignationsImageManager = ({
  image,
  setImage,
  setFieldToTouched
}: AdvDesImageManagerProps): JSX.Element => {
  const [imageDataUrl, setImageDataUrl] = useState<string>(null);
  const [imageId, setImageId] = useState<string>(null);
  const [addAlert] = useAlerts();
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();

  useEffect(() => {
    if (image) {
      // Parse guid from URL
      const end = image.split(`${imagePrefix}-`)[1];
      const parsedImageId = end.split('.')[0];
      setImageId(parsedImageId);
    }
  }, [image]);

  const { mutateAsync: saveImageAsync, isLoading: uploadIsLoading } =
    useImageUpload(
      {
        organizationId,
        imageDataUrl,
        imagePrefix
      },
      {
        onSuccess: () => setFieldToTouched()
      }
    );

  const { mutateAsync: deleteImageAsync, isLoading: deleteIsLoading } =
    useImageDelete(
      {
        organizationId,
        prefix: imagePrefix,
        imageId
      },
      {
        onSuccess: () => setFieldToTouched()
      }
    );

  const handlePreventDefault = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const readFile = (file: File) =>
    new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => resolve(reader.result as string),
        false
      );
      reader.readAsDataURL(file);
    });

  const saveImage = async () => {
    // Save image to CDN
    try {
      const result = await saveImageAsync();

      // Push image into state
      setImage(result);
    } catch (err) {
      addAlert({
        title: 'There was an error uploading your image.',
        severity: 'error'
      });
    }
  };

  const handleFileUpload = async (file?: File) => {
    try {
      if (file) {
        if (file.size > MAXIMUM_FILE_SIZE) {
          addAlert({
            title: MAXIMUM_FILE_SIZE_EXCEEDED_MSG,
            severity: 'error'
          });
          return;
        }

        const imageDataUrlString = await readFile(file);
        setImageDataUrl(imageDataUrlString);
        saveImage();
      }
    } catch (err) {
      addAlert({
        title: 'There was an error loading your image.',
        severity: 'error'
      });
    }
  };

  const onDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileUpload(e.dataTransfer.files[0]);
  };

  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    handleFileUpload(e.target.files?.[0]);
  };

  const deleteImage = async () => {
    // Delete image from CDN
    try {
      await deleteImageAsync();
      setImage(null);
    } catch (err) {
      addAlert({
        title: 'There was an error deleting your image.',
        severity: 'error'
      });
    }
  };

  return (
    <Blocker
      className="designations-image-blocker"
      block={uploadIsLoading || deleteIsLoading}
    >
      {image ? (
        <div className="designations-image">
          <div className="designations-image-wrapper">
            <img src={image} alt="designation" />
            <IconButton
              className="delete"
              label="delete"
              variant="basic"
              size="small"
              icon={ICONS.TRASH}
              onClick={() => deleteImage()}
            />
          </div>
        </div>
      ) : (
        <div
          className="designations-image-upload-container"
          onDragEnter={handlePreventDefault}
          onDragLeave={handlePreventDefault}
          onDragOver={handlePreventDefault}
          onDrop={onDropFile}
        >
          <Text variant="h5"> Drag & Drop File </Text>
          <span className="or">or</span>
          <Button
            className="designations-upload-image-btn"
            /* eslint-disable-next-line */
            /* @ts-ignore */
            component="label"
          >
            Browse File
            <input
              hidden
              type="file"
              onChange={onFileSelected}
              accept="image/*"
            />
          </Button>
        </div>
      )}
    </Blocker>
  );
};
