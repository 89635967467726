import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogTitle
} from 'components/Dialog';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useUpdateSmsForm } from 'queries/UseGivingForms';
import './SmsFormSelectModal.scss';

type SmsFormSelectModalProps = {
  open: boolean;
  onClose: () => void;
};

export const SmsFormSelectModal = ({
  open,
  onClose
}: SmsFormSelectModalProps): JSX.Element => {
  const [addAlert] = useAlerts();
  const { givingFormId } = useParams();
  const { selectedOrganization } = useAppContext();

  const { mutateAsync: updateSmsForm, isLoading } = useUpdateSmsForm();

  const onSubmit = async (close: () => void) => {
    try {
      await updateSmsForm({
        organizationId: selectedOrganization.id,
        smsFormId: givingFormId
      });
    } catch (e) {
      addAlert({
        title:
          "Uh oh. Looks like there was an error updating the organization's SMS giving form.",
        severity: 'error'
      });
    }
    close();
  };

  return (
    <Dialog
      className="sms-form-modal"
      open={open}
      label="SMS Form Modal"
      onClose={onClose}
    >
      <DialogTitle>Use This Form For SMS</DialogTitle>
      <DialogContent>
        <DialogContentDescription>
          Use this form when processing SMS transactions for your organization?
          This will deselect any previously selected giving forms. This change
          will automatically be published.
        </DialogContentDescription>
      </DialogContent>
      <DialogActions>
        <DialogActionButton variant="primary" onClick={onSubmit}>
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Choose'
          )}
        </DialogActionButton>
        <DialogActionButton variant="secondary" onClick={onClose}>
          Cancel
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
