import React, { ForwardedRef, forwardRef, useEffect } from 'react';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import TextField, { useLimitedLength } from '.';

export type LimitedTextFieldProps = {
  /**
   * The max number of characters allowed in the TextField value
   */
  maxChar: number;
  /**
   * An optional starting value for the TextField
   */
  value?: string;
  /**
   * Callback function to aid in overwriting the default helperText.
   * Is provided the current length and max length of the input's value,
   * and uses the return value as the new helper text
   */
  formatHelperText?: (length: number, maxLength: number) => string;
} & MuiTextFieldProps;

/**
 * Higher Level TextField component that takes use of the `useLimitedLength` hook to provide
 * an input field that enforces a given maxLength for it's value.
 */

const LimitedTextField = forwardRef(
  (
    {
      maxChar,
      formatHelperText,
      value,
      onChange,
      ...props
    }: LimitedTextFieldProps,
    ref: ForwardedRef<HTMLDivElement>
  ): JSX.Element => {
    const [limitedVal, setLimitedVal] = useLimitedLength(maxChar, value);

    useEffect(() => {
      if (value !== undefined) {
        // force an update to the value in the case it gets deleted
        setLimitedVal(value);
      }
    }, [setLimitedVal, value]);

    /**
     * Formatted helper text. Overriden if helperText is explicitly provided.
     */
    const computedHelperText =
      formatHelperText?.(limitedVal.length, maxChar) ??
      `${limitedVal.length}/${maxChar} characters`;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setLimitedVal(e.currentTarget.value);
      if (e.currentTarget.value.length <= maxChar) {
        onChange?.(e);
      }
    };

    return (
      <TextField
        inputRef={ref}
        value={limitedVal}
        helperText={computedHelperText}
        onChange={handleChange}
        {...props}
      />
    );
  }
);

export default LimitedTextField;
