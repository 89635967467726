import { AxiosError } from 'axios';
import axiosClient from './axiosClient';
import { UpdateP2P } from './types/p2pTypes';

export const archiveP2PService = async ({
  p2pId,
  name,
  slug,
  startDate,
  organizationId,
  isArchived
}: UpdateP2P) => {
  try {
    const { data } = await axiosClient.patch('/p2p/archive', {
      p2pId,
      name,
      slug,
      startDate,
      organizationId,
      isArchived
    });

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Unable to archive Peer-to-Peer'
    );
  }
};
