import { useState } from 'react';

/**
 * A hook to be able to manage the given length of a string/value. This hook is built in to the
 * <LimitedTextField /> component, but also exposed to allow any custom implementation
 *
 * @param length - The max length of a given range. Any attempt to change the value that would surpass this length is not allowed
 * @param defaultValue - The starting value for the tracked value. Defaults to '' if not specified
 * @returns {Array} - Array[0] is the value, array[1] is a setter function for the value.
 */

export const useLimitedLength = (
  length: number,
  defaultValue = ''
): readonly [string, (newValue: string) => void] => {
  const [value, setValue] = useState(defaultValue);

  /**
   * trimValue will only permit mutations that do not exceed the maxLength. Other than this it directly interfaces
   * with the native state setter function
   * @param newValue - The desired value to update to
   */
  const trimValue = (newValue: string) => {
    const computedValue =
      newValue.length <= length ? newValue : newValue.substring(0, length);
    setValue(computedValue);
  };

  /**
   * `as const` here updates the inferred type to be [boolean, typeof trimValue]
   * as opposed to (boolean | typeof trimValue)[]
   */
  return [value, trimValue] as const;
};
