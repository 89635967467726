import {
  BlockTypes,
  IGivingFormConfig,
  IRecurringOptionsBlock,
  RecurringOptionsType
} from 'types';

export const isFeatureEnabled = (
  config: IGivingFormConfig,
  blockType: BlockTypes
) => {
  const featureBlock = config.blocks.find(
    (block) => block.blockType === blockType
  );

  return featureBlock !== undefined;
};

export const isRecurringEnabled = (config: IGivingFormConfig) => {
  const recurringBlock = config.blocks.find(
    (block) => block.blockType === BlockTypes.RecurringOptions
  );

  if (!recurringBlock) {
    return false;
  }

  const recurringOptions = (
    recurringBlock as IRecurringOptionsBlock
  ).recurringOptions.filter((option) => option !== RecurringOptionsType.Once);

  return recurringOptions.length > 0;
};
