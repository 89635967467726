import {
  AlertTitle,
  Alert as MuiAlert,
  AlertProps as MuiAlertProps
} from '@mui/material';
import { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';

export type AlertProps = Omit<
  MuiAlertProps,
  'title' | 'elevation' | 'square'
> & {
  /**
   * The main content of the alert. If using a description in addition to the
   * main title, use `children`.
   */
  title: string;
  /**
   * The "description" text for the alert. Will be rendered underneath
   * the title.
   */
  children?: string;
};

type ActionProps = Pick<AlertProps, 'action' | 'onClose'>;

/**
 * This component handles the logic for rendering a custom action button
 * or an icon button.
 * @returns A custom action, or an X Icon Button, or nothing
 */
const Action = ({ action, onClose }: ActionProps): JSX.Element | null => {
  // if an action prop was provided, render the action
  if (action) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{action}</>;
  }

  // if no action prop was provided, but an onClose callback has been provided,
  // render an X icon button
  if (onClose) {
    return (
      <IconButton
        onClick={onClose}
        label="dismiss alert"
        tooltipLabel="Dismiss"
        icon={ICONS.X}
        size="small"
      />
    );
  }

  // otherwise don't render anything
  return null;
};

/**
 * Base level GMS Alert Component
 * More about Alert here - https://mui.com/components/alert/
 * @param props Any valid MUI Alert props are forwarded
 * @returns A GMS themed Alert component
 */
const Alert = ({
  title,
  children,
  onClose,
  action,
  ...props
}: AlertProps): JSX.Element => (
  <MuiAlert action={<Action action={action} onClose={onClose} />} {...props}>
    {title != null && <AlertTitle>{title}</AlertTitle>}
    {children != null && (
      <Text variant="h6Regular" sx={{ mt: 0.5 }}>
        {children}
      </Text>
    )}
  </MuiAlert>
);

export default Alert;
