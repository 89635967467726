import { Theme, createTheme, lighten } from '@mui/material';
import Icon, { ICONS } from 'components/Icon';

export const createAlertTheme = (currTheme: Theme): Theme => {
  const { typography, palette } = currTheme;
  const theme = createTheme(currTheme, {
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            padding: 14,
            borderRadius: '0.5rem'
          },

          // styles for the left icon
          icon: {
            padding: 0,

            // set fill/stroke of the containing circle to the current color
            '& circle': {
              fill: 'currentColor',
              stroke: 'currentColor'
            },

            // change actual icon within color to white
            // e.g. the actual exclamation point or 'i' info symbol
            '& :not(circle, path:first-of-type)': {
              stroke: palette.common.white
            }
          },

          // styles for the content within the alert
          message: {
            padding: 0,
            color: palette.darkGray.main,

            // styles for the description text
            '& .MuiTypography-h6Regular': {
              color: palette.darkGray.main
            }
          },

          // styles for the right Button/IconButton
          action: {
            padding: 0,
            marginTop: -5,
            marginRight: -5,
            height: '1rem'
          },

          // styles for the different variants
          standardSuccess: {
            border: `1px solid ${palette.green.main}`,
            background: lighten(palette.green.main, 0.85)
          },
          standardInfo: {
            border: `1px solid ${palette.blue.main}`,
            background: lighten(palette.blue.main, 0.85)
          },
          standardWarning: {
            border: `1px solid ${palette.orange.main}`,
            background: lighten(palette.orange.main, 0.85)
          },
          standardError: {
            border: `1px solid ${palette.red.main}`,
            background: lighten(palette.red.main, 0.85)
          }
        },
        defaultProps: {
          // tells MUI to use these icons given the specific variant
          iconMapping: {
            success: <Icon icon={ICONS.CIRCLE_CHECK} />,
            info: <Icon icon={ICONS.INFO} />,
            warning: <Icon icon={ICONS.ALERT} />,
            error: <Icon icon={ICONS.ALERT} />
          }
        }
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            ...typography.h5,
            color: palette.darkGray.main,
            marginBottom: 0,
            marginTop: 5,
            paddingTop: 0
          }
        }
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            /**
             * Overwrites high-specificity MUI styles that offsets snackbar
             */
            '&&': {
              bottom: 0,
              left: 0
            }
          }
        }
      }
    }
  });

  return theme;
};
