import { useOrganization } from 'queries/UseOrganizationData';
import { useAppContext } from './useAppContext';

export const useOrgFeatures = () => {
  const { selectedOrganization } = useAppContext();

  const { data } = useOrganization(selectedOrganization.id);

  return data?.features;
};
