import {
  PaletteColor,
  SimplePaletteColorOptions,
  Theme,
  createTheme
} from '@mui/material';

/**
 * When adding a color to the theme, make sure to
 * follow the module augmentation guide:
 * https://mui.com/customization/palette/#adding-new-colors
 */
declare module '@mui/material/styles' {
  interface Palette {
    blue: PaletteColor;
    darkBlue: PaletteColor;
    green: PaletteColor;
    purple: PaletteColor;
    red: PaletteColor;
    orange: PaletteColor;
    pink: PaletteColor;
    yellow: PaletteColor;
    black: PaletteColor;
    lightestGray: PaletteColor;
    lightGray: PaletteColor;
    darkGray: PaletteColor;
    white: PaletteColor;
  }
  interface PaletteOptions {
    blue?: SimplePaletteColorOptions;
    darkBlue?: SimplePaletteColorOptions;
    green?: SimplePaletteColorOptions;
    purple?: SimplePaletteColorOptions;
    red?: SimplePaletteColorOptions;
    orange?: SimplePaletteColorOptions;
    pink?: SimplePaletteColorOptions;
    yellow?: SimplePaletteColorOptions;
    black?: SimplePaletteColorOptions;
    lightestGray?: SimplePaletteColorOptions;
    lightGray?: SimplePaletteColorOptions;
    darkGray?: SimplePaletteColorOptions;
    white?: SimplePaletteColorOptions;
  }
}

const createColorTheme = (currTheme: Theme): Theme => {
  const { augmentColor } = currTheme.palette;
  // add colors from
  // https://www.figma.com/file/PXuH57YN1wuPNEKVbbY8DM/iDonate-SOW-2-Progress?node-id=1%3A1179
  let theme = createTheme(currTheme, {
    palette: {
      blue: augmentColor({ color: { main: '#2356F6' } }),
      darkBlue: augmentColor({ color: { main: '#17479e' } }),
      green: augmentColor({ color: { main: '#3FAB8C' } }),
      purple: augmentColor({ color: { main: '#AA23BF' } }),
      red: augmentColor({ color: { main: '#EA333E' } }),
      orange: augmentColor({ color: { main: '#EA9633' } }),
      pink: augmentColor({ color: { main: '#EC67C7' } }),
      yellow: augmentColor({ color: { main: '#DDBA1F' } }),
      black: augmentColor({ color: { main: '#020026' } }),
      lightestGray: augmentColor({
        color: { main: '#EEF0FC' }
      }),
      lightGray: augmentColor({ color: { main: '#DADCE8' } }),
      darkGray: augmentColor({ color: { main: '#656677' } }),
      white: augmentColor({ color: { main: '#F8F8FA' } }) // TODO: ELG - This isn't actually part of our design, our white is FFFFFF
    }
  });

  // assign colors to things
  theme = createTheme(theme, {
    palette: {
      primary: {
        main: theme.palette.blue.main
      }
    }
  });

  return theme;
};

export { createColorTheme };
