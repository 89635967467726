import { FormEvent, useEffect, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
import Prism from 'prismjs';
import Button from 'components/Button';
import Text from 'components/Text';
import './GivingFormCustomCss.scss';
import './Prism/prism.css';

const MAX_LINES = 35;
const MIN_LINES = 7;

type GivingFormCustomCssProps = {
  startingCustomCss?: string;
  updateCustomCss(customCss: string): void;
};

export const GivingFormCustomCss = ({
  startingCustomCss,
  updateCustomCss
}: GivingFormCustomCssProps): JSX.Element => {
  const [customCss, setCustomCss] = useState<string>(startingCustomCss);
  const inputElement = useRef(null);
  const highlightElement = useRef(null);
  const highlightContentElement = useRef(null);
  const containerElement = useRef(null);

  // Maintain height each time input is changed
  useEffect(() => {
    const newlineCount = (customCss.match(/\n/g) || '').length + 1;
    // height equals line count within max/min times line height(22px) divided by font size(16px) plus padding(2.5rem)
    // line height and font size are specificed for this input on the classes .editing and .highlighting
    const height =
      (Math.max(Math.min(newlineCount, MAX_LINES), MIN_LINES) * 22) / 16 + 2.5;
    if (inputElement && highlightElement && containerElement) {
      inputElement.current.setAttribute('style', `height: ${height}rem`);
      highlightElement.current.setAttribute('style', `height: ${height}rem`);
      containerElement.current.setAttribute(
        'style',
        `height: ${height + 2.5}rem`
      );
    }
  }, [customCss]);

  // Initialize and style content in textarea
  useEffect(() => {
    inputElement.current.textContent = customCss;
    highlightContentElement.current.textContent = customCss;
    Prism.highlightAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSubmit = async () => {
    updateCustomCss(customCss);
  };

  // update styled content each time textarea input is changed
  const update = (text: string) => {
    let updatedText = text;
    setCustomCss(updatedText);
    if (updatedText[text.length - 1] === '\n') {
      // If the last character is a newline character
      // Add a placeholder space character to the final line
      updatedText += ' ';
    }
    highlightContentElement.current.textContent = updatedText;
    Prism.highlightElement(highlightContentElement.current);
  };

  // Must specifically check for and enter tabs.  Otherwise,
  // pressing tab would target the next input on the page
  const checkForTab = (event: FormEvent<HTMLTextAreaElement>) => {
    const code = event.currentTarget.value;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((event as any).code === 'Tab') {
      event.preventDefault();
      const element = event.currentTarget;
      const beforeTab = code.slice(0, element.selectionStart);
      const afterTab = code.slice(element.selectionEnd, element.value.length);
      const cursorPos = element.selectionEnd + 1;
      element.value = `${beforeTab}\t${afterTab}`;
      element.selectionStart = cursorPos;
      element.selectionEnd = cursorPos;
      update(element.value);
    }
  };

  // textarea and styled text must be forced to scroll together
  const syncScroll = (element: FormEvent<HTMLTextAreaElement>) => {
    highlightElement.current.scrollTop = element.currentTarget.scrollTop;
    highlightElement.current.scrollLeft = element.currentTarget.scrollLeft;
  };

  return (
    <Box className="ElementLibraryMenu-container">
      <Box className="ElementLibraryMenu-Header">
        <Text variant="h2" className="ElementLibraryMenu-Header__title">
          Custom CSS
        </Text>
        <Text variant="body" className="ElementLibraryMenu-Header__subtitle">
          Add your own custom CSS to this Giving Form
        </Text>
      </Box>
      <Box className="ElementLibraryMenu-content--container giving-form-css-editor">
        <Stack className="LookAndFeelMenu">
          <div className="cssTextareaContainer" ref={containerElement}>
            <textarea
              className="editing"
              ref={inputElement}
              onInput={(e) => update(e.currentTarget.value)}
              onKeyDown={checkForTab}
              onScroll={syncScroll}
              spellCheck={false}
            />

            <pre
              className="highlighting"
              ref={highlightElement}
              aria-hidden="true"
            >
              <code
                className="language-css highlighting-content"
                ref={highlightContentElement}
              />
            </pre>
          </div>

          <Button
            className="submit-custom-css"
            onClick={onClickSubmit}
            disabled={customCss === startingCustomCss}
          >
            Update
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
