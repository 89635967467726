import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useAbTest, useEndAbTestMutation } from 'queries/UseAbTests';
import { AbTest, GivingFormConfigVariant } from 'types';
import { EndAbTestConfirmationModal } from './EndAbTestConfirmationModal';
import { EndAbTestCongratulationsModal } from './EndAbTestCongratuationsModal';
import { EndAbTestModal } from './EndAbTestModal';

interface EndAbTestModalFlowProps {
  isOpen: boolean;
  abTestId: string;
  returnButtonText: string;
  onRequestClose: () => void;
}

const enum FlowStep {
  END_TEST,
  CONFIRMATION,
  CONGRATULATIONS
}

export const EndAbTestModalFlow = ({
  isOpen,
  abTestId,
  onRequestClose,
  returnButtonText
}: EndAbTestModalFlowProps): JSX.Element => {
  const [pushAlert] = useAlerts();
  const queryClient = useQueryClient();
  const { selectedOrganization } = useAppContext();
  const organizationId = selectedOrganization?.id;
  const { data: abTest = {} as AbTest } = useAbTest(
    {
      organizationId,
      abTestId,
      includeMetrics: true
    },
    {
      enabled: !!abTestId
    }
  );

  const [currentStep, setCurrentStep] = useState<FlowStep>(FlowStep.END_TEST);
  const [selectedVariant, setSelectedVariant] =
    useState<GivingFormConfigVariant>();

  const handleClose = () => {
    onRequestClose();
    setCurrentStep(FlowStep.END_TEST);
  };

  const { mutateAsync: endAbTest, isLoading: isEndingTest } =
    useEndAbTestMutation(
      {
        organizationId,
        abTestId,
        givingFormConfigVariant: selectedVariant
      },
      {
        onError: () => {
          pushAlert({
            title: 'Unable to end the current AB test.',
            severity: 'error'
          });
        },
        onSuccess: () => {
          setCurrentStep(FlowStep.CONGRATULATIONS);
          queryClient.invalidateQueries(['getAbTestByOrganization']);
          queryClient.invalidateQueries(['getAbTest']);
        }
      }
    );

  if (isOpen) {
    switch (currentStep) {
      case FlowStep.END_TEST:
        return (
          <EndAbTestModal
            abTest={abTest}
            onCancel={() => {
              setSelectedVariant(null);
              handleClose();
            }}
            onEndTest={(variant: GivingFormConfigVariant) => {
              setSelectedVariant(variant);
              setCurrentStep(FlowStep.CONFIRMATION);
            }}
            returnButtonText={returnButtonText}
          />
        );
      case FlowStep.CONFIRMATION:
        return (
          <EndAbTestConfirmationModal
            abTest={abTest}
            selectedVariant={selectedVariant}
            isEndingTest={isEndingTest}
            onConfirm={() => {
              endAbTest();
            }}
            onPreviousStep={() => {
              setSelectedVariant(null);
              setCurrentStep(FlowStep.END_TEST);
            }}
            onCancel={() => {
              setSelectedVariant(null);
              handleClose();
            }}
          />
        );
      case FlowStep.CONGRATULATIONS:
        return (
          <EndAbTestCongratulationsModal
            abTest={abTest}
            selectedVariant={selectedVariant}
          />
        );
      default:
        return null;
    }
  }

  return null;
};
