import { Divider, Skeleton } from '@mui/material';
import { StrategyAbTesting, StrategyPitch } from 'components';
import { useAppContext } from 'hooks/useAppContext';
import './Strategy.scss';

export const Strategy = () => {
  const { organizationInfo } = useAppContext();
  const isAbTestingEnabled = organizationInfo?.features?.abTesting;

  const skeleton = (
    <>
      <div className="skeleton-container">
        <div>
          <Skeleton
            className="skeleton skeleton-header"
            animation="wave"
            height="2rem"
            width="16rem"
          />
          <Skeleton
            className="skeleton skeleton-body"
            animation="wave"
            height="24rem"
            width="32rem"
          />
        </div>
        <Skeleton
          className="skeleton"
          animation="wave"
          height="27.5rem"
          width="27.5rem"
          variant="circular"
        />
      </div>
      <Divider className="divider" />
      <Skeleton
        className="skeleton skeleton-bottom"
        animation="wave"
        height="3.375rem"
        width="50rem"
      />
    </>
  );

  const renderAbTesting = () => {
    if (!organizationInfo.features) {
      return skeleton;
    }
    if (isAbTestingEnabled) {
      return <StrategyAbTesting />;
    }
    return <StrategyPitch />;
  };

  return (
    <div className="strategy">
      <div className="strategy-header">
        <div className="fluid-container" />
      </div>
      <div className="strategy-card fluid-container">{renderAbTesting()}</div>
    </div>
  );
};
