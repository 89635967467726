import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Blocker, ButtonProps } from 'components';
import { Preview } from 'components/PreviewModal';
import { AbTestOverviewBody } from 'components/gms/AbTestOverviewBody';
import { EndAbTestModalFlow } from 'components/gms/EndAbTestModal/EndAbTestModalFlow';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import { useAppContext } from 'hooks/useAppContext';
import { useAbTest } from 'queries/UseAbTests';
import { AbTest } from 'types';
import './AbTestOverview.scss';

export const AbTestOverview = (): JSX.Element => {
  const { abTestId } = useParams();
  const { selectedOrganization } = useAppContext();
  const organizationId = selectedOrganization?.id;
  const { data: abTest = {} as AbTest, isLoading } = useAbTest({
    organizationId,
    abTestId,
    includeMetrics: true
  });
  const [isEndTestFlowOpen, setIsEndTestFlowOpen] = useState<boolean>(false);
  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);

  const breadcrumbs = [
    {
      label: 'Strategy',
      path: '/strategy'
    },
    {
      label: 'A/B Tests',
      path: '/strategy/ab-test'
    },
    {
      label: 'A/B Test Overview',
      path: ''
    }
  ];

  const subtitleProps = {
    customTitle: abTest?.givingForm?.name && (
      <>
        Giving Form: <b>{abTest?.givingForm?.name}</b>
      </>
    )
  };

  const primaryButtonProps: ButtonProps = {
    children: 'End Test',
    onClick: () => setIsEndTestFlowOpen(true)
  };

  const secondaryButtonProps: ButtonProps = {
    children: 'Preview Giving Forms',
    onClick: () => setPreviewModalOpen(true)
  };

  return (
    <Blocker block={isLoading}>
      <FeatureHeader
        titleProps={{
          value: abTest.name
        }}
        breadcrumbsProps={{ breadcrumbs }}
        subtitleProps={subtitleProps}
        primaryButtonProps={!abTest.endedAt ? primaryButtonProps : undefined}
        secondaryButtonProps={secondaryButtonProps}
      />
      {!isLoading && <AbTestOverviewBody abTest={abTest} />}
      <EndAbTestModalFlow
        isOpen={isEndTestFlowOpen}
        abTestId={abTestId}
        onRequestClose={() => setIsEndTestFlowOpen(false)}
        returnButtonText="Back to Analytics"
      />
      {previewModalOpen && (
        <Preview
          isOpen={previewModalOpen}
          onClose={() => setPreviewModalOpen(false)}
          configData={null}
          givingFormId={abTest.givingForm.id}
          abTest={abTest}
        />
      )}
    </Blocker>
  );
};
