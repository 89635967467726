import { useEffect, useState } from 'react';
import { InputAdornment, Skeleton } from '@mui/material';
import { matchSorter } from 'match-sorter';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { Blocker, EmptyStateTextDisplay } from 'components';
import { ButtonProps } from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import TextField from 'components/TextField';
import CampaignPreviewCard from 'components/gms/CampaignPreviewCard';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useCampaigns } from 'queries/UseCampaigns';
import { CampaignType } from 'services/types';
import './Campaigns.scss';

export const Campaigns = (): JSX.Element => {
  const navigate = useNavigate();
  const [pushAlert] = useAlerts();
  const { selectedOrganization } = useAppContext();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<CampaignType[]>([]);

  const { isLoading, isFetching, data, isError } = useCampaigns(
    selectedOrganization.id,
    {
      onSuccess: (campaigns) => setSearchResults(campaigns)
    }
  );

  const debouncedSearch = useDebouncedCallback((searchText: string) => {
    if (!searchText) {
      setSearchResults(data);
    } else {
      setSearchResults(matchSorter(data, searchText, { keys: ['title'] }));
    }
  }, 750);

  const onSearch = (searchText: string) => {
    setSearchQuery(searchText);
    debouncedSearch(searchText);
  };

  const onCampaignClick = (campaignId: string) => {
    navigate(`/campaigns/${campaignId}`);
  };

  const primaryButtonProps: ButtonProps = {
    children: 'Start New Campaign',
    startIcon: <Icon icon={ICONS.PLUS} />,
    onClick: () => navigate('/campaigns/new')
  };

  useEffect(() => {
    if (isError) {
      pushAlert({
        title: 'Uh oh. Looks like there was an error loading your campaigns.',
        severity: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const activeCampaigns = searchResults?.filter(
    (campaign) => !campaign.isArchived
  );
  const archivedCampaigns = searchResults?.filter(
    (campaign) => campaign.isArchived
  );

  return (
    <div className="campaigns-container">
      <Blocker block={isLoading}>
        <FeatureHeader
          titleProps={selectedOrganization.name}
          primaryButtonProps={primaryButtonProps}
          hoverThemeVariant
        />
        <div className="content-container fluid-container">
          <div className="list-container">
            <div className="list-header">
              <Text className="list-header-title" variant="h2">
                Your Campaigns
              </Text>
              <TextField
                className="list-header-search-bar"
                placeholder="Search Campaigns"
                value={searchQuery}
                onChange={(e) => onSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon icon={ICONS.SEARCH} />
                    </InputAdornment>
                  )
                }}
                autoComplete="off"
              />
            </div>
            {(isFetching || debouncedSearch?.isPending()) && (
              <div className="list-cards">
                {Array(6)
                  .fill(0)
                  .map((_, index) => (
                    <Skeleton
                      role="progressbar"
                      aria-label="skeleton loader"
                      className="list-card-loader"
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      variant="rectangular"
                      animation="wave"
                      width="100%"
                    />
                  ))}
              </div>
            )}
            {!debouncedSearch?.isPending() &&
              !isFetching &&
              activeCampaigns?.length === 0 && (
                <EmptyStateTextDisplay heading="You don’t currently have any campaigns to display." />
              )}
            {!debouncedSearch?.isPending() &&
              !isFetching &&
              !isError &&
              activeCampaigns?.length > 0 && (
                <div className="list-cards">
                  {activeCampaigns.map((campaign: CampaignType) => (
                    <CampaignPreviewCard
                      key={campaign.id}
                      campaignAmountRaised={campaign.amountRaised}
                      title={campaign.title}
                      campaignGoal={campaign.goal}
                      campaignStartDate={campaign.startDate}
                      campaignEndDate={campaign.endDate}
                      onCampaignClick={() => onCampaignClick(campaign.id)}
                      isArchived={campaign.isArchived}
                    />
                  ))}
                </div>
              )}

            {!debouncedSearch?.isPending() &&
              !isFetching &&
              !isError &&
              archivedCampaigns?.length > 0 && (
                <>
                  <Text className="list-header-title-archived" variant="h2">
                    Archived Campaigns
                  </Text>

                  <div className="list-cards">
                    {archivedCampaigns.map((campaign: CampaignType) => (
                      <CampaignPreviewCard
                        key={campaign.id}
                        campaignAmountRaised={campaign.amountRaised}
                        title={campaign.title}
                        campaignGoal={campaign.goal}
                        campaignStartDate={campaign.startDate}
                        campaignEndDate={campaign.endDate}
                        onCampaignClick={() => onCampaignClick(campaign.id)}
                        isArchived={campaign.isArchived}
                      />
                    ))}
                  </div>
                </>
              )}
          </div>
        </div>
      </Blocker>
    </div>
  );
};
