import * as yup from 'yup';
import { DesignationGroup } from 'services';

export interface IAdvancedDesignations {
  title: string;
  code: string; // user-provided ID
  startDate?: string;
  endDate?: string;
  description?: string;
  // tags, groups, and image are included in this interface because
  // they are part of the submitted form data, but these fields
  // are validated independently by their own components outside of yup
  tags?: string[];
  groups?: DesignationGroup[];
  imageUrl?: string;
  isActive: boolean;
}

function lessThanEndDate(this: yup.TestContext, field: Date) {
  const endDate = new Date(this?.parent?.endDate);
  if (!endDate.getTime()) {
    return true;
  }
  return field.setHours(0, 0, 0, 0) <= endDate.setHours(0, 0, 0, 0);
}

export const AdvancedDesignationsSchema = yup.object({
  title: yup
    .string()
    .required('Designation title is required')
    .max(60, 'Cannot exceed 60 characters'),
  code: yup
    .string()
    .required('Designation fund ID is required')
    .max(60, 'Cannot exceed 60 characters'),
  startDate: yup
    .date()
    .nullable(true)
    .test('lessThanEndDate', 'Must be valid range', lessThanEndDate),
  endDate: yup.date().nullable(true),
  description: yup.string().max(150, 'Cannot exceed 150 characters')
});
