import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack
} from '@mui/material';
import { ICONS, LimitedTextField, NumberTextField } from 'components';
import Button from 'components/Button';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { CustomImpactStatement } from 'types/givingFormTypes/GiftOptionsBlock';
import './EditCustomImpact.scss';

type EditCustomImpactProps = {
  hasConfig: boolean;
  customImpactStatement: CustomImpactStatement;
  setCustomImpactStatement: Dispatch<SetStateAction<CustomImpactStatement>>;
  open: boolean;
  setOpen: (open: boolean) => void;
  onUpdate: () => void;
  onDelete: () => void;
  primaryColor?: string;
  font?: string;
};

const MAX_LABEL_CHARS = 30;

export const EditCustomImpact = ({
  hasConfig,
  customImpactStatement,
  setCustomImpactStatement,
  open,
  setOpen,
  onUpdate,
  onDelete,
  primaryColor,
  font
}: EditCustomImpactProps): JSX.Element => (
  <Accordion className="edit-custom-impact" expanded={open}>
    <AccordionSummary
      className={`edit-custom-impact-summary ${open ? 'expanded' : ''}`}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={0.25} alignItems="center">
          <Text variant="h3">Custom Amount</Text>
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          {!open && hasConfig && (
            <IconButton
              label="edit"
              variant="basic"
              size="small"
              icon={ICONS.PENCIL}
              onClick={() => setOpen(true)}
            />
          )}
          {hasConfig && (
            <IconButton
              label="delete"
              variant="basic"
              size="small"
              icon={ICONS.TRASH}
              onClick={onDelete}
            />
          )}
          {!open && !hasConfig && (
            <div className="add-impact">
              <Icon icon={ICONS.PLUS} fontSize="small" />
              <Text variant="h4" onClick={() => setOpen(true)}>
                Add Impact
              </Text>
            </div>
          )}
        </Stack>
      </Stack>
      {!open && hasConfig && (
        <div
          className="custom-impact-preview-container"
          style={{
            border: `1px solid ${primaryColor || '#000000'}`
          }}
        >
          <div
            className="custom-impact-background"
            style={{
              backgroundColor: `${primaryColor || '#ccc'}`
            }}
          />
          <div className="custom-impact-content">
            <Text
              variant="h5"
              style={{
                color: `${primaryColor || '#000000'}`,
                fontFamily: `${font || 'Roobert'}`
              }}
            >
              ${customImpactStatement.equationAmount} = 1{' '}
              {customImpactStatement.label}
            </Text>
          </div>
        </div>
      )}
    </AccordionSummary>
    <AccordionDetails
      className={`edit-custom-impact-details ${open ? 'expanded' : ''}`}
    >
      <Stack spacing={0.5}>
        <Text variant="caption">
          Fill out the equation below, and we will calculate the appropriate
          impact to show for a donor&apos;s custom amount.
        </Text>
        <Text variant="h5">Impact Label</Text>
        <LimitedTextField
          placeholder="i.e. Meal"
          value={customImpactStatement.label}
          hiddenLabel
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setCustomImpactStatement({
              ...customImpactStatement,
              label: e.target.value
            })
          }
          maxChar={MAX_LABEL_CHARS}
        />
        <Text variant="h5">Complete the Equation</Text>
        <div className="equation-row">
          <NumberTextField
            prefix="$"
            placeholder="$ Enter Amount"
            value={customImpactStatement.equationAmount}
            onChange={(value) =>
              setCustomImpactStatement({
                ...customImpactStatement,
                equationAmount: value ? Number(value) : null
              })
            }
            onBlur={() => {
              setCustomImpactStatement({
                ...customImpactStatement,
                equationAmount: customImpactStatement.equationAmount || 0
              });
            }}
            className="equation-amount"
          />
          <Text variant="h7" className="equation-equals">
            =
          </Text>
          <Text variant="h8" className="equation-value">
            1 {customImpactStatement.label || 'Item'}
          </Text>
        </div>
        <Button
          variant="primary"
          onClick={() => onUpdate()}
          disabled={
            !customImpactStatement?.label ||
            !customImpactStatement?.equationAmount
          }
          fullWidth
        >
          Update
        </Button>
      </Stack>
    </AccordionDetails>
  </Accordion>
);
