import { Dispatch, SetStateAction } from 'react';
import { Autocomplete, AutocompleteProps } from '@mui/material';
import Chip from 'components/Chip';
import TextField from './TextField';
import './TextFieldWithChips.scss';

type TextFieldWithChipsProps<T> = Omit<
  AutocompleteProps<T, true, true, true>,
  'renderTags' | 'renderInput' | 'options'
> & {
  placeholder: string;
  setTags: Dispatch<SetStateAction<string[]>>;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const TextFieldWithChips = <T extends unknown>({
  placeholder,
  className,
  onChange,
  setTags,
  ...props
}: TextFieldWithChipsProps<T>): JSX.Element => (
  <Autocomplete
    className={`text-field-with-chips ${className}`}
    disableClearable
    multiple
    freeSolo
    options={[]}
    onChange={(event, value, reason) => {
      setTags(value as string[]);
      onChange(event, value, reason);
    }}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderTags={(value: any[], getTagProps) =>
      value.map((option: string, index: number) => (
        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
      ))
    }
    renderInput={(params) => (
      <TextField {...params} variant="filled" placeholder={placeholder} />
    )}
    {...props}
  />
);
