/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FocusEvent, FormEvent, KeyboardEvent, useState } from 'react';
import Icon, { ICONS } from 'components/Icon';
import './TransactionFeeMessage.scss';

interface TransactionFeeMessageProps {
  onBlur(value: string): void;
  defaultText: string;
  placeholder: string;
}

export const TransactionFeeMessage = ({
  onBlur,
  defaultText,
  placeholder
}: TransactionFeeMessageProps) => {
  const [charAmount, setCharAmount] = useState<number>(defaultText.length - 14);
  const [beforeText, afterText] = defaultText.split(placeholder);

  const getCaretIndex = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sel = document.getSelection() as any;
    sel.modify('extend', 'backward', 'paragraphboundary');
    const pos = sel.toString().length;
    if (sel.anchorNode !== undefined) sel.collapseToEnd();
    return pos;
  };

  const moveToEnd = (event: KeyboardEvent<HTMLDivElement>) => {
    const caretIndex = getCaretIndex();
    const contentLength = event.currentTarget.innerText.length;
    const atEnd = caretIndex === contentLength;
    if (atEnd) {
      event.preventDefault();
      (
        document.querySelector('.gms-content-editable-after') as HTMLDivElement
      ).focus();
    }
  };

  const moveToStart = (event: KeyboardEvent<HTMLDivElement>) => {
    const caretIndex = getCaretIndex();
    const atStart = caretIndex === 0;
    if (atStart) {
      event.preventDefault();
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(
        document.querySelector('.gms-content-editable-before')
      );
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLDivElement, Element>) => {
    const { relatedTarget } = event;
    const before = document.querySelector('.gms-content-editable-before');
    const after = document.querySelector('.gms-content-editable-after');

    if (relatedTarget === before || relatedTarget === after) {
      event.preventDefault();
    } else {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        (document.querySelector('.gms-content-editable') as HTMLDivElement)
          .outerHTML,
        'text/html'
      );
      doc.querySelector('[data-tag="placeholder"]').replaceWith(placeholder);
      onBlur(doc.body.innerText);
    }
  };

  const handleBeforeInput = (event: FormEvent<HTMLDivElement>) => {
    const before = (
      document.querySelector('.gms-content-editable-before') as HTMLDivElement
    ).innerText;
    const after = (
      document.querySelector('.gms-content-editable-after') as HTMLDivElement
    ).innerText;

    if (after.length + before.length >= 100) {
      event.preventDefault();
    }
  };

  const handleInput = () => {
    const before = (
      document.querySelector('.gms-content-editable-before') as HTMLDivElement
    ).innerText;
    const after = (
      document.querySelector('.gms-content-editable-after') as HTMLDivElement
    ).innerText;

    setCharAmount(before.length + after.length);
  };

  const handleClick = () => {
    (
      document.querySelector('.gms-content-editable-after') as HTMLDivElement
    ).focus();
  };

  return (
    <>
      <div className="gms-content-editable" onBlur={(e) => handleBlur(e)}>
        <div
          className="gms-content-editable-before"
          contentEditable
          suppressContentEditableWarning
          onKeyDown={(e) => {
            if (e.code === 'ArrowRight') {
              moveToEnd(e);
            }
          }}
          onInput={() => handleInput()}
          onBeforeInput={(e) => handleBeforeInput(e)}
        >
          {beforeText}
        </div>
        <span
          data-tag="placeholder"
          className="gms-content-editable-tag"
          onClick={handleClick}
        >
          <Icon icon={ICONS.LOCK} />
          {placeholder}
        </span>
        <div
          className="gms-content-editable-after"
          contentEditable
          suppressContentEditableWarning
          onKeyDown={(e) => {
            if (e.code === 'ArrowLeft') {
              moveToStart(e);
            }
          }}
          onInput={handleInput}
          onBeforeInput={(e) => handleBeforeInput(e)}
        >
          {afterText}
        </div>
      </div>

      <p className="content-editable-helper">{charAmount}/100 characters</p>
    </>
  );
};
