import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
  Stack
} from '@mui/material';
import { Link } from 'react-router-dom';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';

type Breadcrumb = {
  label: string;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: Record<string, any>;
};

export interface BreadcrumbsProps extends MuiBreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps): JSX.Element => (
  <Stack direction="row">
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      separator={<Icon icon={ICONS.CHEVRON_RIGHT} color="primary" />}
    >
      {breadcrumbs.map((item, index) =>
        index === breadcrumbs.length - 1 || item.path === null ? (
          <Text
            variant="h4"
            color={(theme) => theme.palette.darkGray.main}
            key={item.label}
          >
            {item.label}
          </Text>
        ) : (
          <Link
            to={item.path}
            style={{ textDecoration: 'none' }}
            key={item.label}
            state={item.state}
          >
            <Text variant="h4" color={(theme) => theme.palette.primary.main}>
              {item.label}
            </Text>
          </Link>
        )
      )}
    </MuiBreadcrumbs>
  </Stack>
);

export default Breadcrumbs;
