import { Dispatch, SetStateAction } from 'react';
import { CircularProgress } from '@mui/material';
import { useQueryClient } from 'react-query';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useDeleteDesignationGroupByIdMutation } from 'queries/UseDesignations';
import { DesignationGroup } from 'services';
import './AdvancedDesignationsModals.scss';

export interface NewDesignationsGroupModalProps {
  groupToDelete: DesignationGroup;
  isOpen: boolean;
  onClose: () => void;
  selectedGroups: DesignationGroup[];
  setSelectedGroups: Dispatch<SetStateAction<DesignationGroup[]>>;
  setFieldToTouched: () => void;
}

export const DeleteDesignationsGroupModal = ({
  groupToDelete,
  isOpen,
  onClose,
  selectedGroups,
  setSelectedGroups,
  setFieldToTouched
}: NewDesignationsGroupModalProps) => {
  const [pushAlert] = useAlerts();
  const queryClient = useQueryClient();
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();

  const { mutateAsync: deleteDesignationGroupMutation, isLoading } =
    useDeleteDesignationGroupByIdMutation(organizationId, {
      onSuccess: () => {
        const newSelectedGroupsArray = selectedGroups.filter(
          (group: DesignationGroup) => group.id !== groupToDelete.id
        );
        setSelectedGroups(newSelectedGroupsArray);
        queryClient.invalidateQueries('getDesignationGroups');
        setFieldToTouched();
        onClose();
      },
      onError: () => {
        pushAlert({
          severity: 'error',
          title: 'There was an error deleting your designation group'
        });
      }
    });

  const handleConfirmOnClick = () => {
    deleteDesignationGroupMutation(groupToDelete.id);
  };

  const groupContainsDesignations = groupToDelete.designations.length > 0;

  return (
    <Dialog
      label="delete designations group confirmation modal"
      onClose={onClose}
      open={isOpen}
      className="delete-designations-group-modal"
    >
      <DialogTitle
        className="delete-designations-group-modal-title"
        onClick={onClose}
      >
        Delete Group Confirmation
      </DialogTitle>
      <DialogContent className="delete-designations-group-modal-content">
        <DialogContentTitle className="delete-designations-group-modal-content-title">
          Are you sure you want to delete this designation group?
        </DialogContentTitle>
        {groupContainsDesignations ? (
          <div className="delete-designations-group-modal-content">
            This group contains the following designations:
            <ul>
              {groupToDelete.designations.map((d) => (
                <li key={d.designation.id}>{d.designation.title}</li>
              ))}
            </ul>
            Your designations will not be deleted if you delete this group.
          </div>
        ) : (
          <DialogContentDescription>
            This group does not contain any designations.
          </DialogContentDescription>
        )}
      </DialogContent>
      <DialogActions className="delete-designations-group-modal-actions-container">
        <DialogActionButton
          className="delete-designations-group-modal-confirm"
          onClick={() => handleConfirmOnClick()}
          variant="primary"
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Yes, Delete This Group'
          )}
        </DialogActionButton>
        <DialogActionButton onClick={onClose} variant="secondary">
          Cancel
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
