import { Area } from 'react-easy-crop';

export enum HostedPageLayout {
  FullWidth = 'FullWidth',
  LeftHalf = 'LeftHalf',
  LeftThird = 'LeftThird',
  RightHalf = 'RightHalf',
  RightThird = 'RightThird'
}

export enum HostedPageBlockTypes {
  HostedPageHeaderBlock = 'HostedPageHeaderBlock',
  CustomContent = 'CustomContent',
  GoalMeterBlock = 'GoalMeterBlock',
  HostedPageFooterBlock = 'HostedPageFooterBlock'
}

export enum HostedPageSections {
  top = 'top',
  side = 'side',
  bottom = 'bottom'
}

export interface HostedPageBlockBaseType {
  id: string;
  blockType: HostedPageBlockTypes;
}

export enum BannerSelections {
  COLOR = 'COLOR',
  IMAGE = 'IMAGE'
  // VIDEO = 'VIDEO'
}

export interface IHostedPageHeaderBlock extends HostedPageBlockBaseType {
  id: 'header';
  blockType: HostedPageBlockTypes.HostedPageHeaderBlock;
  html: string;
  isEnabled: boolean;
  banner: {
    isEnabled: boolean;
    selectedBanner: BannerSelections;
    baseImageUrl?: string;
    croppedImageUrl?: string;
    croppedDimensions: Area;
  };
}

export interface IHostedPageFooterBlock extends HostedPageBlockBaseType {
  id: 'footer';
  blockType: HostedPageBlockTypes.HostedPageFooterBlock;
  html: string;
  isEnabled: boolean;
}

export interface IHostedPageConfig {
  layout: HostedPageLayout;
  slug: string;
  pageFavicon?: string;
  pageTitle?: string;
  topBlocks: HostedPageBlockBaseType[];
  sideBlocks: HostedPageBlockBaseType[];
  bottomBlocks: HostedPageBlockBaseType[];
  header?: IHostedPageHeaderBlock;
  footer: IHostedPageFooterBlock;
  customCss?: string;
}

export interface IHostedPageCustomContentBlock extends HostedPageBlockBaseType {
  name: string;
  blockType: HostedPageBlockTypes.CustomContent;
  html: string;
}
