import { Controller, useFormContext } from 'react-hook-form';
import abTestImage from 'assets/images/ab-flask.svg';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { AbTestNotificationCriteria } from 'components/gms/AbTestNotificationCriteria';
import { useAbTestDraft } from 'hooks/useAbTestDraft';
import { NotificationUnit, TimeNotificationUnit } from 'types';
import { NotificationTypes } from 'utils';
import { CreateAbTestSchema } from '../CreateAbTest.Schema';

interface SettingsStepProps {
  nextClicked: boolean;
}

export const SettingsStep = ({ nextClicked }: SettingsStepProps) => {
  const { control, trigger, getValues } = useFormContext();
  const { draft, updateDraft } = useAbTestDraft();

  const {
    config: {
      description = '',
      assumption = '',
      thresholds: {
        unit = 'Select',
        amount = null,
        timeUnit = 'Select',
        timeAmount = null
      }
    }
  } = draft ?? { config: { thresholds: {} } };

  const updateDraftNotifications = async () => {
    try {
      const values = getValues();

      // Only if validation passes for these fields do we write them to localstorage
      await CreateAbTestSchema.validateAt('notificationUnit', values);
      await CreateAbTestSchema.validateAt('notificationUnitAmount', values);

      const { notificationUnit, notificationUnitAmount } = values;

      const isEmpty = notificationUnit === 'Select';

      return updateDraft({
        config: {
          thresholds: {
            unit: isEmpty ? undefined : (notificationUnit as NotificationUnit),
            amount: isEmpty ? undefined : +notificationUnitAmount
          }
        }
      });
    } catch (e) {
      return e;
    }
  };

  const updateDraftTimeNotifications = async () => {
    try {
      const values = getValues();
      // Only if validation passes for these fields do we write them to localstorage
      await CreateAbTestSchema.validateAt('timeNotificationUnit', values);
      await CreateAbTestSchema.validateAt('timeNotificationUnitAmount', values);

      const { timeNotificationUnit, timeNotificationUnitAmount } = values;

      const isEmpty = timeNotificationUnit === 'Select';

      return updateDraft({
        config: {
          thresholds: {
            timeUnit: isEmpty
              ? undefined
              : (timeNotificationUnit as TimeNotificationUnit),
            timeAmount: isEmpty ? undefined : +timeNotificationUnitAmount
          }
        }
      });
    } catch (e) {
      return e;
    }
  };

  const handleMetricNotificationChange = (triggerName: string) => {
    if (nextClicked) {
      trigger(triggerName);
    }

    updateDraftNotifications();
  };

  const handleTimeNotificationChange = (triggerName: string) => {
    if (nextClicked) {
      trigger(triggerName);
    }

    updateDraftTimeNotifications();
  };

  // we wait for the asynchronous check to localstorage for a draft to finish before rendering anything. this should be a very short amount of time
  // since retreiving localstorage is only x amount of milliseconds. this is important so we can properly render the page with the proper defaults.
  // Eventually when drafts are moved to mongo, this will become an http request and we can render a blocker in the meantime

  if (draft !== undefined) {
    return (
      <div className="create-ab-test fluid-container">
        <img src={abTestImage} alt="ab-test-flask" className="ab-test-image" />
        <Text variant="h3" className="ab-test-description-label">
          I am creating this A/B Test to
        </Text>
        <Controller
          name="description"
          control={control}
          defaultValue={description}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              {...field}
              multiline
              minRows={3}
              maxRows={3}
              className="ab-test-description-textfield"
              error={!!error}
              helperText={error?.message}
              placeholder="Enter Description"
              onBlur={(e) => {
                updateDraft({
                  config: {
                    description: e.target.value
                  }
                });
              }}
            />
          )}
        />
        <Text variant="h3" className="ab-test-assumption-label">
          and I predict
        </Text>
        <Controller
          name="assumption"
          control={control}
          defaultValue={assumption}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              {...field}
              multiline
              minRows={4}
              maxRows={4}
              error={!!error}
              helperText={error?.message}
              className="ab-test-assumption-textfield"
              placeholder="Enter Assumptions"
              onBlur={(e) => {
                updateDraft({
                  config: {
                    assumption: e.target.value
                  }
                });
              }}
            />
          )}
        />
        <Text variant="h3" className="ab-test-notification-label">
          I would like to be notified after
        </Text>
        <div className="ab-test-notification-container">
          <AbTestNotificationCriteria
            variant={NotificationTypes.MetricBased}
            onValueChange={handleMetricNotificationChange}
            defaultUnit={unit}
            defaultAmount={amount}
          />
          <Text variant="h3">or</Text>
          <AbTestNotificationCriteria
            variant={NotificationTypes.TimeBased}
            onValueChange={handleTimeNotificationChange}
            defaultUnit={timeUnit}
            defaultAmount={timeAmount}
          />
        </div>
      </div>
    );
  }

  return null;
};
