import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import Dialog, {
  DialogContent,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { useOrgFeatures } from 'hooks/useOrgFeatures';
import './ChannelSelectDialog.scss';

type ChannelSelectDialogProps = {
  open: boolean;
  onClose: () => void;
  campaignName: string;
};

/**
 * A GMS-specific dialog for allowing the user to select a channel and add it
 * to an existing campaign.
 */
const ChannelSelectDialog = ({
  open,
  onClose,
  campaignName
}: ChannelSelectDialogProps) => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const features = useOrgFeatures();

  return (
    <Dialog
      className="channel-select-dialog"
      label="channel select dialog"
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle onClick={(close) => close()} />
      <DialogContent>
        <Text variant="h5">
          Campaign:{' '}
          <span className="selected-campaign-name">{campaignName}</span>
        </Text>
        <DialogContentTitle>
          Where would you like to get started?
        </DialogContentTitle>
        <Box
          display="flex"
          justifyContent="center"
          className="channel-options-container"
        >
          <Grid
            container
            justifyContent="center"
            className="channel-options-button-wrapper"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className="channel-button"
              onClick={() => navigate(`/campaigns/${campaignId}/giving-forms`)}
            >
              <Icon
                className="channel-icon"
                icon={ICONS.GIFT}
                color="primary"
              />
              <Text variant="h5">Giving Form</Text>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className={clsx('channel-button', {
                disabled: !features?.p2p2019
              })}
              onClick={() =>
                features?.p2p2019 &&
                navigate(`/campaigns/${campaignId}/p2p/new`)
              }
            >
              <Icon className="channel-icon users" icon={ICONS.USERS} />
              <Text variant="h5">Peer to Peer</Text>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className={clsx('channel-button', {
                disabled: !features?.smsGiving
              })}
              onClick={() =>
                features?.smsGiving &&
                navigate(`/campaigns/${campaignId}/text-keywords/new`)
              }
            >
              <Icon className="channel-icon messages" icon={ICONS.MESSAGES} />
              <Text variant="h5">Text to Give</Text>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className={clsx('channel-button', {
                disabled: !features?.eventManagement2019
              })}
              onClick={() =>
                features?.eventManagement2019 &&
                navigate(`/campaigns/${campaignId}/events/new`)
              }
            >
              <Icon
                className="channel-icon speakerphone"
                icon={ICONS.SPEAKERPHONE}
              />
              <Text variant="h5">Event</Text>
            </Box>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default ChannelSelectDialog;
