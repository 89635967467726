import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import Icon, { ICONS } from 'components/Icon';
import './UnpublishedChangesModal.scss';

type UnpublishedChangesModalProps = {
  open: boolean;
  onClose: () => void;
  onReturnToEditor: () => void;
};

export const UnpublishedChangesModal = ({
  open,
  onClose,
  onReturnToEditor
}: UnpublishedChangesModalProps) => (
  <Dialog
    className="unpub-changes-modal"
    label="Unpublished Changes Modal"
    open={open}
    onClose={() => onClose()}
  >
    <DialogTitle
      className="unpub-changes-modal-title"
      onClick={(close) => close()}
    >
      Create A/B Test
    </DialogTitle>
    <DialogContent>
      <DialogContentTitle>
        <Icon fontSize="large" icon={ICONS.ALERT_TRIANGLE} />
        <div className="title-space" />
        This form has unpublished changes
      </DialogContentTitle>
      <DialogContentDescription className="unpub-changes-modal-content">
        Your recent Giving Form edits will not be visible in this A/B test
        unless you publish these changes first.
        <span className="editor-return-message">
          Would you like to return to the Giving Form Editor to publish these
          changes before starting your A/B Test?
        </span>
      </DialogContentDescription>
    </DialogContent>
    <DialogActions>
      <DialogActionButton variant="primary" onClick={() => onReturnToEditor()}>
        Yes, Return to Editor
      </DialogActionButton>
      <DialogActionButton onClick={(close) => close()} variant="secondary">
        No, Select Another Form
      </DialogActionButton>
    </DialogActions>
  </Dialog>
);
