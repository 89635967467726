import { Chip } from '@mui/material';
import clsx from 'clsx';
import Button from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { OrganizationRole, useRenderIfRole } from 'hooks/useRenderIfRole';
import {
  decimalToPercentage,
  numberFormatter,
  usdCurrencyFormatter
} from 'utils';
import '../ChannelPreviewCard.scss';
import './GivingFormPreviewCard.scss';

type GivingFormPreviewCardProps = {
  givingFormName: string;
  givingFormId: string;
  createdDate: string;
  editedDate?: string;
  amountRaised?: number;
  conversionRate?: number;
  donationAverage?: number; // donationAverage only rendered on Campaign Home
  donors?: number; // donors only rendered on Select GF to A/B Test
  donationCount?: number;
  visits?: number;
  abTestDelta?: {
    amountRaised: number;
    conversionRate?: number;
    donationCount: number;
    visits: number;
  };
  onEditClick?: (isLegacy: boolean) => void;
  onEmbedCodeClick?: () => void;
  onTestResultsClick?: () => void;
  onPreviewClick?: () => void;
  onTestThisFormClick?: () => void;
  onChooseFormClick?: () => void;
  onArchiveClick?: (toArchive: boolean) => void;
  marginOnEven?: boolean;
  isDraft?: boolean;
  isAbTest?: boolean;
  isAbTestDraft?: boolean;
  testVariantSelected?: boolean;
  unpublishedChanges: boolean;
  parentComp: string; // used to conditionally render parts of the card that are specific to different screens
  isLegacy?: boolean;
  className?: string;
  isSmsForm?: boolean;
  hostedPageSlug?: string;
  isArchived?: boolean;
};

/**
 * A card used to display an overview of campaign's giving form
 */
export const GivingFormPreviewCard = ({
  givingFormName,
  givingFormId,
  createdDate,
  editedDate,
  amountRaised = 0,
  conversionRate,
  donationAverage = 0,
  donors,
  donationCount = 0,
  visits = 0,
  abTestDelta,
  onEditClick,
  onEmbedCodeClick,
  onTestResultsClick,
  onPreviewClick,
  onTestThisFormClick,
  onChooseFormClick,
  onArchiveClick,
  unpublishedChanges,
  isDraft,
  isAbTest,
  isAbTestDraft,
  testVariantSelected,
  parentComp,
  isLegacy,
  className,
  isSmsForm,
  hostedPageSlug,
  isArchived,
  marginOnEven = true
}: GivingFormPreviewCardProps) => {
  const {
    envConfig,
    selectedOrganization,
    organizationInfo: { organizationSlug }
  } = useAppContext();
  const [addAlert] = useAlerts();

  // Use first value in giving form ID to get a psudo-static color on each card
  // to be rendered on Select GF to A/B Test
  const backgroundColorKey = (givingFormId?.charCodeAt(0) || 0) % 3;

  const cardHeaderClassName = clsx(
    'channel-preview-header',
    backgroundColorKey === 0 &&
      ['abTestParentComp', 'endAbTest'].includes(parentComp) &&
      'ab-test-purple-gradient',
    backgroundColorKey === 1 &&
      ['abTestParentComp', 'endAbTest'].includes(parentComp) &&
      'ab-test-green-gradient',
    backgroundColorKey === 2 &&
      ['abTestParentComp', 'endAbTest'].includes(parentComp) &&
      'ab-test-yellow-gradient',
    {
      'channel-preview-header-draft': isDraft
    }
  );
  const channelIconClassName = clsx('channel-icon', {
    'channel-icon-draft': isDraft
  });
  const formDetailClassName = clsx('form-detail', {
    'form-detail-draft': isDraft
  });

  const detailVariant = parentComp === 'endAbTest' ? 'h6' : 'body';

  const { renderIfRole } = useRenderIfRole();

  const getImageName = () => {
    if (parentComp === 'endAbTest' && givingFormName === 'Form B') {
      return 'variant-b-preview-image.jpg';
    }

    return 'preview-image.jpg';
  };

  const handleCopyPageLinkClick = () => {
    window.navigator.clipboard.writeText(
      `${envConfig.hostedPageDomain}${organizationSlug}/${hostedPageSlug}`
    );
    addAlert({
      title: 'Link copied to clipboard',
      severity: 'success'
    });
  };

  const showVariantMetric = () =>
    parentComp === 'endAbTest' &&
    givingFormName === 'Form B' &&
    Object.keys(abTestDelta || {}).length > 0;

  // GivingFormPreviewCard is rendered on Campaign Home
  // and on Select Giving Form to A/B Test
  // Cards have different hover buttons depending on which
  // parent component is rendering them
  const overviewHoverButtons = (parentComponent: string) => {
    if (parentComponent === 'campaignHome') {
      return (
        <div
          className={clsx('overview-hover', {
            'overview-archived': isArchived
          })}
        >
          {isAbTest && (
            <Button
              variant="secondary"
              onClick={() => onTestResultsClick()}
              startIcon={<Icon icon={ICONS.CHART_LINE} />}
            >
              Test Results
            </Button>
          )}
          {hostedPageSlug && (
            <Button
              variant="secondary"
              onClick={() => handleCopyPageLinkClick()}
              startIcon={<Icon icon={ICONS.COPY_DASHED} strokeWidth={0.5} />}
              className="preview-card-copy-page-link-button"
            >
              Copy Page Link
            </Button>
          )}
          {!isLegacy && (
            <Button
              variant="secondary"
              onClick={() => onPreviewClick()}
              startIcon={<Icon icon={ICONS.EYE} />}
            >
              Preview
            </Button>
          )}
          {!isAbTestDraft &&
            !isAbTest &&
            renderIfRole(
              <Button
                variant="secondary"
                onClick={() => onEditClick(isLegacy)}
                startIcon={<Icon icon={ICONS.PENCIL} />}
              >
                Edit
              </Button>,
              OrganizationRole.Editor
            )}
          {!isDraft && !isLegacy && (
            <Button
              variant="secondary"
              startIcon={<Icon icon={ICONS.COPY} />}
              onClick={() => onEmbedCodeClick()}
            >
              Embed Code
            </Button>
          )}
          {!isAbTest &&
            !isArchived &&
            renderIfRole(
              <Button
                variant="secondary"
                onClick={() => onArchiveClick(true)}
                startIcon={<Icon icon={ICONS.ARCHIVE} />}
              >
                Archive
              </Button>,
              OrganizationRole.Editor
            )}
          {!isAbTest &&
            isArchived &&
            renderIfRole(
              <Button
                variant="secondary"
                onClick={() => onArchiveClick(false)}
                startIcon={<Icon icon={ICONS.ARCHIVE} />}
              >
                Unarchive
              </Button>,
              OrganizationRole.Editor
            )}
        </div>
      );
      // eslint-disable-next-line no-else-return
    } else if (parentComponent === 'abTestParentComp' && !isAbTest) {
      return (
        <div className="overview-hover">
          {renderIfRole(
            <Button
              className="test-form-button"
              variant="secondary"
              startIcon={<Icon icon={ICONS.COPY_DASHED} strokeWidth={0.5} />}
              onClick={() => onTestThisFormClick()}
            >
              Test This Form
            </Button>,
            OrganizationRole.Editor
          )}
        </div>
      );
    }
    return null;
  };

  const renderDelta = (delta: number) => {
    if (!showVariantMetric()) {
      return null;
    }

    return (
      <div
        className={clsx('ab-variant-metric', {
          'variant-increased': delta > 0,
          'variant-decreased': delta < 0,
          'variant-unchanged': delta === 0
        })}
      >
        <span className="variant-direction">
          {delta > 0 && (
            <Icon
              className="variant-direction-icon"
              icon={ICONS.ARROW_UP_RIGHT}
            />
          )}
          {delta < 0 && (
            <Icon
              className="variant-direction-icon"
              icon={ICONS.ARROW_DOWN_RIGHT}
            />
          )}
        </span>
        <span>{`${Math.abs(decimalToPercentage(delta))}%`}</span>
      </div>
    );
  };

  const renderChips = () => {
    if (isArchived) {
      return (
        <Chip
          className="channel-card-chip archived"
          size="medium"
          label="Archived"
        />
      );
    }
    if (isDraft) {
      return (
        <Chip className="channel-card-chip draft" size="medium" label="Draft" />
      );
    }
    if (unpublishedChanges && !isAbTest && !isAbTestDraft) {
      return (
        <Chip
          className="channel-card-chip unpub-changes"
          size="medium"
          label="Unpublished Changes"
        />
      );
    }
    if (isAbTest) {
      return (
        <Chip
          className="channel-card-chip ab-test"
          size="medium"
          label="A/B Test in Progress"
        />
      );
    }
    if (isAbTestDraft && !isAbTest) {
      return (
        <Chip
          className="channel-card-chip ab-test-draft"
          size="medium"
          label="A/B Test Draft"
        />
      );
    }
    return null;
  };

  return (
    <div
      className={clsx(
        {
          'channel-preview-box': true,
          'giving-form-preview-box': true,
          'margin-on-even': marginOnEven,
          'campaign-home': parentComp === 'campaignHome',
          'ab-test-select-form': parentComp === 'abTestParentComp',
          ab: isAbTest || isAbTestDraft,
          'ab-test': isAbTest,
          'ab-test-draft': isAbTestDraft && !isAbTest,
          selected: testVariantSelected
        },
        className
      )}
    >
      {isArchived && <div className="archived-grayed-out" />}
      {parentComp !== 'endAbTest' && overviewHoverButtons(parentComp)}
      {renderChips()}

      <div className="giving-form-preview-box-card">
        {isLegacy ? (
          <div className="channel-preview-header legacy-header">
            <Text className="header-label" variant="h2">
              Giving Form
            </Text>
            <Icon icon={ICONS.GIFT} className="header-icon" />
          </div>
        ) : (
          <div className={cardHeaderClassName}>
            <img
              src={`${envConfig?.s3Url}/organizations/${
                selectedOrganization.id
              }/giving-form/${givingFormId}/${getImageName()}?cache=${new Date().getTime()}`}
              className="preview-image"
              alt=""
              onError={(event) => {
                // eslint-disable-next-line no-param-reassign
                event.currentTarget.className = 'preview-image-missing';
              }}
              loading="lazy"
            />

            <div className="background-ellipse">
              <div className="background-ellipse-center" />
            </div>
          </div>
        )}
        <div className="channel-preview-content">
          <div className="channel-preview-content-name">
            {parentComp !== 'endAbTest' && (
              <IconButton
                label="giving form label icon"
                className={channelIconClassName}
                icon={ICONS.GIFT}
                variant="primary"
                size="small"
              />
            )}
            <Text variant="h3">{givingFormName}</Text>
          </div>

          {isDraft && (
            <div className="form-details">
              <div className={formDetailClassName}>
                <div className="form-detail-line-item">
                  <Text className="label" variant="body">
                    Started
                  </Text>
                </div>
                <div className="form-detail-line-item">
                  <Text variant={detailVariant}>{createdDate}</Text>
                </div>
              </div>
              <div className={formDetailClassName}>
                <div className="form-detail-line-item">
                  <Text className="label" variant="body">
                    Last Edited
                  </Text>
                </div>
                <div className="form-detail-line-item">
                  <Text variant={detailVariant}>{editedDate}</Text>
                </div>
              </div>
            </div>
          )}

          {!isDraft && (
            <div className="form-details">
              {parentComp !== 'endAbTest' && (
                <div className={formDetailClassName}>
                  <div className="form-detail-line-item">
                    <Text className="label" variant="body">
                      Started
                    </Text>
                  </div>
                  <div className="form-detail-line-item">
                    <Text variant={detailVariant}>{createdDate}</Text>
                  </div>
                </div>
              )}

              <div className={formDetailClassName}>
                <div className="form-detail-line-item">
                  <Text className="label" variant="body">
                    Amount Raised
                  </Text>
                </div>
                <div className="form-detail-line-item">
                  <Text variant={detailVariant}>
                    {usdCurrencyFormatter.format(amountRaised)}
                    {renderDelta(abTestDelta?.amountRaised)}
                  </Text>
                </div>
              </div>
              {parentComp === 'endAbTest' && (
                <div className={formDetailClassName}>
                  <div className="form-detail-line-item">
                    <Text className="label" variant="body">
                      Conversion Rate
                    </Text>
                  </div>
                  <div className="form-detail-line-item">
                    <Text variant={detailVariant}>
                      {conversionRate
                        ? `${decimalToPercentage(conversionRate)}%`
                        : 'N/A'}
                      {renderDelta(abTestDelta?.conversionRate ?? 0)}
                    </Text>
                  </div>
                </div>
              )}
              {parentComp !== 'endAbTest' && (
                <div className={formDetailClassName}>
                  <div className="form-detail-line-item">
                    <Text className="label" variant="body">
                      {parentComp === 'campaignHome'
                        ? 'Donation Average'
                        : 'Donors'}
                    </Text>
                  </div>
                  <div className="form-detail-line-item">
                    <Text variant={detailVariant}>
                      {parentComp === 'campaignHome'
                        ? usdCurrencyFormatter.format(donationAverage)
                        : donors}
                    </Text>
                  </div>
                </div>
              )}
              <div className={formDetailClassName}>
                <div className="form-detail-line-item">
                  <Text className="label" variant="body">
                    Donations
                  </Text>
                </div>
                <div className="form-detail-line-item">
                  <Text variant={detailVariant}>
                    {numberFormatter.format(donationCount)}
                    {renderDelta(abTestDelta?.donationCount)}
                  </Text>
                </div>
              </div>
              <div className={formDetailClassName}>
                <div className="form-detail-line-item">
                  <Text className="label" variant="body">
                    Visits
                  </Text>
                </div>
                <div className="form-detail-line-item">
                  <Text variant={detailVariant}>
                    {numberFormatter.format(visits)}
                    {renderDelta(abTestDelta?.visits)}
                  </Text>
                </div>
              </div>
            </div>
          )}
          {parentComp === 'endAbTest' && (
            <Button
              className="choose-variant-button"
              variant="secondary"
              fullWidth
              onClick={() => onChooseFormClick?.()}
              startIcon={
                testVariantSelected ? <Icon icon={ICONS.CHECK} /> : null
              }
            >
              {testVariantSelected ? 'Form Selected' : 'Choose Form'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GivingFormPreviewCard;
