import { ChangeEvent, useCallback, useState } from 'react';
import { Box, FormControlLabel, Switch } from '@mui/material';
import clsx from 'clsx';
import {
  MAXIMUM_FILE_SIZE,
  MAXIMUM_FILE_SIZE_EXCEEDED_MSG
} from 'constants/givingFormContants';
import { isEqual } from 'lodash';
import Cropper, { Area } from 'react-easy-crop';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import Button from 'components/Button/Button';
import Radio, { RadioGroup } from 'components/Radio';
import Text from 'components/Text';
import WYSIWYG from 'components/WYSIWYG';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { useUpdateBannerImages } from 'queries/UseGivingForms';
import { useUser } from 'queries/UseUsers';
import { BannerSelections, IGivingFormConfig } from 'types';
import { getCroppedImg } from 'utils/imageUtils';
import OptionalSection from '../OptionalSection';
import './EditHostedPageHeader.scss';

export const EditHostedPageHeader = (): JSX.Element => {
  const { givingFormId } = useParams();
  const { data: user } = useUser();

  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();
  const { configData, updateConfig } = useConfigContext<IGivingFormConfig>();
  const {
    config: { hostedPageConfig }
  } = configData;
  const [html, setHtml] = useState<string>(hostedPageConfig.header.html ?? '');
  const [isHeaderEnabled, setIsHeaderEnabled] = useState<boolean>(
    hostedPageConfig.header.isEnabled
  );
  const { banner } = hostedPageConfig.header;
  const [crop, setCrop] = useState({
    x: banner.croppedDimensions.x,
    y: banner.croppedDimensions.y
  });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaDimensions, setCroppedAreaDimensions] = useState(
    banner.croppedDimensions
  );
  const [imageSrc, setImageSrc] = useState<string | null>(banner?.baseImageUrl);
  const [selectedBanner, setSelectedBanner] = useState<BannerSelections>(
    banner.selectedBanner || BannerSelections.IMAGE
  );
  const [isBannerEnabled, setIsBannerEnabled] = useState<boolean>(
    banner.isEnabled || false
  );

  const [addAlert] = useAlerts();

  const handleUpdate = (newHtml: string) => {
    const newConfig = { ...configData.config };
    newConfig.hostedPageConfig.header.html = newHtml ?? '';
    updateConfig(newConfig);
  };

  const handleEnableHeaderToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setIsHeaderEnabled(event.target.checked);
    const newConfig = { ...configData.config };
    newConfig.hostedPageConfig.header.isEnabled = event.target.checked;
    updateConfig(newConfig);
  };

  const { mutate: updateBannerImages, reset: resetUpdateBannerImages } =
    useUpdateBannerImages({
      givingFormId,
      organizationId,
      userId: user.id
    });

  const handleBannerImageUpdate = useCallback(
    async (_: Area, croppedAreaPixels: Area) => {
      // croppedAreaPixel has an x/y to specify an origin and a width/height to specify the size of the  cropped section
      setCroppedAreaDimensions(croppedAreaPixels);

      const haveUpdatedCroppedDimensions = !isEqual(
        configData.config.hostedPageConfig.header.banner.croppedDimensions,
        croppedAreaPixels
      );

      // Exit early croppedDimensions in draft are same as in memory aka image hasn't been updated
      if (haveUpdatedCroppedDimensions) {
        // Only update draft if there is an actual change in the cropped area OR if the user uploaded a new baseImage
        const updatedCroppedImage = await getCroppedImg(
          imageSrc,
          croppedAreaPixels
        );

        resetUpdateBannerImages();
        updateBannerImages(
          {
            bannerImages: {
              baseImage: imageSrc || '',
              croppedImage: updatedCroppedImage
            }
          },
          {
            onSuccess: (result) => {
              const newBaseImageSrc = result.replace(/-cropped/, '-base');

              configData.config.hostedPageConfig.header.banner.baseImageUrl =
                newBaseImageSrc;
              configData.config.hostedPageConfig.header.banner.croppedImageUrl =
                result;
              configData.config.hostedPageConfig.header.banner.croppedDimensions =
                croppedAreaPixels;
              updateConfig(configData.config);
            }
          }
        );
      }
    },
    [
      configData.config,
      imageSrc,
      resetUpdateBannerImages,
      updateBannerImages,
      updateConfig
    ]
  );

  const onCropComplete = useDebouncedCallback(handleBannerImageUpdate, 500);

  const readFile = (file: File) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });

  const handleFileUpload = async (file: File) => {
    try {
      if (file.size > MAXIMUM_FILE_SIZE) {
        addAlert({
          title: MAXIMUM_FILE_SIZE_EXCEEDED_MSG,
          severity: 'error'
        });
        return;
      }
      const imageDataUrl = await readFile(file);

      setImageSrc(imageDataUrl as string);
    } catch (err) {
      addAlert({
        title: 'There was an error loading your image.',
        severity: 'error'
      });
    }
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    handleFileUpload(e.target.files?.[0]);
  };

  const onDropFile = async (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileUpload(e.dataTransfer.files[0]);
  };
  const handleRemoveImage = () => {
    const newConfig = configData.config;
    newConfig.hostedPageConfig.header.banner.baseImageUrl = null;
    newConfig.hostedPageConfig.header.banner.croppedImageUrl = null;
    updateConfig(newConfig);
    setImageSrc(null);
  };
  const handlePreventDefault = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const renderBannerChild = () => {
    if (selectedBanner === BannerSelections.IMAGE && !imageSrc) {
      return (
        <div
          className={clsx('image-upload-container', {
            'disabled-overlay': !isHeaderEnabled
          })}
          onDragEnter={handlePreventDefault}
          onDragLeave={handlePreventDefault}
          onDragOver={handlePreventDefault}
          // I have to do this because I'm literally giving it the type as describe in the Spec, but TS still cries
          // eslint-disable-next-line
          // @ts-ignore
          onDrop={onDropFile}
        >
          <Text variant="h5"> Drag & Drop File </Text>
          <span className="or">or</span>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <Button className="upload-image-btn" component="label">
            Browse Files
            <input
              hidden
              type="file"
              onChange={onFileChange}
              accept="image/*"
            />
          </Button>
        </div>
      );
    }
    if (selectedBanner === BannerSelections.IMAGE) {
      return (
        <div
          className={clsx('cropper-overlay', {
            'disabled-overlay': !isHeaderEnabled
          })}
        >
          <Text variant="h7" className="cropper-caption">
            Drag photo to reposition
          </Text>
          <Box position="relative" className="cropper-container">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={16 / 3}
              initialCroppedAreaPixels={croppedAreaDimensions}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              style={{ containerStyle: { margin: '1rem' } }}
            />
          </Box>
          <Button
            className="remove-image-btn"
            variant="text"
            disabled={!isHeaderEnabled}
            onClick={handleRemoveImage}
          >
            Remove Image
          </Button>
        </div>
      );
    }
    if (selectedBanner === BannerSelections.COLOR) {
      return null;
    }
    return null;
  };
  return (
    <div className="element-library-edit-hosted-page-header">
      <div className="element-library-edit-hosted-page-header-title">
        <FormControlLabel
          className="enable-header-switch"
          control={
            <Switch
              checked={isHeaderEnabled}
              onChange={handleEnableHeaderToggle}
              inputProps={{ 'aria-label': 'enable header toggle switch' }}
            />
          }
          label="Enable Header"
        />
        <div className={isHeaderEnabled ? '' : 'disabled'}>
          <div className="disabled-overlay" />
          <Text variant="h5">Header</Text>
          <div className="WYSIWYG-container">
            <WYSIWYG
              onChange={(newHtml: string) => setHtml(newHtml ?? '')}
              value={html}
              onBlur={handleUpdate}
            />
          </div>
        </div>
      </div>
      <OptionalSection
        isEnabled={isHeaderEnabled}
        title="Banner"
        initiallySelected={isBannerEnabled}
        onChange={(e) => {
          const newConfig = configData.config;
          newConfig.hostedPageConfig.header.banner.isEnabled = e;
          updateConfig(newConfig);
          setIsBannerEnabled(e);
        }}
      />
      {isBannerEnabled ? (
        <>
          <RadioGroup
            value={selectedBanner}
            onChange={async (e) => {
              const newConfig = configData.config;
              newConfig.hostedPageConfig.header.banner.selectedBanner = e.target
                .value as BannerSelections;
              updateConfig(newConfig);
              setSelectedBanner(e.target.value as BannerSelections);
            }}
            row
            className="radio-group"
          >
            <FormControlLabel
              value={BannerSelections.IMAGE}
              control={
                <Radio checked={selectedBanner === BannerSelections.IMAGE} />
              }
              label={
                BannerSelections.IMAGE[0] +
                BannerSelections.IMAGE.slice(1).toLowerCase()
              }
              disabled={!isHeaderEnabled}
              className={clsx('radio-button', {
                'selected-radio': selectedBanner === BannerSelections.IMAGE
              })}
            />
          </RadioGroup>
          {renderBannerChild()}
        </>
      ) : null}
    </div>
  );
};
