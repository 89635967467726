import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Blocker, LimitedTextField } from 'components';
import Button from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import { OrganizationRole, useRenderIfRole } from 'hooks/useRenderIfRole';
import { ConfirmDesignationOverwrtieModal } from 'views/Designations/AdvancedDesignations';
import { DesignationsProps } from '../Designations';
import {
  DesignatedFundsSchema,
  IDesignatedFunds
} from './DesignatedFunds.schema';

export const DesignatedFunds = ({
  allDesignationsInOrg,
  allDesignationsIsLoading,
  checkFundIdIsUnique,
  confirmOverwriteModalIsOpen,
  createDesignationMutation,
  designationIdParam,
  idTooltip,
  renderButtonContent,
  setConfirmOverwriteModalIsOpen,
  setCurrentFundId,
  updateDesignationMutation,
  toggleDesignationStatus
}: DesignationsProps) => {
  const { renderIfRole } = useRenderIfRole();

  const [isActive, setIsActive] = useState<boolean>();

  const methods = useForm<IDesignatedFunds>({
    resolver: yupResolver(DesignatedFundsSchema),
    mode: 'onBlur'
  });

  useEffect(() => {
    if (!!designationIdParam && !allDesignationsIsLoading) {
      const {
        title,
        code,
        isActive: currentIsActive
      } = allDesignationsInOrg.find((des) => des.id === designationIdParam);
      // form state renders before designations are finished loading, resetting field values
      // to current values from db once loaded
      methods.setValue('title', title ?? '');
      methods.setValue('code', code ?? '');
      setCurrentFundId(code);
      setIsActive(currentIsActive);
    }
  }, [allDesignationsInOrg, designationIdParam, allDesignationsIsLoading]);

  const fundId = methods.watch('code');

  const handleFormSubmit = (formData: IDesignatedFunds) => {
    if (designationIdParam) {
      // if we have an id param, hit the put endpoint
      updateDesignationMutation(formData);
    } else {
      // otherwise, we are creating a new designation, hit the post endpoint
      createDesignationMutation(formData);
    }
  };

  const handleSubmitOnClick = () => {
    const fundIdIsUnique = checkFundIdIsUnique(fundId);
    if (fundIdIsUnique) {
      methods.handleSubmit((formData) => handleFormSubmit(formData))();
    } else {
      setConfirmOverwriteModalIsOpen(true);
    }
  };

  const handleOverwriteOnConfirm = () => {
    setConfirmOverwriteModalIsOpen(false);

    methods.handleSubmit((formData) => handleFormSubmit(formData))();
  };

  const handleToggleStatus = () => {
    toggleDesignationStatus(!isActive);
  };

  return (
    <div className="designated-funds-form">
      <Blocker block={designationIdParam && allDesignationsIsLoading}>
        <FormProvider {...methods}>
          <form
            className="designations-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitOnClick();
            }}
          >
            <div className="designations-form-row">
              <Text
                className="designations-form-label center-label"
                variant="h3"
              >
                My designation is titled
              </Text>
              <Controller
                control={methods.control}
                defaultValue=""
                name="title"
                render={({
                  field: { ref, ...field },
                  fieldState: { error }
                }) => (
                  <LimitedTextField
                    {...field}
                    className="designations-form-field"
                    error={!!error}
                    helperText={error?.message ?? null}
                    hiddenLabel
                    maxChar={60}
                    placeholder="Enter Designation Title"
                  />
                )}
              />
            </div>
            <div className="designations-form-row">
              <div className="designations-form-label center-label">
                <Tooltip title={idTooltip} placement="top-start">
                  <div>
                    <Icon icon={ICONS.HELP} color="disabled" />
                  </div>
                </Tooltip>
                <Text variant="h3">My designation fund ID is</Text>
              </div>
              <Controller
                control={methods.control}
                defaultValue=""
                name="code"
                render={({
                  field: { ref, ...field },
                  fieldState: { error }
                }) => (
                  <LimitedTextField
                    {...field}
                    className="designations-form-field"
                    error={!!error}
                    helperText={error?.message ?? null}
                    hiddenLabel
                    maxChar={60}
                    placeholder="Enter Designation Fund ID"
                  />
                )}
              />
            </div>
            <div className="designations-submit-button-container">
              {renderIfRole(
                <Button
                  fullWidth
                  type="submit"
                  disabled={!methods.formState.isValid}
                >
                  {renderButtonContent()}
                </Button>,
                OrganizationRole.Editor
              )}
              {renderIfRole(
                <Button
                  fullWidth
                  type="button"
                  variant="secondary"
                  onClick={() => handleToggleStatus()}
                >
                  {isActive ? 'Archive Designation' : 'Make Designation Active'}
                </Button>,
                OrganizationRole.Editor
              )}
            </div>
          </form>
        </FormProvider>
      </Blocker>

      {confirmOverwriteModalIsOpen && (
        <ConfirmDesignationOverwrtieModal
          fundId={fundId}
          isOpen={confirmOverwriteModalIsOpen}
          onClose={() => setConfirmOverwriteModalIsOpen(false)}
          onConfirm={() => handleOverwriteOnConfirm()}
        />
      )}
    </div>
  );
};
