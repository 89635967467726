/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, DragEvent, useState } from 'react';
import {
  MAXIMUM_FILE_SIZE,
  MAXIMUM_FILE_SIZE_EXCEEDED_MSG
} from 'constants/givingFormContants';
import { useParams } from 'react-router-dom';
import { Blocker, ICONS } from 'components';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useDeleteImage, useSaveImage } from 'queries/UseGivingForms';
import './ThankYouGiftImageManager.scss';

const GIFT_IMAGE_PREFIX = 'gift-image';

export interface ThankYouGiftImageManagerProps {
  image: string;
  setImage: (image: string) => void;
}

const ThankYouGiftImageManager = ({
  image,
  setImage
}: ThankYouGiftImageManagerProps): JSX.Element => {
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [addAlert] = useAlerts();
  const { givingFormId } = useParams();
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();

  const { mutate: saveImageMutation } = useSaveImage();
  const { mutate: deleteImageMutation } = useDeleteImage();

  const handlePreventDefault = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const readFile = (file: File) =>
    new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => resolve(reader.result as string),
        false
      );
      reader.readAsDataURL(file);
    });

  const saveImage = async (imageDataUrl: string) => {
    // Save image to CDN
    setIsWorking(true);

    saveImageMutation(
      {
        image: imageDataUrl,
        prefix: GIFT_IMAGE_PREFIX,
        givingFormId,
        organizationId
      },
      {
        onSuccess: (result) => {
          setImage(result);
        },
        onError: () => {
          addAlert({
            title: 'There was an error uploading your image.',
            severity: 'error'
          });
        },
        onSettled: () => {
          setIsWorking(false);
        }
      }
    );
  };

  const handleFileUpload = async (file?: File) => {
    try {
      if (file) {
        if (file.size > MAXIMUM_FILE_SIZE) {
          addAlert({
            title: MAXIMUM_FILE_SIZE_EXCEEDED_MSG,
            severity: 'error'
          });
          return;
        }

        const imageDataUrl = await readFile(file);
        saveImage(imageDataUrl);
      }
    } catch (err) {
      addAlert({
        title: 'There was an error loading your image.',
        severity: 'error'
      });
    }
  };

  const onDropFile = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileUpload(e.dataTransfer.files[0]);
  };

  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    handleFileUpload(e.target.files?.[0]);
  };

  const onImageDeleted = (src: string) => {
    setIsWorking(true);

    // Parse guid from URL
    const end = src.split(`${GIFT_IMAGE_PREFIX}-`)[1];
    const parsedImageId = end.split('.')[0];

    // Delete image from CDN
    deleteImageMutation(
      {
        imageId: parsedImageId,
        prefix: GIFT_IMAGE_PREFIX,
        givingFormId,
        organizationId
      },
      {
        onSettled: () => {
          setImage('');
          setIsWorking(false);
        }
      }
    );
  };

  return (
    <Blocker className="thank-you-gift-image-blocker" block={isWorking}>
      <Text variant="caption">
        Add an image of the gift the donor will receive.
      </Text>
      {image ? (
        <div className="thank-you-gift-image">
          <div className="image-wrapper">
            <img src={image} alt="" />
            <IconButton
              className="delete"
              label="delete"
              variant="basic"
              size="small"
              icon={ICONS.TRASH}
              onClick={() => onImageDeleted(image)}
            />
          </div>
        </div>
      ) : (
        <div
          className="image-upload-container"
          onDragEnter={handlePreventDefault}
          onDragLeave={handlePreventDefault}
          onDragOver={handlePreventDefault}
          onDrop={onDropFile}
        >
          <Text variant="h5"> Drag & Drop File </Text>
          <span className="or">or</span>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <Button className="upload-image-btn" component="label">
            Browse File
            <input
              hidden
              type="file"
              onChange={onFileSelected}
              accept="image/*"
            />
          </Button>
        </div>
      )}
    </Blocker>
  );
};

export default ThankYouGiftImageManager;
