import { AlertInfo } from 'hooks';
import { IOrganizationFeatures } from 'services';
import {
  AllPaymentOptions,
  BlockTypes,
  IGivingFormConfig,
  IPaymentInfoSection,
  IThankYouAccountInvitationBlock
} from 'types';

type FilterPremiumFeaturesResult = {
  updatedConfig: IGivingFormConfig;
  hasChanged: boolean;
};
export const filterPremiumFeatures = (
  config: IGivingFormConfig,
  features: IOrganizationFeatures,
  acceptedPaymentOptions: string[],
  addAlert: (alert: AlertInfo) => void,
  donorSSP: string
): FilterPremiumFeaturesResult => {
  const updatedConfig = config as IGivingFormConfig;

  const scrubFeatureToggle = (
    feature: boolean,
    label: string,
    testForFeatureCallback: () => boolean,
    removeFeatureCallback: () => void
  ): boolean => {
    if (!feature && testForFeatureCallback()) {
      removeFeatureCallback();
      addAlert({
        title: `${label} is not enabled for your organization.`,
        severity: 'warning'
      });
      return true;
    }
    return false;
  };

  const testForPaymentMethod = (method: AllPaymentOptions) => {
    const paymentSectionBlock = updatedConfig.blocks.find(
      (block) => block.blockType === 'PaymentSection'
    ) as IPaymentInfoSection;
    if (!paymentSectionBlock) {
      return false;
    }
    return paymentSectionBlock.paymentOptionsBlock.paymentOptions.includes(
      method
    );
  };
  const removePaymentMethod = (method: AllPaymentOptions) => {
    const paymentSectionBlock = updatedConfig.blocks.find(
      (block) => block.blockType === 'PaymentSection'
    ) as IPaymentInfoSection;
    if (paymentSectionBlock) {
      paymentSectionBlock.paymentOptionsBlock.paymentOptions =
        paymentSectionBlock.paymentOptionsBlock.paymentOptions.filter(
          (option) => option !== method
        );
    }
  };

  // Paypal
  const testForPaypal = () => testForPaymentMethod(AllPaymentOptions.PAYPAL);
  const removePaypal = () => removePaymentMethod(AllPaymentOptions.PAYPAL);

  // Apple Pay
  const testForApplePay = () =>
    testForPaymentMethod(AllPaymentOptions.APPLEPAY);
  const removeApplePay = () => removePaymentMethod(AllPaymentOptions.APPLEPAY);

  // Google Pay
  const testForGooglePay = () =>
    testForPaymentMethod(AllPaymentOptions.GOOGLEPAY);
  const removeGooglePay = () =>
    removePaymentMethod(AllPaymentOptions.GOOGLEPAY);

  // Account Invitation Branded DSS
  const testForDssb = () => {
    const accountInvitationBlock = updatedConfig.thankYouConfig.blocks.find(
      (block) => block.blockType === BlockTypes.ThankYouAccountInvitationBlock
    ) as IThankYouAccountInvitationBlock;
    if (accountInvitationBlock) {
      return accountInvitationBlock.accountInvitationUrl === donorSSP;
    }
    return false;
  };
  const removeDssb = () => {
    const accountInvitationBlock = updatedConfig.thankYouConfig.blocks.find(
      (block) => block.blockType === BlockTypes.ThankYouAccountInvitationBlock
    ) as IThankYouAccountInvitationBlock;
    if (accountInvitationBlock) {
      accountInvitationBlock.accountInvitationUrl = '';
    }
  };

  // Hosted Pages
  // TODO: CH - Add these function bodies once hosted pages are added
  const testForHostedPage = () => false; // !!updatedConfig?.hostedPageConfig;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const removeHostedPage = () => {}; // delete updatedConfig?.hostedPageConfig;

  // Corporate Matching
  const testForCorporateMatching = () => {
    const givingFormCorporateMatching = !!updatedConfig.blocks.find(
      (block) => block.blockType === BlockTypes.CorporateMatchingBlock
    );
    const thankYouCorporateMatching =
      !!updatedConfig.thankYouConfig.blocks.find(
        (block) => block.blockType === BlockTypes.ThankYouCorporateMatchingBlock
      );

    return givingFormCorporateMatching || thankYouCorporateMatching;
  };

  const removeCorporateMatching = () => {
    updatedConfig.blocks = updatedConfig.blocks.filter(
      (block) => block.blockType !== BlockTypes.CorporateMatchingBlock
    );
    updatedConfig.thankYouConfig.blocks =
      updatedConfig.thankYouConfig.blocks.filter(
        (block) => block.blockType !== BlockTypes.ThankYouCorporateMatchingBlock
      );
  };

  // Custom CSS
  const testForCustomCss = () => !!updatedConfig?.customCss;
  const removeCustomCss = () => delete updatedConfig?.customCss;

  // Tributes
  const testForTributes = () =>
    !!updatedConfig.blocks.find(
      (block) => block.blockType === BlockTypes.TributeBlock
    );

  const removeTributes = () => {
    updatedConfig.blocks = updatedConfig.blocks.filter(
      (block) => block.blockType !== BlockTypes.TributeBlock
    );
  };

  const scrubbedBools = [
    scrubFeatureToggle(
      acceptedPaymentOptions.includes('paypal'),
      'Paypal',
      testForPaypal,
      removePaypal
    ),
    scrubFeatureToggle(
      features.applePay,
      'Apple Pay',
      testForApplePay,
      removeApplePay
    ),
    scrubFeatureToggle(
      features.googlePay,
      'Google Pay',
      testForGooglePay,
      removeGooglePay
    ),
    scrubFeatureToggle(
      features.donorSelfServeBranding,
      'Custom DSS Branding',
      testForDssb,
      removeDssb
    ),
    scrubFeatureToggle(
      features.hostedPages,
      'Hosted Pages',
      testForHostedPage,
      removeHostedPage
    ),
    scrubFeatureToggle(
      features.corporateMatching,
      'Corporate Matching',
      testForCorporateMatching,
      removeCorporateMatching
    ),
    scrubFeatureToggle(
      features.customCss,
      'Custom CSS',
      testForCustomCss,
      removeCustomCss
    ),
    scrubFeatureToggle(
      features.tributes,
      'Tributes',
      testForTributes,
      removeTributes
    )
  ];

  // Checking if there is a 'true' value in the array, meaning there WAS a feature that was scrubbed
  // Equivalent to .reduce((result, curr) => curr || result, false);
  // Equivalent to .some(bool => bool === true)
  const hasChanged = scrubbedBools.some((bool) => bool);

  return { updatedConfig, hasChanged };
};
