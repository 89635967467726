import parse from 'html-react-parser';
import Dialog, { DialogActionButton, DialogContent } from 'components/Dialog';
import './ReceiptPreviewModal.scss';

type ReceiptPreviewModalProps = {
  receiptHtml: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ReceiptPreviewModal = ({
  receiptHtml,
  isOpen,
  onClose
}: ReceiptPreviewModalProps) => (
  <Dialog
    className="receipt-preview-modal"
    label="Receipt Preview"
    open={isOpen}
    onClose={() => onClose()}
  >
    <DialogActionButton
      className="exit-preview-button"
      onClick={(close) => close()}
      variant="secondary"
    >
      Exit Preview
    </DialogActionButton>
    <DialogContent>{parse(receiptHtml)}</DialogContent>
  </Dialog>
);
