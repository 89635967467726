import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { AutocompleteElementProps } from 'react-hook-form-mui';
import ComboBox from 'components/ComboBox';
import Icon, { ICONS } from 'components/Icon';

const ComboBoxElement = <TFieldValues extends FieldValues, TOption>({
  autocompleteProps,
  name,
  control,
  options,
  loading,
  rules,
  required,
  multiple
}: AutocompleteElementProps<TFieldValues, TOption, false, false>) => {
  const validationRules: ControllerProps['rules'] = {
    ...rules,
    ...(required && {
      required: rules?.required || 'This field is required'
    })
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={validationRules}
      render={({ field: { onChange, onBlur, ref, ...field } }) => (
        <ComboBox
          {...autocompleteProps}
          {...field}
          loading={loading}
          multiple={multiple}
          options={options}
          getOptionLabel={autocompleteProps?.getOptionLabel}
          disableCloseOnSelect={
            typeof autocompleteProps?.disableCloseOnSelect === 'boolean'
              ? autocompleteProps.disableCloseOnSelect
              : !!multiple
          }
          isOptionEqualToValue={autocompleteProps?.isOptionEqualToValue}
          onChange={(event, value, reason, details) => {
            onChange(value);
            if (autocompleteProps?.onChange) {
              autocompleteProps.onChange(event, value, reason, details);
            }
          }}
          popupIcon={<Icon icon={ICONS.CHEVRON_DOWN} />}
          onBlur={(event) => {
            onBlur();
            if (typeof autocompleteProps?.onBlur === 'function') {
              autocompleteProps.onBlur(event);
            }
          }}
        />
      )}
    />
  );
};

export default ComboBoxElement;
