import React from 'react';
import { Box } from '@mui/material';
import './ContentContainer.scss';

type Props = {
  children: React.ReactNode;
};

const ContentContainer = ({ children }: Props): JSX.Element => (
  <Box id="app-content-container">{children}</Box>
);

export default ContentContainer;
