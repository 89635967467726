import { ReactNode, forwardRef } from 'react';
import { Chip } from '@mui/material';
import { Link } from 'react-router-dom';

interface NavLinkWithChipProps {
  to: string;
  children: ReactNode;
  [propName: string]: unknown;
}

// Normally a forwardRef component wouldn't be wrapped by a function,
// but in this case a boolean needed to be passed in.
export const NavLinkWithChip = (isFeatureEnabled: boolean) =>
  forwardRef<unknown, NavLinkWithChipProps>(
    (
      { to, children, ...props }: NavLinkWithChipProps,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _ref
    ) => (
      <div className="AppHeader-LinkContainer">
        <Link to={to} {...props}>
          {children}
        </Link>
        {isFeatureEnabled !== undefined && !isFeatureEnabled && (
          <Chip label="PRO" className="AppHeader-Chip" />
        )}
      </div>
    )
  );
