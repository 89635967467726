/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */

export const getBaseSite = (url: string): string => {
  return url
    ? url
        .replace(
          /^((\w+):\/\/)?((\w+):?(\w+)?@)?([^\/\?:]+)(:?(\d+)?)(\/?[^\?#]+)?\??([^#]+)?#?(\w*)/,
          '$1$6$7'
        )
        .toLowerCase()
    : undefined;
};

export const isCrossDomain = (urlA: string, urlB: string): boolean => {
  return getBaseSite(urlA) !== getBaseSite(urlB);
};

export const isAbsolutePath = (path: string): boolean => {
  return !!path.match(/^(?:http|https):/);
};

export const getAbsolutePath = (path: string, relativeTo: string): string => {
  if (path.startsWith('#')) {
    return relativeTo.match(/^[^\#]+/)[0] + path;
  }

  if (isAbsolutePath(path)) {
    return path;
  }

  if (path.startsWith('/')) {
    return getBaseSite(relativeTo) + path;
  }

  const stack = relativeTo.split('/');
  const parts = path.split('/');
  stack.pop();

  parts.forEach((part) => {
    if (part === '..') {
      stack.pop();
    } else {
      stack.push(part);
    }
  });

  return stack.join('/');
};

export const getRelativePath = (path: string, relativeTo: string): string => {
  const match = path.match(new RegExp(`^(?:${relativeTo})(?:[\/])?(.*)$`, 'i'));
  return match ? match[1] : path;
};

export const removePathQueryAndFragment = (path: string): string => {
  const match = path.match(/^([^\?\#]+)?(?:[\?\#].*)?$/);
  return match ? match[1] || '' : path;
};

export const removePathFragment = (path: string): string => {
  const match = path.match(/^([^\#]+)?(?:[\#].*)?$/);
  return match ? match[1] || '' : path;
};

export const getPathQueryAndFragment = (path: string): string => {
  const match = path.match(/([\?\#].+)$/);
  return match ? match[1] : '';
};

export const getPathQuery = (path: string): string => {
  if (!path) {
    return '';
  }
  const match = path.match(/([\?][^\#]+)/);
  return match ? match[1] : '';
};

export const getPathFragment = (path: string): string => {
  const match = path.match(/([\#].+)$/);
  return match ? match[1] : '';
};

export const getPathParams = (path: string): any => {
  const result: any = {};

  const queryString = getPathQuery(path);
  if (queryString) {
    queryString
      .substring(1)
      .split('&')
      .forEach((n: any) => {
        n = n.split('=');
        // eslint-disable-next-line prefer-destructuring
        result[n[0]] = n[1];
      });
  }

  return result;
};

export const buildPathQuery = (params: any): string => {
  return params && Object.keys(params).length
    ? `?${Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&')}`
    : '';
};
