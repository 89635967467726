import { AxiosError } from 'axios';
import axios from 'services/axiosClient';
import { AbTest, GivingFormConfigVariant } from 'types';
import {
  AbTestNotesDeleteBody,
  AbTestNotesPatchBody,
  AbTestNotesPostBody,
  AbTestPostBody,
  AbTestUniqueNamePostBody,
  IGetAbTest
} from './types';

export const getAbTestByOrganization = async (
  organizationId: string,
  includeMetrics = false
): Promise<AbTest[]> => {
  try {
    const { data } = await axios.get(`/abTests`, {
      params: {
        organizationId,
        includeMetrics
      }
    });

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error with A/B Test Service'
    );
  }
};

export const getAbTestService = async ({
  organizationId,
  abTestId,
  includeMetrics = false
}: IGetAbTest): Promise<AbTest> => {
  try {
    const { data } = await axios.get(`/abTests/${abTestId}`, {
      params: {
        organizationId,
        includeMetrics
      }
    });
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error with A/B Test Service'
    );
  }
};

export const validateAbTestNameService = async ({
  organizationId,
  name,
  campaignId
}: AbTestUniqueNamePostBody) => {
  try {
    const { data } = await axios.post('/abTests/isNameUnique', {
      name,
      organizationId,
      campaignId
    });
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error with non-unique A/B test name'
    );
  }
};

export const createAbTestService = async (
  organizationId: string,
  abTest: AbTestPostBody
) => {
  const { data } = await axios.post(`/abTests`, abTest, {
    params: {
      organizationId
    }
  });
  return data;
};

export const endAbTestService = async (
  organizationId: string,
  abTestId: string,
  givingFormConfigVariant: GivingFormConfigVariant
) => {
  const { data } = await axios.post(
    `/abTests/end`,
    {
      abTestId,
      givingFormConfigVariant
    },
    {
      params: {
        organizationId
      }
    }
  );
  return data;
};

export const publishAbTestNotesService = async ({
  content,
  userId,
  abTestId,
  organizationId
}: AbTestNotesPostBody): Promise<AbTest> => {
  try {
    const { data } = await axios.post(
      `/abTests/notes`,
      {
        content,
        userId,
        abTestId
      },
      {
        params: {
          organizationId
        }
      }
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error with A/B Tests Notes'
    );
  }
};

export const patchAbTestNoteService = async ({
  content,
  organizationId,
  noteId
}: AbTestNotesPatchBody) => {
  const { data } = await axios.patch(
    `/abTests/notes?organizationId=${organizationId}`,
    {
      content,
      noteId
    }
  );
  return data;
};

export const deleteAbTestNoteService = async ({
  organizationId,
  noteId
}: AbTestNotesDeleteBody) => {
  const { data } = await axios.delete(
    `/abTests/notes/${noteId}?organizationId=${organizationId}`
  );
  return data;
};
