import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BreadcrumbsProps, ButtonProps, MenuItemProps } from 'components';
import Icon, { ICONS } from 'components/Icon';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import NewGivingFormDialog from 'components/gms/NewGivingFormDialog';
import { useAppContext } from 'hooks/useAppContext';
import { useCampaignById } from 'queries/UseCampaigns';
import { Templates } from './Templates';

export const GivingForms = () => {
  const { campaignId } = useParams();
  const [showNewGivingFormDialog, setShowNewGivingFormDialog] = useState(false);
  const { selectedOrganization } = useAppContext();
  const { data: campaignData } = useCampaignById(
    selectedOrganization.id,
    campaignId
  );
  const [activeTab, setActiveTab] = useState<string>('globalTemplates');
  const breadcrumbsProps: BreadcrumbsProps = {
    breadcrumbs: [
      {
        label: 'All Campaigns',
        path: '/campaigns'
      },
      {
        label: campaignData?.title || 'Loading...',
        path: `/campaigns/${campaignId}`
      },
      {
        label: 'New Giving Form',
        path: `/campaigns/${campaignId}/giving-forms`
      }
    ]
  };
  const handleStartFromScratch = () => {
    setShowNewGivingFormDialog(true);
  };
  const primaryButtonProps: ButtonProps = {
    children: 'Start From Scratch',
    onClick: handleStartFromScratch,
    startIcon: <Icon icon={ICONS.PLUS} />
  };

  const tabProps = [
    {
      label: "iDonate's Templates",
      value: 'globalTemplates'
    },
    {
      label: 'My Templates',
      value: 'orgTemplates'
    }
  ];

  const featureMenuItems: MenuItemProps[] = [];

  return (
    <>
      <FeatureHeader
        titleProps="Giving Form Templates"
        breadcrumbsProps={breadcrumbsProps}
        primaryButtonProps={primaryButtonProps}
        featureMenuItems={featureMenuItems}
        tabsProps={tabProps}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <Templates activeTab={activeTab} />
      <NewGivingFormDialog
        open={showNewGivingFormDialog}
        onClose={() => setShowNewGivingFormDialog(false)}
        templateId=""
      />
    </>
  );
};
