import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import Text from '../Text';

/**
 * Base TextField component that renders an MUI TextField component.
 * Wraps your label in an h5 if a string is received.
 * To read more about what props this component accepts, visit https://mui.com/components/text-fields/
 */
const TextField = ({ label, ...props }: TextFieldProps): JSX.Element => {
  const computedLabel =
    typeof label === 'string' ? <Text variant="h5">{label}</Text> : label;
  return <MuiTextField label={computedLabel} {...props} />;
};

export default TextField;
