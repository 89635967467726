import { useMutation, useQueryClient } from 'react-query';
import { archiveP2PService } from 'services/p2pService';

export const useArchiveP2P = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      p2pId,
      name,
      slug,
      startDate,
      isArchived
    }: {
      p2pId: string;
      name: string;
      slug: string;
      startDate: string;
      isArchived: boolean;
    }) =>
      archiveP2PService({
        p2pId,
        name,
        slug,
        startDate,
        isArchived,
        organizationId
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('campaignPeerToPeers');
      }
    }
  );
};
