import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Blocker, EmptyStateTextDisplay } from 'components';
import Button from 'components/Button';
import Text from 'components/Text';
import { GivingFormPreviewCard } from 'components/gms/ChannelPreviewCards';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import { NewAbTestDialog } from 'components/gms/NewAbTestDialog';
import { UnpublishedChangesModal } from 'components/gms/UnpublishedChangesModal';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useGetAllAbTests } from 'queries/UseAbTests';
import { useOrgGivingForms } from 'queries/UseOrganizationData';
import editsRepositoryApi from 'services/editsRepositoryService';
import { CampaignGivingForm } from 'services/types/campaignTypes';
import { OrgCampaignsWithGivingForms } from 'services/types/organizationTypes';
import {
  formatIsoDate,
  sortAlphabetically,
  sortGivingFormsByDate
} from 'utils';
import './AbTestGivingForms.scss';

interface GivingFormsGroupedByCampaign extends OrgCampaignsWithGivingForms {
  givingForms: CampaignGivingForm[];
}

export const AbTestGivingForms = () => {
  const navigate = useNavigate();
  const [allGivingFormEdits, setAllGivingFormEdits] = useState([]);
  const [activeAbTests, setActiveAbTests] = useState([]);
  const [givingFormsGroupedByCampaign, setGivingFormsGroupedByCampaign] =
    useState<GivingFormsGroupedByCampaign[]>([]);
  const [threeMostRecentGivingForms, setThreeMostRecentGivingForms] = useState<
    CampaignGivingForm[]
  >([]);
  const [unpubChangesModalOpen, setUnpubChangesModalOpen] = useState(false);
  const [currentGivingForm, setCurrentGivingForm] = useState<{
    campaignId: string;
    givingFormId: string;
  }>({ campaignId: null, givingFormId: null });
  const [showNewAbTestDialog, setShowNewAbTestDialog] = useState(false);
  const { selectedOrganization } = useAppContext();
  const [pushAlert] = useAlerts();

  const mapGivingFormsToCampaigns = ({
    campaigns,
    givingForms
  }: {
    campaigns: OrgCampaignsWithGivingForms[];
    givingForms: CampaignGivingForm[];
  }) => {
    const mappedCampaigns = campaigns.map((campaign) => {
      const campaignGfs = givingForms.filter(
        (form) => form.campaignId === campaign.campaignId
      );
      campaignGfs.sort(sortAlphabetically);
      return {
        ...campaign,
        givingForms: campaignGfs
      };
    });
    return mappedCampaigns.sort(sortAlphabetically);
  };
  const {
    isLoading: isLoadingGivingForms,
    isError,
    isRefetching: isRefetchingGivingForms
  } = useOrgGivingForms(selectedOrganization.id, {
    onSuccess: (orgData) => {
      setThreeMostRecentGivingForms(
        orgData.orgGivingForms.sort(sortGivingFormsByDate).slice(0, 3)
      );
      setGivingFormsGroupedByCampaign(
        mapGivingFormsToCampaigns({
          campaigns: orgData.orgCampaignsWithGivingForms,
          givingForms: orgData.orgGivingForms
        })
      );
    },
    onError: () => {
      pushAlert({
        title:
          'Uh oh. Looks like there was an error loading your giving forms.',
        severity: 'error'
      });
    },
    refetchOnMount: 'always'
  });

  const getAllGivingFormEditsIds = async () => {
    try {
      const allGivingFormEditsIds = await editsRepositoryApi.getAllEdits(
        'givingForm'
      );
      setAllGivingFormEdits(allGivingFormEditsIds);
    } catch (e) {
      pushAlert({
        title: 'There was an error loading your local edits.',
        severity: 'error'
      });
    }
  };

  const { isLoading: isLoadingAbTests, isRefetching: isRefetchingAbTests } =
    useGetAllAbTests(
      { organizationId: selectedOrganization.id, includeMetrics: true },
      {
        onError: () => {
          pushAlert({
            title:
              'Uh oh. Looks like there was an error loading your A/B tests.',
            severity: 'error'
          });
        },
        onSuccess: (data) => {
          setActiveAbTests(data.filter((test) => !test.endedAt));
        },
        refetchOnMount: 'always'
      }
    );

  useEffect(() => {
    getAllGivingFormEditsIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTestThisFormClick = (
    unpubChanges: boolean,
    campaignId: string,
    givingFormId: string
  ) => {
    setCurrentGivingForm({ campaignId, givingFormId });
    if (unpubChanges) {
      setUnpubChangesModalOpen(true);
    } else {
      setShowNewAbTestDialog(true);
    }
  };

  const onReturnToEditorClick = (campaignId: string, givingFormId: string) => {
    navigate(`/campaigns/${campaignId}/giving-forms/${givingFormId}/edit`);
  };

  const breadcrumbs = [
    {
      label: 'Strategy',
      path: '/strategy'
    },
    {
      label: 'A/B Tests',
      path: '/strategy/ab-test'
    },
    {
      label: 'Create A/B Test',
      path: '/strategy/ab-test/giving-forms'
    }
  ];

  return (
    <>
      <Blocker
        block={
          isLoadingGivingForms ||
          isRefetchingGivingForms ||
          isLoadingAbTests ||
          isRefetchingAbTests
        }
      >
        <FeatureHeader
          breadcrumbsProps={{ breadcrumbs }}
          titleProps="Select Giving Form to A/B Test"
        />
        <div className="select-gf-ab-test fluid-container">
          {!isLoadingGivingForms &&
            !isRefetchingGivingForms &&
            !givingFormsGroupedByCampaign.length && (
              <div className="empty-state-container">
                <EmptyStateTextDisplay
                  heading="You don’t currently have giving forms to test."
                  subheading="Start by creating and publishing a new giving form in one of your campaigns."
                />
                <Button
                  className="view-campaigns-button"
                  onClick={() => navigate('/campaigns')}
                >
                  View Campaigns
                </Button>
              </div>
            )}
          {!isError && !!givingFormsGroupedByCampaign.length && (
            <>
              {unpubChangesModalOpen && (
                <UnpublishedChangesModal
                  open={unpubChangesModalOpen}
                  onClose={() => setUnpubChangesModalOpen(false)}
                  onReturnToEditor={() =>
                    onReturnToEditorClick(
                      currentGivingForm.campaignId,
                      currentGivingForm.givingFormId
                    )
                  }
                />
              )}
              <Text className="section-header" variant="h3">
                Recently Published
              </Text>
              <div className="giving-form-container">
                {threeMostRecentGivingForms.map((givingForm) => {
                  const hasUnpublishedChanges = allGivingFormEdits.find(
                    ({ id }) => id === givingForm.id
                  );

                  const isAbTest = activeAbTests.find(
                    (abTest) => abTest.givingForm.id === givingForm.id
                  );
                  return (
                    <GivingFormPreviewCard
                      key={givingForm.id}
                      givingFormName={givingForm.name}
                      givingFormId={givingForm.id}
                      createdDate={formatIsoDate(
                        givingForm.createdAt,
                        'MM/dd/yyyy'
                      )}
                      amountRaised={givingForm.amountRaised}
                      donors={givingForm.donorCount}
                      donationCount={givingForm.donationCount}
                      visits={givingForm.visitCount}
                      marginOnEven
                      unpublishedChanges={hasUnpublishedChanges}
                      isAbTest={isAbTest}
                      parentComp="abTestParentComp"
                      onTestThisFormClick={() =>
                        onTestThisFormClick(
                          hasUnpublishedChanges,
                          givingForm.campaignId,
                          givingForm.id
                        )
                      }
                    />
                  );
                })}
              </div>
              {givingFormsGroupedByCampaign.map((campaign) => (
                <div
                  key={campaign.campaignId}
                  className="campaign-row-container"
                >
                  <Text className="section-header" variant="h3">
                    {campaign.name}
                  </Text>
                  <div className="giving-form-container">
                    {campaign.givingForms.map((givingForm) => {
                      const hasUnpublishedChanges = allGivingFormEdits.find(
                        ({ id }) => id === givingForm.id
                      );

                      const isAbTest = activeAbTests.find(
                        (abTest) => abTest.givingForm.id === givingForm.id
                      );
                      return (
                        <GivingFormPreviewCard
                          key={givingForm.id}
                          givingFormName={givingForm.name}
                          givingFormId={givingForm.id}
                          createdDate={formatIsoDate(
                            givingForm.createdAt,
                            'MM/dd/yyyy'
                          )}
                          amountRaised={givingForm.amountRaised}
                          donors={givingForm.donorCount}
                          donationCount={givingForm.donationCount}
                          visits={givingForm.visitCount}
                          marginOnEven={false}
                          unpublishedChanges={hasUnpublishedChanges}
                          isAbTest={isAbTest}
                          parentComp="abTestParentComp"
                          onTestThisFormClick={() =>
                            onTestThisFormClick(
                              hasUnpublishedChanges,
                              givingForm.campaignId,
                              givingForm.id
                            )
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </Blocker>
      <NewAbTestDialog
        open={showNewAbTestDialog}
        onClose={() => setShowNewAbTestDialog(false)}
        givingFormId={currentGivingForm.givingFormId}
        campaignId={currentGivingForm.campaignId}
      />
    </>
  );
};
