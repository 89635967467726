import { useContext } from 'react';
import { AppContext } from 'Providers/AppProvider';

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('App context must be used within the AppProvider');
  }
  return context;
};
