import { useState } from 'react';
import {
  CircularProgress,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { useCheckHostedPageSlug } from 'queries/UseGivingForms';
import {
  BannerSelections,
  HostedPageBlockTypes,
  HostedPageLayout,
  IGivingFormConfig,
  IHostedPageFooterBlock,
  IHostedPageHeaderBlock
} from 'types';
import './HostedPageSlugModal.scss';

const DEFAULT_HEADER_BLOCK: IHostedPageHeaderBlock = {
  id: 'header',
  blockType: HostedPageBlockTypes.HostedPageHeaderBlock,
  html: "<div><h1 style='text-align: center;'><span style='font-size: 72px;'>Header text</span></h1></div>",
  isEnabled: false,
  banner: {
    isEnabled: false,
    selectedBanner: BannerSelections.IMAGE,
    croppedDimensions: { x: 0, y: 0, width: 0, height: 0 }
  }
};

const DEFAULT_FOOTER_BLOCK = {
  id: 'footer',
  blockType: 'HostedPageFooterBlock',
  html: '',
  isEnabled: false
};

export interface HostedPageSlugModalProps {
  openSlugModal: boolean;
  onClose: () => void;
  selectedLayout: HostedPageLayout;
  pageFavicon?: string;
  pageTitle?: string;
}

export const HostedPageSlugModal = ({
  openSlugModal,
  onClose,
  selectedLayout,
  pageFavicon,
  pageTitle
}: HostedPageSlugModalProps) => {
  const { envConfig } = useAppContext();
  const { givingFormId } = useParams();
  const [slug, setSlug] = useState<string>('');
  const [isValidSlug, setIsValidSlug] = useState<boolean>(undefined);
  const [isUniqueSlug, setIsUniqueSlug] = useState<boolean>(undefined);
  const {
    selectedOrganization: { id: selectedOrganizationId }
  } = useAppContext();

  const { mutateAsync: checkHostedPageName, isLoading: isCheckingValidity } =
    useCheckHostedPageSlug({
      organizationId: selectedOrganizationId,
      slug,
      givingFormId
    });

  const {
    configData: { config },
    updateConfig
  } = useConfigContext<IGivingFormConfig>();

  const onStartBuildingHandler = async () => {
    const isUnique = await checkHostedPageName();
    setIsUniqueSlug(isUnique);
    if (isUnique) {
      const newConfig = { ...(config as IGivingFormConfig) };
      newConfig.hostedPageConfig = {
        layout: selectedLayout,
        slug,
        pageFavicon,
        pageTitle,
        topBlocks: [],
        sideBlocks: [],
        bottomBlocks: [],
        header: DEFAULT_HEADER_BLOCK as IHostedPageHeaderBlock,
        footer: DEFAULT_FOOTER_BLOCK as IHostedPageFooterBlock
      };
      updateConfig(newConfig as IGivingFormConfig);
    }
  };

  const validateSlug = (inputSlug: string): boolean => {
    // TODO: JDH - leading hyphens ok?
    // const regex = /^(?!(-))[a-zA-Z0-9-]{1,50}$/
    const regex = /^[a-zA-Z0-9-]{1,50}$/;

    return !!inputSlug.match(regex);
  };

  const handleChangeSlug = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSlug(event.target.value);
    setIsValidSlug(validateSlug(event.target.value));
    setIsUniqueSlug(undefined);
  };

  const setHelperText = () => {
    let helperText = '';
    if (isUniqueSlug === false) {
      helperText = 'You must choose a unique slug';
    }
    if (isValidSlug === false) {
      helperText =
        'You must enter between 1-50 alphanumeric characters including hyphens';
    }
    return helperText;
  };

  const resetUniqueAndValid = () => {
    setIsValidSlug(undefined);
    setIsUniqueSlug(undefined);
  };

  const handleOnClose = () => {
    onClose();
    resetUniqueAndValid();
    setSlug('');
  };

  return (
    <Dialog
      label="slug modal"
      onClose={handleOnClose}
      open={openSlugModal}
      className="hosted-page-slug-modal-dialog"
    >
      <DialogTitle
        className="hosted-page-slug-modal-title"
        onClick={handleOnClose}
      >
        {' '}
      </DialogTitle>
      <DialogContent className="hosted-page-slug-modal-content">
        <DialogContentTitle className="hosted-page-slug-modal-content-title">
          Give this Hosted Page a unique URL.
        </DialogContentTitle>
        <DialogContentText className="hosted-page-slug-modal-content-text">
          To use this Hosted Page, we need to give it a unique URL.
        </DialogContentText>
        <div className="hosted-page-slug-modal-slug-input-container">
          <Text className="hosted-page-slug-modal-base-slug" variant="h8">
            {envConfig.hostedPageDomain}
          </Text>
          <TextField
            placeholder="Enter Customization"
            value={slug}
            onChange={handleChangeSlug}
            error={isUniqueSlug === false || isValidSlug === false}
            helperText={setHelperText()}
          />
        </div>
        <DialogActions className="hosted-page-slug-modal-actions-container">
          <DialogActionButton
            onClick={onStartBuildingHandler}
            disabled={!slug || !isValidSlug}
            variant="primary"
            name="start building"
          >
            {isCheckingValidity ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Start Building'
            )}
          </DialogActionButton>
          <DialogActionButton
            onClick={handleOnClose}
            variant="secondary"
            name="back"
          >
            Back to Editor
          </DialogActionButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
