import { useState } from 'react';
import hostedPageExample from 'assets/images/hosted-page-example.png';
import Button from 'components/Button';
import Text from 'components/Text';
import { HostedPageCreate } from 'components/gms/HostedPageCreateModals/HostedPageCreate';
import './EnableHostedPageJumbotron.scss';

export const EnableHostedPageJumbotron = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  return (
    <div className="hosted-page-jumbotron-container fluid-container">
      <div className="enable-feature-jumbo">
        <div className="hosted-page-text-container">
          <Text variant="h1">Do you want to create a Hosted Page?</Text>
          <Text variant="h5">
            Create a web page to host your giving form! With a Hosted Page, you
            can add additional marketing copy and images, providing more context
            around your cause and persuading your visitors to take action. You
            will create a customized URL that you can share across multiple
            platforms.
          </Text>
          <Button onClick={() => setIsModalOpen(true)}>
            Yes, Enable Hosted Page
          </Button>
        </div>
        <div className="hosted-page-example-container">
          <img src={hostedPageExample} alt="Hosted Page Example" />
        </div>
      </div>
      <HostedPageCreate isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </div>
  );
};
