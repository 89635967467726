import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import TextField from 'components/TextField';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import {
  useCreateGivingFormMutation,
  useValidateGivingFormName
} from 'queries/UseGivingForms';
import { GivingForm } from 'services';
import './NewGivingFormDialog.scss';

type NewGivingFormDialogProps = {
  open: boolean;
  onClose: () => void;
  onCreate?: (data: GivingForm) => void;

  /**
   * the id of the giving form to copy
   */
  templateId?: string;
};

/**
 * A GMS-specific dialog for creating a new giving form.
 * The user must input a name for the new giving form before submitting.
 */
const NewGivingFormDialog = ({
  open,
  onClose,
  onCreate,
  templateId
}: NewGivingFormDialogProps): JSX.Element => {
  const [formName, setFormName] = useState('');
  const queryClient = useQueryClient();
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [addAlert] = useAlerts();
  const [valid, setValid] = useState(true);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValid(true);
    setFormName(event.currentTarget.value);
  };

  const { setSelectedGivingFormId, selectedOrganization } = useAppContext();

  const organizationId = selectedOrganization?.id;

  const {
    mutateAsync: createGivingFormMutation,
    isLoading: isCreatingGivingForm
  } = useCreateGivingFormMutation({
    organizationId,
    name: formName,
    campaignId,
    templateId
  });

  const {
    mutateAsync: checkValidGivingFormName,
    isLoading: isCheckingValidity
  } = useValidateGivingFormName({
    organizationId,
    campaignId,
    name: formName
  });

  const handleCreateGivingForm = async () => {
    try {
      const data = await createGivingFormMutation();
      setSelectedGivingFormId(data.embedInstanceId);
      setFormName('');
      navigate(
        `/campaigns/${campaignId}/giving-forms/${data.embedInstanceId}/edit`
      );
      // invalidate query so we refetch on next page load
      queryClient.invalidateQueries(['campaignGivingForms']);
      onCreate?.(data);
    } catch (e) {
      addAlert({
        title: 'Uh oh. Looks like there was an error creating the giving form.',
        severity: 'error'
      });
    }
  };

  const checkValid = async () => {
    const isValid = await checkValidGivingFormName();
    setValid(isValid);

    return isValid;
  };

  const onStartBuilding = async (close: () => void) => {
    const isValid = await checkValid();

    if (isValid) {
      await handleCreateGivingForm();
      close();
    }
  };

  return (
    <Dialog
      className="new-giving-form-dialog"
      label="new giving form name dialog"
      open={open}
      onClose={onClose}
    >
      <DialogTitle onClick={(close) => close()} />
      <DialogContent>
        <DialogContentTitle>
          What are we calling this Giving Form?
        </DialogContentTitle>
        <DialogContentDescription>
          To start building your form, we need to give it a unique name.
        </DialogContentDescription>
        <TextField
          value={formName}
          placeholder="Enter Form Name"
          hiddenLabel
          onChange={handleChange}
          error={!valid}
          helperText={
            !valid
              ? `"${formName}" is already being used in this campaign. Please pick a unique name.`
              : undefined
          }
        />
      </DialogContent>
      <DialogActions>
        <DialogActionButton variant="secondary" onClick={(close) => close()}>
          Cancel
        </DialogActionButton>
        <DialogActionButton
          variant="primary"
          onClick={onStartBuilding}
          disabled={
            formName === '' || isCheckingValidity || isCreatingGivingForm
          }
        >
          {isCheckingValidity || isCreatingGivingForm ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Start Building'
          )}
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewGivingFormDialog;
