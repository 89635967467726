import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { LimitedTextField, useLimitedLength } from 'components/TextField';
import DeleteBlockModal from 'components/gms/DeleteBlockModal/DeleteBlockModal';
import { useConfigContext } from 'hooks/useConfigContext';
import { IGivingFormConfig } from 'types';
import {
  RecurringGiftEquation,
  RecurringGiftPrompt
} from 'types/givingFormTypes/RecurringGiftPrompt';
import { EditRecurringGiftEquation } from './EditRecurringGiftEquation';
import './EditRecurringGiftPrompt.scss';

const PROMPT_MAX_CHARS = 300;

const DEFAULT_RECURRING_GIFT_PROMPT = {
  id: 'recurringGiftPrompt',
  isEnabled: false,
  modalHeader: 'Would you like to make a Recurring Donation?',
  modalDescription:
    'Make a greater impact by contributing on a regular, recurring basis.',
  equations: [
    {
      id: uuid(),
      min: 5,
      max: 100,
      percentage: 100
    }
  ] as RecurringGiftEquation[]
};

export const EditRecurringGiftPrompt = (): JSX.Element => {
  const {
    configData: { config },
    updateConfig,
    toggleRecurringGiftPrompt
  } = useConfigContext<IGivingFormConfig>();

  const [modalHeader, setModalHeader] = useLimitedLength(
    PROMPT_MAX_CHARS,
    config?.recurringGiftPrompt?.modalHeader ||
      (config?.recurringGiftPrompt?.modalHeader === ''
        ? config?.recurringGiftPrompt?.modalHeader // ternary allows setting a default value and persisting an empty string when returning
        : 'Would you like to make a Recurring Donation?')
  );
  const [modalDescription, setModalDescription] = useLimitedLength(
    PROMPT_MAX_CHARS,
    config?.recurringGiftPrompt?.modalDescription ||
      (config?.recurringGiftPrompt?.modalDescription === ''
        ? config?.recurringGiftPrompt?.modalDescription // ternary allows setting a default value and persisting an empty string when returning
        : 'Make a greater impact by contributing on a regular, recurring basis.')
  );
  const [isPromptEnabled, setIsPromptEnabled] = useState<boolean>(
    config?.recurringGiftPrompt?.isEnabled || false
  );
  const [recurringGiftPromptConfig, setRecurringGiftPromptConfig] =
    useState<RecurringGiftPrompt>();
  const [showNewEquation, setShowNewEquation] = useState(false);
  const [openId, setOpenId] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');

  useEffect(() => {
    // create and add the block to the config if it doesn't exist
    if (!config?.recurringGiftPrompt) {
      const newConfig = {
        ...config,
        recurringGiftPrompt: DEFAULT_RECURRING_GIFT_PROMPT
      };
      updateConfig(newConfig);
      setRecurringGiftPromptConfig(newConfig.recurringGiftPrompt);
    } else {
      setRecurringGiftPromptConfig(config.recurringGiftPrompt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const handleEnablePromptToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPromptEnabled(event.target.checked);
    const newConfig = { ...config };
    const { recurringGiftPrompt } = newConfig;
    recurringGiftPrompt.isEnabled = event.target.checked;
    updateConfig(newConfig);
    // this function emits an event to the Giving Form to render the Recurring Gift Prompt
    // if the prompt is enabled and the form is in Edit mode
    toggleRecurringGiftPrompt(event.target.checked);
    // close the new equation editor when disabling the prompt
    if (!event.target.checked) {
      setShowNewEquation(false);
      setOpenId('');
    }
  };

  const handleHeaderChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setModalHeader(event.target.value);
    const newConfig = { ...config };
    const { recurringGiftPrompt } = newConfig;
    recurringGiftPrompt.modalHeader = event.target.value;
    updateConfig(newConfig);
  };

  const handleDescriptionChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setModalDescription(event.target.value);
    const newConfig = { ...config };
    const { recurringGiftPrompt } = newConfig;
    recurringGiftPrompt.modalDescription = event.target.value;
    updateConfig(newConfig);
  };

  const handleUpdateEquations = (newEquation: RecurringGiftEquation) => {
    const newConfig = { ...config };
    const { recurringGiftPrompt } = newConfig;
    const newEquations = recurringGiftPrompt.equations;

    // if id is null, we are creating a new equation; otherwise, updating an existing one
    if (!newEquation.id) {
      // eslint-disable-next-line no-param-reassign
      newEquation.id = uuid();
      newEquations.push(newEquation);
    } else {
      const currentEquationIndex = newEquations.findIndex(
        (equation) => equation.id === newEquation.id
      );
      newEquations.splice(currentEquationIndex, 1, newEquation);
    }

    updateConfig(newConfig);
    setShowNewEquation(false);
    setOpenId('');
  };

  const handleDeleteEquation = (id?: string) => {
    if (!id) {
      setShowNewEquation(false);
    } else {
      const newConfig = { ...config };
      const { recurringGiftPrompt } = newConfig;
      const newEquations = recurringGiftPrompt.equations;

      const currentEquationIndex = newEquations.findIndex(
        (equation) => equation.id === id
      );
      newEquations.splice(currentEquationIndex, 1);
      updateConfig(newConfig);
      setOpenId('');
    }
  };

  return (
    <div className="element-library-edit-recurring-gift-prompt">
      <div className="text-fields">
        <FormControlLabel
          className="enable-recurring-gift-prompt-switch"
          control={
            <Switch
              checked={isPromptEnabled}
              onChange={handleEnablePromptToggle}
              inputProps={{
                'aria-label': 'enable recurring gift prompt toggle switch'
              }}
            />
          }
          label="Enable Recurring Gift Prompt"
        />
        <div className={!isPromptEnabled && 'disabled'}>
          <div className="disabled-overlay" />
          <Text variant="h5">Modal Header</Text>
          <div className="text-field">
            <LimitedTextField
              value={modalHeader}
              hiddenLabel
              fullWidth
              multiline
              minRows={3}
              maxChar={PROMPT_MAX_CHARS}
              onChange={(event) => setModalHeader(event.target.value)}
              onBlur={(event) => {
                handleHeaderChange(event);
              }}
              placeholder="Enter Prompt Header Here"
            />
          </div>
          <Text variant="h5">Description</Text>
          <div className="text-field">
            <LimitedTextField
              value={modalDescription}
              hiddenLabel
              fullWidth
              multiline
              minRows={3}
              maxChar={PROMPT_MAX_CHARS}
              onChange={(event) => setModalDescription(event.target.value)}
              onBlur={(event) => {
                handleDescriptionChange(event);
              }}
              placeholder="Enter Prompt Description Here"
            />
          </div>
        </div>
      </div>
      <div className={!isPromptEnabled && 'disabled'}>
        <div className="disabled-overlay" />
        <div className="recurring-gift-equations-container">
          {recurringGiftPromptConfig?.equations?.map(
            (equation: RecurringGiftEquation) => (
              <EditRecurringGiftEquation
                allEquations={recurringGiftPromptConfig?.equations}
                equation={equation}
                id={equation.id}
                key={equation.id}
                onDelete={() => {
                  setIdToDelete(equation.id);
                  setDeleteModalOpen(true);
                }}
                onUpdate={handleUpdateEquations}
                open={openId === equation.id && !showNewEquation}
                setOpenId={() => {
                  setOpenId(equation.id);
                  setShowNewEquation(false);
                }}
              />
            )
          )}
        </div>
        <div
          className={clsx('edit-recurring-gift-equation-input', {
            'edit-recurring-gift-equation-input-shown': showNewEquation
          })}
        >
          {showNewEquation && (
            <EditRecurringGiftEquation
              allEquations={recurringGiftPromptConfig?.equations}
              equation={null} // no equation to pass in if adding a new one
              id={null} // no id to pass in if adding a new equation
              onDelete={handleDeleteEquation}
              onUpdate={handleUpdateEquations}
              open={showNewEquation}
              setOpenId={() => setOpenId('')}
            />
          )}
        </div>
        <div className="add-recurring-equation">
          <Icon icon={ICONS.PLUS} fontSize="small" />
          <Text variant="h4" onClick={() => setShowNewEquation(true)}>
            Add Recurring Equation
          </Text>
        </div>
      </div>
      <DeleteBlockModal
        onConfirm={() => handleDeleteEquation(idToDelete)}
        onClose={() => {
          setIdToDelete(null);
          setDeleteModalOpen(false);
        }}
        open={deleteModalOpen}
      />
    </div>
  );
};
