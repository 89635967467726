import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Avatar,
  InputAdornment,
  MenuList,
  Popover,
  TextField
} from '@mui/material';
import { IconSearch } from '@tabler/icons';
import clsx from 'clsx';
import { matchSorter } from 'match-sorter';
import { useNavigate } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import { useAppContext } from 'hooks/useAppContext';
import { IOrganization } from 'services/types/userTypes';
import { OrgItem } from './OrgItem';
import './OrgSelector.scss';

const itemContent =
  (
    isSelectedOrg: (org: IOrganization) => boolean,
    currentIndex: number,
    handleOrgSelect: (org: IOrganization) => void
  ) =>
  (_index: number, organization: IOrganization) =>
    (
      <OrgItem
        organization={organization}
        isSelectedOrg={isSelectedOrg}
        isFocusedOrg={_index === currentIndex}
        handleOrgSelect={handleOrgSelect}
      />
    );

export const OrgSelector = ({
  orgs
}: {
  orgs: IOrganization[];
}): JSX.Element => {
  const navigateTo = useNavigate();
  const [userOrganizations, setUserOrganizations] = useState<IOrganization[]>(
    []
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchCriteria, setSearchCriteria] = useState<string | undefined>();

  // Keyboard navigation related items
  const ref = useRef(null);
  const listRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const { selectedOrganization, setSelectedOrganization } = useAppContext();

  const handleClick = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setSearchCriteria('');
    setCurrentIndex(-1);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const isSelectedOrg = useCallback(
    (org: IOrganization) => org.id === selectedOrganization.id,
    [selectedOrganization]
  );

  useEffect(() => {
    setUserOrganizations(
      [...orgs].sort((a, b) => {
        if (a.id === selectedOrganization.id) {
          return -1;
        }
        if (b.id === selectedOrganization.id) {
          return 1;
        }
        return 0;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization]);

  const mapUserOrgs = useCallback(() => {
    if (!searchCriteria) {
      return userOrganizations;
    }

    return matchSorter(userOrganizations, searchCriteria, {
      keys: ['name']
    });
  }, [userOrganizations, searchCriteria]);

  const handleSearch = (event: ChangeEvent) => {
    setSearchCriteria((event.target as HTMLInputElement).value);
    setCurrentIndex(-1);
    return false;
  };

  const handleOrgSelect = (organization: IOrganization) => {
    handleClose();

    if (selectedOrganization.id !== organization.id) {
      setSelectedOrganization(organization);
      navigateTo('/dashboard');
    }
  };

  const getSelectedOrgInitials = () => {
    const nameArray = selectedOrganization?.name?.split(' ');
    if (nameArray.length === 2) {
      return `${nameArray[0].charAt(0)}${nameArray[1].charAt(0)}`;
    }
    return selectedOrganization?.name?.charAt(0);
  };

  const mappedUserOrgs = mapUserOrgs();

  const keyDownCallback = useCallback(
    (e) => {
      let nextIndex: number = null;

      if (e.code === 'ArrowUp') {
        nextIndex = Math.max(0, currentIndex - 1);
      } else if (e.code === 'ArrowDown') {
        nextIndex = Math.min(99, currentIndex + 1);
      }

      if (nextIndex !== null) {
        ref.current.scrollIntoView({
          index: nextIndex,
          behavior: 'auto',
          done: () => {
            setCurrentIndex(nextIndex);
          }
        });

        e.preventDefault();
      }

      // handle enter
      if (e.code === 'Enter') {
        const menuSelectedOrg = mappedUserOrgs[currentIndex];
        handleOrgSelect(menuSelectedOrg);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentIndex, ref, setCurrentIndex]
  );

  const focusCallback = () => {
    // Make sure the first item in the list is selected when the list is focused.
    setCurrentIndex(0);
  };

  const scrollerRef = useCallback(
    (element) => {
      if (element) {
        element.addEventListener('keydown', keyDownCallback);
        element.addEventListener('focus', focusCallback);
        listRef.current = element;
      } else {
        listRef.current.removeEventListener('keydown', keyDownCallback);
        listRef.current.removeEventListener('focus', focusCallback);
      }
    },
    [keyDownCallback]
  );

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.code === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
      listRef.current.focus();
    }
  };

  return (
    <div className="org-selector">
      <Tooltip title={selectedOrganization.name}>
        <Avatar
          className={clsx('org-selector-avatar', open && 'active')}
          onClick={handleClick}
        >
          {getSelectedOrgInitials()}
        </Avatar>
      </Tooltip>
      <Popover
        className="org-selector-dropdown"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
      >
        <Text variant="h6">Switch Organization</Text>
        {orgs?.length > 10 && (
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconSearch />
                </InputAdornment>
              ),
              onKeyDown: handleKeyDown
            }}
            autoComplete="off"
            autoFocus
            placeholder="Search Organization"
            onChange={handleSearch}
          />
        )}
        <MenuList>
          <Virtuoso
            ref={ref}
            scrollerRef={scrollerRef}
            data={mappedUserOrgs}
            style={{
              height:
                mappedUserOrgs.length > 10 ? 400 : 40 * mappedUserOrgs.length
            }}
            itemContent={itemContent(
              isSelectedOrg,
              currentIndex,
              handleOrgSelect
            )}
            increaseViewportBy={{
              top: 200,
              bottom: 200
            }}
            overscan={40}
            fixedItemHeight={40}
            totalCount={mappedUserOrgs.length}
          />
        </MenuList>
      </Popover>
    </div>
  );
};
