import { AxiosError } from 'axios';
import axios from './axiosClient';
import {
  IOrganizationDashboard,
  IOrganizationInfo,
  IPaymentGateway,
  OrgGivingForms,
  OrgRevenueByChannel,
  PlotBy,
  ReferenceCodes
} from './types';

export const getOrganizationService = async (
  organizationId: string
): Promise<IOrganizationInfo> => {
  try {
    const { data } = await axios.get(`/organizations/${organizationId}`);
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your organization'
    );
  }
};

export const getOrganizationDashboardService = async (
  organizationId: string,
  startDate: string,
  endDate: string,
  plotBy: PlotBy,
  includeChapters: boolean,
  allTimeRangeSelected: boolean
): Promise<IOrganizationDashboard> => {
  try {
    const { data } = await axios.get(
      `/organizations/${organizationId}/dashboard`,
      {
        params: {
          startDate,
          endDate,
          plotBy,
          includeChapters,
          allTimeRangeSelected
        }
      }
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        "There was an error fetching your organization's dashboard data"
    );
  }
};

export const getOrgRevenueByChannelService = async (
  organizationId: string,
  startDate: string,
  endDate: string
): Promise<OrgRevenueByChannel> => {
  try {
    const { data } = await axios.get(
      `/organizations/${organizationId}/revenue-by-channel`,
      {
        params: {
          startDate,
          endDate
        }
      }
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        "There was an error fetching your organization's channel revenue"
    );
  }
};

export const getOrgGivingForms = async (
  organizationId: string
): Promise<OrgGivingForms> => {
  try {
    const { data } = await axios.get(
      `/organizations/${organizationId}/giving-forms`
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        "There was an error fetching your organization's giving forms"
    );
  }
};

export const getOrganizationReferenceCodes = async (
  organizationId: string
): Promise<ReferenceCodes> => {
  try {
    const { data } = await axios.get(
      `/organizations/${organizationId}/referenceCodes`
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        "There was an error fetching your organization's reference codes"
    );
  }
};

export const getOrganizationPaymentGateways = async (
  organizationId: string
): Promise<IPaymentGateway[]> => {
  try {
    const { data } = await axios.get(
      `/organizations/${organizationId}/payment-gateways`
    );
    const paymentGateways = data.map((value: any) => ({
      id: value.id,
      isDefault: value.is_default,
      name: value.name,
      gatewayType: value.gateway_type,
      backendName: value.backend_name
    }));
    return paymentGateways;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        "There was an error fetching your organization's payment gateways"
    );
  }
};
