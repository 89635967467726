import { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Blocker, ButtonProps } from 'components';
import Button from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import { ReceiptPreviewModal } from 'components/ReceiptPreviewModal';
import Text from 'components/Text';
import { ConfirmReceiptEditDialog } from 'components/gms/ConfirmReceiptEditDialog/ConfirmReceiptEditDialog';
import { EmailPreviewCard } from 'components/gms/EmailPreviewCard';
import { NewReceiptDialog } from 'components/gms/NewReceiptDialog/NewReceiptDialog';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { OrganizationRole, useRenderIfRole } from 'hooks/useRenderIfRole';
import { useEmailTemplates, useEmails } from 'queries/UseEmails';
import { Email } from 'services/types';
import { EmailType } from 'types/emailTypes';
import './Receipts.scss';

export const Receipts = () => {
  const navigate = useNavigate();
  const params = useParams();
  const formId = params.givingFormId || params.givingFormTemplateId;
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();
  const [pushAlert] = useAlerts();
  const { selectedCampaignName } = useAppContext();
  const { configData, receiptId, updateReceiptId } = useConfigContext();
  const {
    data: savedReceipts,
    isLoading,
    isError: isErrorLoadingSavedReceipts
  } = useEmails({
    organizationId,
    type: EmailType.Receipt
  });
  const [selectedReceipt, setSelectedReceipt] = useState<Email>(null);
  const [showNewReceiptDialog, setShowNewReceiptDialog] =
    useState<boolean>(false);
  const [showEditConfirmDialog, setShowEditConfirmDialog] =
    useState<boolean>(false);
  const [receiptPreviewModalOpen, setReceiptPreviewModalOpen] =
    useState<boolean>(false);
  const [receiptPreviewHtml, setReceiptPreviewHtml] = useState<string>();
  const [receiptToClone, setReceiptToClone] = useState<string>('');
  const { renderIfRole } = useRenderIfRole();
  const { data: emailTemplates, isLoading: isLoadingTemplates } =
    useEmailTemplates({
      organizationId,
      type: EmailType.Receipt,
      includeGlobals: true
    });

  const onEmailSelect = (receipt: Email) => {
    setSelectedReceipt(receipt);
    updateReceiptId(receipt.emailId);
  };

  const onEmailEdit = (emailId: string) => {
    setReceiptToClone('');
    navigate(`/emails/receipt/${emailId}/edit`, {
      state: {
        isFromGivingFormEditor: !!params.givingFormId,
        isFromGivingFormTemplateEditor: !!params.givingFormTemplateId,
        givingFormId: formId,
        givingFormName: configData.name,
        campaignId: params.campaignId,
        campaignName: selectedCampaignName
      }
    });
  };

  const handleCloneEmail = (emailId: string) => {
    setReceiptToClone(emailId);
    setShowNewReceiptDialog(true);
  };

  useEffect(() => {
    if (!isLoading && savedReceipts) {
      setSelectedReceipt(
        savedReceipts.find((receipt: Email) => receipt.emailId === receiptId)
      );
    }
  }, [setSelectedReceipt, isLoading, savedReceipts, receiptId]);

  useEffect(() => {
    if (isErrorLoadingSavedReceipts) {
      pushAlert({
        title:
          'Uh oh. Looks like there was an error loading your saved receipts.',
        severity: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorLoadingSavedReceipts]);

  const generatePreviewButtons = (receipt: Email) => {
    const previewButtons = [];
    if (selectedReceipt?.emailId !== receipt.emailId) {
      previewButtons.push({
        label: `Select ${EmailType.Receipt}`,
        onClick: () => onEmailSelect(receipt),
        icon: ICONS.CLICK
      });
    }

    previewButtons.push(
      {
        label: 'Clone',
        onClick: () => handleCloneEmail(receipt.emailId),
        icon: ICONS.PENCIL
      },
      {
        label: 'Preview',
        onClick: () => {
          setReceiptPreviewHtml(receipt.html.toString());
          setReceiptPreviewModalOpen(true);
        },
        icon: ICONS.EYE
      }
    );

    return previewButtons;
  };

  const renderSavedReceipts = (savedReceiptsArray: Email[]) => {
    if (savedReceiptsArray?.length) {
      return (
        <>
          <Text variant="body">Select a receipt from your existing list.</Text>
          <Grid container marginTop={1.5} justifyContent="flex-start">
            {savedReceiptsArray.map((receipt: Email) => (
              <EmailPreviewCard
                name={receipt.name}
                type={EmailType.Receipt}
                isSelected={selectedReceipt?.emailId === receipt.emailId}
                previewButtons={generatePreviewButtons(receipt)}
                key={receipt.emailId}
              />
            ))}
          </Grid>
        </>
      );
    }
    return (
      <Stack
        direction="column"
        justifyContent="center"
        className="no-results"
        alignItems="center"
        marginTop={2}
      >
        <Text variant="h3">You don’t currently have any saved receipts.</Text>
        <Text variant="body">
          Start by customizing a template from the library below or by creating
          a new receipt.
        </Text>
        <Box className="bar" marginTop={1.6} />
      </Stack>
    );
  };

  const renderReceiptTemplates = (receiptsArray: Email[]) => {
    if (receiptsArray?.length) {
      return (
        <Box className="receipts-container">
          <Text marginBottom={0.5} variant="h3">
            Receipt Templates
          </Text>
          <Text variant="body">
            Customize a receipt from our template library.
          </Text>
          <Grid container marginTop={1.5} justifyContent="flex-start">
            {receiptsArray.map((receipt: Email) => (
              <EmailPreviewCard
                name={receipt.name}
                type={EmailType.Receipt}
                isSelected={selectedReceipt?.emailId === receipt.emailId}
                previewButtons={generatePreviewButtons(receipt)}
                key={receipt.emailId}
              />
            ))}
          </Grid>
        </Box>
      );
    }
    return (
      <Stack
        direction="column"
        justifyContent="center"
        className="no-results"
        alignItems="center"
        marginTop={2}
      >
        <Text variant="h3">
          You don’t currently have any receipt templates to display.
        </Text>
        <Box className="bar" marginTop={1.6} />
      </Stack>
    );
  };

  const handleStartFromScratch = () => {
    setShowNewReceiptDialog(true);
  };

  const primaryButtonProps: ButtonProps = {
    children: 'Start From Scratch',
    onClick: handleStartFromScratch,
    startIcon: <Icon icon={ICONS.PLUS} />
  };

  return (
    <Blocker block={isLoading || isLoadingTemplates}>
      <div className="fluid-container">
        <Box paddingTop={14.5} flexDirection="column">
          {receiptId && (
            <Box className="selected-receipt-container">
              {receiptPreviewHtml && (
                <ReceiptPreviewModal
                  receiptHtml={receiptPreviewHtml}
                  isOpen={receiptPreviewModalOpen}
                  onClose={() => setReceiptPreviewModalOpen(false)}
                />
              )}
              <Text variant="h3">Selected Receipt</Text>
              <Stack direction="row" className="selected-receipt">
                <Box className="success-icon">
                  <Icon
                    icon={ICONS.DODECAGON}
                    className="dodecagon"
                    color="primary"
                    viewBox="0 0 142 142"
                  />
                  <Icon icon={ICONS.CHECK} className="check-icon" />
                </Box>
                <Box className="receipt-details">
                  <Text variant="h2">{selectedReceipt?.name}</Text>
                  <Box className="divider" />
                  <Text className="receipt-description" variant="body">
                    This receipt will be emailed to your donors after they fill
                    out the giving form and complete a transaction.
                  </Text>
                  <Stack direction="row">
                    {renderIfRole(
                      <Button
                        className="edit-button"
                        variant="primary"
                        onClick={() => setShowEditConfirmDialog(true)}
                      >
                        Edit Receipt
                      </Button>,
                      OrganizationRole.Editor
                    )}
                    <Button
                      onClick={() => {
                        setReceiptPreviewHtml(selectedReceipt.html.toString());
                        setReceiptPreviewModalOpen(true);
                      }}
                      variant="secondary"
                    >
                      Preview
                    </Button>
                  </Stack>
                </Box>
                <Box
                  display="flex"
                  justifyContent="end"
                  className="receipt-preview"
                >
                  <Icon
                    icon={ICONS.RECEIPT}
                    className="preview-icon"
                    viewBox="0 0 22 22"
                  />
                </Box>
              </Stack>
            </Box>
          )}
          {renderIfRole(
            <Box className="scratch-button-container">
              <Button
                {...primaryButtonProps}
                disabled={primaryButtonProps.disabled}
              />
            </Box>,
            OrganizationRole.Editor
          )}
          <NewReceiptDialog
            open={showNewReceiptDialog}
            onClose={() => {
              setShowNewReceiptDialog(false);
              setReceiptToClone('');
            }}
            onCreate={(newReceipt) => onEmailEdit(newReceipt.emailId)}
            templateId={receiptToClone}
          />
          <ConfirmReceiptEditDialog
            emailId={selectedReceipt?.emailId}
            isOpen={showEditConfirmDialog}
            onClose={() => {
              setShowEditConfirmDialog(false);
            }}
            onConfirm={() => onEmailEdit(selectedReceipt?.emailId)}
          />
          <Box className="receipts-container">
            <Text marginBottom={0.5} variant="h3">
              My Receipts
            </Text>
            {savedReceipts && renderSavedReceipts(savedReceipts)}
          </Box>
          {/* {receipts && renderReceiptTemplates(receipts)} */}
        </Box>
      </div>
    </Blocker>
  );
};
