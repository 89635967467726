import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import './DeleteTemplateModal.scss';

type DeleteTemplateModalProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const DeleteTemplateModal = ({
  open,
  onConfirm,
  onClose
}: DeleteTemplateModalProps) => (
  <Dialog open={open} label="Delete Template Modal" onClose={onClose}>
    <DialogTitle
      onClick={(close) => close()}
      className="delete-template-modal-title"
    >
      Template will be removed
    </DialogTitle>
    <DialogContent>
      <DialogContentTitle>Are you sure?</DialogContentTitle>
      <DialogContentDescription>
        Template will be permanently removed and it can&apos;t be recovered.
      </DialogContentDescription>
    </DialogContent>
    <DialogActions className="delete-template-modal-title-action-container">
      <DialogActionButton
        onClick={(close) => {
          onConfirm();
          close();
        }}
        variant="primary"
      >
        Yes, Delete
      </DialogActionButton>
      <DialogActionButton onClick={(close) => close()} variant="secondary">
        No, Cancel
      </DialogActionButton>
    </DialogActions>
  </Dialog>
);
