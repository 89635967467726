import { ElementLibraryGivingFormViews } from 'types';
import { IDataView } from '../ElementLibrary';
import { EditCorporateMatching } from './EditCorporateMatching';
import EditCustomContent from './EditCustomContent';
import EditCustomFields from './EditCustomFields';
import EditDesignations from './EditDesignations';
import EditFooter from './EditFooter';
import EditGiftOptions from './EditGiftOptions';
import EditHeader from './EditHeader';
import { EditImpacts } from './EditImpact';
import EditPaymentOptions from './EditPaymentOptions';
import { EditRecurringGiftPrompt } from './EditRecurringGiftPrompt';
import EditRecurringOptions from './EditRecurringOptions';
import EditThankYouGift from './EditThankYouGift';
import { EditTribute } from './EditTribute';
import EmailOptIn from './EmailOptIn';
import RootViewContent from './RootMenu';
import { ThankYouAccountInvitation } from './ThankYouAccountInvitation/ThankYouAccountInvitation';
import ThankYouBodyMessage from './ThankYouBodyMessage';
import { ThankYouCorporateMatching } from './ThankYouCorporateMatching';
import ThankYouHeader from './ThankYouHeader';
import ThankYouMessageDonationDetails from './ThankYouMessageDonationDetails';
import ThankYouRootViewContent from './ThankYouRootMenu';

export const ElementLibraryGivingFormViewData: {
  [key in ElementLibraryGivingFormViews]: IDataView<ElementLibraryGivingFormViews>;
} = {
  [ElementLibraryGivingFormViews.RootView]: {
    title: 'Element Library',
    component: RootViewContent
  },
  [ElementLibraryGivingFormViews.HeaderView]: {
    title: 'Header',
    subtitle: 'Give your form a header and description.',
    component: () => <EditHeader />
  },
  [ElementLibraryGivingFormViews.FooterView]: {
    title: 'Footer',
    subtitle: 'Control what is displayed in the bottom of your Giving Form.',
    component: () => <EditFooter />
  },
  [ElementLibraryGivingFormViews.GiftOptionsView]: {
    title: 'Donation Options',
    subtitle: 'Allow your donors to select an amount to donate.',
    component: () => <EditGiftOptions />
  },
  [ElementLibraryGivingFormViews.PaymentSectionView]: {
    title: 'Payment Info',
    subtitle:
      'Choose the payment information you would like your donors to provide',
    component: () => <EditPaymentOptions />
  },
  [ElementLibraryGivingFormViews.OptInOptionsView]: {
    title: 'Email Opt-In',
    subtitle:
      'Add the ability for donors to opt in to receiving email notifications',
    component: () => <EmailOptIn />
  },
  [ElementLibraryGivingFormViews.RecurringOptionsView]: {
    title: 'Recurring Options',
    subtitle: 'Allow your donors to select a donation frequency',
    component: () => <EditRecurringOptions />
  },
  [ElementLibraryGivingFormViews.ImpactView]: {
    title: 'Impact',
    subtitle: 'Show your donors the impact they can make',
    component: () => <EditImpacts />
  },
  [ElementLibraryGivingFormViews.RecurringGiftPromptView]: {
    title: 'Recurring Gift Prompt',
    subtitle:
      'Remind your donors how much further a recurring donation can go.',
    component: () => <EditRecurringGiftPrompt />
  },
  [ElementLibraryGivingFormViews.CustomContentBlockView]: {
    title: 'Custom Content Block',
    subtitle: 'Control what is displayed in the custom content Block',
    component: () => <EditCustomContent />
  },
  [ElementLibraryGivingFormViews.CustomFieldView]: {
    title: 'Custom Field',
    subtitle:
      'Add a text, multiple choice, checkbox, or a dropdown field to your form',
    component: EditCustomFields
  },
  [ElementLibraryGivingFormViews.DesignationsBlockView]: {
    title: 'Designations',
    subtitle: 'Allow your donors to select a designation for their donation',
    component: () => <EditDesignations />
  },
  [ElementLibraryGivingFormViews.CorporateMatchingView]: {
    title: 'Corporate Matching',
    subtitle:
      'Allow your donors to select their employer to match their donation.',
    component: () => <EditCorporateMatching />
  },
  [ElementLibraryGivingFormViews.ThankYouRootView]: {
    title: 'Element Library',
    component: ThankYouRootViewContent
  },
  [ElementLibraryGivingFormViews.ThankYouHeaderView]: {
    title: 'Header',
    subtitle: 'Control what is displayed in the thank you header',
    component: ThankYouHeader
  },
  [ElementLibraryGivingFormViews.ThankYouMessageDonationDetailsView]: {
    title: 'Donation Details',
    subtitle: 'Control what information displays on this Thank You Message.',
    component: ThankYouMessageDonationDetails
  },
  [ElementLibraryGivingFormViews.ThankYouMessageBodyView]: {
    title: 'Message Body',
    subtitle: 'Customize the thank you note by adding an additional message',
    component: ThankYouBodyMessage
  },
  [ElementLibraryGivingFormViews.ThankYouAccountInvitationView]: {
    title: 'Account Invitation',
    subtitle: 'Urge your donors to create an account',
    component: ThankYouAccountInvitation
  },
  [ElementLibraryGivingFormViews.ThankYouCorporateMatchingView]: {
    title: 'Corporate Matching',
    subtitle:
      'Allow donors to update corporate matching from the Thank You page.',
    component: ThankYouCorporateMatching
  },
  [ElementLibraryGivingFormViews.TributeView]: {
    title: 'Tribute',
    subtitle: 'Allow your donors to make a tribute donation',
    component: EditTribute
  },
  [ElementLibraryGivingFormViews.ThankYouGiftView]: {
    title: 'Thank You Gift',
    subtitle:
      'Allow your recipients to select a gift in return for their donation.',
    component: EditThankYouGift
  }
};
