import { CSSProperties } from 'react';
import { Theme, alpha, createTheme } from '@mui/material';

const createIconButtonStyle = (
  styles: CSSProperties,
  hoverStyles: CSSProperties,
  disabledStyles: CSSProperties
) => ({
  ...styles,
  '&:hover': {
    ...hoverStyles
  },
  '&:disabled': {
    ...disabledStyles
  }
});

export const createIconButtonTheme = (currTheme: Theme): Theme => {
  const {
    palette: {
      blue,
      darkBlue,
      lightestGray,
      darkGray,
      lightGray,
      getContrastText
    }
  } = currTheme;

  const theme = createTheme(currTheme, {
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            height: '3rem',
            width: '3rem',
            '&.Mui-disabled': {
              opacity: 0.3
            },
            '&.Mui-focusVisible': {
              outline: `solid 2px ${alpha(blue.main, 0.5)}`
            }
          },
          sizeSmall: {
            height: '2rem',
            width: '2rem'
          }
        },
        defaultProps: {
          disableRipple: false,
          disableFocusRipple: true
        },
        variants: [
          {
            props: { variant: 'primary' },
            style: createIconButtonStyle(
              { backgroundColor: blue.main, color: getContrastText(blue.main) },
              { backgroundColor: darkBlue.main },
              { backgroundColor: blue.main, color: getContrastText(blue.main) }
            )
          },
          {
            props: { variant: 'secondary' },
            style: createIconButtonStyle(
              {
                backgroundColor: 'white',
                color: blue.main,
                border: `1px solid ${blue.main}`
              },
              { backgroundColor: lightestGray.main },
              { color: blue.main }
            )
          },
          {
            props: { variant: 'tertiary' },
            style: createIconButtonStyle(
              { color: darkGray.main, border: `1px solid ${darkGray.main}` },
              { backgroundColor: lightestGray.main },
              { color: darkGray.main }
            )
          },
          {
            props: { variant: 'basic' },
            style: createIconButtonStyle(
              { color: blue.main },
              { backgroundColor: lightestGray.main },
              { color: blue.main }
            )
          }
        ]
      }
    }
  });

  return theme;
};
