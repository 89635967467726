import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import { generateEmbed } from 'services/embedGenerator';
import { resolvedEnvironment } from 'services/environment';
import './CopyEmbedDialog.scss';

type CopyEmbedDialogProps = {
  open: boolean;
  onClose: () => void;
  givingFormId: string;
};

/**
 * A GMS-specific dialog for allowing the user to select a channel and add it
 * to an existing campaign.
 */
export const CopyEmbedDialog = ({
  open,
  onClose,
  givingFormId
}: CopyEmbedDialogProps) => {
  const [showCopiedCodeBox, setShowCopiedCodeBox] = useState<boolean>(false);
  const [showCopiedButton, setShowCopiedButton] = useState<boolean>(false);
  const embedScriptText = generateEmbed(
    'giving-form',
    givingFormId,
    resolvedEnvironment.appsBaseUrl
  );
  return (
    <Dialog
      className="copy-embed-dialog"
      label="Copy Embed Dialog"
      open={open}
      onClose={onClose}
    >
      <DialogTitle onClick={(close) => close()} />
      <DialogContent>
        <Stack>
          <Text variant="h1" className="copy-embed-dialog__title">
            Embed Code
          </Text>
          <Text variant="body">
            Copy and paste the code below into your website, article, or app.
          </Text>
          <Text variant="h5" color="darkGray.main" mt={1} mb={1.25}>
            Embed Code
          </Text>{' '}
          <Tooltip
            title={
              showCopiedCodeBox
                ? 'Copied to clipboard'
                : 'Click to copy embed code'
            }
          >
            <Box
              className="copy-embed-dialog__embed-code"
              onClick={() => {
                window.navigator.clipboard.writeText(embedScriptText);
                setShowCopiedCodeBox(true);

                setTimeout(() => {
                  setShowCopiedCodeBox(false);
                }, 2000);
              }}
            >
              <Icon
                icon={ICONS.COPY}
                color="primary"
                className="copy-embed-dialog__copy-icon"
              />
              <code>{embedScriptText}</code>
            </Box>
          </Tooltip>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Tooltip
          title={
            showCopiedButton
              ? 'Copied to clipboard'
              : 'Click to copy embed code'
          }
        >
          <Box className="copy-embed-dialog__copy-button-box">
            <DialogActionButton
              variant="primary"
              onClick={() => {
                window.navigator.clipboard.writeText(embedScriptText);
                setShowCopiedButton(true);

                setTimeout(() => {
                  setShowCopiedButton(false);
                }, 2000);
              }}
            >
              Copy Code
            </DialogActionButton>
          </Box>
        </Tooltip>
        <DialogActionButton
          variant="secondary"
          onClick={() => onClose()}
          className="copy-embed-dialog__cancel-button"
        >
          Cancel
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
