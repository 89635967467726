import { AxiosError } from 'axios';
import axios from 'services/axiosClient';
import { UpdateEvent } from './types';

export const archiveEventService = async ({
  eventId,
  organizationId,
  isArchived
}: UpdateEvent) => {
  try {
    const { data } = await axios.patch('/events/archive', {
      eventId,
      organizationId,
      isArchived
    });

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Unable to archive Event'
    );
  }
};
