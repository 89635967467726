import { Area } from 'react-easy-crop';

export const createImage = (url: string): Promise<CanvasImageSource> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    // Must add this, otherwise later drawing the image to a canvas and calling .toDataUrl will fail due to it being
    // polluted/contaminated
    image.crossOrigin = 'Anonymous';
    image.src = url;
  });

// Use to convert an external URL to base64
export const getImageBase64 = async (url: string): Promise<string> => {
  const image = await createImage(url);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // set canvas size to match the bounding box
  canvas.width = image.width as number;
  canvas.height = image.height as number;
  ctx.drawImage(image, 0, 0);

  // As Base64 string
  return canvas.toDataURL('image/png');
};

// Same principle as getBase64Img but takes cropped dimensions to output a cropped base64 image
export async function getCroppedImg(
  imageSrc: string,
  imageCrop: Area
): Promise<string> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // set canvas size to match the bounding box
  canvas.width = imageCrop.width;
  canvas.height = imageCrop.height;
  ctx.translate(-1 * imageCrop.x, -1 * imageCrop.y);
  ctx.drawImage(image, 0, 0);

  // As Base64 string
  return canvas.toDataURL('image/png');
}
