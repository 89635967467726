import { format } from 'date-fns';
import Text from 'components/Text';
import './DateRangeText.scss';

type DateRangeTextProps = {
  selectedRangeValue: number;
  startDate: Date;
  endDate: Date;
};

export const DateRangeText = ({
  selectedRangeValue,
  startDate,
  endDate
}: DateRangeTextProps) => (
  <Text className="date-range-label" variant="caption">
    {selectedRangeValue === 365
      ? `${format(startDate, 'MMM yyyy')} - ${format(endDate, 'MMM yyyy')}`
      : `${format(startDate, 'MMM do yyyy')} - ${format(
          endDate,
          'MMM do yyyy'
        )}`}
  </Text>
);
