import { Theme, createTheme } from '@mui/material';

export const createRadioTheme = (currTheme: Theme): Theme => {
  const theme = createTheme(currTheme, {
    components: {
      MuiRadio: {
        defaultProps: {
          // Disable default MUI animations
          disableRipple: false,
          disableFocusRipple: true
        }
      }
    }
  });

  return theme;
};
