import { AxiosError } from 'axios';
import axios from 'services/axiosClient';
import { ImageDelete, ImageUploadPostBody } from './types';

export const imageUpload = async ({
  organizationId,
  imageDataUrl,
  imagePrefix
}: ImageUploadPostBody) => {
  try {
    const { data } = await axios.post(
      `/organizations/image/upload?organizationId=${organizationId}`,
      { image: imageDataUrl, prefix: imagePrefix }
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error saving image'
    );
  }
};

export const imageDelete = async ({
  organizationId,
  prefix,
  imageId
}: ImageDelete) => {
  try {
    await axios.delete(
      `/organizations/image/delete?organizationId=${organizationId}&prefix=${prefix}&imageId=${imageId}`
    );
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error deleting image'
    );
  }
};
