import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { SwitchElement } from 'react-hook-form-mui';
import { useDebounce } from 'use-debounce';
import * as yup from 'yup';
import OptionalSection from 'components/EditorMenus/ElementLibrary/OptionalSection';
import CheckboxElement from 'components/ReactHookForm/CheckboxElement';
import LimitedTextElement from 'components/ReactHookForm/LimitedTextElement';
import MultiselectCheckboxElement from 'components/ReactHookForm/MultiselectCheckboxElement';
import Text from 'components/Text';
import { useConfigContext } from 'hooks/useConfigContext';
import {
  TributeDataCollectionField,
  useTributeDataCollection
} from 'hooks/useTributeDataCollection';
import { BlockTypes, IGivingFormConfig, ITributeBlock } from 'types';
import './EditTribute.scss';
import { TributeImageManager } from './TributeImageManager';

const maxFieldChars = 300;
const maxMessageChars = 700;
const maxImageCount = 4;

const EditTributeSchema = yup.object({
  tributeEnabled: yup.boolean(),
  tributeSectionTitle: yup.string().when('tributeEnabled', {
    is: true,
    then: yup.string().required('Tribute Section Title is required')
  }),
  tributeInstruction: yup.string().when('tributeEnabled', {
    is: true,
    then: yup.string().required('Tribute Instruction is required')
  }),
  ecardEnabled: yup.boolean(),
  ecardSectionTitle: yup.string().when(['tributeEnabled', 'ecardEnabled'], {
    is: true,
    then: yup.string().required('E-card Section Title is required')
  }),
  ecardInstruction: yup.string().when(['tributeEnabled', 'ecardEnabled'], {
    is: true,
    then: yup.string().required('E-card Instruction is required')
  }),
  ecardOrgMessage: yup.string().when(['tributeEnabled', 'ecardEnabled'], {
    is: true,
    then: yup.string().required('E-card Organization Message is required')
  })
});

type EditTributeFormValues = {
  tributeEnabled: boolean;
  tributeSectionTitle: string;
  tributeDataCollection: TributeDataCollectionField[];
  tributeInstruction: string;
  ecardEnabled: boolean;
  ecardSectionTitle: string;
  ecardInstruction: string;
  ecardOrgMessage: string;
  allowDonorToAddCustomMessage: boolean;
  ecardImages: string[];
};

interface TributeBlockFieldsProps {
  values: EditTributeFormValues;
  onSubmit: (values: EditTributeFormValues) => void;
}

const TributeBlockFields = ({
  values,
  onSubmit
}: TributeBlockFieldsProps): JSX.Element => {
  const { tributeDataCollectionOptions } = useTributeDataCollection();

  const {
    control,
    watch,
    formState: { errors, isValid }
  } = useForm<EditTributeFormValues>({
    defaultValues: values,
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(EditTributeSchema),
    mode: 'all'
  });

  const watchAllFields = watch();
  const [allFields] = useDebounce(watchAllFields, 1000, {
    equalityFn: (left, right) => isEqual(left, right)
  });

  useEffect(() => {
    if (isValid) {
      onSubmit(allFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFields, isValid]);

  return (
    <form>
      <div className="element-library-edit-tribute">
        <div className="text-fields">
          <SwitchElement
            control={control}
            name="tributeEnabled"
            className="switch-element"
            label="Enable Tribute"
          />

          <div className={!watchAllFields.tributeEnabled ? 'disabled' : ''}>
            <div className="disabled-overlay" />
            <div>
              <Text variant="h5">Section Title</Text>
              <div className="text-field">
                <LimitedTextElement
                  name="tributeSectionTitle"
                  placeholder="Enter Prompt Here"
                  control={control}
                  hiddenLabel
                  fullWidth
                  multiline
                  minRows={3}
                  error={!isValid && !!errors.tributeSectionTitle}
                  maxChar={maxFieldChars}
                />
              </div>
            </div>
            <div>
              <Text variant="h5">
                What information would you like to collect from your donor?
              </Text>
              <div className="text-field">
                <MultiselectCheckboxElement
                  control={control}
                  name="tributeDataCollection"
                  label="Select"
                  options={tributeDataCollectionOptions}
                />
              </div>
            </div>
            <div>
              <Text variant="h5">
                Let the donor know what you plan to do with this
                information.&nbsp;
                <span>
                  (i.e.: We will put the tribute&apos;s name on a plaque.)
                </span>
              </Text>
              <div className="text-field">
                <LimitedTextElement
                  name="tributeInstruction"
                  control={control}
                  hiddenLabel
                  fullWidth
                  multiline
                  minRows={3}
                  maxChar={maxFieldChars}
                  error={!isValid && !!errors.tributeInstruction}
                />
              </div>
            </div>
            <div>
              <Controller
                control={control}
                name="ecardEnabled"
                render={(props) => (
                  <OptionalSection
                    initiallySelected={props.field.value}
                    title="Include E-card"
                    titleVariant="h5"
                    onChange={props.field.onChange}
                  >
                    <br />
                    <div>
                      <Text variant="h5">E-card Section Title</Text>
                      <div className="text-field">
                        <LimitedTextElement
                          name="ecardSectionTitle"
                          placeholder="Enter Prompt Here"
                          control={control}
                          hiddenLabel
                          fullWidth
                          multiline
                          minRows={3}
                          maxChar={maxFieldChars}
                          error={!isValid && !!errors.ecardSectionTitle}
                        />
                      </div>
                    </div>
                    <div>
                      <Text variant="h5">E-card Instructional Message</Text>
                      <div className="text-field">
                        <LimitedTextElement
                          name="ecardInstruction"
                          control={control}
                          hiddenLabel
                          fullWidth
                          multiline
                          minRows={3}
                          maxChar={maxFieldChars}
                          error={!isValid && !!errors.ecardInstruction}
                        />
                      </div>
                    </div>
                    <div>
                      <Text variant="h5">E-card Organization Message</Text>
                      <div className="text-field">
                        <LimitedTextElement
                          name="ecardOrgMessage"
                          placeholder="Write a message from the organization to the tribute"
                          control={control}
                          hiddenLabel
                          fullWidth
                          multiline
                          minRows={7}
                          maxChar={maxMessageChars}
                          error={!isValid && !!errors.ecardOrgMessage}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="checkbox-field">
                        <CheckboxElement
                          control={control}
                          name="allowDonorToAddCustomMessage"
                          label="Allow Donor to Add Custom Message"
                        />
                      </div>
                    </div>
                    <div>
                      <Text variant="h5">E-card Images</Text>
                      <div className="images-field">
                        <Controller
                          control={control}
                          name="ecardImages"
                          render={({ field }) => (
                            <TributeImageManager
                              images={field.value}
                              setImages={(images) => field.onChange(images)}
                              maxImageCount={maxImageCount}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </OptionalSection>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const DEFAULT_TRIBUTE_BLOCK: ITributeBlock = {
  id: 'tribute',
  blockType: BlockTypes.TributeBlock,
  isEnabled: false,
  tributeSectionTitle: 'Would you like to make this donation a tribute?',
  tributeInstruction:
    'Fill out the following information so we can send your honoree a gift!',
  isAddressEnabled: false,
  isDonorNameEnabled: false,
  isEmailEnabled: false,
  isTitleEnabled: false,
  isTributeMessageEnabled: false,
  ecard: {
    isEnabled: false,
    ecardSectionTitle: 'Would you like to make this tribute an E-Card?',
    ecardInstruction:
      'Select the image you want to include in your E-Card. Feel free to add a customized message!',
    ecardOrgMessage: 'A charitable gift has been given in your honor.',
    isCustomMessageEnabled: false,
    images: []
  }
};

export const EditTribute = (): JSX.Element => {
  const {
    configData: { config },
    updateConfig
  } = useConfigContext<IGivingFormConfig>();

  const tributeBlock = useMemo(
    () =>
      (config.blocks.find(
        (x) => x.blockType === BlockTypes.TributeBlock
      ) as ITributeBlock) || DEFAULT_TRIBUTE_BLOCK,
    [config.blocks]
  );

  const { composeTributeDataCollection, populateFromTributeDataCollection } =
    useTributeDataCollection();

  const handleSubmit = (values: EditTributeFormValues) => {
    let newBlock: ITributeBlock = {
      ...tributeBlock,
      ...{
        isEnabled: values.tributeEnabled,
        tributeSectionTitle: values.tributeSectionTitle,
        tributeInstruction: values.tributeInstruction,
        ecard: {
          isEnabled: values.ecardEnabled,
          ecardSectionTitle: values.ecardSectionTitle,
          ecardInstruction: values.ecardInstruction,
          ecardOrgMessage: values.ecardOrgMessage,
          isCustomMessageEnabled: values.allowDonorToAddCustomMessage,
          images: values.ecardImages
        }
      }
    };
    newBlock = populateFromTributeDataCollection(
      newBlock,
      values.tributeDataCollection
    );
    if (!isEqual(newBlock, tributeBlock)) {
      const isTributeBlockExists = !!config.blocks.find(
        (x) => x.blockType === BlockTypes.TributeBlock
      );

      const newBlocks = isTributeBlockExists
        ? config.blocks.map((block) =>
            block.blockType === BlockTypes.TributeBlock ? newBlock : block
          )
        : [...config.blocks, newBlock];

      updateConfig({
        ...config,
        blocks: newBlocks
      });
    }
  };

  return (
    <TributeBlockFields
      values={{
        tributeEnabled: tributeBlock.isEnabled ?? false,
        tributeSectionTitle: tributeBlock.tributeSectionTitle ?? '',
        tributeDataCollection: composeTributeDataCollection(tributeBlock) ?? [],
        tributeInstruction: tributeBlock.tributeInstruction ?? '',
        ecardEnabled: tributeBlock.ecard.isEnabled ?? false,
        ecardSectionTitle: tributeBlock.ecard.ecardSectionTitle ?? '',
        ecardInstruction: tributeBlock.ecard.ecardInstruction ?? '',
        ecardOrgMessage: tributeBlock.ecard.ecardOrgMessage ?? '',
        allowDonorToAddCustomMessage:
          tributeBlock.ecard.isCustomMessageEnabled ?? false,
        ecardImages: tributeBlock.ecard.images ?? []
      }}
      onSubmit={handleSubmit}
    />
  );
};
