import { Skeleton } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import pluralize from 'pluralize';
import { useParams } from 'react-router-dom';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { AbTestStatusChip } from 'components/gms/AbTestOverviewBody/AbTestStatusChip';
import { useAppContext } from 'hooks/useAppContext';
import { useAbTest } from 'queries/UseAbTests';
import './AbTestResultsHeader.scss';

export const AbTestResultsHeader = () => {
  const { abTestId } = useParams();
  const { selectedOrganization } = useAppContext();
  const organizationId = selectedOrganization?.id;
  const { data: abTestData, isLoading } = useAbTest({
    organizationId,
    abTestId,
    includeMetrics: true
  });

  const getDaysActive = () =>
    differenceInCalendarDays(
      abTestData?.endedAt ? new Date(abTestData?.endedAt) : new Date(),
      new Date(abTestData?.createdAt)
    );

  return !isLoading ? (
    <div className="abtest-results">
      <div className="abtest-results-top">
        <div className="abtest-results-title-and-days">
          <Text variant="h2">Results</Text>
          <Icon
            icon={ICONS.ACTIVITY}
            className="abtest-results-activity-icon"
          />
          <Text variant="caption" className="abtest-results-days-active">
            {pluralize('Day', getDaysActive(), true)} Active
          </Text>
        </div>
        <AbTestStatusChip endedAt={abTestData?.endedAt} hasDraft={false} />
      </div>
      <Text variant="body">
        See which giving form has the better performance from the results below.
      </Text>
    </div>
  ) : (
    <Skeleton
      className="abtest-results-skeleton"
      animation="wave"
      height="3.625rem"
      width="100%"
    />
  );
};
