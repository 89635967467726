import { AxiosError } from 'axios';
import axios from 'services/axiosClient';
import { GivingForm, GivingFormPostBody } from './types';
import {
  CheckHostedPageSlugRequestDto,
  DeleteGivingFormTemplate,
  DeleteImage,
  DeleteTemplateSharePostBody,
  GetAllGivingFormsRequest,
  GetAllTemplateSharesRequest,
  GetGivingFormTemplateByIdRequest,
  GetHostedPageSlugsRequestDto,
  GivingFormTemplatePostBody,
  HostedPageSlugsResponseDto,
  TemplateShare,
  UniqueNamePostBody,
  UpdateBannerImagesPost,
  UpdateGivingFormPostBody,
  UpdateGivingFormRedirectURLPostBody,
  UpdateGivingFormReferenceCodePostBody,
  UpdateGivingFormTemplate,
  UpdateSmsFormPostBody,
  UpdateTemplateSharePostBody,
  UploadImagePost,
  ValidateGivingFormConfigPostBody
} from './types/givingFormTypes';

export const getAllGivingFormsService = async ({
  organizationId,
  campaignId = null,
  includeGlobals = false
}: GetAllGivingFormsRequest) => {
  try {
    let queryParams = `/?organizationId=${organizationId}&includeGlobals=${includeGlobals}`;
    if (campaignId) queryParams += `&campaignId=${campaignId}`;

    const { data } = await axios.get(`/givingForms${queryParams}`, {
      data: { organizationId }
    });
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your forms.'
    );
  }
};

export const getGivingFormByInstanceIdService = async (
  embedInstanceId: string
): Promise<GivingForm> => {
  try {
    const { data } = await axios.get(`/givingForms/${embedInstanceId}`);
    data.updatedAt = new Date(data.updatedAt);
    data.createdAt = new Date(data.createdAt);
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your form.'
    );
  }
};

export const checkHostedPageSlug = async ({
  organizationId,
  slug,
  givingFormId,
  isFromDuplicateForm = false
}: CheckHostedPageSlugRequestDto): Promise<boolean> => {
  try {
    // when duplicating a form, we do not want to exclude the current
    // slug because the current slug will always be taken
    const { data } = await axios.post('/givingForms/checkHostedPageSlug', {
      organizationId,
      slug,
      givingFormId,
      isFromDuplicateForm
    });
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error checking your custom URL.'
    );
  }
};

export const getHostedPageSlugs = async ({
  organizationId,
  givingFormId
}: GetHostedPageSlugsRequestDto): Promise<HostedPageSlugsResponseDto> => {
  try {
    const { data } = await axios.get('/givingForms/getHostedPageSlugs', {
      params: {
        organizationId,
        givingFormId
      }
    });

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error getting hosted page slugs.'
    );
  }
};

export const createGivingFormService = async ({
  organizationId,
  name,
  campaignId,
  templateId,
  hostedPageSlug
}: GivingFormPostBody): Promise<GivingForm> => {
  const postData = {
    organizationId,
    name,
    campaignId,
    templateId,
    hostedPageSlug
  };
  try {
    const { data } = await axios.post('/givingForms', postData);
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error within the service'
    );
  }
};

export const updateGivingFormService = async ({
  givingFormId,
  name,
  config,
  receiptId,
  organizationId,
  isSmsForm,
  isArchived
}: UpdateGivingFormPostBody) => {
  try {
    const { data } = await axios.patch(`/givingForms/${givingFormId}`, {
      name,
      config,
      receiptId,
      organizationId,
      isSmsForm,
      isArchived
    });

    data.updatedAt = new Date(data.updatedAt);
    data.createdAt = new Date(data.createdAt);
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Unable to update Giving Form'
    );
  }
};

export const updateGivingFormTemplateService = async ({
  givingFormTemplateId,
  name,
  config,
  receiptId,
  organizationId
}: UpdateGivingFormTemplate) => {
  try {
    const { data } = await axios.patch(
      `/givingForms/templates/${givingFormTemplateId}`,
      {
        name,
        config,
        receiptId,
        organizationId
      }
    );

    data.updatedAt = new Date(data.updatedAt);
    data.createdAt = new Date(data.createdAt);
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Unable to update Giving Form Template'
    );
  }
};

export const deleteGivingFormTemplateService = async ({
  givingFormTemplateId,
  organizationId
}: DeleteGivingFormTemplate) => {
  try {
    const { data } = await axios.delete(
      `/givingForms/templates/${givingFormTemplateId}?organizationId=${organizationId}`
    );

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Unable to delete Giving Form Template'
    );
  }
};

export const validateGivingFormNameService = async ({
  organizationId,
  name,
  campaignId,
  givingFormId,
  includeGlobalTemplates = false
}: UniqueNamePostBody) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.set('includeGlobalTemplates', String(includeGlobalTemplates));

    const { data } = await axios.post(
      `/givingForms/isNameUnique?${searchParams.toString()}`,
      {
        name,
        organizationId,
        campaignId,
        givingFormId
      }
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error with not unique giving form name'
    );
  }
};

// Add types
export const createGivingFormTemplateService = async ({
  organizationId,
  name,
  templateId,
  templateDescription
}: GivingFormTemplatePostBody): Promise<GivingForm> => {
  const postData = {
    organizationId,
    name,
    templateId,
    templateDescription
  };

  const { data } = await axios.post('/givingForms/templates', postData);
  return data;
  // omitted error handling and will be handled on the react-query level
};

export const getGivingFormTemplatesService = async ({
  organizationId,
  includeSharedTemplates,
  includeGlobals = false
}: Pick<
  GetAllGivingFormsRequest,
  'organizationId' | 'includeGlobals' | 'includeSharedTemplates'
>): Promise<GivingForm[]> => {
  try {
    const queryParams = `?organizationId=${organizationId}&includeGlobals=${includeGlobals}&includeSharedTemplates=${includeSharedTemplates}`;

    const { data } = await axios.get(`/givingForms/templates${queryParams}`, {
      data: { organizationId }
    });
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error retrieving your giving form templates'
    );
  }
};

export const getGivingFormTemplateByIdService = async ({
  organizationId,
  templateId
}: GetGivingFormTemplateByIdRequest): Promise<GivingForm> => {
  try {
    const { data } = await axios.get(
      `/givingForms/templates/${templateId}?organizationId=${organizationId}`
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error retrieving your giving form template'
    );
  }
};

export const validateGivingFormConfigService = async ({
  organizationId,
  givingFormId,
  config
}: ValidateGivingFormConfigPostBody): Promise<boolean> => {
  try {
    const { data } = await axios.post<boolean>(
      '/givingForms/validateVariantBAndSaveScreenshot',
      {
        organizationId,
        givingFormId,
        config
      }
    );

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error validating your giving form config'
    );
  }
};

export const updateGivingFormReferenceCode = async ({
  givingFormId,
  organizationId,
  referenceCode
}: UpdateGivingFormReferenceCodePostBody) => {
  try {
    const { data } = await axios.patch(
      `/givingForms/${givingFormId}/referenceCode`,
      {
        referenceCode,
        organizationId
      }
    );

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error updating your reference code'
    );
  }
};

export const updateGivingFormRedirectURL = async ({
  givingFormId,
  redirectURL,
  organizationId
}: UpdateGivingFormRedirectURLPostBody) => {
  try {
    const { data } = await axios.patch(
      `/givingForms/${givingFormId}/redirectURL`,
      {
        redirectURL,
        organizationId
      }
    );

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error updating redirect URL'
    );
  }
};

export const updateSmsForm = async ({
  organizationId,
  smsFormId
}: UpdateSmsFormPostBody) => {
  try {
    const { data } = await axios.patch(`/givingForms/updateSmsForm`, {
      organizationId,
      smsFormId
    });

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error updating SMS form'
    );
  }
};

export const updateBannerImages = async (
  {
    bannerImages,
    givingFormId,
    organizationId,
    userId
  }: UpdateBannerImagesPost,
  signal: AbortSignal
) => {
  try {
    const { data } = await axios.post(
      `/givingForms/${givingFormId}/updateBannerImages?organizationId=${organizationId}&userId=${userId}`,
      {
        bannerImages
      },
      {
        signal
      }
    );

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error updating banner image'
    );
  }
};

export const deleteBannerImages = async ({
  givingFormId,
  organizationId,
  fileName,
  userId
}: any) => {
  try {
    await axios.delete(
      `/givingForms/${givingFormId}/bannerImages?organizationId=${organizationId}&userId=${userId}&fileName=${fileName}`
    );
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error deleting banner image'
    );
  }
};

export const saveImage = async ({
  image,
  prefix,
  givingFormId,
  organizationId
}: UploadImagePost) => {
  try {
    const { data } = await axios.post(
      `/givingForms/${givingFormId}/saveImage?organizationId=${organizationId}`,
      {
        image,
        prefix
      }
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error saving thank you gift image'
    );
  }
};

export const deleteImage = async ({
  imageId,
  prefix,
  givingFormId,
  organizationId
}: DeleteImage) => {
  try {
    await axios.delete(
      `/givingForms/${givingFormId}/image?organizationId=${organizationId}&imageId=${imageId}&prefix=${prefix}`
    );
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error deleting thank you gift image'
    );
  }
};

export const readFile = async (file: File) =>
  new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => resolve(reader.result as string),
      false
    );
    reader.readAsDataURL(file);
  });

export const getAllTemplateShares = async ({
  organizationId,
  templateId
}: GetAllTemplateSharesRequest) => {
  try {
    const { data } = await axios.get(
      `/templateShare?organizationId=${organizationId}&templateId=${templateId}`
    );

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error getting all template shares'
    );
  }
};

export const updateTemplateShares = async (
  requestBody: UpdateTemplateSharePostBody
): Promise<TemplateShare[]> => {
  try {
    const { data } = await axios.post('/templateShare', requestBody);

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'Error updating all template shares'
    );
  }
};

export const deleteTemplateShare = async ({
  organizationId,
  sharedOrganizationId,
  templateId
}: DeleteTemplateSharePostBody) => {
  const { data } = await axios.delete(
    `/templateShare?organizationId=${organizationId}&sharedOrganizationId=${sharedOrganizationId}&templateId=${templateId}`
  );

  return data;
};

export const getGivingFormsForEmailService = async ({
  organizationId,
  emailId
}: {
  organizationId: string;
  emailId: string;
}) => {
  const { data } = await axios.get(
    `/givingForms/${organizationId}/forReceipt/${emailId}`
  );
  return data;
};

export const archiveGivingFormService = async ({
  givingFormId,
  organizationId,
  isArchived
}: UpdateGivingFormPostBody) => {
  try {
    const { data } = await axios.patch(`/givingForms/archive`, {
      givingFormId,
      organizationId,
      isArchived
    });

    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Unable to archive Giving Form'
    );
  }
};
