import {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import {
  MAXIMUM_DONATION,
  MINIMUM_DONATION
} from 'constants/givingFormContants';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form';
import * as yup from 'yup';
import {
  ICONS,
  LimitedTextField,
  NumberTextField,
  useLimitedLength
} from 'components';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { useConfigContext } from 'hooks/useConfigContext';
import {
  BlockTypes,
  GiftOption,
  IGiftOptionsBlock,
  IGivingFormConfig
} from 'types';
import OptionalSection from '../OptionalSection';
import './EditGiftOptions.scss';

const formatCurrency = (value: number) => {
  if (Number.isInteger(value)) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }).format(value);
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(value);
};

export const compactCurrencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact'
});

const SUB_HEADER_MAX_CHARS = 50;
const TAG_MAX_CHARS = 14;

function atLeastMinDonation(field?: number) {
  if (!field) {
    return true;
  }
  return field >= MINIMUM_DONATION;
}

function noMoreThanMaxDonation(field?: number) {
  if (!field) {
    return true;
  }
  return field < MAXIMUM_DONATION;
}

const giftOptionsSchema = yup.object({
  minimum: yup
    .mixed()
    .nullable()
    .test(
      'mustBeMoreThanMin',
      `Must be at least ${compactCurrencyFormatter.format(MINIMUM_DONATION)}`,
      atLeastMinDonation
    )
    .test(
      'mustBeLessThanMax',
      `Must be less than ${compactCurrencyFormatter.format(MAXIMUM_DONATION)}`,
      noMoreThanMaxDonation
    )
});

type EditGiftOptionProps = {
  option: GiftOption | null;
  options: GiftOption[];
  isDefault: boolean;
  open: boolean;
  setOpenIndex: Dispatch<SetStateAction<number>>;
  onUpdate: (
    index: number,
    newOption: GiftOption,
    isDefaultOption: boolean
  ) => void;
  onDelete: (index: number) => void;
  index?: number;
};

const EditGiftOption = ({
  option,
  options,
  isDefault,
  open,
  setOpenIndex,
  onUpdate,
  onDelete,
  index
}: EditGiftOptionProps): JSX.Element => {
  const [amount, setAmount] = useState<number>(option?.amount ?? null);
  const [tag, setTag] = useState<string>(option?.tag ?? '');

  const [isDefaultOption, setIsDefaultOption] = useState<boolean>(isDefault);
  const otherAmounts = options
    .map((op) => op.amount)
    .filter((opAmount) => opAmount !== option?.amount ?? null);

  useEffect(() => {
    setIsDefaultOption(isDefault);
  }, [isDefault]);

  let errorMessage;
  if (amount < MINIMUM_DONATION) {
    errorMessage = `Must be at least ${formatCurrency(MINIMUM_DONATION)}`;
  } else if (amount > MAXIMUM_DONATION) {
    errorMessage = `Must be less than ${formatCurrency(MAXIMUM_DONATION)}`;
  } else if (otherAmounts.includes(amount)) {
    errorMessage = 'Must be unique';
  }

  return (
    <Accordion className="EditGiftOption" expanded={open}>
      <AccordionSummary
        className={`EditGiftOption-summary ${open ? 'expanded' : ''}`}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={0.25} alignItems="center">
            <Text variant="h3">
              {option ? formatCurrency(option.amount) : '-'}
            </Text>
            {open && (
              <Checkbox
                checked={isDefaultOption}
                onChange={(e, isChecked) => {
                  setIsDefaultOption(isChecked);
                }}
              />
            )}
            {(open || isDefault) && (
              <Text variant="caption" className="EditGiftOption-defaultText">
                Default
              </Text>
            )}
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            {!open && (
              <IconButton
                label="edit"
                variant="basic"
                size="small"
                icon={ICONS.PENCIL}
                onClick={() => setOpenIndex(index)}
              />
            )}
            <IconButton
              label="delete"
              variant="basic"
              size="small"
              icon={ICONS.TRASH}
              onClick={() => onDelete(index)}
              disabled={options.length === 1}
            />
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        className={`EditGiftOption-details ${open ? 'expanded' : ''}`}
      >
        <Stack spacing={0.5}>
          <Text variant="h5">Amount</Text>
          <NumberTextField
            prefix="$"
            placeholder="$"
            value={amount}
            hiddenLabel
            valueIsNumericString
            thousandSeparator
            onChange={(e) => setAmount(Number(e))}
            error={!!errorMessage}
            helperText={errorMessage}
          />
          <span className="EditGiftOption-tag-label-container">
            <Text variant="h5" as="span">
              Tag&nbsp;
            </Text>
            <Text
              className="EditGiftOption-tag-label-optional-indicator"
              variant="h6"
              as="span"
            >
              (optional)
            </Text>
          </span>
          <LimitedTextField
            placeholder="Enter tag here"
            value={tag}
            maxChar={TAG_MAX_CHARS}
            onChange={(e) => setTag(e.target.value)}
          />

          <Button
            variant="primary"
            onClick={() =>
              onUpdate(index, { ...option, amount, tag }, isDefaultOption)
            }
            disabled={!!errorMessage}
            fullWidth
          >
            Update
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const EditGiftOptions = (): JSX.Element => {
  const { control } = useFormContext();
  const { configData, updateConfig } = useConfigContext<IGivingFormConfig>();
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [showNewGift, setShowNewGift] = useState<boolean>(false);

  const [openRecurringIndex, setOpenRecurringIndex] = useState<number | null>(
    null
  );
  const [showNewRecurringGift, setShowNewRecurringGift] =
    useState<boolean>(false);

  const giftOptionsBlockConfig = configData.config.blocks.find(
    (block) => block.blockType === BlockTypes.GiftOptionBlock
  ) as IGiftOptionsBlock;
  const giftOptionBlockIndex = configData.config.blocks.indexOf(
    giftOptionsBlockConfig
  );

  const updateGiftOptionsConfig = (
    newGiftOptionsBlockConfig: IGiftOptionsBlock
  ) => {
    const newBlocks = configData.config.blocks;
    newBlocks.splice(giftOptionBlockIndex, 1, newGiftOptionsBlockConfig);
    const newConfig: IGivingFormConfig = {
      ...configData.config,
      blocks: newBlocks
    };
    updateConfig(newConfig);
  };

  const [subHeader, setSubHeader] = useLimitedLength(
    SUB_HEADER_MAX_CHARS,
    giftOptionsBlockConfig?.subtext || ''
  );

  const handleSubHeaderChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setSubHeader(event.target.value);
  };

  const handleSubHeaderBlur: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = async (event) => {
    const newGiftOptionsBlockConfig: IGiftOptionsBlock = {
      ...giftOptionsBlockConfig,
      subtext: event.target.value as string
    };
    updateGiftOptionsConfig(newGiftOptionsBlockConfig);
  };

  const handleOptionUpdate = (
    index: number,
    optionEdits: GiftOption,
    isDefault: boolean
  ) => {
    const newOptions = giftOptionsBlockConfig.giftOptions;
    const oldOption = newOptions.splice(index, 1, optionEdits);
    let { defaultOption } = giftOptionsBlockConfig;

    // if the option came in checked for default
    if (isDefault) {
      // make sure default is the new option
      defaultOption = optionEdits.amount;
    } else if (defaultOption === oldOption[0].amount) {
      // we didn't change the amount and we just want to remove
      defaultOption = null;
    }
    // otherwise we should leave it alone

    const newGiftOptionsBlockConfig: IGiftOptionsBlock = {
      ...giftOptionsBlockConfig,
      defaultOption,
      giftOptions: newOptions
    };
    updateGiftOptionsConfig(newGiftOptionsBlockConfig);
    setOpenIndex(null);
  };

  const handleAmountDelete = (index: number) => {
    const newOptions = giftOptionsBlockConfig.giftOptions;
    const deletedOption = newOptions.splice(index, 1);
    const defaultOption =
      giftOptionsBlockConfig.defaultOption === deletedOption[0].amount
        ? null
        : giftOptionsBlockConfig.defaultOption;
    const newGiftOptionsBlockConfig: IGiftOptionsBlock = {
      ...giftOptionsBlockConfig,
      defaultOption,
      giftOptions: newOptions
    };
    updateGiftOptionsConfig(newGiftOptionsBlockConfig);
    setOpenIndex(null);
  };

  const handleNewOptionUpdate = (
    index: number,
    newOption: GiftOption,
    isDefault: boolean
  ) => {
    const newOptions = [...giftOptionsBlockConfig.giftOptions, newOption];
    const defaultOption = isDefault
      ? newOption.amount
      : giftOptionsBlockConfig.defaultOption;
    const newGiftOptionsBlockConfig: IGiftOptionsBlock = {
      ...giftOptionsBlockConfig,
      defaultOption,
      giftOptions: newOptions
    };
    updateGiftOptionsConfig(newGiftOptionsBlockConfig);
    setShowNewGift(false);
  };

  const handleMinimumAmountUpdate = (newMinimum?: number) => {
    if (newMinimum < MAXIMUM_DONATION) {
      const newGiftOptionsBlockConfig: IGiftOptionsBlock = {
        ...giftOptionsBlockConfig,
        min: newMinimum || 5
      };
      updateGiftOptionsConfig(newGiftOptionsBlockConfig);
    }
  };

  const handleCustomAmountUpdate = (newValue: boolean) => {
    const newGiftOptionsBlockConfig: IGiftOptionsBlock = {
      ...giftOptionsBlockConfig,
      enableCustomAmount: newValue,
      min: newValue ? giftOptionsBlockConfig.min : 5
    };
    updateGiftOptionsConfig(newGiftOptionsBlockConfig);
  };

  const updateAllowRecurring = (isAllowed: boolean) => {
    const newGiftOptionsBlockConfig: IGiftOptionsBlock = {
      ...giftOptionsBlockConfig,
      allowRecurringGiftOptions: isAllowed
    };
    updateGiftOptionsConfig(newGiftOptionsBlockConfig);
  };

  const handleNewRecurringOptionUpdate = (
    index: number,
    newOption: GiftOption,
    isDefault: boolean
  ) => {
    const newOptions = [
      ...(giftOptionsBlockConfig.recurringGiftOptions || []),
      newOption
    ];
    const defaultRecurringOption = isDefault
      ? newOption.amount
      : giftOptionsBlockConfig.defaultRecurringOption;
    const newGiftOptionsBlockConfig: IGiftOptionsBlock = {
      ...giftOptionsBlockConfig,
      defaultRecurringOption,
      recurringGiftOptions: newOptions
    };
    updateGiftOptionsConfig(newGiftOptionsBlockConfig);
    setShowNewRecurringGift(false);
  };

  const handleRecurringOptionUpdate = (
    index: number,
    optionEdits: GiftOption,
    isDefault: boolean
  ) => {
    const newOptions = giftOptionsBlockConfig.recurringGiftOptions;
    const oldOption = newOptions.splice(index, 1, optionEdits);
    let { defaultRecurringOption } = giftOptionsBlockConfig;

    // if the option came in checked for default
    if (isDefault) {
      // make sure default is the new option
      defaultRecurringOption = optionEdits.amount;
    } else if (defaultRecurringOption === oldOption[0].amount) {
      // we didn't change the amount and we just want to remove
      defaultRecurringOption = null;
    }
    // otherwise we should leave it alone

    const newGiftOptionsBlockConfig: IGiftOptionsBlock = {
      ...giftOptionsBlockConfig,
      defaultRecurringOption,
      recurringGiftOptions: newOptions
    };
    updateGiftOptionsConfig(newGiftOptionsBlockConfig);
    setOpenRecurringIndex(null);
  };

  const handleRecurringAmountDelete = (index: number) => {
    const newOptions = giftOptionsBlockConfig.recurringGiftOptions;
    const deletedOption = newOptions.splice(index, 1);
    const defaultRecurringOption =
      giftOptionsBlockConfig.defaultRecurringOption === deletedOption[0].amount
        ? null
        : giftOptionsBlockConfig.defaultRecurringOption;
    const newGiftOptionsBlockConfig: IGiftOptionsBlock = {
      ...giftOptionsBlockConfig,
      defaultRecurringOption,
      recurringGiftOptions: newOptions
    };
    updateGiftOptionsConfig(newGiftOptionsBlockConfig);
    setOpenIndex(null);
  };

  return (
    <>
      <div className="edit-donation-options-subheader-label">
        <Text variant="h5">Sub Header</Text>
        <Text className="edit-donation-options-optional-message" variant="h6">
          (Optional)
        </Text>
      </div>
      <Controller
        name="subHeader"
        control={control}
        defaultValue={subHeader ?? ''}
        render={({
          field: { ref, onChange, onBlur, ...field },
          fieldState: { error }
        }) => (
          <LimitedTextField
            {...field}
            hiddenLabel
            placeholder="How much would you like to donate?"
            fullWidth
            multiline
            minRows={3}
            maxChar={SUB_HEADER_MAX_CHARS}
            error={!!error}
            onChange={(event) => {
              onChange(event.target.value.substring(0, SUB_HEADER_MAX_CHARS));
              handleSubHeaderChange(event);
            }}
            onBlur={(event) => {
              onBlur();
              handleSubHeaderBlur(event);
            }}
          />
        )}
      />
      <div className="edit-donation-options-options-container">
        {giftOptionsBlockConfig.giftOptions.map((option, idx) => (
          <EditGiftOption
            option={option}
            options={giftOptionsBlockConfig.giftOptions}
            isDefault={option.amount === giftOptionsBlockConfig.defaultOption}
            open={openIndex === idx && !showNewGift}
            setOpenIndex={(index) => {
              setOpenIndex(index);
              setShowNewGift(false);
            }}
            onUpdate={handleOptionUpdate}
            onDelete={handleAmountDelete}
            index={idx}
            key={option.amount}
          />
        ))}
        <div
          className={clsx('edit-donation-options-new-amount-input', {
            'edit-donation-options-new-amount-input-shown': showNewGift
          })}
        >
          <EditGiftOption
            option={null} // we will never have an option to pass in
            options={giftOptionsBlockConfig.giftOptions}
            isDefault={false}
            open
            setOpenIndex={setOpenIndex}
            onUpdate={handleNewOptionUpdate}
            onDelete={() => setShowNewGift(false)}
          />
        </div>
        {giftOptionsBlockConfig.giftOptions.length < 5 && !showNewGift && (
          <div className="edit-donation-options-add-option">
            <Icon icon={ICONS.PLUS} fontSize="small" />
            <Text variant="h4" onClick={() => setShowNewGift(true)}>
              Add New Amount
            </Text>
          </div>
        )}
      </div>
      <div className="edit-donation-options-recurring-options">
        <OptionalSection
          title="Allow Alternate Recurring Options"
          initiallySelected={
            giftOptionsBlockConfig.allowRecurringGiftOptions || false
          }
          onChange={(isOpen) => updateAllowRecurring(isOpen)}
        >
          {giftOptionsBlockConfig.allowRecurringGiftOptions && (
            <div className="edit-donation-options-recurring-options-block">
              {giftOptionsBlockConfig.recurringGiftOptions?.map(
                (option, idx) => (
                  <EditGiftOption
                    option={option}
                    options={giftOptionsBlockConfig.recurringGiftOptions}
                    isDefault={
                      option.amount ===
                      giftOptionsBlockConfig.defaultRecurringOption
                    }
                    open={openRecurringIndex === idx && !showNewRecurringGift}
                    setOpenIndex={(index) => {
                      setOpenRecurringIndex(index);
                      setShowNewRecurringGift(false);
                    }}
                    onUpdate={handleRecurringOptionUpdate}
                    onDelete={handleRecurringAmountDelete}
                    index={idx}
                    key={option.amount}
                  />
                )
              )}
              <div
                className={clsx(
                  'edit-donation-options-new-recurring-amount-input',
                  {
                    'edit-donation-options-new-recurring-amount-input-shown':
                      showNewRecurringGift
                  }
                )}
              >
                <EditGiftOption
                  option={null} // we will never have an option to pass in
                  options={giftOptionsBlockConfig.recurringGiftOptions || []}
                  isDefault={false}
                  open
                  setOpenIndex={setOpenRecurringIndex}
                  onUpdate={handleNewRecurringOptionUpdate}
                  onDelete={() => setShowNewRecurringGift(false)}
                />
              </div>
              {(!giftOptionsBlockConfig.recurringGiftOptions ||
                giftOptionsBlockConfig.recurringGiftOptions?.length < 5) &&
                !showNewRecurringGift && (
                  <div className="edit-donation-options-add-recurring-option">
                    <Icon icon={ICONS.PLUS} fontSize="small" />
                    <Text
                      variant="h4"
                      onClick={() => setShowNewRecurringGift(true)}
                    >
                      Add New Amount
                    </Text>
                  </div>
                )}
            </div>
          )}
        </OptionalSection>
      </div>
      <OptionalSection
        title="Custom Amount"
        initiallySelected={giftOptionsBlockConfig?.enableCustomAmount}
        onChange={handleCustomAmountUpdate}
      >
        <div className="edit-donation-options-custom-option">
          <Text variant="h4">Minimum Custom Amount</Text>
          <Controller
            name="minimum"
            control={control}
            defaultValue={giftOptionsBlockConfig?.min ?? ''}
            render={({
              field: { ref, onChange, onBlur, ...field },
              fieldState: { error }
            }) => (
              <NumberTextField
                {...field}
                hiddenLabel
                placeholder="Minimum Amount"
                prefix="$"
                fullWidth
                error={!!error}
                helperText={error?.message ?? null}
                onChange={(event) => {
                  onChange(event);
                  handleMinimumAmountUpdate(Number(event));
                }}
                onBlur={() => onBlur()}
              />
            )}
          />
        </div>
      </OptionalSection>
    </>
  );
};

export default (): JSX.Element => {
  const methods = useForm({
    resolver: yupResolver(giftOptionsSchema),
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <EditGiftOptions />
    </FormProvider>
  );
};
