import pluralize from 'pluralize';
import { Notification, NotificationType } from 'services';
import { NotificationUnit, TimeNotificationUnit } from 'types';
import { numberFormatter, usdCurrencyFormatter } from 'utils';

export const makeThresholdNotificationText = (
  thresholdNotification: Notification
): string => {
  const { type, payload } = thresholdNotification;
  if (type === NotificationType.Threshold && 'thresholds' in payload) {
    const { abTestName, thresholds } = payload as {
      abTestName: string;
      thresholds: {
        unit: NotificationUnit | TimeNotificationUnit;
        amount: number;
      }[];
    };
    // if both a quantitative threshold and time-based threshold have been passed,
    // show combined notification text
    if (payload.thresholds.length === 2) {
      // quantitative thresholds come back first (e.g. visits or donations),
      // then time thresholds
      const [quantitative, time] = thresholds;
      let text = abTestName;

      if (quantitative.unit === NotificationUnit.donation) {
        text += ` raised ${usdCurrencyFormatter.format(quantitative.amount)}, `;
      } else if (quantitative.unit === NotificationUnit.visit) {
        text += ` reached ${numberFormatter.format(
          quantitative.amount
        )} ${pluralize('visit', quantitative.amount)}, `;
      }

      text += `and it's been ${pluralize(time.unit, time.amount, true)}!`;
      return text;
    }

    // logic for if only a single threshold was passed
    // (could be quantitative or time-based)
    const [threshold] = thresholds;
    if (threshold?.unit === NotificationUnit.donation) {
      return `${abTestName} raised ${usdCurrencyFormatter.format(
        threshold.amount
      )}!`;
    }
    if (threshold?.unit === NotificationUnit.visit) {
      return `${abTestName} reached ${numberFormatter.format(
        threshold?.amount
      )} ${pluralize('visit', threshold?.amount)}!`;
    }
    if (
      threshold?.unit === TimeNotificationUnit.day ||
      threshold?.unit === TimeNotificationUnit.week ||
      threshold?.unit === TimeNotificationUnit.month
    ) {
      return `${abTestName} has been running for ${numberFormatter.format(
        threshold.amount
      )} ${pluralize(threshold.unit, threshold.amount)}!`;
    }
  }

  return '';
};
