import { useCallback, useMemo, useRef, useState } from 'react';
import { alpha, useTheme } from '@mui/material';
import { Chart, ChartDataset, LegendElement } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { deepmerge } from 'deepmerge-ts';
import { Line } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import type { LineGraphTooltipProps } from 'components/gms';
import { LineGraphTooltip } from 'components/gms';
import { IOrganizationDashboard, PlotBy } from 'services/types';
import { usdCurrencyFormatter } from 'utils/formatters';
import {
  currencyFormatter,
  getSpannedYears,
  makeBaseDataset,
  makeLegendTitle,
  timeScaleMapper
} from '.';
import { LineGraphData } from './LineGraph.types';
import { generateTooltipDate } from './LineGraph.utils';

type LineGraphProps = {
  data: IOrganizationDashboard['chartData'];
  elapsedDaysInData: number;
  plotBy: PlotBy;
};

export const LineGraph = ({
  data,
  elapsedDaysInData,
  plotBy
}: LineGraphProps) => {
  const chartRef = useRef<ChartJSOrUndefined<'line', LineGraphData>>();
  const theme = useTheme();
  const currentYearColor = theme.palette.primary.main;
  const previousYearColor = alpha(theme.palette.yellow.main, 0.5);
  const { current: currentYearDataPoints, previous: previousYearDataPoints } =
    data;
  const [tooltipData, setTooltipData] = useState<LineGraphTooltipProps | null>(
    null
  );

  const { callback, time } = timeScaleMapper(elapsedDaysInData);

  const makeDataset = useCallback(
    (
      dataset: Partial<Omit<ChartDataset<'line', LineGraphData>, 'data'>> & {
        data: LineGraphData;
      },
      color: string
    ) => deepmerge(makeBaseDataset(color), dataset),
    []
  );

  const spannedYears = useMemo(() => getSpannedYears(data), [data]);

  const buildDatasets = useMemo(
    () => () => {
      const dataset = [
        makeDataset(
          {
            data: currentYearDataPoints,
            // the `|| '        '` is to account for when there is no data
            // and the new data is loading
            label: makeLegendTitle(spannedYears.current) || '        '
          },
          currentYearColor
        )
      ];
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!previousYearDataPoints) {
        dataset.push(
          makeDataset(
            {
              data: previousYearDataPoints,
              label:
                makeLegendTitle(
                  // accounting for when the data originally added one year to all data points
                  // in the previous year
                  spannedYears.previous.map((year) => year - 1)
                ) || '        ',
              backgroundColor: '#ffffff00'
            },
            previousYearColor
          )
        );
      }

      return dataset;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentYearDataPoints, previousYearDataPoints, spannedYears]
  );

  const chart = useMemo(
    () => (
      <Line
        ref={chartRef}
        plugins={[
          {
            id: 'legend',
            // a hacky workaround to add space between legend and graph, since
            // there's no built-in way to do it
            // https://stackoverflow.com/questions/42585861/chart-js-increase-spacing-between-legend-and-chart/42589310#:~:text=this%20hacky%20solution%20/%20workaround%3A
            beforeInit: (chartCtx) => {
              const legendElement = chartCtx.legend as LegendElement<'line'> & {
                fit: () => void;
              };

              // Get reference to the original fit function
              const originalFit = legendElement.fit;

              // Override the fit function
              // eslint-disable-next-line no-param-reassign
              legendElement.fit = function fit() {
                // Call original function and bind scope in order to use `this` correctly inside it
                originalFit.bind(legendElement)();
                // Change the height as suggested in another answers
                // eslint-disable-next-line react/no-this-in-sfc
                this.height += 20;
              };
            }
          }
        ]}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
              external: (context) => {
                const { tooltip } = context;
                // if chart is not defined, return early
                const theChart = chartRef.current;
                if (!theChart) {
                  return;
                }
                // hide the tooltip when chartjs determines you've hovered out
                if (tooltip.opacity === 0) {
                  setTooltipData(null);
                  return;
                }

                const position = theChart.canvas.getBoundingClientRect();

                // assuming tooltip is `position: fixed`
                // set position of tooltip
                const left = position.left + tooltip.dataPoints[0].element.x;
                const top = position.top + tooltip.dataPoints[0].element.y;

                setTooltipData({
                  top,
                  left,
                  date: generateTooltipDate(
                    plotBy,
                    new Date(tooltip.dataPoints[0].parsed.x)
                  ),
                  primaryColor: currentYearColor,
                  secondaryColor: !!previousYearDataPoints && previousYearColor,
                  currentYearStat: usdCurrencyFormatter.format(
                    tooltip?.dataPoints?.[0]?.parsed?.y
                  ),
                  previousYearStat:
                    !!previousYearDataPoints &&
                    usdCurrencyFormatter.format(
                      tooltip?.dataPoints?.[1]?.parsed?.y
                    )
                });
              }
            },
            legend: {
              onClick: () => null,
              align: 'end',
              labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                font: {
                  weight: 'bold'
                },
                // takes a callback with the chart context to help generate the legend
                generateLabels: (chartCtx: Chart<'line'>) => {
                  const copy = chartCtx.config.data.datasets.slice();
                  copy.reverse();
                  return copy.map((set, index) => ({
                    text: index === 0 ? ` ${set?.label}    ` : ` ${set?.label}`,
                    borderRadius: 6,
                    datasetIndex: index,
                    // no-any: chart.js types are a little strange here
                    // wasn't able to find a workaround
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    fillStyle: set.borderColor as any,
                    fontColor: 'black',
                    lineWidth: 0,
                    pointStyle: 'circle'
                  }));
                }
              },
              rtl: false
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x'
          },
          scales: {
            x: {
              type: 'time',
              grid: {
                drawBorder: false,
                lineWidth: 0
              },
              ticks: {
                autoSkip: false,
                maxRotation: 0,
                major: {
                  enabled: true
                },
                callback
              },
              time
            },
            y: {
              min: 0,
              grid: {
                drawBorder: false
              },
              ticks: {
                callback: (val) => currencyFormatter.format(Number(val))
              }
            }
          }
        }}
        data={{
          datasets: buildDatasets()
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentYearDataPoints, previousYearDataPoints]
  );

  return (
    <>
      {chart}
      {tooltipData != null ? <LineGraphTooltip {...tooltipData} /> : null}
    </>
  );
};
