import { EmailBlockTypes } from './emailTypes';
import { BlockTypes, HostedPageBlockTypes } from './givingFormTypes';

export enum ElementLibraryGivingFormViews {
  RootView = 'RootView',
  HeaderView = 'HeaderView',
  FooterView = 'FooterView',
  GiftOptionsView = 'GiftOptionsView',
  ImpactView = 'ImpactView',
  PaymentSectionView = 'PaymentSectionView',
  RecurringOptionsView = 'RecurringOptionsView',
  RecurringGiftPromptView = 'RecurringGiftPromptView',
  CustomContentBlockView = 'CustomContentBlockView',
  CustomFieldView = 'CustomFieldView',
  OptInOptionsView = 'OptInOptionsView',
  DesignationsBlockView = 'DesignationsBlockView',
  CorporateMatchingView = 'CorporateMatchingView',
  ThankYouRootView = 'ThankYouRootView',
  ThankYouHeaderView = 'ThankYouHeaderView',
  ThankYouMessageBodyView = 'ThankYouMessageBodyView',
  ThankYouMessageDonationDetailsView = 'ThankYouMessageDonationDetailsView',
  ThankYouAccountInvitationView = 'ThankYouAccountInvitationView',
  ThankYouCorporateMatchingView = 'ThankYouCorporateMatchingView',
  ThankYouGiftView = 'ThankYouGiftView',
  TributeView = 'TributeView'
}

export enum ElementLibraryEmailViews {
  RootView = 'RootView',
  Header = 'Header',
  CustomContent = 'CustomContent',
  EmailDetails = 'EmailDetails'
}

export enum ElementLibraryHostedPageViews {
  RootView = 'RootView',
  HeaderView = 'HeaderView',
  CustomContentBlockView = 'CustomContentBlockView',
  GoalMeterView = 'GoalMeterView',
  FooterView = 'FooterView'
}

export const ElementLibraryGivingFormViewMap: {
  [key in BlockTypes]: ElementLibraryGivingFormViews;
} = {
  [BlockTypes.GiftOptionBlock]: ElementLibraryGivingFormViews.GiftOptionsView,
  [BlockTypes.PaymentSection]: ElementLibraryGivingFormViews.PaymentSectionView,
  [BlockTypes.PaymentOptionsBlock]:
    ElementLibraryGivingFormViews.PaymentSectionView,
  [BlockTypes.CoverTransactionFeeBlock]:
    ElementLibraryGivingFormViews.PaymentSectionView,
  [BlockTypes.RecurringOptions]:
    ElementLibraryGivingFormViews.RecurringOptionsView,
  [BlockTypes.RecurringGiftPrompt]:
    ElementLibraryGivingFormViews.RecurringGiftPromptView,
  [BlockTypes.CustomContentBlock]:
    ElementLibraryGivingFormViews.CustomContentBlockView,
  [BlockTypes.CustomFieldBlock]: ElementLibraryGivingFormViews.CustomFieldView,
  [BlockTypes.EmailOptInBlock]: ElementLibraryGivingFormViews.OptInOptionsView,
  [BlockTypes.GiveAnonymouslyBlock]:
    ElementLibraryGivingFormViews.PaymentSectionView,
  [BlockTypes.DesignationsBlock]:
    ElementLibraryGivingFormViews.DesignationsBlockView,
  [BlockTypes.CorporateMatchingBlock]:
    ElementLibraryGivingFormViews.CorporateMatchingView,
  [BlockTypes.ThankYouMessageBodyBlock]:
    ElementLibraryGivingFormViews.ThankYouMessageBodyView,
  [BlockTypes.ThankYouMessageDonationDetailsBlock]:
    ElementLibraryGivingFormViews.ThankYouMessageDonationDetailsView,
  [BlockTypes.ThankYouHeaderBlock]:
    ElementLibraryGivingFormViews.ThankYouHeaderView,
  [BlockTypes.ThankYouAccountInvitationBlock]:
    ElementLibraryGivingFormViews.ThankYouAccountInvitationView,
  [BlockTypes.ThankYouCorporateMatchingBlock]:
    ElementLibraryGivingFormViews.ThankYouCorporateMatchingView,
  [BlockTypes.TributeBlock]: ElementLibraryGivingFormViews.TributeView,
  [BlockTypes.ThankYouGiftBlock]:
    ElementLibraryGivingFormViews.ThankYouGiftView,
  /**
   * The below mapping does not actually come into play at any point, but is required because PageBreakBlock
   * is the only block that doesnt have configuration in the element library (it lives in look and feel)
   */

  [BlockTypes.PageBreakBlock]: ElementLibraryGivingFormViews.RootView
};

export const ElementLibraryEmailViewMap: {
  [key in EmailBlockTypes]: ElementLibraryEmailViews;
} = {
  [EmailBlockTypes.Header]: ElementLibraryEmailViews.Header,
  [EmailBlockTypes.CustomContent]: ElementLibraryEmailViews.CustomContent
};

export const ElementLibraryHostedPageViewMap: {
  [key in HostedPageBlockTypes]: ElementLibraryHostedPageViews;
} = {
  [HostedPageBlockTypes.HostedPageHeaderBlock]:
    ElementLibraryHostedPageViews.HeaderView,
  [HostedPageBlockTypes.CustomContent]:
    ElementLibraryHostedPageViews.CustomContentBlockView,
  [HostedPageBlockTypes.GoalMeterBlock]:
    ElementLibraryHostedPageViews.GoalMeterView,
  [HostedPageBlockTypes.HostedPageFooterBlock]:
    ElementLibraryHostedPageViews.FooterView
};
