import { FC } from 'react';
import { Box } from '@mui/material';
import Select from 'components/Select';
import Text from 'components/Text';

type Font<T> = {
  value: T;
  icon: FC;
};

interface IFontSelectProps<T> {
  startingFont: T;
  updateFont: (newFont: T) => void;
  fontOptions: Font<T>[];
}

const FontSelect = <T extends string>({
  startingFont,
  updateFont,
  fontOptions
}: IFontSelectProps<T>) => (
  <Box className="FontSelectContainer">
    <Text variant="h3">Font Style</Text>
    <Select
      defaultValue={startingFont}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(e: any) => updateFont(e.target.value)}
      options={fontOptions}
    />
  </Box>
);

export default FontSelect;
