import { Fragment, useEffect, useMemo, useState } from 'react';
import { Autocomplete, AutocompleteProps, TextFieldProps } from '@mui/material';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { SelectCheckboxMenuItem } from 'components';
import Icon, { ICONS } from 'components/Icon';
import TextField from 'components/TextField';
import './ComboCheckBox.scss';

export type Option = {
  label: string;
  value: unknown;
};

export type ComboCheckBoxProps = Omit<
  AutocompleteProps<Option, true, false, true>,
  'renderInput' | 'onChange' | 'defaultValue' | 'label'
> & {
  onChange: (selected: Option[]) => void;
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
  defaultValue?: Option[];
  label?: ((numSelected: number) => string) | string;
};

const ALL_UUID = uuid();

const ComboCheckBox = ({
  options,
  onChange,
  error,
  helperText,
  defaultValue,
  label,
  ...autocompleteProps
}: ComboCheckBoxProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    defaultValue ?? []
  );

  const inputLabelClassName = clsx('ComboCheckBox__label', {
    'ComboCheckBox__label--selected': selectedOptions.length
  });

  const allOptions = [{ label: 'Select All', value: ALL_UUID }, ...options];

  useEffect(() => {
    onChange(selectedOptions.filter((option) => option.value !== ALL_UUID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedOptions(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultValue)]);

  const computedLabel = useMemo(() => {
    if (!label) {
      return 'Select';
    }

    if (typeof label === 'string') {
      return label;
    }

    return label(selectedOptions.length);
  }, [label, selectedOptions.length]);

  return (
    <Autocomplete
      className="ComboCheckBox"
      disableClearable
      disableCloseOnSelect
      renderTags={() => null}
      multiple
      options={allOptions}
      onChange={(_event, value, reason) => {
        const values = value as Option[];
        if (reason === 'selectOption') {
          // select all is pressed
          if (values.find((v) => v.value === ALL_UUID)) {
            // if only some of the values are selected
            if (selectedOptions.length < options.length) {
              // select all
              setSelectedOptions(options as Option[]);
            } else {
              // unselect all
              setSelectedOptions([]);
            }
          } else {
            setSelectedOptions(values);
          }
        } else if (reason === 'removeOption') {
          setSelectedOptions(values.filter((v) => v.value !== ALL_UUID));
        }
      }}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) =>
        option.value === ALL_UUID ? (
          <Fragment key={ALL_UUID}>
            <SelectCheckboxMenuItem
              {...props}
              checked={selectedOptions.length >= options.length}
              label={option.label}
            />
            <hr className="ComboCheckBox__separator" />
          </Fragment>
        ) : (
          <SelectCheckboxMenuItem
            {...props}
            key={option.label}
            checked={selected}
            label={option.label}
          />
        )
      }
      popupIcon={<Icon icon={ICONS.CHEVRON_DOWN} />}
      value={selectedOptions}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={({ ...params }) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          className="ComboCheckBox__text-field"
          InputLabelProps={{
            shrink: false,
            className: inputLabelClassName
          }}
          label={computedLabel}
        />
      )}
      {...autocompleteProps}
    />
  );
};

export default ComboCheckBox;
