import { Theme, createTheme } from '@mui/material';

export const createSelectTheme = (currTheme: Theme): Theme =>
  createTheme(currTheme, {
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&&': {
              backgroundColor: currTheme.palette.common.white,
              maxHeight: '3rem',
              '&.MuiOutlinedInput-root': {
                borderRadius: '.5rem',

                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: currTheme.palette.primary.main
                }
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: currTheme.palette.lightGray.main,
                borderWidth: '1px !important'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: currTheme.palette.primary.main
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: currTheme.palette.red.main
              },
              '&:hover': {
                backgroundColor: currTheme.palette.lightestGray.main,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: currTheme.palette.lightGray.main
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: currTheme.palette.primary.main
                },
                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                  borderColor: currTheme.palette.red.main
                }
              },
              '&.Mui-disabled': {
                borderColor: currTheme.palette.lightGray.main,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: currTheme.palette.lightestGray.main
                },
                '&:hover': {
                  backgroundColor: currTheme.palette.common.white
                }
              }
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.MuiInputLabel-root': {
              fontFamily: currTheme.typography.body.fontFamily,
              color: currTheme.palette.darkGray.main,
              lineHeight: 'unset',
              '&[data-shrink="false"]': {
                transform: 'translate(0.875rem, 0.875rem) scale(1)'
              },
              '&.Mui-disabled': {
                color: currTheme.palette.lightGray.main
              }
            }
          }
        }
      }
    }
  });
