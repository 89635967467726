import { useEffect, useLayoutEffect, useState } from 'react';
import {
  FormControl,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  SelectChangeEvent
} from '@mui/material';
import Checkbox from 'components/Checkbox';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogTitle
} from 'components/Dialog';
import { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { useAppContext } from 'hooks/useAppContext';
import {
  useGetTemplateShares,
  useUpdateTemplateShares
} from 'queries/UseGivingForms';
import { useUser } from 'queries/UseUsers';
import { TemplateShare } from 'services/types/givingFormTypes';
import { IOrganization } from 'services/types/userTypes';
import './ShareTemplateModal.scss';

type ShareTemplateModalProps = {
  open: boolean;
  onClose: () => void;
  selectedTemplate: string;
};

export const ShareTemplateModal = ({
  open,
  onClose,
  selectedTemplate
}: ShareTemplateModalProps): JSX.Element => {
  const { data: user } = useUser();

  // store all child org
  const { selectedOrganization } = useAppContext();
  const [childOrgs, setChildOrgs] = useState<IOrganization[]>();
  const [selectedChildOrgs, setSelectedChildOrgs] = useState<string[]>([]);

  const { refetch: getTemplateShares } = useGetTemplateShares(
    {
      organizationId: selectedOrganization.id,
      templateId: selectedTemplate
    },
    {
      enabled: open
    }
  );

  const { mutateAsync: updateTemplateSharesMutation } =
    useUpdateTemplateShares();

  useEffect(() => {
    setChildOrgs(
      user?.organizations?.filter(
        (o) =>
          o.path.includes(`.${selectedOrganization.id}`) &&
          o.id !== selectedOrganization.id
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizations, selectedOrganization?.id]);

  useLayoutEffect(() => {
    if (open) {
      getTemplateShares().then((results) => {
        setSelectedChildOrgs(
          results?.data?.map((t: TemplateShare) => t.sharedOrganizationId)
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSaveUpdates = async () => {
    await updateTemplateSharesMutation({
      organizationId: selectedOrganization.id,
      sharedOrganizationIds: selectedChildOrgs,
      templateId: selectedTemplate
    });
  };

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    // Per MUI docs, this value is always a string array.
    setSelectedChildOrgs(event.target.value as string[]);
  };

  return (
    <Dialog
      className="share-template-modal"
      open={open}
      label="Share Template Modal"
      onClose={onClose}
    >
      <DialogTitle>Share This Template</DialogTitle>
      <DialogContent>
        <DialogContentDescription className="share-template-modal-description">
          Choose the child organizations that you want to share this template
          with:
        </DialogContentDescription>
        <FormControl fullWidth className="MultiselectCheckbox">
          <MuiSelect
            multiple
            value={selectedChildOrgs}
            onChange={handleChange}
            renderValue={() => (
              <Text variant="body">
                {selectedChildOrgs?.length || 0} organization(s) selected
              </Text>
            )}
            displayEmpty
            IconComponent={ICONS.CHEVRON_DOWN}
          >
            {childOrgs?.map((childOrg: IOrganization) => (
              <MuiMenuItem value={childOrg.id} key={childOrg.id}>
                <Checkbox checked={selectedChildOrgs?.includes(childOrg.id)} />
                <Text variant="body">{childOrg?.name}</Text>
              </MuiMenuItem>
            ))}
          </MuiSelect>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          onClick={(close) => {
            handleSaveUpdates();
            close();
          }}
          variant="primary"
        >
          Update
        </DialogActionButton>
        <DialogActionButton onClick={(close) => close()} variant="secondary">
          Cancel
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
