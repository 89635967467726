import { useState } from 'react';
import { useConfigContext } from 'hooks/useConfigContext';
import { HostedPageLayout, IGivingFormConfig } from 'types';
import { HostedPageSelectLayoutModal, HostedPageSlugModal } from '.';

type HostedPageCreateProps = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
};

export const HostedPageCreate = ({
  isOpen,
  setIsOpen
}: HostedPageCreateProps) => {
  const { configData } = useConfigContext<IGivingFormConfig>();
  const [isShowFirstModal, setIsShowFirstModal] = useState<boolean>(true);
  const [selectedLayout, setSelectedLayout] = useState<HostedPageLayout>();
  const [pageFavicon, setPageFavicon] = useState<string>();
  const [pageTitle, setPageTitle] = useState<string>(configData?.name);

  return (
    <>
      <HostedPageSelectLayoutModal
        open={isOpen && isShowFirstModal}
        onClose={() => {
          setIsOpen(false);
          setSelectedLayout(undefined);
        }}
        onNext={() => {
          setIsShowFirstModal(false);
        }}
        nextEnabled={!!selectedLayout}
        setLayoutSelection={setSelectedLayout}
        layoutSelection={selectedLayout}
        pageMetadata={{
          pageFavicon,
          setPageFavicon,
          pageTitle,
          setPageTitle
        }}
      />
      <HostedPageSlugModal
        selectedLayout={selectedLayout}
        pageFavicon={pageFavicon}
        pageTitle={pageTitle}
        openSlugModal={isOpen && !isShowFirstModal}
        onClose={() => {
          setIsOpen(false);
          setIsShowFirstModal(true);
          setSelectedLayout(undefined);
        }}
      />
    </>
  );
};
