import { UIEventHandler, useState } from 'react';
import { Divider } from '@mui/material';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import doubleClipboard from 'assets/images/double-clipboard.svg';
import Button from 'components/Button';
import Text from 'components/Text';
import { LimitedTextField } from 'components/TextField';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useAbTest, usePublishAbTestNotes } from 'queries/UseAbTests';
import { useUser } from 'queries/UseUsers';
import { AbTestNote } from 'types/abTypes';
import { AbTestNote as Note } from './AbTestNote';
import { AbTestNoteSkeleton } from './AbTestNoteSkeleton';
import './AbTestNotes.scss';

const NOTE_CHAR_LIMIT = 2000;

export const AbTestNotes = () => {
  const [pushAlert] = useAlerts();
  const { data: userData } = useUser();
  const { abTestId } = useParams();
  const { selectedOrganization } = useAppContext();

  const [notesData, setNotesData] = useState<AbTestNote[]>();
  const [contentValue, setContentValue] = useState<string>();
  const [showGradient, setShowGradient] = useState<boolean>(true);

  const handleNoteScroll: UIEventHandler<HTMLDivElement> = ({
    currentTarget
  }) =>
    setShowGradient(
      currentTarget.scrollHeight - currentTarget.scrollTop !==
        currentTarget.clientHeight
    );

  const organizationId = selectedOrganization?.id;

  const { isRefetching } = useAbTest(
    { organizationId, abTestId, includeMetrics: true },
    {
      onError: () => {
        pushAlert({
          title: 'Unable to fetch notes at this time',
          severity: 'error'
        });
      },
      onSuccess: (abTestData) => {
        setNotesData(abTestData.notes);
      },
      refetchOnMount: 'always'
    }
  );

  const setNotes = (notes: AbTestNote[]) => setNotesData(notes);

  const { mutateAsync: publishAbTestNote } = usePublishAbTestNotes(
    {
      content: contentValue,
      userId: userData.id,
      abTestId,
      organizationId
    },
    {
      onError: () => {
        pushAlert({
          title: 'Unable to publish note at this time',
          severity: 'error'
        });
      },
      onSuccess: (abTestData) => {
        setNotesData(abTestData.notes);
        setContentValue('');
        pushAlert({
          title: 'Note published successfully',
          severity: 'success'
        });
      }
    }
  );

  return (
    <div
      className={clsx('aside-container ab-test-notes', {
        'ab-test-notes-bottom-gradient':
          (notesData?.length || isRefetching) && showGradient,
        'ab-test-notes-loading': isRefetching
      })}
      onScroll={handleNoteScroll}
    >
      <LimitedTextField
        multiline
        fullWidth
        minRows={5}
        maxRows={5}
        onChange={(e) => setContentValue(e.target.value)}
        value={contentValue}
        maxChar={NOTE_CHAR_LIMIT}
        hiddenLabel
        placeholder="Enter Note"
      />
      <div className="button-and-header-container">
        <Button
          type="button"
          variant="primary"
          fullWidth
          disabled={!contentValue}
          onClick={() => publishAbTestNote()}
        >
          Submit Note
        </Button>
        <Divider>
          <Text variant="caption">Previous Activity</Text>
        </Divider>
      </div>
      <div className="previous-activity-container">
        {!isRefetching && !notesData?.length && (
          <div className="no-previous-notes-container">
            <Text variant="h3">No previous notes to display.</Text>
            <img src={doubleClipboard} alt="overlapping clipboards" />
          </div>
        )}
        {!isRefetching ? (
          notesData
            .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
            .map((note: AbTestNote) => (
              <Note
                key={note.id}
                userId={userData.id}
                note={note}
                organizationId={organizationId}
                setNotes={setNotes}
              />
            ))
        ) : (
          <>
            <AbTestNoteSkeleton />
            <AbTestNoteSkeleton />
          </>
        )}
      </div>
    </div>
  );
};
