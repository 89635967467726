export interface DateRange {
  startDate: Date | null;
  endDate: Date;
}

export enum DateRangeValues {
  OneWeek = '7',
  OneMonth = '30',
  ThreeMonths = '91',
  OneYear = '365',
  YearToDate = '-1',
  All = '0'
}

export enum DateRangeValueComparisonLabel {
  OneWeek = 'Last Week',
  OneMonth = 'Last Month',
  ThreeMonths = 'Last 3 Months',
  OneYear = 'Last 12 Months',
  YearToDate = 'YTD'
}

export enum NotificationTypes {
  MetricBased = 'MetricBased',
  TimeBased = 'TimeBased'
}
