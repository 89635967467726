import { FunctionComponent } from 'react';
import {
  Box,
  ButtonUnstyled,
  ButtonUnstyledProps,
  Icon as MuiIcon,
  Stack
} from '@mui/material';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { useConfigContext } from 'hooks/useConfigContext';
import { ElementLibraryHostedPageViews, IGivingFormConfig } from 'types';
import './RootMenu.scss';

const RootViewMenuItem = ({
  children,
  icon,
  ...props
}: ButtonUnstyledProps & { icon: FunctionComponent }) => (
  <Box className="ElementLibrary-RootMenu--item">
    <ButtonUnstyled {...props}>
      <Stack
        className="ElementLibrary-RootMenu--item-container"
        direction="row"
      >
        <MuiIcon component={icon} />
        <Text variant="h4">{children}</Text>
        <Icon icon={ICONS.CHEVRON_RIGHT} />
      </Stack>
    </ButtonUnstyled>
  </Box>
);

const RootViewContent = ({
  setView
}: {
  setView: (view: ElementLibraryHostedPageViews) => void;
}): JSX.Element => {
  const { setHighlightedBlock } = useConfigContext<IGivingFormConfig>();

  return (
    <Stack id="ElementLibrary-RootMenu">
      <Box>
        <Text variant="h3">Commonly Used</Text>
        <RootViewMenuItem
          onClick={() => {
            setHighlightedBlock('header');
            setView(ElementLibraryHostedPageViews.HeaderView);
          }}
          icon={ICONS.LAYOUT_NAVBAR}
        >
          Header
        </RootViewMenuItem>
        <RootViewMenuItem
          onClick={() => {
            setView(ElementLibraryHostedPageViews.CustomContentBlockView);
          }}
          icon={ICONS.MESSAGE2}
        >
          Custom Content
        </RootViewMenuItem>
        <RootViewMenuItem
          onClick={() => {
            setHighlightedBlock('goalMeter');
            setView(ElementLibraryHostedPageViews.GoalMeterView);
          }}
          icon={ICONS.STARS_THREE}
        >
          Goal Meter
        </RootViewMenuItem>
        <RootViewMenuItem
          onClick={() => {
            setHighlightedBlock('footer');
            setView(ElementLibraryHostedPageViews.FooterView);
          }}
          icon={ICONS.LAYOUT_BOTTOMBAR}
        >
          Footer
        </RootViewMenuItem>
      </Box>
    </Stack>
  );
};

export default RootViewContent;
