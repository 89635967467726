import { clsx } from 'clsx';
import { parseISO } from 'date-fns';
import Chip from 'components/Chip';
import Icon, { ICONS } from 'components/Icon';
import ProgressBar from 'components/ProgressBar';
import Text from 'components/Text';
import { fundingPercentage, getProgressBarAmount } from 'utils/chartHelpers';
import { formatDate, remainingDays } from 'utils/dateHelpers';
import { usdCurrencyFormatter } from 'utils/formatters';
import './CampaignPreviewCard.scss';

type CampaignPreviewCardProps = {
  campaignAmountRaised: number;
  title: string;
  campaignGoal?: number;
  campaignStartDate: string;
  campaignEndDate?: string;
  onCampaignClick: () => void;
  isArchived: boolean;
};

/**
 * A card used to display a campaign
 */
const CampaignPreviewCard = ({
  campaignAmountRaised,
  title,
  campaignGoal,
  campaignStartDate,
  campaignEndDate,
  onCampaignClick,
  isArchived
}: CampaignPreviewCardProps): JSX.Element => {
  const amountRaisedClassName = clsx({
    'campaign-amount-raised-archived': isArchived
  });
  const progressBarClassName = clsx({
    'campaign-progress-bar-archived': isArchived
  });

  const renderCampaignCardChip = () => {
    if (isArchived) {
      return <Chip className="campaign-card-chip-archived" label="Archived" />;
      // eslint-disable-next-line no-else-return
    } else if (campaignGoal > 0) {
      return (
        <Chip
          label={
            fundingPercentage(campaignAmountRaised, campaignGoal) > 100
              ? `Over Funded`
              : `${fundingPercentage(
                  campaignAmountRaised,
                  campaignGoal
                )}% Funded`
          }
        />
      );
    } else {
      return null;
    }
  };

  return (
    <button
      type="button"
      className={clsx('campaign-preview-card', {
        'campaign-preview-card-archived': isArchived
      })}
      onClick={onCampaignClick}
    >
      <div className="campaign-preview-card-header">
        <Text variant="h2">{title}</Text>
        {renderCampaignCardChip()}
      </div>
      <Text variant="caption" className="date-range">{`${formatDate(
        campaignStartDate
      )} to ${formatDate(campaignEndDate) || 'Present'}`}</Text>
      <div className="campaign-preview-card-progress-bar">
        <ProgressBar
          className={progressBarClassName}
          variant="determinate"
          value={getProgressBarAmount(campaignAmountRaised, campaignGoal)}
        />
      </div>
      <div className="campaign-preview-card-footer">
        <div className="campaign-preview-card-footer-left">
          <Text className={amountRaisedClassName} variant="h8" fontWeight={900}>
            {usdCurrencyFormatter.format(campaignAmountRaised || 0)}
          </Text>
          {campaignGoal > 0 && (
            <Text variant="h8" className="campaign-preview-card-goal-amount">
              &nbsp;{`of ${usdCurrencyFormatter.format(campaignGoal)}`}
            </Text>
          )}
          <Text variant="h8" className="campaign-preview-card-goal-amount">
            &nbsp;raised
          </Text>
        </div>
        {parseISO(campaignEndDate) > new Date() && (
          <div className="campaign-preview-card-footer-right">
            <Icon
              className="campaign-preview-card-alarm-icon"
              icon={ICONS.ALARM}
              viewBox="0 0 24 24"
            />
            <Text className="campaign-preview-card-days-left" variant="body">
              {`${remainingDays(
                new Date(),
                parseISO(campaignEndDate)
              )} Days Left`}
            </Text>
          </div>
        )}
      </div>
    </button>
  );
};

export default CampaignPreviewCard;
