import { Subject } from 'rxjs';
import { Logger } from 'services/Logger';

export enum GivingFormModes {
  EDIT = 'EDIT',
  PREVIEW = 'PREVIEW'
}

export enum EditorEventTypes {
  BlockOrderUpdate = 'BlockOrderUpdate',
  BodyHeightUpdate = 'BodyHeightUpdate',
  ConfigurationUpdate = 'ConfigurationUpdate',
  DeleteBlock = 'DeleteBlock',
  DeselectBlock = 'DeselectBlock',
  EditBlock = 'EditBlock',
  EditThankYouBlock = 'EditThankYouBlock',
  FormPageChange = 'FormPageChange',
  OrgInfoUpdate = 'OrgInfoUpdate', // passes organization name and logo URL to editor preview
  PageLoaded = 'PageLoaded', // Event used to signal page has loaded data from API's
  PageReady = 'PageReady', // Event used to signal the React app itself has loaded, independent of other data needed
  PreviewZoom = 'PreviewZoom',
  SetBannerImage = 'SetBannerImage',
  SetPreviewGivingForm = 'SetPreviewGivingForm',
  SetPreviewThankYou = 'SetPreviewThankYou',
  ThankYouBlockOrderUpdate = 'ThankYouBlockOrderUpdate',
  ToggleRecurringGiftPrompt = 'ToggleRecurringGiftPrompt' // this event is used to render the Recurring Gift Prompt in the Giving Form while in Edit mode
}

export enum ZoomEmitValues {
  Increase = 'Increase',
  Decrease = 'Decrease',
  FitToWidth = 'FitToWidth',
  FitToHeight = 'FitToHeight'
}

export interface EventHubEvent {
  name: string;
  payload: any;
}

export class EventHub {
  private messageChannel = new MessageChannel();

  private eventSubject = new Subject<EventHubEvent>();

  private logger: Logger;

  constructor(
    iframe: any,
    frameOrigin: string,
    options: { props?: any; logger?: Logger } = {}
  ) {
    this.logger = options.logger;
    this.messageChannel.port1.onmessage = (e) => {
      if (this.logger) {
        const event: EventHubEvent = e.data;
        this.logger.log(`🔼 Event: "${event.name}"`, event.payload);
      }
      this.eventSubject.next(e.data);
    };
    iframe.contentWindow.postMessage(
      { name: 'EventHub', props: options.props },
      frameOrigin,
      [this.messageChannel.port2]
    );
  }

  subscribe(name: string, callback: (e: EventHubEvent) => void) {
    this.eventSubject.subscribe((event: EventHubEvent) => {
      if (event.name === name || name === '*') {
        callback(event);
      }
    });
  }

  emit(name: string, payload: any) {
    const event: EventHubEvent = { name, payload };
    if (this.logger) {
      this.logger.log(`🔽 Event: "${event.name}"`, event.payload);
    }
    this.messageChannel.port1.postMessage(event);
  }
}

export default EventHub;
