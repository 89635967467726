import { useAppContext } from './useAppContext';

export enum OrganizationRole {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  Editor = 'Editor',
  Viewer = 'Viewer'
}

const roleMap = {
  SuperAdmin: 4,
  Admin: 3,
  Editor: 2,
  Viewer: 1
};

export const getRoleInt = (role: OrganizationRole): number => roleMap[role];

export const useRenderIfRole = () => {
  const { selectedOrganization } = useAppContext();

  const renderIfRole = (
    component: JSX.Element,
    minRole: OrganizationRole
  ): JSX.Element | null => {
    let role;
    if (selectedOrganization) {
      role = selectedOrganization.role;
    }

    if (!role) return null;
    if (getRoleInt(role) < getRoleInt(minRole)) return null;

    return component;
  };

  return { renderIfRole };
};
