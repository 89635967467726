import { useState } from 'react';
import { Box, CircularProgress, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import createAbTestExample from 'assets/images/create-ab-test-example.png';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import Text from 'components/Text';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useValidateAbTestName } from 'queries/UseAbTests';
import './NewAbTestDialog.scss';

interface NewAbTestDialogProps {
  campaignId: string;
  givingFormId: string;
  open: boolean;
  onClose: () => void;
}

export const NewAbTestDialog = ({
  campaignId,
  givingFormId,
  open,
  onClose
}: NewAbTestDialogProps) => {
  const [testName, setTestName] = useState('');
  const navigate = useNavigate();
  const [addAlert] = useAlerts();
  const [valid, setValid] = useState(true);
  const { selectedOrganization, setNewAbTestName } = useAppContext();
  const organizationId = selectedOrganization?.id;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!valid) {
      setValid(true);
    }
    setTestName(e.currentTarget.value);
  };

  const { mutateAsync: checkValidAbTestName, isLoading: isCheckingValidity } =
    useValidateAbTestName({
      organizationId,
      campaignId,
      name: testName
    });

  const checkValid = async () => {
    try {
      const isValid = await checkValidAbTestName();
      setValid(isValid);
      return isValid;
    } catch (error) {
      addAlert({
        title:
          "Uh oh. Looks like there was an error validating the uniqueness of the A/B test's name.",
        severity: 'error'
      });
      setValid(false);
      return false;
    }
  };

  const onCloseDialog = (close: () => void) => {
    setTestName('');
    setValid(true);
    close();
  };

  const onStartBuilding = async (close: () => void) => {
    const isValid = await checkValid();

    if (isValid) {
      // Save A/B Test name for the create wizard
      setNewAbTestName(testName);

      onCloseDialog(close);
      // navigate to the Create A/B test wizard flow
      navigate(`/strategy/ab-test/create/${givingFormId}`);
    }
  };

  return (
    <Dialog
      className="new-ab-test-dialog"
      label="new ab test dialog"
      open={open}
      onClose={() => onCloseDialog(onClose)}
    >
      <DialogTitle onClick={(close) => onCloseDialog(close)}>
        Create A/B Test
      </DialogTitle>
      <DialogContent>
        <Stack direction="row">
          <Box className="left-form">
            <DialogContentTitle>
              What are we calling this A/B Test?
            </DialogContentTitle>
            <Text variant="body" className="description">
              To start creating your A/B Test, we need to give it a unique name.
            </Text>
            <TextField
              value={testName}
              placeholder="Enter Test Name"
              hiddenLabel
              onChange={handleChange}
              error={!valid}
              helperText={
                !valid
                  ? `"${testName}" is already being used by another A/B Test in this campaign. Please pick a unique name.`
                  : undefined
              }
            />
          </Box>
          <img src={createAbTestExample} alt="ab testing example" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          variant="primary"
          onClick={(close) => {
            onStartBuilding(close);
          }}
          disabled={testName === '' || isCheckingValidity}
        >
          {isCheckingValidity ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Start Building'
          )}
        </DialogActionButton>
        <DialogActionButton
          variant="secondary"
          onClick={(close) => {
            onCloseDialog(close);
          }}
        >
          Back to Giving Forms
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
