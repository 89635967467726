import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Blocker,
  BreadcrumbsProps,
  ButtonProps,
  EmptyStateTextDisplay
} from 'components';
import Icon, { ICONS } from 'components/Icon';
import { Preview } from 'components/PreviewModal';
import { AbTestPreviewCard } from 'components/gms/AbTestPreviewCard';
import { ChannelPreviewGrid } from 'components/gms/ChannelPreviewGrid';
import { EndAbTestModalFlow } from 'components/gms/EndAbTestModal';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useGetAllAbTests } from 'queries/UseAbTests';
import { useUser } from 'queries/UseUsers';
import editsRepositoryApi from 'services/editsRepositoryService';
import { AbTest } from 'types';
import './AbTests.scss';

type LocationState = {
  abTestPublishErrorMessage?: string;
};

export const AbTests = () => {
  const { selectedOrganization } = useAppContext();
  const {
    data: { id: userId }
  } = useUser();

  const navigate = useNavigate();
  const location = useLocation();
  const { abTestPublishErrorMessage } = {
    ...(location.state as LocationState)
  };
  const [pushAlert] = useAlerts();
  const { data: allAbTests, isLoading } = useGetAllAbTests(
    { organizationId: selectedOrganization.id, includeMetrics: true },
    {
      onError: () => {
        pushAlert({
          title: 'Uh oh. Looks like there was an error loading your A/B tests.',
          severity: 'error'
        });
      }
    }
  );

  const [allAbTestDrafts, setAllAbTestDrafts] = useState<AbTest[]>([]);
  const [endAbTestModalId, setEndAbTestModalId] = useState<string | null>(null);
  const [previewModalProps, setPreviewModalProps] = useState<{
    isOpen: boolean;
    givingFormId: string;
    configData: null;
    abTest: AbTest;
  }>({ isOpen: false, givingFormId: '', configData: null, abTest: null });

  const getAllAbTestDrafts = async () => {
    try {
      const allAbTestEditsIds = await editsRepositoryApi.getAllEdits(
        'abTest',
        userId
      );

      const allDrafts: AbTest[] = await Promise.all(
        allAbTestEditsIds.map(async (edit) => {
          // Get draft from storage
          const draft = (
            await editsRepositoryApi.getEditsById('abTest', edit.id, userId)
          ).config as AbTest;

          return draft;
        })
      );
      setAllAbTestDrafts(allDrafts);
    } catch (e) {
      pushAlert({
        title: 'There was an error loading your A/B test drafts.',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    getAllAbTestDrafts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (abTestPublishErrorMessage) {
      // if the user is redirected to this page after publishAbTest returns
      // an error, we need to populate that error here
      pushAlert({
        title: abTestPublishErrorMessage,
        severity: 'error'
      });
    }
    window.history.replaceState({}, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestPublishErrorMessage]);

  /** - LC
   * Because of the way the data is returned, we need to structure it in a way
   * it can be consumed by our component. This function returns an object of
   * key/value pairs. Each key is the title of the campaign and the array is
   * the tests that belong to that specific campaign.
   */
  const createCampaignAbTests = useCallback(
    (abTests: AbTest[]): Record<string, AbTest[]> =>
      !isLoading &&
      abTests.reduce<Record<string, AbTest[]>>((acc, curr) => {
        if (acc[curr?.campaign?.name] === undefined) {
          acc[curr?.campaign?.name] = [curr];
        } else {
          acc[curr?.campaign?.name].push(curr);
        }
        return acc;
      }, {}),
    [isLoading]
  );

  const sortedAbTests = createCampaignAbTests([
    ...(allAbTests || []),
    ...allAbTestDrafts
  ]);

  const breadcrumbsProps: BreadcrumbsProps = {
    breadcrumbs: [
      { label: 'Strategy', path: '/strategy' },
      { label: 'A/B Tests', path: '/strategy/ab-test' }
    ]
  };

  const primaryButtonProps: ButtonProps = {
    children: 'New A/B Tests',
    startIcon: <Icon icon={ICONS.PLUS} sx={{ strokeWidth: 3 }} />,
    onClick: () => navigate('/strategy/ab-test/giving-forms')
  };

  const onEndAbTestClick = (abTestId: string) => {
    setEndAbTestModalId(abTestId);
  };

  const onPreviewClick = (abTest: AbTest) => {
    setPreviewModalProps({
      isOpen: true,
      givingFormId: abTest.givingForm.id,
      configData: null,
      abTest
    });
  };

  return (
    <div>
      <Blocker block={isLoading}>
        <FeatureHeader
          titleProps="A/B Tests"
          breadcrumbsProps={breadcrumbsProps}
          primaryButtonProps={primaryButtonProps}
        />
        <div className="ab-tests-cards-container fluid-container">
          {!isLoading && Object.keys(sortedAbTests).length === 0 && (
            <EmptyStateTextDisplay
              heading="You don’t currently have any A/B Tests."
              subheading="Start by creating an A/B test for one of your giving forms."
            />
          )}
          {Object.keys(sortedAbTests).map((campaignName) => (
            <ChannelPreviewGrid key={campaignName} title={campaignName}>
              {sortedAbTests[campaignName].map((abTest: AbTest) => (
                <AbTestPreviewCard
                  abTest={abTest}
                  // creating unique key for each test card;
                  // cannot use abTest.abTestId because drafts do not have IDs
                  key={abTest.name + abTest.campaign.id}
                  onDraftDiscarded={() => getAllAbTestDrafts()}
                  onEndAbTest={onEndAbTestClick}
                  onPreviewClick={() => onPreviewClick(abTest)}
                />
              ))}
            </ChannelPreviewGrid>
          ))}
        </div>
        <EndAbTestModalFlow
          isOpen={!!endAbTestModalId}
          abTestId={endAbTestModalId}
          onRequestClose={() => setEndAbTestModalId(null)}
          returnButtonText="Back to A/B Tests"
        />
        {previewModalProps.isOpen && (
          <Preview
            onClose={() =>
              setPreviewModalProps({
                isOpen: false,
                givingFormId: null,
                configData: null,
                abTest: null
              })
            }
            {...previewModalProps}
          />
        )}
      </Blocker>
    </div>
  );
};
