import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogTitle
} from 'components/Dialog';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import { generateEmbed } from 'services/embedGenerator';
import { resolvedEnvironment } from 'services/environment';
import { SuccessDialog } from '..';
import './PublishFirstTimeDialog.scss';

type PublishFirstTimeDialogProps = {
  open: boolean;
  onClose: () => void;
  campaignName: string;
};

/**
 * A GMS-specific dialog for allowing the user to select a channel and add it
 * to an existing campaign.
 */
export const PublishFirstTimeDialog = ({
  open,
  onClose,
  campaignName
}: PublishFirstTimeDialogProps) => {
  const { campaignId, givingFormId } = useParams();
  const navigate = useNavigate();
  const [showCopied, setShowCopied] = useState<boolean | undefined>(undefined);
  const embedScriptText = generateEmbed(
    'giving-form',
    givingFormId,
    resolvedEnvironment.appsBaseUrl
  );
  return (
    <SuccessDialog
      className="publish-first-time-dialog"
      label="First Publish Dialog"
      open={open}
      onClose={onClose}
    >
      <DialogTitle onClick={(close) => close()}>Congratulations!</DialogTitle>
      <DialogContent>
        <DialogContentDescription>
          Your Giving Form has been published
        </DialogContentDescription>
        <Stack>
          <Text variant="body">
            This Giving Form is now available in your {campaignName} campaign
            home
          </Text>
          <Text variant="h5" color="darkGray.main" mt={1} mb={1.25}>
            Embed Code
          </Text>{' '}
          <Tooltip
            title={
              showCopied ? 'Copied to clipboard' : 'Click to copy embed code'
            }
          >
            <Box
              className="publish-first-time-dialog__embed-code"
              onClick={() => {
                window.navigator.clipboard.writeText(embedScriptText);
                setShowCopied(true);

                setTimeout(() => {
                  setShowCopied(undefined);
                }, 2000);
              }}
            >
              <Icon
                icon={ICONS.COPY}
                color="primary"
                className="publish-first-time-dialog__copy-icon"
              />
              <code>{embedScriptText}</code>
            </Box>
          </Tooltip>
        </Stack>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          variant="primary"
          onClick={() =>
            navigate(`/campaigns/${campaignId}/giving-forms/${givingFormId}`)
          }
        >
          Form Overview
        </DialogActionButton>
        <DialogActionButton
          variant="secondary"
          onClick={() => navigate(`/campaigns/${campaignId}`)}
        >
          Campaign Home
        </DialogActionButton>
      </DialogActions>
    </SuccessDialog>
  );
};
