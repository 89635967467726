import { useEffect, useState } from 'react';
import { FormControlLabel, Skeleton } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Blocker, LimitedTextField } from 'components';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import DatePicker from 'components/DatePicker';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { TextFieldWithChips } from 'components/TextField';
import Tooltip from 'components/Tooltip';
import { useAlerts } from 'hooks';
import { OrganizationRole, useRenderIfRole } from 'hooks/useRenderIfRole';
import { useDesignationGroups } from 'queries/UseDesignations';
import { DesignationGroup } from 'services';
import { formatIsoDate } from 'utils';
import { formatDesignationDate } from 'utils/designations';
import { DesignationsProps } from '../Designations';
import {
  AdvancedDesignationsSchema,
  IAdvancedDesignations
} from './AdvancedDesignations.schema';
import { AdvancedDesignationsImageManager } from './AdvancedDesignationsImageManager';
import { ConfirmDesignationOverwrtieModal } from './ConfirmDesignationOverwriteModal';
import { DeleteDesignationsGroupModal } from './DeleteDesignationsGroupModal';
import { NewDesignationsGroupModal } from './NewDesignationsGroupModal';

export const AdvancedDesignations = ({
  allDesignationsInOrg,
  allDesignationsIsLoading,
  checkFundIdIsUnique,
  confirmOverwriteModalIsOpen,
  createDesignationMutation,
  designationIdParam,
  idTooltip,
  organizationId,
  renderButtonContent,
  setConfirmOverwriteModalIsOpen,
  setCurrentFundId,
  updateDesignationMutation,
  toggleDesignationStatus
}: DesignationsProps) => {
  const [selectedGroups, setSelectedGroups] = useState<DesignationGroup[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [image, setImage] = useState<string>(null);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [designationGroupToDelete, setDesignationGroupToDelete] =
    useState<DesignationGroup>(null);
  const [newGroupModalIsOpen, setNewGroupModalIsOpen] = useState(false);
  const [deleteGroupModalIsOpen, setDeleteGroupModalIsOpen] = useState(false);
  const { renderIfRole } = useRenderIfRole();
  const [pushAlert] = useAlerts();
  const imageTooltip = 'File size should not exceed 10MB';

  const {
    data: allDesignationGroups,
    isLoading: groupsLoading,
    isFetching: groupsFetching
  } = useDesignationGroups(organizationId, {
    onError: () => {
      pushAlert({
        title: 'There was an error loading your designation groups.',
        severity: 'error'
      });
    }
  });

  const methods = useForm<IAdvancedDesignations>({
    resolver: yupResolver(AdvancedDesignationsSchema),
    mode: 'onBlur'
  });

  useEffect(() => {
    if (!!designationIdParam && !allDesignationsIsLoading) {
      const {
        title,
        code,
        startDate,
        endDate,
        description,
        tags: currentTags,
        groups,
        image: currentImage,
        isActive: currentIsActive
      } = allDesignationsInOrg.find((des) => des.id === designationIdParam);
      // form state renders before designations are finished loading, resetting field values
      // to current values from db once loaded
      methods.setValue('title', title ?? '');
      methods.setValue('code', code ?? '');
      methods.setValue(
        'startDate',
        startDate ? formatIsoDate(startDate, 'MM/dd/yyyy', 'UTC') : undefined
      );
      methods.setValue(
        'endDate',
        endDate ? formatIsoDate(endDate, 'MM/dd/yyyy', 'UTC') : undefined
      );
      methods.setValue('description', description ?? '');
      setTags(currentTags);
      setSelectedGroups(groups);
      setImage(currentImage);
      setCurrentFundId(code);
      setIsActive(currentIsActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDesignationsInOrg, designationIdParam, allDesignationsIsLoading]);

  const fundId = methods.watch('code');

  const handleGroupsOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    group: DesignationGroup
  ) => {
    let newSelectedGroups: DesignationGroup[];
    if (e.target.checked) {
      newSelectedGroups = [...selectedGroups, group];
    } else {
      const groupIndex = selectedGroups.findIndex((g) => g.id === group.id);
      /* old version of typescript doesn't recognize toSpliced as an array method */
      /* eslint-disable-next-line */
      /* @ts-ignore */
      newSelectedGroups = selectedGroups.toSpliced(groupIndex, 1);
    }
    setSelectedGroups(newSelectedGroups);
  };

  const handleOpenDeleteGroupModal = (
    event: React.MouseEvent,
    group: DesignationGroup
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setDeleteGroupModalIsOpen(true);
    setDesignationGroupToDelete(group);
  };

  const handleOpenNewGroupModal = () => setNewGroupModalIsOpen(true);

  const handleFormSubmit = (formData: IAdvancedDesignations) => {
    const appendedData = {
      ...formData,
      startDate: formatDesignationDate(formData.startDate),
      endDate: formatDesignationDate(formData.endDate),
      tags,
      groups: selectedGroups,
      image,
      isActive: designationIdParam ? formData.isActive : true
    };
    if (designationIdParam) {
      // if we have an id param, hit the put endpoint
      updateDesignationMutation(appendedData);
    } else {
      // otherwise, we are creating a new designation, hit the post endpoint
      createDesignationMutation(appendedData);
    }
  };

  const handleSubmitOnClick = () => {
    const fundIdIsUnique = checkFundIdIsUnique(fundId);
    if (fundIdIsUnique) {
      methods.handleSubmit((formData) => handleFormSubmit(formData))();
    } else {
      setConfirmOverwriteModalIsOpen(true);
    }
  };

  const handleOverwriteOnConfirm = () => {
    setConfirmOverwriteModalIsOpen(false);

    methods.handleSubmit((formData) => handleFormSubmit(formData))();
  };

  const handleToggleStatus = () => {
    toggleDesignationStatus(!isActive);
  };

  return (
    <div className="advanced-designations-form">
      {/* Need blocker when editing a designation because we are waiting for all
        form fields to populate. When creating new designation, only need loading state
        for groups. */}
      <Blocker block={designationIdParam && allDesignationsIsLoading}>
        <FormProvider {...methods}>
          <form
            className="designations-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitOnClick();
            }}
          >
            <div className="designations-form-row">
              <Text
                className="designations-form-label center-label"
                variant="h3"
              >
                My designation is titled
              </Text>
              <Controller
                control={methods.control}
                defaultValue=""
                name="title"
                render={({
                  field: { ref, ...field },
                  fieldState: { error }
                }) => (
                  <LimitedTextField
                    {...field}
                    className="designations-form-field"
                    error={!!error}
                    helperText={error?.message ?? null}
                    hiddenLabel
                    maxChar={60}
                    placeholder="Enter Designation Title"
                  />
                )}
              />
            </div>
            <div className="designations-form-row">
              <div className="designations-form-label center-label">
                <Tooltip title={idTooltip} placement="top-start">
                  <div>
                    <Icon icon={ICONS.HELP} color="disabled" />
                  </div>
                </Tooltip>
                <Text variant="h3">My designation fund ID is</Text>
              </div>
              <Controller
                control={methods.control}
                defaultValue=""
                name="code"
                render={({
                  field: { ref, ...field },
                  fieldState: { error }
                }) => (
                  <LimitedTextField
                    {...field}
                    className="designations-form-field"
                    error={!!error}
                    helperText={error?.message ?? null}
                    hiddenLabel
                    maxChar={60}
                    placeholder="Enter Designation Fund ID"
                  />
                )}
              />
            </div>
            <div className="designations-form-row">
              <Text
                className="designations-form-label center-label"
                variant="h3"
              >
                The start and end date is between
              </Text>
              <div className="designations-form-field designations-date-pickers">
                <Controller
                  control={methods.control}
                  name="startDate"
                  render={({
                    field: { ref, onChange, ...field },
                    fieldState: { error }
                  }) => (
                    <DatePicker
                      error={!!error}
                      value={field.value}
                      onChange={(...args) => {
                        onChange(...args);
                        methods.trigger('startDate');
                      }}
                      TextFieldProps={{
                        ...field,
                        helperText: error?.message ?? null
                      }}
                    />
                  )}
                />
                <Text variant="h3">-</Text>
                <Controller
                  control={methods.control}
                  name="endDate"
                  render={({
                    field: { ref, onChange, ...field },
                    fieldState: { error }
                  }) => (
                    <DatePicker
                      error={!!error}
                      value={field.value}
                      onChange={(...args) => {
                        onChange(...args);
                        methods.trigger('startDate');
                        methods.trigger('endDate');
                      }}
                      TextFieldProps={{
                        ...field
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="designations-form-row">
              <Text className="designations-form-label top-label" variant="h3">
                Designation Description
              </Text>
              <Controller
                control={methods.control}
                defaultValue=""
                name="description"
                render={({
                  field: { ref, onChange, onBlur, ...field },
                  fieldState: { error }
                }) => (
                  <LimitedTextField
                    {...field}
                    className="designations-form-field"
                    error={!!error}
                    hiddenLabel
                    multiline
                    minRows={3}
                    maxChar={150}
                    maxRows={3}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder="Enter Designation Description"
                  />
                )}
              />
            </div>
            <div className="designations-form-row">
              <Text
                className="designations-form-label center-label"
                variant="h3"
              >
                Tags
              </Text>
              <Controller
                control={methods.control}
                name="tags"
                render={({ field: { ref, onChange, ...field } }) => (
                  <TextFieldWithChips
                    {...field}
                    className="designations-form-field advanced-designations-tags"
                    onChange={() => {
                      onChange();
                    }}
                    placeholder="Enter Tags"
                    setTags={setTags}
                    value={tags}
                  />
                )}
              />
            </div>
            <div className="designations-form-row">
              <Text className="designations-form-label top-label" variant="h3">
                Designation Grouping
              </Text>
              <div className="designations-form-field">
                <div className="designations-groups">
                  {groupsLoading || groupsFetching ? (
                    <div>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                    </div>
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {allDesignationGroups?.length > 0 &&
                        allDesignationGroups.map((group) => {
                          const selected = selectedGroups.find(
                            ({ id }) => id === group.id
                          );
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={group.name}
                                  checked={!!selected}
                                  onChange={(event) => {
                                    handleGroupsOnChange(event, group);
                                    // ensure the field is marked as "touched" by react-hook-form so the form can be submitted in edit mode
                                    methods.trigger('groups');
                                  }}
                                />
                              }
                              key={group.id}
                              label={
                                <div className="designations-groups-label">
                                  {group.name}
                                  {renderIfRole(
                                    <Icon
                                      icon={ICONS.X}
                                      onClick={(e) =>
                                        handleOpenDeleteGroupModal(e, group)
                                      }
                                    />,
                                    OrganizationRole.Editor
                                  )}
                                </div>
                              }
                            />
                          );
                        })}
                    </>
                  )}
                </div>
                <div
                  className="designations-groups-new"
                  onClick={handleOpenNewGroupModal}
                  onKeyDown={handleOpenNewGroupModal}
                  role="button"
                  tabIndex={0}
                >
                  <Icon icon={ICONS.PLUS} fontSize="small" />
                  {renderIfRole(
                    <Text variant="h4">Add New Group</Text>,
                    OrganizationRole.Editor
                  )}
                </div>
              </div>
            </div>
            <div className="designations-form-row">
              <div className="designations-form-label top-label">
                <Tooltip title={imageTooltip} placement="top-start">
                  <div>
                    <Icon icon={ICONS.HELP} color="disabled" />
                  </div>
                </Tooltip>
                <Text variant="h3">Designation Image</Text>
              </div>
              <div className="designations-form-field">
                <AdvancedDesignationsImageManager
                  image={image}
                  setImage={setImage}
                  setFieldToTouched={() => methods.trigger('imageUrl')}
                />
              </div>
            </div>

            <div className="designations-submit-button-container">
              {renderIfRole(
                <Button
                  fullWidth
                  type="submit"
                  disabled={
                    !methods.formState.isValid ||
                    groupsFetching ||
                    groupsLoading
                  }
                >
                  {renderButtonContent()}
                </Button>,
                OrganizationRole.Editor
              )}
              {renderIfRole(
                <Button
                  fullWidth
                  type="button"
                  variant="secondary"
                  onClick={() => handleToggleStatus()}
                >
                  {isActive ? 'Archive Designation' : 'Make Designation Active'}
                </Button>,
                OrganizationRole.Editor
              )}
            </div>
          </form>
        </FormProvider>
      </Blocker>

      {newGroupModalIsOpen && (
        <NewDesignationsGroupModal
          allGroupsForOrg={allDesignationGroups}
          isOpen={newGroupModalIsOpen}
          onClose={() => setNewGroupModalIsOpen(false)}
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          // ensure the field is marked as "touched" by react-hook-form so the form can be submitted in edit mode
          setFieldToTouched={() => methods.trigger('groups')}
        />
      )}

      {deleteGroupModalIsOpen && (
        <DeleteDesignationsGroupModal
          groupToDelete={designationGroupToDelete}
          isOpen={deleteGroupModalIsOpen}
          onClose={() => {
            setDeleteGroupModalIsOpen(false);
            setDesignationGroupToDelete(null);
          }}
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          // ensure the field is marked as "touched" by react-hook-form so the form can be submitted in edit mode
          setFieldToTouched={() => methods.trigger('groups')}
        />
      )}

      {confirmOverwriteModalIsOpen && (
        <ConfirmDesignationOverwrtieModal
          fundId={fundId}
          isOpen={confirmOverwriteModalIsOpen}
          onClose={() => setConfirmOverwriteModalIsOpen(false)}
          onConfirm={() => handleOverwriteOnConfirm()}
        />
      )}
    </div>
  );
};
