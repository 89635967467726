import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { ActiveMenu } from 'components/EditorMenus';
import { EditsSaved } from 'components/EditsSaved';
import { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import { Preview } from 'components/PreviewModal';
import Text from 'components/Text';
import DeleteBlockModal, {
  DeleteModalConfig
} from 'components/gms/DeleteBlockModal/DeleteBlockModal';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { useEditorIframeContext } from 'hooks/useEditorIframeContext';
import { useEventHubPreview } from 'hooks/useEventHubPreview';
import { useGivingFormByInstanceId } from 'queries/UseGivingForms';
import { EditorEventTypes } from 'services';
import {
  BlockBaseType,
  EditorMenus,
  EditorTypes,
  ElementLibraryGivingFormViews,
  GivingFormBlockType,
  IGivingFormConfig
} from 'types';
import {
  emitDeselect,
  emitSelect,
  emitSetPreviewGivingForm,
  emitSetPreviewThankYou,
  filterPremiumFeatures,
  getLibraryViewFromBlockId
} from 'utils';
import './GivingFormEditor.scss';

type GivingFormEditorProps = {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  deleteModalConfig: DeleteModalConfig;
  setDeleteModalConfig: Dispatch<SetStateAction<DeleteModalConfig>>;
  isAbTestWizard?: boolean;
};

export const GivingFormEditor = ({
  activeTab,
  setActiveTab,
  deleteModalConfig,
  setDeleteModalConfig,
  isAbTestWizard
}: GivingFormEditorProps): JSX.Element => {
  const { givingFormId, givingFormTemplateId } = useParams();
  const formId = givingFormId || givingFormTemplateId;
  const { refetch: getGivingForm } = useGivingFormByInstanceId(formId);
  const {
    configData,
    updateConfig,
    handleSetForm,
    discardEdits,
    highlightedBlock,
    setHighlightedBlock,
    isPublished
  } = useConfigContext<IGivingFormConfig>();
  const {
    blockOrderToUpdate,
    embedReadyToDisplay,
    eventHub,
    iframeHeight,
    iframeReadyCallback,
    thankYouBlockOrderToUpdate
  } = useEditorIframeContext();
  const { config } = configData;
  const [activeMenu, setActiveMenu] = useState<EditorMenus>(
    EditorMenus.GivingFormElementLibrary
  );
  const [libraryView, setLibraryView] = useState<ElementLibraryGivingFormViews>(
    ElementLibraryGivingFormViews.RootView
  );
  const [hasBeenFiltered, setHasBeenFiltered] = useState<boolean>(false);
  const [addAlert] = useAlerts();
  const {
    envConfig,
    organizationInfo: { donorSSP, features, acceptedPaymentOptions }
  } = useAppContext();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeSrc = `${envConfig?.appsBaseUrl}/giving-form/edit-${formId}`;
  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);

  useEventHubPreview({ iframeRef, configData, iframeReadyCallback });

  useEffect(() => {
    if (activeTab === 'GivingForm') {
      setLibraryView(ElementLibraryGivingFormViews.RootView);
    } else if (activeTab === 'ThankYou') {
      setLibraryView(ElementLibraryGivingFormViews.ThankYouRootView);
    }
    if (embedReadyToDisplay && activeTab === 'GivingForm') {
      emitSetPreviewGivingForm(eventHub);
    } else if (embedReadyToDisplay && activeTab === 'ThankYou') {
      emitSetPreviewThankYou(eventHub);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, embedReadyToDisplay]);

  // This logic has to be executed in a useEffect so that configData
  // always contains the latest information
  useEffect(() => {
    if (blockOrderToUpdate.length) {
      const newBlocks = blockOrderToUpdate.map((id: string) =>
        config.blocks.find((block) => block.id === id)
      );
      const newConfig = {
        ...config,
        blocks: newBlocks as GivingFormBlockType[] // .map above should never return undefined
      };
      updateConfig(newConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockOrderToUpdate]);

  useEffect(() => {
    if (thankYouBlockOrderToUpdate.length) {
      const newBlocks = thankYouBlockOrderToUpdate.map((id: string) =>
        config.thankYouConfig.blocks.find((block) => block.id === id)
      );
      const newConfig: IGivingFormConfig = {
        ...config,
        thankYouConfig: {
          ...config.thankYouConfig,
          blocks: newBlocks
        }
      };
      updateConfig(newConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thankYouBlockOrderToUpdate]);

  useEffect(() => {
    if (activeMenu !== EditorMenus.GivingFormElementLibrary) {
      emitDeselect(eventHub);
      setHighlightedBlock('');
      setLibraryView(ElementLibraryGivingFormViews.RootView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenu]);

  useEffect(() => {
    // if the user is editing the recurring gift prompt,
    // the GF needs to receive the config through the event hub
    // to determine if the prompt should be set to open when
    // that section of the element library is opened
    if (highlightedBlock === 'recurringGiftPrompt') {
      emitSelect(highlightedBlock, eventHub, config);
    } else {
      emitSelect(highlightedBlock, eventHub);
    }
    if (highlightedBlock === 'header') {
      setActiveMenu(EditorMenus.GivingFormElementLibrary);
      setLibraryView(ElementLibraryGivingFormViews.HeaderView);
    } else if (highlightedBlock === 'footer') {
      setActiveMenu(EditorMenus.GivingFormElementLibrary);
      setLibraryView(ElementLibraryGivingFormViews.FooterView);
    } else if (highlightedBlock === 'thankYouHeader') {
      setActiveMenu(EditorMenus.GivingFormElementLibrary);
      setLibraryView(ElementLibraryGivingFormViews.ThankYouHeaderView);
    } else if (
      [
        ...(configData?.config?.blocks || []),
        ...(configData?.config?.thankYouConfig?.blocks || [])
      ]?.find((block) => block.id === highlightedBlock)
    ) {
      const newLibraryView = getLibraryViewFromBlockId(
        highlightedBlock,
        EditorTypes.GivingForm,
        configData
      );
      const givingFormLibraryView =
        newLibraryView as ElementLibraryGivingFormViews;
      setActiveMenu(EditorMenus.GivingFormElementLibrary);
      setLibraryView(givingFormLibraryView);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedBlock]);

  useEffect(() => {
    if (config?.blocks && features && !hasBeenFiltered && eventHub) {
      const filteredResult = filterPremiumFeatures(
        config,
        features,
        acceptedPaymentOptions,
        addAlert,
        donorSSP
      );
      if (filteredResult.hasChanged) {
        eventHub.emit(
          EditorEventTypes.ConfigurationUpdate,
          filteredResult.updatedConfig
        );
      }

      setHasBeenFiltered(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config?.blocks, features, hasBeenFiltered, eventHub]);

  const getCurrentBackendGivingForm = async () => {
    const { data } = await getGivingForm();
    return data;
  };

  // function to be passed through EditsSaved to RevertChangesModal
  const onDiscard = async () => {
    const currentBackendGivingForm = await getCurrentBackendGivingForm();
    setActiveMenu(EditorMenus.GivingFormElementLibrary);
    setLibraryView(ElementLibraryGivingFormViews.RootView);
    emitSetPreviewGivingForm(eventHub);
    setActiveTab('GivingForm');
    filterPremiumFeatures(
      currentBackendGivingForm.config,
      features,
      acceptedPaymentOptions,
      addAlert,
      donorSSP
    );
    discardEdits();
    handleSetForm({
      ...currentBackendGivingForm,
      seedConfigPublishedAt: currentBackendGivingForm.updatedAt
    });
  };

  const revertChangesModalCopy = (
    <>
      Any changes you made to the giving form will not be saved, and it will be
      reverted to the most recent published version.
      <br />
      <br />
      If the giving form has never been published, it will revert to the
      template.
    </>
  );

  const closeModal = () => setDeleteModalConfig({ open: false });

  const handleDelete = () => {
    if (eventHub) {
      const newConfig = { ...(config as IGivingFormConfig) };
      const { id } = deleteModalConfig;
      newConfig.blocks = newConfig.blocks.filter(
        (block: BlockBaseType) => block.id !== id
      );

      updateConfig(newConfig);
      setLibraryView(ElementLibraryGivingFormViews.RootView);
      closeModal();
    }
  };

  const elementLibraryReturnAction =
    libraryView === ElementLibraryGivingFormViews.RootView ||
    libraryView === ElementLibraryGivingFormViews.ThankYouRootView
      ? undefined
      : () => {
          setHighlightedBlock('');
          setLibraryView(
            activeTab === 'GivingForm'
              ? ElementLibraryGivingFormViews.RootView
              : ElementLibraryGivingFormViews.ThankYouRootView
          );
        };

  return (
    <Stack className="GivingFormEditor-Wrapper fluid-container" direction="row">
      <Stack className="GivingFormEditor-iFrameContainer">
        <Box
          className={clsx('GivingFormEditor-MenuSelectionStack', {
            'ab-test-preview': isAbTestWizard
          })}
        >
          <Stack direction="row" spacing={1}>
            <IconButton
              icon={ICONS.PLUS}
              variant={
                activeMenu === EditorMenus.GivingFormElementLibrary
                  ? 'primary'
                  : 'secondary'
              }
              onClick={() => {
                setActiveMenu(EditorMenus.GivingFormElementLibrary);
                if (activeTab === 'ThankYou') {
                  setLibraryView(
                    ElementLibraryGivingFormViews.ThankYouRootView
                  );
                }
              }}
              label="Add Element"
              tooltipLabel="Add Element"
            />
            <IconButton
              icon={ICONS.PALETTE}
              variant={
                activeMenu === EditorMenus.GivingFormLookAndFeel
                  ? 'primary'
                  : 'secondary'
              }
              onClick={() => setActiveMenu(EditorMenus.GivingFormLookAndFeel)}
              label="Look and Feel"
              tooltipLabel="Look & Feel"
            />
            {features?.customCss && (
              <IconButton
                icon={ICONS.CODE}
                variant={
                  activeMenu === EditorMenus.GivingFormCustomCss
                    ? 'primary'
                    : 'secondary'
                }
                onClick={() => setActiveMenu(EditorMenus.GivingFormCustomCss)}
                label="Custom CSS"
                tooltipLabel="Custom CSS"
              />
            )}
            <IconButton
              icon={ICONS.EYE}
              onClick={() => setPreviewModalOpen(true)}
              label="Preview"
              tooltipLabel="Preview"
              variant="secondary"
            />
          </Stack>
          {configData.editsSavedTime && (
            <EditsSaved
              configData={configData}
              onDiscard={onDiscard}
              modalCopy={revertChangesModalCopy}
              editorType={EditorTypes.GivingForm}
              isPublished={isPublished}
              isTemplate={!givingFormId}
            />
          )}
        </Box>
        {isAbTestWizard && (
          <div className="ab-test-iframe-label-container">
            <div className="ab-test-iframe-label">
              <Text variant="h6">Form B</Text>
            </div>
          </div>
        )}
        <div
          className={clsx('GivingFormEditor-iFrame', {
            'ab-test-preview': isAbTestWizard
          })}
        >
          <iframe
            id="previewFrame"
            name="previewFrame"
            title="previewFrame"
            src={iframeSrc}
            frameBorder="0"
            allow="payment"
            sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation allow-modals allow-downloads allow-popups"
            width="100%"
            style={{
              visibility:
                !iframeHeight || !embedReadyToDisplay ? 'hidden' : 'visible',
              height: iframeHeight
            }}
            ref={iframeRef}
          />
        </div>
      </Stack>
      <ActiveMenu
        activeMenu={activeMenu}
        elementLibraryReturnAction={elementLibraryReturnAction}
        libraryView={libraryView}
        setLibraryView={setLibraryView}
      />
      <DeleteBlockModal
        onConfirm={handleDelete}
        onClose={closeModal}
        open={deleteModalConfig.open}
      />
      {previewModalOpen && (
        <Preview
          isOpen={previewModalOpen}
          onClose={() => setPreviewModalOpen(false)}
          configData={configData}
          givingFormId={formId}
          isAbTestWizard={isAbTestWizard}
        />
      )}
    </Stack>
  );
};

export default GivingFormEditor;
