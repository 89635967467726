import { useMutation, useQueryClient } from 'react-query';
import { archiveEventService } from 'services/eventService';

export const useArchiveEvent = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ isArchived, eventId }: { isArchived: boolean; eventId: string }) =>
      archiveEventService({
        eventId,
        organizationId,
        isArchived
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('campaignEvents');
      }
    }
  );
};
