import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';

export interface ConfirmDesignationOverwriteModalProps {
  fundId: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmDesignationOverwrtieModal = ({
  fundId,
  isOpen,
  onClose,
  onConfirm
}: ConfirmDesignationOverwriteModalProps) => (
  <Dialog
    label="confirm designation overwrite group modal"
    onClose={onClose}
    open={isOpen}
    className="confirm-designation-overwrite-modal"
  >
    <DialogTitle
      className="confirm-designation-overwrite-modal-title"
      onClick={onClose}
    >
      Duplicate Fund ID
    </DialogTitle>
    <DialogContent className="confirm-designation-overwrite-modal-content">
      <DialogContentTitle className="confirm-designation-overwrite-modal-content-title">
        {`"${fundId}" already exists`}
      </DialogContentTitle>
      <DialogContentDescription>
        The Fund ID provided already exists. Proceeding will result in the
        creation of a new designation, replacing the existing one associated
        with this Fund ID. Are you sure you want to proceed?
      </DialogContentDescription>
    </DialogContent>
    <DialogActions className="confirm-designation-overwrite-modal-actions-container">
      <DialogActionButton
        className="confirm-designation-overwrite-modal-confirm"
        onClick={() => onConfirm()}
        variant="primary"
      >
        Yes, Replace
      </DialogActionButton>
      <DialogActionButton onClick={() => onClose()} variant="secondary">
        Cancel
      </DialogActionButton>
    </DialogActions>
  </Dialog>
);
