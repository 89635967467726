import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Stepper } from 'components/Stepper';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import { PublishAbTestDialog } from 'components/gms/PublishAbTestDialog';
import { useAlerts } from 'hooks';
import { AbTestDraftProvider, useAbTestDraft } from 'hooks/useAbTestDraft';
import { useAppContext } from 'hooks/useAppContext';
import { useCampaignById } from 'queries/UseCampaigns';
import { useGivingFormByInstanceId } from 'queries/UseGivingForms';
import { useUser } from 'queries/UseUsers';
import { CampaignType } from 'services';
import { GivingForm } from 'services/types/givingFormTypes';
import { CreateAbTestSchema } from './CreateAbTest.Schema';
import './CreateAbTest.scss';
import { EditPageBStep } from './EditPageBStep';
import { EditVariantBStep } from './EditVariantBStep';
import { ReviewStep } from './ReviewStep';
import { SettingsStep } from './SettingsStep';

const breadcrumbs = [
  {
    label: 'Strategy',
    path: '/strategy'
  },
  {
    label: 'A/B Tests',
    path: '/strategy/ab-test'
  },
  {
    label: 'Create A/B Test',
    path: ''
  }
];

interface CreateAbTestProps {
  trigger(): Promise<boolean>;
}

export const CreateAbTestView = ({
  trigger
}: CreateAbTestProps): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(0);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { givingFormId } = useParams();
  const { abTestName, setNewAbTestName, selectedOrganization } =
    useAppContext();
  const [draftName, setDraftName] = useState(abTestName);
  const [draftCampaignId, setDraftCampaignId] = useState('');
  const [draftCampaignName, setDraftCampaignName] = useState('');
  const { draft, updateDraft } = useAbTestDraft();
  const [givingForm, setGivingForm] = useState<GivingForm>();
  const [isTriggerNextPage, setIsTriggerNextPage] = useState(false);
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);
  const [numberOfSteps, setNumberOfSteps] = useState(3);
  const [pushAlert] = useAlerts();
  const organizationId = selectedOrganization?.id;

  useEffect(() => {
    /**
     * `draft` is `undefined` if we are searching for a draft. we wait until we have definitely found or not found a draft
     * to run the following logic.
     *
     * One thing to note here is that abTestName is only a truthy value if we come to the create flow from the giving form
     * selection page. This helps us know if we need to set up the first draft for a given ab test.
     *
     * In the case that abTestName is a falsy value, we would expect that there is already a draft in localStorage, and we
     * are returning to an ab test in progress.
     *
     * If abTest was falsy AND there was no draft in localStorage, we assume the user typed in a random giving form id in the url
     * and send them back to the ab test home page.
     */
    if (draft !== undefined) {
      if (!abTestName && draft === null) {
        /**
         * If there is no abTestName (meaning we didnt come from the giving form selection page) and there is no draft
         * then we navigate them back to the ab test home page.
         */
        navigate('/strategy/ab-test');
      } else if (abTestName && draft === null) {
        /**
         * If there is an abTestName and no draft, it means that we came from giving form selection and need to set up a draft.
         * Here we configure what the test name should be, based on their selection from the previous page
         */
        updateDraft({ config: { name: abTestName } });
        setNewAbTestName('');
        setDraftName(abTestName);
      } else if (draft) {
        /**
         * If we reach here it means that we are returning to a test in progress. We save the test name from the config
         * in state and continue on our way.
         */
        setDraftName(draft.config?.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  useGivingFormByInstanceId(givingFormId, {
    /**
     * This query remains disabled until we verify that we either got to this page by picking a giving form to test, or are
     * returning to an ab test in progress. Our source of truth for that is whether we have a draftName saved in state.
     */
    enabled: !!draftName,
    onSuccess: (givingFormData: GivingForm) => {
      /**
       * Once the query resolves we save the campaign and GF data onto the draft, and set the campaign ID. Having a campaign ID in
       * state is our source of truth for whether we enable a followup request to get the campaignName.
       */
      updateDraft({
        config: {
          campaign: {
            id: givingFormData.campaignId,
            name: ''
          },
          givingForm: {
            name: givingFormData.name,
            id: givingFormId
          }
        }
      });
      setDraftCampaignId(givingFormData.campaignId);
      // Save the GF so that we know if it has a HP or not
      setGivingForm(givingFormData);
      if (givingFormData?.config?.hostedPageConfig !== undefined) {
        setNumberOfSteps(4);
      }
    }
  });

  useCampaignById(organizationId, draftCampaignId, {
    /**
     * This query remains disabled until we have a draftCampaignId saved in state.
     */
    enabled: !!draftCampaignId,
    onSuccess: (campaignData: CampaignType) => {
      /**
       * When we get our campaign data, we save the campaign name to both local state and
       * the draft, and finally our flow of setting up draft data is complete.
       */
      setDraftCampaignName(campaignData?.title);

      updateDraft({
        config: {
          campaign: { name: campaignData?.title, id: draftCampaignId }
        }
      });
    }
  });

  useEffect(
    /**
     * When the component unmounts, we invalidate the useCampaignById cache. This is to prevent the case of starting an ab test on a campaign,
     * and then immediately starting an ab test on another form in the same campaign, and us not triggering our success logic.
     */
    () => () => {
      queryClient.invalidateQueries('campaignById');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const goToPreviousStep = () =>
    setCurrentStep((step) => Math.max(0, step - 1));

  const goToNextStep = (isGivingFormConfigValid?: boolean) => {
    trigger().then((isValid) => {
      if (isValid) {
        if (
          currentStep !== 1 ||
          (currentStep === 1 && isGivingFormConfigValid)
        ) {
          setCurrentStep((step) => Math.min(numberOfSteps, step + 1));
        } else {
          pushAlert({
            severity: 'error',
            title: 'Something is not valid and we cannot continue.'
          });
        }
      }
    });
  };

  const views = [
    <SettingsStep nextClicked={isTriggerNextPage} />,
    <EditVariantBStep
      isTriggerNextPage={isTriggerNextPage}
      goToNextStep={goToNextStep}
      resetTriggerNextPage={() => setIsTriggerNextPage(false)}
    />,
    <EditPageBStep
      isTriggerNextPage={isTriggerNextPage}
      goToNextStep={goToNextStep}
      resetTriggerNextPage={() => setIsTriggerNextPage(false)}
    />,
    <ReviewStep />
  ];

  // Remove the EditPageBStep if the GF doesn't have a hosted page config
  if (!givingForm?.config?.hostedPageConfig) {
    views.splice(2, 1);
  }

  return (
    <>
      <FeatureHeader
        titleProps={draftName}
        subtitleProps={{
          customTitle: draftCampaignName ? (
            <>
              Campaign: <b>{draftCampaignName}</b>
            </>
          ) : (
            <Skeleton animation="wave" />
          )
        }}
        breadcrumbsProps={{ breadcrumbs }}
        className="ab-test-feature-header"
        primaryButtonProps={{
          children: currentStep === numberOfSteps - 1 ? 'Publish' : 'Next',
          onClick: () => {
            if (currentStep === 0) {
              goToNextStep();
            } else if (currentStep === numberOfSteps - 1) {
              setPublishDialogOpen(true);
            } else {
              // step 1 is the variant B configuration page (or step 2 if HP config exists), so trigger validation flow
              setIsTriggerNextPage(true);
            }
          }
        }}
        secondaryButtonProps={
          currentStep !== 0 && {
            children: 'Previous',
            onClick: goToPreviousStep
          }
        }
      >
        <div className="ab-stepper-container">
          <Stepper
            activeStep={currentStep}
            handleStep={(index) => setCurrentStep(index)}
            hasHostedPage={givingForm?.config?.hostedPageConfig !== undefined}
          />
        </div>
      </FeatureHeader>
      {views[currentStep]}
      {publishDialogOpen && (
        <PublishAbTestDialog
          open={publishDialogOpen}
          setPublishDialogOpen={setPublishDialogOpen}
        />
      )}
    </>
  );
};

export const CreateAbTest = (): JSX.Element => {
  const methods = useForm({
    resolver: yupResolver(CreateAbTestSchema),
    mode: 'onChange'
  });

  const {
    data: { id: userId }
  } = useUser();
  const {
    selectedOrganization: { id: orgId }
  } = useAppContext();
  const { givingFormId } = useParams();

  return (
    <AbTestDraftProvider orgId={orgId} userId={userId} draftId={givingFormId}>
      <FormProvider {...methods}>
        <CreateAbTestView trigger={methods.trigger} />
      </FormProvider>
    </AbTestDraftProvider>
  );
};
