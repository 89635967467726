import React from 'react';
import { Box } from '@mui/material';
import './AppContainer.scss';

type Props = {
  children: React.ReactNode;
};

const AppContainer = ({ children }: Props): JSX.Element => (
  <Box className="app-container">{children}</Box>
);

export default AppContainer;
