import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import {
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup,
  TextField
} from '@mui/material';
import clsx from 'clsx';
import {
  MAXIMUM_FILE_SIZE,
  MAXIMUM_FILE_SIZE_EXCEEDED_MSG
} from 'constants/givingFormContants';
import { useParams } from 'react-router-dom';
import FullWidthImage from 'assets/images/hosted-page/FullWidth.svg';
import LeftHalfImage from 'assets/images/hosted-page/LeftHalf.svg';
import LeftThirdImage from 'assets/images/hosted-page/LeftThird.svg';
import RightHalfImage from 'assets/images/hosted-page/RightHalf.svg';
import RightThirdImage from 'assets/images/hosted-page/RightThird.svg';
import Button from 'components/Button';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Radio from 'components/Radio';
import Text from 'components/Text';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useSaveImage } from 'queries/UseGivingForms';
import { readFile } from 'services/givingFormService';
import { HostedPageLayout } from 'types';
import './HostedPageSelectLayoutModal.scss';

type HostedPageSelectLayoutModalProps = {
  updatingLayout?: boolean;
  open: boolean;
  onClose: () => void;
  setLayoutSelection: (layout: HostedPageLayout) => void;
  layoutSelection: HostedPageLayout;
  onNext: () => void;
  nextEnabled: boolean;
  pageMetadata: {
    pageFavicon: string;
    setPageFavicon: Dispatch<SetStateAction<string>>;
    pageTitle: string;
    setPageTitle: Dispatch<SetStateAction<string>>;
  };
};

const layoutOptionsData = [
  {
    display: 'Half Page, Left Aligned',
    img: LeftHalfImage,
    selection: HostedPageLayout.LeftHalf
  },
  {
    display: 'Half Page, Right Aligned',
    img: RightHalfImage,
    selection: HostedPageLayout.RightHalf
  },
  {
    display: 'Full Page',
    img: FullWidthImage,
    selection: HostedPageLayout.FullWidth
  },
  {
    display: '1/3 Page, Left Aligned',
    img: LeftThirdImage,
    selection: HostedPageLayout.LeftThird
  },
  {
    display: '1/3 Page, Right Aligned',
    img: RightThirdImage,
    selection: HostedPageLayout.RightThird
  }
];

const defaultFavicon = `${window.location.origin}/favicon.ico`;

export const HostedPageSelectLayoutModal = ({
  updatingLayout = false,
  open,
  onClose,
  setLayoutSelection,
  layoutSelection,
  onNext,
  nextEnabled,
  pageMetadata
}: HostedPageSelectLayoutModalProps) => {
  const [addAlert] = useAlerts();
  const { givingFormId } = useParams();
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();

  const { mutateAsync: saveHostedPageFaviconImageAsync } = useSaveImage();

  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > MAXIMUM_FILE_SIZE) {
        addAlert({
          title: MAXIMUM_FILE_SIZE_EXCEEDED_MSG,
          severity: 'error'
        });
        return;
      }

      const imageDataUrl = await readFile(file);
      // set image uploading flag
      try {
        const result = await saveHostedPageFaviconImageAsync({
          image: imageDataUrl,
          prefix: 'favicon-image',
          givingFormId,
          organizationId
        });
        pageMetadata.setPageFavicon(result);
      } catch (err) {
        addAlert({
          title: 'There was an error uploading your image.',
          severity: 'error'
        });
      } // finally to remove spinner
    }
  };

  const onFaviconDeleted = async () => {
    // prompt "are you sure"
    pageMetadata.setPageFavicon(null);
  };

  return (
    <Dialog
      label="Hosted Page Layout"
      open={open}
      onClose={() => onClose()}
      classes={{ paper: 'hosted-page-select-layout-modal-container' }}
    >
      <DialogTitle
        className="hosted-page-select-layout-modal-title"
        onClick={(close) => (onClose ? onClose() : close())}
      >
        Hosted Page Details
      </DialogTitle>
      <DialogContent>
        <div className="metadata-container">
          <div className="page-favicon">
            <Text variant="h5">Page Favicon</Text>
            <div className="favicon-image-wrapper">
              <img
                src={pageMetadata.pageFavicon || defaultFavicon}
                alt="Hosted Page Favicon"
              />
              {pageMetadata.pageFavicon && (
                <IconButton
                  className="delete"
                  label="delete"
                  variant="basic"
                  size="small"
                  icon={ICONS.TRASH}
                  onClick={onFaviconDeleted}
                />
              )}
            </div>
            {/* eslint-disable-next-line */}
            {/* @ts-ignore */}
            <Button fullWidth className="upload-favicon-btn" component="label">
              Browse Files
              <input
                hidden
                type="file"
                onChange={onFileSelected}
                accept="image/*"
              />
            </Button>
          </div>
          <div className="page-title">
            <Text variant="h5">Page Title</Text>
            <TextField
              fullWidth
              placeholder="Enter Page Title"
              value={pageMetadata.pageTitle}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                pageMetadata.setPageTitle(event.target.value)
              }
              error={!pageMetadata.pageTitle}
              helperText={
                !pageMetadata.pageTitle ? 'Page Title is required.' : ''
              }
            />
          </div>
        </div>
        <Divider />
        <DialogContentTitle>Select Page Layout</DialogContentTitle>
        <DialogContentDescription>
          Select how would like your hosted page to display your giving form.
        </DialogContentDescription>
        <div className="layout-options-container">
          <FormControl>
            <RadioGroup row name="layout">
              {layoutOptionsData.map((option) => (
                // This element catches radio selection click events, and allows the image to also be clickable
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                <div
                  onClick={() => setLayoutSelection(option.selection)}
                  key={option.selection}
                  className={clsx('layout-option-wrapper', {
                    selected: layoutSelection === option.selection
                  })}
                >
                  <FormControlLabel
                    value={option.selection}
                    label={<Text variant="h3">{option.display}</Text>}
                    checked={layoutSelection === option.selection}
                    name={option.selection}
                    control={<Radio />}
                  />
                  <div className="layout-image">
                    <img src={option.img} alt={option.display} />
                  </div>
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions className="hosted-page-select-layout-modal-actions-container">
        <DialogActionButton
          onClick={() => {
            onNext();
          }}
          disabled={!nextEnabled}
          variant="primary"
          name="next"
        >
          {updatingLayout ? 'Update' : 'Next'}
        </DialogActionButton>
        <DialogActionButton
          onClick={(close) => (onClose ? onClose() : close())}
          variant="secondary"
          name="back"
        >
          Back to Editor
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
