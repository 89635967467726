import { useState } from 'react';
import { Box, SelectChangeEvent, Stack } from '@mui/material';
import { MultiselectCheckbox } from 'components';
import ComboBox from 'components/ComboBox';
import { MultiselectOption } from 'components/Select/MultiselectCheckbox';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { useAlerts } from 'hooks';
import { useConfigContext } from 'hooks/useConfigContext';
import {
  BlockTypes,
  IGivingFormConfig,
  IRecurringOptionsBlock,
  RecurringOptionsType
} from 'types';
import { RecurringMethod } from 'types/givingFormTypes/RecurringOptionsBlock';
import {
  BlockDetailType,
  IThankYouMessageDonationDetailsBlock
} from 'types/givingFormTypes/ThankYouMessageDonationDetailsBlock';
import OptionalSection from '../OptionalSection';

const recurringOptions: MultiselectOption[] = Object.values(
  RecurringOptionsType
).map((value) => ({
  label: value,
  value
}));

const recurringMethods = Object.values(RecurringMethod).map((value) => value);

const EditRecurringOptions = (): JSX.Element => {
  const {
    configData: { config },
    updateConfig
  } = useConfigContext<IGivingFormConfig>();
  const [addAlert] = useAlerts();
  const recurringOptionsConfig = config.blocks.find(
    (block) => block.blockType === 'RecurringOptions'
  ) as IRecurringOptionsBlock;

  const [initialValues] = useState<string[]>(
    recurringOptionsConfig?.recurringOptions as string[]
  );
  const [defaultOption, setDefaultOption] = useState<string>(
    recurringOptionsConfig?.defaultOption || null
  );
  const [showStartDate, setShowStartDate] = useState(
    recurringOptionsConfig?.showStartDate ?? false
  );
  const [recurringMethod, setRecurringMethod] = useState<RecurringMethod>(
    recurringOptionsConfig?.recurringMethod
  );
  const [indefiniteEndNoticeMessage, setIndefiniteEndNoticeMessage] =
    useState<string>(recurringOptionsConfig?.indefiniteEndNoticeMessage ?? '');

  const selectedOptions = recurringOptionsConfig?.recurringOptions || [];

  const updateRecurringOptionsBlock = <K extends keyof IRecurringOptionsBlock>(
    key: K,
    value: IRecurringOptionsBlock[K] | null
  ) => {
    const newConfig = { ...config };
    const recurringOptionsBlock = newConfig.blocks.find(
      (block) => block.blockType === 'RecurringOptions'
    ) as IRecurringOptionsBlock;
    if (recurringOptionsBlock) {
      recurringOptionsBlock[key] = value ?? undefined;
    } else {
      const newRecurringOptionsBlock: IRecurringOptionsBlock = {
        blockType: BlockTypes.RecurringOptions,
        id: 'recurring-options',
        recurringOptions: [],
        [key]: value ?? undefined
      };
      newConfig.blocks.push(newRecurringOptionsBlock);
    }

    updateConfig(newConfig);
  };

  const handleDefaultChange = (value?: RecurringOptionsType) => {
    setDefaultOption(value);
    updateRecurringOptionsBlock('defaultOption', value);
  };

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const newConfig = { ...config };

    let values = event.target.value as RecurringOptionsType[];
    if (typeof event.target.value === 'string') {
      values = [event.target.value] as RecurringOptionsType[];
    }
    if (
      (values.length === 1 && values.includes(RecurringOptionsType.Once)) ||
      values.length === 0
    ) {
      // If no recurring options (or only "Once"), need to remove schedule block (if exists) from TY config
      // Check if "Transaction Fee" is selected to display in the Thank You Donor/Gift Detail
      // and remove it if present:
      const tyBlocks = newConfig.thankYouConfig.blocks;
      const tyDetailsScheduleInfoBlock: IThankYouMessageDonationDetailsBlock =
        tyBlocks.find(
          (block) =>
            block.blockType ===
              BlockTypes.ThankYouMessageDonationDetailsBlock &&
            (block as IThankYouMessageDonationDetailsBlock).detailType ===
              BlockDetailType.ScheduleInformation
        ) as IThankYouMessageDonationDetailsBlock;
      if (tyDetailsScheduleInfoBlock) {
        tyBlocks.splice(tyBlocks.indexOf(tyDetailsScheduleInfoBlock), 1);
        addAlert({
          title: 'Thank You Message Updated',
          description:
            'Because there are no recurring options, the Schedule Info has been removed from the Thank You Message'
        });
      }
    }

    if (values.length === 0) {
      const blocks = newConfig.blocks.filter(
        (block) => block.blockType !== 'RecurringOptions'
      );
      newConfig.blocks = blocks;

      updateConfig(newConfig);
      setDefaultOption(null);
      return;
    }

    updateRecurringOptionsBlock('recurringOptions', values);
    updateRecurringOptionsBlock('defaultOption', undefined);
  };

  const handleRecurringMethodChange = (value?: RecurringMethod) => {
    setRecurringMethod(value);
    updateRecurringOptionsBlock('recurringMethod', value);
  };

  const handleShowStartDateChange = (value?: boolean) => {
    setShowStartDate(value);
    updateRecurringOptionsBlock('showStartDate', value);
  };

  const handleIndefiniteEndNoticeMessageChange = (value?: string) => {
    setIndefiniteEndNoticeMessage(value);
    updateRecurringOptionsBlock('indefiniteEndNoticeMessage', value);
  };

  return (
    <Stack id="ElementLibrary-EditRecurringOptions" spacing={1}>
      <Text variant="h3">Recurring Options</Text>
      <MultiselectCheckbox
        label="Select"
        options={recurringOptions}
        defaultValue={initialValues}
        sortSelected
        onChange={handleChange}
      />
      <Text variant="h3">Set Default Option</Text>
      <Text variant="caption">
        Select which you would like to set as the default.
      </Text>
      <ComboBox
        value={defaultOption}
        options={selectedOptions}
        disabled={selectedOptions.length === 0}
        onChange={(_, selection) =>
          handleDefaultChange(selection as RecurringOptionsType)
        }
      />
      <Text variant="h3">Schedule Start Date</Text>
      <Box>
        <OptionalSection
          title="Show Start Date"
          initiallySelected={showStartDate}
          onChange={handleShowStartDateChange}
        />
      </Box>
      <Text variant="h3">Recurring Method</Text>
      <ComboBox
        value={recurringMethod}
        options={recurringMethods}
        onChange={(_, selection) =>
          handleRecurringMethodChange(selection as RecurringMethod)
        }
      />
      <Text variant="h3">Indefinite End Notice Message</Text>
      <TextField
        minRows={5}
        multiline
        placeholder="If no end date is selected, recurring gift will continue indefinitely."
        value={indefiniteEndNoticeMessage}
        onChange={(e) => handleIndefiniteEndNoticeMessageChange(e.target.value)}
      />
    </Stack>
  );
};

export default EditRecurringOptions;
