import { Theme, createTheme } from '@mui/material';

export const createBreadcrumbTheme = (currTheme: Theme): Theme => {
  const theme = createTheme(currTheme, {
    components: {
      MuiBreadcrumbs: {
        styleOverrides: {
          separator: {
            margin: 0
          },
          li: {
            marginBottom: 0
          }
        }
      }
    }
  });

  return theme;
};
