import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import TextField from 'components/TextField';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { useUpdateGivingFormRedirectURL } from 'queries/UseGivingForms';
import { IGivingFormConfig } from 'types/givingFormTypes';
import './RedirectURLFormDialog.scss';

type RedirectURLFormDialogProps = {
  open: boolean;
  onClose: () => void;
  onUpdate: (url: string) => void;
};

const RedirectURLFormDialog = ({
  open,
  onClose,
  onUpdate
}: RedirectURLFormDialogProps): JSX.Element => {
  const [addAlert] = useAlerts();
  const { givingFormId } = useParams();
  const { selectedOrganization } = useAppContext();
  const { configData, updateConfig } = useConfigContext<IGivingFormConfig>();
  const [redirectURL, setRedirectURL] = useState(
    configData?.config?.redirectURL ?? ''
  );

  const { mutateAsync: updateRedirectURL, isLoading } =
    useUpdateGivingFormRedirectURL();

  useEffect(() => {
    setRedirectURL(configData?.config?.redirectURL);
  }, [configData?.config?.redirectURL]);

  const onSubmit = async (close: () => void) => {
    try {
      await updateRedirectURL({
        organizationId: selectedOrganization.id,
        givingFormId,
        redirectURL
      });
      updateConfig({ ...configData.config, redirectURL });
      onUpdate(redirectURL);
    } catch (e) {
      addAlert({
        title: 'Uh oh. Looks like there was an error saving redirect URL.',
        severity: 'error'
      });
    }
    close();
  };

  return (
    <Dialog
      className="redirect-url-form-dialog"
      label="new giving form name dialog"
      open={open}
      onClose={onClose}
    >
      <DialogTitle className="dialog-title" onClick={(close) => close()}>
        Redirect URL
      </DialogTitle>
      <DialogContent>
        <DialogContentTitle>Redirect URL</DialogContentTitle>
        <DialogContentDescription>
          Upon successful transaction, send your donors to the URL of your
          choosing!
        </DialogContentDescription>
        <TextField
          value={redirectURL}
          placeholder="Enter Redirect URL"
          hiddenLabel
          onChange={(e) => setRedirectURL(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          variant="primary"
          onClick={onSubmit}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
        </DialogActionButton>
        <DialogActionButton variant="secondary" onClick={(close) => close()}>
          Cancel
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default RedirectURLFormDialog;
