import { useGetFieldSetById } from 'queries/UseCampaigns';
import { useAppContext } from './useAppContext';

export const useOrgCustomFields = () => {
  const { selectedOrganization } = useAppContext();
  const { data: fieldSetData } = useGetFieldSetById(selectedOrganization.id);

  const orgCustomFields = Array.from(
    new Set((fieldSetData ?? []).map((f) => f.fieldNames).flat())
  );

  return orgCustomFields;
};
