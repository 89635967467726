import { BlockTypes, IGivingFormConfig } from 'types';

type ValidityObject = {
  isValid: boolean;
  isFirst: boolean;
  isLast: boolean;
  isNextToAnotherPageBreak: boolean;
};

export const validatePageBreaks = (
  config: IGivingFormConfig
): ValidityObject => {
  let errorStates = {
    isFirst: false,
    isLast: false,
    isNextToAnotherPageBreak: false
  };

  config.blocks.forEach((block, i, arr) => {
    if (block.blockType !== BlockTypes.PageBreakBlock) {
      return;
    }

    const isNextToAnotherPageBreak = [
      arr[i - 1]?.blockType,
      arr[i + 1]?.blockType
    ].includes(BlockTypes.PageBreakBlock);
    const isFirst = i === 0;
    const isLast = i === arr.length - 1;

    errorStates = {
      isFirst: errorStates.isFirst || isFirst,
      isLast: errorStates.isLast || isLast,
      isNextToAnotherPageBreak:
        errorStates.isNextToAnotherPageBreak || isNextToAnotherPageBreak
    };
  });

  return {
    ...errorStates,
    isValid: !Object.values(errorStates).includes(true)
  };
};
