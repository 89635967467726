/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useState } from 'react';
import {
  MAXIMUM_FILE_SIZE,
  MAXIMUM_FILE_SIZE_EXCEEDED_MSG
} from 'constants/givingFormContants';
import { useParams } from 'react-router-dom';
import { Blocker, ICONS } from 'components';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useDeleteImage, useSaveImage } from 'queries/UseGivingForms';
import './TributeImageManager.scss';

const TRIBUTE_IMAGE_PREFIX = 'tribute-image';

export interface TributeImageManagerProps {
  images: string[];
  setImages: (images: string[]) => void;
  maxImageCount: number;
}

export const TributeImageManager = ({
  images,
  setImages,
  maxImageCount
}: TributeImageManagerProps): JSX.Element => {
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [addAlert] = useAlerts();
  const { givingFormId } = useParams();
  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();

  const { mutateAsync: saveTributeImageAsync } = useSaveImage();
  const { mutateAsync: deleteTributeImageAsync } = useDeleteImage();

  const handlePreventDefault = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const readFile = (file: File) =>
    new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => resolve(reader.result as string),
        false
      );
      reader.readAsDataURL(file);
    });

  const saveImage = async (imageDataUrl: string) => {
    // Save image to CDN
    setIsWorking(true);
    try {
      const result = await saveTributeImageAsync({
        image: imageDataUrl,
        prefix: TRIBUTE_IMAGE_PREFIX,
        givingFormId,
        organizationId
      });

      // Push image into state,
      setImages([...images, result]);
    } catch (err) {
      addAlert({
        title: 'There was an error uploading your image.',
        severity: 'error'
      });
    } finally {
      setIsWorking(false);
    }
  };

  const handleFileUpload = async (file?: File) => {
    try {
      if (file) {
        if (file.size > MAXIMUM_FILE_SIZE) {
          addAlert({
            title: MAXIMUM_FILE_SIZE_EXCEEDED_MSG,
            severity: 'error'
          });
          return;
        }

        const imageDataUrl = await readFile(file);
        saveImage(imageDataUrl);
      }
    } catch (err) {
      addAlert({
        title: 'There was an error loading your image.',
        severity: 'error'
      });
    }
  };

  const onDropFile = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileUpload(e.dataTransfer.files[0]);
  };

  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    handleFileUpload(e.target.files?.[0]);
  };

  const onImageDeleted = (src: string) => {
    setIsWorking(true);

    // Parse guid from URL
    const end = src.split(`${TRIBUTE_IMAGE_PREFIX}-`)[1];
    const parsedImageId = end.split('.')[0];

    // Delete image from CDN
    deleteTributeImageAsync({
      imageId: parsedImageId,
      prefix: TRIBUTE_IMAGE_PREFIX,
      givingFormId,
      organizationId
    })
      .then(() => {
        const clone = [...images];
        clone.splice(clone.indexOf(src), 1);
        setImages(clone);
        setIsWorking(false);
      })
      .catch(() => {
        addAlert({
          title: 'There was an error deleting your image.',
          severity: 'error'
        });
        setIsWorking(false);
      });
  };

  return (
    <Blocker className="tribute-image-blocker" block={isWorking}>
      <Text variant="caption">{maxImageCount} images can be uploaded.</Text>
      {images.length < maxImageCount && (
        <div
          className="image-upload-container"
          onDragEnter={handlePreventDefault}
          onDragLeave={handlePreventDefault}
          onDragOver={handlePreventDefault}
          // eslint-disable-next-line
          // @ts-ignore
          onDrop={onDropFile}
        >
          <Text variant="h5"> Drag & Drop File </Text>
          <span className="or">or</span>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <Button className="upload-image-btn" component="label">
            Browse Files
            <input
              hidden
              type="file"
              onChange={onFileSelected}
              accept="image/*"
            />
          </Button>
        </div>
      )}
      <div className="tribute-images">
        {images.map((src, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="image-wrapper" key={index}>
            <img src={src} alt="" />
            <IconButton
              className="delete"
              label="delete"
              variant="basic"
              size="small"
              icon={ICONS.TRASH}
              onClick={() => onImageDeleted(src)}
            />
          </div>
        ))}
      </div>
    </Blocker>
  );
};
