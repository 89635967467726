import { useState } from 'react';
import { FormControlLabel, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import Button from 'components/Button';
import Radio, { RadioGroup } from 'components/Radio';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { BlockTypes, ICustomFieldBlock } from 'types';
import { CustomFieldInputTypesProps } from '../EditCustomFields';

const CustomFieldCurrencyInputSchema = yup.object({
  heading: yup.string().required('Prompt name is required')
});

const CustomFieldCurrencyInput = ({
  customField,
  onUpdate,
  name,
  nameError,
  legacyFieldMapping
}: CustomFieldInputTypesProps): JSX.Element => {
  const { control, formState } = useFormContext();
  const [heading, setHeading] = useState<string>(customField?.heading || '');
  const [required, setRequired] = useState<boolean>(
    customField?.fieldInfo?.required || false
  );

  const isFormUpdated =
    heading !== customField?.heading ||
    name !== customField?.name ||
    legacyFieldMapping !== customField?.legacyFieldMapping ||
    required !== customField?.fieldInfo?.required ||
    customField?.fieldInfo?.type !== 'currency';

  const updateCurrencyInput = () => {
    const updatedBlock: ICustomFieldBlock = {
      name,
      reportingKey: name,
      heading,
      id: customField?.id || uuid(),
      blockType: BlockTypes.CustomFieldBlock,
      fieldInfo: {
        type: 'currency',
        required
      },
      legacyFieldMapping
    };
    onUpdate(updatedBlock);
  };

  return (
    <Stack spacing={1}>
      <Text variant="h5">Prompt Text</Text>
      <Controller
        name="heading"
        control={control}
        defaultValue={heading || ''}
        render={({
          field: { ref, onChange, ...field },
          fieldState: { error }
        }) => (
          <TextField
            {...field}
            hiddenLabel
            multiline
            minRows={3}
            placeholder="Prompt Text Here"
            onChange={(e) => {
              onChange(e.target.value);
              setHeading(e.target.value as string);
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
      <Text variant="h5">Make this a required field? </Text>
      <Controller
        name="fieldInfo.required"
        control={control}
        defaultValue={required ? 'yes' : 'no'}
        render={({ field: { ref, onChange, ...field } }) => (
          <RadioGroup
            {...field}
            row
            onChange={(e) => {
              onChange(e.target.value);
              setRequired((e.target.value as string) === 'yes');
            }}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={<Text variant="caption">Yes</Text>}
            />
            <FormControlLabel
              value="no"
              control={<Radio />}
              label={<Text variant="caption">No</Text>}
            />
          </RadioGroup>
        )}
      />
      <Button
        variant="primary"
        fullWidth
        disabled={nameError || !formState.isValid || !isFormUpdated}
        onClick={updateCurrencyInput}
      >
        Update
      </Button>
    </Stack>
  );
};

export default (props: CustomFieldInputTypesProps): JSX.Element => {
  const methods = useForm({
    resolver: yupResolver(CustomFieldCurrencyInputSchema),
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <CustomFieldCurrencyInput {...props} />
    </FormProvider>
  );
};
