import {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogTitle
} from 'components/Dialog';
import Text from 'components/Text';
import { useConfigContext } from 'hooks/useConfigContext';
import SuccessDialog from '../SuccessDialog';
import './SelectReceiptDialog.scss';

type SelectReceiptDialogProps = {
  open: boolean;
  onClose: () => void;
  givingFormName: string;
  receiptId: string;
};

export const SelectReceiptDialog = ({
  open,
  onClose,
  givingFormName,
  receiptId
}: SelectReceiptDialogProps) => {
  const { updateReceiptId } = useConfigContext();

  return (
    <SuccessDialog label="Select Receipt Dialog" open={open} onClose={onClose}>
      <DialogTitle onClick={(close) => close()}>Congratulations!</DialogTitle>
      <DialogContent className="select-receipt-dialog-content">
        <DialogContentDescription>
          Your receipt has been saved!
        </DialogContentDescription>
        <Text variant="body">
          This receipt is now available in the giving form builder’s Receipt
          tab.
        </Text>
        <Text variant="h2">
          Would you like to use this receipt in your {givingFormName} Giving
          Form?
        </Text>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          variant="primary"
          onClick={(close) => {
            updateReceiptId(receiptId);
            close();
          }}
        >
          Yes, Use This Receipt
        </DialogActionButton>
        <DialogActionButton variant="secondary" onClick={(close) => close()}>
          No, Continue Browsing
        </DialogActionButton>
      </DialogActions>
    </SuccessDialog>
  );
};
