import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { LimitedTextField } from 'components/TextField';
import { LimitedTextFieldProps } from 'components/TextField/LimitedTextField';

const LimitedTextElement = <T extends FieldValues>({
  control,
  name,
  ...otherProps
}: LimitedTextFieldProps & {
  control?: Control<T>;
  name: Path<T>;
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => <LimitedTextField {...otherProps} {...field} />}
  />
);

export default LimitedTextElement;
