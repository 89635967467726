import { Box, Stack } from '@mui/material';
import ColorPicker from 'components/ColorPicker';
import FontSelect from 'components/EditorMenus/FontSelect';
import Text from 'components/Text';
import { FontOptions } from 'types';
import { ICONS } from '../FontSelect/FontIcons';
import './GivingFormLookAndFeelMenu.scss';

export interface LookAndFeelMenuTypes {
  startingPrimaryColor: string;
  updatePrimaryColor(newColor: string): void;
  startingAccentColor: string;
  updateAccentColor(newColor: string): void;
  startingFont: FontOptions;
  updateFont(newFont: FontOptions): void;
  addPageBreak(): void;
}

const fontOptions = [
  {
    value: FontOptions.Bodoni,
    icon: ICONS.BODONI
  },
  {
    value: FontOptions.CrimsonPro,
    icon: ICONS.CRIMSONPRO
  },
  {
    value: FontOptions.EbGaramond,
    icon: ICONS.EBGARAMOND
  },
  {
    value: FontOptions.Helvetica,
    icon: ICONS.HELVETICA
  },
  {
    value: FontOptions.HelveticaNeue,
    icon: ICONS.HELVETICANEUE
  },
  {
    value: FontOptions.Jost,
    icon: ICONS.JOST
  },
  {
    value: FontOptions.Kameron,
    icon: ICONS.KAMERON
  },
  {
    value: FontOptions.LibreBaskerville,
    icon: ICONS.LIBREBASKERVILLE
  },
  {
    value: FontOptions.LibreFranklin,
    icon: ICONS.LIBREFRANKLIN
  },
  {
    value: FontOptions.Montserrat,
    icon: ICONS.MONTSERRAT
  },
  {
    value: FontOptions.Nunito,
    icon: ICONS.NUNITO
  },
  {
    value: FontOptions.Poppins,
    icon: ICONS.POPPINS
  },
  {
    value: FontOptions.TimesNewRoman,
    icon: ICONS.TIMESNEWROMAN
  },
  {
    value: FontOptions.Tinos,
    icon: ICONS.TINOS
  }
];

export const GivingFormLookAndFeelMenu = ({
  startingPrimaryColor,
  updatePrimaryColor,
  startingAccentColor,
  updateAccentColor,
  startingFont,
  updateFont,
  addPageBreak
}: LookAndFeelMenuTypes): JSX.Element => (
  <Box className="ElementLibraryMenu-container">
    <Box className="ElementLibraryMenu-Header">
      <Text variant="h2" className="ElementLibraryMenu-Header__title">
        Look &amp; Feel
      </Text>
    </Box>
    <Box className="ElementLibraryMenu-content--container">
      <Stack className="LookAndFeelMenu">
        <Text variant="h3">Primary Color</Text>
        <ColorPicker
          startingColor={startingPrimaryColor}
          onChange={updatePrimaryColor}
        />
        <Text variant="h3">Accent Color</Text>
        <ColorPicker
          startingColor={startingAccentColor}
          onChange={updateAccentColor}
        />
        <FontSelect<FontOptions>
          startingFont={startingFont}
          updateFont={updateFont}
          fontOptions={fontOptions}
        />
        <hr className="gf-element-library-divider" />
        <Text
          className="gf-element-library-page-break"
          variant="h4"
          onClick={addPageBreak}
        >
          + Add Page Break
        </Text>
      </Stack>
    </Box>
  </Box>
);
