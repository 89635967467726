import { CSSProperties } from 'react';

export const cssPropertiesToString = (css: CSSProperties) =>
  Object.entries(css)
    .map(
      ([k, v]) =>
        `${k.replace(
          /[A-Z]/g,
          (match) => `-${match.toLowerCase()}`
        )}:${v.replaceAll('"', "'")}`
    )
    .join(';');
