import { TextFieldProps } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import TextField from 'components/TextField';

const NumberTextElement = <T extends FieldValues>({
  name,
  control,
  ...props
}: Omit<NumericFormatProps<TextFieldProps>, 'onChange'> & {
  control: Control<T>;
  name: Path<T>;
}) => (
  <Controller
    name={name}
    control={control}
    render={({
      field: { ref, onChange, onBlur, ...field },
      fieldState: { error }
    }) => (
      <NumericFormat
        {...field}
        onValueChange={(values) => onChange(values.floatValue)}
        customInput={TextField}
        {...props}
        error={!!error}
        hiddenLabel
        id={name}
        onBlur={onBlur}
      />
    )}
  />
);

export default NumberTextElement;
