import { CircularProgress } from '@mui/material';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import Text from 'components/Text';
import { AbTest, GivingFormConfigVariant } from 'types';
import { GivingFormPreviewCard } from '../ChannelPreviewCards';
import './EndAbTestConfirmationModal.scss';

interface EndAbTestConfirmationModalProps {
  abTest: AbTest;
  selectedVariant: GivingFormConfigVariant;
  isEndingTest: boolean;
  onConfirm: () => void;
  onPreviousStep: () => void;
  onCancel: () => void;
}

export const EndAbTestConfirmationModal = ({
  abTest,
  selectedVariant,
  isEndingTest,
  onConfirm,
  onPreviousStep,
  onCancel
}: EndAbTestConfirmationModalProps): JSX.Element => {
  const abTestDelta =
    selectedVariant === GivingFormConfigVariant.VariantB
      ? abTest?.metrics?.delta
      : null;

  const getVariantName = (variant: GivingFormConfigVariant) =>
    `Form ${variant === GivingFormConfigVariant.VariantA ? 'A' : 'B'}`;

  const metrics =
    selectedVariant === GivingFormConfigVariant.VariantA
      ? abTest?.metrics?.variantA
      : abTest?.metrics?.variantB;

  return (
    <Dialog
      className="end-ab-test-confirm-dialog"
      label="end ab test confirmation dialog"
      open
      onClose={onCancel}
    >
      <DialogTitle onClick={onCancel}>Are you Sure?</DialogTitle>
      <DialogContent>
        <DialogContentTitle>
          You have now chosen to use {getVariantName(selectedVariant)}.
        </DialogContentTitle>
        <Text variant="body">
          Once you end this test, you will not able to restart the test or
          change your mind later. The form you select will be published and the
          alternate can be found in your archives.
          <Text as="span" variant="h4">
            Do you wish to continue?
          </Text>
        </Text>
        <div className="variant-container">
          <GivingFormPreviewCard
            className="end-ab-test-card"
            givingFormName={getVariantName(selectedVariant)}
            givingFormId={abTest?.givingForm.id}
            createdDate={abTest?.createdAt}
            amountRaised={metrics?.amountRaised}
            conversionRate={metrics?.conversionRate}
            donationCount={metrics?.donationCount}
            visits={metrics?.visits}
            unpublishedChanges={false}
            parentComp="endAbTest"
            testVariantSelected
            abTestDelta={abTestDelta}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          variant="primary"
          disabled={isEndingTest}
          onClick={onConfirm}
        >
          {isEndingTest ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Yes, End Test & Launch Form'
          )}
        </DialogActionButton>
        <DialogActionButton
          variant="secondary"
          disabled={isEndingTest}
          onClick={onPreviousStep}
        >
          No, Back to Select Form
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
