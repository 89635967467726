/* eslint-disable class-methods-use-this */

export class Logger {
  log(message?: any, ...optionalParams: any[]) {
    if (optionalParams && optionalParams.length && optionalParams[0]) {
      console.log(message, ...optionalParams);
    } else {
      console.log(message);
    }
  }

  error(message?: any, ...optionalParams: any[]) {
    if (optionalParams && optionalParams.length && optionalParams[0]) {
      console.error(message, ...optionalParams);
    } else {
      console.error(message);
    }
  }
}
