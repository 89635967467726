import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import TextField from 'components/TextField';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import {
  useCreateEmailByTypeMutation,
  useValidateEmailName
} from 'queries/UseEmails';
import { Email } from 'services';
import { EmailType } from 'types/emailTypes';
import './NewReceiptDialog.scss';

type NewReceiptDialogProps = {
  open: boolean;
  onClose: () => void;
  onCreate?: (data: Email) => void;

  /**
   * the id of the giving form to copy
   */
  templateId?: string;
};

/**
 * A GMS-specific dialog for creating a new giving form.
 * The user must input a name for the new giving form before submitting.
 */
export const NewReceiptDialog = ({
  open,
  onClose,
  onCreate,
  templateId
}: NewReceiptDialogProps): JSX.Element => {
  const [formName, setFormName] = useState('');
  const [addAlert] = useAlerts();
  const [valid, setValid] = useState(true);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!valid) {
      setValid(true);
    }
    setFormName(e.currentTarget.value);
  };

  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();

  const { mutateAsync: createReceiptMutation, isLoading: isCreatingReceipt } =
    useCreateEmailByTypeMutation({
      organizationId,
      type: EmailType.Receipt,
      templateId,
      name: formName
    });

  const { mutateAsync: checkValidReceiptName, isLoading: isCheckingValidity } =
    useValidateEmailName({
      organizationId,
      type: EmailType.Receipt,
      name: formName
    });

  const checkValid = async () => {
    const isValid = await checkValidReceiptName();
    setValid(isValid);
    return isValid;
  };

  const handleCreateReceipt = async () => {
    try {
      const newReceipt = await createReceiptMutation();
      setFormName('');
      onCreate?.(newReceipt);
    } catch (e) {
      addAlert({
        title: (e as AxiosError).response.data.message,
        severity: 'error'
      });
    }
  };

  const onStartBuilding = async (close: () => void) => {
    const isValid = await checkValid();
    if (isValid) {
      await handleCreateReceipt();
      close();
    }
  };

  return (
    <Dialog
      className="new-receipt-dialog"
      label="new receipt dialog"
      open={open}
      onClose={onClose}
    >
      <DialogTitle onClick={(close) => close()} />
      <DialogContent>
        <DialogContentTitle>
          What are we calling this Receipt?
        </DialogContentTitle>
        <DialogContentDescription>
          To start building your receipt, we need to give it a unique name.
        </DialogContentDescription>
        <TextField
          value={formName}
          placeholder="Enter Form Name"
          hiddenLabel
          onChange={handleChange}
          error={!valid}
          helperText={
            !valid
              ? `"${formName}" is already being used by another receipt in this organization. Please pick a unique name.`
              : undefined
          }
        />
      </DialogContent>
      <DialogActions>
        <DialogActionButton variant="secondary" onClick={(close) => close()}>
          Cancel
        </DialogActionButton>
        <DialogActionButton
          variant="primary"
          onClick={onStartBuilding}
          disabled={formName === '' || isCheckingValidity || isCreatingReceipt}
        >
          {isCreatingReceipt ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Start Building'
          )}
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
