import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps
} from '@mui/material';
import { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { useDialogContext } from './Dialog';

export type DialogTitleProps = {
  /**
   * The title for the dialog
   */
  children?: string;

  /**
   * Fired when the button is clicked.
   * A close() callback is passed for convenience to close the dialog.
   * If not provided, the close icon will not show up.
   */
  onClick?: (onClose: () => void) => void;
} & Omit<MuiDialogTitleProps, 'onClick' | 'children'>;

const DialogTitle = ({
  children,
  onClick,
  ...props
}: DialogTitleProps): JSX.Element => {
  const { onClose, computedLabel, isSuccessVariant } = useDialogContext();

  return (
    <MuiDialogTitle
      id={`${computedLabel}-dialog-title`}
      sx={{
        position: 'relative'
      }}
      {...props}
    >
      <Text
        sx={(theme) => ({
          ...theme.typography.h2,
          display: 'inline-block',
          color: 'white'
        })}
        variant="h2"
        component="span"
      >
        {children}
      </Text>
      {onClick ? (
        <IconButton
          icon={ICONS.X}
          label="close"
          tooltipLabel="Close"
          variant={isSuccessVariant ? 'tertiary' : 'primary'}
          onClick={() => onClick(() => onClose('closeButtonClick'))}
          sx={{
            position: 'absolute',
            right: 18,
            top: isSuccessVariant ? 18 : 12
          }}
        />
      ) : null}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
