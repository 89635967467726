export const usdCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2
});

export const customNumberFormatter = (digits: number) =>
  new Intl.NumberFormat('en-US', {
    maximumFractionDigits: digits
  });

export const timeFormatter = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: '2-digit'
});

export const decimalToPercentage = (decimal: number) =>
  Number(customNumberFormatter(1).format(decimal * 100));
