import * as yup from 'yup';
import { Option } from 'components/ComboCheckBox/ComboCheckBox';

export interface INewCampaignSchema {
  title: string;
  startDate: string;
  endDate?: string;
  goalAmount?: number;
  designations: Option[];
}

function lessThanEndDate(this: yup.TestContext, field: Date) {
  const endDate = new Date(this?.parent?.endDate);
  if (!endDate.getTime()) {
    return true;
  }
  return field.setHours(0, 0, 0, 0) <= endDate.setHours(0, 0, 0, 0);
}

function goalAmountFitsRange(field?: number) {
  if (!field) {
    return true;
  }
  return field >= 5 && field <= 9999999999;
}

export const NewCampaignSchema = yup.object({
  title: yup.string().required('A unique name is required'),
  startDate: yup
    .date()
    .required('A start date is required')
    .test('lessThanEndDate', 'Must be valid range', lessThanEndDate),
  goalAmount: yup
    .mixed()
    .nullable()
    .test(
      'mustFitRange',
      'Must be between $5 and $9,999,999,999',
      goalAmountFitsRange
    ),
  endDate: yup
    .date()
    .min(new Date(), 'End date cannot be in the past')
    .nullable(true),
  designations: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required()
      })
    )
    .min(1, 'Must select at least one designation')
});
