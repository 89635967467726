import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GivingFormEditor from 'components/GivingFormEditor';
import { DeleteModalConfig } from 'components/gms/DeleteBlockModal/DeleteBlockModal';
import { FlaskBlocker } from 'components/gms/FlaskBlocker';
import { useAlerts } from 'hooks';
import { useAbTestDraft } from 'hooks/useAbTestDraft';
import { useAppContext } from 'hooks/useAppContext';
import {
  ConfigContextProvider,
  useConfigContext
} from 'hooks/useConfigContext';
import { IframeContextProvider } from 'hooks/useEditorIframeContext';
import {
  useGivingFormByInstanceId,
  useValidateGivingFormConfig
} from 'queries/UseGivingForms';
import { GivingFormModes } from 'services';
import { IGivingFormConfig } from 'types';

type EditVariantBStepProps = {
  goToNextStep(isValid: boolean): void;
  isTriggerNextPage: boolean;
  resetTriggerNextPage: () => void;
};

const EditVariantBStepChild = ({
  isTriggerNextPage,
  goToNextStep,
  resetTriggerNextPage
}: EditVariantBStepProps) => {
  const {
    envConfig,
    selectedOrganization: { id: organizationId }
  } = useAppContext();
  const { givingFormId } = useParams();
  const { handleSetForm, configData } = useConfigContext<IGivingFormConfig>();
  const {
    data: givingFormData,
    isLoading: isLoadingGivingFormData,
    refetch: getLatestGivingFormData
  } = useGivingFormByInstanceId(givingFormId);

  const { draft, updateDraft } = useAbTestDraft();
  const iframeSrc = `${envConfig?.appsBaseUrl}/giving-form/edit-${givingFormId}`;
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [deleteModalConfig, setDeleteModalConfig] = useState<DeleteModalConfig>(
    {
      open: false
    }
  );

  const [pushAlert] = useAlerts();

  const { mutateAsync: validateConfig } = useValidateGivingFormConfig(
    { organizationId, config: configData.config, givingFormId },
    {
      onSuccess: (isValid) => goToNextStep(isValid),
      onError: (error) => {
        setIsValidating(false);
        let message = 'Unable to process.'; // Fallback error message incase something unexpected threw a non-base Error
        if (error instanceof Error) {
          message = error.message;
        }
        pushAlert({
          title: 'Error moving to next step.',
          description: message,
          severity: 'error'
        });
      }
    }
  );

  useEffect(() => {
    if (givingFormData && !configData?.config?.blocks) {
      handleSetForm({
        ...givingFormData,
        seedConfigPublishedAt: givingFormData.updatedAt
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingFormData]);

  useEffect(() => {
    const attemptNext = async () => {
      if (isTriggerNextPage) {
        setIsValidating(true);
        resetTriggerNextPage();

        const { data: latestGivingFormData } = await getLatestGivingFormData();

        // Don't throw validation error if HP exists, validation will occur on next step.
        // Only one config difference of either the GF or the Hosted Page is required for validation
        if (
          JSON.stringify(latestGivingFormData.config) ===
            JSON.stringify(draft.config.configVariantB) &&
          !givingFormData.config.hostedPageConfig
        ) {
          pushAlert({
            title: 'You must make a change.',
            description: 'Form B cannot be the same as Form A',
            severity: 'error'
          });
          setIsValidating(false);
          return;
        }
        validateConfig();
      }
    };

    attemptNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTriggerNextPage]);

  useEffect(() => {
    updateDraft({
      config: {
        configVariantB: configData.config
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData.config]);

  return (
    <FlaskBlocker block={isLoadingGivingFormData || isValidating}>
      <IframeContextProvider
        iframeSrc={iframeSrc}
        setDeleteModalConfig={setDeleteModalConfig}
        mode={GivingFormModes.EDIT}
      >
        <GivingFormEditor
          activeTab="GivingForm"
          setActiveTab={() => undefined}
          deleteModalConfig={deleteModalConfig}
          setDeleteModalConfig={setDeleteModalConfig}
          isAbTestWizard
        />
      </IframeContextProvider>
    </FlaskBlocker>
  );
};

export const EditVariantBStep = ({
  goToNextStep,
  isTriggerNextPage,
  resetTriggerNextPage
}: EditVariantBStepProps) => {
  const { givingFormId } = useParams();
  return (
    <ConfigContextProvider<IGivingFormConfig>
      id={givingFormId}
      type="givingFormVariantB"
      key={givingFormId}
    >
      <EditVariantBStepChild
        isTriggerNextPage={isTriggerNextPage}
        goToNextStep={goToNextStep}
        resetTriggerNextPage={resetTriggerNextPage}
      />
    </ConfigContextProvider>
  );
};
