import { Line } from 'react-chartjs-2';
import { LineGraphData } from '../LineGraph/LineGraph.types';
import './DonorLineGraph.scss';

type DonorLineGraphProps = {
  chartData: LineGraphData;
  color: string;
};

export const DonorLineGraph = ({ chartData, color }: DonorLineGraphProps) => (
  <div className="donor-line-graph-container">
    <Line
      className="donor-line-graph"
      data={{
        datasets: [
          {
            borderColor: color,
            borderWidth: 2,
            data: chartData,
            pointRadius: 0,
            pointHoverRadius: 0
          }
        ]
      }}
      options={{
        responsive: true,
        elements: {
          line: {
            tension: 0.4
          }
        },
        plugins: {
          title: {
            display: false
          },
          tooltip: {
            enabled: false
          },
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false
            },
            ticks: {
              display: false
            }
          },
          y: {
            grid: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false
            },
            ticks: {
              display: false
            }
          }
        }
      }}
    />
  </div>
);
