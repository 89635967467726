import { IGivingFormConfig } from 'types/givingFormTypes';

export enum TimeNotificationUnit {
  day = 'day',
  week = 'week',
  month = 'month'
}

export enum NotificationUnit {
  visit = 'visit',
  donation = 'donation'
}

export interface AbTestThresholds {
  timeUnit?: TimeNotificationUnit;
  timeAmount?: number;
  unit?: NotificationUnit;
  amount?: number;
}

export interface AbTestNote {
  createdAt: string;
  updatedAt?: string;
  id: string;
  content: string;
  userId: string;
  userName?: string;
  userEmail?: string;
}

export interface AbTestMetricsSummary {
  conversionRate: number | null;
  visits: number;
  visitors: number;
  amountRaised: number;
  donationCount: number;
  donationAverage: number;
}

export interface AbTestMetrics {
  givingFormId: string;
  variantA: AbTestMetricsSummary | null;
  variantB: AbTestMetricsSummary | null;
  delta: AbTestMetricsSummary | null;
}

export interface AbTest {
  abTestId: string;
  assumption?: string;
  campaign: {
    id: string;
    name: string;
  };
  configVariantA: IGivingFormConfig;
  configVariantB: IGivingFormConfig;
  createdAt: string;
  description?: string;
  endedAt?: string;
  givingForm: {
    name: string;
    id: string;
  };
  metrics: AbTestMetrics | null;
  name: string;
  notes?: AbTestNote[];
  organization: {
    id: string;
  };
  thresholds: AbTestThresholds;
}

export interface AbTestDraft {
  name: string;
  description?: string;
  assumption?: string;
  thresholds?: AbTestThresholds;
  configVariantB?: IGivingFormConfig;
  organization: {
    id: string;
  };
  givingForm: {
    id: string;
  };
}

export enum GivingFormConfigVariant {
  VariantA = 'VariantA',
  VariantB = 'VariantB'
}
