import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ButtonProps } from 'components';
import { Blocker } from 'components/Blocker';
import Icon, { ICONS } from 'components/Icon';
import { ReceiptPreviewModal } from 'components/ReceiptPreviewModal';
import { ConfirmDefaultEmailDialog } from 'components/gms/ConfirmDefaultEmailDialog/ConfirmDefaultEmailDialog';
import { ConfirmReceiptEditDialog } from 'components/gms/ConfirmReceiptEditDialog/ConfirmReceiptEditDialog';
import { EmailPreviewCard } from 'components/gms/EmailPreviewCard';
import { FeatureHeader } from 'components/gms/FeatureHeader';
import { NewReceiptDialog } from 'components/gms/NewReceiptDialog/NewReceiptDialog';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useEmails, useMarkEmailAsDefault } from 'queries/UseEmails';
import { Email } from 'services';
import { EmailType } from 'types';
import './ReceiptManagement.scss';

export const ReceiptManagement = (): JSX.Element => {
  const [pushAlert] = useAlerts();
  const navigate = useNavigate();

  const [receiptPreviewModalOpen, setReceiptPreviewModalOpen] =
    useState<boolean>(false);
  const [receiptPreviewHtml, setReceiptPreviewHtml] = useState<string>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showNewReceiptDialog, setShowNewReceiptDialog] =
    useState<boolean>(false);
  const [selectedReceipt, setSelectedReceipt] = useState<string>();

  const {
    selectedOrganization: { id: organizationId }
  } = useAppContext();
  const {
    data: savedReceipts,
    isLoading,
    isError: isErrorLoadingSavedReceipts
  } = useEmails({
    organizationId,
    type: EmailType.Receipt
  });

  const { mutateAsync: markEmailAsDefault, isLoading: defaultEmailUpdating } =
    useMarkEmailAsDefault();

  const onEmailEdit = (emailId: string) =>
    navigate(`/emails/receipt/${emailId}/edit`, {
      state: {
        isFromReceiptManager: true
      }
    });

  const handleMarkAsDefault = (emailId: string) => {
    markEmailAsDefault({ organizationId, emailId });
  };

  useEffect(() => {
    if (isErrorLoadingSavedReceipts) {
      pushAlert({
        title:
          'Uh oh. Looks like there was an error loading your saved receipts.',
        severity: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorLoadingSavedReceipts]);

  const primaryButtonProps: ButtonProps = {
    children: 'Start From Scratch',
    onClick: () => setShowNewReceiptDialog(true),
    startIcon: <Icon icon={ICONS.PLUS} />
  };

  const generatePreviewButtons = (receipt: Email) => {
    const buttons = [];
    if (!receipt.isDefault) {
      buttons.push({
        label: 'Set as Default',
        onClick: () => {
          setSelectedReceipt(receipt.emailId);
          setShowConfirmModal(true);
        },
        icon: ICONS.CLICK
      });
    }

    buttons.push(
      {
        label: 'Customize',
        onClick: () => {
          setSelectedReceipt(receipt.emailId);
          setShowEditModal(true);
        },
        icon: ICONS.PENCIL
      },
      {
        label: 'Preview',
        onClick: () => {
          setReceiptPreviewHtml(receipt.html.toString());
          setReceiptPreviewModalOpen(true);
        },
        icon: ICONS.EYE
      }
    );

    return buttons;
  };

  return (
    <div className="receipt-management">
      <FeatureHeader
        primaryButtonProps={primaryButtonProps}
        titleProps="Receipts"
      />
      <Blocker block={isLoading || defaultEmailUpdating}>
        <ConfirmDefaultEmailDialog
          isOpen={showConfirmModal}
          onConfirm={() => handleMarkAsDefault(selectedReceipt)}
          onClose={() => {
            setShowConfirmModal(false);
            setSelectedReceipt(null);
          }}
        />
        <ConfirmReceiptEditDialog
          emailId={selectedReceipt}
          isOpen={showEditModal}
          onConfirm={() => onEmailEdit(selectedReceipt)}
          onClose={() => {
            setSelectedReceipt(null);
            setShowEditModal(false);
          }}
        />
        <NewReceiptDialog
          open={showNewReceiptDialog}
          onClose={() => setShowNewReceiptDialog(false)}
          onCreate={(newReceipt) => onEmailEdit(newReceipt.emailId)}
          templateId=""
        />
        <Box padding={7} paddingTop={11} display="flex">
          {receiptPreviewHtml && (
            <ReceiptPreviewModal
              receiptHtml={receiptPreviewHtml}
              isOpen={receiptPreviewModalOpen}
              onClose={() => setReceiptPreviewModalOpen(false)}
            />
          )}
          <Box className="receipt-management-container">
            <Grid container marginTop={1.5} justifyContent="flex-start">
              {!isLoading &&
                savedReceipts.map((receipt: Email) => (
                  <EmailPreviewCard
                    name={receipt.name}
                    type={EmailType.Receipt}
                    isSelected={false}
                    isDefault={receipt.isDefault}
                    previewButtons={generatePreviewButtons(receipt)}
                    key={receipt.emailId}
                  />
                ))}
            </Grid>
          </Box>
        </Box>
      </Blocker>
    </div>
  );
};
