import { ElementLibraryHostedPageViews } from 'types';
import { IDataView } from '../ElementLibrary';
import { EditCustomContent } from './EditCustomContent';
import EditGoalMeter from './EditGoalMeter';
import { EditHostedPageFooter } from './EditHostedPageFooter';
import { EditHostedPageHeader } from './EditHostedPageHeader';
import RootViewContent from './RootMenu';

export const ElementLibraryHostedPageViewData: {
  [key in ElementLibraryHostedPageViews]: IDataView<ElementLibraryHostedPageViews>;
} = {
  [ElementLibraryHostedPageViews.RootView]: {
    title: 'Element Library',
    component: RootViewContent
  },
  [ElementLibraryHostedPageViews.HeaderView]: {
    title: 'Header',
    subtitle: 'Give your program form a header and description.',
    component: () => <EditHostedPageHeader />
  },
  [ElementLibraryHostedPageViews.CustomContentBlockView]: {
    title: 'Custom Content Block',
    subtitle: 'Share custom content with your donors',
    component: () => <EditCustomContent />
  },
  [ElementLibraryHostedPageViews.GoalMeterView]: {
    title: 'Goal Meter',
    subtitle:
      'Showcase a goal meter to show your donors progress towards your donation goal',
    component: () => <EditGoalMeter />
  },
  [ElementLibraryHostedPageViews.FooterView]: {
    title: 'Footer',
    subtitle: 'Control what is displayed in the bottom of your Hosted Page.',
    component: () => <EditHostedPageFooter />
  }
};
