import { TableCell } from '@mui/material';

export interface BooleanCellProps {
  value: boolean;
  trueValue?: string;
  falseValue?: string;
}

export const BooleanCell = ({
  value,
  trueValue,
  falseValue
}: BooleanCellProps) => (
  <TableCell>{value ? trueValue || 'Yes' : falseValue || 'No'}</TableCell>
);
