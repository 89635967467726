import abTestingFeatureImg from 'assets/images/strategy-ab-testing.svg';
import marketTrendsFeatureImg from 'assets/images/strategy-market-trends.svg';
import recurringGiftOptionsFeatureImg from 'assets/images/strategy-recurring-gift-options.svg';
import abTestImg from 'assets/images/strategy.png';
import Text from 'components/Text';
import './StrategyPitch.scss';

export const StrategyPitch = () => (
  <div className="strategy-pitch">
    <div className="strategy-pitch-left">
      <Text variant="h1" className="strategy-pitch-title">
        Build your organization with strategy.
      </Text>
      <Text variant="h7" as="h2">
        Our premium features will help you increase your donations by
        implementing strategy. We will provide the tools to do more good!
      </Text>
      <ul className="strategy-pitch-features">
        <li className="strategy-pitch-feature">
          <img
            className="strategy-pitch-feature-img"
            src={abTestingFeatureImg}
            alt="ab testing feature"
            loading="lazy"
          />
          <div>
            <Text className="strategy-pitch-feature-title" variant="h2" as="h3">
              A/B Testing
            </Text>
            <Text variant="caption">
              Take guessing out of it. An A/B test is designed to help you make
              the right decisions for your giving forms. Make changes and test
              out which form creates the biggest impact with your donors.
            </Text>
          </div>
        </li>
        <li className="strategy-pitch-feature">
          <img
            className="strategy-pitch-feature-img"
            src={recurringGiftOptionsFeatureImg}
            alt="recurring gift options feature"
            loading="lazy"
          />
          <div>
            <Text className="strategy-pitch-feature-title" variant="h2" as="h3">
              Recurring Gift Options
            </Text>
            <Text variant="caption">
              With premium, your donors can enable recurring gift options and
              donate to your cause on a fixed, recurring schedule.
            </Text>
          </div>
        </li>
        <li className="strategy-pitch-feature">
          <img
            className="strategy-pitch-feature-img"
            src={marketTrendsFeatureImg}
            alt="market trends feature"
            loading="lazy"
          />
          <div>
            <Text className="strategy-pitch-feature-title" variant="h2" as="h3">
              Market Trends
            </Text>
            <Text variant="caption">
              Google Analytics, go-to-market strategies, and other marketing
              help can be found when you upgrade to premium!
            </Text>
          </div>
        </li>
      </ul>
      {/* <Button className="strategy-pitch-upgrade-btn"> */}
      {/*  Upgrade to Premium Now! */}
      {/* </Button> */}
    </div>
    <div className="strategy-pitch-right">
      <img
        className="strategy-pitch-img"
        src={abTestImg}
        alt="ab testing example"
        loading="lazy"
      />
    </div>
  </div>
);
