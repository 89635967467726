import { useEffect, useState } from 'react';
import { Box, SelectChangeEvent, Stack } from '@mui/material';
import { MultiselectCheckbox } from 'components';
import { MultiselectOption } from 'components/Select/MultiselectCheckbox';
import Text from 'components/Text';
import { useConfigContext } from 'hooks/useConfigContext';
import { BlockTypes, ICustomFieldBlock, IGivingFormConfig } from 'types';
import {
  BlockDetailType,
  DonorGiftOptionsType,
  IThankYouMessageDonationDetailsBlock,
  ScheduleOptionsType,
  TodaysTransactionOptionsType
} from 'types/givingFormTypes/ThankYouMessageDonationDetailsBlock';
import { isFeatureEnabled, isRecurringEnabled } from 'utils/configHelper';
import './ThankYouMessageDonationDetails.scss';

// static options lists
const enumToMultiselectOption = (
  theEnum: Record<string, string>
): MultiselectOption[] =>
  Object.values(theEnum).map((value) => ({
    label: value,
    value
  }));
const scheduleOptions = enumToMultiselectOption(ScheduleOptionsType);
const todaysTransactionOptions = enumToMultiselectOption(
  TodaysTransactionOptionsType
);

interface DetailBlockData extends IThankYouMessageDonationDetailsBlock {
  titleText: string;
  options: MultiselectOption[];
  initialOptions: string[];
  handleUpdate: (event: SelectChangeEvent<string[]>) => void;
  enabled: boolean;
}

const ThankYouDonationDetails = (): JSX.Element => {
  const {
    configData: { config },
    updateConfig,
    setHighlightedBlock,
    highlightedBlock
  } = useConfigContext<IGivingFormConfig>();

  // look up custom fields from the config
  const customFieldsOptions: MultiselectOption[] = config.blocks
    .filter((block) => block.blockType === BlockTypes.CustomFieldBlock)
    .map((block) => {
      const value = (block as ICustomFieldBlock).reportingKey;
      return { label: value, value };
    });

  // config-based conditional fields
  let donorGiftOptions = enumToMultiselectOption(DonorGiftOptionsType);
  if (!isFeatureEnabled(config, BlockTypes.CoverTransactionFeeBlock)) {
    donorGiftOptions = donorGiftOptions.filter(
      (option) => option.value !== DonorGiftOptionsType.TransactionFee
    );
  }

  if (!isFeatureEnabled(config, BlockTypes.GiveAnonymouslyBlock)) {
    donorGiftOptions = donorGiftOptions.filter(
      (option) => option.value !== DonorGiftOptionsType.AnonymousGift
    );
  }

  // TODO: The email opt-in looks like it is currently not
  // implemented. Add the check once it is.
  // if (true) {
  //   donorGiftOptions = donorGiftOptions.filter(
  //     (option) => option.value !== DonorGiftOptionsType.EmailOptIn
  //   );
  // }

  const getConfigBlock = (formConfig: IGivingFormConfig, detailType: string) =>
    formConfig.thankYouConfig.blocks.find(
      (block) =>
        block.blockType === BlockTypes.ThankYouMessageDonationDetailsBlock &&
        (block as IThankYouMessageDonationDetailsBlock).detailType ===
          detailType
    ) as IThankYouMessageDonationDetailsBlock;

  // get blocks from config
  const donorBlock = getConfigBlock(config, BlockDetailType.DonorInformation);
  const scheduleBlock = getConfigBlock(
    config,
    BlockDetailType.ScheduleInformation
  );
  const transactionBlock = getConfigBlock(
    config,
    BlockDetailType.TodaysTransaction
  );
  const customFieldsBlock = getConfigBlock(
    config,
    BlockDetailType.CustomFields
  );

  // generate initial state
  const [initialDonorOptions, setInitialDonorOptions] = useState<string[]>(
    donorBlock?.placeholders as string[]
  );
  const [initialScheduleOptions] = useState<string[]>(
    scheduleBlock?.placeholders as string[]
  );
  const [initialTransactionOptions] = useState<string[]>(
    transactionBlock?.placeholders as string[]
  );
  const [initialCustomFieldsOption] = useState<string[]>(
    customFieldsBlock?.placeholders as string[]
  );

  // ID to manage which list is open based on highlightedBlock value:
  const [openListId, setOpenListId] = useState<string>(highlightedBlock);
  useEffect(() => {
    setOpenListId(highlightedBlock);
  }, [highlightedBlock]);

  // actions
  const removeBlockFromConfig = (detailType: BlockDetailType) => {
    const newConfig = { ...config };
    const blocks = newConfig.thankYouConfig.blocks.filter(
      (block) =>
        block.blockType !== BlockTypes.ThankYouMessageDonationDetailsBlock ||
        (block as IThankYouMessageDonationDetailsBlock).detailType !==
          detailType
    );
    newConfig.thankYouConfig.blocks = blocks;
    if (detailType === BlockDetailType.DonorInformation) {
      setInitialDonorOptions([]);
    }
    updateConfig(newConfig);
  };

  const addOrUpdateConfigBlock = (
    detailType: BlockDetailType,
    blockId: string,
    values:
      | DonorGiftOptionsType[]
      | ScheduleOptionsType[]
      | TodaysTransactionOptionsType[]
      | string[]
  ) => {
    const newConfig = { ...config };
    let newConfigBlock = getConfigBlock(newConfig, detailType);

    if (newConfigBlock) {
      // update current block
      newConfigBlock.placeholders = values;
    } else {
      // or create new block
      newConfigBlock = {
        blockType: BlockTypes.ThankYouMessageDonationDetailsBlock,
        detailType,
        id: blockId,
        placeholders: values
      };
      newConfig.thankYouConfig.blocks.push(newConfigBlock);
      if (detailType === BlockDetailType.DonorInformation) {
        setInitialDonorOptions(values);
      }
    }

    updateConfig(newConfig);
  };

  // handlers
  const handleUpdate =
    (blockDetailType: BlockDetailType, id: string) =>
    (event: SelectChangeEvent<string[]>) => {
      let values = event.target.value as string[];
      if (typeof event.target.value === 'string') {
        values = [event.target.value] as string[];
      }
      if (!values?.length) {
        removeBlockFromConfig(blockDetailType);
      } else {
        addOrUpdateConfigBlock(blockDetailType, id, values);
      }
    };
  const handleUpdateDonorOptions = handleUpdate(
    BlockDetailType.DonorInformation,
    'tyDonorInformation'
  );
  const handleUpdateScheduleOptions = handleUpdate(
    BlockDetailType.ScheduleInformation,
    'tyScheduleInformation'
  );
  const handleUpdateTransactionOptions = handleUpdate(
    BlockDetailType.TodaysTransaction,
    'tyTodaysTransaction'
  );
  const handleUpdateCustomFieldOptions = handleUpdate(
    BlockDetailType.CustomFields,
    'tyCustomFields'
  );

  // Assemble data and handlers to easily parse for rendering:
  const donorBlockData: DetailBlockData = {
    titleText: 'Donor/Gift Information',
    id: 'tyDonorInformation',
    detailType: BlockDetailType.DonorInformation,
    options: donorGiftOptions,
    initialOptions: initialDonorOptions,
    handleUpdate: handleUpdateDonorOptions,
    enabled: true,
    ...donorBlock
  };

  const scheduleBlockData: DetailBlockData = {
    titleText: 'Schedule Information',
    id: 'tyScheduleInformation',
    detailType: BlockDetailType.ScheduleInformation,
    options: scheduleOptions,
    initialOptions: initialScheduleOptions,
    handleUpdate: handleUpdateScheduleOptions,
    enabled: isRecurringEnabled(config),
    ...scheduleBlock
  };

  const transactionBlockData: DetailBlockData = {
    titleText: "Today's Transaction",
    id: 'tyTodaysTransaction',
    detailType: BlockDetailType.TodaysTransaction,
    options: todaysTransactionOptions,
    initialOptions: initialTransactionOptions,
    handleUpdate: handleUpdateTransactionOptions,
    enabled: true,
    ...transactionBlock
  };

  const customFieldsBlockData: DetailBlockData = {
    titleText: 'Custom Field Content',
    id: 'tyCustomFields',
    detailType: BlockDetailType.CustomFields,
    options: customFieldsOptions,
    initialOptions: initialCustomFieldsOption,
    handleUpdate: handleUpdateCustomFieldOptions,
    enabled: customFieldsOptions.length > 0,
    ...customFieldsBlock
  };

  // Arrays to track and render the component in proper order
  // Precedence is:
  // 1. All donation detail blocks in the order they appear in the config
  // 2. Remaining blocks in `defaultOrder` in the order they appear in the array
  // Sections will always be rendered in order using `defaultOrder`, but use CSS flex's order to set display order
  const defaultOrder = [
    donorBlockData,
    scheduleBlockData,
    transactionBlockData,
    customFieldsBlockData
  ];

  // First grab all the blocks present in the config (in order) and map the corresponding block data objects into that array
  const presentOrder = config.thankYouConfig.blocks
    .filter(
      (block) =>
        block.blockType === BlockTypes.ThankYouMessageDonationDetailsBlock
    )
    .map((block) =>
      defaultOrder.find((blockData) => blockData.id === block.id)
    );

  // Grab the remaining defaultOrder block data object (in order)
  const missing = defaultOrder.filter(
    (block) => !presentOrder.find((presentBlock) => presentBlock === block)
  );

  // Actual order to display all the sections.  This is used for the CSS flex order value
  const actualOrder = [...presentOrder, ...missing];

  return (
    <Stack className="ElementLibrary-ThankYouDonationDetails">
      {defaultOrder.map(
        (blockData) =>
          blockData.enabled && (
            <Box
              key={blockData.detailType}
              order={actualOrder.findIndex((d) => d === blockData) + 1}
              className="ElementLibrary-ThankYouDonationDetails--section"
            >
              <Text variant="h3">{blockData.titleText}</Text>
              <MultiselectCheckbox
                onOpen={() => setHighlightedBlock(blockData.id)}
                onClose={() => setHighlightedBlock('')}
                options={blockData.options}
                defaultValue={blockData.initialOptions}
                open={blockData.id === openListId}
                sortSelected
                onChange={blockData.handleUpdate}
              />
            </Box>
          )
      )}
    </Stack>
  );
};

export default ThankYouDonationDetails;
