import { useMemo, useState } from 'react';
import {
  Table as MuiTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import './Table.scss';

export interface TableHeaderProps {
  key: string;
  label: string;
  renderer?: (value: any, rowData: any) => React.ReactElement;
}

export interface TableProps {
  headers: TableHeaderProps[];
  rows: { [key: string]: any }[];
  isLoading: boolean;
}

export const Table = ({ headers, rows, isLoading }: TableProps) => {
  const [page, setPage] = useState<number>(0);
  const rowsPerPageOptions = [10, 25, 50, 100, 9999];
  const [perPage, setPerPage] = useState<number>(() => rowsPerPageOptions[0]);

  const pagedResults = useMemo(() => {
    if (!rows) {
      return [];
    }

    const startIndex = perPage * page;
    const endIndex = Math.min(startIndex + perPage, rows?.length || 0);

    const newRows = rows?.slice(startIndex, endIndex);

    return newRows;
  }, [page, perPage, rows]);

  return (
    <TableContainer component={Paper} className="table-component">
      <MuiTable aria-label="basic table">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell>{header.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            pagedResults.map((rowData) => (
              <TableRow>
                {headers.map((header) => {
                  const { key, renderer } = header;
                  if (renderer) {
                    const val = rowData[key];
                    return renderer(val, rowData);
                  }
                  return (
                    <TableCell className="table-component-default-cell">
                      {rowData[key]}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </MuiTable>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        className="table-pagination"
        count={rows?.length}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => setPerPage(+event.target.value)}
        SelectProps={{
          MenuProps: { className: 'table-pagination-select' }
        }}
      />
    </TableContainer>
  );
};
