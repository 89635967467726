import { Box, Skeleton, Stack } from '@mui/material';
import { TablerIcon } from '@tabler/icons';
import clsx from 'clsx';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import './DashboardTotal.scss';

export interface DashboardTotalProps {
  heading: string;
  icon: TablerIcon;
  total: string;
  empty?: boolean;
  comparisonDifference?: number | null;
  comparisonUnit?: string;
  isLoading?: boolean;
}

export const DashboardTotal = ({
  heading,
  icon,
  total,
  empty = false,
  comparisonDifference,
  comparisonUnit,
  isLoading = false
}: DashboardTotalProps): JSX.Element => {
  const isIncreased = comparisonDifference > 0;

  return (
    <Stack
      flexDirection="row"
      className={clsx('dashboard-total-container', { empty })}
    >
      <Box className="icon-wrapper">
        <Icon icon={icon} />
      </Box>

      <Stack>
        <Text variant="h4">{heading}</Text>

        <Stack flexDirection="row">
          {isLoading ? (
            <Skeleton animation="wave" height={40} width="100%" />
          ) : (
            <Stack
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
              className="total-wrapper"
            >
              <Text className="value" variant="h1">
                {total}
              </Text>

              {!!comparisonDifference && comparisonDifference !== 0 && (
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  className="comparison-wrapper"
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    className={clsx('comparison-percent-wrapper', {
                      increase: isIncreased,
                      decrease: !isIncreased
                    })}
                  >
                    <Icon
                      icon={
                        isIncreased
                          ? ICONS.ARROW_UP_RIGHT
                          : ICONS.ARROW_DOWN_RIGHT
                      }
                      fontSize="small"
                    />
                    <Text variant="h6" className="comparison-percent">
                      {comparisonDifference}%
                    </Text>
                  </Stack>

                  <Text variant="h6" className="comparison-unit">
                    {comparisonUnit}
                  </Text>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
