import { FC } from 'react';
import { SvgIcon as MuiSvgIcon, SvgIconProps } from '@mui/material';

export interface IconProps extends SvgIconProps {
  icon: FC;
  strokeWidth?: number; // some non-tabler icons require different strokeWidth
}

/**
 * An Icon component meant to use icons from
 * https://tabler-icons.io with a 1.5px stroke width
 * Height and width are, by default, 1.5rem (24px).
 */
const Icon = ({
  icon,
  strokeWidth = 1.5,
  sx,
  ...props
}: IconProps): JSX.Element => (
  <MuiSvgIcon
    component={icon}
    sx={{
      strokeWidth,
      stroke: 'currentColor',
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      ...sx
    }}
    {...props}
  />
);

// the actual component
export default Icon;
