import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import Text from 'components/Text';
import './PublishWarningDialog.scss';

type PublishWarningDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  onDiscard: () => void;
};

export const PublishWarningDialog = ({
  open,
  onConfirm,
  onClose,
  onDiscard
}: PublishWarningDialogProps) => (
  <Dialog
    className="publish-warning-select-dialog"
    label="publish-warning select dialog"
    open={open}
    onClose={() => onClose()}
  >
    <DialogTitle onClick={(close) => close()} />
    <DialogContent>
      <DialogContentTitle>
        Are you sure you would like to publish?
      </DialogContentTitle>
      <Text variant="body">
        <span className="selected-campaign-name">
          Another account is currently editing this form and has newer edits.
          Would you like to override these edits to publish? Once published,
          previous edits cannot be restored.
        </span>
      </Text>
    </DialogContent>
    <DialogActions>
      <DialogActionButton
        variant="primary"
        onClick={(close) => {
          onConfirm();
          close();
        }}
      >
        Override Newer Version
      </DialogActionButton>
      <DialogActionButton
        variant="secondary"
        onClick={(close) => {
          close();
          onDiscard();
        }}
      >
        Discard Changes
      </DialogActionButton>
      <DialogActionButton variant="text" onClick={(close) => close()}>
        Cancel
      </DialogActionButton>
    </DialogActions>
  </Dialog>
);
