import pluralize from 'pluralize';
import { useParams } from 'react-router-dom';
import Text from 'components/Text';
import { useAppContext } from 'hooks/useAppContext';
import { useAbTest } from 'queries/UseAbTests';
import { NotificationUnit, TimeNotificationUnit } from 'types';
import { formatIsoDate } from 'utils';
import './AbTestDetails.scss';

export const AbTestDetails = () => {
  const { abTestId } = useParams();
  const { selectedOrganization } = useAppContext();
  const organizationId = selectedOrganization?.id;
  const { data: abTest, isLoading } = useAbTest({
    organizationId,
    abTestId,
    includeMetrics: true
  });
  // TODO mmeza - this campaign object defaulting won't be necessary after
  // we pass down an already populated test.
  const {
    thresholds,
    description,
    assumption,
    campaign: { name: campaignName },
    createdAt
  } = abTest || { campaign: {} };

  const renderThresholds = () => {
    const { unit, timeUnit, amount, timeAmount } = thresholds || {};

    // this logic yields us two variables `left` and `right`, which will either be undefined, or a module of { unit, amount }, with the time
    // module being prioritized as left over right.

    const validKeys = [
      ...Object.keys(NotificationUnit),
      ...Object.keys(TimeNotificationUnit)
    ];

    const [left, right] = [
      {
        unit,
        amount
      },
      {
        unit: timeUnit,
        amount: timeAmount
      }
    ].filter(
      (e) =>
        e.unit && // theres a unit
        e.amount && // theres an amount
        validKeys.includes(e.unit) // the unit is valid
    );

    return left ? (
      <Text className="label" variant="h3">
        Notify After: <span>{pluralize(left.unit, +left.amount, true)}</span>
        {right && (
          <>
            <span className="or">{' or '}</span>
            <span>{pluralize(right.unit, +right.amount, true)}</span>
          </>
        )}
      </Text>
    ) : null;
  };

  return (
    !isLoading && (
      <div className="aside-container">
        <Text className="caption-campaign" variant="caption">
          Campaign: <span className="caption-span">{campaignName}</span>{' '}
        </Text>
        <Text className="caption-campaign" variant="caption">
          Test Created: {formatIsoDate(createdAt, 'MMM dd, yyyy')}
        </Text>
        <Text className="label" variant="h3">
          Description: <span className="description-span">{description}</span>
        </Text>
        <Text className="label" variant="h3">
          Assumption: <span>{assumption}</span>
        </Text>
        {renderThresholds()}
      </div>
    )
  );
};
