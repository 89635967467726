import { v4 as uuid } from 'uuid';
import { useConfigContext } from 'hooks/useConfigContext';
import {
  BlockTypes,
  IGivingFormConfig,
  IThankYouCorporateMatchingBlock
} from 'types';
import OptionalSection from '../OptionalSection';
import './ThankYouCorporateMatching.scss';

export const ThankYouCorporateMatching = (): JSX.Element => {
  const {
    configData: { config },
    updateConfig
  } = useConfigContext<IGivingFormConfig>();
  const initialThankYouCorporateMatchingBlock =
    config.thankYouConfig.blocks.find(
      (block) => block.blockType === BlockTypes.ThankYouCorporateMatchingBlock
    ) as IThankYouCorporateMatchingBlock;

  const toggleThankYouCorporateMatching = (enabled: boolean) => {
    const newConfig = { ...config };
    if (enabled) {
      const id = uuid();
      const newBlock: IThankYouCorporateMatchingBlock = {
        blockType: BlockTypes.ThankYouCorporateMatchingBlock,
        id,
        isEnabled: enabled
      };
      newConfig.thankYouConfig.blocks.push(newBlock);
    } else {
      newConfig.thankYouConfig.blocks = newConfig.thankYouConfig.blocks.filter(
        (block) => block.blockType !== BlockTypes.ThankYouCorporateMatchingBlock
      );
    }

    updateConfig(newConfig);
  };

  return (
    <div className="thank-you-corporate-matching">
      <OptionalSection
        initiallySelected={!!initialThankYouCorporateMatchingBlock}
        title="Enable Corporate Matching"
        titleVariant="h3"
        onChange={(enabled) => {
          toggleThankYouCorporateMatching(enabled);
        }}
      />
    </div>
  );
};
