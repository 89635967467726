import { ReactNode } from 'react';
import clsx from 'clsx';
import BlockerGif from './Blocker.gif';
import './Blocker.scss';

export interface BlockerProps {
  className?: string;
  block: boolean;
  children: ReactNode;
}

export const Blocker = ({
  className,
  block,
  children
}: BlockerProps): JSX.Element => (
  <div className={clsx('blocker-wrapper', className)}>
    {block && (
      <div className="blocker-indicator">
        <img alt="Loading..." src={BlockerGif} />
      </div>
    )}
    {children}
  </div>
);
