import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';

type UnsubscribeFromShareDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const UnsubscribeFromShareDialog = ({
  open,
  onConfirm,
  onClose
}: UnsubscribeFromShareDialogProps) => (
  <Dialog open={open} label="Unsubscribe from Template Share" onClose={onClose}>
    <DialogTitle>Unsubscribe from Template Share</DialogTitle>
    <DialogContent>
      <DialogContentTitle>Are you sure?</DialogContentTitle>
      <DialogContentDescription>
        You can only be resubscribed by your parent organization.
      </DialogContentDescription>
    </DialogContent>
    <DialogActions>
      <DialogActionButton
        onClick={(close) => {
          onConfirm();
          close();
        }}
        variant="primary"
      >
        Yes, Unsubscribe
      </DialogActionButton>
      <DialogActionButton onClick={(close) => close()} variant="secondary">
        No, Cancel
      </DialogActionButton>
    </DialogActions>
  </Dialog>
);
