import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack
} from '@mui/material';
import { ICONS, LimitedTextField } from 'components';
import Button from 'components/Button';
import { EditCustomImpact } from 'components/EditorMenus/ElementLibrary/GivingFormViews/EditCustomImpact';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { DeleteConfirmationModal } from 'components/gms/DeleteConfirmationModal/DeleteConfirmationModal';
import { useConfigContext } from 'hooks/useConfigContext';
import {
  BlockTypes,
  GiftOption,
  IGiftOptionsBlock,
  IGivingFormConfig
} from 'types';
import { CustomImpactStatement } from 'types/givingFormTypes/GiftOptionsBlock';
import './EditImpact.scss';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const IMPACT_MAX_CHARS = 100;

type EditImpactProps = {
  option: GiftOption | null;
  open: boolean;
  setOpenIndex: Dispatch<SetStateAction<number>>;
  onUpdate: (index: number, impactStatement: string) => void;
  onDelete: (index: number) => void;
  index?: number;
  primaryColor?: string;
  font?: string;
};

const EditImpact = ({
  option,
  open,
  setOpenIndex,
  onUpdate,
  onDelete,
  index,
  primaryColor,
  font
}: EditImpactProps): JSX.Element => {
  const [impactStatement, setImpactStatement] = useState<string>(
    option.impactStatement ?? ''
  );

  useEffect(() => {
    // clears the limited textfield when the impact is deleted
    setImpactStatement(option.impactStatement);
  }, [option.impactStatement]);

  return (
    <Accordion className="edit-impact" expanded={open}>
      <AccordionSummary
        className={`edit-impact-summary ${open ? 'expanded' : ''}`}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={0.25} alignItems="center">
            <Text variant="h3">{currencyFormatter.format(option.amount)}</Text>
            {option.tag && <Text variant="body">({option.tag})</Text>}
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            {!open && option.impactStatement && (
              <IconButton
                label="edit"
                variant="basic"
                size="small"
                icon={ICONS.PENCIL}
                onClick={() => setOpenIndex(index)}
              />
            )}
            {option.impactStatement && (
              <IconButton
                label="delete"
                variant="basic"
                size="small"
                icon={ICONS.TRASH}
                onClick={() => onDelete(index)}
              />
            )}
            {!open && !option.impactStatement && (
              <div className="add-impact">
                <Icon icon={ICONS.PLUS} fontSize="small" />
                <Text variant="h4" onClick={() => setOpenIndex(index)}>
                  Add Impact
                </Text>
              </div>
            )}
          </Stack>
        </Stack>
        {!open && option.impactStatement && (
          <div
            className="impact-preview-container"
            style={{
              border: `1px solid ${primaryColor || '#000000'}`
            }}
          >
            <div
              className="impact-background"
              style={{
                backgroundColor: `${primaryColor || '#ccc'}`
              }}
            />
            <div className="impact-content">
              <Text
                variant="h5"
                style={{
                  color: `${primaryColor || '#000000'}`,
                  fontFamily: `${font || 'Roobert'}`
                }}
              >
                {option.impactStatement}
              </Text>
            </div>
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails
        className={`edit-impact-details ${open ? 'expanded' : ''}`}
      >
        <Stack spacing={0.5}>
          <Text variant="h5">
            What impact will this donation amount provide?
          </Text>
          <LimitedTextField
            placeholder="Enter Impact"
            value={impactStatement}
            hiddenLabel
            onChange={(e) => setImpactStatement(e.target.value)}
            maxChar={IMPACT_MAX_CHARS}
            multiline
            minRows={4}
          />

          <Button
            variant="primary"
            onClick={() => onUpdate(index, impactStatement)}
            disabled={!impactStatement}
            fullWidth
          >
            Update
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export const EditImpacts = (): JSX.Element => {
  const { configData, updateConfig } = useConfigContext<IGivingFormConfig>();
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [customOpen, setCustomOpen] = useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [deletingCustom, setDeletingCustom] = useState<boolean>(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState<boolean>(false);

  const giftOptionsBlockConfig = configData.config.blocks.find(
    (block) => block.blockType === BlockTypes.GiftOptionBlock
  ) as IGiftOptionsBlock;
  const giftOptionBlockIndex = configData.config.blocks.indexOf(
    giftOptionsBlockConfig
  );

  // edit custom state
  const [customImpactStatement, setCustomImpactStatement] =
    useState<CustomImpactStatement>(
      giftOptionsBlockConfig.customImpactStatement || {
        label: '',
        equationAmount: 0
      }
    );

  const updateGiftOptionsConfig = (
    newGiftOptionsBlockConfig: IGiftOptionsBlock
  ) => {
    const newBlocks = configData.config.blocks;
    newBlocks.splice(giftOptionBlockIndex, 1, newGiftOptionsBlockConfig);
    const newConfig: IGivingFormConfig = {
      ...configData.config,
      blocks: newBlocks
    };
    updateConfig(newConfig);
  };

  const handleImpactUpdate = (index: number, impactStatement: string) => {
    const newOptions = giftOptionsBlockConfig.giftOptions;
    const updatedOption = {
      ...giftOptionsBlockConfig.giftOptions[index],
      impactStatement
    };
    newOptions[index] = updatedOption;
    const newGiftOptionsBlockConfig: IGiftOptionsBlock = {
      ...giftOptionsBlockConfig,
      giftOptions: newOptions
    };
    updateGiftOptionsConfig(newGiftOptionsBlockConfig);
    setOpenIndex(null);
  };

  const handleCustomImpactUpdate = () => {
    const newOptions = { ...giftOptionsBlockConfig };
    newOptions.customImpactStatement = customImpactStatement;
    updateGiftOptionsConfig(newOptions);
    setOpenIndex(null);
    setCustomOpen(false);
  };

  const showDeleteCustomConfirmation = () => {
    setDeletingCustom(true);
    setDeleteConfirmationModalOpen(true);
  };

  const showDeleteConfirmation = (index: number) => {
    setDeleteIndex(index);
    setDeleteConfirmationModalOpen(true);
  };

  const onCloseDeleteConfirmationModal = () => {
    setOpenIndex(null);
    setDeleteIndex(null);
    setDeletingCustom(false);
    setCustomOpen(false);
    setDeleteConfirmationModalOpen(false);
  };

  const handleDeleteImpact = (index: number) => {
    if (deletingCustom) {
      const newConfig = { ...giftOptionsBlockConfig };
      newConfig.customImpactStatement = null;
      updateGiftOptionsConfig(newConfig);
      setCustomImpactStatement({
        label: '',
        equationAmount: 0
      });
    } else {
      const newOptions = giftOptionsBlockConfig.giftOptions;
      newOptions[index].impactStatement = '';
      const newGiftOptionsBlockConfig: IGiftOptionsBlock = {
        ...giftOptionsBlockConfig,
        giftOptions: newOptions
      };
      updateGiftOptionsConfig(newGiftOptionsBlockConfig);
    }

    onCloseDeleteConfirmationModal();
  };

  return (
    <>
      <Text variant="caption">
        *The information below is already set to the required settings to
        collect donations.
      </Text>
      <div className="edit-impact-options-subheader">
        <Text variant="h5">Your Donations Options</Text>
      </div>
      <div className="edit-impact-options-container">
        {giftOptionsBlockConfig.giftOptions.map((option, idx) => (
          <EditImpact
            option={option}
            open={openIndex === idx}
            setOpenIndex={(index) => {
              setCustomOpen(false);
              setOpenIndex(index);
            }}
            onUpdate={handleImpactUpdate}
            onDelete={showDeleteConfirmation}
            index={idx}
            key={option.amount}
            primaryColor={configData.config?.theme?.primary}
            font={configData.config?.theme?.font?.formBody}
          />
        ))}
        {giftOptionsBlockConfig.enableCustomAmount && (
          <EditCustomImpact
            hasConfig={!!giftOptionsBlockConfig.customImpactStatement}
            customImpactStatement={customImpactStatement}
            setCustomImpactStatement={setCustomImpactStatement}
            open={customOpen}
            setOpen={(isOpen: boolean) => {
              setOpenIndex(null);
              setCustomOpen(isOpen);
            }}
            onUpdate={handleCustomImpactUpdate}
            onDelete={showDeleteCustomConfirmation}
            primaryColor={configData.config?.theme?.primary}
            font={configData.config?.theme?.font?.formBody}
          />
        )}
      </div>
      <DeleteConfirmationModal
        onConfirm={() => handleDeleteImpact(deleteIndex)}
        onClose={() => onCloseDeleteConfirmationModal()}
        open={deleteConfirmationModalOpen}
        name="Impact Statement"
      />
    </>
  );
};
