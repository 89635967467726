import { UseQueryOptions, useMutation } from 'react-query';
import { ImageDelete, ImageUploadPostBody } from 'services';
import { imageDelete, imageUpload } from 'services/imageService';

export const useImageUpload = (
  { organizationId, imageDataUrl, imagePrefix }: ImageUploadPostBody,
  queryOptions?: UseQueryOptions
) =>
  useMutation(
    ['imageUpload'],
    () => imageUpload({ organizationId, imageDataUrl, imagePrefix }),
    queryOptions
  );

export const useImageDelete = (
  { organizationId, prefix, imageId }: ImageDelete,
  queryOptions?: UseQueryOptions
) =>
  useMutation(
    ['imageDelete'],
    () => imageDelete({ organizationId, prefix, imageId }),
    queryOptions
  );
