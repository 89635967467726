import { ChangeEventHandler } from 'react';
import { Box, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form';
import * as yup from 'yup';
import Text from 'components/Text';
import { LimitedTextField, useLimitedLength } from 'components/TextField';
import { useConfigContext } from 'hooks/useConfigContext';
import { EmailConfig } from 'types';
import './EditEmailDetails.scss';

const subjectLineMaxChars = 50;

const subjectLineSchema = yup.object({
  subjectLine: yup
    .string()
    .max(
      subjectLineMaxChars,
      `Subject line cannot exceed ${subjectLineMaxChars} characters`
    )
    .typeError('Must enter valid subject line')
    .required('Subject line is required')
});

const EditEmailDetails = (): JSX.Element => {
  const { control } = useFormContext();
  const { configData, updateConfig } = useConfigContext<EmailConfig>();
  const [subjectLine, setSubjectLine] = useLimitedLength(
    subjectLineMaxChars,
    configData.config.subjectLine
  );

  const handleSubjectLineChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setSubjectLine(event.target.value);
  };

  const handleSubjectLineBlur: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = async (event) => {
    subjectLineSchema
      .validateAt('subjectLine', { subjectLine: event.target.value })
      .then((validValue) => {
        // AssertShape promise return is type of field being checked, but must be cast to unknown first
        const newSubjectLine = validValue as unknown as string;
        const newConfig = { ...configData.config };
        newConfig.subjectLine = newSubjectLine;
        updateConfig(newConfig);
      })
      .catch(() => undefined); // No need to handle, just preventing uncaught error
  };

  return (
    <Stack className="ElementLibrary-EditSubjectLine">
      <Box className="ElementLibrary-EditSubjectLine--title">
        <Text variant="h3">* Subject Line</Text>
        <Controller
          name="subjectLine"
          control={control}
          defaultValue={subjectLine ?? ''}
          render={({
            field: { ref, onChange, onBlur, ...field },
            fieldState: { error }
          }) => (
            <LimitedTextField
              {...field}
              placeholder="Enter Subject"
              hiddenLabel
              fullWidth
              multiline
              minRows={8}
              maxChar={subjectLineMaxChars}
              error={!!error}
              onChange={(event) => {
                onChange(event.target.value.substring(0, subjectLineMaxChars));
                handleSubjectLineChange(event);
              }}
              onBlur={(event) => {
                onBlur();
                handleSubjectLineBlur(event);
              }}
            />
          )}
        />
      </Box>
    </Stack>
  );
};

export default (): JSX.Element => {
  const methods = useForm({
    resolver: yupResolver(subjectLineSchema),
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <EditEmailDetails />
    </FormProvider>
  );
};
