import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';

interface StepIconProps {
  active: boolean;
  completed: boolean;
  icon: number;
}

export const StepIcon = ({ completed, active, icon }: StepIconProps) => {
  if (completed) {
    return (
      <div className="ab-step-icon-completed">
        <Icon icon={ICONS.CHECK} className="check-icon" />
      </div>
    );
  }

  const classPostfix = active ? 'active' : 'inactive';
  return (
    <div className={`ab-step-icon-${classPostfix}`}>
      <Text variant="h4">{icon}</Text>
    </div>
  );
};
