import { format } from 'date-fns';
import { Designation } from 'services';

export const mapDesignations = (designation: Designation) => ({
  id: designation.id,
  title: designation.title
});

export const formatDesignationDate = (date: string | undefined) => {
  if (!date) return null;
  return format(new Date(date), 'yyyy-MM-dd');
};
