import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import './DeleteHostedPageModal.scss';

type DeleteHostedPageModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const DeleteHostedPageModal = ({
  isOpen,
  onConfirm,
  onClose
}: DeleteHostedPageModalProps) => (
  <Dialog
    open={isOpen}
    label="Delete Modal"
    onClose={onClose}
    className="DeleteHostedPageModal"
  >
    <DialogTitle
      onClick={(close) => close()}
      className="DeleteHostedPageModal-Title"
    >
      Changes will not be saved
    </DialogTitle>
    <DialogContent>
      <DialogContentTitle>Are you sure?</DialogContentTitle>
      <DialogContentDescription>
        If you remove the Hosted Page, any changes you’ve made on this page will
        not be saved and it will be removed from your form.
      </DialogContentDescription>
    </DialogContent>
    <DialogActions className="DeleteHostedPageModal-ActionContainer">
      <DialogActionButton
        onClick={(close) => {
          onConfirm();
          close();
        }}
        variant="primary"
      >
        Yes, Remove Hosted Page
      </DialogActionButton>
      <DialogActionButton onClick={(close) => close()} variant="secondary">
        No, Return to Editor
      </DialogActionButton>
    </DialogActions>
  </Dialog>
);
