import createAuth0Client from '@auth0/auth0-spa-js';
import { resolvedEnvironment } from './environment';

const configureAuth0Client = async () => {
  try {
    return createAuth0Client({
      // use current location as default, but allow envConfig to override.
      redirect_uri: window.location.origin,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      ...resolvedEnvironment.auth0
    });
  } catch (e) {
    throw new Error(`There was an error: ${JSON.stringify(e)}`);
  }
};

export default configureAuth0Client();
