import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DateRangeValues } from 'utils';
import './DateRangeSelector.scss';

export interface DateRangeSelectorProps {
  onChange: (
    _event: React.MouseEvent<HTMLElement>,
    timeSelection: number
  ) => void;
  selectedRangeValue: number;
}

export const DateRangeSelector = ({
  onChange,
  selectedRangeValue
}: DateRangeSelectorProps): JSX.Element => (
  <ToggleButtonGroup
    className="date-range-selector"
    color="primary"
    size="small"
    exclusive
    onChange={onChange}
    value={selectedRangeValue}
  >
    <ToggleButton value={parseInt(DateRangeValues.OneWeek, 10)}>
      1W
    </ToggleButton>
    <ToggleButton value={parseInt(DateRangeValues.OneMonth, 10)}>
      1M
    </ToggleButton>
    <ToggleButton value={parseInt(DateRangeValues.ThreeMonths, 10)}>
      3M
    </ToggleButton>
    <ToggleButton value={parseInt(DateRangeValues.OneYear, 10)}>
      1Y
    </ToggleButton>
    <ToggleButton value={parseInt(DateRangeValues.YearToDate, 10)}>
      YTD
    </ToggleButton>
    <ToggleButton value={parseInt(DateRangeValues.All, 10)}>ALL</ToggleButton>
  </ToggleButtonGroup>
);
