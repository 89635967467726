import { Box, Stack } from '@mui/material';
import { TablerIcon } from '@tabler/icons';
import Button, { ButtonProps } from 'components/Button';
import Chip from 'components/Chip';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import './EmailPreviewCard.scss';

type PreviewButtonProps = ButtonProps & {
  label: string;
  icon: TablerIcon;
};

type EmailPreviewCardProps = {
  name: string;
  type: string;
  isSelected: boolean;
  isDefault?: boolean;
  previewButtons?: PreviewButtonProps[];
};

/**
 * A card used to display an email template's preview
 */
export const EmailPreviewCard = ({
  name,
  type,
  isSelected,
  isDefault,
  previewButtons
}: EmailPreviewCardProps) => (
  <Box className="email-card-box">
    <Stack
      direction="column"
      justifyContent="center"
      className="overview-hover"
    >
      {previewButtons.map((p: PreviewButtonProps) => (
        <Button
          key={`preview_button_${p.label}`}
          onClick={(e) => p.onClick(e)}
          startIcon={<Icon icon={p.icon} />}
          variant={p.variant || 'secondary'}
          disabled={p.disabled}
        >
          {p.label}
        </Button>
      ))}
    </Stack>

    {isSelected && (
      <Box display="flex" justifyContent="end" className="selected-overlay">
        <Chip icon={<Icon icon={ICONS.CHECK} />} label="Selected" />
      </Box>
    )}

    {isDefault && (
      <Box display="flex" justifyContent="end" className="default-overlay">
        <Chip icon={<Icon icon={ICONS.CHECK} />} label="Default" />
      </Box>
    )}

    <Stack direction="column" justifyContent="center" spacing={2}>
      <Box
        display="flex"
        paddingLeft={1.5}
        justifyContent="space-between"
        alignItems="center"
        className="email-preview-header"
      >
        <Text className="header-label" variant="h2">
          {type}
        </Text>
        <Icon icon={ICONS.RECEIPT} className="header-icon" />
      </Box>
      <Box padding={1.5} className="email-preview-content">
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Text variant="h3">{name}</Text>
        </Stack>
      </Box>
    </Stack>
  </Box>
);
