import { useRef, useState } from 'react';
import { TextFieldProps } from '@mui/material';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { TextVariants } from './Text';

export interface EditableTextProps {
  value: string;
  variant?: TextVariants;
  textFieldProps?: TextFieldProps;
}

const EditableText = ({
  value,
  variant = 'h1',
  textFieldProps
}: EditableTextProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const error = textFieldProps?.error;

  const combinedTextFieldProps: TextFieldProps = {
    value,
    autoFocus: true,
    autoComplete: 'off',
    ...textFieldProps,
    onBlur: (
      e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
    ) => {
      if (textFieldProps?.onBlur) {
        textFieldProps.onBlur(e);
      }
      if (!error) {
        setIsEditing(false);
      }
    },
    sx: {
      '& .MuiInputBase-root .MuiInputBase-input': {
        typography: variant,
        padding: '0 1rem 0 1rem'
      },
      ...textFieldProps?.sx
    }
  };

  return (
    <div ref={wrapperRef}>
      {isEditing || error ? (
        <TextField {...combinedTextFieldProps} />
      ) : (
        <Text
          variant={variant}
          onClick={() => setIsEditing(true)}
          sx={{ px: 1 }}
        >
          {value}
        </Text>
      )}
    </div>
  );
};

export default EditableText;
