import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { ActiveMenu } from 'components/EditorMenus';
import { EditsSaved } from 'components/EditsSaved';
import { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import DeleteBlockModal, {
  DeleteModalConfig
} from 'components/gms/DeleteBlockModal/DeleteBlockModal';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { useEditorIframeContext } from 'hooks/useEditorIframeContext';
import { useEventHubPreview } from 'hooks/useEventHubPreview';
import {
  useDeleteBannerImages,
  useGivingFormByInstanceId
} from 'queries/UseGivingForms';
import { useUser } from 'queries/UseUsers';
import {
  EditorMenus,
  EditorTypes,
  ElementLibraryHostedPageViews,
  IGivingFormConfig
} from 'types';
import {
  emitDeselect,
  emitSelect,
  emitSetPreviewGivingForm,
  emitZoomLevel,
  getLibraryViewFromBlockId
} from 'utils';
import { deleteHostedPageBlockFromConfigById } from 'utils/hostedPageBlockUtils';
import { DeleteHostedPageModal } from '../DeleteHostedPageModal';
import './HostedPageEditor.scss';
import { ZoomEmitValues } from './types';

type HostedPageEditorProps = {
  deleteModalConfig: DeleteModalConfig;
  setDeleteModalConfig: Dispatch<SetStateAction<DeleteModalConfig>>;
  isAbTestWizard?: boolean;
};

export const HostedPageEditor = ({
  deleteModalConfig,
  setDeleteModalConfig,
  isAbTestWizard
}: HostedPageEditorProps): JSX.Element => {
  const { givingFormId, givingFormTemplateId } = useParams();
  const formId = givingFormId || givingFormTemplateId;
  const { refetch: getGivingForm } = useGivingFormByInstanceId(formId);
  const {
    configData,
    updateConfig,
    handleSetForm,
    discardEdits,
    highlightedBlock,
    setHighlightedBlock,
    isPublished
  } = useConfigContext<IGivingFormConfig>();
  const {
    hostedPageBlockOrderToUpdate,
    embedReadyToDisplay,
    eventHub,
    iframeHeight,
    iframeReadyCallback
  } = useEditorIframeContext();
  const { config } = configData;
  const [activeMenu, setActiveMenu] = useState<EditorMenus>(
    EditorMenus.HostedPageElementLibrary
  );
  const [zoomSelection, setZoomSelection] = useState<string>('width');
  const [libraryView, setLibraryView] = useState<ElementLibraryHostedPageViews>(
    ElementLibraryHostedPageViews.RootView
  );
  const [isDeleteHostedPageModalOpen, setIsDeleteHostedPageModalOpen] =
    useState<boolean>(false);
  const { envConfig } = useAppContext();

  const { selectedOrganization } = useAppContext();
  const { data: user } = useUser();
  const { mutateAsync: deleteImages } = useDeleteBannerImages({
    givingFormId: formId,
    organizationId: selectedOrganization.id,
    fileName: 'hp-banner-image',
    userId: user.id
  });

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeSrc = `${envConfig?.appsBaseUrl}/hosted-page/edit-${formId}`;

  useEventHubPreview({ iframeRef, configData, iframeReadyCallback });

  useEffect(() => {
    setLibraryView(ElementLibraryHostedPageViews.RootView);
  }, []);
  useEffect(() => {
    if (embedReadyToDisplay) {
      emitSetPreviewGivingForm(eventHub);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embedReadyToDisplay]);

  useEffect(() => {
    if (!hostedPageBlockOrderToUpdate) {
      return;
    }
    const newConfig = configData.config;
    const { topBlocks, sideBlocks, bottomBlocks } = newConfig.hostedPageConfig;
    const blockSets = {
      top: topBlocks,
      side: sideBlocks,
      bottom: bottomBlocks
    };
    const { from, target } = hostedPageBlockOrderToUpdate;

    const [itemToMove] = blockSets[from.section].splice(from.index, 1);
    blockSets[target.section].splice(target.index, 0, itemToMove);

    updateConfig(newConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostedPageBlockOrderToUpdate]);

  useEffect(() => {
    if (activeMenu !== EditorMenus.HostedPageElementLibrary) {
      emitDeselect(eventHub);
      setHighlightedBlock('');
      setLibraryView(ElementLibraryHostedPageViews.RootView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenu]);

  useEffect(() => {
    emitSelect(highlightedBlock, eventHub);
    if (highlightedBlock === 'header') {
      setLibraryView(ElementLibraryHostedPageViews.HeaderView);
    } else if (highlightedBlock === 'footer') {
      setLibraryView(ElementLibraryHostedPageViews.FooterView);
    } else {
      const allBlocks = [
        ...(configData?.config?.hostedPageConfig?.topBlocks || []),
        ...(configData?.config?.hostedPageConfig?.sideBlocks || []),
        ...(configData?.config?.hostedPageConfig?.bottomBlocks || [])
      ];
      if (allBlocks.find((block) => block.id === highlightedBlock)) {
        const newLibraryView = getLibraryViewFromBlockId(
          highlightedBlock,
          EditorTypes.HostedPage,
          configData
        );
        const hostedPageLibraryView =
          newLibraryView as ElementLibraryHostedPageViews;
        setActiveMenu(EditorMenus.HostedPageElementLibrary);
        setLibraryView(hostedPageLibraryView);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedBlock]);

  const getCurrentBackendGivingForm = async () => {
    const { data } = await getGivingForm();
    return data;
  };

  // function to be passed through EditsSaved to RevertChangesModal
  const onDiscard = async () => {
    const currentBackendGivingForm = await getCurrentBackendGivingForm();
    setActiveMenu(EditorMenus.HostedPageElementLibrary);
    setLibraryView(ElementLibraryHostedPageViews.RootView);
    emitSetPreviewGivingForm(eventHub);
    deleteImages();
    discardEdits();
    handleSetForm({
      ...currentBackendGivingForm,
      seedConfigPublishedAt: currentBackendGivingForm.updatedAt
    });
  };

  const revertChangesModalCopy = (
    <>
      Any changes you made to the giving form will not be saved, and it will be
      reverted to the most recent published version.
      <br />
      <br />
      If the giving form has never been published, it will revert to the
      template.
    </>
  );

  const closeModal = () => setDeleteModalConfig({ open: false });

  const handleDelete = () => {
    if (eventHub) {
      const newConfig = { ...(config as IGivingFormConfig) };
      const { id } = deleteModalConfig;

      deleteHostedPageBlockFromConfigById(id, newConfig);

      updateConfig(newConfig);
      setLibraryView(ElementLibraryHostedPageViews.RootView);
      closeModal();
    }
  };

  const handleDeleteHostedPage = () => {
    if (eventHub) {
      const newConfig = { ...(config as IGivingFormConfig) };
      delete newConfig?.hostedPageConfig;
      updateConfig(newConfig);
      setIsDeleteHostedPageModalOpen(false);
    }
  };

  const elementLibraryReturnAction =
    libraryView === ElementLibraryHostedPageViews.RootView
      ? undefined
      : () => {
          setHighlightedBlock('');
          setLibraryView(ElementLibraryHostedPageViews.RootView);
        };

  return (
    <div className="hosted-page-container">
      <div
        className={clsx('hosted-page-editor fluid-container', {
          'ab-test-preview': isAbTestWizard
        })}
      >
        <div className="menu-selection-stack">
          <IconButton
            icon={ICONS.PLUS}
            variant={
              activeMenu === EditorMenus.HostedPageElementLibrary
                ? 'primary'
                : 'secondary'
            }
            onClick={() => setActiveMenu(EditorMenus.HostedPageElementLibrary)}
            label="Add Element"
            tooltipLabel="Add Element"
          />
          <IconButton
            icon={ICONS.CODE}
            variant={
              activeMenu === EditorMenus.HostedPageCustomCss
                ? 'primary'
                : 'secondary'
            }
            onClick={() => setActiveMenu(EditorMenus.HostedPageCustomCss)}
            label="Custom CSS"
            tooltipLabel="Custom CSS"
          />
          <IconButton
            icon={ICONS.TRASH}
            variant="secondary"
            onClick={() => setIsDeleteHostedPageModalOpen(true)}
            label="Remove Page"
            tooltipLabel="Remove Page"
          />
          {/* leaving as reference for when L&F menu is created */}
          {/* <IconButton
            icon={ICONS.PALETTE}
            variant={
              activeMenu === EditorMenus.GivingFormLookAndFeel
                ? 'primary'
                : 'secondary'
            }
            onClick={() => setActiveMenu(EditorMenus.GivingFormLookAndFeel)}
            label="Look and Feel"
            tooltipLabel="Look & Feel"
           /> */}
          {configData.editsSavedTime && (
            <EditsSaved
              configData={configData}
              onDiscard={onDiscard}
              modalCopy={revertChangesModalCopy}
              editorType={EditorTypes.GivingForm}
              isPublished={isPublished}
            />
          )}
        </div>
        {isAbTestWizard && (
          <div className="ab-test-iframe-label-container">
            <div className="ab-test-iframe-label">
              <Text variant="h6">Page B</Text>
            </div>
          </div>
        )}
        <iframe
          name="hosted-page-iframe"
          title="Hosted Page Preview"
          src={iframeSrc}
          frameBorder="0"
          sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation allow-modals allow-downloads allow-popups"
          width="100%"
          style={{
            visibility:
              !iframeHeight || !embedReadyToDisplay ? 'hidden' : 'visible'
          }}
          ref={iframeRef}
          className={clsx({
            'ab-test-preview': isAbTestWizard
          })}
        />
        <ActiveMenu
          activeMenu={activeMenu}
          elementLibraryReturnAction={elementLibraryReturnAction}
          libraryView={libraryView}
          setLibraryView={setLibraryView}
        />
        <div className="zoom-controls">
          <IconButton
            onClick={() => {
              emitZoomLevel(ZoomEmitValues.FitToHeight, eventHub);
              setZoomSelection('height');
            }}
            icon={ICONS.VIEWPORT_WIDE}
            label="Reset Height"
            tooltipLabel="Reset Height"
            variant="tertiary"
            className={clsx('rotate-icon', {
              selected: zoomSelection === 'height'
            })}
          />
          <IconButton
            onClick={() => {
              emitZoomLevel(ZoomEmitValues.Decrease, eventHub);
              setZoomSelection('');
            }}
            icon={ICONS.MINUS}
            label="Zoom Out"
            tooltipLabel="Zoom Out"
            variant="tertiary"
          />
          <IconButton
            onClick={() => {
              emitZoomLevel(ZoomEmitValues.Increase, eventHub);
              setZoomSelection('');
            }}
            icon={ICONS.PLUS}
            variant="tertiary"
            label="Zoom In"
            tooltipLabel="Zoom In"
          />
          <IconButton
            onClick={() => {
              emitZoomLevel(ZoomEmitValues.FitToWidth, eventHub);
              setZoomSelection('width');
            }}
            icon={ICONS.VIEWPORT_WIDE}
            label="Reset Width"
            tooltipLabel="Reset Width"
            variant="tertiary"
            className={clsx({ selected: zoomSelection === 'width' })}
          />
        </div>
      </div>
      <DeleteBlockModal
        onConfirm={handleDelete}
        onClose={closeModal}
        open={deleteModalConfig.open}
      />
      <DeleteHostedPageModal
        onConfirm={handleDeleteHostedPage}
        onClose={() => setIsDeleteHostedPageModalOpen(false)}
        isOpen={isDeleteHostedPageModalOpen}
      />
    </div>
  );
};
