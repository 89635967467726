import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import Text from 'components/Text';
import WYSIWYG, { Tag, TagSection } from 'components/WYSIWYG';
import { useOrgCustomFields } from 'hooks';
import { useConfigContext } from 'hooks/useConfigContext';
import { EmailConfig } from 'types';
import { createUnfilteredTagList } from 'utils/placeholderUtils';
import './EditHeader.scss';

const EditHeader = (): JSX.Element => {
  const { configData, updateConfig } = useConfigContext<EmailConfig>();
  const [html, setHtml] = useState<string>(configData.config.header.html);
  const orgCustomFields = useOrgCustomFields();

  const handleUpdate = (newHtml: string) => {
    const newConfig = { ...configData.config };
    newConfig.header.html = newHtml;

    updateConfig(newConfig);
  };

  return (
    <Stack id="ElementLibrary-EditEmailHeader">
      <Box id="ElementLibrary-EditEmailHeader--title">
        <Text variant="h3">Title</Text>
        <Box className="EditCustomContent-WYSIWYG-container">
          <WYSIWYG
            defaultStyles={{
              fontFamily: configData.config.theme.font,
              color: configData.config.theme.text
            }}
            onChange={(newHtml: string) => setHtml(newHtml)}
            tagList={
              createUnfilteredTagList(orgCustomFields, true) as TagSection[]
            }
            tags={createUnfilteredTagList(orgCustomFields) as Tag[]}
            onBlur={handleUpdate}
            value={html}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default EditHeader;
