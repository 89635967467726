import { ChangeEventHandler } from 'react';
import { Box, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form';
import * as yup from 'yup';
import Text from 'components/Text';
import { LimitedTextField, useLimitedLength } from 'components/TextField';
import { useConfigContext } from 'hooks/useConfigContext';
import { IGivingFormConfig } from 'types';
import './EditHeader.scss';

const titleMaxChars = 50;
const subtitleMaxChars = 200;

const headerSchema = yup.object({
  title: yup
    .string()
    .max(titleMaxChars, `Title cannot exceed ${titleMaxChars} characters`)
    .typeError('Must enter valid title')
    .required('Title is required'),
  subtitle: yup
    .string()
    .max(
      subtitleMaxChars,
      `Description cannot exceed ${subtitleMaxChars} characters`
    )
    .typeError('Must enter valid title')
});

const EditHeader = (): JSX.Element => {
  const { control } = useFormContext();
  const { configData, updateConfig } = useConfigContext<IGivingFormConfig>();
  const [title, setTitle] = useLimitedLength(
    titleMaxChars,
    configData.config.header?.title
  );
  const [subtitle, setSubtitle] = useLimitedLength(
    subtitleMaxChars,
    configData.config.header?.subtitle
  );

  const handleTitleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setTitle(event.target.value);
  };

  const handleTitleBlur: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = async (event) => {
    headerSchema
      .validateAt('title', { title: event.target.value })
      .then((validValue) => {
        // AssertShape promise return is type of field being checked, but must be cast to unknown first
        const newTitle = validValue as unknown as string;
        const newConfig = { ...configData.config };
        if (newConfig.header) {
          newConfig.header.title = newTitle;
        }
        updateConfig(newConfig);
      })
      .catch(() => undefined); // No need to handle, just preventing uncaught error
  };

  const handleSubtitleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setSubtitle(event.target.value);
  };

  const handleSubtitleBlur: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = async (event) => {
    headerSchema
      .validateAt('subtitle', { subtitle: event.target.value })
      .then((validValue) => {
        // AssertShape promise return is type of field being checked, but must be cast to unknown first
        const newSubtitle = validValue as unknown as string;
        const newConfig = { ...configData.config };
        if (newConfig.header) {
          newConfig.header.subtitle = newSubtitle;
        }
        updateConfig(newConfig);
      })
      .catch(() => undefined); // No need to handle, just preventing uncaught error
  };

  return (
    <Stack id="ElementLibrary-EditHeader">
      <Box id="ElementLibrary-EditHeader--title">
        <Text variant="h3">Title</Text>
        <Controller
          name="title"
          control={control}
          defaultValue={title ?? ''}
          render={({
            field: { ref, onChange, onBlur, ...field },
            fieldState: { error }
          }) => (
            <LimitedTextField
              {...field}
              hiddenLabel
              fullWidth
              multiline
              minRows={3}
              maxChar={titleMaxChars}
              error={!!error}
              onChange={(event) => {
                onChange(event.target.value.substring(0, titleMaxChars));
                handleTitleChange(event);
              }}
              onBlur={(event) => {
                onBlur();
                handleTitleBlur(event);
              }}
            />
          )}
        />
      </Box>
      <Box id="ElementLibrary-EditHeader--description">
        <Text variant="h3">Description</Text>
        <Controller
          name="subtitle"
          control={control}
          defaultValue={subtitle ?? ''}
          render={({
            field: { ref, onChange, onBlur, ...field },
            fieldState: { error }
          }) => (
            <LimitedTextField
              {...field}
              hiddenLabel
              fullWidth
              multiline
              minRows={3}
              maxChar={subtitleMaxChars}
              error={!!error}
              onChange={(event) => {
                onChange(event.target.value.substring(0, subtitleMaxChars));
                handleSubtitleChange(event);
              }}
              onBlur={(event) => {
                onBlur();
                handleSubtitleBlur(event);
              }}
            />
          )}
        />
      </Box>
    </Stack>
  );
};

export default (): JSX.Element => {
  const methods = useForm({
    resolver: yupResolver(headerSchema),
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <EditHeader />
    </FormProvider>
  );
};
