import React, { forwardRef } from 'react';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  SelectChangeEvent
} from '@mui/material';
import { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { SelectCheckboxMenuItem } from '.';
import './MultiselectCheckbox.scss';

export interface MultiselectOption {
  label: string;
  value: string;
}

export interface MultiselectProps
  extends Omit<
    MuiSelectProps<string[]>,
    'label' | 'defaultValue' | 'disabled'
  > {
  label?: string;
  options: MultiselectOption[];
  sortSelected?: boolean;
  defaultValue?: string[];
  disabled?: boolean;
}

const Multiselect = forwardRef(
  (
    {
      options,
      label,
      defaultValue,
      disabled = false,
      sortSelected = false,
      ...props
    }: MultiselectProps,
    ref
  ): JSX.Element => {
    const [values, setValues] = React.useState<string[]>(defaultValue || []);

    const handleChange = (
      event: SelectChangeEvent<string[]>,
      child: React.ReactNode
    ) => {
      props?.onChange?.(event, child);
      const val =
        typeof event.target.value === 'string'
          ? [event.target.value]
          : event.target.value;
      setValues(val);
    };

    const selectId = `${label}-select-id`;

    const selectProps = {
      ...props,
      sx: props.sx,
      defaultValue,
      labelId: `${label}-select-label-id`,
      id: selectId,
      label,
      value: values,
      disabled,
      onChange: handleChange,
      multiple: true,
      renderValue: (selected: string[]) => (
        <Text variant="body">({selected.length}) Selected</Text>
      )
    };

    let menuItems = options.map((item) => (
      <SelectCheckboxMenuItem
        {...item}
        key={item.value}
        checked={!!values.find((value) => item.value === value)}
      />
    ));
    // Combine into single array, or else MUI ripple effect won't function properly
    if (sortSelected) {
      const selectedOptions = values.map((value) => {
        const selectedOption = options.find((option) => option.value === value);
        return selectedOption;
      });
      const selectedMenuItems = selectedOptions.map((option) => (
        <SelectCheckboxMenuItem {...option} checked key={option.value} />
      ));
      const unselectedMenuItems = options
        .filter((option) => !selectedOptions.includes(option))
        .map((item) => <SelectCheckboxMenuItem {...item} key={item.value} />);

      menuItems = [...selectedMenuItems, ...unselectedMenuItems];
    }

    return (
      <FormControl fullWidth className="MultiselectCheckbox">
        {!!label && (
          <InputLabel
            disabled={disabled}
            id={`${label}-select-label-id`}
            htmlFor={selectId}
          >
            {label}
          </InputLabel>
        )}
        <MuiSelect
          {...selectProps}
          IconComponent={ICONS.CHEVRON_DOWN}
          inputRef={ref}
        >
          {menuItems}
        </MuiSelect>
      </FormControl>
    );
  }
);

export default Multiselect;
