import { UseQueryOptions, useMutation, useQuery } from 'react-query';
import {
  createDesignationGroupService,
  createDesignationService,
  deleteDesignationGroupByIdService,
  getDesignationGroupsService,
  getDesignationsService,
  toggleDesignationStatusService,
  updateDesignationService
} from 'services/designations';
import {
  Designation,
  DesignationGroup,
  IGetDesignations
} from 'services/types/designationTypes';

export const useDesignations = ({
  organizationId,
  campaignId,
  isActiveFilter,
  refetchOnWindowFocus = 'always'
}: IGetDesignations) =>
  useQuery(
    ['getDesignations', { organizationId, campaignId, isActiveFilter }],
    () =>
      getDesignationsService({ organizationId, campaignId, isActiveFilter }),
    { refetchOnWindowFocus }
  );

export const useCreateDesignationMutation = (
  organizationId: string,
  queryOptions?: UseQueryOptions<Designation>
) =>
  useMutation(
    ['createDesignation', { organizationId }],
    (designationFormData: Designation) =>
      createDesignationService({
        organizationId,
        designationFormData
      }),
    queryOptions
  );

export const useUpdateDesignationMutation = (
  organizationId: string,
  designationId: string,
  queryOptions?: UseQueryOptions<Designation>
) =>
  useMutation(
    (designationFormData: Designation) =>
      updateDesignationService({
        organizationId,
        designationId,
        designationFormData
      }),
    queryOptions
  );

export const useDesignationGroups = (
  organizationId: string,
  queryOptions?: UseQueryOptions<DesignationGroup[]>
) =>
  useQuery<DesignationGroup[]>(
    ['getDesignationGroups', { organizationId }],
    () => getDesignationGroupsService(organizationId),
    queryOptions
  );

export const useCreateDesignationGroupMutation = (
  organizationId: string,
  queryOptions?: UseQueryOptions<DesignationGroup>
) =>
  useMutation(
    ['createDesignationGroup', { organizationId }],
    (groupName: string) =>
      createDesignationGroupService({
        organizationId,
        groupName
      }),
    queryOptions
  );

export const useDeleteDesignationGroupByIdMutation = (
  organizationId: string,
  queryOptions?: UseQueryOptions<void>
) =>
  useMutation(
    ['deleteDesignationGroupById', { organizationId }],
    (designationGroupId: string) =>
      deleteDesignationGroupByIdService({
        organizationId,
        designationGroupId
      }),
    queryOptions
  );

export const useToggleDesignationStatusMutation = (
  organizationId: string,
  designationId: string,
  queryOptions?: UseQueryOptions<void, Error>
) =>
  useMutation(
    ['toggleDesginationStatus', { organizationId, designationId }],
    (isActive: boolean) =>
      toggleDesignationStatusService({
        organizationId,
        designationId,
        isActive
      }),
    queryOptions
  );
