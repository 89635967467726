import { useEffect, useState } from 'react';
import { Autocomplete, Box, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Blocker } from 'components/Blocker';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { useUpdateGivingFormReferenceCode } from 'queries/UseGivingForms';
import { useOrganizationReferenceCodes } from 'queries/UseOrganizationData';
import { IGivingFormConfig } from 'types';
import './ReferenceCodeDialog.scss';

interface ReferenceCodeDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ReferenceCodeDialog = ({
  open,
  onClose
}: ReferenceCodeDialogProps) => {
  const { givingFormId } = useParams();
  const { selectedOrganization } = useAppContext();
  const [pushAlert] = useAlerts();
  const { configData, updateConfig } = useConfigContext<IGivingFormConfig>();
  const organizationId = selectedOrganization?.id;

  const [referenceCode, setReferenceCode] = useState<string>(
    configData?.config?.referenceCode
  );

  useEffect(() => {
    setReferenceCode(configData.config.referenceCode);
  }, [configData.config.referenceCode]);

  const {
    data: referenceCodes,
    isLoading: isLoadingReferenceCodes,
    refetch: refetchReferenceCodes
  } = useOrganizationReferenceCodes(organizationId);

  useEffect(() => {
    if (open) {
      refetchReferenceCodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const {
    mutateAsync: updateGivingFormReferenceCode,
    isLoading: isUpdatingGivingFormReferenceCode
  } = useUpdateGivingFormReferenceCode({
    givingFormId,
    organizationId,
    referenceCode
  });

  const onUpdateReferenceCodeClick = async () => {
    await updateGivingFormReferenceCode();
    const updatedConfig = configData.config;
    updatedConfig.referenceCode = referenceCode;
    updateConfig(updatedConfig);
    pushAlert({
      title: 'Reference Code Updated'
    });
    onClose();
  };

  return (
    <Dialog
      className="reference-code-dialog"
      label="reference code dialog"
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle
        className="reference-code-dialog-title"
        onClick={() => onClose()}
      >
        Reference Code
      </DialogTitle>
      <DialogContent>
        <Blocker block={isLoadingReferenceCodes}>
          <Stack direction="row">
            <Box className="left-form">
              <DialogContentTitle>Reference Code</DialogContentTitle>
              <Text variant="body">
                Add a reference code to your giving form so that you can quickly
                and easily track your transactions across your organization.
              </Text>
              <Text className="reference-code-dialog-label" variant="body">
                Create New or Select Existing Reference Code
              </Text>
              <Autocomplete
                freeSolo
                autoSelect
                className="reference-code-autocomplete"
                options={referenceCodes?.referenceCodes || []}
                value={referenceCode}
                onInputChange={(_e, value) => setReferenceCode(value)}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="reference-code-input"
                    hiddenLabel
                    placeholder="Enter Reference Code"
                  />
                )}
              />
            </Box>
          </Stack>
        </Blocker>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          variant="primary"
          onClick={onUpdateReferenceCodeClick}
          disabled={
            isUpdatingGivingFormReferenceCode || isLoadingReferenceCodes
          }
        >
          Update Reference code
        </DialogActionButton>
        <DialogActionButton
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
