import { ElementLibraryEmailViews } from 'types/ElementLibraryViews';
import { IDataView } from '../ElementLibrary';
import EditCustomContent from './EditCustomContent';
import EditEmailDetails from './EditEmailDetails';
import EditHeader from './EditHeader';
import RootViewContent from './RootMenu';

/**
 * Donation Details and Donation Summary no longer needed from DEV-4573.
 * But we can't remove them in this email view map because of some other ts definitions.
 */
export const ElementLibraryEmailViewData: {
  [key in ElementLibraryEmailViews]: IDataView<ElementLibraryEmailViews>;
} = {
  [ElementLibraryEmailViews.RootView]: {
    title: 'Element Library',
    component: RootViewContent
  },
  [ElementLibraryEmailViews.Header]: {
    title: 'Header',
    subtitle: 'Customize the header on the receipt your donors recieve.',
    component: EditHeader
  },
  [ElementLibraryEmailViews.CustomContent]: {
    title: 'Custom Content',
    subtitle: 'Share custom content with your donors.',
    component: EditCustomContent
  },
  [ElementLibraryEmailViews.EmailDetails]: {
    title: 'Email Subject',
    subtitle: 'Fill out the subject line for your donor’s receipt email.',
    component: EditEmailDetails
  }
};
