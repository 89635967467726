import axios from 'axios';
import { resolvedEnvironment } from './environment';

const createAxiosInstance = () => {
  const instance = axios.create();

  // dynamic baseUrl from envConfig:
  instance.interceptors.request.use(async (config) => ({
    ...config,
    url: `${resolvedEnvironment.baseUrl}${resolvedEnvironment.apiPrefix}${config.url}`
  }));

  // handle global Bearer token:
  instance.interceptors.request.use(
    async (config) => {
      const token = await window.sessionStorage.getItem('accessToken');
      if (config.headers) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        return config;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance();
