import { FunctionComponent } from 'react';
import {
  Box,
  ButtonUnstyled,
  ButtonUnstyledProps,
  Icon as MuiIcon,
  Stack
} from '@mui/material';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import {
  BlockTypes,
  ElementLibraryGivingFormViews,
  GivingFormBlockType,
  IGivingFormConfig
} from 'types';
import './RootMenu.scss';

const ThankYouRootViewMenuItem = ({
  children,
  icon,
  ...props
}: ButtonUnstyledProps & { icon: FunctionComponent }) => (
  <Box className="ElementLibrary-RootMenu--item">
    <ButtonUnstyled {...props}>
      <Stack
        className="ElementLibrary-RootMenu--item-container"
        direction="row"
      >
        <MuiIcon component={icon} />
        <Text variant="h4">{children}</Text>
        <Icon icon={ICONS.CHEVRON_RIGHT} />
      </Stack>
    </ButtonUnstyled>
  </Box>
);
const ThankYouRootViewContent = ({
  setView
}: {
  setView: (view: ElementLibraryGivingFormViews) => void;
}): JSX.Element => {
  const {
    configData: {
      config: { blocks }
    },
    setHighlightedBlock
  } = useConfigContext<IGivingFormConfig>();

  const { organizationInfo } = useAppContext();
  const hasCorporateMatching = organizationInfo?.features?.corporateMatching;

  const getBlockId = (blockType: BlockTypes) =>
    blocks.find((block: GivingFormBlockType) => block.blockType === blockType)
      ?.id || '';

  return (
    <Stack id="ElementLibrary-RootMenu">
      <Box>
        <Text variant="h3">Commonly Used</Text>
        <ThankYouRootViewMenuItem
          onClick={() => {
            setHighlightedBlock('thankYouHeader');
            setView(ElementLibraryGivingFormViews.ThankYouHeaderView);
          }}
          icon={ICONS.LAYOUT_NAVBAR}
        >
          Header
        </ThankYouRootViewMenuItem>
        <ThankYouRootViewMenuItem
          onClick={() => {
            setHighlightedBlock('ThankYouMessageBodyBlock');
            setView(ElementLibraryGivingFormViews.ThankYouMessageBodyView);
          }}
          icon={ICONS.FILE_TEXT}
        >
          Message Body
        </ThankYouRootViewMenuItem>
        <ThankYouRootViewMenuItem
          onClick={() => {
            setHighlightedBlock(
              getBlockId(BlockTypes.ThankYouAccountInvitationBlock)
            );
            setView(
              ElementLibraryGivingFormViews.ThankYouAccountInvitationView
            );
          }}
          icon={ICONS.USER_PLUS}
        >
          Account Invitation
        </ThankYouRootViewMenuItem>
        {hasCorporateMatching && (
          <ThankYouRootViewMenuItem
            onClick={() => {
              setHighlightedBlock(
                getBlockId(BlockTypes.ThankYouCorporateMatchingBlock)
              );
              setView(
                ElementLibraryGivingFormViews.ThankYouCorporateMatchingView
              );
            }}
            icon={ICONS.SKYSCRAPER}
          >
            Corporate Matching
          </ThankYouRootViewMenuItem>
        )}
      </Box>
      {/* <Box>
        <Text variant="h3">More Options</Text>
      </Box> */}
    </Stack>
  );
};

export default ThankYouRootViewContent;
