import { useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import Text from 'components/Text';
import WYSIWYG from 'components/WYSIWYG';
import { useConfigContext } from 'hooks/useConfigContext';
import {
  HostedPageBlockTypes,
  IGivingFormConfig,
  IHostedPageFooterBlock
} from 'types';
import './EditHostedPageFooter.scss';

export const EditHostedPageFooter = (): JSX.Element => {
  const { configData, updateConfig } = useConfigContext<IGivingFormConfig>();
  const [html, setHtml] = useState<string>(
    configData.config.hostedPageConfig?.footer?.html
  );
  const [isFooterEnabled, setIsFooterEnabled] = useState(
    configData.config.hostedPageConfig?.footer?.isEnabled
  );

  const handleUpdate = (newHtml: string) => {
    const newConfig = { ...configData.config };
    newConfig.hostedPageConfig.footer.html = newHtml;
    updateConfig(newConfig);
  };

  const handleEnableFooterToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsFooterEnabled(event.target.checked);
    const newConfig = { ...configData.config };
    if (newConfig.hostedPageConfig.footer) {
      newConfig.hostedPageConfig.footer.isEnabled = event.target.checked;
    } else {
      // Add the footer block if it doesn't exist
      const newFooterBlock: IHostedPageFooterBlock = {
        blockType: HostedPageBlockTypes.HostedPageFooterBlock,
        id: 'footer',
        html: html || '<div></div>',
        isEnabled: event.target.checked
      };
      newConfig.hostedPageConfig.footer = newFooterBlock;
    }
    updateConfig(newConfig);
  };

  return (
    <div className="element-library-edit-hosted-page-footer">
      <div className="element-library-edit-hosted-page-footer-title">
        <FormControlLabel
          className="enable-footer-switch"
          control={
            <Switch
              checked={isFooterEnabled}
              onChange={handleEnableFooterToggle}
              inputProps={{ 'aria-label': 'enable footer toggle switch' }}
            />
          }
          label="Enable Footer"
        />
        <div className={isFooterEnabled ? '' : 'disabled'}>
          <div className="disabled-overlay" />
          <Text variant="h5">Footer Message</Text>
          <div className="WYSIWYG-container">
            <WYSIWYG
              defaultStyles={{
                fontFamily: configData.config.theme.font.footer,
                color: configData.config.theme.text
              }}
              onChange={(newHtml: string) => setHtml(newHtml)}
              value={html}
              onBlur={handleUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
