import React from 'react';

/**
 * Acts as the React version of Array.join().
 * It takes in an array in and a separator (any valid JSX),
 * and returns the joined array.
 * @param arr An array of components to join
 * @param separator JSX components to stick in between every element in the given arr
 * @returns The joined array
 */
export const reactJoin = (
  arr: React.ReactNode[],
  separator: React.ReactNode
): React.ReactNode[] => {
  if (arr.length === 1) {
    return arr;
  }

  const newArr = arr.slice();

  // eslint-disable-next-line no-plusplus
  for (let i = arr.length - 1; i > 0; i--) {
    newArr.splice(i, 0, separator);
  }

  return newArr.map((el, index) => {
    const isEl = React.isValidElement(el);
    if (isEl) {
      // eslint-disable-next-line react/no-array-index-key
      return React.cloneElement(el, { key: index });
    }

    return el;
  });
};
