import { Dispatch, SetStateAction, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  MAXIMUM_DONATION,
  MINIMUM_DONATION
} from 'constants/givingFormContants';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form';
import * as yup from 'yup';
import Button from 'components/Button';
import { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { NumberTextField } from 'components/TextField';
import { RecurringGiftEquation } from 'types/givingFormTypes/RecurringGiftPrompt';
import './EditRecurringGiftEquation.scss';

type EditRecurringGiftEquationProps = {
  allEquations: RecurringGiftEquation[];
  equation: RecurringGiftEquation | null;
  id: string | null;
  onDelete: (id: string) => void;
  onUpdate: ({
    id,
    min,
    max,
    percentage,
    isStartDateEnabled,
    isEndDateEnabled
  }: RecurringGiftEquation) => void;
  open: boolean;
  setOpenId: Dispatch<SetStateAction<string>>;
};

const RecurringEquationSchema = yup.object({
  equation: yup.object({
    min: yup
      .number()
      // yup sets a NaN error when field is blank because it receives
      // an empty string from the number input.
      // overriding type error message with 'Required'
      .typeError('Required')
      .required('Required')
      .min(MINIMUM_DONATION, `Must be at least $${MINIMUM_DONATION}`)
      .max(MAXIMUM_DONATION, `Cannot exceed $${MAXIMUM_DONATION}`)
      // for validation that involves both min and max values, only showing
      // error message on min field so user does not get 2 validation
      // messages for one error
      .test({
        name: 'minCannotEqualMax',
        exclusive: false,
        message: 'Minimum and maximum values cannot be the same',
        test(value) {
          return value !== this.parent.max;
        }
      })
      .test({
        name: 'minLessThanMax',
        exclusive: false,
        message: 'Minimum must be less than maximum',
        test(value) {
          return value < this.parent.max;
        }
      }),
    max: yup
      .number()
      // yup sets a NaN error when field is blank because it receives
      // an empty string from the number input.
      // overriding type error message with 'Required'
      .typeError('Required')
      .required('Required')
      .min(MINIMUM_DONATION, `Must be at least $${MINIMUM_DONATION}`)
      .max(MAXIMUM_DONATION, `Cannot exceed $${MAXIMUM_DONATION}`),
    percentage: yup
      .number()
      // yup sets a NaN error when field is blank because it receives
      // an empty string from the number input.
      // overriding type error message with 'Required'
      .typeError('Required')
      .required('Required')
      .min(1, 'Must be at least 1%')
      .max(1000, 'Cannot exceed 1000%')
  })
});

const EditRecurringGiftEquationComp = ({
  allEquations,
  equation,
  id,
  onDelete,
  onUpdate,
  open,
  setOpenId
}: EditRecurringGiftEquationProps): JSX.Element => {
  const [min, setMin] = useState<number | string>(equation?.min || 5);
  const [max, setMax] = useState<number | string>(equation?.max || 100);
  const [percentage, setPercentage] = useState<number | string>(
    equation?.percentage || 100
  );
  const { control, formState, trigger } = useFormContext();

  const expandedClass = open ? 'expanded' : '';

  return (
    <Accordion
      className={`edit-recurring-gift-equation ${expandedClass}`}
      expanded={open}
    >
      <AccordionSummary
        className={`edit-recurring-gift-equation-summary ${expandedClass}`}
      >
        {!open && (
          <div className="min-max-summary">
            <Text variant="h4">{`$${min} - $${max}`}</Text>
          </div>
        )}
        <div className="edit-recurring-gift-equation-icon-container">
          {!open && (
            <IconButton
              label="edit"
              variant="basic"
              size="small"
              icon={ICONS.PENCIL}
              onClick={() => setOpenId(id)}
            />
          )}
          <IconButton
            label="delete"
            variant="basic"
            size="small"
            icon={ICONS.TRASH}
            onClick={() => onDelete(id)}
            disabled={allEquations.length === 1 && !!id}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={`edit-recurring-gift-equation-details ${expandedClass}`}
      >
        <div className="equation-details-header-container">
          <Text variant="h5">Donation Amount Threshold</Text>
          <Text variant="caption">
            Enter the threshold for this recurring equation.
          </Text>
        </div>
        <div className="min-max-container">
          <div>
            <Text variant="h6">Minimum</Text>
            <Controller
              name="equation.min"
              control={control}
              defaultValue={min}
              render={({
                field: { ref, onChange, onBlur, ...field },
                fieldState: { error }
              }) => (
                <NumberTextField
                  {...field}
                  aria-label="minimum recurring gift threshold"
                  decimalScale={0}
                  error={!!error}
                  helperText={error?.message}
                  hiddenLabel
                  valueIsNumericString
                  onBlur={() => onBlur()}
                  onChange={(e) => {
                    onChange(e);
                    setMin(e);
                  }}
                  placeholder="$"
                  prefix="$"
                  thousandSeparator
                  value={min}
                />
              )}
            />
          </div>
          <div>
            <Text variant="h6">Maximum</Text>
            <Controller
              name="equation.max"
              control={control}
              defaultValue={max}
              render={({
                field: { ref, onChange, onBlur, ...field },
                fieldState: { error }
              }) => (
                <NumberTextField
                  {...field}
                  aria-label="maximum recurring gift threshold"
                  decimalScale={0}
                  error={!!error}
                  helperText={error?.message}
                  hiddenLabel
                  valueIsNumericString
                  onBlur={() => {
                    // if there is an error on the min field and
                    // the new max value fixes the error, triggering
                    // validation on the min field when max is blurred
                    // ensures the error is cleared
                    trigger('equation.min');
                    onBlur();
                  }}
                  onChange={(e) => {
                    onChange(e);
                    setMax(e);
                  }}
                  placeholder="$"
                  prefix="$"
                  thousandSeparator
                  value={max}
                />
              )}
            />
          </div>
        </div>
        <div className="equation-details-header-container">
          <Text variant="h5">Percentage</Text>
          <Text variant="caption">
            What percentage of the donor’s selected donation should go into a
            monthly donation?
          </Text>
        </div>
        <div className="percentage-container">
          <Controller
            name="equation.percentage"
            control={control}
            defaultValue={percentage}
            render={({
              field: { ref, onChange, onBlur, ...field },
              fieldState: { error }
            }) => (
              <NumberTextField
                {...field}
                aria-label="recurring gift percentage"
                decimalScale={0}
                error={!!error}
                fullWidth
                helperText={error?.message}
                hiddenLabel
                valueIsNumericString
                onBlur={() => onBlur()}
                onChange={(e) => {
                  onChange(e);
                  setPercentage(e);
                }}
                placeholder="%"
                suffix="%"
                thousandSeparator
                value={percentage}
              />
            )}
          />
        </div>
        <Button
          variant="primary"
          onClick={() =>
            onUpdate({
              id,
              min: Number(min),
              max: Number(max),
              percentage: Number(percentage),
              isEndDateEnabled: false,
              isStartDateEnabled: false
            })
          }
          disabled={!formState.isValid}
          fullWidth
        >
          Update
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

export const EditRecurringGiftEquation = (
  props: EditRecurringGiftEquationProps
): JSX.Element => {
  const methods = useForm({
    resolver: yupResolver(RecurringEquationSchema),
    mode: 'onBlur'
  });

  return (
    <FormProvider {...methods}>
      <EditRecurringGiftEquationComp {...props} />
    </FormProvider>
  );
};
