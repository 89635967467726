/* eslint-disable jsx-a11y/tabindex-no-positive */
import { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  MAXIMUM_FILE_SIZE,
  MAXIMUM_FILE_SIZE_EXCEEDED_MSG
} from 'constants/givingFormContants';
import { Jodit } from 'jodit-pro';
import 'jodit-pro/build/jodit.css';
import { v4 as uuid } from 'uuid';
import Tag from 'assets/icons/tag.svg';
import { useAlerts } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { resolvedEnvironment } from 'services/environment';
import { FontOptions } from 'types';
import { cssPropertiesToString } from 'utils';
import ICONS from './WYSIWYGIcons';
import WYSIWYGMenu from './WYSIWYGMenu';
import { checkForTags } from './WYSIWYGUtils';

/**
 * TODOS:
 * Fix font list to actually preview the font
 * Fix circle option in bullet list dropdown
 * Put alignment in a menu
 * Fix weird starting position (if you backspace all the way in the editor) - check "enter: 'P'" config property
 * Verify correct icons for "font", "font-size", "paragraph"
 * Update types from 'any'
 * update chevrons to be included in icon size (maybe not, this approach gives more options)
 * update colorPickerDefaultTab to text
 * component cannot be controlled current. This should probably be extended.
 */

export type Tag = {
  /**
   * Text to show in the tag dropdown
   */
  name: string;
  /**
   * Value that actually gets injected into the WYSIWYG html. Will be wrapped in a <span> to inject blue background
   */
  value: string;
};

export type TagSection = {
  heading: string;
  tags: Tag[];
};

type UploaderData = {
  getAll: (string: string) => File[];
};

type UploaderProcess = {
  files: string[];
  baseurl: string;
  path: string;
  error: number;
  msg: string;
};

type WYSIWYGProps = {
  /**
   * Callback function that returns a custom list of buttons. Callback is passed the jodit editor instance which can be used to create
   * custom buttons from the consuming component if desired (see the default tag button for an example)
   */
  initializeButtons?: (editor: Editor) => string[];
  /**
   * Callback to run on blur of the editor. Probably use this for performance purposes over onChange. Passed the HTML string and native event
   */
  onBlur?: (content: string, event: JoditEvent) => void;
  /**
   * STARTING value for the editor. Should be extended in the future to allow for the WYSIWYG to be controlled.
   */
  value?: string;
  /**
   * List of sectioned tags. Is displayed in the dropdown, and falls back to `tags` prop if not provided.
   */
  tagList?: TagSection[];
  /**
   * List of valid tags. If no initializeButtons callback was provided and this prop is not provided (or array is empty), the tag button is hidden
   * Callback to run on change of the editor. Probably not very performant compared to using onBlur. Passed the HTML string and native event
   */
  onChange?: (content: string) => void;
  /**
   * List of tags to show in the tag dropdown. If no initializeButtons callback was provided and this prop is not provided (or array is empty), the tag button is hidden
   */
  tags?: Tag[];
  /**
   * Default styles for the jodit editor. fontFamily will default to helvetica if font family not provided
   */
  defaultStyles?: CSSProperties;
};

/**
 * List of fonts. Example key value pair:
 * { CSS_VALUE: DROPDOWN_TEXT }
 * e.g.
 * '"Bodoni Moda", serif': 'Bodoni'
 */
const fontOptions = {
  [FontOptions.Bodoni]: 'Bodoni',
  [FontOptions.CrimsonPro]: 'Crimson Pro',
  [FontOptions.EbGaramond]: 'EB Garamond',
  [FontOptions.Helvetica]: 'Helvetica',
  [FontOptions.HelveticaNeue]: 'Helvetica Neue',
  [FontOptions.Jost]: 'Jost',
  [FontOptions.Kameron]: 'Kameron',
  [FontOptions.LibreBaskerville]: 'Libre Baskerville',
  [FontOptions.LibreFranklin]: 'Libre Franklin',
  [FontOptions.Montserrat]: 'Montserrat',
  [FontOptions.Nunito]: 'Nunito',
  [FontOptions.Poppins]: 'Poppins',
  [FontOptions.TimesNewRoman]: 'Times New Roman',
  [FontOptions.Tinos]: 'Tinos'
};

const editorId = `jodit-editor-${uuid()}`;

const WYSIWYG = ({
  initializeButtons,
  onBlur,
  onChange,
  value = '',
  tags = [],
  tagList = [{ heading: '', tags }],
  defaultStyles = {}
}: WYSIWYGProps) => {
  const { selectedOrganization } = useAppContext();
  const jodit = useRef(null);
  const menuOpen = useRef(false);
  const editorDiv = useRef(null);
  const [showTagMenu, setShowTagMenu] = useState<boolean>(false);
  const [addAlert] = useAlerts();

  const token = window.sessionStorage.getItem('accessToken');

  const getButtons = () =>
    initializeButtons?.(jodit.current) ??
    [
      'bold',
      'italic',
      'underline',
      'font',
      'fontsize',
      'paragraph',
      'align',
      'ul',
      'ol',
      'brush',
      'image',
      'table',
      tags.length
        ? {
            name: 'Tag',
            iconURL: Tag,
            tooltip: 'Add Tag',
            exec: () => {
              setShowTagMenu(true);
              menuOpen.current = true;
            }
          }
        : null
    ].filter((e) => e);

  /**
   * Config for the Jodit Editor
   */
  const config = {
    license: resolvedEnvironment.joditProLicense,
    readonly: false,
    saveSelectionOnBlur: true,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    imagesExtensions: ['jpg', 'gif', 'png', 'svg'],
    // eslint-disable-next-line no-unneeded-ternary
    saveSelectionOnChange: onChange ? true : false,
    height: 400,
    width: 500,
    theme: 'gms', // Wraps editor in `jodit_theme_gms` class
    defaultMode: Jodit.MODE_WYSIWYG,
    enableDragAndDropFileToEditor: true,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    uploader: {
      url: `${resolvedEnvironment.baseUrl}${resolvedEnvironment.apiPrefix}/givingForms/image/upload?organizationId=${selectedOrganization.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      buildData: (data: UploaderData) =>
        new Promise((resolve, reject) => {
          const file: File = data.getAll('files[0]')[0];
          if (file.size > MAXIMUM_FILE_SIZE) {
            addAlert({
              title: MAXIMUM_FILE_SIZE_EXCEEDED_MSG,
              severity: 'error'
            });
            reject(new Error(MAXIMUM_FILE_SIZE_EXCEEDED_MSG));
          }

          const formData = new FormData();
          formData.append('file', file);
          formData.append('fileType', file.type);

          resolve(formData);
        }),
      isSuccess: (response: unknown) => !!response,
      process: ({ url }: { url: string }) => ({
        files: url.split('/').slice(-1),
        baseurl: `${url.split('/').slice(0, -1).join('/')}/`,
        path: '',
        error: -1,
        msg: ''
      }),
      defaultHandlerSuccess: ({ baseurl, files }: UploaderProcess) => {
        jodit.current.selection.insertImage(`${baseurl}${files[0]}`);
      }
    },
    disablePlugins: 'backup',
    controls: {
      font: {
        list: Jodit.atom(fontOptions) // Jodit.atom _replaces_ the font list instead of adding to it
      }
    },
    events: {
      /**
       * getIcon is fired any time an icon is loaded. Allows for us to define custom icons.
       */
      getIcon: (_: unknown, __: unknown, code: string) => {
        const icon = ICONS[code as WYSIWYGButton];
        return icon ? `<img src="${icon}" alt="${code}" />` : '';
      },
      afterInit: () => {
        /**
         * Connect the native element blur event to the onBlur prop
         */
        jodit.current.events.on('blur', (e: JoditEvent) => {
          if (!menuOpen.current) {
            const newValue = checkForTags(
              jodit.current.value,
              tags.map((tag) => tag.value)
            );

            // Catch an edge case where an 'empty' jodit editor returns this string
            if (newValue === '<p><br></p>') {
              onBlur?.(null, e);
            } else {
              onBlur?.(
                `<div style="${cssPropertiesToString(
                  defaultStyles
                )}">${newValue}</div>`,
                e
              );
            }
          }
        });
        jodit.current.events.on('keyup', (e: JoditEvent) => {
          if (e.key === '}') {
            const newValue = checkForTags(
              jodit.current.value,
              tags.map((tag) => tag.value)
            );
            jodit.current.setEditorValue(newValue);
          }
        });
        /**
         * Connect the native element change event to the onChange prop
         */
        jodit.current.events.on('change', (newValue: string) => {
          // Catch an edge case where an 'empty' jodit editor returns this string
          if (newValue === '<p><br></p>') {
            onChange?.(null);
          } else {
            onChange?.(
              `<div style="${cssPropertiesToString(
                defaultStyles
              )}">${newValue}</div>`
            );
          }
        });
        /**
         * Set default value after jodit editor has been initialized.
         */
        if (value) {
          jodit.current.value = value;
        }
      }
    },
    buttons: getButtons(),
    toolbarAdaptive: false
  };

  useEffect(() => {
    /**
     * Instantiate instance of the Jodit Editor after root #editor node has mounted
     */
    // eslint-disable-next-line no-new
    jodit.current = new Jodit(`#${editorId}`, config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const makeTags = (allTags: Tag[]) =>
    allTags.map(({ value: textValue, name }) => ({
      label: name,
      onClick: () => {
        jodit.current.selection.insertHTML(
          `<span class="jodit-valid-tag">${textValue}</span>`
        );
        setShowTagMenu(false);
        menuOpen.current = false;
      }
    }));

  return (
    <div id="gms-jodit-editor-container" style={defaultStyles}>
      <div id={editorId} ref={editorDiv} />

      <WYSIWYGMenu
        popperProps={{
          open: showTagMenu,
          anchorEl: () =>
            document.querySelector(
              '.jodit-toolbar-button[aria-label="Add Tag"]'
            ),
          className: 'jodit-popper'
        }}
        menuItems={tagList.map((section) => ({
          ...section,
          tags: makeTags(section.tags)
        }))}
        handleClose={() => setShowTagMenu(false)}
      />
    </div>
  );
};

export default WYSIWYG;
