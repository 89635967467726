import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';

type ChannelTooltipProps = {
  channelCount: {
    givingForms: number;
    events: number;
    peerToPeer: number;
    textToGive: number;
  };
};

export const ChannelTooltip = ({ channelCount }: ChannelTooltipProps) => (
  <div className="channels-tooltip">
    <Text variant="h6" className="channels-tooltip-title">
      Campaign Channels
    </Text>
    <div className="channels-tooltip-row">
      <Icon icon={ICONS.CIRCLE} className="giving-forms" />
      <Text variant="h6"> Giving Forms (</Text>
      <Text variant="h6" className="giving-forms">
        <b>{channelCount.givingForms}</b>
      </Text>
      <Text variant="h6">)</Text>
    </div>
    <div className="channels-tooltip-row">
      <Icon icon={ICONS.CIRCLE} className="events" />
      <Text variant="h6">Events (</Text>
      <Text variant="h6" className="events">
        <b>{channelCount.events}</b>
      </Text>
      <Text variant="h6">)</Text>
    </div>
    <div className="channels-tooltip-row">
      <Icon icon={ICONS.CIRCLE} className="peer-to-peer" />
      <Text variant="h6">Peer-To-Peer (</Text>
      <Text variant="h6" className="peer-to-peer">
        <b>{channelCount.peerToPeer}</b>
      </Text>
      <Text variant="h6">)</Text>
    </div>
    <div className="channels-tooltip-row">
      <Icon icon={ICONS.CIRCLE} className="text-to-give" />
      <Text variant="h6">Text to Give (</Text>
      <Text variant="h6" className="text-to-give">
        <b>{channelCount.textToGive}</b>
      </Text>
      <Text variant="h6">)</Text>
    </div>
  </div>
);
