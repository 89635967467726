import { OrganizationRole } from 'hooks/useRenderIfRole';
import { resolvedEnvironment } from '../services/environment';
import { LegacyApp, LegacyRoute } from './types';

const GMS = { name: 'GMS', rootUrl: resolvedEnvironment.gmsBaseUrl };
const EVENTS = { name: 'Events', rootUrl: resolvedEnvironment.eventsBaseUrl };
const EVENTS_PUBLIC = {
  name: 'Events Public',
  rootUrl: resolvedEnvironment.eventsPublicBaseUrl
};
const P2P = { name: 'P2P', rootUrl: resolvedEnvironment.p2pBaseUrl };
const P2P_PUBLIC = {
  name: 'P2P Public',
  rootUrl: resolvedEnvironment.p2pPublicBaseUrl
};
const RECONCILIATION = {
  name: 'Reconciliation',
  rootUrl: resolvedEnvironment.reconciliationUiBaseUrl
};

export const LEGACY_APPS: LegacyApp[] = [GMS, P2P, EVENTS, RECONCILIATION];
export const EXTERNAL_APPS: LegacyApp[] = [P2P_PUBLIC, EVENTS_PUBLIC];

export const LEGACY_ROUTES: LegacyRoute[] = [
  // Dashboards
  {
    app: GMS,
    redirectToLocalPath: 'dashboard',
    legacyPath: 'dashboard'
  },
  { app: GMS, legacyPath: 'chapter', redirectToLocalPath: '' },

  // User Profile
  { app: GMS, localPath: 'my-profile', legacyPath: 'profile' },

  // Help
  { app: GMS, localPath: 'help', legacyPath: 'help' },

  // Org Settings
  {
    app: GMS,
    localPath: 'settings/general',
    legacyPath: 'organization/account'
  },
  {
    app: GMS,
    localPath: 'settings/users',
    legacyPath: 'organization/users',
    minimumRole: OrganizationRole.Editor
  },
  {
    app: GMS,
    localPath: 'settings/users/:userId/edit',
    legacyPath: 'organization/users/edit/:userId'
  },
  {
    app: GMS,
    localPath: 'settings/contacts',
    legacyPath: 'organization/contacts'
  },
  {
    app: GMS,
    localPath: 'settings/payment-gateways',
    legacyPath: 'organization/cash-options'
  },
  {
    app: GMS,
    localPath: 'settings/integrations',
    legacyPath: 'organization/integrations'
  },
  { app: GMS, localPath: 'settings/texting', legacyPath: 'text/settings' },

  // Webhooks
  {
    app: GMS,
    localPath: 'settings/webhooks',
    legacyPath: 'organization/webhooks'
  },
  {
    app: GMS,
    localPath: 'settings/webhooks/new/:webhookType',
    legacyPath: 'organization/webhooks/install/:webhookType'
  },
  {
    app: GMS,
    localPath: 'settings/webhooks/:webhookId/edit',
    legacyPath: 'organization/webhooks/:webhookId'
  },
  {
    app: GMS,
    localPath: 'settings/webhooks/:webhookId/events',
    legacyPath: 'organization/events/:webhookId'
  },

  // Reports
  {
    app: GMS,
    localPath: 'reporting',
    legacyPath: 'reporting',
    backgroundHeader: 'default'
  },

  // Donations
  {
    app: GMS,
    localPath: 'donations',
    legacyPath: 'donations'
  },
  {
    app: GMS,
    localPath: 'donations/:donationId',
    legacyPath: 'donations/detail/:donationId'
  },
  {
    app: GMS,
    localPath: 'donations/offline/new',
    legacyPath: 'donations/offline'
  },
  {
    app: GMS,
    localPath: 'donations/offline/import',
    legacyPath: 'donations/import-offline'
  },
  {
    app: GMS,
    localPath: 'donations/recurring',
    legacyPath: 'donations/recurring'
  },
  {
    app: GMS,
    localPath: 'donations/recurring/:recurringId',
    legacyPath: 'donations/recurring/:recurringId'
  },
  // Donors
  {
    app: GMS,
    localPath: 'donors',
    legacyPath: 'donors'
  },
  {
    app: GMS,
    localPath: 'donors/:donorId',
    legacyPath: 'donors/detail/:donorId'
  },

  // Reconciliation
  {
    app: GMS,
    legacyPath: 'reconciliation/summary',
    redirectToLocalPath: ''
  },
  {
    app: GMS,
    legacyPath: 'reconciliation/batches',
    redirectToLocalPath: ''
  },
  {
    app: GMS,
    legacyPath: 'reconciliation/deposits',
    redirectToLocalPath: ''
  },
  {
    app: GMS,
    legacyPath: 'reconciliation/disbursements',
    redirectToLocalPath: ''
  },
  {
    app: GMS,
    legacyPath: 'reconciliation/transactions',
    redirectToLocalPath: ''
  },
  {
    app: GMS,
    legacyPath: 'reconciliation/statements',
    redirectToLocalPath: ''
  },
  {
    app: GMS,
    legacyPath: 'reconciliation/:report',
    redirectToLocalPath: ''
  },

  // Campaigns
  { app: GMS, legacyPath: 'campaigns', redirectToLocalPath: 'campaigns' },
  {
    app: GMS,
    legacyPath: 'campaigns/new',
    redirectToLocalPath: 'campaigns/new'
  },
  {
    app: GMS,
    legacyPath: 'campaign/:campaignId',
    redirectToLocalPath: 'campaigns/:campaignId'
  },

  {
    app: GMS,
    localPath: 'campaigns/:campaignId/edit',
    legacyPath: 'campaign/:campaignId/edit'
  },
  {
    app: GMS,
    localPath: 'campaigns/:campaignId/edit',
    legacyPath: 'campaign/:campaignId/edit/:tab'
  },

  // Giving Forms
  {
    app: GMS,
    localPath: 'campaigns/:campaignId/giving-forms/:givingFormId',
    legacyPath: 'embed/view/:givingFormId'
  },
  {
    app: GMS,
    legacyPath: 'embeds',
    redirectToLocalPath: 'campaigns/:campaignId'
  },
  {
    app: GMS,
    legacyPath: 'embed/new',
    redirectToLocalPath: 'campaigns/:campaignId/giving-forms/new'
  },
  {
    app: GMS,
    localPath: 'campaigns/:campaignId/giving-forms/:givingFormId/edit-legacy',
    legacyPath: 'embed/edit/:givingFormId'
  },
  {
    app: GMS,
    legacyPath: 'embed/edit-new/:givingFormId',
    redirectToLocalPath: 'campaigns/:campaignId/giving-forms/:givingFormId/edit'
  },

  // Thank You Gifts
  { app: GMS, legacyPath: 'thank-you-gifts', redirectToLocalPath: '' },
  { app: GMS, legacyPath: 'thank-you-gift/new', redirectToLocalPath: '' },
  {
    app: GMS,
    legacyPath: 'thank-you-gift/:thankYouGiftId',
    redirectToLocalPath: ''
  },

  // Tributes
  { app: GMS, legacyPath: 'tributes', redirectToLocalPath: '' },
  { app: GMS, legacyPath: 'tribute/new', redirectToLocalPath: '' },
  { app: GMS, legacyPath: 'tribute/:tributeId', redirectToLocalPath: '' },

  // Landing Pages
  {
    app: GMS,
    legacyPath: 'pages',
    redirectToLocalPath: 'campaigns/:campaignId'
  },
  {
    app: GMS,
    legacyPath: 'pages/new',
    redirectToLocalPath: 'campaigns/:campaignId'
  },
  {
    app: GMS,
    legacyPath: 'pages/edit/:landingPageId',
    redirectToLocalPath: 'campaigns/:campaignId'
  },

  // Goal Meters
  {
    app: GMS,
    legacyPath: 'goal-meters',
    redirectToLocalPath: 'campaigns/:campaignId'
  },
  {
    app: GMS,
    legacyPath: 'goal-meters/new',
    redirectToLocalPath: 'campaigns/:campaignId'
  },
  {
    app: GMS,
    legacyPath: 'goal-meters/edit/:goalMeterId',
    redirectToLocalPath: 'campaigns/:campaignId'
  },

  // Text
  {
    app: GMS,
    legacyPath: 'text',
    redirectToLocalPath: 'campaigns/:campaignId'
  },
  {
    app: GMS,
    legacyPath: 'text/autoresponses',
    redirectToLocalPath: 'campaigns/:campaignId'
  },

  {
    app: GMS,
    localPath: 'campaigns/:campaignId/text-keywords/new',
    legacyPath: 'text/giving-keyword/new',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: GMS,
    localPath: 'campaigns/:campaignId/text-keywords/:textKeywordId/edit',
    legacyPath: 'text/giving-keyword/:textKeywordId',
    queryMap: { campaignId: 'campaign_id' }
  },

  {
    app: GMS,
    localPath: 'campaigns/:campaignId/text-responses/new',
    legacyPath: 'text/autoresponder-keyword/new',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: GMS,
    localPath: 'campaigns/:campaignId/text-responses/:textResponseId/edit',
    legacyPath: 'text/autoresponder-keyword/:textResponseId',
    queryMap: { campaignId: 'campaign_id' }
  },

  // P2P
  {
    app: GMS,
    legacyPath: 'p2p-ui',
    redirectToLocalPath: 'campaigns/:campaignId',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: P2P,
    legacyPath: 'programs',
    redirectToLocalPath: 'campaigns/:campaignId',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: P2P,
    legacyPath: 'peer-to-peer/:action',
    redirectToLocalPath: 'campaigns/:campaignId',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: P2P,
    legacyPath: 'anchor-sites',
    redirectToLocalPath: 'campaigns/:campaignId',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: P2P,
    legacyPath: 'anchor-sites/:anchorSiteId/edit',
    redirectToLocalPath: 'campaigns/:campaignId',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: P2P,
    localPath: 'campaigns/:campaignId/p2p/new',
    legacyPath: 'programs/new',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: P2P,
    localPath: 'campaigns/:campaignId/p2p/:p2pId',
    legacyPath: 'programs/:p2pId/details',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: P2P,
    localPath: 'campaigns/:campaignId/p2p/:p2pId/edit',
    legacyPath: 'programs/:p2pId/edit',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: P2P,
    localPath: 'campaigns/:campaignId/p2p/:p2pId/edit/:step',
    legacyPath: 'programs/:p2pId/edit/:step',
    queryMap: { campaignId: 'campaign_id' }
  },

  // Events
  {
    app: GMS,
    legacyPath: 'event-management',
    redirectToLocalPath: 'campaigns/:campaignId',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: EVENTS,
    legacyPath: 'events',
    redirectToLocalPath: 'campaigns/:campaignId',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: EVENTS,
    localPath: 'campaigns/:campaignId/events/new',
    legacyPath: 'events/new',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: EVENTS,
    localPath: 'campaigns/:campaignId/events/:eventId',
    legacyPath: 'events/:eventId/sales',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: EVENTS,
    localPath: 'campaigns/:campaignId/events/:eventId/edit',
    legacyPath: 'events/:eventId/edit',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: EVENTS,
    localPath: 'campaigns/:campaignId/events/:eventId/edit/surveys/new',
    legacyPath: 'events/:eventId/edit/surveys/new',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: EVENTS,
    localPath: 'campaigns/:campaignId/events/:eventId/edit/survey/:surveyId',
    legacyPath: 'events/:eventId/edit/surveys/:surveyId',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: EVENTS,
    localPath: 'campaigns/:campaignId/events/:eventId/edit/tickets/new',
    legacyPath: 'events/:eventId/edit/tickets/new',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: EVENTS,
    localPath: 'campaigns/:campaignId/events/:eventId/edit/tickets/:ticketId',
    legacyPath: 'events/:eventId/edit/tickets/:ticketId',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: EVENTS,
    localPath: 'campaigns/:campaignId/events/:eventId/edit/:view',
    legacyPath: 'events/:eventId/edit/:view',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: EVENTS,
    localPath: 'campaigns/:campaignId/events/:eventId/:view',
    legacyPath: 'events/:eventId/:view',
    queryMap: { campaignId: 'campaign_id' }
  },
  {
    app: RECONCILIATION,
    localPath: 'settings/reconciliation',
    legacyPath: ':orgId/gms-ui'
  }
];
