import { Divider, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Icon, { ICONS } from 'components/Icon';
import ProgressBar from 'components/ProgressBar';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import { CampaignType } from 'services';
import { formatDate } from 'utils';
import { getProgressBarAmount } from 'utils/chartHelpers';
import { usdCurrencyFormatter } from 'utils/formatters';
import './ActiveCampaignsCardItem.scss';
import { ChannelTooltip } from './ChannelTooltip';

type ActiveCampaignsCardItemProps = {
  campaign: CampaignType;
};

export const ActiveCampaignsCardItem = ({
  campaign
}: ActiveCampaignsCardItemProps): JSX.Element => (
  <Stack key={campaign.id} className="active-campaigns-item">
    <div className="active-campaigns-item-container">
      <Stack className="active-campaigns-item-name-container">
        <Link
          to={`/campaigns/${campaign.id}`}
          className="active-campaigns-item-link"
        >
          <Text
            noWrap
            variant="caption"
            className="active-campaigns-item-title"
          >
            {campaign.title}
          </Text>
        </Link>
        <Text className="active-campaigns-item-range-label" variant="caption">
          {formatDate(campaign.startDate)} -{' '}
          {formatDate(campaign.endDate) || 'Present'}
        </Text>
      </Stack>
      <Stack className="active-campaigns-item-donation-container">
        <Text variant="h6" className="active-campaigns-item-goal">
          <span className="active-campaigns-item-amount-raised">
            {usdCurrencyFormatter.format(campaign.amountRaised)}
          </span>{' '}
          / {usdCurrencyFormatter.format(campaign.goal)}
        </Text>
        <ProgressBar
          variant="determinate"
          value={getProgressBarAmount(campaign.amountRaised, campaign.goal)}
          className="active-campaigns-item-progress-bar"
        />
      </Stack>

      <div className="active-campaigns-item-channels">
        <Tooltip
          title={<ChannelTooltip channelCount={campaign.channelCount} />}
          placement="top"
          componentsProps={{ popper: { className: 'channels-tooltip-popper' } }}
          arrow={false}
        >
          <div className="tooltip-trigger">
            <Icon icon={ICONS.SUBTASK} />
            <Text variant="h6">
              {Object.values(campaign.channelCount).reduce(
                (total, curr) => total + curr,
                0
              )}
            </Text>
          </div>
        </Tooltip>
      </div>
    </div>
    <Divider className="active-campaigns-item-divider" />
  </Stack>
);
