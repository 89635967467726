import { Box, Chip, Stack } from '@mui/material';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { formatIsoDate } from 'utils';
import {
  numberFormatter,
  timeFormatter,
  usdCurrencyFormatter
} from 'utils/formatters';
import '../ChannelPreviewCard.scss';
import './EventPreviewCard.scss';

type EventPreviewCardProps = {
  id: string;
  campaignId: string;
  eventName: string;
  createdDate: string;
  eventStartDate: string;
  eventEndDate: string;
  amountRaised: number;
  donorCount: number;
  ticketsSold: number;
  visitCount: number;
  isArchived: boolean;
  onArchiveClick: (toArchive: boolean) => void;
  marginOnEven?: boolean;
};

const getTimeRangeLabel = (startDate: string, endDate: string): string => {
  const isMultipleDays =
    new Date(startDate).setHours(0, 0, 0, 0) !==
    new Date(endDate).setHours(0, 0, 0, 0);

  // If event spans multiple days, then display start time and number of days spanned
  // If event spans one day, then display that date and the start/end times
  let timeLabel = isMultipleDays
    ? `${timeFormatter.format(new Date(startDate))} (${
        new Date(endDate).setHours(0, 0, 0, 0) / (1000 * 60 * 60 * 24) -
        new Date(startDate).setHours(0, 0, 0, 0) / (1000 * 60 * 60 * 24) +
        1 // The Day count is inclusive to partial days so we have to add 1
      } Days)`
    : `${timeFormatter.format(new Date(startDate))}-${timeFormatter.format(
        new Date(endDate)
      )}`;

  timeLabel = timeLabel.replace(/:00/g, '');
  if (timeLabel.indexOf(' PM') !== timeLabel.lastIndexOf(' PM')) {
    timeLabel = timeLabel.replace(' PM', '');
  }
  if (timeLabel.indexOf(' AM') !== timeLabel.lastIndexOf(' AM')) {
    timeLabel = timeLabel.replace(' AM', '');
  }

  return `${formatIsoDate(startDate, 'MM/dd/yyyy')}, ${timeLabel}`;
};

/**
 * A card used to display an overview of campaign's giving form
 */
export const EventPreviewCard = ({
  id,
  campaignId,
  eventName,
  createdDate,
  eventStartDate,
  eventEndDate,
  amountRaised,
  donorCount,
  ticketsSold,
  visitCount,
  isArchived,
  onArchiveClick,
  marginOnEven = true
}: EventPreviewCardProps) => {
  const navigate = useNavigate();

  return (
    <Box
      className={clsx({
        'channel-preview-box': true,
        'event-preview-box': true,
        'margin-on-even': marginOnEven
      })}
    >
      {isArchived && <div className="archived-grayed-out" />}
      {isArchived && (
        <Chip
          className="channel-card-chip archived"
          size="medium"
          label="Archived"
        />
      )}
      <Stack
        direction="column"
        justifyContent="center"
        className={clsx('overview-hover', {
          'overview-archived': isArchived
        })}
      >
        <Button
          variant="secondary"
          onClick={() => navigate(`/campaigns/${campaignId}/events/${id}`)}
          startIcon={<Icon icon={ICONS.CHART_INFOGRAPHIC} />}
        >
          Overview
        </Button>
        <Button
          variant="secondary"
          onClick={() => navigate(`/campaigns/${campaignId}/events/${id}/edit`)}
          startIcon={<Icon icon={ICONS.PENCIL} />}
        >
          Edit
        </Button>
        <Button
          variant="secondary"
          onClick={() => onArchiveClick(!isArchived)}
          startIcon={<Icon icon={ICONS.ARCHIVE} />}
        >
          {isArchived ? 'Unarchive' : 'Archive'}
        </Button>
      </Stack>

      <Stack direction="column" justifyContent="center" spacing={2}>
        <Box
          display="flex"
          paddingLeft={1.5}
          justifyContent="space-between"
          alignItems="center"
          className="channel-preview-header"
        >
          <Text className="header-label" variant="h2">
            Event
          </Text>
          <Icon icon={ICONS.SPEAKERPHONE} className="header-icon" />
        </Box>
        <Box
          padding={1.5}
          paddingBottom={0.5}
          className="channel-preview-content"
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <IconButton
              label="event label icon"
              className="channel-icon"
              icon={ICONS.SPEAKERPHONE}
              variant="primary"
              size="small"
            />
            <Text variant="h3">{eventName}</Text>
          </Stack>

          <Stack direction="column" marginTop={1}>
            <Stack
              className="form-detail"
              direction="row"
              justifyContent="space-between"
            >
              <Box>
                <Text className="label" variant="body">
                  Created
                </Text>
              </Box>
              <Box>
                <Text variant="body">
                  {formatIsoDate(createdDate, 'MM/dd/yyyy')}
                </Text>
              </Box>
            </Stack>
            <Stack
              className="form-detail"
              direction="row"
              justifyContent="space-between"
            >
              <Box>
                <Text className="label" variant="body">
                  Event Date
                </Text>
              </Box>
              <Box>
                <Text variant="body">
                  {getTimeRangeLabel(eventStartDate, eventEndDate)}
                </Text>
              </Box>
            </Stack>
            <Stack
              className="form-detail"
              direction="row"
              justifyContent="space-between"
            >
              <Box>
                <Text className="label" variant="body">
                  Amount Raised
                </Text>
              </Box>
              <Box>
                <Text variant="body">
                  {usdCurrencyFormatter.format(amountRaised)}
                </Text>
              </Box>
            </Stack>
            <Stack
              className="form-detail"
              direction="row"
              justifyContent="space-between"
            >
              <Box>
                <Text className="label" variant="body">
                  Donors
                </Text>
              </Box>
              <Box>
                <Text variant="body">{numberFormatter.format(donorCount)}</Text>
              </Box>
            </Stack>
            <Stack
              className="form-detail"
              direction="row"
              justifyContent="space-between"
            >
              <Box>
                <Text className="label" variant="body">
                  Tickets Sold
                </Text>
              </Box>
              <Box>
                <Text variant="body">
                  {numberFormatter.format(ticketsSold)}
                </Text>
              </Box>
            </Stack>
            <Stack
              className="form-detail"
              direction="row"
              justifyContent="space-between"
            >
              <Box>
                <Text className="label" variant="body">
                  Visits
                </Text>
              </Box>
              <Box>
                <Text variant="body">{numberFormatter.format(visitCount)}</Text>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
