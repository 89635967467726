import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ActiveMenu } from 'components/EditorMenus';
import { EditsSaved } from 'components/EditsSaved';
import { ICONS } from 'components/Icon';
import IconButton from 'components/IconButton';
import DeleteBlockModal, {
  DeleteModalConfig
} from 'components/gms/DeleteBlockModal/DeleteBlockModal';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { useEditorIframeContext } from 'hooks/useEditorIframeContext';
import { useEventHubPreview } from 'hooks/useEventHubPreview';
import { useEmailById } from 'queries/UseEmails';
import { useOrganization } from 'queries/UseOrganizationData';
import { EditorMenus, EditorTypes, ElementLibraryEmailViews } from 'types';
import { EmailConfig, EmailType } from 'types/emailTypes/Email';
import { EmailBlockBaseType } from 'types/emailTypes/EmailBlockBaseType';
import {
  emitDeselect,
  emitOrgInfo,
  emitSelect,
  getLibraryViewFromBlockId
} from 'utils';
import './EmailEditor.scss';

type EmailEditorProps = {
  type: EmailType;
  iframeSrc: string;
  deleteModalConfig: DeleteModalConfig;
  setDeleteModalConfig: Dispatch<SetStateAction<DeleteModalConfig>>;
};

export const EmailEditor = ({
  type,
  iframeSrc,
  deleteModalConfig,
  setDeleteModalConfig
}: EmailEditorProps): JSX.Element => {
  const { emailId } = useParams();
  const { refetch: getEmail } = useEmailById({
    emailId,
    type
  });
  const {
    configData,
    configData: { config },
    updateConfig,
    handleSetForm,
    discardEdits,
    highlightedBlock,
    setHighlightedBlock
  } = useConfigContext<EmailConfig>();
  const {
    blockOrderToUpdate,
    embedReadyToDisplay,
    eventHub,
    iframeHeight,
    iframeReadyCallback
  } = useEditorIframeContext();
  const { selectedOrganization } = useAppContext();
  const { data: orgData } = useOrganization(selectedOrganization.id);
  const [activeMenu, setActiveMenu] = useState<EditorMenus>(
    EditorMenus.EmailElementLibrary
  );
  const [libraryView, setLibraryView] = useState<ElementLibraryEmailViews>(
    ElementLibraryEmailViews.RootView
  );
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEventHubPreview({ iframeRef, configData, iframeReadyCallback });

  useEffect(() => {
    if (embedReadyToDisplay && orgData && selectedOrganization) {
      const orgInfo = {
        orgName: selectedOrganization.name,
        orgLogoUrl: orgData.logoUrl
      };
      emitOrgInfo(eventHub, orgInfo);
    }
  }, [embedReadyToDisplay, eventHub, orgData, selectedOrganization]);
  // This logic has to be executed in a useEffect so that configData
  // always contains the latest information
  useEffect(() => {
    if (blockOrderToUpdate.length) {
      const newBlocks = blockOrderToUpdate.map((id: string) =>
        config.blocks.find((block: EmailBlockBaseType) => block.id === id)
      );
      const newConfig = {
        ...config,
        blocks: newBlocks as EmailBlockBaseType[] // .map above should never return undefined
      };
      updateConfig(newConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockOrderToUpdate]);

  useEffect(() => {
    if (activeMenu !== EditorMenus.EmailElementLibrary) {
      emitDeselect(eventHub);
      setHighlightedBlock('');
      setLibraryView(ElementLibraryEmailViews.RootView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenu]);

  useEffect(() => {
    emitSelect(highlightedBlock, eventHub);

    if (highlightedBlock === 'header') {
      setActiveMenu(EditorMenus.EmailElementLibrary);
      setLibraryView(ElementLibraryEmailViews.Header);
    } else if (
      (configData?.config?.blocks || [])?.find(
        (block) => block.id === highlightedBlock
      )
    ) {
      const newLibraryView = getLibraryViewFromBlockId(
        highlightedBlock,
        EditorTypes.Email,
        configData
      );
      const emailLibraryView = newLibraryView as ElementLibraryEmailViews;
      setActiveMenu(EditorMenus.EmailElementLibrary);
      setLibraryView(emailLibraryView);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedBlock]);

  const getCurrentEmail = async () => {
    const { data } = await getEmail();
    return data;
  };

  // function to be passed through EditsSaved to RevertChangesModal
  const onDiscard = async () => {
    const currentBackendEmail = await getCurrentEmail();
    setActiveMenu(EditorMenus.EmailElementLibrary);
    setLibraryView(ElementLibraryEmailViews.RootView);
    discardEdits();
    handleSetForm({
      ...currentBackendEmail,
      seedConfigPublishedAt: currentBackendEmail.updatedAt
    });
  };

  const revertChangesModalCopy = (
    <>
      Any changes you made to the email will not be saved, and it will be
      reverted to the most recent published version.
    </>
  );

  const closeModal = () => setDeleteModalConfig({ open: false });

  const handleDelete = () => {
    if (eventHub) {
      const newConfig = { ...(config as EmailConfig) };
      const { id } = deleteModalConfig;
      newConfig.blocks = newConfig.blocks.filter(
        (block: EmailBlockBaseType) => block.id !== id
      );

      updateConfig(newConfig);
      setLibraryView(ElementLibraryEmailViews.RootView);
      closeModal();
    }
  };

  const elementLibraryReturnAction =
    libraryView === ElementLibraryEmailViews.RootView
      ? undefined
      : () => {
          setHighlightedBlock('');
          setLibraryView(ElementLibraryEmailViews.RootView);
        };

  return (
    <Stack className="EmailEditor-Wrapper fluid-container" direction="row">
      <Stack className="EmailEditor-iFrameContainer">
        <Box className="EmailEditor-MenuSelectionStack">
          <Stack direction="row" spacing={1}>
            <IconButton
              icon={ICONS.PLUS}
              variant={
                activeMenu === EditorMenus.EmailElementLibrary
                  ? 'primary'
                  : 'secondary'
              }
              onClick={() => setActiveMenu(EditorMenus.EmailElementLibrary)}
              label="Add Element"
              tooltipLabel="Add Element"
            />
            <IconButton
              icon={ICONS.PALETTE}
              variant={
                activeMenu === EditorMenus.EmailLookAndFeel
                  ? 'primary'
                  : 'secondary'
              }
              onClick={() => setActiveMenu(EditorMenus.EmailLookAndFeel)}
              label="Look and Feel"
              tooltipLabel="Look & Feel"
            />
          </Stack>
          {configData.editsSavedTime && (
            <EditsSaved
              configData={configData}
              onDiscard={onDiscard}
              modalCopy={revertChangesModalCopy}
              editorType={EditorTypes.Email}
            />
          )}
        </Box>
        <div className="EmailEditor-iFrame">
          <iframe
            id="previewFrame"
            name="previewFrame"
            title="previewFrame"
            src={iframeSrc}
            frameBorder="0"
            sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation allow-modals allow-downloads allow-popups"
            width="100%"
            style={{
              visibility:
                !iframeHeight || !embedReadyToDisplay ? 'hidden' : 'visible',
              height: iframeHeight
            }}
            ref={iframeRef}
          />
        </div>
      </Stack>
      <ActiveMenu
        activeMenu={activeMenu}
        elementLibraryReturnAction={elementLibraryReturnAction}
        libraryView={libraryView}
        setLibraryView={setLibraryView}
      />
      <DeleteBlockModal
        onConfirm={handleDelete}
        onClose={closeModal}
        open={deleteModalConfig.open}
      />
    </Stack>
  );
};

export default EmailEditor;
