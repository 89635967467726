import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButtonProps as MuiIconButtonProps,
  Stack
} from '@mui/material';
import clsx from 'clsx';
import { ICONS } from 'components';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { TextVariants } from 'components/Text/Text';
import './OptionalSection.scss';

type OptionalSectionProps = {
  title: string;
  titleVariant?: TextVariants;
  initiallySelected: boolean;
  onChange?: (value: boolean) => void;
  children?: React.ReactNode;
  IconButtonProps?: MuiIconButtonProps;
  unmountOnExit?: boolean;
  isEnabled?: boolean;
};

const OptionalSection = ({
  title,
  titleVariant = 'h3',
  initiallySelected,
  onChange,
  children,
  IconButtonProps = {},
  unmountOnExit = false,
  isEnabled = true
}: OptionalSectionProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(initiallySelected);

  const titleClassName = clsx('OptionalSection-title', {
    'OptionalSection-title--selected': open
  });

  return (
    <Accordion
      className="OptionalSection"
      expanded={open}
      TransitionProps={{ unmountOnExit }}
    >
      <AccordionSummary className="OptionalSection-summary">
        <Stack direction="row" alignItems="center" spacing={0.75}>
          <IconButton
            className="OptionalSection-icon"
            variant={open ? 'primary' : 'tertiary'}
            label={title}
            icon={open ? ICONS.MINUS : ICONS.PLUS}
            disabled={!isEnabled}
            onClick={() => {
              onChange?.(!open);
              setOpen(!open);
            }}
            {...IconButtonProps}
          />
          <Text
            className={clsx(titleClassName, { disabled: !isEnabled })}
            variant={titleVariant}
          >
            {title}
          </Text>
        </Stack>
      </AccordionSummary>
      {!!children && (
        <AccordionDetails className="OptionalSection-details">
          {children}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default OptionalSection;
