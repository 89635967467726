export enum EditorMenus {
  EmailElementLibrary = 'EmailElementLibrary',
  EmailLookAndFeel = 'EmailLookAndFeel',
  GivingFormElementLibrary = 'GivingFormElementLibrary',
  GivingFormLookAndFeel = 'GivingFormLookAndFeel',
  GivingFormCustomCss = 'GivingFormCustomCss',
  HostedPageElementLibrary = 'HostedPageElementLibrary',
  HostedPageCustomCss = 'HostedPageCustomCss'
}

export enum EditorTypes {
  GivingForm = 'GivingForm',
  Email = 'Email',
  HostedPage = 'HostedPage'
}
