import { CSSProperties } from 'react';
import Text from 'components/Text';
import './AbTestOverviewBarGraphTooltip.scss';

interface AbTestOverviewBarGraphTooltipProps {
  title: string;
  amount: string;
  styles: CSSProperties;
}

export const AbTestOverviewBarGraphTooltip = ({
  title,
  amount,
  styles
}: AbTestOverviewBarGraphTooltipProps) => (
  <div className="abtest-bar-graph-tooltip" style={styles}>
    <Text variant="h6">{title}</Text>
    <Text variant="h2">{amount}</Text>
    <div className="abtest-bar-graph-tooltip-point" />
  </div>
);
