import { useState } from 'react';
import { Card, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import Text from 'components/Text';
import { AbTest } from 'types';
import { AbTestOverviewBarGraph } from '../AbTestOverviewBarGraph/AbTestOverviewBarGraph';
import { AbTestDetails } from './AbTestDetails';
import { AbTestNotes } from './AbTestNotes';
import './AbTestOverviewBody.scss';
import { AbTestResultsHeader } from './AbTestResultsHeader';
import { AbTestResultsTable } from './AbTestResultsTable';

interface AbTestOverviewBodyProps {
  abTest: AbTest;
}

export const AbTestOverviewBody = ({ abTest }: AbTestOverviewBodyProps) => {
  const [selectedTab, setSelectedTab] = useState('details');

  const renderAside = () => {
    // TODO mmeza - We can refactor this to pass down the abTest
    // so that sub-components do not need to refetch this data, but
    // this will also require unit test refactors.
    if (selectedTab === 'details') return <AbTestDetails />;
    if (selectedTab === 'notes') return <AbTestNotes />;
    return null;
  };

  const tabsProps = [
    {
      label: 'Test Details',
      value: 'details'
    },
    {
      label: 'Notes',
      value: 'notes'
    }
  ];

  return (
    <div className="overview-body-container fluid-container">
      <div
        className={clsx('left-container', {
          'ab-test-notes-tab': selectedTab === 'notes'
        })}
      >
        <Tabs value={selectedTab}>
          {tabsProps.map((tabProps) => (
            <Tab
              key={tabProps.value}
              {...tabProps}
              onClick={() => setSelectedTab(tabProps?.value)}
            />
          ))}
        </Tabs>
        {renderAside()}
      </div>
      <div className="right-container">
        <AbTestResultsHeader />
        <AbTestResultsTable metrics={abTest.metrics} />
        <div className="abtest-overview-charts">
          <div className="abtest-overview-donations">
            <Card className="abtest-overview-chart-card">
              <Text variant="h3">Total Donation Amount</Text>
              <AbTestOverviewBarGraph
                variantAData={abTest.metrics.variantA?.amountRaised}
                variantBData={abTest.metrics.variantB?.amountRaised}
                tooltipTitle="Total Donations Received"
                useCurrencyFormatter
              />
            </Card>
          </div>
          <div className="abtest-overview-transactions">
            <Card className="abtest-overview-chart-card">
              <Text variant="h3">Total Donations</Text>
              <AbTestOverviewBarGraph
                variantAData={abTest.metrics.variantA?.donationCount}
                variantBData={abTest.metrics.variantB?.donationCount}
                tooltipTitle="Number of Transactions"
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
