import { useEffect, useState } from 'react';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import TextField from 'components/TextField';
import { AbTestNotificationCriteria } from 'components/gms/AbTestNotificationCriteria';
import { useAbTestDraft, useOnUnmount } from 'hooks';
import { useAppContext } from 'hooks/useAppContext';
import { NotificationUnit, TimeNotificationUnit } from 'types';
import { NotificationTypes } from 'utils';
import './ReviewStep.scss';

export const ReviewStep = (): JSX.Element => {
  const { selectedOrganization, envConfig } = useAppContext();
  const { clearErrors, control, getFieldState, getValues, setValue, trigger } =
    useFormContext();
  const { givingFormId } = useParams();
  const { draft, updateDraft } = useAbTestDraft();
  const {
    config: {
      description = '',
      assumption = '',
      thresholds: {
        unit = 'Select',
        amount = null,
        timeUnit = 'Select',
        timeAmount = null
      }
    }
  } = draft ?? { config: { thresholds: {} } };

  const [editingSettings, setEditingSettings] = useState<boolean>(false);
  const [hideOrLabel, setHideOrLabel] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [stateBackup, setStateBackup] = useState<any>({});

  const handleResize = () => {
    const elementAHeight =
      document.getElementById('column-variant-a').offsetHeight;
    const elementBHeight =
      document.getElementById('column-variant-b').offsetHeight;
    const fontSize = parseFloat(
      window
        .getComputedStyle(document.getElementById('column-variant-a'), null)
        .getPropertyValue('font-size')
    );
    const newTopA = (window.innerHeight - elementAHeight - 58) / fontSize;
    const newTopB = (window.innerHeight - elementBHeight - 58) / fontSize;
    document.getElementById('column-variant-a').style.top = `${newTopA}rem`;
    document.getElementById('column-variant-b').style.top = `${newTopB}rem`;
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  useOnUnmount(() => {
    if (Object.keys(stateBackup).length) {
      clearErrors();
      Object.keys(stateBackup).forEach((key) =>
        setValue(key, stateBackup[key])
      );
    }
  }, [stateBackup]);

  const metricHasValidationError = () => {
    setHideOrLabel(
      getFieldState('notificationUnit').invalid ||
        getFieldState('notificationUnitAmount').invalid
    );
  };

  const renderThresholds = () => {
    // this logic yields us two variables `left` and `right`, which will either be undefined, or a module of { unit, amount }, with the time
    // module being prioritized as left over right.

    const validKeys = [
      ...Object.keys(NotificationUnit),
      ...Object.keys(TimeNotificationUnit)
    ];

    const [left, right] = [
      {
        unit,
        amount
      },
      {
        unit: timeUnit,
        amount: timeAmount
      }
    ].filter(
      (e) =>
        e.unit && // theres a unit
        e.amount && // theres an amount
        validKeys.includes(e.unit) // the unit is valid
    );

    return left ? (
      <>
        <Text variant="h3">Notify After:</Text>
        <Text variant="h7">{pluralize(left.unit, +left.amount, true)}</Text>
        {right && (
          <>
            <Text variant="caption">{' or '}</Text>
            <Text variant="h7">
              {pluralize(right.unit, +right.amount, true)}
            </Text>
          </>
        )}
      </>
    ) : (
      <>
        <Text variant="h3">Notify After:</Text>
        <Text variant="body">Not provided</Text>
      </>
    );
  };

  const handleUpdate = () => {
    trigger().then((isValid) => {
      if (isValid) {
        const currentValues = getValues();
        updateDraft({
          config: {
            description: currentValues.description,
            assumption: currentValues.assumption,
            thresholds: {
              unit: currentValues.notificationUnit,
              amount: +currentValues.notificationUnitAmount,
              timeUnit: currentValues.timeNotificationUnit,
              timeAmount: +currentValues.timeNotificationUnitAmount
            }
          }
        });
        setStateBackup({});
        setEditingSettings(false);
      }
    });
  };

  return (
    <div className="test-review-step">
      <div className="test-review-header">
        <Text variant="h2">Review</Text>
        <Button
          variant="text"
          startIcon={<Icon icon={ICONS.PENCIL} />}
          disabled={editingSettings}
          onClick={() => {
            setStateBackup(getValues());
            setEditingSettings(!editingSettings);
          }}
        >
          Edit Details
        </Button>
      </div>
      <div
        className={clsx('test-review-details', {
          'edit-mode': editingSettings
        })}
      >
        <div className="details-layout">
          {!editingSettings && (
            <>
              <div className="view-description">
                <Text variant="h3">Description:</Text>
                {description && (
                  <Text variant="body">
                    I am creating this test to {description}
                  </Text>
                )}
                {!description && (
                  <Text variant="body" className="no-value">
                    Not provided
                  </Text>
                )}
              </div>
              <div className="view-assumption">
                <Text variant="h3">Assumption:</Text>
                {assumption && (
                  <Text variant="body">I predict that {assumption}</Text>
                )}
                {!assumption && (
                  <Text variant="body" className="no-value">
                    Not provided
                  </Text>
                )}
              </div>
              <div
                className={clsx('view-notifications', {
                  'no-value': !amount && !timeAmount
                })}
              >
                {renderThresholds()}
              </div>
            </>
          )}
          {editingSettings && (
            <>
              <div className="edit-description">
                <Text variant="h3">Description:</Text>
                <Text variant="caption">I am creating this A/B Test to...</Text>
                <Controller
                  name="description"
                  control={control}
                  defaultValue={description}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error }
                  }) => (
                    <TextField
                      {...field}
                      multiline
                      minRows={5}
                      maxRows={5}
                      className="ab-test-description-textfield"
                      error={!!error}
                      helperText={error?.message}
                      placeholder="Enter Description"
                    />
                  )}
                />
              </div>
              <div className="edit-assumption">
                <Text variant="h3">Assumption:</Text>
                <Text variant="caption">I predict that...</Text>
                <Controller
                  name="assumption"
                  control={control}
                  defaultValue={assumption}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error }
                  }) => (
                    <TextField
                      {...field}
                      multiline
                      minRows={5}
                      maxRows={5}
                      error={!!error}
                      helperText={error?.message}
                      className="ab-test-assumption-textfield"
                      placeholder="Enter Assumptions"
                    />
                  )}
                />
              </div>
              <div className="edit-notifications">
                <Text variant="h3">Notify After:</Text>
                <Text variant="caption">
                  I would like to be notified when...
                </Text>
                <div className="ab-test-notification-container">
                  <AbTestNotificationCriteria
                    variant={NotificationTypes.MetricBased}
                    defaultUnit={unit}
                    defaultAmount={amount}
                    onValueChange={(triggerName: string) => {
                      trigger(triggerName).then(() => {
                        metricHasValidationError();
                      });
                    }}
                  />
                  <Text
                    variant="h5"
                    className={clsx({ 'hide-or-label': hideOrLabel })}
                  >
                    or
                  </Text>
                  <AbTestNotificationCriteria
                    variant={NotificationTypes.TimeBased}
                    defaultUnit={timeUnit}
                    defaultAmount={timeAmount}
                    onValueChange={(triggerName: string) =>
                      trigger(triggerName)
                    }
                  />
                </div>
              </div>
              <div className="details-edit-actions">
                <Button onClick={handleUpdate}>Update</Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    Object.keys(stateBackup).forEach((key) =>
                      setValue(key, stateBackup[key])
                    );
                    clearErrors();
                    setEditingSettings(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="test-review-preview">
        <div className="preview-column" id="column-variant-a">
          <Text variant="h2">Form A</Text>
          <img
            src={`${envConfig?.s3Url}/public/organizations/${
              selectedOrganization.id
            }/giving-form/${givingFormId}/preview-image.jpg?cache=${new Date().getTime()}`}
            className="preview-image"
            alt=""
            onLoad={handleResize}
          />
        </div>
        <div className="preview-column" id="column-variant-b">
          <Text variant="h2">Form B</Text>
          <img
            src={`${envConfig?.s3Url}/public/organizations/${
              selectedOrganization.id
            }/giving-form/${givingFormId}/variant-b-preview-image.jpg?cache=${new Date().getTime()}`}
            className="preview-image"
            alt=""
            onLoad={handleResize}
          />
        </div>
      </div>
    </div>
  );
};
