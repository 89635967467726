import { FC } from 'react';
import { Avatar, AvatarProps } from '@mui/material';
import Icon from 'components/Icon';

type IconAvatarProps = AvatarProps & {
  icon: FC;
};

const IconAvatar = ({ icon, ...rest }: IconAvatarProps): JSX.Element => (
  <Avatar {...rest}>
    <Icon icon={icon} />
  </Avatar>
);

export default IconAvatar;
