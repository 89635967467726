import { TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import './AbTestPreviewCardMetrics.scss';

type AbTestPreviewCardMetricsProps = {
  rowTitle: string;
  valueA: number | string;
  valueB: number | string;
  delta: number;
};
export const AbTestPreviewCardMetrics = ({
  rowTitle,
  valueA,
  valueB,
  delta
}: AbTestPreviewCardMetricsProps) => {
  const isDelta = delta !== 0;
  const isIncreased = delta > 0 && isDelta;

  const theDelta = Math.abs(
    Math.round(delta) === 0 ? +delta.toFixed(2) : Math.round(delta)
  );
  return (
    <TableRow className="ab-test-preview-card-metrics">
      <TableCell className="ab-test-preview-card-metrics-label-container">
        <Text
          className="ab-test-preview-card-metrics-label"
          variant="caption"
          as="p"
        >
          {rowTitle}
        </Text>
      </TableCell>
      <TableCell align={isDelta ? 'left' : 'left'}>
        <Text variant="body" as="p">
          <strong>A:&nbsp;</strong>
          {valueA}
        </Text>
      </TableCell>
      <TableCell align={isDelta ? 'left' : 'left'}>
        <Text variant="body" as="p">
          <strong>B:&nbsp;</strong>
          {valueB}
        </Text>
      </TableCell>
      {isDelta && (
        <TableCell align="right">
          <Text
            variant="h4"
            as="p"
            className={clsx('ab-test-preview-card-metrics-delta', {
              increase: isIncreased,
              decrease: !isIncreased
            })}
          >
            <Icon
              fontSize="small"
              icon={isIncreased ? ICONS.ARROW_UP_RIGHT : ICONS.ARROW_DOWN_RIGHT}
            />
            {theDelta}%
          </Text>
        </TableCell>
      )}
    </TableRow>
  );
};
