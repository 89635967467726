import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState
} from 'react';
import { DeleteModalConfig } from 'components/gms/DeleteBlockModal/DeleteBlockModal';
import {
  EditorEventTypes,
  EventHub,
  EventHubEvent,
  GivingFormModes
} from 'services';
import { EmailConfig, IGivingFormConfig } from 'types';
import { useConfigContext } from './useConfigContext';

type BlockOrderUpdatePayload = {
  from: { section: 'top' | 'side' | 'bottom'; index: number };
  target: { section: 'top' | 'side' | 'bottom'; index: number };
};

// attributes exposed by the hook for interfacing with the iframe in each editor
type IframeContextType = {
  eventHub: EventHub;
  iframeHeight: string;
  embedReadyToDisplay: boolean;
  blockOrderToUpdate: string[];
  hostedPageBlockOrderToUpdate: BlockOrderUpdatePayload;
  thankYouBlockOrderToUpdate: string[];
  iframeReadyCallback(iframe: HTMLIFrameElement): EventHub;
};

type iframeProviderProps = {
  children: React.ReactNode;
  iframeSrc: string;
  setDeleteModalConfig: Dispatch<SetStateAction<DeleteModalConfig>>;
  mode: GivingFormModes;
};

export const IframeContext = createContext<IframeContextType | null>(null);

export const useEditorIframeContext = () => {
  const context = useContext(IframeContext);

  if (!context) {
    throw new Error('Something went wrong. No context found for this iframe.');
  }

  return context as IframeContextType;
};

export const IframeContextProvider = ({
  children,
  iframeSrc,
  setDeleteModalConfig,
  mode
}: iframeProviderProps) => {
  const [eventHub, setEventHub] = useState<EventHub>();
  const [iframeHeight, setIFrameHeight] = useState<string>('');
  const [embedReadyToDisplay, setEmbedReadyToDisplay] =
    useState<boolean>(false);

  const [blockOrderToUpdate, setBlockOrderToUpdate] = useState<string[]>([]);
  // TODO: ELG - this convention is for the new drag and drop, once everything is changed over we can delete the previous blockOrderToUpdate
  // When that comes, rename it
  const [hostedPageBlockOrderToUpdate, setHostedPageBlockOrderToUpdate] =
    useState<BlockOrderUpdatePayload>();
  const [thankYouBlockOrderToUpdate, setThankYouBlockOrderToUpdate] = useState<
    string[]
  >([]);
  const iframeUrl = new URL(iframeSrc);
  const {
    configData,
    setHighlightedBlock,
    setEventHub: setContextEventHub
  } = useConfigContext<IGivingFormConfig | EmailConfig>();
  const { config } = configData;

  const value: IframeContextType = useMemo(() => {
    // callback to be passed to useEventHubPreview for eventHub subscriptions
    const iframeReadyCallback = (iframe: EventTarget) => {
      const eventHubRef = new EventHub(iframe, iframeUrl.origin, {
        props: { mode }
      });

      setEventHub(eventHubRef);
      setContextEventHub(eventHubRef);

      if (config.blocks) {
        // Before data is loaded, config can be an empty object.  Using presence of `blocks` to determine if real config
        // if we have the config already, can emit right away.  Otherwise if delay getting data, emit will happen elsewhere
        eventHubRef.emit(EditorEventTypes.ConfigurationUpdate, config);
      }

      eventHubRef.subscribe(EditorEventTypes.EditBlock, (ev: EventHubEvent) => {
        setHighlightedBlock(ev.payload.id);
      });

      eventHubRef.subscribe(
        EditorEventTypes.BlockOrderUpdate,
        (ev: EventHubEvent) => {
          setHostedPageBlockOrderToUpdate(ev.payload);
          setBlockOrderToUpdate(ev.payload.blockOrder);
        }
      );

      eventHubRef.subscribe(
        EditorEventTypes.BodyHeightUpdate,
        (ev: EventHubEvent) => {
          setIFrameHeight(ev.payload);
        }
      );

      eventHubRef.subscribe(
        EditorEventTypes.EditThankYouBlock,
        (ev: EventHubEvent) => {
          setHighlightedBlock(ev.payload.id);
        }
      );

      eventHubRef.subscribe(
        EditorEventTypes.ThankYouBlockOrderUpdate,
        (ev: EventHubEvent) => {
          setThankYouBlockOrderToUpdate(ev.payload.blockOrder);
        }
      );

      eventHubRef.subscribe(EditorEventTypes.PageLoaded, () => {
        setEmbedReadyToDisplay(true);
      });

      eventHubRef.subscribe(
        EditorEventTypes.DeleteBlock,
        (ev: EventHubEvent) => {
          setDeleteModalConfig({
            open: true,
            id: ev.payload.id
          });
        }
      );

      return eventHubRef;
    };

    return {
      blockOrderToUpdate,
      hostedPageBlockOrderToUpdate,
      embedReadyToDisplay,
      eventHub,
      iframeHeight,
      iframeReadyCallback,
      thankYouBlockOrderToUpdate
    };
  }, [
    blockOrderToUpdate,
    hostedPageBlockOrderToUpdate,
    embedReadyToDisplay,
    eventHub,
    iframeHeight,
    thankYouBlockOrderToUpdate,
    iframeUrl.origin,
    setContextEventHub,
    config,
    mode,
    setHighlightedBlock,
    setDeleteModalConfig
  ]);

  return (
    <IframeContext.Provider value={value}>{children}</IframeContext.Provider>
  );
};
