import BlockBaseType from './BlockBaseType';

export enum DonorGiftOptionsType {
  DonorName = 'Donor Name',
  CompanyName = 'Company Name',
  DonorAddress = 'Donor Address',
  DonorPhone = 'Donor Phone',
  DonorEmail = 'Donor Email',
  EmailOptIn = 'Email Opt-In',
  DonationAmount = 'Donation Amount',
  TransactionFee = 'Transaction Fee',
  Designation = 'Designation',
  AnonymousGift = 'Anonymous Gift',
  PaymentMethod = 'Payment Method',
  PaymentExpiration = 'Payment Expiration'
}

export enum ScheduleOptionsType {
  BillingFrequency = 'Billing Frequency',
  BillingStartDate = 'Billing Start Date',
  ScheduleNumber = 'Schedule Number'
}

export enum TodaysTransactionOptionsType {
  AuthorizationNumber = 'Authorization Number',
  TransactionDate = 'Transaction Date',
  TransactionAmount = 'Transaction Amount'
}

export enum BlockDetailType {
  DonorInformation = 'DonorInformation',
  ScheduleInformation = 'ScheduleInformation',
  TodaysTransaction = 'TodaysTransaction',
  CustomFields = 'CustomFields'
}

export interface IThankYouMessageDonationDetailsBlock extends BlockBaseType {
  detailType: BlockDetailType;
  placeholders:
    | DonorGiftOptionsType[]
    | ScheduleOptionsType[]
    | TodaysTransactionOptionsType[]
    | string[]; // CustomFields is dynamic based on user defined CustomField values
}
