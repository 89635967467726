import { ReactNode } from 'react';
import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import './DashboardCard.scss';

type DashboardCardProps = {
  children: ReactNode;
  isActiveCampaignsCard?: boolean;
};

export const DashboardCard = ({
  children,
  isActiveCampaignsCard
}: DashboardCardProps): JSX.Element => (
  <Grid item md={6}>
    <Box
      className={clsx('dashboard-card', {
        'active-campaigns-card': isActiveCampaignsCard
      })}
    >
      {children}
    </Box>
  </Grid>
);
