import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import OptionalSection from 'components/EditorMenus/ElementLibrary/OptionalSection';
import Text from 'components/Text';
import { LimitedTextField, useLimitedLength } from 'components/TextField';
import { useConfigContext } from 'hooks/useConfigContext';
import { BlockTypes, ICorporateMatchingBlock, IGivingFormConfig } from 'types';
import './EditCorporateMatching.scss';

const EditCorporateMatchingSchema = yup.object({
  prompt: yup.string().required('Prompt is required')
});

const EditCorporateMatchingInternal = (): JSX.Element => {
  const maxPromptLength = 200;
  const {
    configData: { config },
    updateConfig
  } = useConfigContext<IGivingFormConfig>();
  const { control } = useFormContext();

  const initialCorporateMatchingBlock = config.blocks.find(
    (block) => block.blockType === BlockTypes.CorporateMatchingBlock
  ) as ICorporateMatchingBlock;
  const [corporateMatchingSelected, setCorporateMatchingSelected] = useState(
    !!initialCorporateMatchingBlock
  );
  const [promptValue, setPromptValue] = useLimitedLength(
    maxPromptLength,
    initialCorporateMatchingBlock?.prompt ||
      'See if your employer will match your donation!'
  );

  const toggleCorporateMatchingEnabled = (enabled: boolean) => {
    const newConfig = { ...config };
    if (enabled) {
      const id = uuid();
      const newCorporateMatchingBlock: ICorporateMatchingBlock = {
        blockType: BlockTypes.CorporateMatchingBlock,
        id,
        prompt: promptValue
      };
      newConfig.blocks.push(newCorporateMatchingBlock);
    } else {
      newConfig.blocks = newConfig.blocks.filter(
        (block) => block.blockType !== BlockTypes.CorporateMatchingBlock
      );
    }
    updateConfig(newConfig);
  };

  const updatePromptValue = (updatedPrompt: string) => {
    const newConfig = { ...config };
    const corporateMatchingBlock = newConfig.blocks.find(
      (block) => block.blockType === BlockTypes.CorporateMatchingBlock
    ) as ICorporateMatchingBlock;
    corporateMatchingBlock.prompt = updatedPrompt;
    updateConfig(newConfig);
  };

  return (
    <div className="corporate-matching">
      <OptionalSection
        initiallySelected={corporateMatchingSelected}
        title="Enable Corporate Matching"
        titleVariant="h3"
        onChange={(enabled) => {
          toggleCorporateMatchingEnabled(enabled);
          setCorporateMatchingSelected(!corporateMatchingSelected);
        }}
      >
        <Text className="corporate-matching-prompt-label" variant="h5">
          * Prompt
        </Text>
        <Controller
          name="prompt"
          control={control}
          defaultValue={promptValue || ''}
          render={({
            field: { ref, onChange, onBlur, ...field },
            fieldState: { error }
          }) => (
            <LimitedTextField
              {...field}
              placeholder="Prompt Text Here"
              hiddenLabel
              fullWidth
              multiline
              minRows={5}
              maxChar={maxPromptLength}
              error={!!error}
              onChange={(e) => {
                onChange(e.target.value);
                setPromptValue(e.target.value);
              }}
              onBlur={(e) => {
                onBlur();
                updatePromptValue(e.target.value);
              }}
            />
          )}
        />
      </OptionalSection>
    </div>
  );
};

export const EditCorporateMatching = (): JSX.Element => {
  const methods = useForm({
    resolver: yupResolver(EditCorporateMatchingSchema),
    mode: 'onTouched'
  });

  return (
    <FormProvider {...methods}>
      <EditCorporateMatchingInternal />
    </FormProvider>
  );
};
