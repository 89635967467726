import { useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import flaskGif from 'assets/images/science-flask.gif';
import {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentDescription,
  DialogTitle
} from 'components/Dialog';
import { useAlerts } from 'hooks';
import { useAbTestDraft } from 'hooks/useAbTestDraft';
import { useAppContext } from 'hooks/useAppContext';
import { useCreateAbTestMutation } from 'queries/UseAbTests';
import { AbTest } from 'types';
import SuccessDialog, { SuccessDialogVariants } from '../SuccessDialog';
import './PublishAbTestDialog.scss';

type PublishAbTestDialogProps = {
  open: boolean;
  setPublishDialogOpen: (arg: boolean) => void;
};

// a three-part dialog for publishing an A/B Test
// Confirmation -> Loading -> Success
export const PublishAbTestDialog = ({
  open,
  setPublishDialogOpen
}: PublishAbTestDialogProps) => {
  const navigate = useNavigate();
  const [addAlert] = useAlerts();
  const {
    draft: { config },
    discardDraft
  } = useAbTestDraft();
  const [publishedAbTest, setPublishedAbTest] = useState<AbTest>();
  const [isPublished, setIsPublished] = useState(false);
  const {
    selectedOrganization: { id: selectedOrganizationId }
  } = useAppContext();

  const {
    name,
    description,
    assumption,
    thresholds,
    givingForm,
    configVariantB
  } = config;

  const { mutateAsync: createAbTestMutation, isLoading } =
    useCreateAbTestMutation(selectedOrganizationId, {
      name,
      description,
      assumption,
      thresholds,
      givingFormId: givingForm.id,
      configVariantB
    });

  const onClose = () => {
    // prevent user from closing dialog while awaiting response
    // from backend (step 2)
    if (isLoading) return;
    // step 3
    if (isPublished) navigate('/strategy/ab-test');
    // step 1
    setPublishDialogOpen(false);
  };

  const handleCreateAbTest = async () => {
    try {
      const newAbTest = await createAbTestMutation();
      setIsPublished(true);
      setPublishedAbTest(newAbTest);
      discardDraft(); // discards the A/B Test draft and givingFormVariantB draft
    } catch (e) {
      const { response } = e as AxiosError;
      onClose();
      if (response.status === 403) {
        // redirect the user away from the wizard if there is an active
        // a/b test for their current giving form because there is no further
        // action they can take in the wizard
        navigate('/strategy/ab-test', {
          state: { abTestPublishErrorMessage: response.data.message }
        });
        discardDraft(); // discards the A/B Test draft and givingFormVariantB draft
      } else {
        addAlert({
          // do we want a more specific error messaging when fails to publish
          title: 'There was an error publishing your A/B Test.',
          severity: 'error'
        });
      }
    }
  };

  const onPrimaryClick = () => {
    // no buttons in step 2
    // step 3
    if (isPublished) {
      navigate(`/strategy/ab-test/${publishedAbTest.abTestId}/overview`);
    } else {
      // step 1
      handleCreateAbTest();
    }
  };

  const getVariant = () => {
    // step 2
    if (isLoading) return SuccessDialogVariants.LOADING;
    // step 3
    if (isPublished) return SuccessDialogVariants.SUCCESS;
    // step 1
    return SuccessDialogVariants.CONFIRMATION;
  };

  const renderDialogContentDescription = () => {
    if (isPublished) {
      return (
        // step 3
        <DialogContentDescription>
          Your A/B Test has started! You can no longer make edits.
          <span className="dialog-content-text">
            {`This A/B is now available in your ${
              config?.campaign?.name || ''
            } campaign home. 
          Continue to check in on the results by heading to the Overview so 
          you can see which form is performing better!`}
          </span>
        </DialogContentDescription>
      );
      // eslint-disable-next-line no-else-return
    } else if (isLoading) {
      return (
        // step 2
        <DialogContentDescription>
          <img
            className="flask-gif"
            src={flaskGif}
            alt="animated science flask"
          />
          <span className="dialog-content-text publish-dialog-loading">
            Hang tight!
          </span>
          <span className="dialog-content-text publish-dialog-loading">
            Publishing Your Test...
          </span>
        </DialogContentDescription>
      );
    }
    return (
      // step 1
      <DialogContentDescription>
        You can no longer make edits after publishing this test.
        <span className="dialog-content-text">
          In order to ensure your A/B test produces accurate results, you will
          not be able to make changes after publishing. Do you wish to continue?
        </span>
      </DialogContentDescription>
    );
  };

  return (
    <SuccessDialog
      className="publish-ab-test-dialog"
      label="publish A/B Test"
      onClose={() => onClose()}
      open={open}
      variant={getVariant()}
    >
      {/* no title in step 2 */}
      {!isLoading && (
        <DialogTitle onClick={() => onClose()}>
          {isPublished ? 'Congratulations!' : 'Are you sure?'}
        </DialogTitle>
      )}
      <DialogContent>{renderDialogContentDescription()}</DialogContent>
      {/* no buttons in step 2 */}
      {!isLoading && (
        <DialogActions>
          <DialogActionButton
            onClick={() => onPrimaryClick()}
            variant="primary"
          >
            {isPublished ? 'A/B Test Overview' : 'Yes, Publish Test'}
          </DialogActionButton>
          <DialogActionButton onClick={() => onClose()} variant="secondary">
            {isPublished ? 'View All A/B Tests' : 'Back to Review'}
          </DialogActionButton>
        </DialogActions>
      )}
    </SuccessDialog>
  );
};
