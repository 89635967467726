import Text from 'components/Text';
import WYSIWYG, { Tag, TagSection } from 'components/WYSIWYG';
import { validateHeader } from 'components/WYSIWYG/WYSIWYGUtils';
import { useConfigContext } from 'hooks/useConfigContext';
import { BlockTypes, IGivingFormConfig, IThankYouHeader } from 'types';
import { IThankYouMessageBody } from 'types/givingFormTypes/ThankYouMessageBody';
import { createTagList } from 'utils/placeholderUtils';
import './ThankYouHeader.scss';

const ThankYouHeader = () => {
  const {
    updateConfig,
    configData: { config }
  } = useConfigContext<IGivingFormConfig>();

  const onEditorChange = (newContent: string) => {
    const newConfig = { ...config };
    if (newContent) {
      if (
        newConfig.thankYouConfig.blocks[0]?.blockType ===
        BlockTypes.ThankYouHeaderBlock
      ) {
        // update header
        (newConfig.thankYouConfig.blocks[0] as IThankYouHeader).html =
          newContent;
      } else {
        // add header
        newConfig.thankYouConfig.blocks.unshift({
          id: 'thankYouHeader',
          blockType: BlockTypes.ThankYouHeaderBlock,
          html: newContent
        });
      }
    } else if (
      newConfig.thankYouConfig.blocks[0]?.blockType ===
      BlockTypes.ThankYouHeaderBlock
    ) {
      // remove header
      newConfig.thankYouConfig.blocks.shift();
    }
    if (validateHeader({ config: newConfig })) {
      updateConfig(newConfig);
    }
  };

  return (
    <div className="ty-header">
      <Text variant="h3" className="ty-header-title">
        Message
      </Text>
      <WYSIWYG
        defaultStyles={{
          fontFamily: config.theme.font.formBody
        }}
        onBlur={onEditorChange}
        value={
          config.thankYouConfig.blocks[0].blockType ===
          BlockTypes.ThankYouHeaderBlock
            ? (config.thankYouConfig.blocks[0] as IThankYouMessageBody).html
            : null
        }
        tagList={createTagList(config, true) as TagSection[]}
        tags={createTagList(config) as Tag[]}
      />
    </div>
  );
};

export default ThankYouHeader;
