import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentTitle,
  DialogTitle
} from 'components/Dialog';
import Icon, { ICONS } from 'components/Icon';
import Text from 'components/Text';
import './TemplatePublishWarningDialog.scss';

type TemplatePublishWarningDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

/**
 * A GMS-specific dialog for warning the user that the current giving form has unpublished changes
 * that won't be saved to the new template
 */
export const TemplatePublishWarningDialog = ({
  open,
  onConfirm,
  onClose
}: TemplatePublishWarningDialogProps) => (
  <Dialog
    className="template-publish-warning-dialog"
    label="save template publish warning dialog"
    open={open}
    onClose={() => onClose()}
  >
    <DialogTitle className="dialog-title" onClick={(close) => close()}>
      Save Template
    </DialogTitle>
    <DialogContent>
      <DialogContentTitle>
        <Icon
          className="warning-icon"
          viewBox="0 0 24 15"
          icon={ICONS.ALERT_TRIANGLE}
        />{' '}
        You have unpublished changes
      </DialogContentTitle>
      <Text variant="body" className="body-description">
        Your recent Giving Form edits will not be visible in this new template
        unless you publish these changes first.
      </Text>

      <Text variant="h7">
        Would you like to publish these changes before saving your template?
      </Text>
    </DialogContent>
    <DialogActions>
      <DialogActionButton variant="primary" onClick={(close) => close()}>
        Yes, Publish Changes
      </DialogActionButton>
      <DialogActionButton
        variant="secondary"
        onClick={(close) => {
          onConfirm();
          close();
        }}
      >
        No, Save Without Changes
      </DialogActionButton>
    </DialogActions>
  </Dialog>
);
