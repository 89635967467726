import { AxiosError } from 'axios';
import axios from 'services/axiosClient';
import {
  CampaignEvent,
  CampaignFieldSets,
  CampaignGivingForm,
  CampaignPeerToPeer,
  CampaignPostBody,
  CampaignTextToGive,
  CampaignType,
  CampaignUpdateBody,
  ChannelSummary,
  PerChannelCampaignMetrics
} from './types';

export const getAllCampaignsService = async (
  organizationId: string
): Promise<CampaignType[]> => {
  try {
    const { data } = await axios.get(
      `/campaigns?organizationId=${organizationId}`
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your campaigns.'
    );
  }
};

export const getCampaignByIdService = async (
  organizationId: string,
  campaignId: string
): Promise<CampaignType> => {
  try {
    const { data } = await axios.get(
      `/campaigns/${campaignId}?organizationId=${organizationId}`
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your campaign.'
    );
  }
};

export const getCampaignGivingForms = async (
  organizationId: string,
  campaignId: string
): Promise<CampaignGivingForm[]> => {
  try {
    const { data } = await axios.get('/campaigns/giving-forms', {
      params: {
        organizationId,
        campaignId
      }
    });
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your giving forms.'
    );
  }
};

export const getCampaignPeerToPeers = async (
  organizationId: string,
  campaignId: string
): Promise<CampaignPeerToPeer[]> => {
  try {
    const { data } = await axios.get('/campaigns/peer-to-peers', {
      params: {
        organizationId,
        campaignId
      }
    });
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your peer to peers.'
    );
  }
};

export const getCampaignTextToGive = async (
  organizationId: string,
  campaignId: string
): Promise<CampaignTextToGive[]> => {
  try {
    const { data } = await axios.get('/campaigns/sms-keywords', {
      params: {
        organizationId,
        campaignId
      }
    });
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your peer to peers.'
    );
  }
};

export const getCampaignEvents = async (
  organizationId: string,
  campaignId: string
): Promise<CampaignEvent[]> => {
  try {
    const { data } = await axios.get('/campaigns/events', {
      params: {
        organizationId,
        campaignId
      }
    });
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your events.'
    );
  }
};

export const getAllCampaignChannelMetrics = async (
  organizationId: string,
  campaignId: string,
  startDate: string,
  endDate?: string
): Promise<ChannelSummary> => {
  try {
    const { data } = await axios.get(
      `/campaigns/${campaignId}/channel-metrics`,
      {
        params: {
          organizationId,
          startDate,
          endDate
        }
      }
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your metrics.'
    );
  }
};

export const getCampaignPerChannelMetrics = async (
  organizationId: string,
  campaignId: string
): Promise<PerChannelCampaignMetrics> => {
  try {
    const { data } = await axios.get(
      `/campaigns/${campaignId}/per-channel-metrics`,
      {
        params: {
          organizationId
        }
      }
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching your channel metrics.'
    );
  }
};

export const createCampaignService = async ({
  organizationId,
  title,
  startDate,
  endDate,
  goalAmount,
  designationIds
}: CampaignPostBody): Promise<CampaignType> => {
  const postData = {
    organizationId,
    title,
    startDate,
    endDate,
    goal: goalAmount,
    designationIds
  };
  try {
    const { data } = await axios.post('/campaigns', postData);
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message || 'Error with campaign service'
    );
  }
};

export const getFieldSetByIdService = async (
  organizationId: string
): Promise<CampaignFieldSets> => {
  try {
    const { data } = await axios.get(
      `/organizations/${organizationId}/field-sets`
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error fetching custom fields for your campaign.'
    );
  }
};

export const updateCampaign = async (
  organizationId: string,
  campaignId: string,
  body: CampaignUpdateBody
): Promise<CampaignType> => {
  try {
    const { data } = await axios.patch(
      `/campaigns/${campaignId}?organizationId=${organizationId}`,
      body
    );
    return data;
  } catch (e) {
    throw Error(
      (e as AxiosError).response.data.message ||
        'There was an error archiving your campaign.'
    );
  }
};
