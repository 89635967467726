/* eslint-disable no-useless-escape */

export class Url {
  href?: string;

  origin?: string;

  pathWithExtras?: string;

  path?: string;

  extras?: string;

  constructor(url: string) {
    const match = url.match(
      /^(http(?:s)?\:\/\/[^\/]+)(((?:\/)?[^\?\#]+)?([\?\#].*)?)$/
    );
    if (match) {
      const [href, origin, pathWithExtras, path, extras] = match;
      Object.assign(this, { href, origin, pathWithExtras, path, extras });
    }
  }
}
