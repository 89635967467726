import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Stack
} from '@mui/material';
import { ICONS } from 'components';
import Button from 'components/Button';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import Select, { SelectOption } from 'components/Select';
import Text from 'components/Text';
import TextField from 'components/TextField';
import DeleteBlockModal, {
  DeleteModalConfig
} from 'components/gms/DeleteBlockModal/DeleteBlockModal';
import { useAlerts } from 'hooks/useAlerts/useAlerts';
import { useAppContext } from 'hooks/useAppContext';
import { useConfigContext } from 'hooks/useConfigContext';
import { useOrgFeatures } from 'hooks/useOrgFeatures';
import { useGetFieldSetById } from 'queries/UseCampaigns';
import { CampaignFieldSets } from 'services/types/campaignTypes';
import {
  BlockBaseType,
  BlockTypes,
  ICustomFieldBlock,
  IGivingFormConfig
} from 'types';
import {
  BlockDetailType,
  IThankYouMessageDonationDetailsBlock
} from 'types/givingFormTypes/ThankYouMessageDonationDetailsBlock';
import {
  CustomFieldCurrencyInput,
  CustomFieldDateInput,
  CustomFieldDropdownInput,
  CustomFieldNumberInput,
  CustomFieldSingleChoiceInput,
  CustomFieldTextAreaInput,
  CustomFieldTextInput
} from './EditCustomFieldInputTypes';
import './EditCustomFields.scss';

const NEW_FIELD_INDEX = -1;

const LEGACY_FIELD_MAPPING_OPTIONS: SelectOption[] = [
  {
    label: 'Custom Note 1',
    value: 'custom_note_1'
  },
  {
    label: 'Custom Note 2',
    value: 'custom_note_2'
  },
  {
    label: 'Custom Note 3',
    value: 'custom_note_3'
  },
  {
    label: 'Custom Note 4',
    value: 'custom_note_4'
  },
  {
    label: 'Custom Note 5',
    value: 'custom_note_5'
  },
  {
    label: 'Designation Note',
    value: 'designation_note'
  }
];

export type CustomFieldInputTypesProps = {
  customField?: ICustomFieldBlock;
  onUpdate: (block: ICustomFieldBlock) => void;
  name: string;
  legacyFieldMapping: string;
  nameError: boolean;
};

type EditCustomFieldProps = {
  customField?: ICustomFieldBlock;
  setOpenIndex: Dispatch<SetStateAction<number>>;
  onUpdate: (block: ICustomFieldBlock) => void;
  onDelete: () => void;
  open: boolean;
  index: number;
  customFieldNames: string[];
  availableLegacyOptions: SelectOption[];
};

const EditCustomField = ({
  customField,
  open,
  index,
  availableLegacyOptions,
  setOpenIndex,
  onUpdate,
  onDelete,
  customFieldNames
}: EditCustomFieldProps): JSX.Element => {
  const [addAlert] = useAlerts();
  const [name, setName] = useState<string>(customField?.name ?? '');
  const [isNameTouched, setIsNameTouched] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>(
    customField?.fieldInfo.type || ''
  );
  const [legacyFieldMapping, setLegacyFieldMapping] = useState<string>(
    customField?.legacyFieldMapping ?? ''
  );
  const features = useOrgFeatures();

  const { selectedOrganization } = useAppContext();

  const [fieldSets, setFieldSets] = useState<CampaignFieldSets>([]);

  const { data: fieldSetData, isError } = useGetFieldSetById(
    selectedOrganization.id
  );

  useEffect(() => {
    if (fieldSetData) {
      setFieldSets(fieldSetData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldSetData]);

  useEffect(() => {
    if (isError) {
      addAlert({
        title:
          'Uh oh. Looks like there was an error loading custom fields for this campaign.',
        severity: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const INPUT_TYPE_OPTIONS: SelectOption[] = [
    {
      label: 'Short Text',
      value: 'text',
      icon: ICONS.TYPOGRAPHY
    },
    {
      label: 'Paragraph',
      value: 'textarea',
      icon: ICONS.FILE_TEXT
    },
    {
      label: 'Single Choice',
      value: 'singleChoice',
      icon: ICONS.RADIO_BUTTON
    },
    {
      label: 'Dropdown',
      value: 'dropdown',
      icon: ICONS.DROPDOWN
    },
    {
      label: 'Date',
      value: 'date',
      icon: ICONS.CALENDAR
    },
    {
      label: 'Number',
      value: 'number',
      icon: ICONS.HASH
    },
    {
      label: 'Currency',
      value: 'currency',
      icon: ICONS.DOLLAR
    }
  ];

  const getInputTypeRenderedValue = () => {
    if (inputType === '') {
      return (
        <Text id="input-type-placeholder" variant="body">
          Select
        </Text>
      );
    }
    const selection = INPUT_TYPE_OPTIONS.find(
      (option) => option.value === inputType
    );
    return (
      <Stack direction="row" spacing={0.5}>
        <Icon icon={selection.icon} />
        <Text variant="body">{selection.label}</Text>
      </Stack>
    );
  };

  const getLegacyFieldMappingRenderedValue = () => {
    if (!legacyFieldMapping) {
      return <Text variant="body">Select</Text>;
    }
    const selection = availableLegacyOptions.find(
      (option) => option.value === legacyFieldMapping
    );
    return <Text variant="body">{selection.label}</Text>;
  };

  let nameError: string;
  if (name === '') {
    nameError = 'Field name is required';
  } else if (
    customField
      ? customFieldNames
          .filter((_name) => _name !== customField.name)
          .includes(name)
      : customFieldNames.includes(name)
  ) {
    nameError = 'Field name must be unique';
  }

  const FormContentComponents: {
    [key: string]: React.FC<CustomFieldInputTypesProps>;
  } = {
    text: CustomFieldTextInput,
    textarea: CustomFieldTextAreaInput,
    number: CustomFieldNumberInput,
    currency: CustomFieldCurrencyInput,
    date: CustomFieldDateInput,
    singleChoice: CustomFieldSingleChoiceInput,
    dropdown: CustomFieldDropdownInput
  };

  const renderInputForm = () => {
    const FormContentComponent = FormContentComponents?.[inputType];
    if (FormContentComponent) {
      return (
        <FormContentComponent
          customField={customField}
          onUpdate={onUpdate}
          name={name}
          legacyFieldMapping={legacyFieldMapping}
          nameError={!!nameError}
        />
      );
    }
    return null;
  };

  const autocompleteFieldSetOptions = Array.from(
    new Set(fieldSets.map((f) => f.fieldNames).flat())
  ).filter((f) => !customFieldNames.includes(f));

  return (
    <Accordion className="EditCustomFields" expanded={open}>
      <AccordionSummary
        className={`EditCustomFields-summary ${open ? 'expanded' : ''}`}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={0.25} alignItems="center">
            <Text className="EditCustomFields-fieldName" variant="h5">
              {open ? 'Name This Custom Field' : customField?.name || ''}
            </Text>
          </Stack>
          <Stack
            className="EditCustomFields-actionButtons"
            direction="row"
            justifyContent="flex-end"
          >
            {!open && (
              <IconButton
                label="edit"
                variant="basic"
                size="small"
                icon={ICONS.PENCIL}
                onClick={() => setOpenIndex(index)}
              />
            )}
            <IconButton
              label="delete"
              variant="basic"
              size="small"
              icon={ICONS.TRASH}
              onClick={() => {
                onDelete();
              }}
            />
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        className={`EditCustomFields-details ${open ? 'expanded' : ''}`}
      >
        <Stack spacing={1}>
          <Autocomplete
            freeSolo
            autoSelect
            clearOnEscape
            disableClearable
            options={autocompleteFieldSetOptions}
            value={name}
            onChange={(event, value) => {
              setName(value as string);
              setIsNameTouched(true);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                hiddenLabel
                placeholder="Custom Field Name Here"
                onChange={(e) => {
                  setName(e.target.value as string);
                  setIsNameTouched(true);
                }}
                error={!!nameError && isNameTouched}
                helperText={isNameTouched ? nameError : undefined}
              />
            )}
          />

          {features.legacyCustomFieldMapping && (
            <>
              <Text variant="h5">Legacy Field Mapping</Text>
              <Select
                options={availableLegacyOptions}
                value={legacyFieldMapping}
                displayEmpty
                onChange={(e) =>
                  setLegacyFieldMapping(e.target.value as string)
                }
                renderValue={getLegacyFieldMappingRenderedValue}
              />
            </>
          )}

          <Text variant="h5">Select Input Type</Text>
          <Select
            options={INPUT_TYPE_OPTIONS}
            value={inputType}
            displayEmpty
            onChange={(e) => setInputType(e.target.value as string)}
            renderValue={getInputTypeRenderedValue}
          />
          {inputType ? (
            renderInputForm()
          ) : (
            <Button variant="primary" fullWidth disabled>
              Update
            </Button>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const EditCustomFields = (): JSX.Element => {
  const { configData, updateConfig, highlightedBlock, setHighlightedBlock } =
    useConfigContext<IGivingFormConfig>();
  const [addAlert] = useAlerts();

  const customFields: ICustomFieldBlock[] =
    (configData?.config?.blocks?.filter(
      (block) => block.blockType === BlockTypes.CustomFieldBlock
    ) as ICustomFieldBlock[]) || [];
  const customFieldNames = customFields.map((field) => field.name);

  const [openIndex, setOpenIndex] = useState<number | null>(
    customFields.indexOf(
      customFields.find((customField) => customField.id === highlightedBlock)
    )
  );
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [deleteModalConfig, setDeleteModalConfig] = useState<DeleteModalConfig>(
    {
      open: false
    }
  );

  useEffect(() => {
    const newIndex = customFields.indexOf(
      customFields.find((customField) => customField.id === highlightedBlock)
    );
    if (newIndex !== -1) {
      setOpenIndex(newIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedBlock]);

  useEffect(() => {
    if (openIndex !== NEW_FIELD_INDEX) {
      setIsAdding(false);
    }
    if (customFields?.[openIndex]?.id) {
      setHighlightedBlock(customFields[openIndex].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openIndex]);

  const closeModal = () => setDeleteModalConfig({ open: false });

  const updateCustomFieldBlock = (newBlock: ICustomFieldBlock) => {
    const newBlocks = configData.config.blocks;
    const oldBlock = newBlocks.find(
      (block) => block.id === newBlock.id
    ) as ICustomFieldBlock;
    const blockIndex = newBlocks.indexOf(oldBlock);
    newBlocks.splice(blockIndex, 1, newBlock);
    const newConfig: IGivingFormConfig = {
      ...configData.config,
      blocks: newBlocks
    };

    // If the name has changed, then we need to check if we have to update the custom field TY block
    if (newBlock.name !== oldBlock.name) {
      const tyDetailsCustomFieldsBlock: IThankYouMessageDonationDetailsBlock =
        newConfig.thankYouConfig.blocks.find(
          (block) =>
            block.blockType ===
              BlockTypes.ThankYouMessageDonationDetailsBlock &&
            (block as IThankYouMessageDonationDetailsBlock).detailType ===
              BlockDetailType.CustomFields
        ) as IThankYouMessageDonationDetailsBlock;
      const placeholders = tyDetailsCustomFieldsBlock?.placeholders as string[];
      if (placeholders?.includes(oldBlock.name)) {
        placeholders.splice(
          placeholders.indexOf(oldBlock.name),
          1,
          newBlock.name
        );
      }
    }

    updateConfig(newConfig);
  };

  const addCustomFieldBlock = (block: ICustomFieldBlock) => {
    const newBlocks = [...configData.config.blocks, block];
    const newConfig = {
      ...configData.config,
      blocks: newBlocks
    };
    updateConfig(newConfig);
    setOpenIndex(customFields.length);
    setHighlightedBlock(block.id);
  };

  const handleDelete = () => {
    const { id } = deleteModalConfig;
    const oldBlock = configData.config.blocks.find(
      (block) => block.id === id
    ) as ICustomFieldBlock;
    const newBlocks = configData.config.blocks.filter(
      (block: BlockBaseType) => block.id !== id
    );
    const newConfig = {
      ...configData.config,
      blocks: newBlocks
    };

    // Need to check if we have to delete the custom field in the corresponding TY block
    const tyDetailsCustomFieldsBlock: IThankYouMessageDonationDetailsBlock =
      newConfig.thankYouConfig.blocks.find(
        (block) =>
          block.blockType === BlockTypes.ThankYouMessageDonationDetailsBlock &&
          (block as IThankYouMessageDonationDetailsBlock).detailType ===
            BlockDetailType.CustomFields
      ) as IThankYouMessageDonationDetailsBlock;
    const placeholders = tyDetailsCustomFieldsBlock?.placeholders as string[];
    if (placeholders?.includes(oldBlock.name)) {
      addAlert({
        title: 'Thank You Message Updated',
        description:
          'The custom field you removed has also been removed from the Custom Fields Thank You Message section.'
      });
      placeholders.splice(placeholders.indexOf(oldBlock.name), 1);
      if (placeholders.length === 0) {
        newConfig.thankYouConfig.blocks.splice(
          newConfig.thankYouConfig.blocks.indexOf(tyDetailsCustomFieldsBlock),
          1
        );
      }
    }

    updateConfig(newConfig);
    setOpenIndex(null);
    setHighlightedBlock('');
    closeModal();
  };

  const getUnusedLegacyFields = (currentIndex: number) => {
    const usedLegacyFields = customFields
      .filter(
        (customField, index) =>
          index !== currentIndex && customField.legacyFieldMapping
      )
      .map((customField) => customField.legacyFieldMapping);
    return LEGACY_FIELD_MAPPING_OPTIONS.filter(
      (option) => !usedLegacyFields.includes(option.value)
    );
  };

  return (
    <Stack id="ElementLibrary-EditCustomFields">
      <div id="ElementLibrary-EditCustomFields--optionsContainer">
        {customFields.map((customField, idx) => (
          <EditCustomField
            availableLegacyOptions={getUnusedLegacyFields(idx)}
            customField={customField}
            open={openIndex === idx}
            setOpenIndex={setOpenIndex}
            onUpdate={updateCustomFieldBlock}
            onDelete={() =>
              setDeleteModalConfig({
                open: true,
                id: customField.id
              })
            }
            index={idx}
            key={customField.id}
            customFieldNames={customFieldNames}
          />
        ))}
        {isAdding && (
          <EditCustomField
            open={isAdding}
            availableLegacyOptions={getUnusedLegacyFields(-1)}
            setOpenIndex={setOpenIndex}
            onUpdate={addCustomFieldBlock}
            onDelete={() => setIsAdding(false)}
            index={NEW_FIELD_INDEX}
            key="newCustomField"
            customFieldNames={customFieldNames}
          />
        )}
      </div>
      {!isAdding && (
        <Stack
          id="ElementLibrary-EditCustomFields--addOption"
          direction="row"
          spacing={0.25}
          alignItems="center"
        >
          <Icon icon={ICONS.PLUS} fontSize="small" />
          <Text
            variant="h4"
            onClick={() => {
              setIsAdding(true);
              setHighlightedBlock('');
              setOpenIndex(NEW_FIELD_INDEX);
            }}
          >
            Add New Custom Field
          </Text>
        </Stack>
      )}

      <DeleteBlockModal
        onConfirm={handleDelete}
        onClose={closeModal}
        open={deleteModalConfig.open}
      />
    </Stack>
  );
};

export default EditCustomFields;
