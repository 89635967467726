import { ReactComponent as Bodoni } from 'assets/icons/fontSelectIcons/BodoniFont.svg';
import { ReactComponent as CrimsonPro } from 'assets/icons/fontSelectIcons/Crimsontext.svg';
import { ReactComponent as EbGaramond } from 'assets/icons/fontSelectIcons/EBGaramond.svg';
import { ReactComponent as Helvetica } from 'assets/icons/fontSelectIcons/Helvetica.svg';
import { ReactComponent as HelveticaNeue } from 'assets/icons/fontSelectIcons/HelveticaNeue.svg';
import { ReactComponent as Jost } from 'assets/icons/fontSelectIcons/Jost.svg';
import { ReactComponent as Kameron } from 'assets/icons/fontSelectIcons/Kameron.svg';
import { ReactComponent as LibreBaskerville } from 'assets/icons/fontSelectIcons/LibreBaskerville.svg';
import { ReactComponent as LibreFranklin } from 'assets/icons/fontSelectIcons/LibreFranklin.svg';
import { ReactComponent as Montserrat } from 'assets/icons/fontSelectIcons/Montserrat.svg';
import { ReactComponent as Nunito } from 'assets/icons/fontSelectIcons/Nunito.svg';
import { ReactComponent as Poppins } from 'assets/icons/fontSelectIcons/Poppins.svg';
import { ReactComponent as TimesNewRoman } from 'assets/icons/fontSelectIcons/TimesNewRoman.svg';
import { ReactComponent as Tinos } from 'assets/icons/fontSelectIcons/Tinos.svg';
import Icon from 'components/Icon';
import './FontIcon.scss';

const BodoniIcon = () => (
  <Icon
    className="FontIcon"
    icon={Bodoni}
    viewBox="76 5 50 21"
    sx={{ strokeWidth: 0 }}
  />
);

const CrimsonProIcon = () => (
  <Icon
    className="FontIcon"
    icon={CrimsonPro}
    viewBox="60 -4 50 20"
    sx={{ strokeWidth: 0 }}
  />
);

const EbGaramondIcon = () => (
  <Icon
    className="FontIcon"
    icon={EbGaramond}
    viewBox="60 -4 50 20"
    sx={{ strokeWidth: 0 }}
  />
);

const HelveticaIcon = () => (
  <Icon
    className="FontIcon"
    icon={Helvetica}
    viewBox="58 -4 50 20"
    sx={{ strokeWidth: 0 }}
  />
);

const HelveticaNeueIcon = () => (
  <Icon
    className="FontIcon"
    icon={HelveticaNeue}
    viewBox="59 -4 50 20"
    sx={{ strokeWidth: 0 }}
  />
);

const JostIcon = () => (
  <Icon
    className="FontIcon"
    icon={Jost}
    viewBox="59 -4 50 20"
    sx={{ strokeWidth: 0 }}
  />
);

const KameronIcon = () => (
  <Icon
    className="FontIcon"
    icon={Kameron}
    viewBox="63 -6 50 21"
    sx={{ strokeWidth: 0 }}
  />
);

const LibreBaskervilleIcon = () => (
  <Icon
    className="FontIcon"
    icon={LibreBaskerville}
    viewBox="63 -4 50 21"
    sx={{ strokeWidth: 0 }}
  />
);

const LibreFranklinIcon = () => (
  <Icon
    className="FontIcon"
    icon={LibreFranklin}
    viewBox="63 -4 50 21"
    sx={{ strokeWidth: 0 }}
  />
);

const MontserratIcon = () => (
  <Icon
    className="FontIcon"
    icon={Montserrat}
    viewBox="63 -4 50 21"
    sx={{ strokeWidth: 0 }}
  />
);

const NunitoIcon = () => (
  <Icon
    className="FontIcon"
    icon={Nunito}
    viewBox="63 -5 50 21"
    sx={{ strokeWidth: 0 }}
  />
);

const PoppinsIcon = () => (
  <Icon
    className="FontIcon"
    icon={Poppins}
    viewBox="63 -4 50 21"
    sx={{ strokeWidth: 0 }}
  />
);

const TimesNewRomanIcon = () => (
  <Icon
    className="FontIcon"
    icon={TimesNewRoman}
    viewBox="63 -5 50 21"
    sx={{ strokeWidth: 0 }}
  />
);

const TinosIcon = () => (
  <Icon
    className="FontIcon"
    icon={Tinos}
    viewBox="63 -5 50 21"
    sx={{ strokeWidth: 0 }}
  />
);

export const ICONS = {
  BODONI: BodoniIcon,
  CRIMSONPRO: CrimsonProIcon,
  EBGARAMOND: EbGaramondIcon,
  HELVETICA: HelveticaIcon,
  HELVETICANEUE: HelveticaNeueIcon,
  JOST: JostIcon,
  KAMERON: KameronIcon,
  LIBREBASKERVILLE: LibreBaskervilleIcon,
  LIBREFRANKLIN: LibreFranklinIcon,
  MONTSERRAT: MontserratIcon,
  NUNITO: NunitoIcon,
  POPPINS: PoppinsIcon,
  TIMESNEWROMAN: TimesNewRomanIcon,
  TINOS: TinosIcon
};
