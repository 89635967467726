import { Theme, createTheme } from '@mui/material';
import { createAlertTheme } from 'components/Alert';
import { createBreadcrumbTheme } from 'components/Breadcrumbs';
import { createButtonTheme } from 'components/Button';
import { createCheckboxTheme } from 'components/Checkbox';
import { createChipTheme } from 'components/Chip';
import { createDatePickerTheme } from 'components/DatePicker';
import { createDialogTheme } from 'components/Dialog';
import { createIconButtonTheme } from 'components/IconButton';
import { createMenuTheme } from 'components/Menu';
import { createRadioTheme } from 'components/Radio';
import { createSelectTheme } from 'components/Select';
import { createTabsTheme } from 'components/Tabs';
import { createTypographyTheme } from 'components/Text';
import { createTextFieldTheme } from 'components/TextField';
import { createTooltipTheme } from 'components/Tooltip';
import { createColorTheme } from './palette';

const initFoundationThemes = (currTheme: Theme): Theme => {
  let theme = currTheme;

  theme = createColorTheme(theme);
  theme = createTypographyTheme(theme);

  return theme;
};

export const initComponentThemes = (currTheme: Theme): Theme => {
  let theme = currTheme;

  theme = createBreadcrumbTheme(theme);
  theme = createButtonTheme(theme);
  theme = createRadioTheme(theme);
  theme = createCheckboxTheme(theme);
  theme = createIconButtonTheme(theme);
  theme = createMenuTheme(theme);
  theme = createTooltipTheme(theme);
  theme = createTextFieldTheme(theme);
  theme = createDialogTheme(theme);
  theme = createChipTheme(theme);
  theme = createAlertTheme(theme);
  theme = createTabsTheme(theme);
  theme = createSelectTheme(theme);
  theme = createDatePickerTheme(theme);

  return theme;
};

const initTheme = (): Theme => {
  /**
   * This theme setup follows the pattern referenced in
   * https://mui.com/customization/theming/#createtheme-options-args-theme
   */
  // initialize theme object with default spacing unit
  let theme = createTheme({ spacing: 16 });

  theme = initFoundationThemes(theme);
  theme = initComponentThemes(theme);

  return theme;
};

const theTheme = initTheme();

export { theTheme };
