import {
  authenticateUser,
  checkAuthentication,
  loginService
} from './auth0Service';

export const preloadAuthenticator = async () => {
  const query = window.location.search;
  // const storedToken = window.sessionStorage.getItem('accessToken');

  if (query.includes('code=') && query.includes('state=')) {
    // User is coming back from an Auth0 redirect with auth information, pass off to auth0
    return authenticateUser();
  }

  // user is coming in directly, without auth info in the query string - utilize Auth0 to
  // check for existing session.
  if (await checkAuthentication()) {
    return true;
  }

  // user is unauthenticated and has no stored session with Auth0 -- engage loginWithRedirect
  window.sessionStorage.setItem('accessToken', ''); // is this required?
  return loginService();
};
