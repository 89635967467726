import * as React from 'react';
import { Box, Theme } from '@mui/material';
import Dialog, { DialogProps } from 'components/Dialog';
import Icon, { ICONS } from 'components/Icon';

export enum SuccessDialogVariants {
  CONFIRMATION = 'CONFIRMATION',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS'
}

const iconVariantMap = {
  SUCCESS: ICONS.CHECK,
  LOADING: ICONS.DOTS_HORIZONTAL,
  CONFIRMATION: ICONS.EXCLAMATION
};

interface SuccessDialogProps extends DialogProps {
  variant?: SuccessDialogVariants;
}

type SuccessDialogIconProps = {
  iconVariant: SuccessDialogVariants;
};

/**
 * The squigly dodecagon with the checkmark icon at the top of
 * the success dialog
 */
const SuccessDialogIcon = ({
  iconVariant
}: SuccessDialogIconProps): React.ReactElement | null => (
  <Box
    sx={{
      position: 'fixed',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      zIndex: 1
    }}
  >
    <Icon
      icon={ICONS.DODECAGON}
      sx={{
        fill: (theme: Theme) => theme.palette.primary.main,
        width: 145,
        height: 145
      }}
      color="primary"
      viewBox="0 0 142 142"
      data-testid="successIcon"
    />
    <Icon
      icon={iconVariantMap[iconVariant]}
      sx={{
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        stroke: (theme) => theme.palette.common.white,
        strokeWidth: 2,
        width: 80,
        height: 80
      }}
      data-testid="successIcon"
    />
  </Box>
);

/**
 * The GMS-specific success dialog.
 * Uses the sx prop to style all the dialog sub-components underneath it.
 * Sets the `isSuccess` prop on the `<Dialog />` component to notify the
 * `<DialogActionButton />` components to apply the `fullWidth` prop.
 */
const SuccessDialog = ({
  children,
  sx,
  variant = SuccessDialogVariants.SUCCESS,
  ...props
}: SuccessDialogProps): JSX.Element => (
  <Dialog
    isSuccess
    sx={[
      (theme) => ({
        // adds specificity to overwrite default component library dialog styles
        '&&': {
          '.MuiDialog-paper': {
            maxWidth: '40rem'
          },
          // styles for the <DialogTitle /> component
          '.MuiDialogTitle-root': {
            px: 6.5,
            pb: 0.75,
            background: theme.palette.common.white,

            '.MuiTypography-root.MuiTypography-h2': {
              ...theme.typography.h1,
              mt: 5,
              color: theme.palette.common.black
            },
            '.MuiIconButton-root': {
              color: theme.palette.darkGray.main,
              border: 0
            }
          },
          '.MuiDialogContent-root': {
            px: 5.25,
            py: 0,
            minHeight: '6.25rem'
          },

          // in the success dialog, the <DialogContentTitle /> will *NOT* be used
          '.DialogContentTitle': {
            display: 'none',
            visibility: 'hidden'
          },

          // styles for the <DialogContentDescription /> component
          '.MuiDialogContentText-root': {
            ...theme.typography.h3,
            color: theme.palette.primary.main,
            textAlign: 'center',
            mb: 2
          },

          // styles for the action button container: <DialogActions />
          '.MuiDialogActions-root': {
            backgroundColor: 'white !important',
            py: 3.5,
            px: 6.25,
            '& > :first-of-type': {
              ml: 0
            },
            '& > :not(:first-of-type)': {
              ml: 1.5
            }
          }
        }
      }),
      // uses this as the second element in the sx prop array for any styles
      // that are passed in
      () => ({ ...sx })
    ]}
    {...props}
  >
    <SuccessDialogIcon iconVariant={variant} />
    {children}
  </Dialog>
);

export default SuccessDialog;
